import { Select } from 'antd';
import i18n from 'i18next';
import { REPORT } from 'modules/core/constants';
import { generateSelectOptions } from 'modules/core/utils';

const ReportSelect = ({
  defaultSelectValue,
  setDefaultSelectValue,
  selection,
}) => {
  const REPORT_OPTIONS = [
    {
      id: REPORT.REPORTS_KEYS.REPORTE_KEY,
      name: i18n.t('LABEL_REPORTS'),
    },
    {
      id: REPORT.REPORTS_KEYS.CUSTOM_KEY,
      name: i18n.t('LABEL_CUSTOM_REPORTS'),
    },
  ];

  return (
    <Select
      style={{ width: 205 }}
      defaultValue={defaultSelectValue}
      onChange={value => setDefaultSelectValue(value)}
      options={generateSelectOptions({ options: REPORT_OPTIONS, selection })}
    />
  );
};

export default ReportSelect;
