export const NAME = 'planning';

export const CREATE_PERIOD = `${NAME}/CREATE_PERIOD`;
export const CREATE_PERIOD_SUCCESS = `${NAME}/CREATE_PERIOD_SUCCESS`;
export const CREATE_PERIOD_FAIL = `${NAME}/CREATE_PERIOD_FAIL`;

export const DELETE_PERIOD = `${NAME}/DELETE_PERIOD`;
export const DELETE_PERIOD_SUCCESS = `${NAME}/DELETE_PERIOD_SUCCESS`;
export const DELETE_PERIOD_FAIL = `${NAME}/DELETE_PERIOD_FAIL`;

export const EDIT_PERIOD = `${NAME}/EDIT_PERIOD`;
export const EDIT_PERIOD_SUCCESS = `${NAME}/EDIT_PERIOD_SUCCESS`;
export const EDIT_PERIOD_FAIL = `${NAME}/EDIT_PERIOD_FAIL`;

export const FETCH_PERIODS = `${NAME}/FETCH_PERIODS`;
export const FETCH_PERIODS_SUCCESS = `${NAME}/FETCH_PERIODS_SUCCESS`;
export const FETCH_PERIODS_FAIL = `${NAME}/FETCH_PERIODS_FAIL`;

export const FETCH_PERIOD_DETAIL = `${NAME}/FETCH_PERIOD_DETAIL`;
export const FETCH_PERIOD_DETAIL_SUCCESS = `${NAME}/FETCH_PERIOD_DETAIL_SUCCESS`;
export const FETCH_PERIOD_DETAIL_FAIL = `${NAME}/FETCH_PERIOD_DETAIL_FAIL`;

export const FETCH_CONCEPTS_LIST = `${NAME}/FETCH_CONCEPTS_LIST`;
export const FETCH_CONCEPTS_LIST_SUCCESS = `${NAME}/FETCH_CONCEPTS_LIST_SUCCESS`;
export const FETCH_CONCEPTS_LIST_FAIL = `${NAME}/FETCH_CONCEPTS_LIST_FAIL`;

export const FETCH_CONCEPTS_DETAIL = `${NAME}/FETCH_CONCEPTS_DETAIL`;
export const FETCH_CONCEPTS_DETAIL_SUCCESS = `${NAME}/FETCH_CONCEPTS_DETAIL_SUCCESS`;
export const FETCH_CONCEPTS_DETAIL_FAIL = `${NAME}/FETCH_CONCEPTS_DETAIL_FAIL`;

export const FETCH_CONCEPT_TEMPLATE_XLS = `${NAME}/FETCH_REPORT_TEMPLATE_XLS`;

export const FETCH_CONCEPT_CONSOLIDATED_XLS = `${NAME}/FETCH_CONCEPT_CONSOLIDATED_XLS`;

export const FETCH_PROJECTIONS = `${NAME}/FETCH_PROJECTIONS`;
export const FETCH_PROJECTIONS_SUCCESS = `${NAME}/FETCH_PROJECTIONS_SUCCESS`;
export const FETCH_PROJECTIONS_FAIL = `${NAME}/FETCH_PROJECTIONS_FAIL`;

export const FETCH_PROJECTION_DETAIL = `${NAME}/FETCH_PROJECTION_DETAIL`;
export const FETCH_PROJECTION_DETAIL_SUCCESS = `${NAME}/FETCH_PROJECTION_DETAIL_SUCCESS`;
export const FETCH_PROJECTION_DETAIL_FAIL = `${NAME}/FETCH_PROJECTION_DETAIL_FAIL`;

export const FETCH_PROJECTION_COLUMNS = `${NAME}/FETCH_PROJECTION_COLUMNS`;
export const FETCH_PROJECTION_COLUMNS_SUCCESS = `${NAME}/FETCH_PROJECTION_COLUMNS_SUCCESS`;
export const FETCH_PROJECTION_COLUMNS_FAIL = `${NAME}/FETCH_PROJECTION_COLUMNS_FAIL`;

export const FETCH_PROJECTION_ROWS = `${NAME}/FETCH_PROJECTION_ROWS`;
export const FETCH_PROJECTION_ROWS_SUCCESS = `${NAME}/FETCH_PROJECTION_ROWS_SUCCESS`;
export const FETCH_PROJECTION_ROWS_FAIL = `${NAME}/FETCH_PROJECTION_ROWS_FAIL`;

export const FETCH_PROJECTION_AMOUNTS_ROWS = `${NAME}/FETCH_PROJECTION_AMOUNTS_ROWS`;
export const FETCH_PROJECTION_AMOUNTS_ROWS_SUCCESS = `${NAME}/FETCH_PROJECTION_AMOUNTS_ROWS_SUCCESS`;
export const FETCH_PROJECTION_AMOUNTS_ROWS_FAIL = `${NAME}/FETCH_PROJECTION_AMOUNTS_ROWS_FAIL`;

export const FETCH_PROJECTION_TEMPLATE_XLS = `${NAME}/FETCH_PROJECTION_TEMPLATE_XLS`;
export const FETCH_PROJECTION_TEMPLATE_XLS_SUCCESS = `${NAME}/FETCH_PROJECTION_TEMPLATE_XLS_SUCCESS`;
export const FETCH_PROJECTION_TEMPLATE_XLS_FAIL = `${NAME}/FETCH_PROJECTION_TEMPLATE_XLS_FAIL`;

export const UPLOAD_PROJEXTION_XLS = `${NAME}/UPLOAD_PROJEXTION_XLS`;
export const UPLOAD_PROJEXTION_XLS_SUCCESS = `${NAME}/UPLOAD_PROJEXTION_XLS_SUCCESS`;
export const UPLOAD_PROJEXTION_XLS_FAIL = `${NAME}/UPLOAD_PROJEXTION_XLS_FAIL`;

export const UPLOAD_CONCEPT_VALUES_XLS = `${NAME}/UPLOAD_CONCEPT_VALUES_XLS`;

export const DOWNLOAD_PROJECTION_XLS = `${NAME}/DOWNLOAD_PROJECTION_XLS,`;
export const DOWNLOAD_PROJECTION_XLS_SUCCESS = `${NAME}/DOWNLOAD_PROJECTION_XLS_SUCCESS`;
export const DOWNLOAD_PROJECTION_XLS_FAIL = `${NAME}/DOWNLOAD_PROJECTION_XLS_FAIL`;

export const FETCH_PROJECTION_KPIS = `${NAME}/FETCH_PROJECTION_KPIS`;
export const FETCH_PROJECTION_KPIS_SUCCESS = `${NAME}/FETCH_PROJECTION_KPIS_SUCCESS`;
export const FETCH_PROJECTION_KPIS_FAIL = `${NAME}/FETCH_PROJECTION_KPIS_FAIL`;

export const FETCH_PROJECTION_AMOUNTS_KPIS = `${NAME}/FETCH_PROJECTION_AMOUNTS_KPIS`;
export const FETCH_PROJECTION_AMOUNTS_KPIS_SUCCESS = `${NAME}/FETCH_PROJECTION_AMOUNTS_KPIS_SUCCESS`;
export const FETCH_PROJECTION_AMOUNTS_KPIS_FAIL = `${NAME}/FETCH_PROJECTION_AMOUNTS_KPIS_FAIL`;

export const FETCH_USER_TRACKS = `${NAME}/FETCH_TRACKS`;
export const FETCH_USER_TRACKS_SUCCESS = `${NAME}/FETCH_TRACKS_SUCCESS`;
export const FETCH_USER_TRACKS_FAIL = `${NAME}/FETCH_TRACKS_FAIL`;

export const FETCH_FILE_TRACKS = `${NAME}/FETCH_FILE_TRACKS`;
export const FETCH_FILE_TRACKS_SUCCESS = `${NAME}/FETCH_FILE_TRACKS_SUCCESS`;
export const FETCH_FILE_TRACKS_FAIL = `${NAME}/FETCH_FILE_TRACKS_FAIL`;

export const APPROVE_PROJECTION = `${NAME}/APPROVE_PROJECTION`;
export const APPROVE_PROJECTION_SUCCESS = `${NAME}/APPROVE_PROJECTION_SUCCESS`;
export const APPROVE_PROJECTION_FAIL = `${NAME}/APPROVE_PROJECTION_FAIL`;

export const FETCH_PROJECTION_FILTERS = `${NAME}/FETCH_PROJECTION_FILTERS`;
export const FETCH_PROJECTION_FILTERS_SUCCESS = `${NAME}/FETCH_PROJECTION_FILTERS_SUCCESS`;
export const FETCH_PROJECTION_FILTERS_FAIL = `${NAME}/FETCH_PROJECTION_FILTERS_FAIL`;

export const FETCH_PUBLISHED_ACCOUNTS = `${NAME}/FETCH_PUBLISHED_ACCOUNTS`;
export const FETCH_PUBLISHED_ACCOUNTS_SUCCESS = `${NAME}/FETCH_PUBLISHED_ACCOUNTS_SUCCESS`;
export const FETCH_PUBLISHED_ACCOUNTS_FAIL = `${NAME}/FETCH_PUBLISHED_ACCOUNTS_FAIL`;

export const CLEAN_PUBLISHED_DATES = `${NAME}/CLEAN_PLANNING_PUBLISHED_DATES`;

export const UPDATE_TRANSACTION = `${NAME}/UPDATE_TRANSACTION`;
export const UPDATE_TRANSACTION_SUCCESS = `${NAME}/UPDATE_TRANSACTION_SUCCESS`;
export const UPDATE_TRANSACTION_FAIL = `${NAME}/UPDATE_TRANSACTION_FAIL`;

export const UPDATE_TRANSACTION_FORWARD = `${NAME}/UPDATE_TRANSACTION_FORWARD`;
export const UPDATE_TRANSACTION_FORWARD_SUCCESS = `${NAME}/UPDATE_TRANSACTION_FORWARD_SUCCESS`;
export const UPDATE_TRANSACTION_FORWARD_FAIL = `${NAME}/UPDATE_TRANSACTION_FORWARD_FAIL`;

export const UPDATE_TRANSACTION_ALL = `${NAME}/UPDATE_TRANSACTION_ALL`;
export const UPDATE_TRANSACTION_ALL_SUCCESS = `${NAME}/UPDATE_TRANSACTION_ALL_SUCCESS`;
export const UPDATE_TRANSACTION_ALL_FAIL = `${NAME}/UPDATE_TRANSACTION_ALL_FAIL`;

export const APPLY_VARIABLES = `${NAME}/APPLY_VARIABLES`;
export const APPLY_VARIABLES_SUCCESS = `${NAME}/APPLY_VARIABLES_SUCCESS`;
export const APPLY_VARIABLES_FAIL = `${NAME}/APPLY_VARIABLES_FAIL`;

export const SET_UPLOAD_LIMIT_DATE = `${NAME}/SET_UPLOAD_LIMIT_DATE`;

export const FETCH_UPLOAD_LIMIT_DATE = `${NAME}/FETCH_UPLOAD_LIMIT_DATE`;
export const FETCH_UPLOAD_LIMIT_DATE_SUCCESS = `${NAME}/FETCH_UPLOAD_LIMIT_DATE_SUCCESS`;
export const FETCH_UPLOAD_LIMIT_DATE_FAIL = `${NAME}/FETCH_UPLOAD_LIMIT_DATE_FAIL`;

export const EDIT_BUDGET_NAME = `${NAME}/EDIT_BUDGET_NAME`;
export const EDIT_BUDGET_NAME_SUCCESS = `${NAME}/EDIT_BUDGET_NAME_SUCCESS`;
export const EDIT_BUDGET_NAME_FAIL = `${NAME}/EDIT_BUDGET_NAME_FAIL`;

export const DELETE_BUDGET = `${NAME}/DELETE_BUDGET`;
export const DELETE_BUDGET_SUCCESS = `${NAME}/DELETE_BUDGET_SUCCESS`;
export const DELETE_BUDGET_FAIL = `${NAME}/DELETE_BUDGET_FAIL`;

export const DUPLICATE_BUDGET = `${NAME}/DUPLICATE_BUDGET`;
export const DUPLICATE_BUDGET_SUCCESS = `${NAME}/DUPLICATE_BUDGET_SUCCESS`;
export const DUPLICATE_BUDGET_FAIL = `${NAME}/DUPLICATE_BUDGET_FAIL`;

export const FETCH_CONSOLIDATION_LIST = `${NAME}/FETCH_CONSOLIDATION_LIST`;
export const FETCH_CONSOLIDATION_LIST_SUCCESS = `${NAME}/FETCH_CONSOLIDATION_LIST_SUCCESS`;
export const FETCH_CONSOLIDATION_LIST_FAIL = `${NAME}/FETCH_CONSOLIDATION_LIST_FAIL`;

export const CREATE_CONSOLIDATION = `${NAME}/CREATE_CONSOLIDATION`;
export const CREATE_CONSOLIDATION_SUCCESS = `${NAME}/CREATE_CONSOLIDATION_SUCCESS`;
export const CREATE_CONSOLIDATION_FAIL = `${NAME}/CREATE_CONSOLIDATION_FAIL`;

export const FETCH_CONSOLIDATION_DETAIL_KPI = `${NAME}/FETCH_CONSOLIDATION_DETAIL_KPI`;
export const FETCH_CONSOLIDATION_DETAIL_KPI_SUCCESS = `${NAME}/FETCH_CONSOLIDATION_DETAIL_KPI_SUCCESS`;
export const FETCH_CONSOLIDATION_DETAIL_KPI_FAIL = `${NAME}/FETCH_CONSOLIDATION_DETAIL_KPI_FAIL`;

export const CHANGE_BUDGET = `${NAME}/CHANGE_BUDGET`;
export const CHANGE_BUDGET_SUCCESS = `${NAME}/CHANGE_BUDGET_SUCCESS`;
export const CHANGE_BUDGET_FAIL = `${NAME}/CHANGE_BUDGET_FAIL`;

export const DELETE_BUDGET_ROW = `${NAME}/DELETE_BUDGET_ROW`;
export const DELETE_ALL_PROJECTION_ROWS = `${NAME}/DELETE_ALL_PROJECTION_ROWS`;

export const FETCH_FORMULAS_AMOUNT_LIST = `${NAME}/FETCH_FORMULAS_AMOUNT_LIST`;
export const FETCH_FORMULAS_AMOUNT_LIST_SUCCESS = `${NAME}/FETCH_FORMULAS_AMOUNT_LIST_SUCCESS`;
export const FETCH_FORMULAS_AMOUNT_LIST_FAIL = `${NAME}/FETCH_FORMULAS_AMOUNT_LIST_FAIL`;

export const FETCH_FACTOR_ROWS = `${NAME}/FETCH_FACTOR_ROWS`;
export const FETCH_FACTOR_ROWS_SUCCESS = `${NAME}/FETCH_FACTOR_ROWS_SUCCESS`;
export const FETCH_FACTOR_ROWS_FAIL = `${NAME}/FETCH_FACTOR_ROWS_FAIL`;

export const DELETE_ALL_FACTOR_ROWS = `${NAME}/DELETE_ALL_FACTOR_ROWS`;
export const DELETE_FACTOR_ROW = `${NAME}/DELETE_FACTOR_ROW`;

export const REPLICATE_FACTOR = `${NAME}/REPLICATE_FACTOR`;
export const REPLICATE_FACTOR_SUCCESS = `${NAME}/REPLICATE_FACTOR_SUCCESS`;
export const REPLICATE_FACTOR_FAIL = `${NAME}/REPLICATE_FACTOR_FAIL`;

export const FETCH_FACTOR_COLUMNS = `${NAME}/FETCH_FACTOR_COLUMNS`;
export const FETCH_FACTOR_COLUMNS_SUCCESS = `${NAME}/FETCH_FACTOR_COLUMNS_SUCCESS`;
export const FETCH_FACTOR_COLUMNS_FAIL = `${NAME}/FETCH_FACTOR_COLUMNS_FAIL`;

export const UPDATE_TRANSACTION_FACTOR = `${NAME}/UPDATE_TRANSACTION_FACTOR`;

export const UNAPPLY_FORMULA = `${NAME}/UNAPPLY_FORMULA`;
export const UNAPPLY_FORMULA_SUCCESS = `${NAME}/UNAPPLY_FORMULA_SUCCESS`;
export const UNAPPLY_FORMULA_FAIL = `${NAME}/UNAPPLY_FORMULA_FAIL`;

export const APPLY_FORMULA = `${NAME}/APPLY_FORMULA`;
export const APPLY_FORMULA_SUCCESS = `${NAME}/APPLY_FORMULA_SUCCESS`;
export const APPLY_FORMULA_FAIL = `${NAME}/APPLY_FORMULA_FAIL`;
export const FETCH_DIMENSIONS_VALUES_LIST = `${NAME}/FETCH_DIMENSIONS_VALUES_LIST`;
export const FETCH_DIMENSIONS_VALUES_LIST_SUCCESS = `${NAME}/FETCH_DIMENSIONS_VALUES_LIST_SUCCESS`;
export const FETCH_DIMENSIONS_VALUES_LIST_FAIL = `${NAME}/FETCH_DIMENSIONS_VALUES_LIST_FAIL`;

//FIJAR PPTO
export const SET_DEFAULT_BUDGET = `${NAME}/SET_DEFAULT_BUDGET`;
export const SET_DEFAULT_BUDGET_SUCCESS = `${NAME}/SET_DEFAULT_BUDGET_SUCCESS`;
export const SET_DEFAULT_BUDGET_FAIL = `${NAME}/SET_DEFAULT_BUDGET_FAIL`;

//Actualizar datos
export const UPDATE_CONSOLIDATED = `${NAME}/UPDATE_CONSOLIDATED`;
export const UPDATE_CONSOLIDATED_SUCCESS = `${NAME}/UPDATE_CONSOLIDATED_SUCCESS`;
export const UPDATE_CONSOLIDATED_FAIL = `${NAME}/UPDATE_CONSOLIDATED_FAIL`;

export const PUBLISH_CONSOLIDATED = `${NAME}/PUBLISH_CONSOLIDATED`;
// ++ COMMENTS ++
export const SYNC_CREATE_PROJECTION_COMMENT = `${NAME}/SYNC_CREATE_PROJECTION_COMMENT`;
export const SYNC_DELETE_PROJECTION_COMMENT = `${NAME}/SYNC_DELETE_PROJECTION_COMMENT`;

export const FETCH_TRANSACTION_COMMENTS = `${NAME}/FETCH_TRANSACTION_COMMENTS`;
export const FETCH_TRANSACTION_COMMENTS_SUCCESS = `${NAME}/FETCH_TRANSACTION_COMMENTS_SUCCESS`;
export const FETCH_TRANSACTION_COMMENTS_FAIL = `${NAME}/FETCH_TRANSACTION_COMMENTS_FAIL`;

export const CREATE_TRANSACTION_COMMENT = `${NAME}/CREATE_TRANSACTION_COMMENT`;
export const CREATE_TRANSACTION_COMMENT_SUCCESS = `${NAME}/CREATE_TRANSACTION_COMMENT_SUCCESS`;
export const CREATE_TRANSACTION_COMMENT_FAIL = `${NAME}/CREATE_TRANSACTION_COMMENT_FAIL`;

export const DELETE_TRANSACTION_COMMENT = `${NAME}/DELETE_TRANSACTION_COMMENT`;
export const DELETE_TRANSACTION_COMMENT_SUCCESS = `${NAME}/DELETE_TRANSACTION_COMMENT_SUCCESS`;
export const DELETE_TRANSACTION_COMMENT_FAIL = `${NAME}/DELETE_TRANSACTION_COMMENT_FAIL`;
// ++ END COMMENTS ++

//MESES
export const FETCH_MONTHS_LIST = `${NAME}/FETCH_MONTHS_LIST`;
export const FETCH_MONTHS_LIST_SUCCESS = `${NAME}/FETCH_MONTHS_LIST_SUCCESS`;
export const FETCH_MONTHS_LIST_FAIL = `${NAME}/FETCH_MONTHS_LIST_FAIL`;

//MULTIMONEDA

export const FETCH_EXCHANGE_RATE_LIST = `${NAME}/FETCH_EXCHANGE_RATE_LIST`;
export const FETCH_EXCHANGE_RATE_LIST_SUCCESS = `${NAME}/FETCH_EXCHANGE_RATE_LIST_SUCCESS`;
export const FETCH_EXCHANGE_RATE_LIST_FAIL = `${NAME}/FETCH_EXCHANGE_RATE_LIST_FAIL`;

export const SAVE_EXCHANGE_RATE = `${NAME}/SAVE_EXCHANGE_RATE`;

export const SET_EXCHANGE_RATE_VALUES = `${NAME}/SET_EXCHANGE_RATE_VALUES`;

export const FETCH_CURRENCIES_TRACKS = `${NAME}/FETCH_CURRENCIES_TRACKS`;
export const FETCH_CURRENCIES_TRACKS_SUCCESS = `${NAME}/FETCH_CURRENCIES_TRACKS_SUCCESS`;
export const FETCH_CURRENCIES_TRACKS_FAIL = `${NAME}/FETCH_CURRENCIES_TRACKS_FAIL`;

export const EDIT_TRANSACTION_ORIGIN_CURRENCY = `${NAME}/EDIT_TRANSACTION_ORIGIN_CURRENCY`;
export const CREATE_TRANSACTION_ORIGIN_CURRENCY = `${NAME}/CREATE_TRANSACTION_ORIGIN_CURRENCY`;
export const UPDARE_TRANSACTION_FORWARD_ORIGIN = `${NAME}/UPDARE_TRANSACTION_FORWARD_ORIGIN`;

// INTEGRATIONS
export const INTEGRATIONS_UPLOAD_FILE = `${NAME}/INTEGRATIONS_UPLOAD_FILE`;
export const INTEGRATIONS_UPLOAD_FILE_SUCCESS = `${NAME}/INTEGRATIONS_UPLOAD_FILE_SUCCESS`;
export const INTEGRATIONS_UPLOAD_FILE_FAIL = `${NAME}/INTEGRATIONS_UPLOAD_FILE_FAIL`;

export const FETCH_ORIGIN_ROWS = `${NAME}/FETCH_ORIGIN_ROWS`;
export const FETCH_ORIGIN_ROWS_SUCCESS = `${NAME}/FETCH_ORIGIN_ROWS_SUCCESS`;
export const FETCH_ORIGIN_ROWS_FAIL = `${NAME}/FETCH_ORIGIN_ROWS_FAIL`;

//DASHBOARD SELF-SERVICE
export const FETCH_DASHBOARD_URL = `${NAME}/FETCH_DASHBOARD_URL`;
export const FETCH_DASHBOARD_URL_SUCCESS = `${NAME}/FETCH_DASHBOARD_URL_SUCCESS`;
export const FETCH_DASHBOARD_URL_FAIL = `${NAME}/FETCH_DASHBOARD_URL_FAIL`;

export const FETCH_DASHBOARD_DATASOURCES = `${NAME}/FETCH_DASHBOARD_DATASOURCES`;
export const FETCH_DASHBOARD_DATASOURCES_SUCCESS = `${NAME}/FETCH_DASHBOARD_DATASOURCES_SUCCESS`;
export const FETCH_DASHBOARD_DATASOURCES_FAIL = `${NAME}/FETCH_DASHBOARD_DATASOURCES_FAIL`;

//pxq

export const FETCH_PXQ = `${NAME}/FETCH_PXQ`;
export const FETCH_PXQ_SUCCESS = `${NAME}/FETCH_PXQ_SUCCESS`;
export const FETCH_PXQ_FAIL = `${NAME}/FETCH_PXQ_FAIL`;

export const CREATE_PXQ = `${NAME}/CREATE_PXQ`;

export const ENABLE_PXQ = `${NAME}/ENABLE_PXQ`;

export const UPDATE_CELL_PXQ = `${NAME}/UPDATE_CELL_PXQ`;

export const SET_DATA_UPDATE_CELL_PXQ = `${NAME}/SET_DATA_UPDATE_CELL_PXQ`;

//endpoints SICA
export const UPDATE_DATA_SICA = `${NAME}/UPDATE_DATA_SICA`;
export const PRINT_REPORTS_SICA = `${NAME}/PRINT_REPORTS_SICA`;
export const UPLOAD_DATA_SICA = `${NAME}/UPLOAD_DATA_SICA`;

export const UPDATE_DATA_OTHER = `${NAME}/UPDATE_DATA_OTHER`;

export const UPDATE_DATA_ZARCAM = `${NAME}/UPDATE_DATA_ZARCAM`;
