import React, { useState } from 'react';
import {
  Modal,
  Button,
  Form,
  Col,
  Row,
  Input,
  message,
  Select,
  Space,
  notification,
} from 'antd';
import { AddAndDeleteFormItem } from 'modules/core/components';
import { LeftCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import { useTranslation } from 'react-i18next';
import { generateSelectOptions } from 'modules/core/utils';

const CreateLevelModal = ({
  visible,
  onCancel,
  createFirstTime,
  onShowPlanAccountModal,
  createPlanAccount,
  onRefetchDimensioValues,
  onRefetchDimensioValuesParents,
  dimensionValuesParents: { valuesParents },
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoadingModal(true);
        createPlanAccount(values.levels, {
          dimension_name: 'Nivel',
          writeable: 'True',
        })
          .then(() =>
            Promise.all([
              onRefetchDimensioValues(),
              onRefetchDimensioValuesParents(),
            ]).then(() => {
              message.success(t('FEEDBACK_CREATE_SUCCESS'));
              setIsLoadingModal(false);
              onClose();
            })
          )
          .catch(error => {
            //TODO: AGREGAR NOTIFICATION CUANDO HAYA MENSAJES COMPLETOS PARA MOSTRAR EN TODAS LAS INTERACCIONES DE ERROR
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: error,
              duration: 0,
            });
            setIsLoadingModal(false);
            onClose();
          });
      })
      .catch(e => {});
  };

  return (
    <Modal
      title={t('CONFIG_ACCOUNTS_CREATE_LEVEL_ACTION')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      centered
      closable
      onCancel={onClose}
      destroyOnClose
      footer={
        !createFirstTime ? (
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              type="link"
              icon={<LeftCircleOutlined />}
              disabled={isLoadingModal}
              onClick={() => {
                onShowPlanAccountModal(true);
                onClose();
              }}
            >
              {t('ACTION_RETURN')}
            </Button>
            <Space direction="horizontal" size="middle">
              <Button onClick={onClose} disabled={isLoadingModal}>
                {t('ACTION_CANCEL')}
              </Button>
              <Button
                type="primary"
                onClick={onConfirm}
                loading={isLoadingModal}
              >
                {t('ACTION_CREATE')}
              </Button>
            </Space>
          </Space>
        ) : (
          <>
            <Button onClick={onClose} disabled={isLoadingModal}>
              {t('ACTION_CANCEL')}
            </Button>
            <Button type="primary" onClick={onConfirm} loading={isLoadingModal}>
              {t('ACTION_CREATE')}
            </Button>
          </>
        )
      }
    >
      <Form form={form} layout="vertical" initialValues={{ levels: [''] }}>
        <Form.List name="levels">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row key={field.key} type="flex" justify="end">
                    <Row gutter={24} type="flex" justify="start">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'code']}
                          label={[field.label, t('FIELD_CODE')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_ACCOUNTS_CODE_INPUT_LEVEL_PLACEHOLDER'
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'name']}
                          label={[field.label, t('FIELD_NAME')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_ACCOUNTS_NAME_INPUT_LEVEL_PLACEHOLDEL'
                            )}
                          ></Input>
                        </Form.Item>
                      </Col>
                      {!createFirstTime && (
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, 'parent_id']}
                            label={[
                              field.label,
                              t('CONFIG_ACCOUNTS_LEVEL_PARENT_LABEL'),
                            ]}
                          >
                            <Select
                              placeholder={t('ACTION_SELECT')}
                              options={generateSelectOptions({
                                options: valuesParents && valuesParents,
                              })}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <AddAndDeleteFormItem
                      fieldsLength={fields.length}
                      index={index}
                      disabled={isLoadingModal}
                      addData={{
                        onClick: () => add(),
                        buttonName: 'CONFIG_ACCOUNTS_ADD_NEW_LEVEL_ACTION',
                      }}
                      deleteData={{
                        onClick: () => remove(field.name),
                        buttonName: 'CONFIG_ACCOUNTS_DELETE_LEVEL_ACTION',
                      }}
                    />
                  </Row>
                ))}
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  createPlanAccount: configuration.actions.createPlanAccount,
};

export default connect(null, mapDispatchToProps)(CreateLevelModal);
