import * as types from './constants';
import { saveAs } from 'file-saver';
import i18n from 'i18next';

export const fetchDistributions =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DISTRIBUTIONS,
      payload: {
        request: {
          url: `/adjustment/distributions/`,
          method: 'GET',
          params: {
            period_id: periodId,
            ...params,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchDistributionOriginColumns = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_DISTRIBUTION_ORIGIN_COLUMNS,
    payload: {
      request: {
        url: `/adjustment/adjustments/columns`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchDistributionOriginBalance =
  (data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DISTRIBUTION_ORIGIN_BALANCE,
      payload: {
        request: {
          url: '/planning/transactions/balance/',
          method: 'POST',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editDistributionValues =
  (data = {}) =>
  dispatch =>
    dispatch({
      type: types.EDIT_DISTRIBUTION_VALUES,
      payload: { data },
    });

export const editDistributionOriginRow =
  (data = {}) =>
  dispatch =>
    dispatch({
      type: types.EDIT_DISTRIBUTION_ORIGIN_ROW,
      payload: { data },
    });

export const addDistributionDestinationRow = () => dispatch =>
  dispatch({
    type: types.ADD_DISTRIBUTION_DESTINATION_ROW,
  });

export const editDistributionDestinationRow =
  (rowId, data = {}) =>
  dispatch =>
    dispatch({
      type: types.EDIT_DISTRIBUTION_DESTINATION_ROW,
      payload: { rowId, data },
    });

export const removeDistributionDestinationRow = rowId => dispatch =>
  dispatch({
    type: types.REMOVE_DISTRIBUTION_DESTINATION_ROW,
    payload: { rowId },
  });

export const resetDistributionDestinationRows = () => dispatch =>
  dispatch({
    type: types.RESET_DISTRIBUTION_DESTINATION_ROWS,
  });

export const copyDistributionRow = rowId => dispatch =>
  dispatch({
    type: types.COPY_DISTRIBUTION_ROWS,
    payload: { rowId },
  });

export const downloadDistributionTemplateXLS =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_DISTRIBUTION_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/adjustment/distributions/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, i18n.t('DISTRIBUTION_SOURCE_DATA_TEMPLATE'));
    return Promise.resolve();
  };

export const uploadDistributionFile =
  (data, dimensionId, params = {}) =>
  async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('file', file);
      formData.append('destination', dimensionId);
    });
    const response = await dispatch({
      type: types.UPLOAD_DISTRIBUTION_FILE,
      payload: {
        request: {
          url: `/adjustment/distributions/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createDistribution =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_DISTRIBUTION,
      payload: {
        request: {
          url: '/adjustment/distributions/distribute/',
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const exitDistributionCreation = () => dispatch =>
  dispatch({
    type: types.EXIT_DISTRIBUTION_CREATION,
  });

export const cancelDistribution =
  (distributionId, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CANCEL_DISTRIBUTION,
      payload: {
        request: {
          url: `/adjustment/distributions/${distributionId}/cancel/`,
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const duplicateDistribution = distributionId => async dispatch => {
  const response = await dispatch({
    type: types.DUPLICATE_DISTRIBUTION,
    payload: {
      request: {
        url: `/adjustment/distributions/${distributionId}/duplicate/`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchDistributionDetail =
  (distributionId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DISTRIBUTION_DETAIL,
      payload: {
        request: {
          url: `/adjustment/distributions/${distributionId}/details/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const downloadDistributionsXLS =
  (filename = i18n.t('DISTRIBUTION_DEFAULT_FILE_NAME'), params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_DISTRIBUTIONS_XLS,
      payload: {
        request: {
          url: `/adjustment/distributions/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const downloadSingleDistributionsXLS =
  (
    distributionId,
    filename = i18n.t('DISTRIBUTION_TEMPLATE_NAME'),
    params = {}
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_SINGLE_DISTRIBUTIONS_XLS,
      payload: {
        request: {
          url: `/adjustment/distributions/${distributionId}/detail-consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };
