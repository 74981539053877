import React, { useState, useEffect } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Space, Button, Dropdown, Modal, Col, Row, Card } from 'antd';
import { Typography, ButtonActionBar } from 'modules/core/components';
import {
  isUserAllowed,
  getOperationsByKeys,
  getPeriodOperationsKeys,
  GA,
} from 'modules/core/utils';
import {
  UserOutlined,
  CalendarOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import logoBlack from 'assets/images/logo/logo-black.svg';
import logoCompleto from 'assets/images/logo/logo.svg';
import { UserAvatar } from 'modules/configuration/components';
import { ROLES } from 'modules/core/constants';
import moment from 'moment';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import home from 'modules/home';

import './AppLayout.scss';

const { Header } = Layout;
const { SETTINGS } = ROLES;

const AppLayout = ({
  children,
  history,
  onLogout,
  loggedUser,
  showIcons = true,
  notifications = [],
  fetchOnboardingSteps,
  hasDataConfig,
  integrationList,
}) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { t } = useTranslation();

  const exit = () =>
    onLogout().then(() => {
      history.push('/login');
    });

  const pathLogoComplete = ['/'];
  const pathname = window.location.pathname;

  const headerMenus = [
    {
      title: t('LABEL_PERIODS'),
      url: '/periodos',
      exact: true,
      icon: <CalendarOutlined />,
      allowedFor: getPeriodOperationsKeys(),
    },
    {
      title: t('LABEL_CONFIG'),
      url: '/configuracion',
      exact: false,
      icon: <SettingOutlined />,
      allowedFor: [SETTINGS],
    },
  ];

  useEffect(() => {
    GA.pageView();
  });

  const renderLogoBlack = () => (
    <div className="app-brand-logo" onClick={() => history.push('/')}>
      <img alt="brand-logo" src={logoBlack} />
    </div>
  );

  const renderLogoComplete = () => (
    <div className="app-brand-logo-complete" onClick={() => history.push('/')}>
      <img alt="brand-logo" src={logoCompleto} />
    </div>
  );

  return (
    <Layout className="app-layout">
      <Header className="app-layout-header">
        {pathLogoComplete.includes(pathname)
          ? !hasDataConfig && integrationList.every(data => !data.status)
            ? renderLogoComplete()
            : renderLogoBlack()
          : renderLogoBlack()}
        {showIcons && (
          <Space className="app-layout-actions" align="center" size="small">
            <Dropdown
              trigger={['click']}
              overlay={
                <div style={{ padding: 0 }}>
                  <Card
                    style={{ width: 400 }}
                    bodyStyle={{ height: 80 }}
                    title={
                      <Typography.Body level={2}>
                        {loggedUser.email}
                      </Typography.Body>
                    }
                    headStyle={{ height: 56 }}
                    actions={[
                      <ButtonActionBar position="start">
                        <Button
                          type="link"
                          onClick={() => setShowLogoutModal(true)}
                        >
                          {t('SIGN_OFF')}
                        </Button>
                      </ButtonActionBar>,
                    ]}
                    extra={
                      <Button
                        type="link"
                        onClick={() =>
                          history.push(
                            `/configuracion/misdatos/usuarios/${loggedUser.userId}/perfil` //TODO: Reemplazar por ruta de perfil cuando se desarrolle
                          )
                        }
                      >
                        {t('PROFILE')}
                      </Button>
                    }
                  >
                    {/* TODO: Ajustar cuando esten las notificaciones */}
                    {notifications.length > 0 ? (
                      notifications.map(notification => (
                        <Row gutter={24}>
                          <Col span={2}>
                            <UserAvatar avatarLetter={'PK'} />
                          </Col>
                          <Col span={22}>
                            <Row gutter={24}>
                              <Col span={24}>
                                <Typography.Body level={2}>
                                  {notification.message}
                                </Typography.Body>
                              </Col>
                              <Col span={24}>
                                <Typography.Body level={2}>{`${moment(
                                  notification.datetime
                                ).format(' ddd HH:mm A')}`}</Typography.Body>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <Typography.Body level={2}>
                        {t('NO_NOTIFICATIONS_MESSAGE')}
                      </Typography.Body>
                    )}
                  </Card>
                </div>
              }
            >
              <Button className="app-layout-user-section" title={t('SESSION')}>
                {loggedUser.firstName + ' ' + loggedUser.lastName}
                <UserOutlined />
              </Button>
            </Dropdown>
            {headerMenus
              .filter(navItem =>
                isUserAllowed(getOperationsByKeys(navItem.allowedFor))
              )
              .map((item, index) => {
                const { icon, url, title, exact } = item;
                return (
                  <NavLink
                    exact={exact}
                    to={url}
                    className="header-icon"
                    activeClassName="selected"
                    title={title}
                    key={index}
                  >
                    {icon}
                  </NavLink>
                );
              })}
          </Space>
        )}
      </Header>
      <Layout className="app-layout-body">{children}</Layout>
      <Modal
        visible={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        onOk={exit}
        cancelText={t('ACTION_CANCEL')}
        okText={t('SIGN_OFF')}
        title={t('SIGN_OFF')}
      >
        {t('SIGN_OFF_CONFIRM_MESSAGE')}
      </Modal>
    </Layout>
  );
};

const mapStateToProps = state => ({
  hasDataConfig: configuration.selectors.getHasDataConfig(state),
  integrationList: home.selectors.getIntegrationList(state),
});

export default withRouter(connect(mapStateToProps, null)(AppLayout));
