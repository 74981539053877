import { Select } from 'antd';

const SimpleSelect = ({
  options = [],
  onChange = () => {},
  placeholder = '',
  disabled = false,
  loading = false,
  ...props
}) => {
  return (
    <Select
      {...props}
      placeholder={placeholder}
      options={options}
      disabled={disabled}
      onChange={onChange}
      loading={loading}
      showSearch
      allowClear
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};

export default SimpleSelect;
