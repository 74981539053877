import { createSelector } from 'reselect';
import { NAME } from './constants';

// ++ DIMENSIONS ++
export const getDimensionsList = state => state[NAME].dimensionsList;
export const getDimensionDetail = state => state[NAME].dimensionDetail;
export const getDimensionValues = state => state[NAME].dimensionValues;
export const getValuesDimension = state => state[NAME].valuesDimension;
export const getDisplaySettings = state => state[NAME].displaySettings;
export const getDimensionValuesParents = state =>
  state[NAME].dimensionValuesParents;

// ++ USERS ++
export const getUsersList = state => state[NAME].usersList;
export const getUserDetail = state => state[NAME].userDetail;
export const getUserRoles = state => state[NAME].userRoles;

// ++ MODIFICADORES ++
export const getModifiersList = state => state[NAME].modifiersList;

// ++ VARIABLES ++
export const getVariablesList = state => state[NAME].variablesList;
export const getVariablesKpis = state => state[NAME].variablesKpis;
export const getBehaviourList = state => state[NAME].behaviourList;
export const getVariableDetail = state => state[NAME].variableDetail;

// ++ FORMULAS ++
export const getFormulasList = state => state[NAME].formulasList;
export const getFormulaDetail = state => state[NAME].formulaDetail;

//FACTORES
export const getFactoresList = state => state[NAME].factoresList;
export const getFactoresBehaviours = state => state[NAME].factoresBehaviours;

//otros reportes
export const getOtherReportsList = state => state[NAME].otherReportsList;
export const getOtherReportsStructure = state =>
  state[NAME].otherReportsStructure;

// ++ ROLES ++
export const getRolesList = state => state[NAME].rolesList;
export const getRoleOperationKeys = state => state[NAME].roleOperationsKeys;
export const getRoleDimensions = state => state[NAME].roleDimensions;

/*-----MULTIMONEDA-------*/
export const getCurrencyList = state => state[NAME].currencyList;

/*-----ONBOARDING-------*/
export const getOnboardingSteps = state => state[NAME].onboardingSteps;

export const getHasDataConfig = createSelector(
  getDimensionValues,
  getDimensionsList,
  (dimensionValues, dimensionsList) => {
    return dimensionsList.count > 0 && dimensionValues.count > 0;
  }
);
