const dashboard = {
  DASHBOARD_WELLCOME: '¡Bienvenido a Plika!',
  DASHBOARD_CONFIG_ACTION: 'Configurar Plika',
  DASHBOARD_EMPTY_STATE_DESCRIPTION: 'Todavía no estamos listos para trabajar',
  DASHBOARD_PERIODS_ENTER_ACTION: 'Ir a períodos',
  // Cards texts
  PLANNING_ENTER_TITLE: 'Gestioná tus presupuestos',
  CONTROL_ENTER_TITLE: 'Lleva un control de las ejecuciones',
  FORECAST_ENTER_TITLE: 'Re-definí tus presupuestos',
  REPORTS_ENTER_TITLE: 'Analizá tu período presupuestario',

  //Onboarding ants
  ONBOARDING_PLANNING_DESCRIPTION:
    'Comenzá a armar tu presupuestación de ventas y gastos. Podrás crear tantos escenarios de ventas y gastos como sean necesarios y luego consolidarlos entre sí.',

  ONBOARDING_CONTROL_DESCRIPTION:
    'Cargá datos reales de ventas y gastos, también realizá ajustes de gestión y distribuciones.',

  ONBOARDING_FORECAST_DESCRIPTION:
    'Realizá re-presupuestaciones en el módulo de Forecast para identificar lo que no evoluciona según lo planificado, para esto deberás tener aprobados los presupuestos en el módulo de Planificación y publicados los reales de ventas y gastos en el módulo de Seguimiento.',

  ONBOARDING_REPORT_DESCRIPTION:
    'Accede a los “Reportes” y “Reportes personalizados”, para visualizar las métricas más relevantes de los mismos, gráficos de evolución y comparaciones.',

  DASHBOARD_USER_HELLO: 'Hola {{name}}!',
  DASHBOARD_SUBTITLE: 'Te ofrecemos acceso a información que puede interesarte',
  DASHBOARD_LABEL_ARTICLES: 'Artículos más recientes',
  DASHBOARD_LABEL_DICTIONARY: 'Diccionario de Métricas FP&A',
  DASHBOARD_LABEL_NEW_FUNCTIONS: 'Nuevas funciones en Plika',
  DASHBOARD_COMERCIAL_CONTACT: 'Contacto comercial',
  DASHBOARD_SERVICES_CONTACT: 'Contacto servicios',
  DASHBOARD_WHATSAPP_CONTACT: 'Haz tu consulta por whatsapp haciendo',
  DASHBOARD_WHATSAPP_CONTACT_LINK: 'click aquí',
  DASHBOARD_NEWS_PLIKA: 'Novedades Plika',
  DASHBOARD_SUPPORT_INFO: 'Información soporte',
  DASHBOARD_EBOOK_DESCRIPTION:
    'Principales tendencias FP&A que impactan en los equipos financieros',
  DASHBOARD_ARTICLE_DESCRIPTION:
    'Mantente al tanto de las tendencias en FP&A con el Blog de Plika. Aprende con un nuevo artículo cada semana.',
  DASHBOARD_METRICS_DESCRIPTION:
    'Accede a las métricas más utilizadas por responsables de Financial Planning & Analysis',
  DASHBOARD_NEW_FUNCTIONS_DESCRIPTION:
    'Visita nuestro canal de YouTube para aprender a utilizar las funcionalidades más recientes de Plika',
  DASHBOARD_GO_EBOOK_LINK: 'Ir al Ebook',
  DASHBOARD_GO_BLOG_LINK: 'Ir al Blog',
  DASHBOARD_GO_NEW_FUNCTIONS_LINK: 'Nuevas Funciones',
  DASHBOARD_GO_METRICS_LINK: 'Ir a Métricas',
};

export default dashboard;
