import i18n from 'i18next';
import { Popconfirm, Tooltip, Space } from 'antd';
import { Typography, DropdownMenu } from 'modules/core/components';
import { InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { DIMENSIONS } from 'modules/core/constants';
import _ from 'lodash';

const columns = ({
  disabled,
  onAction,
  loading,
  removeDimensionId,
  setRemoveDimensionId,
}) => [
  {
    title: i18n.t('FIELD_ORDER'),
    dataIndex: 'order',
    className: 'drag-visible',
    width: 250,
  },
  {
    title: i18n.t('FIELD_ASSOCIATED_DIMENSION'),
    dataIndex: 'dimension',
    width: 250,
  },
  {
    title: (
      <Space size="large">
        {i18n.t('FIELD_ACTIONS')}
        <Tooltip title={i18n.t('CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE')}>
          <Typography.Icon icon={InfoCircleOutlined} type="primary" />
        </Tooltip>
      </Space>
    ),
    dataIndex: 'config_id',
    align: 'right',
    width: 150,
    render: (config_id, row) =>
      row.dimension !== DIMENSIONS.ACCOUNT_LABEL ? (
        <Popconfirm
          placement="bottomRight"
          title={i18n.t('CONFIG_CONCEPT_ASSOCIATE_REMOVE_TITLE', {
            name: row.dimension,
          })}
          okText={i18n.t('ACTION_REMOVE')}
          onConfirm={() => onAction(config_id)}
          okButtonProps={{ loading: loading }}
          cancelText={i18n.t('ACTION_CANCEL')}
          onCancel={() => setRemoveDimensionId(null)}
          visible={removeDimensionId === config_id}
        >
          <DropdownMenu
            title={i18n.t('ACTION_MORE')}
            menu={_.compact([
              {
                title: i18n.t('ACTION_REMOVE'),
                icon: <CloseCircleOutlined />,
                onClick: () => {
                  setRemoveDimensionId(config_id);
                },
                disabled: disabled,
              },
            ])}
          />
        </Popconfirm>
      ) : (
        ''
      ),
  },
];

export default columns;
