import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConfigurationUploadModal } from 'modules/configuration/components';
import { FinancialTable } from './components';

//TODO: AGREGAR PERMISOS FINANCIERO
const FinancialConceptsList = ({ isTableLoading, financialConceptsValues }) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <FinancialTable
            dataSource={financialConceptsValues}
            tableLoading={isTableLoading}
          />
        </Col>
      </Row>
      <ConfigurationUploadModal
        title={t('CONFIG_CONCEPTS_UPLOAD_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        //TO DO: agregar el resto cuando este la conexion
      />
    </>
  );
};

export default FinancialConceptsList;
