const forecast = {
  // Actions
  FORECAST_CREATE_ACTION: 'Create forecast',
  FORECAST_APPROVE_MODAL_TITLE: '{{action}} forecast',

  // Fields
  FORECAST_CUT_DATE_FIELD: 'Cut month:',

  // Messages
  FORECAST_NO_DATA_ALERT_TITLE:
    "We still don't have all the data we need to create your forecast",
  FORECAST_NO_DATA_ALERT_TEXT:
    'In order for you to create a forecast we must have the budgets and the actual data published. Once you do, you can create your re-budgets.',
  FORECAST_EMPTY_STATE_TEXT:
    'Identify what is not evolving as planned and re-define your goals. Modify the future in the short and medium term to reduce risks',
  FORECAST_CREATE_TOOLTIP:
    'In order to create a new forecast, the previous one must be approved',
  FORECAST_APPROVE_MODAL_TEXT:
    'Are you ready to approve the forecast? Remember that once you do, you will no longer be able to modify any data or upload more files.',
  FORECAST_PUBLISH_MODAL_TEXT: `You're about to publish your forecast. In this instance you can edit, continue loading data, forecast compare with each other and visualize your data analysis and monitoring.`,

  FORECAST_SCREEN_SUCCESS_FEEDBACK:
    'You just {{action}} the forecast for the {{period}} period :) ',
  // Do you want to break the news to everyone who participated in this planning?',

  FORECAST_CREATE_ERROR_FEEDBACK: 'The forecast could not be created',
  FORECAST_DELETE_ERROR_FEEDBACK: 'The forecast could not be deleted',
  FORECAST_EDIT_NAME_SUCCESS_FEEDBACK:
    'The forecast name was successfully edited.',
  FORECAST_EDIT_NAME_ERROR_FEEDBACK:
    "We couldn't edit the forecast name. Please try again.",
  FORECAST_DELETE_POPCONFIRM: 'Are you sure you want to delete this forecast?',

  FORECAST_EMPTY_CARD_DESCRIPTION: 'Enter and start re-budgeting',

  FORECAST_ALERT_INFO_TITLE: 'The months with real data are not editable',
  FORECAST_ALERT_INFO_DESCRIPTION:
    'From here you will be able to work on the re-definition of your budget, so you will see editable months in which there is still no real data uploaded and published.',
  FORECAST_TOOLTIP_NOT_UPLOAD_FILE:
    'Cannot upload file because the forecast is already approved',
  FORECAST_UPLOAD_SCREEN_DESCRIPTION: 'Start loading your projection',

  // XLS names
  FORECAST_TEMPLATE_NAME:
    'Plika Template Forecast {{concept}} - {{periodName}}',
  FORECAST_EVOLUTION_TABLE_FILE_NAME:
    'Plika - Forecast {{concept}} - {{periodName}} (monthly evolution)',
  FORECAST_CONSOLIDATION_EVOLUTION_TABLE_FILE_NAME: `Plika - Forecast {{consoName}}({{reportName}}) - {{periodName}} (Consolidation)`,

  //Empty screen
  FORECAST_EMPTY_SCREEN_TITLE: 'You have any {{concept}} forecast created yet.',
  FORECAST_EMPTY_SCREEN_BODY:
    'Create a forecast, prioritizes and built the number of projections and different scenarios have',
};

export default forecast;
