import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { SelectType } from 'modules/core/components';
import { REPORT, ROLES } from 'modules/core/constants';
import { ReportList, OtherReportList } from './components';
import { isUserAllowed } from 'modules/core/utils';
import _ from 'lodash';

//TODO:REVEER PERMISOS CUANDO ESTE LO DE FINANCIERO ROLES
const { ANALYSIS__CUSTOM_REPORTS__VIEW, ANALYSIS__REPORTSPNL__VIEW } = ROLES;

const ReportsHome = () => {
  const [defaultSelectValue, setDefaultSelectValue] = useState(
    REPORT.REPORTS_KEYS.REPORTE_KEY
  );

  const canUserReportView = isUserAllowed(ANALYSIS__REPORTSPNL__VIEW);
  const canUserCustomView = isUserAllowed(ANALYSIS__CUSTOM_REPORTS__VIEW);

  const auxSelection = _.compact([
    !canUserReportView && REPORT.REPORTS_KEYS.REPORTE_KEY,
    !canUserCustomView && REPORT.REPORTS_KEYS.CUSTOM_KEY,
  ]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <SelectType.Report
          defaultSelectValue={defaultSelectValue}
          setDefaultSelectValue={setDefaultSelectValue}
          selection={auxSelection}
        />
      </Col>
      <Col span={24}>
        {defaultSelectValue === REPORT.REPORTS_KEYS.REPORTE_KEY ? (
          <ReportList />
        ) : (
          <OtherReportList />
        )}
      </Col>
    </Row>
  );
};

export default ReportsHome;
