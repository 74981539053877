import React, { useState, useEffect } from 'react';
import { Table, Button, Space } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, DollarOutlined } from '@ant-design/icons';
import {
  TableSettingsModal,
  ButtonActionBar,
  Typography,
  CellStyle,
  References,
} from 'modules/core/components';
import reports from 'modules/reports';
import login from 'modules/login';
import {
  calculateScrollX,
  formItemsHelper,
  columnPadding,
  columnsHelper,
} from 'modules/core/utils';
import { ROWS, TABLES, COLUMNS, ACCES_USERS } from 'modules/core/constants';
import i18n from 'i18next';

import './SegmentedAnalysisTable.scss';

//TODO: CUANDO SE AJUSTE EL NOMBRE DEL TOTAL AL ESTANDARIZADO, USAR LA CONSTANTE DE ARCHIVO TABLE
const TOTAL_REPORT_KEY = 'Total';

const FORMATS_TYPES = {
  '0,0': 'integer',
  '0,0.00': 'decimals',
  '0[.]0a': 'millions',
};

//TODO: EXPANDABLEROWS
const SegmentedAnalysisTable = ({
  activeBySegmentedTable,
  fetchReportSegmentedDataRow,
  fetchReportSegmentedColumns,
  reportSegmentedColumns,
  reportSegmentedDataRow,
  downloadEvolutionSegmentedXLS,
  reportId,
  periodId,
  dimensionId,
  filters,
  selectedDates,
  periodDetail,
  functionalCurrencyData,
  loggedUser: { email },
}) => {
  const [tableSettings, setTableSettings] = useState({
    cantItems: 5,
    ...TABLES.DEFAULT_SETTINGS(email),
  });
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    const endpointsParams = {
      period_id: periodId,
      dimension: dimensionId,
      amount: tableSettings.cantItems,
    };
    Promise.all([
      fetchReportSegmentedColumns(reportId, endpointsParams),
      fetchReportSegmentedDataRow(reportId, {
        ...endpointsParams,
        ...selectedDates,
        ...filters,
      }),
    ]).then(() => setIsTableLoading(false));
  }, [
    fetchReportSegmentedDataRow,
    fetchReportSegmentedColumns,
    reportId,
    periodId,
    dimensionId,
    tableSettings.cantItems,
    selectedDates,
    filters,
  ]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const BY_PERIOD_COLUMNS_MAP = () => {
    return {
      concept: {
        title: i18n.t('FIELD_CONCEPT'),
        width: COLUMNS.WIDTH.EXPANDABLE,
      },
      [TOTAL_REPORT_KEY]: {
        title: i18n.t('FIELD_TOTAL').toUpperCase(),
        width: COLUMNS.WIDTH.TRANSACTION,
      },
    };
  };

  const renderColumnsNumbers = (rowType, rowConcept, value) => (
    <CellStyle.Number
      value={value}
      format={columnsHelper.renderFormatValue({
        isRatio: rowType === ROWS.RATIO,
        concept: rowConcept,
        has_restriction,
        format: tableSettings.format,
      })}
      has_restriction={has_restriction}
    />
  );

  const columnsGenerator = columns => {
    return (
      columns && [
        ...columns
          .filter((column, index) =>
            tableSettings.visibleData
              ? !column.is_transaction ||
                tableSettings.visibleData.includes(column.data_index) ||
                column.data_index === TOTAL_REPORT_KEY
              : true
          )
          .map((column, index) => {
            return {
              key: index,
              ellipsis: true,
              dataIndex: column.data_index,
              ...(!column.is_transaction && {
                ...BY_PERIOD_COLUMNS_MAP()[column.data_index],
                fixed: 'left',
                ...(column.data_index === TOTAL_REPORT_KEY && {
                  className: COLUMNS.TYPES.TOTALIZER,
                  align: 'right',
                }),
                render: (value, row) => {
                  if (column.data_index !== TOTAL_REPORT_KEY) {
                    return columnPadding(value, row.level, row.type);
                  } else {
                    return renderColumnsNumbers(row.type, row.concept, value);
                  }
                },
              }),
              ...(column.is_transaction && {
                title: column.title,
                width: COLUMNS.WIDTH.TRANSACTION,
                align: 'right',
                render: (transaction, row) =>
                  renderColumnsNumbers(row.type, row.concept, transaction),
              }),
            };
          }),
      ]
    );
  };

  const renderTableActions = () => {
    return (
      <ButtonActionBar position="space-between">
        <References
          referencesItems={[
            {
              color: '#a73ba1',
              type: 'Ab',
              title: t('LABEL_RATIO'),
              description: t('REPORT_VALUES_RATIO'),
            },
            ...(functionalCurrencyData
              ? [
                  {
                    type: <DollarOutlined />,
                    title:
                      functionalCurrencyData && functionalCurrencyData.code,
                    description:
                      functionalCurrencyData &&
                      t('REFERENCE_DESCRIPTION', {
                        currencyName: t(
                          functionalCurrencyData.name
                        ).toLowerCase(),
                      }),
                  },
                ]
              : []),
          ]}
        />
        <Space direction="horizontal">
          <Button
            type="text"
            shape="circle"
            title={t('ACTION_DOWNLOAD_TABLE')}
            icon={<Typography.Icon icon={DownloadOutlined} />}
            onClick={() =>
              downloadEvolutionSegmentedXLS(
                reportId,
                {
                  period_id: periodId,
                  format_number: FORMATS_TYPES[tableSettings.format],
                  dimension: dimensionId,
                  ...selectedDates,
                  ...filters,
                },
                t('REPORT_EVOLUTION_SEGMENTED_ANALYSIS_FILE_NAME', {
                  periodName: periodDetail.name,
                })
              )
            }
          />
          <TableSettingsModal
            onConfirm={data => {
              // NOTA: Al cambiar cantidad de dimensiones visibles,
              // setteamos las seleccionadas en NULL para que se vean todas
              if (data.cantItems !== tableSettings.cantItems)
                setTableSettings({ ...data, visibleData: null });
              else setTableSettings(data);
            }}
            options={
              reportSegmentedColumns[activeBySegmentedTable.value] !==
                undefined &&
              reportSegmentedColumns[activeBySegmentedTable.value].filter(
                column => column && column.is_transaction
              )
            }
            settings={tableSettings}
            formItems={formItemsHelper.itemsTableView(tableSettings.cantItems)}
          />
        </Space>
      </ButtonActionBar>
    );
  };

  return (
    <>
      {!isTableLoading && renderTableActions()}
      <Table
        rowKey="id"
        size="small"
        loading={isTableLoading}
        columns={columnsGenerator(
          reportSegmentedColumns[activeBySegmentedTable.value]
        )}
        dataSource={reportSegmentedDataRow[activeBySegmentedTable.value]}
        rowClassName={record => ROWS.TYPES[record.type]}
        expandIconColumnIndex={-1} //TODO: Cuando este el expandir desde back, borrarlo.
        scroll={{
          x: calculateScrollX(
            columnsGenerator(
              reportSegmentedColumns[activeBySegmentedTable.value]
            )
          ),
          y: 400,
        }}
        pagination={false}
      />
    </>
  );
};

const mapStateToProps = state => ({
  reportSegmentedDataRow: reports.selectors.getReportSegmentedDataRow(state),
  reportSegmentedColumns: reports.selectors.getReportSegmentedColumns(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchReportSegmentedDataRow: reports.actions.fetchReportSegmentedDataRow,
  fetchReportSegmentedColumns: reports.actions.fetchReportSegmentedColumns,
  downloadEvolutionSegmentedXLS: reports.actions.downloadEvolutionSegmentedXLS,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SegmentedAnalysisTable);
