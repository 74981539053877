import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import {
  ConnectIntegrationModal,
  IntegrationsList,
} from 'modules/core/components';
import { INTEGRATIONS } from 'modules/core/constants';
import home from 'modules/home';

const Integrations = ({
  fetchIntegrationList,
  integrationList,
  integrationData,
  setIntegrationData,
  setShowScreenSuccess,
}) => {
  const [isdataLoading, setIsDataLoading] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);

  useEffect(() => {
    setIsDataLoading(true);
    fetchIntegrationList().then(() => setIsDataLoading(false));
  }, [fetchIntegrationList]);

  //NOTE: PROVISIORIO FILTRADO SOLO ODOO HASTA QUE FUNCIONE QUICKBOOK
  const auxIntegrationsList = [
    ...integrationList
      .filter(data => data.name === 'Odoo')
      .map(integration => {
        return {
          ...integration,
          type: INTEGRATIONS.KEYS.INTEGRATION_KEY,
          ...INTEGRATIONS.DATA[integration.name],
        };
      }),
  ];

  const hasIntegrationConnect = [...auxIntegrationsList].some(
    integration => integration.status
  );

  return (
    <div>
      <Spin spinning={isdataLoading} />
      {!isdataLoading && (
        <>
          <Row gutter={[16, 16]}>
            {hasIntegrationConnect && (
              <Col span={24}>
                <IntegrationsList
                  title={'INTERATION_CONNECTED_SECTION_TITLE'}
                  dataSource={auxIntegrationsList.filter(
                    integration => integration.status
                  )}
                  setIntegrationData={setIntegrationData}
                  setShowConnectModal={setShowConnectModal}
                  size="2,5"
                  onRefetchIntegrationList={() => fetchIntegrationList()}
                />
              </Col>
            )}
            {auxIntegrationsList.filter(integration => !integration.status)
              .length !== 0 && (
              <Col span={24}>
                <IntegrationsList
                  title={
                    hasIntegrationConnect
                      ? 'INTERATION_MORE_OPTIONS_SECTION_TITLE'
                      : 'INTEGRATION_OPTION_SECTION_TITLE'
                  }
                  dataSource={auxIntegrationsList.filter(
                    integration => !integration.status
                  )}
                  setIntegrationData={setIntegrationData}
                  setShowConnectModal={setShowConnectModal}
                  size="2,5"
                  onRefetchIntegrationList={() => fetchIntegrationList()}
                />
              </Col>
            )}
          </Row>
          <ConnectIntegrationModal
            visible={showConnectModal}
            onCancel={() => setShowConnectModal(false)}
            integrationData={integrationData}
            setShowScreenSuccess={() => setShowScreenSuccess(true)}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  integrationList: home.selectors.getIntegrationList(state),
});

const mapDispatchToProps = {
  fetchIntegrationList: home.actions.fetchIntegrationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
