import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePagination } from 'modules/core/customHooks';
import { Button, Table, Space, Popconfirm, message, notification } from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import {
  DropdownMenu,
  InputCell,
  DeleteAndUpdate,
  Typography,
} from 'modules/core/components';
import _ from 'lodash';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';

const { SETTINGS__SECURITY__ROLES__EDIT, SETTINGS__SECURITY__ROLES__DELETE } =
  ROLES;

const RolesTable = ({
  editRoleOperations,
  deleteRole,
  editRole,
  onDuplicateRole,
  dataSource,
  reFetchTable,
  setShowRoleOperationsDrawer,
  tableLoading,
  count,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [deleteRoleId, setDeleteRoleId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [tablePagination, setTablePagination] = usePagination();
  const { t } = useTranslation();

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });
  };

  const onConfirm = () => {
    setEditRow(null);
    setDataCellEdit({});
  };

  const onCancel = () => setEditRow(null);

  const onEditRole = ({ name, code }) => {
    if (!_.isEmpty(dataCellEdit)) {
      setIsEditing(true);
      setIsTableLoading(true);
      editRole({ id: editRow, name, code, ...dataCellEdit })
        .then(() => {
          setIsEditing(false);
          reFetchTable();
          setIsTableLoading(false);
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          setEditRow(null);
          setDataCellEdit({});
        })
        .catch(error => {
          setIsEditing(false);
          setIsTableLoading(false);
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: error.response.data,
            duration: 0,
          });
          setEditRow(null);
        });
    }
  };

  const onDeleteRole = id => {
    setIsDeleting(true);
    setIsTableLoading(true);
    deleteRole(id)
      .then(() => {
        setIsDeleting(false);
        reFetchTable();
        setIsTableLoading(false);
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
        setDeleteRoleId(null);
      })
      .catch(error => {
        setIsDeleting(false);
        setIsTableLoading(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error.response.data,
          duration: 0,
        });
        setDeleteRoleId(null);
      });
  };

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      width: '50%',
      editable: true,
      render: (name, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={name}
              name="name"
              onCellUpdate={onCellEdit}
            />
          );
        } else {
          return name;
        }
      },
    },
    {
      title: t('FIELD_CODE'),
      dataIndex: 'code',
      width: '25%',
      editable: true,
      render: (code, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={code}
              name="code"
              onCellUpdate={onCellEdit}
            />
          );
        } else {
          return code;
        }
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id, row) => {
        return id !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_ROLES_POPCONFIRM_DELETE_ROLE_TITLE')}
            okText={t('ACTION_DELETE')}
            onConfirm={() => onDeleteRole(id)}
            okbuttonprops={{ loading: isDeleting }}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteRoleId(null)}
            visible={deleteRoleId === id}
          >
            <Space size="middle">
              <Button
                type="text"
                shape="circle"
                icon={<Typography.Icon icon={EyeOutlined} />}
                title={t('CONFIG_ROLES_VIEW_TITLE')}
                onClick={() => {
                  document.body.style.overflow = 'hidden';
                  editRoleOperations({
                    checked: true,
                    operationsKeys: row.operations_asign.map(el => el.code),
                  });
                  setShowRoleOperationsDrawer({
                    roleId: id,
                    name: row.name,
                    visible: true,
                  });
                }}
              />
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => setEditRow(id),
                    disabled: !isUserAllowed(SETTINGS__SECURITY__ROLES__EDIT),
                  },
                  {
                    title: t('ACTION_DUPLICATE'),
                    icon: <CopyOutlined />,
                    onClick: () =>
                      onDuplicateRole({
                        id_rol: id,
                        name: row.name,
                        code: row.code,
                      }),
                  },
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteRoleId(id),
                    disabled: !isUserAllowed(SETTINGS__SECURITY__ROLES__DELETE),
                  },
                ]}
              />
            </Space>
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() => onEditRole(row)}
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        bordered
        size="small"
        loading={tableLoading || isTableLoading}
        dataSource={dataSource}
        columns={columns}
        onChange={setTablePagination}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total: count,
          size: 'small',
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true,
        }}
      />
    </>
  );
};

const mapDispatchToProps = {
  editRoleOperations: configuration.actions.editRoleOperations,
  deleteRole: configuration.actions.deleteRole,
  editRole: configuration.actions.editRole,
};

export default connect(null, mapDispatchToProps)(RolesTable);
