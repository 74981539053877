import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { SelectType } from 'modules/core/components';
import { REPORT, ROLES } from 'modules/core/constants';
import { FinancialReportsList, FinancialOtherReportsList } from './components';
import { isUserAllowed } from 'modules/core/utils';
import _ from 'lodash';

//NOTE: USAMOS LOS PERMISOS DE REPORTE COMUN HASTA QUE ESTEN LOS FINANCIEROS
const {
  SETTINGS__REPORTS__REPORTSPNL__VIEW,
  SETTINGS__REPORTS__CUSTOM_REPORTS__VIEW,
} = ROLES;

//TODO: AGREGAR PERMISOS DE REPORTE CUSTOM CUANDO ESTE PARA FINANCIERO
const ReportsHome = ({ fnlReportsData, reportsCount, isScreenLoading }) => {
  const [defaultSelectValue, setDefaultSelectValue] = useState(
    REPORT.REPORTS_KEYS.REPORTE_KEY
  );

  const canUserReportView = isUserAllowed(SETTINGS__REPORTS__REPORTSPNL__VIEW);
  const canUserCustomView = isUserAllowed(
    SETTINGS__REPORTS__CUSTOM_REPORTS__VIEW
  );

  const auxSelection = _.compact([
    !canUserReportView && REPORT.REPORTS_KEYS.REPORTE_KEY,
    !canUserCustomView && REPORT.REPORTS_KEYS.CUSTOM_KEY,
  ]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <SelectType.Report
          defaultSelectValue={defaultSelectValue}
          setDefaultSelectValue={setDefaultSelectValue}
          selection={auxSelection}
        />
      </Col>
      <Col span={24}>
        {defaultSelectValue === REPORT.REPORTS_KEYS.REPORTE_KEY ? (
          <FinancialReportsList
            fnlReportsData={fnlReportsData}
            reportsCount={reportsCount}
            isScreenLoading={isScreenLoading}
          />
        ) : (
          <FinancialOtherReportsList fnlReportsData={fnlReportsData} />
        )}
      </Col>
    </Row>
  );
};

export default ReportsHome;
