import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Row, Col } from 'antd';
import { Navigator } from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { userCanAcces } from 'modules/core/utils';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'modules/core/customHooks';
import EconomicReportsHome from '../EconomicReports/ReportsHome/ReportsHome';
import FinancialReportsHome from '../FinancialReports/ReportsHome/ReportsHome';
import {
  ACCES_USERS,
  CONCEPTS,
  REPORT,
  INTEGRATIONS,
} from 'modules/core/constants';
import reports from 'modules/reports';
import login from 'modules/login';

import './ReportsTypeHome.scss';

//TODO: AGREGAR PERMISOS FINANCIEROS-ECONOMICOS
const ReportsTypeHome = ({
  fetchReportList,
  reportList: { reportData, count },
  loggedUser: { email },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const { t } = useTranslation();
  let query = useQuery();

  useEffect(() => {
    setIsScreenLoading(true);
    fetchReportList({ flat: 'True' }).then(() => setIsScreenLoading(false));
  }, [fetchReportList]);

  const pnlReportsData = reportData.filter(
    rep => rep.type === REPORT.REPORTS_KEYS.PNL_KEY
  );

  const fnlReportsData = reportData.filter(
    rep => rep.type === REPORT.REPORTS_KEYS.FNL_KEY
  );

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        { name: t('LABEL_REPORTS') },
      ]}
    />
  );

  return (
    <SiderLayout>
      <div className="configuration-report-type-home-container">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Col span={24}>
            <Tabs
              size="small"
              defaultActiveKey={
                query.get('tab') !== null
                  ? query.get('tab')
                  : CONCEPTS.TYPE.ECONOMIC_KEY
              }
            >
              <Tabs.TabPane
                tab={t('LABEL_ECONOMIC')}
                key={CONCEPTS.TYPE.ECONOMIC_KEY}
              >
                <EconomicReportsHome
                  pnlReportsData={pnlReportsData}
                  reportsCount={count}
                  isScreenLoading={isScreenLoading}
                />
              </Tabs.TabPane>
              {userCanAcces({
                patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
                email,
              }) && (
                <Tabs.TabPane
                  tab={t('LABEL_FINANCIAL')}
                  key={CONCEPTS.TYPE.FINANCIAL_KEY}
                >
                  <FinancialReportsHome
                    fnlReportsData={fnlReportsData}
                    reportsCount={count}
                    isScreenLoading={isScreenLoading}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </div>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  reportList: reports.selectors.getReportList(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchReportList: reports.actions.fetchReportList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTypeHome);
