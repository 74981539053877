import { useState } from 'react';

// A custom hook that manage Antd table pagination
function usePagination({ pageSize = 10, totalizerRows = 0 } = {}) {
  const [pagination, setPagination] = useState({
    page_size: pageSize,
    page: 1,
  });

  function handlePagination({ current, pageSize }) {
    setPagination(prevPagination => {
      const newPagination = { ...prevPagination, page: current };
      // NOTE: Check if page size has changed or just change page
      if (pageSize - totalizerRows === prevPagination.page_size) {
        return newPagination;
      }
      return {
        ...newPagination,
        ...(pageSize && { page_size: pageSize }),
      };
    });
  }

  return [pagination, handlePagination, totalizerRows];
}

export default usePagination;
