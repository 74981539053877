export const NAME = 'real';

export const FETCH_REAL_COLUMNS = `${NAME}/FETCH_REAL_COLUMNS`;
export const FETCH_REAL_COLUMNS_SUCCESS = `${NAME}/FETCH_REAL_COLUMNS_SUCCESS`;
export const FETCH_REAL_COLUMNS_FAIL = `${NAME}/FETCH_REAL_COLUMNS_FAIL`;

export const FETCH_REAL_ROWS = `${NAME}/FETCH_REAL_ROWS`;
export const FETCH_REAL_ROWS_SUCCESS = `${NAME}/FETCH_REAL_ROWS_SUCCESS`;
export const FETCH_REAL_ROWS_FAIL = `${NAME}/FETCH_REAL_ROWS_FAIL`;

export const FETCH_CONTROL_DETAIL_KPIS = `${NAME}/FETCH_CONTROL_DETAIL_KPIS`;
export const FETCH_CONTROL_DETAIL_KPIS_SUCCESS = `${NAME}/FETCH_CONTROL_DETAIL_KPIS_SUCCESS`;
export const FETCH_CONTROL_DETAIL_KPIS_FAIL = `${NAME}/FETCH_CONTROL_DETAIL_KPIS_FAIL`;

export const DOWNLOAD_PROJECTION_XLS = `${NAME}/DOWNLOAD_PROJECTION_XLS,`;
export const DOWNLOAD_PROJECTION_XLS_SUCCESS = `${NAME}/DOWNLOAD_PROJECTION_XLS_SUCCESS`;
export const DOWNLOAD_PROJECTION_XLS_FAIL = `${NAME}/DOWNLOAD_PROJECTION_XLS_FAIL`;

export const PUBLISH_PROJECTION = `${NAME}/PUBLISH_PROJECTION`;
export const PUBLISH_PROJECTION_SUCCESS = `${NAME}/PUBLISH_PROJECTION_SUCCESS`;
export const PUBLISH_PROJECTION_FAIL = `${NAME}/PUBLISH_PROJECTION_FAIL`;

export const FETCH_MONTHS_TO_PUBLISH = `${NAME}/FETCH_MONTHS_TO_PUBLISH`;
export const FETCH_MONTHS_TO_PUBLISH_SUCCESS = `${NAME}/FETCH_MONTHS_TO_PUBLISH_SUCCESS`;
export const FETCH_MONTHS_TO_PUBLISH_FAIL = `${NAME}/FETCH_MONTHS_TO_PUBLISH_FAIL`;

export const FETCH_MONTHS_TO_APPROVE = `${NAME}/FETCH_MONTHS_TO_APPROVE`;
export const FETCH_MONTHS_TO_APPROVE_SUCCESS = `${NAME}/FETCH_MONTHS_TO_APPROVE_SUCCESS`;
export const FETCH_MONTHS_TO_APPROVE_FAIL = `${NAME}/FETCH_MONTHS_TO_APPROVE_FAIL`;

export const FETCH_PUBLISHED_MONTHS = `${NAME}/FETCH_PUBLISHED_MONTHS`;
export const FETCH_PUBLISHED_MONTHS_SUCCESS = `${NAME}/FETCH_PUBLISHED_MONTHS_SUCCESS`;
export const FETCH_PUBLISHED_MONTHS_FAIL = `${NAME}/FETCH_PUBLISHED_MONTHS_FAIL`;

export const PUBLISH_MONTHS = `${NAME}/PUBLISH_MONTHS`;
export const PUBLISH_MONTHS_SUCCESS = `${NAME}/PUBLISH_MONTHS_SUCCESS`;
export const PUBLISH_MONTHS_FAIL = `${NAME}/PUBLISH_MONTHS_FAIL`;

export const APPROVE_MONTHS = `${NAME}/APPROVE_MONTHS`;

export const DOWNLOAD_COMPARATION_XLS = `${NAME}/DOWNLOAD_COMPARATION_XLS`;
export const DOWNLOAD_COMPARATION_XLS_SUCCESS = `${NAME}/DOWNLOAD_COMPARATION_XLS_SUCCESS`;
export const DOWNLOAD_COMPARATION_XLS_FAIL = `${NAME}/DOWNLOAD_COMPARATION_XLS_FAIL`;

export const FETCH_APPROVE_DATES = `${NAME}/FETCH_APPROVE_DATES`;
export const FETCH_APPROVE_DATES_SUCCESS = `${NAME}/FETCH_APPROVE_DATES_SUCCESS`;
export const FETCH_APPROVE_DATES_FAIL = `${NAME}/FETCH_APPROVE_DATES_FAIL`;
