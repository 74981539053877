const distributions = {
  DISTRIBUTION_LABEL: 'Distributions',
  DISTRIBUTION_NO_DATA_ALERT_TITLE:
    "We still don't have all the data we need so you can create your distributions",
  DISTRIBUTION_NO_DATA_ALERT_TEXT:
    'In order for you to create distributions we must have real data approved. Once you do, you can create all the distributions that you consider necessary.',
  DISTRIBUTION_EMPTY_STATE_TITLE: 'Create your first distribution!',
  DISTRIBUTION_EMPTY_STATE_TEXT:
    'Make records that allow you to correctly distribute and allocate both your expenses and your sales',
  DISTRIBUTION_CREATE_ACTION: 'Create distribution',
  DISTIBUTION_CREATE_MODAL_TITLE: 'Distribution creation',
  DISTIBUTION_ORIGIN_FIELD: 'Source data',
  DISTRIBUTION_TO_FIELD: 'Destination data',
  DISTIBUTION_DIMENSION_TO_FIELD: 'Destination dimension',
  DISTRIBUTION_ACCOUNT_FIELD: 'Accounting account',
  DISTRIBUTION_SELECT_DATE_LABEL: 'Year / month to distribute',
  DISTRIBUTION_DATE_FIELD: 'Year / month distributed',
  DISTIBUTION_UPLOAD_DATA_ALERT:
    'To load the destination data we need you to first load the source data',
  DISTIBUTION_UPLOAD_ACTION: 'Load distribution',
  DISTRIBUTION_DOWNLOAD_ACTION: 'Download distribution',
  DISTRIBUTION_EMPTY_VALUES_TEXT:
    'Here you will see all the dimension values that you choose to make your distribution',
  DISTRIBUTION_DETAIL_TITLE: 'Distribution detail',
  DISTRIBUTION_CANCELED_DETAIL_TITLE: 'Detail of canceled distribution',
  DISTRIBUTION_CANCEL_MODAL_TITLE: 'Distribution cancellation confirmation',
  DISTRIBUTION_CANCEL_MODAL_TEXT:
    '{{userName}}, You are about to cancel a distribution in the account {{account}} in {{añoMes}}. Remember that once you do it, you cannot undo it.',
  DISTRIBUTION_CREATE_SUCCESS_FEEDBACK_TITLE: 'Registered distribution',
  DISTRIBUTION_CANCEL_SUCCESS_FEEDBACK_TITLE:
    'Great! The cancellation was recorded.',
  DISTRIBUTION_SUCCESS_FEEDBACK_DESCRIPTION:
    'You can already see the impact of it in monitoring or analysis.',

  DISTRIBUTION_EMPTY_STATE_APPROVE_DATA_TOOLTIP: 'No actual data approved',

  DISTRIBUTION_VIEW_ACTION_TITLE: 'See Distribution',
  DISTRIBUTION_CANCEL_CONFIRM_FAIL_FEEDBACK_DESCRIPTION:
    '{{userName}}, we were unable to abort.',
  DISTRIBUTION_CREATE_FAIL_FEEDBACK_DESRIPTION:
    'The distribution could not be created.',
  DISTRIBUTION_NEW_CREATE_ACTION: 'Create new distribution',
  DISTRIBUTION_LOAD_SUCCESS_FEEDBACK_TITLE:
    'The distribution was loaded successfully',
  DISTRIBUTION_LOAD_SUCCESS_FEEDBACK_DISTRIBUTION: 'You can work with ....',
  DISTRIBUTION_NOT_AMOUNT_FAIL_FEEDBACK: 'There is no amount to distribute',
  DISTRIBUTION_SELECT_DIMENSION_ACTION: 'Please select a destination dimension',
  DISTRIBUTION_PERCENTAGE_INFO_TOOLTIP:
    'Remember that the sum of the percentages must give 100%',

  DISTRIBUTION_DATA_TABLE_FILE_NAME:
    'Plika - Distributions - {{periodName}} (list)',
  DISTRIBUTION_DATA_ROW_FILE_NAME: 'Plika - Distribution - {{periodName}}',
  DISTRIBUTION_TEMPLATE_NAME: 'Plika Template - Distribution',
  DISTRIBUTION_DEFAULT_FILE_NAME: 'Plika - Distributions  (list)',
  DISTRIBUTION_SOURCE_DATA_TEMPLATE:
    'Plika Template- Distribution Source data.xlsx',
  DISTRIBUTION_ADD_NEW_COMBINATION_ACTION: 'Add new combination',
};

export default distributions;
