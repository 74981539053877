import Immutable from 'seamless-immutable';
import * as types from './constants';
import { updateCommentInReducer } from 'modules/core/utils';
import { DIMENSIONS, COLUMNS } from 'modules/core/constants';
import _ from 'lodash';

const initialState = Immutable({
  periods: [],
  periodDetail: {},
  conceptsList: {
    count: 0,
    values: [],
  },
  conceptsDetail: {
    name: '',
    count: 0,
    values: [],
  },
  projections: [],
  projectionDetail: {},
  projectionColumns: [],
  projectionRows: {
    transactions: [],
    count: 0,
  },
  projectionAmountsRows: {
    transactions: [],
    count: 0,
  },
  projectionKPIS: [],
  projectionAmountsKPIS: [],
  projectionDeadline: {
    date: null,
    time: null,
  },
  userTracks: {
    values: [],
    count: 0,
  },
  fileTracks: {
    values: [],
    count: 0,
  },
  filterDimensions: [],
  publishedDates: [],
  publishedAccounts: [],
  consolidationList: [],
  consolidationKPIS: [],
  formulasAmountList: [],
  factorColumns: [],
  factorRows: {
    transactions: [],
    count: 0,
  },
  dimensionsValuesList: [],
  exchangeRateList: [],
  monthsList: [],
  currenciesTracks: [],
  dashboardUrl: {},
  dashboardDatasources: {},
  pxq: [],
});

const addNewNode = (nodes, nodeId, newNode, dates) => {
  let newNodes = [];
  const auxPeriod = _.split(dates[0], '-', 3)[0];
  nodes.forEach(node => {
    if (node.id === nodeId) {
      node = {
        ...node,
        children:
          newNode &&
          newNode.map(newNodeAux => {
            // NOTE: _.PICK() SE HIZO PARA QUE EN LOS HIJOS NO SE COPIE EL AMOUNT DE LOS MESES DONDE NO VIENE TIPO DE CAMBIO. SE ELIMINANDO DEL OBJETO LOS MESES QUE NO TIENEN TIPO DE CAMBIO.
            // NOTE: VER SI HAY ALGUNA MEJOR SOLUCION
            const keys = _.keys(node).filter(key => !key.includes(auxPeriod));
            return {
              ..._.pick(node, [
                ...keys,
                _.difference(
                  _.keys(node).filter(key => key.includes(auxPeriod)),
                  dates
                ).length !== 0 && [],
              ]),
              ...newNodeAux,
              [DIMENSIONS.ACCOUNT_LABEL]: newNodeAux.currency,
              id: newNodeAux.id,
              children: null,
              [COLUMNS.TOTAL_DATA_INDEX]: 0,
              parent_id: nodeId,
              endDate: dates[dates.length - 1],
            };
          }),
      };
    }
    if (node.id !== nodeId) {
      node = {
        ...node,
      };
    }
    newNodes.push(node);
  });
  return newNodes;
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let data;
  switch (action.type) {
    case types.FETCH_USER_TRACKS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        userTracks: {
          values: data.results,
          count: data.count,
        },
      });
    case types.FETCH_FILE_TRACKS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        fileTracks: {
          values: data,
          count: data.length,
        },
      });
    case types.FETCH_PERIODS_SUCCESS:
      data = action.payload.data.results;
      return state.merge({
        periods: data,
      });
    case types.FETCH_PERIOD_DETAIL_SUCCESS:
      data = action.payload.data;
      return state.merge({
        periodDetail: data,
      });
    case types.FETCH_CONCEPTS_LIST_SUCCESS:
      return state.merge({
        conceptsList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      });
    case types.FETCH_CONCEPTS_DETAIL_SUCCESS:
      data = action.payload.data;
      return state.merge({
        conceptsDetail: {
          name: data.name,
          count: data.count,
          values: data.configs,
        },
      });
    case types.FETCH_PROJECTIONS_SUCCESS:
      data = action.payload.data.results;
      return state.merge({
        projections: data,
      });
    case types.FETCH_PROJECTION_DETAIL_SUCCESS:
      data = action.payload.data;
      return state.merge({
        projectionDetail: data,
      });
    case types.FETCH_PROJECTION_COLUMNS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        projectionColumns: data.columns,
      });
    case types.FETCH_PROJECTION_ROWS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        projectionRows: {
          lastModificationDate: data.results.last_modification,
          transactions: data.results.rows,
          count: data.count,
        },
      });
    case types.FETCH_PROJECTION_AMOUNTS_ROWS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        projectionAmountsRows: {
          transactions: data.results.rows.map(row => {
            return { ...row, children: row.has_origin_rows ? [] : null };
          }),
          count: data.count,
        },
      });
    case types.FETCH_PROJECTION_KPIS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        projectionKPIS: data.kpis,
      });
    case types.FETCH_PROJECTION_AMOUNTS_KPIS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        projectionAmountsKPIS: data.kpis,
      });
    case types.FETCH_PROJECTION_FILTERS_SUCCESS:
      return state.merge({
        filterDimensions: action.payload.data.filters,
      });

    case types.FETCH_PUBLISHED_ACCOUNTS_SUCCESS:
      return state.merge({
        publishedAccounts: action.payload.data.values,
      });

    case types.CLEAN_PUBLISHED_DATES:
      return state.merge({
        publishedDates: [],
        publishedAccounts: [],
      });

    case types.FETCH_CONSOLIDATION_LIST_SUCCESS:
      return state.merge({
        consolidationList: action.payload.data,
      });

    case types.FETCH_UPLOAD_LIMIT_DATE_SUCCESS:
      return state.merge({
        projectionDeadline: action.payload.data.results[0],
      });

    case types.FETCH_CONSOLIDATION_DETAIL_KPI_SUCCESS:
      data = action.payload.data;
      return state.merge({
        consolidationKPIS: data.kpis,
      });

    case types.FETCH_FORMULAS_AMOUNT_LIST_SUCCESS:
      data = action.payload.data;
      return state.merge({
        formulasAmountList: data,
      });

    case types.FETCH_FACTOR_ROWS_SUCCESS:
      return state.merge({
        factorRows: {
          transactions: action.payload.data.results.rows,
          count: action.payload.data.count,
        },
      });

    case types.FETCH_FACTOR_COLUMNS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        factorColumns: data.columns ? data.columns : [],
      });
    case types.FETCH_DIMENSIONS_VALUES_LIST_SUCCESS:
      data = action.payload.data;
      return state.merge({
        dimensionsValuesList: data,
      });

    case types.SET_DEFAULT_BUDGET_SUCCESS:
      data = action.payload.data;
      return state.merge({
        periodDetail: {
          ...state.periodDetail,
          ...(data.type === 'consolidated'
            ? {
                conso_budget_predef: {
                  ...state.periodDetail.conso_budget_predef,
                  id: data.id,
                  name: data.name,
                },
              }
            : {
                conso_forecast_predef: {
                  ...state.periodDetail.conso_forecast_predef,
                  id: data.id,
                  name: data.name,
                },
              }),
        },
      });

    case types.SYNC_CREATE_PROJECTION_COMMENT: {
      const { reducerKey, ...data } = action.payload.data;
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        })
      );
    }

    case types.SYNC_DELETE_PROJECTION_COMMENT: {
      const { reducerKey, ...data } = action.payload.data;
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        })
      );
    }

    case types.FETCH_EXCHANGE_RATE_LIST_SUCCESS:
      return state.merge({
        exchangeRateList: action.payload.data.results.map(exchangeRate => {
          return {
            ...exchangeRate,
            originAmounts: exchangeRate.exr_by_month,
          };
        }),
      });

    case types.FETCH_MONTHS_LIST_SUCCESS:
      return state.merge({
        monthsList: action.payload.data,
      });

    case types.SET_EXCHANGE_RATE_VALUES: {
      const { exchangeRateId, ...months } = action.payload;
      return state.merge({
        exchangeRateList: state.exchangeRateList.map(exchangeRate => {
          if (exchangeRate.id === exchangeRateId) {
            return {
              ...exchangeRate,
              exr_by_month: {
                ...exchangeRate.exr_by_month,
                ...months,
              },
            };
          }
          return exchangeRate;
        }),
      });
    }
    case types.FETCH_CURRENCIES_TRACKS_SUCCESS:
      return state.merge({
        currenciesTracks: action.payload.data,
      });

    case types.FETCH_ORIGIN_ROWS_SUCCESS:
      let auxDateKeys = [];
      const originsRows = action.payload.data.origin_rows;
      originsRows.forEach(el => {
        auxDateKeys = [
          ...new Set([
            ...auxDateKeys,
            ..._.keys(el).filter(key => key !== 'currency' && key !== 'id'),
          ]),
        ];
      });
      const auxOriginsRows = originsRows.map(row => {
        const dataDateOriginRows = {};

        auxDateKeys.forEach(key =>
          Object.assign(dataDateOriginRows, {
            [key.slice(0, -3)]: {
              ...row[key],
              amount: row[key] ? row[key].amount : 0,
            },
          })
        );
        return {
          currency: row.currency,
          id: row.id,
          ...dataDateOriginRows,
        };
      });
      const rowId =
        action.payload.config.reduxSourceAction.payload.request.data;
      return state.merge({
        projectionAmountsRows: {
          ...state.projectionAmountsRows,
          transactions: addNewNode(
            state.projectionAmountsRows.transactions.asMutable({ deep: true }),
            rowId,
            auxOriginsRows,
            auxDateKeys.sort()
          ),
        },
      });

    case types.FETCH_DASHBOARD_URL_SUCCESS:
      return state.merge({
        dashboardUrl: action.payload.data,
      });

    case types.FETCH_DASHBOARD_DATASOURCES_SUCCESS:
      return state.merge({
        dashboardDatasources: action.payload.data,
      });

    case types.FETCH_PXQ_SUCCESS:
      return state.merge({
        pxq: action.payload.data.results,
      });

    case types.SET_DATA_UPDATE_CELL_PXQ:
      const { amount, date, p, q, row_id } = action.payload;
      return state.merge({
        projectionAmountsRows: {
          transactions: state.projectionAmountsRows.transactions.map(row => {
            if (row.id === row_id) {
              return {
                ...row,
                [date.slice(0, -3)]: {
                  ...row[date.slice(0, -3)],
                  amount,
                  p,
                  q,
                },
              };
            }
            return row;
          }),
          count: state.projectionAmountsRows.count,
        },
      });

    default:
      return state;
  }
};
