import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { usePagination } from 'modules/core/customHooks';
import planning from 'modules/planning';

const CurrencyTrackingDetail = ({
  fetchCurrenciesTracks,
  currenciesTracks,
  projectionDetail,
}) => {
  const [tablePagination, setTablePagination] = usePagination();
  const [isTrackingLoading, setIsTrackingLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setIsTrackingLoading(true);
    fetchCurrenciesTracks({ projection: projectionDetail.id }).then(() =>
      setIsTrackingLoading(false)
    );
  }, [fetchCurrenciesTracks, projectionDetail.id]);

  const columns = [
    {
      title: t('FIELD_DATE'),
      dataIndex: 'creation_date',
      render: value =>
        `${moment(value).format('DD/MM/YYYY')} ${t('DATE_AT_TIME')} ${moment(
          value
        ).format('HH:mm')}`,
    },
    {
      title: t('LABEL_USER'),
      dataIndex: 'user',
    },
    {
      title: t('FIELD_ACTION'),
      dataIndex: 'action',
    },
    {
      title: t('FILED_ORIGIN_CURRENCY'),
      dataIndex: 'exchange_rate',
      render: record => record.origin_currency.code,
    },
    {
      title: t('FIELD_FUNCTIONAL_CURRENCY'),
      dataIndex: 'exchange_rate',
      render: record => record.functional_currency.code,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={currenciesTracks?.results}
      rowKey="id"
      bordered
      size="small"
      loading={isTrackingLoading}
      onChange={setTablePagination}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: currenciesTracks.count,
        size: 'small',
      }}
    />
  );
};

const mapStateToProps = state => ({
  currenciesTracks: planning.selectors.getCurrenciesTracks(state),
});

const mapDispatchToProps = {
  fetchCurrenciesTracks: planning.actions.fetchCurrenciesTracks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencyTrackingDetail);
