import _ from 'lodash';

const SALES = 'SALES';
const SALES_LABEL = 'Ventas';

const EXPENSES = 'EXPENSES';
const EXPENSES_LABEL = 'Gastos';

const EXTRAORDINARY_INCOME = 'EXTRAORDINARY_INCOME';
const EXTRAORDINARY_INCOME_LABEL = 'Ingresos Extraordinarios';

const EXTRAORDINARY_EXPENSES = 'EXTRAORDINARY_EXPENSES';
const EXTRAORDINARY_EXPENSES_LABEL = 'Egresos Extraordinarios';

const OPERATING_INCOME = 'OPERATING_INCOME';
const OPERATING_INCOME_LABEL = 'Ingresos Operativos';

const OPERATING_EXPENSES = 'OPERATING_EXPENSES';
const OPERATING_EXPENSES_LABEL = 'Egresos Operativos';

const ECONOMIC_KEY = 'economic';
const ECONOMIC_ID = 'economico';

const FINANCIAL_KEY = 'financial';
const FINANCIAL_ID = 'financiero';

const TYPE_LIST = [
  {
    id: ECONOMIC_KEY,
    name: 'LABEL_ECONOMIC',
  },
  {
    id: FINANCIAL_KEY,
    name: 'LABEL_FINANCIAL',
  },
];

const KEYS = {
  [SALES]: SALES_LABEL,
  [EXPENSES]: EXPENSES_LABEL,
  [EXTRAORDINARY_INCOME]: EXTRAORDINARY_INCOME_LABEL,
  [EXTRAORDINARY_EXPENSES]: EXTRAORDINARY_EXPENSES_LABEL,
  [OPERATING_INCOME]: OPERATING_INCOME_LABEL,
  [OPERATING_EXPENSES]: OPERATING_EXPENSES_LABEL,
};

const KEYS_REVERSE = _.invert(KEYS);

const TYPE = {
  ECONOMIC_KEY: ECONOMIC_KEY,
  ECONOMIC_ID: ECONOMIC_ID,
  FINANCIAL_KEY: FINANCIAL_KEY,
  FINANCIAL_ID: FINANCIAL_ID,
};

const CONCEPTS = { KEYS, KEYS_REVERSE, TYPE, TYPE_LIST };

export default CONCEPTS;
