import React, { useEffect, useState } from 'react';
import { Tabs, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useQuery } from 'modules/core/customHooks';
import { Navigator } from 'modules/core/components';
import { userCanAcces } from 'modules/core/utils';
import { SiderLayout } from 'modules/core/layouts';
import { ConceptsList } from '../EconomicConcepts';
import { FinancialConceptsList } from '../FinancialConcepts';
import { ACCES_USERS, CONCEPTS, INTEGRATIONS } from 'modules/core/constants';
import planning from 'modules/planning';
import login from 'modules/login';

const ConceptsHome = ({
  fetchConceptsList,
  conceptsList,
  loggedUser: { email },
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const { t } = useTranslation();
  let query = useQuery();

  useEffect(() => {
    fetchConceptsList().then(() => setIsTableLoading(false));
  }, [fetchConceptsList]);

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        { name: t('LABEL_CONCEPTS') },
      ]}
    />
  );

  const renderConceptTabs = () => (
    <Tabs
      size="small"
      defaultActiveKey={
        query.get('tab') !== null
          ? query.get('tab')
          : CONCEPTS.TYPE.ECONOMIC_KEY
      }
    >
      <Tabs.TabPane tab={t('LABEL_ECONOMIC')} key={CONCEPTS.TYPE.ECONOMIC_KEY}>
        <ConceptsList
          isTableLoading={isTableLoading}
          economicConceptsValues={conceptsList.values.filter(
            concept => concept.type === CONCEPTS.TYPE.ECONOMIC_KEY
          )}
          onFetchConceptsList={() => fetchConceptsList()}
        />
      </Tabs.TabPane>
      {userCanAcces({
        patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
        email,
      }) && (
        <Tabs.TabPane
          tab={t('LABEL_FINANCIAL')}
          key={CONCEPTS.TYPE.FINANCIAL_KEY}
        >
          <FinancialConceptsList
            isTableLoading={isTableLoading}
            financialConceptsValues={conceptsList.values.filter(
              concept => concept.type === CONCEPTS.TYPE.FINANCIAL_KEY
            )}
          />
        </Tabs.TabPane>
      )}
    </Tabs>
  );

  return (
    <SiderLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{renderConceptTabs()}</Col>
      </Row>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  conceptsList: planning.selectors.getConceptsList(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchConceptsList: planning.actions.fetchConceptsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConceptsHome);
