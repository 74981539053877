import * as types from './constants';
import i18n from 'i18next';
import { saveAs } from 'file-saver';
import queryString from 'query-string';
import { GA } from 'modules/core/utils';

export const fetchRealColumns =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REAL_COLUMNS,
      payload: {
        request: {
          url: `/planning/periods/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchRealRows =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REAL_ROWS,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/rows/`,
          method: 'GET',
          params: { page_size: 10, page: 1, ...params },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchControlDetailKPIs =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CONTROL_DETAIL_KPIS,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/kpis/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response.payload.data.kpis;
  };

// ++XLS++

export const downloadProjectionXLS =
  (projectionId, filename = i18n.t('CONSOLIDATION_FILE_NAME'), params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_PROJECTION_XLS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/download-consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params: { ...params },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

// ++PUBLISH BUTTON ON PROJECTION DETAIL++
export const publishProjection = id => async dispatch => {
  const response = await dispatch({
    type: types.PUBLISH_PROJECTION,
    payload: {
      request: {
        url: `/planning/projections/${id}/publish/`,
        method: 'PATCH',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return Promise.resolve();
};

//Listado meses cargados para publicar
export const fetchMonthsToPublish =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_MONTHS_TO_PUBLISH,
      payload: {
        request: {
          url: `/planning/periods/${id}/months_to_publish/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

//Listado meses publicados para aprobar
export const fetchMonthsToApprove =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_MONTHS_TO_APPROVE,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/published-months/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

//Accion publicar meses
export const publishMonth =
  (id, data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.PUBLISH_MONTHS,
      payload: {
        request: {
          url: `/planning/periods/${id}/publish/`,
          method: 'PATCH',
          data,
          params,
        },
      },
    });
    if (response.error) {
      // GA.event(GA.ACTIONS.PUBLISH_DATA_REAL_ERROR)
      return Promise.reject(response.error);
    }
    GA.event(GA.ACTIONS.PUBLISH_DATA_REAL);
    return response;
  };

export const approveMonth =
  (id, data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.APPROVE_MONTHS,
      payload: {
        request: {
          url: `/planning/periods/${id}/approve/`,
          method: 'PATCH',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const downloadComparationXLS =
  (periodId, filename = i18n.t('CONTROL_DEFAULT_FILE_NAME'), params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_COMPARATION_XLS,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/download_projection/`,
          method: 'GET',
          responseType: 'blob',
          params: { ...params },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

//listado meses aprobados
export const fetchApproveDates =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_APPROVE_DATES,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/follow-months/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };
