import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePagination } from 'modules/core/customHooks';
import {
  Input,
  Col,
  Row,
  Space,
  Button,
  message,
  Card,
  Spin,
  Popconfirm,
  notification,
} from 'antd';
import {
  DownloadOutlined,
  FileExcelOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  Navigator,
  Typography,
  EmptyScreen,
  DropdownMenu,
  KPIValue,
  DeleteSelectionButton,
} from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { DisplaySettingsModal } from '../../../components';
import {
  ConfigurationUploadModal,
  ConfigurationEmptyAlert,
} from 'modules/configuration/components';
import { isUserAllowed } from 'modules/core/utils';
import { INTEGRATIONS, ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { CreateValueDimensionModal, DimensionsDetailTable } from './components';

import './DimensionDetail.scss';

const { SETTINGS__DIMENSIONS__CREATE, SETTINGS__DIMENSIONS__DELETE } = ROLES;
const { Search } = Input;

const DimensionDetail = ({
  dimensionDetail: { id, name, values_count },
  dimensionValues: { values, count },
  fetchDimensionDetail,
  fetchDimensionValues,
  fetchDimensionTemplateXLS,
  fetchDimensionValuesConsolidatedXLS,
  uploadDimensionValuesXLS,
  deleteDimensionValue,
  deleteAllDimensionValues,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [dataSelection, setDataSelection] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [tablePagination, setTablePagination] = usePagination();
  const { t } = useTranslation();
  let { dimensionId } = useParams();

  useEffect(() => {
    setIsTableLoading(true);
    fetchDimensionValues({
      dimension: dimensionId,
      search: searchValue,
      ...tablePagination,
      writeable: 'True',
    }).then(() => setIsTableLoading(false));
  }, [fetchDimensionValues, dimensionId, searchValue, tablePagination]);

  useEffect(() => {
    fetchDimensionDetail(dimensionId);
  }, [fetchDimensionDetail, dimensionId]);

  const userCanCreate = isUserAllowed(SETTINGS__DIMENSIONS__CREATE);

  const userCanDelete = isUserAllowed(SETTINGS__DIMENSIONS__DELETE);

  const onSetDataRowSelection = data => setDataSelection(data);

  const onRefetch = () =>
    Promise.all([
      fetchDimensionDetail(dimensionId),
      fetchDimensionValues({
        dimension: dimensionId,
        search: searchValue,
        ...tablePagination,
        writeable: 'True',
      }),
    ]);

  const onDeleteSelections = () => {
    setIsDeleting(true);
    Promise.all(
      dataSelection.map(id =>
        deleteDimensionValue(id).catch(error => {
          setIsDeleting(false);
          message.error(t('FEEDBACK_DELETE_SELECTION_ERROR'));
          setDataSelection([]);
        })
      )
    ).then(() =>
      onRefetch().then(() => {
        setIsDeleting(false);
        message.success(t('FEEDBACK_DELETE_SELECTION_SUCCESS'));
        setDataSelection([]);
      })
    );
  };

  const onDeleteAllValues = dimensionId => {
    setIsDeleting(true);
    deleteAllDimensionValues({ dimension_id: dimensionId })
      .then(() => {
        setIsDeleting(false);
        setShowDeleteAll(false);
        onRefetch();
        message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
      })
      .catch(error => {
        setIsDeleting(false);
        setShowDeleteAll(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_VALUES_ERROR'),
          duration: 0,
        });
      });
  };

  const renderNoDataScreen = () => (
    <>
      <Col span={24}>
        <ConfigurationEmptyAlert
          onDownloadTemplate={() =>
            fetchDimensionTemplateXLS(
              t('CONFIG_VALUES_TEMPLATE_NAME', { name: name }),
              {
                dimension: name,
              }
            )
          }
        />
      </Col>
      <Col span={24}>
        <EmptyScreen
          image={emptyStateImage}
          title={t('CONFIG_DIMENSIONS_VALUES_EMPTY_STATE_TITLE')}
          footer={
            <Space direction="horizontal" size="large">
              <Button
                type="secondary"
                onClick={() => setShowUploadModal(true)}
                disabled={!userCanCreate}
              >
                {t('ACTION_UPLOAD_FILE')}
              </Button>
              <Button
                type="primary"
                onClick={() => setShowCreateModal(true)}
                disabled={!userCanCreate}
              >
                {t('ACTION_CREATE')}
              </Button>
            </Space>
          }
        />
      </Col>
    </>
  );

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_DIMENSIONS'),
          url: '/configuracion/misdatos/dimensiones',
        },
        { name: name ? name : '' },
      ]}
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="dimension-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('ACTION_CREATE_NEW')}
        </Button>
        <Button
          onClick={() => setShowUploadModal(true)}
          disabled={!userCanCreate}
        >
          {t('ACTION_UPLOAD_FILE')}
        </Button>
        <DisplaySettingsModal dimensionName={name} dimensionId={dimensionId} />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_DOWNLOAD_TEMPLATE'),
              icon: <FileExcelOutlined />,
              onClick: () =>
                fetchDimensionTemplateXLS(
                  t('CONFIG_VALUES_TEMPLATE_NAME', { name: name }),
                  {
                    dimension: name,
                  }
                ),
            },
            {
              title: t('ACTION_DELETE_ALL'),
              icon: <DeleteOutlined />,
              onClick: () => setShowDeleteAll(true),
              disabled: !userCanDelete,
            },
          ]}
        />
        <Popconfirm
          placement="left"
          title={t('POPCONFIRM_DELETE_ALL_PAGES', {
            projectionName: name,
          })}
          okText={t('ACTION_DELETE')}
          onConfirm={() => onDeleteAllValues(dimensionId)}
          cancelText={t('ACTION_CANCEL')}
          onCancel={() => setShowDeleteAll(false)}
          visible={showDeleteAll}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderKPI = () => (
    <Card style={{ height: 144, width: '25%' }}>
      <Space direction="vertical">
        {!isTableLoading && values_count !== undefined && (
          <KPIValue
            size="medium"
            fullValue={values_count}
            value={values_count}
          />
        )}
        <Spin spinning={isTableLoading} />
        <Typography.Body level={3} type="secondary">
          {t('LABEL_VALUES_DIMENSION')}
        </Typography.Body>
      </Space>
    </Card>
  );

  return (
    <SiderLayout>
      <div className="dimensions-detail-container">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          {count <= 0 && !searchValue && !isTableLoading && (
            <Col span={24}>{renderNoDataScreen()}</Col>
          )}
          {(count > 0 || (searchValue && count === 0)) && (
            <>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderKPI()}</Col>
              <Col span={12}>
                {dataSelection.length > 0 && (
                  <DeleteSelectionButton
                    onConfirm={onDeleteSelections}
                    disabled={!isUserAllowed(SETTINGS__DIMENSIONS__DELETE)}
                    isDeletingAll={
                      dataSelection.length === tablePagination.page_size ||
                      dataSelection.length === count
                    }
                  />
                )}
              </Col>
              <Col span={12}>
                <ButtonActionBar>
                  <Button
                    type="text"
                    shape="circle"
                    title={t('ACTION_DOWNLOAD_TABLE')}
                    icon={<Typography.Icon icon={DownloadOutlined} />}
                    onClick={() =>
                      fetchDimensionValuesConsolidatedXLS(
                        t('CONFIG_VALUES_FILE_NAME', { name: name }),
                        {
                          dimension_name: name,
                        }
                      )
                    }
                  />
                </ButtonActionBar>
              </Col>
              <Col span={24}>
                <DimensionsDetailTable
                  dataSource={values}
                  count={count}
                  tableLoading={isTableLoading || isDeleting}
                  onTableChange={setTablePagination}
                  pagination={tablePagination}
                  dataSelection={dataSelection}
                  onSetRowSelection={id => {
                    onSetDataRowSelection(id);
                  }}
                  onFetchDimensionValues={() =>
                    fetchDimensionValues({
                      dimension: dimensionId,
                      search: searchValue,
                      ...tablePagination,
                      writeable: 'True',
                    })
                  }
                />
              </Col>
            </>
          )}
        </Row>
        <ConfigurationUploadModal
          title={t('CONFIG_DIMENSION_VALUES_UPLOAD_MODAL_TITLE')}
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          onSuccessUpload={
            () => onRefetch()
            //setTablePagination({ current: 1 })
          }
          uploadValuesXLS={file =>
            uploadDimensionValuesXLS(file, { dimension: dimensionId })
          }
          setIsTableLoading={setIsTableLoading}
          nameConfig={t('LABEL_VALUES_DIMENSION')}
        />
        <CreateValueDimensionModal
          visible={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          onFetchDimensionValues={() => onRefetch()}
          dimensionId={id}
        />
      </div>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  dimensionDetail: configuration.selectors.getDimensionDetail(state),
  dimensionValues: configuration.selectors.getDimensionValues(state),
});

const mapDispatchToProps = {
  fetchDimensionDetail: configuration.actions.fetchDimensionDetail,
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionTemplateXLS: configuration.actions.fetchDimensionTemplateXLS,
  fetchDimensionValuesConsolidatedXLS:
    configuration.actions.fetchDimensionValuesConsolidatedXLS,
  uploadDimensionValuesXLS: configuration.actions.uploadDimensionValuesXLS,
  deleteDimensionValue: configuration.actions.deleteDimensionValue,
  deleteAllDimensionValues: configuration.actions.deleteAllDimensionValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(DimensionDetail);
