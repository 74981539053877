import React, { useState, useEffect } from 'react';
import {
  Popconfirm,
  Table,
  Space,
  Button,
  Tooltip,
  message,
  notification,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  DropdownMenu,
  InputCell,
  Typography,
  DeleteAndUpdate,
  SelectCell,
  SelectType,
} from 'modules/core/components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  isUserAllowed,
  generateSelectConceptOptions,
  userCanAcces,
} from 'modules/core/utils';
import { ACCES_USERS, CONCEPTS, ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';
import planning from 'modules/planning';
import login from 'modules/login';

const { SETTINGS__DIMENSIONS__EDIT, SETTINGS__DIMENSIONS__DELETE } = ROLES;

const DimensionsTable = ({
  dataSource,
  count,
  tableLoading,
  pagination,
  onChange,
  onSetRowSelection,
  dataSelection,
  conceptsList: { values },
  fetchConceptsList,
  deleteDimension,
  onFetchDimensionList,
  editConceptDimension,
  editDimensionName,
  deleteConceptDimension,
  loggedUser: { email },
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [deleteDimensionId, setDeleteDimensionId] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const { t } = useTranslation();

  let history = useHistory();

  useEffect(() => {
    fetchConceptsList();
  }, [fetchConceptsList]);

  const onCellEdit = (name, value) =>
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });

  const onPrepareConceptData = selectedConcepts => {
    let selectedAllIds = [];
    const selectedIds = _.compact([
      ...selectedConcepts.map(conceptData => {
        if (!(conceptData.length > 1)) {
          selectedAllIds = [
            ...selectedAllIds,
            ...values
              .filter(concept => concept.type === conceptData[0])
              .map(concept => concept.id),
          ];
        }
        return conceptData[1];
      }),
    ]);
    onCellEdit('concept', [...selectedAllIds, ...selectedIds]);
  };

  const renderConceptsName = ({ dataConcept }) =>
    dataConcept.map(infoConcept => infoConcept.concept.name).join(', ');

  const renderCascaderSelect = defaultValue => (
    <SelectType.Cascader
      options={generateSelectConceptOptions({ values })}
      onChange={onPrepareConceptData}
      defaultValue={defaultValue}
    />
  );

  const renderNormalSelect = conceptConfigs => (
    <SelectCell
      initialValue={conceptConfigs.map(infoConcept => infoConcept.concept.id)}
      onCellUpdate={onCellEdit}
      name="concept"
      options={values
        .filter(val => val.type === CONCEPTS.TYPE.ECONOMIC_KEY)
        .map(infoConcept => {
          return { id: infoConcept.id, name: infoConcept.name };
        })}
      mode="multiple"
      maxTagCount={1}
      showArrow
      allowClear
    />
  );

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      editable: true,
      width: 200,
      render: (record, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={record}
              onCellUpdate={onCellEdit}
              name="name"
            />
          );
        } else {
          return record;
        }
      },
    },
    {
      title: t('FIELD_ASSOCIATED_CONCEPTS'),
      dataIndex: 'concept_configs',
      editable: true,
      width: 200,
      render: (concept_configs, row) => {
        //NOTE: PROVISORIO auxDataConceptConfig HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS
        const auxDataConceptConfig = concept_configs
          .asMutable()
          .filter(
            concept => concept.concept.type === CONCEPTS.TYPE.ECONOMIC_KEY
          );

        if (editRow && row.id === editRow) {
          const defaultValue = concept_configs.asMutable().map(infoConcept => {
            return [infoConcept.concept.type, infoConcept.concept.id];
          });
          return userCanAcces({
            patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
            email,
          })
            ? // NOTE: PROVISORIO HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS
              renderCascaderSelect(defaultValue)
            : renderNormalSelect(auxDataConceptConfig);
        } else {
          if (concept_configs.length > 0) {
            // NOTE: PROVISORIO HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS. DEJAR LO QUE ESTA EN LA FUNCION CUANDO SE PUEDA VER EN TODOS
            const dataConcept = userCanAcces({
              patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
              email,
            })
              ? concept_configs
              : auxDataConceptConfig;
            return renderConceptsName({ dataConcept });
          } else {
            return '-';
          }
        }
      },
    },
    {
      title: t('LABEL_VALUES_DIMENSION'),
      dataIndex: 'count',
      width: 150,
    },
    //TODO: COMENTADO HASTA QUE SE DEFINA SI SE EDITA EL ESTADO.
    // {
    //   title: t('FIELD_STATUS'),
    //   dataIndex: 'state',
    //   editable: true,
    //   width: 200,
    //   render: (record, row) => '-',
    //   //   if (editRow && row.id === editRow) {
    //   //     return (
    //   //       <SelectCell
    //   //         //initialValue={record.id}
    //   //         onCellUpdate={onCellEdit}
    //   //         name={'status'}
    //   //         options={[
    //   //           { id: 'active', name: t('STATE_TAG_ACTIVE') },
    //   //           { id: 'inactive', name: t('STATE_TAG_INACTIVE') },
    //   //         ]}
    //   //         allowClear
    //   //       />
    //   //     );
    //   //   } else {
    //   //     return '-';
    //   //   }
    //   // },
    // },
    {
      title: (
        <Space size="large">
          {t('FIELD_ACTIONS')}
          <Tooltip title={t('CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} type="primary" />
          </Tooltip>
        </Space>
      ),
      align: 'right',
      dataIndex: 'id',
      width: 150,
      render: (record, row) => {
        return record !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_DIMENSIONS_POPCONFIRM_DELETE_DIMENSION_TITLE')}
            okText={t('ACTION_DELETE')}
            onConfirm={() => onDeleteDimension(record)}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteDimensionId(null)}
            visible={deleteDimensionId === record}
          >
            <Space size="middle">
              <Button
                type="text"
                shape="circle"
                icon={<Typography.Icon icon={EyeOutlined} />}
                title={t('ACTION_OPEN_DETAIL')}
                onClick={() =>
                  history.push(`/configuracion/misdatos/dimensiones/${record}`)
                }
              />
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={_.compact([
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => {
                      setEditRow(record);
                    },
                    disabled: !isUserAllowed(SETTINGS__DIMENSIONS__EDIT),
                  },
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteDimensionId(record),
                    disabled: !isUserAllowed(SETTINGS__DIMENSIONS__DELETE),
                  },
                ])}
              />
            </Space>
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() =>
              //NOTE: envio informacion de idDimension, los ids de conceptos a eliminar o agregar y la data conceptConfig
              //NOTE: _.difference() se utiliza para comparar array y obtener como resultado los elementos que difieren tambien como un array.
              onEditDimension(
                record,
                _.difference(
                  row.concept_configs.map(
                    infoConcept => infoConcept.concept.id
                  ),
                  dataCellEdit.concept
                ),
                _.difference(
                  dataCellEdit.concept,
                  row.concept_configs.map(infoConcept => infoConcept.concept.id)
                ),
                row.concept_configs
              )
            }
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        );
      },
    },
  ];

  const onCancel = () => setEditRow(null);

  const onConfirm = () => {
    setEditRow(null);
    setDataCellEdit({});
  };

  const onEditDimension = (
    dimensionId,
    deleteConcepts,
    newConcepts,
    dataConceptsGeneral
  ) => {
    let errors = [];
    if (!_.isEmpty(dataCellEdit)) {
      setIsEditing(true);
      //NOTE: me guardo la info del concept config que contiene el id de concepto a eliminar
      const idConceptConfigDelete = dataConceptsGeneral.filter(infoConcept =>
        deleteConcepts.includes(infoConcept.concept.id)
      );
      const genericError = {
        message: t('FEEDBACK_DEFAULT_ERROR'),
        duration: 0,
      };

      Promise.all([
        dataCellEdit.name &&
          //NOTE: edito nombre
          editDimensionName(editRow, { name: dataCellEdit.name }).catch(
            error => {
              errors.push(error);
              notification.error({
                ...genericError,
                description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
              });
            }
          ),

        ...(deleteConcepts.length !== 0 && dataCellEdit.concept !== undefined
          ? //NOTE: elimino concepto que se deseleccionó utilizando id de concepto config, no el id del concepto
            idConceptConfigDelete.map(dataConcept =>
              deleteConceptDimension(dataConcept.id).catch(error => {
                errors.push(error);
                notification.error({ ...genericError, description: error });
              })
            )
          : []),
        newConcepts.length !== 0
          ? //NOTE: agrego concepto que se seleccionó
            newConcepts.map(id =>
              editConceptDimension({
                dimension_id: dimensionId,
                concept: id,
              }).catch(error => {
                errors.push(error.response.data[0]);
                notification.error({
                  ...genericError,
                  description: error
                    ? error.response.data[0]
                    : t('FEEDBACK_SAVE_CHANGES_FAIL'),
                });
              })
            )
          : [],
      ]).then(() =>
        onFetchDimensionList().then(() => {
          errors.length > 0
            ? message.warning(
                t('CONFIG_DIMENSIONS_NOT_ALL_EDIT_SUCCESS_FEEDBACK')
              )
            : message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          setEditRow(null);
          setDataCellEdit({});
          setIsEditing(false);
        })
      );
    } else {
      setEditRow(null);
      setDataCellEdit({});
    }
  };

  const onDeleteDimension = id => {
    setIsEditing(true);
    deleteDimension(id)
      .then(() =>
        onFetchDimensionList().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          setIsEditing(false);
          setDeleteDimensionId(null);
        })
      )
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error ? error : t('FEEDBACK_DELETE_FAIL'),
          duration: 0,
        });
        setIsEditing(false);
        setDeleteDimensionId(null);
      });
  };

  return (
    <Table
      rowKey="id"
      rowSelection={{
        selectedRowKeys: dataSelection,
        onChange: value => onSetRowSelection(value),
      }}
      bordered
      size="small"
      loading={tableLoading || isEditing}
      columns={columns}
      onChange={onChange}
      dataSource={dataSource}
      pagination={{
        pageSize: pagination.pageSize,
        size: 'small',
        total: count,
        current: pagination.page,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  );
};

const mapStateToProps = state => ({
  conceptsList: planning.selectors.getConceptsList(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  deleteDimension: configuration.actions.deleteDimension,
  fetchConceptsList: planning.actions.fetchConceptsList,
  editConceptDimension: configuration.actions.editConceptDimension,
  editDimensionName: configuration.actions.editDimensionName,
  deleteConceptDimension: configuration.actions.deleteConceptDimension,
};

export default connect(mapStateToProps, mapDispatchToProps)(DimensionsTable);
