const BUDGET = 'budget';
const BUDGET_PERSONALIZED_REPORT = 'Presupuesto';
const BUDGET_VAR_MONEY = 'budget_var$';
const BUDGET_VAR_PERCENTAGE = 'budget_var%';
const REAL = 'real';
const REAL_PERSONALIZED_REPORT = 'Real';
const REAL_LAST_PERIOD = 'prev_real';
const REAL_LAST_PERIOD_MONEY = 'prev_real_var$';
const REAL_LAST_PERIOD_PERCENTAGE = 'prev_real_var%';
const FORECAST = 'forecast';
const LAST_FORECAST = 'last_forecast';
const LAST_FORECAST_VAR_MONEY = 'last_forecast_var$';
const LAST_FORECAST_VAR_PERCENTAGE = 'last_forecast_var%';
const VAR_MONEY = 'VAR $';
const VAR_PERCENTAGE = 'VAR %';
const CONSOLIDATED = 'consolidated';
const CONSOLIDATED_FORECAST = 'consolidated_forecast';

const PXQ_KEY = 'PxQ';

const FUNCTIONAL_KEY = 'functional';
const ORIGIN_KEY = 'origin';

const IN_ELABORATION = 'in_elaboration';
const MAKING = 'making';
const PUBLISHED = 'published';
const APPROVED = 'approved';
const INITIATED = 'initiated';
const APPLIED = 'applied';

const STATUS = {
  IN_ELABORATION: IN_ELABORATION,
  MAKING: MAKING,
  PUBLISHED: PUBLISHED,
  APPROVED: APPROVED,
  INITIATED: INITIATED,
  APPLIED: APPLIED,
};

const STATUS_COLORS = {
  [IN_ELABORATION]: 'gold',
  [MAKING]: 'gold',
  [APPROVED]: 'green',
  [PUBLISHED]: 'cyan', //or blue
  [INITIATED]: 'geekblue',
};

const I18N = {
  [BUDGET]: 'LABEL_BUDGET',
  [BUDGET_VAR_MONEY]: 'VARIATION_MONEY',
  [BUDGET_VAR_PERCENTAGE]: 'VARIATION_PERCENTAGE',
  [REAL]: 'LABEL_REAL',
  [REAL_PERSONALIZED_REPORT]: 'LABEL_REAL',
  [REAL_LAST_PERIOD]: 'REAL_LAST_PERIOD',
  [REAL_LAST_PERIOD_MONEY]: 'VARIATION_MONEY',
  [REAL_LAST_PERIOD_PERCENTAGE]: 'VARIATION_PERCENTAGE',
  [FORECAST]: 'LABEL_FORECAST',
  [LAST_FORECAST]: 'LAST_FORECAST',
  [LAST_FORECAST_VAR_MONEY]: 'VARIATION_MONEY',
  [LAST_FORECAST_VAR_PERCENTAGE]: 'VARIATION_PERCENTAGE',
  [BUDGET_PERSONALIZED_REPORT]: 'LABEL_BUDGET',
  [VAR_MONEY]: 'VARIATION_MONEY',
  [VAR_PERCENTAGE]: 'VARIATION_PERCENTAGE',
};

const LOAD_TYPES = {
  CONVENTIONAL: { id: 'conventional', name: 'LABEL_CONVENTIONAL_LOAD' },
  PLANE: { id: 'plane', name: 'LABEL_FLAT_LOAD' },
};

const LOAD_TYPES_LIST = Object.values(LOAD_TYPES);

const STATUS_COLUMN = {
  0: {
    status: STATUS.APPROVED,
    title: 'CONTROL_APPROVED_DATA_TOOLTIP_TITLE',
  },
  1: {
    status: STATUS.PUBLISHED,
    title: 'CONTROL_PUBLISHED_DATA_TOOLTIP_TITLE',
  },
  2: { status: 'load', title: 'CONTROL_UPLOADED_DATA_TOOLTIP_TITLE' },
  3: { status: 'null', title: '' },
};

const FACTORS_TYPE_OPTIONS = [
  { id: 'principal', name: 'FIELD_PRINCIPAL' },
  { id: 'secondary', name: 'FIELD_SECONDARY' },
];

const VARIABLES_TYPE_OPTIONS = [
  { id: 'percentage', name: 'FIELD_PERCENTAGE' },
  { id: 'number', name: 'FIELD_NUMBER' },
];

const CURRENCIES_OPTIONS = [
  { id: 'USD', code: 'USD', name: 'CONFIG_CURRENCY_USD', prefix: 'US$' },
  { id: 'EUR', code: 'EUR', name: 'CONFIG_CURRENCY_EUR', prefix: '€' },
  { id: 'ARS', code: 'ARS', name: 'CONFIG_CURRENCY_ARS', prefix: 'AR$' },
  { id: 'CLP', code: 'CLP', name: 'CONFIG_CURRENCY_CLP', prefix: 'CL$' },
  { id: 'COP', code: 'COP', name: 'CONFIG_CURRENCY_COP', prefix: 'CO$' },
  { id: 'MXN', code: 'MXN', name: 'CONFIG_CURRENCY_MXN', prefix: 'MX$' },
  { id: 'UYU', code: 'UYU', name: 'CONFIG_CURRENCY_UYU', prefix: 'UY$' },
  { id: 'PEN', code: 'PEN', name: 'CONFIG_CURRENCY_PEN', prefix: 'S/' },
];

const PROJECTION = {
  I18N,
  BUDGET,
  BUDGET_VAR_MONEY,
  BUDGET_VAR_PERCENTAGE,
  REAL,
  REAL_LAST_PERIOD_MONEY,
  REAL_LAST_PERIOD_PERCENTAGE,
  FORECAST,
  REAL_LAST_PERIOD,
  LAST_FORECAST,
  LAST_FORECAST_VAR_MONEY,
  LAST_FORECAST_VAR_PERCENTAGE,
  CONSOLIDATED,
  CONSOLIDATED_FORECAST,
  STATUS,
  STATUS_COLORS,
  LOAD_TYPES,
  LOAD_TYPES_LIST,
  STATUS_COLUMN,
  FACTORS_TYPE_OPTIONS,
  VARIABLES_TYPE_OPTIONS,
  CURRENCIES_OPTIONS,
  FUNCTIONAL_KEY,
  ORIGIN_KEY,
  PXQ_KEY,
};

export default PROJECTION;
