import { LinkOutlined, FileExcelOutlined } from '@ant-design/icons';

export const getDataButtonsContainerHelp = (
  setOnboardingStep,
  fetchOnboardingSteps
) => {
  return {
    '/configuracion': {
      buttons: [
        {
          name: 'LABEL_TUTORIALS',
          icon: <LinkOutlined />,
          subButtons: [
            {
              id: 'plan',
              name: 'LABEL_ACCOUNTS',
              link: 'https://www.youtube.com/watch?v=eXsgafcUzNE',
            },
            {
              id: 'concepts',
              name: 'LABEL_CONCEPTS',
              link: 'https://www.youtube.com/watch?v=KsPk39eoMLI&t=41s',
            },
            {
              id: 'dimensions',
              name: 'LABEL_DIMENSIONS',
              link: 'https://www.youtube.com/watch?v=57cExTvOe2I',
            },
            {
              id: 'projectionElements',
              name: 'LABEL_PROJECTION_ELEMENTS',
              subSubButtons: [
                {
                  id: 'vars',
                  name: 'LABEL_VARIABLES',
                  link: 'https://youtu.be/ZzSkNr2Zoek',
                },
                {
                  id: 'rules',
                  name: 'LABEL_BUSINESS_RULES',
                  link: 'https://youtu.be/1gPyjDhhxB8',
                },
                {
                  id: 'currency',
                  name: 'LABEL_MULTICURRENCY',
                  link: 'https://youtu.be/meALb9xBP5w',
                },
              ],
            },
            {
              id: 'report',
              name: 'LABEL_CUSTOM_REPORTS',
              link: 'https://youtu.be/kV-l_sJl2jQ',
            },
            {
              id: 'roleUsers',
              name: 'LABEL_ROLES_AND_USERS',
              link: 'https://www.youtube.com/watch?v=pTw8dOcOWuo&t=7s',
            },
          ],
        },
        {
          name: 'ONBOARDING_TOUR_ACTIVATE',
          onClick: () =>
            setOnboardingStep({
              dash_isfinish: false,
              config_isfinish: false,
              paso: 0,
            }).then(() => fetchOnboardingSteps()),
        },
        {
          name: 'LABEL_EXAMPLE_TEMPLATE',
          icon: <FileExcelOutlined />,
          subButtons: [
            {
              id: 'plan',
              name: 'LABEL_ACCOUNTS',
              link: 'https://docs.google.com/spreadsheets/d/1eHZJ4RDq2ePujdvrhHBQdduqvoaj-To7/edit#gid=1117241230',
            },
            {
              id: 'dimensions',
              name: 'LABEL_DIMENSIONS',
              link: 'https://docs.google.com/spreadsheets/d/1TpIWVsWE9WVVdSBWtVv58Z4zuVseNtaM/edit#gid=1864584151',
            },
            {
              id: 'report',
              name: 'LABEL_REPORTS',
              link: 'https://docs.google.com/spreadsheets/d/1pelVG1No0lJZyHJdnWzaTHnJF4Y5QpnB/edit#gid=1160347066',
            },
            {
              id: 'roleUsers',
              name: 'LABEL_ROLES_AND_USERS',
              link: 'https://docs.google.com/spreadsheets/d/1YFSucFBnL5QwD9DG4h2JaRBFkcrreO6b/edit#gid=1948728796',
            },
          ],
        },
      ],
    },
    '/analisis': {
      buttons: [
        {
          name: 'LABEL_TUTORIALS',
          link: 'https://www.youtube.com/watch?v=HxfRNs4viSM',
        },
      ],
    },
    '/periodos': {
      buttons: [
        {
          name: 'LABEL_TUTORIALS',
          link: 'https://www.youtube.com/watch?v=BAuFGU2nnRo',
        },
      ],
    },
  };
};
