import React, { useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonActionBar } from 'modules/core/components';
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import _ from 'lodash';

const Onboarding = ({
  steps,
  keyIdentifier,
  fetchOnboardingSteps,
  setOnboardingStep,
  onboardingSteps,
}) => {
  const { t } = useTranslation();

  const renderButtonStepAction = ({ props = {}, name = '', type = '' }) => {
    return (
      <Button type={type} {...props}>
        {t(name)}
      </Button>
    );
  };

  useEffect(() => {
    fetchOnboardingSteps();
  }, [fetchOnboardingSteps]);

  const renderTooltip = ({
    tooltipProps,
    step,
    index,
    backProps,
    continuous,
    primaryProps,
    closeProps,
    skipProps,
    isLastStep,
  }) => (
    <div {...tooltipProps}>
      <div
        style={{
          backgroundColor: 'white',
          width: 400,
          borderRadius: 5,
          padding: 20,
        }}
      >
        <div>
          <div>{step.content}</div>
        </div>
        <ButtonActionBar position="space-between">
          <Tooltip title={t('CONFIG_ONBOARDING_SKIP_TOOLTIP_TITLE')}>
            {renderButtonStepAction({
              props: skipProps,
              name: 'ACTION_SKIP',
              type: 'text',
            })}
          </Tooltip>
          <div>
            {index > 0 &&
              renderButtonStepAction({
                props: backProps,
                name: 'ACTION_RETURN',
                type: 'link',
              })}
            {continuous && !isLastStep
              ? renderButtonStepAction({
                  props: primaryProps,
                  name: 'ACTION_NEXT',
                  type: 'primary',
                })
              : renderButtonStepAction({
                  props: closeProps,
                  name: 'ACTION_FINISH',
                  type: 'primary',
                })}
          </div>
          {!continuous &&
            renderButtonStepAction({
              props: closeProps,
              name: 'ACTION_CLOSE',
            })}
        </ButtonActionBar>
      </div>
    </div>
  );

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      //NOTE: le mandamos los pasos asi porque sino no toma el valor que corresponde
      setOnboardingStep({
        [`${keyIdentifier}_isfinish`]: false,
        paso: index + (action === ACTIONS.PREV ? -1 : 1),
        key: keyIdentifier,
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setOnboardingStep({
        [`${keyIdentifier}_isfinish`]: true,
        paso: index,
      }).then(() => fetchOnboardingSteps());
    }
  };

  return (
    <div>
      <Joyride
        run={
          !_.isEmpty(onboardingSteps) &&
          !onboardingSteps[keyIdentifier]?.isFinish
        }
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        stepIndex={
          !_.isEmpty(onboardingSteps) && onboardingSteps[keyIdentifier]?.paso
        }
        callback={handleJoyrideCallback}
        tooltipComponent={renderTooltip}
        styles={{
          options: {
            primaryColor: '#EEA30D',
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  onboardingSteps: configuration.selectors.getOnboardingSteps(state),
});

const mapDispatchToProps = {
  setOnboardingStep: configuration.actions.setOnboardingStep,
  fetchOnboardingSteps: configuration.actions.fetchOnboardingSteps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
