import { Cascader } from 'antd';
import i18n from 'i18next';

const CascaderSelect = ({
  options = [],
  onChange = () => {},
  defaultValue = undefined,
  ...props
}) => {
  return (
    <Cascader
      {...props}
      placeholder={i18n.t('ACTION_SELECT')}
      defaultValue={defaultValue}
      style={{ width: 200 }}
      showSearch
      options={options}
      onChange={onChange}
      multiple
      maxTagCount={1}
      maxTagTextLength={10}
    />
  );
};

export default CascaderSelect;
