import React, { useState, useEffect } from 'react';
import { Spin, Select } from 'antd';
import { client } from 'httpclient';
import { useTranslation } from 'react-i18next';
import { generateSelectOptions } from 'modules/core/utils';

//TODO: Revisar cual es el motivo de su consante rendereo al mover el cursor fuera del listado de opciones
const CellSelect = ({
  label = '',
  value,
  disabled = true,
  fetchData,
  onChange,
}) => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectorOptions, setSelectorOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isDropdownOpen) {
      setIsLoadingOptions(true);
      client
        .post('/planning/rows/dimensions/', {
          ...fetchData,
        })
        .then(response => {
          setIsLoadingOptions(false);
          setSelectorOptions(response.data.values);
        });
    }
  }, [fetchData, isDropdownOpen]);

  // NOTE: Cambios entre key y id o label y name es debido a la diff entre back y antd.
  return (
    <Select
      style={{ width: '95%' }}
      value={value ? { key: value.id, label: value.name } : undefined}
      placeholder={label}
      disabled={disabled}
      open={isDropdownOpen}
      allowClear
      showSearch
      labelInValue
      notFoundContent={
        isLoadingOptions ? (
          <Spin size="small" />
        ) : (
          t('FEEDBACK_DEFAULT_NOT_FOUND')
        )
      }
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={value =>
        onChange(value ? { id: value.key, name: value.label } : null)
      }
      onDropdownVisibleChange={setIsDropdownOpen}
      options={generateSelectOptions({ options: selectorOptions })}
    />
  );
};

export default CellSelect;
