const integrations = {
  INTEGRATIONS_CONFIG_PLIKA_SUBTITLE: `Let's start setting the tool`,
  INTERATION_INPORT_DATA_SECTION_TITLE: 'Import data',
  INTEGRATION_LOAD_MANUAL_SECTION_TITLE: 'Manual loading',
  INTEGRATION_ENTER_USER_INPUT_PLACEHOLDER: 'Enter user',
  INTEGRATION_ENTER_PASSWORD_INPUT_PLACEHOLDER: 'Enter password',
  INTEGRATION_URL_FORMITEM_LABEL: 'URL',
  INTEGRATION_ENTER_URL_INPUT_PLACEHOLDER: 'Enter a URL',
  INTEGRATION_DATABASE_NAME_FORMITEM_LABEL: 'Database name',
  INTEGRATION_ENTER_DATABASE_INPUT_PLACEHOLDER: 'Enter database name',
  INTEGRATION_PORT_FORMITEM_LABEL: 'Port',
  INTEGRATION_ENTER_PORT_INPUT_PLACEHOLDER: 'Enter port',
  INTEGRATION_SELECT_PERIOD_INPUT_PLACEHOLDER: 'Select a period',
  INTEGRATION_PLIKA_LOGO_IMG_ALT: 'Plika logo',
  INTEGRATION_LOGO_IMG_ALT: 'Integration logo',
  INTEGRATION_DATA_INTEGRATION_MODAL_TITLE: 'Data {{name}}',
  INTEGRATION_CONNECTION_DATA_DIVIDER_TEXT: 'Data for connection',
  INTEGRATION_CONFIGURATION_SUCCESS_SCREEN_TITLE: 'Successful configuration!',
  INTEGRATION_ACTIVATION_SUCCESS_DESCRIPTION_1:
    'The information is being processed, we will send an email to {{email}} when the data is finished.',
  INTEGRATION_ACTIVATION_SUCCESS_DESCRIPTION_2: 'How to continue?',
  INTEGRATION_NEW_CONNECTION_BUTTON_ACTION: 'New connection',
  INTEGRATION_VIEW_PROCESS_DATA_BUTTON_ACTION: 'See process detail',
  INTERATION_CONNECTED_SECTION_TITLE: 'Connected',
  INTERATION_MORE_OPTIONS_SECTION_TITLE: 'More options',
  INTEGRATION_OPTION_SECTION_TITLE: 'Options',

  INTEGRATIONS_FEEDBACK_IMPORT_ERROR_DESCRIPTION:
    'An error occurred when importing the data.',
  INTEGRATIONS_FEEDBACK_ACTIVATE_DEACTIVATE_ERROR_DESCRIPTION:
    'An error occurred to {{action}} integration.',

  INTERATION_PLIKA_SETTING_CARD_TEXT: 'Plika setting',

  INTEGRATION_FEEDBACK_SUCCESS_MESSAGE: 'Successful {{action}}',
  INTEGRATIONS_FEEDBACK_DEACTIVATE_SUCCESS_TEXT: 'Deactivation',
  INTEGRATIONS_FEEDBACK_ACTIVATE_SUCCESS_TEXT: 'Activation',
  INTEGRATIONS_ACTIVATE_DESACTIVATE_POPCONFIRM_TITLE:
    'Are you sure you want {{action}} {{name}}?',
};

export default integrations;
