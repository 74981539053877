import React from 'react';
import { Space } from 'antd';
import { Typography } from 'modules/core/components';

import './EmptyScreen.scss';

const EmptyScreen = ({ image, title, description, footer = null }) => (
  <Space className="core-empty-screen" direction="vertical" size="large">
    <img className="core-empty-screen-img" alt="empty-state" src={image} />
    {title && (
      <Typography.Headline level={4} type="primary">
        {title}
      </Typography.Headline>
    )}
    <div className="core-empty-screen-description">
      <Typography.Body>{description}</Typography.Body>
    </div>
    {footer}
  </Space>
);

export default EmptyScreen;
