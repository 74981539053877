import React from 'react';
import i18n from 'i18next';
import numeral from 'numeral';
import { InputCell } from 'modules/core/components';
import RuleAlert from './RuleAlert';

//const TOTAL_AMOUNT_FORMAT = '0,0';
const TOTAL_FORMAT = '0,0';

const renderEditableCell = ({ initialValue, onCellUpdate }) => (
  <InputCell
    name="name"
    initialValue={initialValue}
    onCellUpdate={onCellUpdate}
  />
);

const renderImportColumn = (value, prefix = '') => (
  <span className="column-import">
    {value ? `${prefix && prefix} ${numeral(value).format(TOTAL_FORMAT)}` : '-'}
  </span>
);

export const initialRulesColumns = ({ editRow, onCellUpdate, prefix }) => [
  {
    title: i18n.t('LABEL_BUSINESS_RULES'),
    dataIndex: 'name',
    render: (name, row) => {
      if (row.factores.length <= 0) {
        return <RuleAlert name={name} ruleId={row.id} />;
      } else if (row.id === editRow) {
        return renderEditableCell({ initialValue: name, onCellUpdate });
      }
      return name;
    },
  },
  {
    title: i18n.t('FIELD_TOTAL_AMOUNT'),
    dataIndex: 'total',
    render: total => renderImportColumn(total, prefix),
  },
  {
    title: i18n.t('FIELD_TOTAL_LINES'),
    dataIndex: 'affected_rows',
    render: line => renderImportColumn(line),
  },
];

export const initialFactorColumns = ({ editRow, onCellUpdate }) => [
  {
    dataIndex: 'name',
    ellipsis: true,
    render: (name, row) => {
      if (row.id) {
        if (row.id === editRow) {
          return renderEditableCell({ initialValue: name, onCellUpdate });
        } else {
          return name;
        }
      }
      return `${i18n.t('LABEL_FORMULA')} (${name})`;
    },
  },
];
