import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { AppLayout } from 'modules/core/layouts';
import login from 'modules/login';

const PrivateRoute = ({
  loggedUser,
  isAuthenticated,
  onLogout,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated) {
        if (rest.path === '/login') {
          return <Redirect to="/" />;
        } else {
          return (
            <AppLayout loggedUser={loggedUser} onLogout={onLogout}>
              <Component {...props} />
            </AppLayout>
          );
        }
      } else {
        if (rest.path === '/login' || rest.path === '/restablecercontraseña') {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }
    }}
  />
);

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  isAuthenticated: login.selectors.getIsAuthenticated(state),
});

const mapDispatchToProps = {
  onLogout: login.actions.onLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
