import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProjectionTrackingScreen } from 'modules/core/components';
import {
  AdjustmentCreation,
  AdjustmentDetail,
} from 'modules/adjustments/containers';
import {
  DistributionCreation,
  DistributionDetail,
} from 'modules/distributions/containers';
import ControlHome from './ControlHome/ControlHome';
import { ControlList, ControlDetail } from './EconomicControl/index';
import { Real, RealDetail } from './FinancialControl/index';

const Home = () => (
  <Switch>
    <Route exact path="/seguimiento/:periodId" component={ControlHome} />
    <Route exact path="/seguimiento/:periodId/financiero" component={Real} />
    <Route
      exact
      path="/seguimiento/:periodId/economico"
      component={ControlList}
    />
    <Route
      exact
      path="/seguimiento/:periodId/economico/ajustes/:adjustmentId"
      component={AdjustmentDetail}
    />
    <Route
      exact
      path="/seguimiento/:periodId/economico/ajustes/"
      component={AdjustmentCreation}
    />
    <Route
      exact
      path="/seguimiento/:periodId/economico/distribuciones/:distributionId"
      component={DistributionDetail}
    />
    <Route
      exact
      path="/seguimiento/:periodId/economico/distribuciones/"
      component={DistributionCreation}
    />
    <Route
      exact
      path="/seguimiento/:periodId/economico/:conceptName(ventas|gastos)/actividad/:projectionId"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/seguimiento/:periodId/financiero/:conceptName(ingresos operativos|egresos operativos|ingresos extraordinarios|egresos extraordinarios)/actividad/:projectionId"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/seguimiento/:periodId/economico/:conceptName(ventas|gastos)/:controlId"
      component={ControlDetail}
    />
    <Route
      exact
      path="/seguimiento/:periodId/financiero/:conceptName(ingresos operativos|egresos operativos|ingresos extraordinarios|egresos extraordinarios)/:controlId"
      component={RealDetail}
    />
  </Switch>
);

export default Home;
