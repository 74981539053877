import React from 'react';

import './Typography.scss';

const Headline = ({
  className,
  children,
  level = 1,
  type = 'title',
  align,
  ...otherProps
}) => {
  const HeaderTag = (function addEventsAndStuff() {
    if (level < 1) {
      return 'h1';
    }
    if (level > 6) {
      return 'h6';
    }
    return `h${level}`;
  })();

  return (
    <HeaderTag
      {...otherProps}
      className={`${className} core-typography-headline`}
      data-type={type}
      data-align={align}
    >
      {children}
    </HeaderTag>
  );
};

const Subtitle = ({
  className,
  children,
  level = 1,
  type = 'primary',
  ...otherProps
}) => (
  <span
    {...otherProps}
    data-level={level}
    data-type={type}
    className={`${className} core-typography-subtitle`}
  >
    {children}
  </span>
);

const Body = ({
  className,
  children,
  level = 1,
  type = 'primary',
  ...otherProps
}) => (
  <span
    {...otherProps}
    data-level={level}
    data-type={type}
    className={`${className} core-typography-body`}
  >
    {children}
  </span>
);

const Icon = ({
  className,
  icon: Icon,
  level = 3,
  type = '',
  ...otherProps
}) => (
  <Icon
    className={`${className} core-typography-icon`}
    data-level={level}
    data-type={type}
    {...otherProps}
  />
);

const Typography = {
  Headline,
  Subtitle,
  Body,
  Icon,
};
export default Typography;
