import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, Spin } from 'antd';
import { useQuery } from 'modules/core/customHooks';
import { SiderLayout } from 'modules/core/layouts';
import { PeriodNavigator } from 'modules/core/components';
import { AdjustmentsList } from 'modules/adjustments/containers';
import { DistributionsList } from 'modules/distributions/containers';
import { TracingList } from './components';
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils';
import { PROJECTION, ROLES, CONCEPTS } from 'modules/core/constants';
import planning from 'modules/planning';

const { CONTROL__REAL, CONTROL__ADJUSTMENTS, CONTROL__DISTRIBUTIONS } = ROLES;

const ControlList = ({
  projections,
  fetchProjections,
  fetchPeriodDetail,
  periodDetail,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const { t } = useTranslation();
  let { periodId } = useParams();
  let query = useQuery();

  const canUserAccessReal = isUserAllowed(getOperationsByKeys([CONTROL__REAL]));
  const canUserAccessAdjustments = isUserAllowed(
    getOperationsByKeys([CONTROL__ADJUSTMENTS])
  );
  const canUserAccessDistributions = isUserAllowed(
    getOperationsByKeys([CONTROL__DISTRIBUTIONS])
  );

  useEffect(() => {
    Promise.all([
      fetchPeriodDetail(periodId),
      fetchProjections({ period: periodId, type: PROJECTION.REAL }),
    ]).then(() => setIsScreenLoading(false));
  }, [fetchProjections, periodId, fetchPeriodDetail]);

  const showDefaultPanelKey = () => {
    if (canUserAccessReal) {
      return PROJECTION.REAL;
    } else if (canUserAccessAdjustments) {
      return 'ajustes';
    } else if (canUserAccessDistributions) {
      return 'distribuciones';
    }
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        { name: t('LABEL_CONTROL'), url: `/seguimiento/${periodId}` },
        { name: t('LABEL_ECONOMIC') },
      ]}
    />
  );

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <Tabs
            defaultActiveKey={
              query.get('tab') !== null
                ? query.get('tab')
                : showDefaultPanelKey()
            }
            size="small"
          >
            {canUserAccessReal && (
              <Tabs.TabPane tab="Real" key="real">
                {projections.length > 0 && (
                  <TracingList
                    dataSource={projections.filter(
                      projection =>
                        projection.concept.type === CONCEPTS.TYPE.ECONOMIC_KEY
                    )}
                    onFetchProjections={() =>
                      fetchProjections({
                        period: periodId,
                        type: PROJECTION.REAL,
                      })
                    }
                  />
                )}
              </Tabs.TabPane>
            )}
            {canUserAccessAdjustments && (
              <Tabs.TabPane tab={t('ADJUSTMENT_MANAGMENT_LABEL')} key="ajustes">
                <AdjustmentsList
                  period={{
                    periodId: periodId,
                    periodName: projections[0].period.name
                      ? projections[0].period.name
                      : '',
                  }}
                  periodDetail={periodDetail}
                />
              </Tabs.TabPane>
            )}
            {canUserAccessDistributions && (
              <Tabs.TabPane tab={t('DISTRIBUTION_LABEL')} key="distribuciones">
                <DistributionsList
                  period={{
                    periodId: periodId,
                    periodName: projections[0].period.name
                      ? projections[0].period.name
                      : '',
                  }}
                  periodDetail={periodDetail}
                />
              </Tabs.TabPane>
            )}
          </Tabs>
        </>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  projections: planning.selectors.getProjections(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
});

const mapDispatchToProps = {
  fetchProjections: planning.actions.fetchProjections,
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlList);
