const planning = {
  //Files names
  PLANNING_TEMPLATE_NAME_DEFAULT: 'Plantilla Plika - Planificación',
  PLANNING_TEMPLATE_NAME:
    'Plantilla Plika - Planificación {{concept}} - {{periodName}} {{loadTypeName}}',
  PLANNING_EVOLUTION_TABLE_FILE_NAME: `Plika - Planificación {{concept}} - {{periodName}} {{loadTypeName}} (evolución mensual)`,
  PLANNING_CONSOLIDATION_EVOLUTION_TABLE_FILE_NAME: `Plika - Planificación {{consoName}}({{reportName}}) - {{periodName}} (Consolidación)`,
  PLANNING_EVOLTION_FILE_NAME: 'Plika - Planificación (evolucion mensual)',

  PLANNING_DISABLED_CREATION_TOOLTIP:
    'No podés crear nuevos presupuestos porque este período ya tiene presupuestos aprobados',
  PLANNING_DISABLED_DELETE_TOOLTIP:
    'No se puede eliminar un presupuesto aprobado',

  //Text modal create budget
  PLANNING_CREATE_FORM_BUDGET_TITLE: 'Crear presupuesto nuevo',
  PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK:
    'El presupuesto se creó correctamente.',
  PLANNING_CREATE_BUDGET_ERROR_FEEDBACK:
    'No pudimos crear el presupuesto. Por favor, volvé a intentarlo.',
  PLANNING_CREATE_FORM_BUDGET_PLACEHOLDER: 'Ej:{{name}} nuevo',

  //Texts modal deadline
  PLANNING_CREATE_FORM_DEADLINE_DATE_PLACEHOLDER: 'Seleccionar día',
  PLANNING_CREATE_FORM_DEADLINE_TIME_PLACEHOLDER: 'Seleccionar hora',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_LABEL: 'Crear recordatorio',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE: 'Ninguno',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_IN_DEADLINE:
    'En fecha límite',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_ONE_DAY_BEFORE:
    '1 día antes',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_TWO_DAY_NEFORE:
    '2 días antes',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_CHECKBOX_LABEL:
    'Enviar notificación de fecha límite por e-mail',
  PLANNING_CREATE_DEADLINE_SUCCESS_FEEDBACK:
    'Se ha establecido la fecha limite de carga.',
  PLANNING_CREATE_DEADLINE_ERROR_FEEDBACK:
    'No pudimos establecer la fecha limite de carga. Por favor, volvé a intentarlo.',
  PLANNING_UPDATE_DEADLINE_SUCCESS_FEEDBACK:
    'Se ha actualizado la fecha límite de carga correctamente.',
  PLANNING_UPDATE_DEADLINE_ERROR_FEEDBACK:
    'No pudimos actualizar la fecha límite de carga. Por favor, volvé a intentarlo.',
  PLANNING_DEADLINE_MODAL_TITLE: '{{field}} fecha limite de carga',

  //Cards empty
  PLANNING_EMPTY_CARD_BODY: 'Ingresá y comenzá a presupuestar',

  //Popconfirm delete
  PLANNING_TEXT_DELETE_BUDGET_POPCONFIRM:
    '¿Estás seguro que deseas eliminar este presupuesto?',

  //Notification budget
  PLANNING_COPY_BUDGET_SUCCESS_FEEDBACK:
    'La copia del presupuesto se creó correctamente.',
  PLANNING_COPY_BUDGET_ERROR_FEEDBACK:
    'No pudimos crear una copia del presupuesto. Por favor, volvé a intentarlo.',
  PLANNING_DELETE_BUDGET_SUCCESS_FEEDBACK:
    'El presupuesto se eliminó correctamente.',
  PLANNING_DELETE_BUDGET_ERROR_FEEDBACK:
    'No pudimos eliminar el presupuesto. Por favor, volvé a intentarlo.',
  PLANNING_EDIT_NAME_BUDGET_SUCCESS_FEEDBACK:
    'El nombre del presupuesto de editó correctamente.',
  PLANNING_EDIT_NAME_BUDGET_ERROR_FEEDBACK:
    'No pudimos editar el nombre del presupuesto. Por favor, volvé a intentarlo.',

  //Popconfirm consolidation
  PLANNING_TEXT_DELETE_CONSOLIDATION_POPCONFIRM:
    '¿Estás seguro que deseas eliminar esta consolidación?',

  //Modal consolidation create
  PLANNING_CONSOLIDATION_CREATE_FORM_TITLE: 'Crear consolidación nueva',
  PLANNING_CONSOLIDATION_CREATE_FORM_INPUT_PLACEHOLDER: 'Ej: consolidado nuevo',
  //Empty screen load file
  PLANNING_EMPTY_SCREEN_UPLOAD_SUBTITLE: 'Comenzá la carga de tu presupuesto',
  PLANNING_UPLOAD_MODAL_TITLE: 'Cargar presupuesto',

  //Drawer scenario
  PLANNING_VARS_EMPTY_SCREEN_UPLOAD_SUBTITLE:
    'Comenzá la carga de tus variables',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES_VALUES:
    'La variable aún no tiene valores asignados.',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES: 'Aún no existen variables',
  PLANNING_VARIABLE_INCOMPLETE_VALUES_TOOLTIP:
    'Se deben completar los valores para todos los meses',

  PLANNING_VARIABLE_APPLY_SUCCESS_FEEDBACK:
    'Las variables se aplicaron correctamente.',
  PLANNING_VARIABLE_APPLY_ERROR_FEEDBACK:
    'No pudimos aplicar la variable. Por favor, volvé a intentarlo.',

  PLANNING_VARIABLE_EDIT_TOOLTIP: 'Click para editar variable',
  PLANNING_VARIABLE_CONFIG_TOOLTIP:
    'Para editar este campo ir a configuración de variables',
  PLANNING_VARIABLE_CONFIG_LINK: 'Ir al listado de variables',
  PLANNING_VARIABLE_CONFIG_LINK_ACTION: 'Editar desde configuración',

  PLANNING_VARIABLES_RETURN_CONFIRM_TITLE: 'Volver al listado de variables',
  PLANNING_VARIABLES_RETURN_CONFIRM_TEXT:
    '¿Estás seguro que deseas volver al listado de variables?',
  PLANNING_VARIABLES_RETURN_CONFIRM_TOOLTIP:
    'Si volves sin guardar perderás los valores cargados hasta el momento.',

  PLANNING_ACTION_MODAL_TITLE: '{{action}} presupuesto',
  PLANNING_APPROVE_MODAL_TEXT:
    'Estás por finalizar con el proceso presupuestario de tu período.¿Estás seguro que deseas hacerlo? Recordá que una vez que apruebes el presupuesto ya no se podrá modificar ningún dato ni subir más archivos.',
  PLANNING_SCREEN_SUCCESS_FEEDBACK:
    ' Acabás de {{action}} el presupuesto del {{period}} :)', // NOTE: COMENTADO HASTA QUE SE PUEDA NOTIFICAR.¿Querés darle la noticia a todos los que participaron de esta planificación?',
  PLANNIG_PUBLISH_MODAL_TEXT:
    'Estás por publicar tu presupuesto. En esta instancia podrás editar,seguir cargando datos, comparar presupuestos entre sí y visualizar tus datos en análisis, seguimiento y forecast.',

  //Empty screen
  PLANNING_EMPTY_SCREEN_TITLE: '¡Aún no tenés ningún presupuesto creado!',
  PLANNING_EMPTY_SCREEN_BODY:
    'Creá el presupuesto, establecé prioridades y construí la cantidad de proyecciones así cómo diferentes escenarios tengas',
  PLANNING_BUDGET_CREATE_ACTION: 'Crear presupuesto',

  //Empty screen consolidation
  PLANNING_CONSOLIDATION_EMPTY_SCREEN_TITLE:
    '¡Aún no tenés ninguna consolidación creada!',
  PLANNING_CONSOLIDATION_ALERT_WARNING_TITLE:
    'Todavía no tenemos todos los datos que necesitamos para que puedas crear tus consolidaciones',
  PLANNING_CONSOLIDATION_ALERT_WARNING_BODY:
    'Para que puedas crear consolidaciones debemos tener al menos un presupuesto de ventas y un presupuesto de gastos creados. Una vez que lo hagas, podrás crear todas las consolidaciones que consideres necesarias.',
  PLANNING_CONSOLIDATION_NO_SALES_WARNING: 'No hay Ventas con datos cargados',
  PLANNING_CONSOLIDATION_NO_EXPENSES_WARNING:
    'No hay Gastos con datos cargados',

  PLANNING_CONVENTIONAL_UPLOAD_TITLE: 'Carga convencional',
  PLANNING_UPLOAD_TYPE_LABEL: 'Tipo de carga',
  PLANNING_SELECT_FACTOR_LABEL: 'Seleccionar factor',
  PLANNING_VALUES_VARS_APPLIED: 'Valores con variables aplicadas',
  PLANNING_VALUES_FACTORS_APPLIED: 'Valores con factores aplicados',

  PLANNING_CONSOLIDATION_BUDGET_FIXED_POPOVER_TEXT:
    'Seleccionaste este presupuesto como Predeterminado. Esto significa que visualizarás sus datos en toda la herramienta.',
  PLANNING_CONSOLIDATION_NOT_DATA_BUDGET_TOOLTIP_TITLE:
    'Aún no tienes datos cargados en ventas y gastos. Para realizar una consolidación deberás tener datos en los presupuestos.',
  PLANNING_CONSOLIDATION_UPDATE_DATA_TOOLTIP_TITLE:
    'Se editaron datos en Ventas o Gastos. Toque el botón para actualizar.',
  PLANNING_CONSOLIDATION_NOT_UPDATE_DATA_TOOLTIP_TITLE:
    'No tiene actualizaciones pendientes.',
  PLANNING_CONSOLIDATION_MODIFY_BUDGET_POPCONFIRM_TITLE:
    'Si modifica el escenario del consolidado, se actualizarán todos sus datos. ¿Está seguro que desea continuar?',
  PLANNING_CONSOLIDATION_INFORMATIVE_TOOLTIP_TITLE:
    'Haz click para establecer este presupuesto como predetermiando.',

  //reglas de negocio
  PLANNING_FORMULAS_INFORMATIVE_ALERT:
    'Recuerde que hasta no cargar todos los importes de sus factores, no verá esa regla de negocio reflejada en el resumen.',
  PLANNING_FORMULAS_DESCRIPTION_NOTIFICATION_SUCCESS:
    'Para visualizarlos ingresa al detalle del factor.',
  PLANNING_FACTOR_EMPTY_STATE_TITLE: 'Comenzá la carga del factor',
  PLANNING_FORMULAS_EMPTY_SCREEN_TITLE:
    '¡Aún no hay reglas de negocio configuradas!',
  PLANNING_SUMMARY_EMPTY_SCREEN_DESCRIPTION:
    'Comienza la carga de tu presupuesto por importe y/o regla para poder ver tu resumen.',
  PLANNING_FACTOR_BACK_TO_RULES_ACTION: 'Volver al listado de reglas',
  PLANNING_FORMULA_GO_TO_LOAD_FACTOR_TEXT: 'para comenzar la carga.',
  PLANING_FORMULA_UNAPPLY_POPCONFIRM_TEXT:
    '¿Estás seguro que deseas {{action}} la regla?',
  PLANNING_FORMULA_APPLY_TOOLTIP_TITLE:
    'Aplica la regla para poder verla en el resumen.',
  PLANNING_FORMULA_APPLY_POPCONFIRM_PENDING_TEXT:
    '¿Estás seguro que deseas aplicar la Regla de Negocio sin tener todos los factores cargados?',
  PLANNING_FORMULA_UNAPPLY_TOOLTIP_TITLE:
    'Desaplica la regla de negocio para poder editar/cargar datos de los factores.',
  PLANNING_FORMULA_LOAD_DATA_TOOLTIP_TITLE:
    'Debes cargar datos para habilitar esta acción.',
  PLANNING_FACTOR_REPLICATE_INFORMATIVE_ALERT_DESCRIPTION:
    'Replique los valores de un factor a otro factor en la regla de negocio que desee.',
  PLANNING_FORMULA_BUTTON_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'Debes desaplicar la regla para poder cargar mas información.',
  PLANNING_FACTOR_POPCONFIRM_SAVE_CHANGES:
    '¿Estás seguro que quieres guardar los cambios realizados? Ten en cuenta que esto impactará en el cálculo y sus resultados.',

  PLANNING_PXQ_DESCRIPTION_ENABLE_NOTIFICATION_ERROR:
    'No pudimos habilitar pxq para la proyeccion.',
  PLANNING_PXQ_DESCRIPTION_CREATE_NOTIFICATION_ERROR:
    'No pudimos obtener informacion de pxq de la proyeccion.',

  PLANNING_DISPLAY_NEGATIVE_DATA_FORM_LABEL: 'Visualización valores negativos',

  //multimoneda
  PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_1: 'Ingresá',
  PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_2: 'acá',
  PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_3:
    'para cargar el tipo de cambio, y así comenzar a presupuestar.',
  PLANNING_CURRENCY_LOAD_EXCHANGE_RATE_TOOLTIP_TITLE:
    'Primero debes cargar el tipo de cambio para poder ingresar',
  PLANNING_CURRENCY_EMPTY_SCREEN_TITLE:
    '¡Aún no hay monedas de origen activadas para mostrar!',
  PLANNING_CURRENCY_CANCEL_POPCONFIRM_TITLE:
    '¿Estás seguro que deseas cancelar los cambios?',
  PLANNING_CURRENCY_LOAD_ALL_EXCHANGE_RATE_ALERT_DESCRIPTION:
    'Para guardar el tipo de cambio todos los meses deben tener un valor cargado.',
  PLANNING_CURRENCY_ALL_VALUES_TOOLTIP_TITLE:
    'Debes completar con valores todos los meses',
};

export default planning;
