import React, { useState } from 'react';
import { Input } from 'antd';

const InputCell = ({
  initialValue,
  onCellUpdate,
  name,
  style = { width: 200 },
}) => {
  const [cellValue, setCellValue] = useState(initialValue);

  const updateCellValue = () => {
    setCellValue(cellValue);
    if (initialValue !== cellValue) {
      onCellUpdate(name, cellValue);
    } else {
      setCellValue(initialValue);
    }
  };

  return (
    <Input
      onBlur={updateCellValue}
      onMouseLeave={updateCellValue}
      onChange={e => setCellValue(e.target.value)}
      value={cellValue}
      style={style}
    />
  );
};

export default InputCell;
