import React, { useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Select,
  message,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AddAndDeleteFormItem } from 'modules/core/components';
import { generateSelectOptions, isUserAllowed } from 'modules/core/utils';
import { PROJECTION } from 'modules/core/constants';
import configuration from 'modules/configuration';
import { connect } from 'react-redux';
import _ from 'lodash';

const CURRENCIES_TYPES = [
  { id: PROJECTION.ORIGIN_KEY, name: 'FILED_ORIGIN_CURRENCY' },
  { id: PROJECTION.FUNCTIONAL_KEY, name: 'FIELD_FUNCTIONAL_CURRENCY' },
];

const CreateCurrencyModal = ({
  buttonName,
  rolesAvailables,
  createCurrencies,
  fetchCurrencyList,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const userCanCreate = isUserAllowed(rolesAvailables);

  const onClose = () => {
    form.resetFields();
    setShowCreateModal(false);
  };

  const renderMessageSuccess = () => {
    message.success(t('FEEDBACK_CREATE_SUCCESS'));
    setIsCreating(false);
    onClose();
  };

  const renderMessageWarning = currencies => {
    notification.warning({
      message: t('FIELD_ATENTION'),
      description: t('CONFIG_CURRENCY_CREATE_FEEDBACK_FAIL_DESCRIPTION', {
        currencies: currencies.join(', '),
      }),
      duration: 0,
    });
    setIsCreating(false);
    onClose();
  };

  const onConfirm = () => {
    form.validateFields().then(values => {
      setIsCreating(true);
      createCurrencies(values.currencies)
        .then(response => {
          //NOTE: me quedo con los codigos de las monedas que no fueron creadas porqu ya existen.
          const currenciesNotCreated = _.compact(
            response.payload.data.map((data, index) =>
              data.non_field_errors ? values.currencies[index].code : null
            )
          );
          currenciesNotCreated.length === 0
            ? renderMessageSuccess()
            : renderMessageWarning(currenciesNotCreated);
          fetchCurrencyList();
        })
        .catch(error => {
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_CREATION_FAIL'),
            duration: 0,
          });
          setIsCreating(false);
          onClose();
        });
    });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setShowCreateModal(true)}
        disabled={!userCanCreate}
      >
        {buttonName}
      </Button>
      <Modal
        visible={showCreateModal}
        title={t('CONFIG_CURRENCY_CREATE_MODAL_TITLE')}
        width={560}
        bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={onConfirm}
        onCancel={onClose}
        okButtonProps={{ loading: isCreating }}
        cancelButtonProps={{ loading: isCreating }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ currencies: [''] }}
        >
          <Form.List name="currencies">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => {
                    return (
                      <Row
                        gutter={24}
                        key={field.key}
                        type="flex"
                        justify="end"
                      >
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, 'value_type']}
                            label={[field.label, t('FIELD_CURRENCY_TYPE')]}
                            rules={[
                              {
                                required: true,
                                message: t('REQUIRED_FIELD'),
                              },
                            ]}
                          >
                            <Select
                              placeholder={t('ACTION_SELECT')}
                              options={generateSelectOptions({
                                options: CURRENCIES_TYPES.map(currency => {
                                  return {
                                    ...currency,
                                    name: t(currency.name),
                                  };
                                }),
                              })}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, 'code']}
                            label={[field.label, t('FIELD_CODE')]}
                            rules={[
                              {
                                required: true,
                                message: t('REQUIRED_FIELD'),
                              },
                            ]}
                          >
                            <Select
                              placeholder={t('ACTION_SELECT')}
                              options={generateSelectOptions({
                                options: PROJECTION.CURRENCIES_OPTIONS.map(
                                  option => {
                                    return { ...option, name: t(option.name) };
                                  }
                                ),
                              })}
                            />
                          </Form.Item>
                        </Col>
                        <AddAndDeleteFormItem
                          fieldsLength={fields.length}
                          index={index}
                          disabled={isCreating}
                          addData={{
                            onClick: () => add(),
                            buttonName: 'CONFIG_CURRENCY_ADD_ACTION',
                          }}
                          deleteData={{
                            onClick: () => remove(field.name),
                            buttonName: 'CONFIG_CURRENCY_DELETE_ACTION',
                          }}
                        />
                      </Row>
                    );
                  })}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  currencyList: configuration.selectors.getCurrencyList(state),
});

const mapDispatchToProps = {
  createCurrencies: configuration.actions.createCurrencies,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCurrencyModal);
