import { CellStyle } from 'modules/core/components';
import { COLUMNS } from 'modules/core/constants';

export const columnsGenerator = (columns, format, has_restriction) =>
  columns &&
  columns.map(col => {
    return {
      ...(!col.is_transaction && {
        ...col,
        width: COLUMNS.WIDTH.EXPANDABLE,
        fixed: 'left',
      }),
      ...(col.is_transaction && {
        ...col,
        ellipsis: true,
        children: col.children.map(dataChildren => {
          return {
            ...dataChildren,
            width: COLUMNS.WIDTH.TRANSACTION,
            ellipsis: true,
            title: dataChildren.title,
            render: transaction => {
              if (transaction) {
                return (
                  <CellStyle.Number
                    value={transaction}
                    format={format}
                    has_restriction={has_restriction}
                  />
                );
              }
              return '-';
            },
          };
        }),
      }),
    };
  });
