import { SelectType } from 'modules/core/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DimensionSelect = ({
  options = [],
  onChange = () => {},
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <SelectType.Simple
      placeholder={t('ACTION_SELECT_DIMENSION')}
      options={options}
      onChange={onChange}
      style={{ width: 200 }}
      disabled={disabled}
    />
  );
};

export default DimensionSelect;
