import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  List,
  Card,
  DatePicker,
  Tooltip,
  Space,
  Popconfirm,
  notification,
  Button,
  Spin,
  message,
  Input,
  Typography as TypographyAnt,
} from 'antd';
import {
  InfoCircleOutlined,
  DeleteOutlined,
  RightCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  DropdownMenu,
  Typography,
  StatusTag,
} from 'modules/core/components';
import forecast from 'modules/forecast';
import { useTranslation } from 'react-i18next';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';

const {
  FORECAST__EXPENSES__EDIT,
  FORECAST__SALES__EDIT,
  FORECAST__CONSOLIDATED__EDIT,
  FORECAST__EXPENSES__DELETE,
  FORECAST__SALES__DELETE,
  FORECAST__CONSOLIDATED__DELETE,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const FORECAST__CONCEPT = {
  EDIT: [
    FORECAST__EXPENSES__EDIT,
    FORECAST__SALES__EDIT,
    FORECAST__CONSOLIDATED__EDIT,
  ],
  DELETE: [
    FORECAST__EXPENSES__DELETE,
    FORECAST__SALES__DELETE,
    FORECAST__CONSOLIDATED__DELETE,
  ],
};
const { Text } = TypographyAnt;

const ForecastPeriodList = ({
  dataSource,
  fetchForecasts,
  deleteForecast,
  editForecastName,
}) => {
  const [isDeletingForecast, setIsDeletingForecast] = useState(false);
  const [deleteForecastId, setDeleteForecastId] = useState(null);
  const [editName, setEditName] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  let history = useHistory();
  let { periodId } = useParams();
  const { t } = useTranslation();

  const onDeleteForecast = () => {
    setIsDeletingForecast(true);
    deleteForecast(deleteForecastId)
      .then(() => {
        fetchForecasts();
        setDeleteForecastId(null);
        setIsDeletingForecast(false);
      })
      .catch(error => {
        notification.error({
          message: t('FORECAST_DELETE_ERROR_FEEDBACK'),
          duration: 0,
        });
        setDeleteForecastId(null);
        setIsDeletingForecast(false);
      });
  };

  const handleEdit = value => {
    if (value !== dataEdit.name) {
      setIsEditing(true);
      editForecastName(dataEdit.id, { name: value })
        .then(() => {
          fetchForecasts();
          message.success(t('FORECAST_EDIT_NAME_SUCCESS_FEEDBACK'));
          setEditName(false);
          setIsEditing(false);
          setDataEdit({ ...dataEdit, name: value });
        })
        .catch(() => {
          message.error(t('FORECAST_EDIT_NAME_ERROR_FEEDBACK'));
          setEditName(false);
          setIsEditing(false);
        });
    } else {
      setEditName(false);
      setIsEditing(false);
    }
  };

  return (
    <List
      grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3 }}
      dataSource={dataSource}
      renderItem={forecast => {
        const { id, name, user_full_name, status, date } = forecast;
        return (
          <List.Item>
            <Card
              title={
                <Spin spinning={isEditing}>
                  {editName ? (
                    <Input
                      autoFocus
                      disabled={isEditing}
                      defaultValue={name}
                      onBlur={e => handleEdit(e.target.value)}
                      onPressEnter={e => handleEdit(e.target.value)}
                    />
                  ) : (
                    <Text style={{ width: 150 }} ellipsis={true} title={name}>
                      {name}
                    </Text>
                  )}
                </Spin>
              }
              headStyle={{ height: 56 }}
              bodyStyle={{ height: 126 }}
              extra={
                <Popconfirm
                  placement="bottomRight"
                  title={t('FORECAST_DELETE_POPCONFIRM')}
                  okText={t('ACTION_DELETE')}
                  okButtonProps={{ loading: isDeletingForecast }}
                  onConfirm={() => onDeleteForecast()}
                  cancelText={t('ACTION_CANCEL')}
                  onCancel={() => setDeleteForecastId(null)}
                  visible={deleteForecastId === id}
                >
                  <ButtonActionBar>
                    <StatusTag status={status} />
                    <Tooltip
                      title={t('FIELD_CREATOR_INFO', {
                        username: user_full_name,
                      })}
                    >
                      <Typography.Icon icon={InfoCircleOutlined} />
                    </Tooltip>
                    <DropdownMenu
                      title={t('ACTION_MORE')}
                      menu={[
                        {
                          title: t('ACTION_DELETE'),
                          icon: <DeleteOutlined />,
                          onClick: () => setDeleteForecastId(id),
                          disabled: !isUserAllowed(FORECAST__CONCEPT.DELETE),
                        },
                        {
                          title: t('ACTION_EDIT_NAME'),
                          icon: <EditOutlined />,
                          onClick: () => {
                            setEditName(true);
                            setDataEdit({ id: id, name: name });
                          },
                          disabled: !isUserAllowed(FORECAST__CONCEPT.EDIT),
                        },
                      ]}
                    />
                  </ButtonActionBar>
                </Popconfirm>
              }
              actions={[
                <ButtonActionBar position="start">
                  <Button
                    type="link"
                    onClick={() => history.push(`/forecast/${periodId}/${id}`)}
                  >
                    {t('ACTION_ENTER')}
                    <RightCircleOutlined />
                  </Button>
                </ButtonActionBar>,
              ]}
            >
              <Space direction="vertical">
                <Typography.Body level={2}>
                  {t('FORECAST_CUT_DATE_FIELD')}
                </Typography.Body>
                <DatePicker
                  style={{ width: 266 }}
                  defaultValue={moment(date, 'YYYY-MM-DD')}
                  disabled={true}
                  format="MMMM YYYY"
                />
              </Space>
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

const mapDispatchToProps = {
  deleteForecast: forecast.actions.deleteForecast,
  editForecastName: forecast.actions.editForecastName,
};

export default connect(null, mapDispatchToProps)(ForecastPeriodList);
