const general = {
  HI: 'Hello, {{name}}!',
  // ES: 'Spanish',
  // EN: 'English',
  YES: 'Yes',
  NO: 'No',

  // Layout
  PROFILE: 'Profile',
  SESSION: 'Session',
  MENU: 'Menu',
  LOGIN: 'Log in',
  SIGN_OFF: 'Sign off',
  SIGN_OFF_CONFIRM_MESSAGE:
    'Is your working day over? OK! Time to rest. If you are sure you want to sign off, let us say: see you soon!',
  NO_NOTIFICATIONS_MESSAGE: 'No new notifications',

  NOT_FOUND_SCREEN_TITLE: 'This page is not available',
  NOT_FOUND_SCREEN_DESCRIPTION:
    'The link you entered may be broken or the page has been removed.',
  ONBOARDING_TOUR_ACTIVATE: 'Take onboarding tour',

  // Call to actions
  ACTION_ACEPT: 'To accept',
  ACTION_ACTIVITIES: 'See activities',
  ACTION_ADJUST: 'Adjust',
  ACTION_ANULATE: 'Cancel',
  ACTION_APPLY: 'Apply',
  ACTION_APPROVE: 'Approve ',
  ACTION_CANCEL: 'Cancel',
  ACTION_CLEAR: 'Clear',
  ACTION_CLOSE: 'Close',
  ACTION_CONFIGURATE: 'Set up',
  ACTION_COPY: 'Create copy',
  ACTION_CREATE: 'Create',
  ACTION_CREATE_NEW: 'Create new',
  ACTION_DEFINE: 'Define',
  ACTION_DELETE: 'Delete',
  ACTION_DELETE_ROW: 'Delete row',
  ACTION_DELETE_ALL: 'Delete all',
  ACTION_DISTRIBUTE: 'To distribute',
  ACTION_DUPLICATE: 'Duplicate',
  ACTION_DOWNLOAD: 'Download',
  ACTION_DOWNLOAD_TABLE: 'Download chart',
  ACTION_DOWNLOAD_TEMPLATE: 'Download template',
  ACTION_EDIT: 'Edit',
  ACTION_EDIT_NAME: 'Edit name',
  ACTION_ENTER: 'More',
  ACTION_EXIT: 'Exit',
  ACTION_FINISH: 'Finish',
  ACTION_MORE: 'More actions',
  ACTION_NOTIFICATE: 'Notify',
  ACTION_OPEN_DETAIL: 'See detail',
  ACTION_PUBLISH: 'Publish',
  ACTION_REMOVE: 'Remove',
  ACTION_RETURN: 'Return',
  ACTION_RETURN_ANYWAY: 'Back anyway',
  ACTION_SAVE: 'Save',
  ACTION_SAVE_CHANGES: 'Save changes',
  ACTION_SEARCH: 'Search',
  ACTION_SELECT: 'Select',
  ACTION_STAGE: 'Simulate scenario',
  ACTION_START: 'Start',
  ACTION_STORE: 'File',
  ACTION_UPLOAD: 'Load',
  ACTION_UPLOAD_FILE: 'Upload file',
  ACTION_UPLOAD_START: 'Start upload',
  ACTION_UNDERSTAND: 'Got it',
  ACTION_NEXT: 'Next',
  ACTION_DELETE_SELECTION: 'Remove selection',
  ACTION_DELETE_PAGE: 'Delete page',
  ACTION_MODIFY: 'Modify',
  ACTION_UNDO: 'Undo',
  ACTION_GO_TO_DETAIL: 'Go to the detail',
  ACTION_UNAPPLY: 'Disengaging',
  ACTION_VIEW: 'View',
  ACTION_UPDATE_DATA: 'Update data',
  ACTION_ANALYTICS_PATCH: 'See analytics',
  ACTION_FIX: 'Fix',
  ACTION_KEEP: 'Keep',
  ACTION_SHOW_COMMENTS: 'Show comments',
  ACTION_COMMENT: 'Comment',
  ACTION_READ_MORE: 'Read more',
  ACTION_HIDE: 'Hide',
  ACTION_SHOW: 'Show',
  ACTION_REPLICATE: 'Replicate',
  ACTION_REPLICATE_VALUE: 'Replicate value',
  ACTION_EXPORT: 'Export',
  ACTION_SORT_ASC: 'Sort ascending',
  ACTION_SORT_DESC: 'Sort descending',
  ACTION_GO: 'Go',
  ACTION_SKIP: 'Skip',
  ACTION_LOAD_EXCHANGE_RATE: 'Upload exchange rate',
  ACTION_GO_TO_CONFIGURE: 'Go to Settings',
  ACTION_CONFIRM: 'Confirm',
  ACTION_ACTIVATE: 'Activate',
  ACTION_DEACTIVATE: 'Deactivate',
  ACTION_CONNECT: 'Connect',
  ACTION_FORMAT: 'Format',
  ACTION_PRINT_DATA: 'Print data',
  ACTION_UPLOAD_DATA: 'Load XLS data',
  ACTION_CALCULATE_VALUE: 'Calculate value',
  ACTION_SELECT_DIMENSION: 'Select dimension',
  ACTION_SELECT_VALUES: 'Select values',

  //States
  STATE_TAG_APPROVED: 'Approved',
  STATE_TAG_CANCELED: 'Canceled',
  STATE_TAG_EJECUTION: 'In action',
  STATE_TAG_ELABORATION: 'In progress',
  STATE_TAG_FINISHED: 'Finished',
  STATE_TAG_PUBLISHED: 'Published',
  STATE_TAG_STARTED: 'Started',
  STATE_TAG_ACTIVE: 'Active',
  STATE_TAG_INACTIVE: 'Inactive',
  STATE_TAG_UNDEFINED: 'Undefined',
  STATE_TAG_FINISHED_SUCCESS: 'Finished successfully',
  STATE_TAG_DESCONNECTED: 'Disconnected',
  STATE_TAG_PROCESSING: 'Processing',
  STATE_TAG_PENDING: 'Pending',
  STATUS_DATE_APPROVED: 'Approved',
  STATUS_DATE_PUBLISHED: 'Published',
  LOADING_STATUS: 'Loading status',
  LOADING_STATUS_ERROR: 'Error',
  LOADING_STATUS_SUCCESS: 'Complete',

  //BUDGET_STATUS
  // TODO: Definir todos los estados, en acuerdo con back y UI
  in_elaboration: 'In progress',
  making: 'In progress',
  approved: 'Approved',
  published: 'Published',
  initiated: 'Initiated',

  //Entities labels
  LABEL_ACTIVITY: 'Activity',
  LABEL_ACTIVITIES: 'Activities',
  LABEL_ACCOUNTS: 'Account Charts',
  LABEL_ADJUSTMENTS: 'Adjustments',
  LABEL_ANALYSIS: 'Analysis',
  LABEL_BUDGET: 'Budget',
  LABEL_BUDGETS: 'Budgets',
  LABEL_CONCEPTS: 'Concepts',
  LABEL_CONFIG: 'Setting',
  LABEL_CONTROL: 'Controlling',
  LABEL_DETAIL: 'Detail',
  LABEL_DIMENSIONS: 'Dimensions',
  LABEL_DISTRIBUTIONS: 'Distributions',
  LABEL_VALUES_DIMENSION: 'Dimension values',
  LABEL_EXPENSES: 'Expenses',
  LABEL_FORECAST: 'Forecast',
  LABEL_PERIODS: 'Periods',
  LABEL_PERIOD: 'Period',
  LABEL_PLANNING: 'Planning',
  LABEL_PROFILE: 'Profile',
  LABEL_REPORTS: 'Reports',
  LABEL_CUSTOM_REPORTS: 'Custom reports',
  LABEL_REPORT: 'Report',
  LABEL_OTHERS: 'Others',
  LABEL_SALES: 'Sales',
  LABEL_USERS: 'Users',
  LABEL_USER: 'User',
  LABEL_SECURITY: 'Security',
  LABEL_ROLES: 'Roles',
  LABEL_ROLES_AND_USERS: 'Roles and Users',
  LABEL_ROLE: 'Role',
  LABEL_VARIABLE: 'Variable',
  LABEL_VARIABLES: 'Variables',
  LABEL_MODIFIERS: 'Modifiers',
  LABEL_PROJECTION_ELEMENTS: 'Projecting means',
  LABEL_FORMULAS: 'Formulas',
  LABEL_FORMULA: 'Formula',
  LABEL_BUSINESS_RULES: 'Business rules',
  LABEL_BUSINESS_RULE: 'Business rule',
  LABEL_FACTOR: 'Factor',
  LABEL_FACTORS: 'Factors',
  LABEL_CONSOLIDATION: 'Consolidation',
  LABEL_NEW: 'New',
  LABEL_CONVENTIONAL_LOAD: 'Conventional load',
  LABEL_FLAT_LOAD: 'Flat load',
  LABEL_FLAT_DOWNLOAD: 'Flat download',
  LABEL_RESUME: 'Resume',
  LABEL_FINANCIAL_GUIDELINES: 'Financial guidelines',
  LABEL_REAL: 'Real',
  LABEL_REALES: 'Real',
  LABEL_NAME_FILE: 'File name',
  LABEL_FILE_TYPE: 'File type',
  LABEL_LOAD_DETAILS: 'File upload details',
  LABEL_LOAD_DETAIL: 'File upload detail',
  LABEL_LOAD_DATA: 'Of data',
  LABEL_LOAD_ASSOCIATION_DATA: 'Account-concept Association',
  LABEL_EXCHANGE_RATE: 'Exchange rate',
  LABEL_SELECT_OPTION_INTEGRATION:
    'Select what kind of information you want to upload',
  LABEL_SELECT_EXPENSES_BUDGET: 'Select one Expenses budget',
  LABEL_SELECT_SALES_BUDGET: 'Select one Sales budget',
  LABEL_CURRENCY: 'Currency',
  LABEL_MULTICURRENCY: 'Multicurrency',
  LABEL_RATIO: 'Ratio',
  LABEL_MORE_INFO: 'More information',
  LABEL_FINANCIAL: 'Financial',
  LABEL_ECONOMIC: 'Economic',
  LABEL_ECONOMIC_CONTROL: 'Economic control',
  LABEL_FINANCIAL_CONTROL: 'Financial control',
  LABEL_LAST_UPDATE: 'Last update {{date}}',
  LABEL_PUBLISHED_DATA_UNTIL: 'Published or approved data until {{date}}',
  LABEL_APPROVED_DATA_UNTIL: 'Approved data until {{date}}',
  LABEL_NO_DATE: 'We still have no data to show',
  LABEL_STATUS_CHANGE: 'Status change',
  LABEL_MY_DATA: 'My data',
  LABEL_INTEGRATION: 'Integrations',
  LABEL_DESTINATION_PROJECTION: 'Destination projection',
  LABEL_EXAMPLE_TEMPLATE: 'Example template',
  LABEL_TUTORIALS: 'Tutorials',
  LABEL_USER_MANUAL: 'User manual',
  LABEL_HELP: 'Help',
  LABEL_SHOPPING: 'Shopping',
  LABEL_BASE_VALUE: 'Base value: {{value}}',
  LABEL_REPORT_STRUCTURE: 'Report structure',
  LABEL_OPERATING_INCOME: 'Operating income',
  LABEL_OPERATING_EXPENSES: 'Operating expenses',
  LABEL_EXTRAORDINARY_INCOME: 'Extraordinary income',
  LABEL_EXTRAORDINARY_EXPENSES: 'Extraordinary expenses',
  LABEL_PROJECTION: 'Projection',
  LABEL_MONTHLY: 'Monthly',

  //Fields
  REQUIRED_FIELD: 'Mandatory field',
  REQUIRED_FIELD_CHARACTERS: 'Cannot exceed {{cant}} characters',
  FIELD_ALL: 'All',
  FIELD_ACCOUNT: 'Account',
  FIELD_ACCOUNTS: 'Accounts',
  FIELD_ACTION: 'Action',
  FIELD_ACTIONS: 'Actions',
  FIELD_AMOUNT: 'Amount',
  FIELD_BEHAVIOR: 'Behaviour',
  FIELD_CODE: 'Code',
  FIELD_COMPARISONS: 'Comparisons',
  FIELD_SEGMENTED: 'Segmented',
  FIELD_CONCEPT: 'Concept',
  FIELD_COUNT: 'Quantity',
  FIELD_DATE: 'Date',
  FIELD_CREATION_DATE: 'Creation date',
  FIELD_CREATOR_INFO: 'Created by {{username}}',
  FIELD_DEADLINE: 'Upload deadline',
  FIELD_DESCRIPTION: 'Description',
  FIELD_DIMENSION: 'Dimension',
  FIELD_DIMENSION_DIMENSIONS: 'Dimension/s',
  FIELD_DIMENSION_VALUES: 'Dimension value(s)',
  FIELD_EMAIL: 'Email',
  FIELD_END_DATE: 'End month',
  FIELD_IMPACT: 'Impact',
  FIELD_NAME: 'Name',
  FIELD_TIME: 'Time',
  FIELD_OBSERVATIONS: 'Observations',
  FIELD_ORDER: 'Order',
  FIELD_ORIGINAL: 'Original',
  FIELD_PERCENTAGE: 'Percentage',
  FIELD_START_DATE: 'Start month',
  FIELD_STATUS: 'Status',
  FIELD_SURNAME: 'Surname',
  FIELD_TOTAL: 'Total',
  FIELD_TYPE: 'Type',
  FIELD_VALUE: 'Value',
  FIELD_VALUE_TYPE: 'Value type',
  FIELD_CANCELLATION: 'Cancellation',
  FIELD_YEAR_MONTH: 'Year/Month',
  FIELD_MOVEMENTS: 'Movements',
  FIELD_ASSOCIATED_CONCEPT: 'Associated concept',
  FIELD_ASSOCIATED_CONCEPTS: 'Associated concepts',
  FIELD_ASSOCIATED_CONCEPT_CONCEPTS: 'Associated concept(s)',
  FIELD_EVOLUTION: 'Monthly evolution',
  FIELD_ASSOCIATED_DIMENSIONS: 'Associated dimensions',
  FIELD_ASSOCIATED_DIMENSION: 'Associated dimension',
  FIELD_COMMENT: 'Commentary',
  FIELD_ATENTION: 'Attention!!',
  FIELD_IMPORTANT: 'Important!',
  FIELD_LEVEL: 'Level',
  FIELD_ACCOUNTS_IMPACTED: 'Impacted account(s)',
  FIELD_TOTAL_AMOUNT: 'Total amount',
  FIELD_TOTAL_LINES: 'Total lines',
  FIELD_PREDETERMINED_BUDGET: 'Predetermined budget',
  FIELD_APPLIED: 'Applied',
  FIELD_UNAPPLIED: 'Unengaged',
  FIELD_LINE_REPORT: 'Report line',
  FIELD_LOAD_TYPE: 'Type of load',
  FIELD_RED: 'Red',
  FIELD_LOADING_SOURCE: 'Source upload',
  FIELD_PRINCIPAL: 'Principal',
  FIELD_SECONDARY: 'Secondary',
  FIELD_NUMBER: 'Number',
  FIELD_CURRENCY_TYPE: 'Type of currency',
  FILED_ORIGIN_CURRENCY: 'Origin currency',
  FIELD_FUNCTIONAL_CURRENCY: 'Functional currency',
  FIELD_MANUAL_LOAD: 'Manual load',
  FIELD_INTEGRATION_LOAD: 'Integration load',
  FIELD_ORIGIN_CURRENCIES: 'Origin currencies',
  FIELD_FULL_VALUE: 'Full value',
  FIELD_VARIATION: 'Variation',
  FIELD_LAST_UPDATED: 'Last update',
  FIELD_DIMENSIONS_AMOUNT: 'Dimensions amount',

  //Placeholders
  ACCOUNT_PLACEHOLDER: 'Select an account',
  DIMENSION_PLACEHOLDER: 'Select a dimension',
  MONTH_PLACEHOLDER: 'Select a month',
  UPLOAD_FILE_PLACEHOLDER: 'Select the file you want to upload',
  VALUES_PLACEHOLDER: 'Select the values',
  VALUE_PLACEHOLDER: 'Select a value',
  ROW_SELECT_PLACEHOLDER: 'Select a row',
  WRITE_COMMENT_PLACEHOLDER: 'Write comment',
  ENTER_AMOUNT_PLACEHOLDER: 'Enter amount',

  //Feedbacks
  FEEDBACK_LOADING: 'Loading...',
  FEEDBACK_LOADING_CHANGES: 'Saving changes',
  FEEDBACK_DEFAULT_ERROR: 'We had a problem',
  FEEDBACK_EDIT_REPORT_NAME_ERROR: 'This field may not be blank',
  FEEDBACK_SAVE_CHANGES_SUCCES: 'Changes saved',
  FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE: 'Error saving changes',
  FEEDBACK_CHANGES_SAVED_SUCCESS: 'Changes were saved successfully',
  FEEDBACK_SAVE_CHANGES_FAIL: 'An error occurred trying to save the changes',
  FEEDBACK_CREATION_FAIL: 'An error ocurred in creation. Please, try again.',
  FEEDBACK_DELETE_FAIL: 'An error ocurred removing. Please, try again.',
  FEEDBACK_UPLOAD_FILE_SUCCESS:
    "The {{configModule}}'s file has been uploaded successfully",
  FEEDBACK_UPLOAD_PROJECITION_FILE_SUCCESS:
    'All the files have already been uploaded in the {{projectionType}}. {{descriptionType}}',
  FEEDBACK_UPLOAD_FILE_WITH_ERRORS:
    'The file was uploaded but with some errors',
  FEEDBACK_UPLOAD_FILE_ERROR: 'There was an error loading the file',
  FEEDBACK_UPLOAD_FILE_HEADBOARD_ERROR: 'Invalid header',

  FEEDBACK_WELLDONE: 'Great job!',
  FEEDBACK_VERY_GOOD: 'Very good!',
  FEEDBACK_ACTION_DEFAULT_ERROR: 'Please try again later to {{action}}',
  FEEDBACK_DEFAULT_NOT_FOUND: 'No results found',
  FEEDBACK_NOT_DATA_REQUEST: `¡Sorry! We couldn't find the requested data`,
  FEEDBACK_DELETE_SELECTION_SUCCESS: 'The selection was deleted successfully',
  FEEDBACK_DELETE_DATA_SUCCESS: 'The data was deleted successfully',
  FEEDBACK_DELETE_SELECTION_ERROR:
    "We couldn't remove the selection. Please try again.",
  FEEDBACK_DELETE_DATA_ERROR: "We couldn't remove the data. Please try again.",
  FEEDBACK_CREATE_SUCCESS: 'The creation was successful',
  FEEDBACK_INPUT_PERCENTAGE: 'Assign percentage to the filtered value (s)',
  FEEDBACK_EXPORT_CHARTS_AS_IMAGE_ERROR:
    'An error occurred when exporting. Please try again',
  FEEDBACK_INTEGRATION_FILE_UPLOAD_SUCCESS:
    'Your Base Files are were successfully uploaded',
  FEEDBACK_INTEGRATION_FILE_UPLOAD_SUCCESS2:
    'See processing status in {{action}}',
  FEEDBACK_INTEGRATION_FILE_UPLOAD_ERROR_MESSAGE: 'File upload error',
  FEEDBACK_CONFIRM_ERROR_DESCRIPTION: 'An error occurred during confirmation.',
  FEEDBACK_DELETE_VALUES_ERROR: "We couldn't find any values to delete",
  FEEDBACK_DELETE_VALUES_SUCCESS: 'The values were deleted successfully',
  MESSAGE_PROCESS_STATUS_SUCCESS: 'Finished loading success',
  MESSAGE_PROCESS_STATUS_PENDING: 'Pending process',
  MESSAGE_PROCESS_STATUS_PROCESSING: 'Processing loaded information',
  FEEDBACK_LOAD_DATA_SUCCESS: 'The data was charged successfully',
  FEEDBACK_LOAD_DATA_ERROR: 'An error occurred when loading the data',
  FEEDBACK_UPDATE_DATA_SUCCESS: 'Successful update',
  FEEDBACK_UPDATE_DATA_ERROR: 'An error occurred when updating the data',
  FEEDBACK_DOWNLOAD_FILE_ERROR: 'An error occurred when downloading the file.',
  FEEDBACK_DOWNLOAD_FILE_SUCCESS: 'Successful download',

  //Info texts
  TABLE_SETTING_MODAL_TITLE:
    'Set the aspect of the content and shape of your table',
  DISPLAY_MODAL_SETTINGS_TITLE: 'Display Preferences',
  COLUMNS_SETTING_MODAL_PLACEHOLDER: 'Select the columns you want to see',
  ALERT_NOT_ALLOWED: 'You do not have permission to perform this action.',
  ALERT_NULL_DATA: 'You still have data to load',
  ALERT_INCOMPLETE_VALUES: 'Incomplete values',
  EMPTY_STATE_DEFAULT_TITLE: "Let's do it!",
  EMPTY_STATE_DRAG_FILE_TEXT:
    'Click or drag the file into this area to upload.',
  EMPTY_STATE_NO_DATA_TITLE: 'We still have no information to show',
  EMPTY_STATE_UPLOAD_DEFAULT_DESCRIPTION: 'Start uploading your files',
  UPLOAD_FILE_TITLE: 'Upload files to Plika',
  INFO_UPLOAD_FILE_MODAL_DESCRIPTION:
    'In order to load, remember to build your file with the predetermined template',
  INFO_UPLOAD_FILE_DOWNLOAD_ACTION: 'Download it.',
  INFO_UPLOAD_FILE_BODY_1:
    'Remember that all the files you upload must be created with the template that we have predetermined for you and your team',
  INFO_UPLOAD_FILE_BODY_2:
    "If you don't have it yet, you can download it from here.",
  INFO_UPLOAD_TOOLTIP: 'You can do individual or massive uploads',
  EXIT_MODAL_DESCRIPTION:
    'When you sign off, you will lose all the information that you have uploaded so far. Do you want to go out anyway?',
  UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_1: 'Downlaod this',
  UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_2: 'file',
  UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_3:
    'for more information on the erroes that ocurred.',
  POPCONFIRM_DELETE_SELECTION_TITLE:
    'Are you sure you want to delete this selection?',
  POPCONFIRM_DELETE_DEFAULT_TITLE: 'Are you sure you want to remove {{name}}?',
  POPCONFIRM_DELETE_ALL_PAGES:
    'You are about to delete the entire contents of {{proyectionName}}. Are you sure you want to delete all pages?',
  POPCONFIRM_EDIT_CANCEL: 'Are you sure you want to cancel editing?',
  TOOLTIP_NOT_APPROVE_TITLE: 'First you publish the data to enable this action',
  POPCONFIRM_SAVE_CHANGES: 'Are you sure you want to save the changes made?',
  POPOVER_NOT_CONSOLIDATED_FIXED: `There isn't fixed {{type}}.To view your data you must set it as default`,
  POPOVER_DEFAULT_CONSOLIDATION_DESCRIPTION:
    'These are your default budgets. To modify must go to the tab consolidated planning and forecast respectively.',
  POPOVER_NOT_DEFAULT_CONSOLIDATION_DESCRIPTION: `There aren't default budgets. To view them you must go to the consolidated tab of planning and forecast respectively.`,
  TOOLTIP_CHECK_SOLVED_TITLE: 'Mark as solved',
  INPUT_ORDER_KPIS_TITLE: 'Order KPI',
  ALERT_INTEGRATION_LOADING_OPTIONS:
    'You can choose to upload just one file at a time or a bulk of files, it is your choice!',
  INFO_FILES_UPLOAD: 'Click here or drag files to start uploading them',
  INTEGRATION_MODAL_TITLE: 'Upload Base Files',
  TOOLTIP_INTEGRATION_MESSAGE: 'Upload files from your database',
  TOOLTIP_INTEGRATION_MODAL_INFO:
    'Here you can upload files from your database to see its information reflected in Plika',
  TOOLTIP_DRAGGER_INFO:
    'To upload any files first you need to set what kind of information you are uploading',
  TOOLTIP_SELECT_BUDGET:
    'Select the Sales and/or Expenses budgets where you want to send this information',
  MODAL_DELETE_CONFIRM_TEXT: 'Do you want to delete it anyway?',
  DELETE_ALL_VALUES: 'Delete all dimension values',
  POPCONFIRM_DELETE_VALUES_TITLE: 'Are you sure you want to delete all values?',

  REFERENCE_DESCRIPTION: 'Values ​​in {{currencyname}}',
  NEED_HELP: 'Do you need help with Plika?',

  //Indicators
  CORESPONDS: 'Corresponds to {{cuenta}}',
  DELTA: 'Delta',
  REAL: 'Real',
  SALES_TOTAL: 'Total sales',
  EXPENSES_TOTAL: 'Total expenses',
  LAST_FORECAST: 'Last forecast',
  REAL_LAST_PERIOD: 'Real previous period',
  REAL_LAST_YEAR: 'Real previous year',
  VARIATION_MONEY: 'Var {{prefix}}',
  VARIATION_PERCENTAGE: 'Var %',

  // Traduccion de data_back
  budget: 'Budget',
  real: 'Real',
  prev_real: 'Real previous period',
  last_forecast: 'Last forecast',
  Ventas: 'Sales',
  Gastos: 'Expenses',
  init: 'Initial',
  secuencial: 'Sequential',
  over_amount: 'Over amount',
  Activa: 'Active',
  Inactiva: 'Inactive',
  Cuenta: 'Account',
  Sucursal: 'Subsidiary',
  'Centro de costos': 'Cost center',
  OTROS: 'Others',

  integer: 'Integer',
  decimals: 'Decimals',
  millions: 'Thousands/millions',
  auto: 'Automatic',
  predefined: 'Predefined',
  sales: 'Sales',
  expenses: 'Expenses',
  pnl: 'P&L',

  DATE_AT_TIME: 'at',

  DETAIL_TITLE: 'Detail {{name}}',
  DUPLICATE_NAME: '{{name}} - duplicate ',
  COPY_NAME: '{{name}} - copy ',

  SHOW_FILTERS_MENU: 'Show filters',
  HIDE_FILTERS_MENU: 'Hide filters',

  CONSOLIDATION_FILE_NAME: 'Plika -  Consolidation',

  DISPLAY_TABLE_SETTING: 'Amounts format',

  // CHARTS
  CHARTS_MODAL_SETTINGS_SUBTITLE: 'Set the appearance for displaying graphics',
  FIELD_LABEL: 'Tags',
  FIELD_LEGENDS: 'Legends',
  LABEL_LAYOUT: 'Layout',
  LABEL_DATA: 'Data',
  LABEL_PADDING: 'Padding',
  LABEL_POSITION_MODE: 'Layout mode',
  LABEL_DIMENSION_FILTER: 'Select the values you want to reflect',
  LABEL_GROUPED: 'Grouped',
  LABEL_STACKED: 'Stacked',
  LABEL_INNER: 'Inside',
  LABEL_OUTER: 'Outside',
  LABEL_NAME_VALUE: 'Name:value',
  LABEL_FORMAT: 'Format',
  LABEL_VALUE_TYPE: 'Value',
  LABEL_VALUE_POSITION: 'Location',
  LABEL_FONT_SIZE: 'Font size',
  CHARTS_VALUES_QUANTITY: 'Number of visible values',
  CHARTS_TOOLTIP_INFO_LEGENDS:
    'You can display a legend at a time for each pie chart. If you want to see one particular, click on and see to the left of the same', //TODO: Pedir tooltip a UI
  CHART_TYPE_PIE: 'Pie',
  CHART_TYPE_BAR: 'Bars',
  CHART_TYPE_LINE: 'Lines',
  CHART_EXPORT_AS_IMAGE_FILE_NAME: 'Chart {{chartTitle}}.{{format}}',
};

export default general;
