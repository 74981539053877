import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Space, Row, Col } from 'antd';
import {
  PlusCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Typography,
  DropdownMenu,
  ButtonActionBar,
} from 'modules/core/components';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import { renderPrefix } from 'modules/core/utils';
import { CellInput, CellSelect } from './Cells';
import adjustments from 'modules/adjustments';
import { AdjustmentUploadModal } from '../../components';
import { useTranslation } from 'react-i18next';

import './AdjustmentCreationTable.scss';

// NOTE: Las deshabilitaciones las maneja el campo 1 que representa a la Cuenta,
// quizas lo mejor seria que el backend envie esa info
const AdjustmentCreationTable = ({
  selectorColumns,
  valueDate = null,
  dataSource,
  fetchAdjustmentSelectorColumns,
  addAdjustmentRow,
  copyAdjustmentRow,
  removeAdjustmentRow,
  editAdjustmentRow,
  downloadAdjustmentTemplateXLS,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  const { t } = useTranslation();

  useEffect(() => {
    fetchAdjustmentSelectorColumns().then(() => setIsTableLoading(false));
  }, [fetchAdjustmentSelectorColumns]);

  const renderTopActions = () => (
    <ButtonActionBar>
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ADJUSTMENT_LOAD_MOVEMENT_ACTION_TITLE'),
            icon: <UploadOutlined />,
            onClick: () => setShowUploadModal(true),
          },
          {
            title: t('ACTION_DOWNLOAD_TEMPLATE'),
            icon: <FileExcelOutlined />,
            onClick: () => downloadAdjustmentTemplateXLS(),
          },
        ]}
      />
    </ButtonActionBar>
  );

  const dimensionColumns = selectorColumns
    .filter(item => item.data_index !== 'amount')
    .map(item => {
      return {
        title: item.title,
        dataIndex: item.data_index,
        width: 200,
        render: (record, row) => {
          return (
            <CellSelect
              key={item.dimension}
              label={item.title}
              disabled={
                !valueDate || (item.data_index !== 1 && row['1'] === null)
              }
              fetchData={{
                date: valueDate,
                desired_dimension_id: item.data_index,
                ...(item.data_index !== 1 && {
                  value_id: row['1'],
                  dimensionId: 1,
                }),
              }}
              value={record}
              onChange={newValue =>
                editAdjustmentRow(row.id, { [item.data_index]: newValue })
              }
            />
          );
        },
      };
    });

  //NOTE: Por como funciona antd con los scroll de tabla y las columnas con ancho fijo,
  // es necesario dejar al menos una columna sin ancho fijo.
  // https://ant.design/components/table/#components-table-demo-fixed-columns
  const columns = [
    { title: '', dataIndex: 'id', render: id => '' },
    {
      title: t('FIELD_AMOUNT'),
      dataIndex: 'amount',
      width: 250,
      fixed: 'right',
      render: (record, row) => (
        <Space align="end">
          <Space direction="vertical">
            <CellInput
              defaultValue={record}
              disabled={row['1'] === null}
              onUpdateValue={value =>
                editAdjustmentRow(row.id, { amount: value })
              }
              prefix={renderPrefix(functionalCurrencyData)}
            />
          </Space>
          <Button
            type="text"
            shape="circle"
            title={t('ACTION_DUPLICATE')}
            disabled={row['1'] === null}
            icon={
              <Typography.Icon
                icon={CopyOutlined}
                type={row['1'] === null ? 'disable' : 'primary'}
              />
            }
            onClick={() => copyAdjustmentRow(row.id)}
          />
          <Button
            type="text"
            shape="circle"
            title={t('ADJUSTMENT_DELETE_MOVEMENT_ACTION_TITLE')}
            disabled={dataSource.length <= 1}
            icon={
              <Typography.Icon
                icon={DeleteOutlined}
                type={dataSource.length <= 1 ? 'disable' : 'primary'}
              />
            }
            onClick={() => removeAdjustmentRow(row.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Col span={24}>{renderTopActions()}</Col>
        <AdjustmentUploadModal
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
        />
        <div className="adjustment-creation-table-title">
          <Typography.Subtitle
            level={2}
            type={!valueDate ? 'disable' : 'primary'}
          >
            {t('FIELD_MOVEMENTS')}
          </Typography.Subtitle>
        </div>
        <Table
          rowKey="id"
          size="small"
          showHeader={false}
          loading={isTableLoading}
          dataSource={dataSource}
          columns={[...dimensionColumns, ...columns]}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Col>
      <Col span={24}>
        <Button
          type="link"
          disabled={!valueDate}
          icon={<PlusCircleOutlined />}
          onClick={addAdjustmentRow}
        >
          {t('ADJUSTMENT_ADD_MOVEMENT_ACTION')}
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  selectorColumns: adjustments.selectors.getSelectorColumns(state),
});

const mapDispatchToProps = {
  fetchAdjustmentSelectorColumns:
    adjustments.actions.fetchAdjustmentSelectorColumns,
  addAdjustmentRow: adjustments.actions.addAdjustmentRow,
  copyAdjustmentRow: adjustments.actions.copyAdjustmentRow,
  removeAdjustmentRow: adjustments.actions.removeAdjustmentRow,
  editAdjustmentRow: adjustments.actions.editAdjustmentRow,
  downloadAdjustmentTemplateXLS:
    adjustments.actions.downloadAdjustmentTemplateXLS,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdjustmentCreationTable);
