import React from 'react';

import './ChartContainer.scss';

const ChartContainer = ({ className = '', style = {}, children }) => (
  <div className={`chart-container ${className}`} style={style}>
    {children}
  </div>
);

export default ChartContainer;
