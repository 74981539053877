import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { SiderLayout } from 'modules/core/layouts';
import {
  ButtonActionBar,
  Typography,
  TableSettingsModal,
  RangePicker,
  PeriodNavigator,
} from 'modules/core/components';
import { Button, Row, Col, Space, Spin } from 'antd';
import { OtherReportTable, OtherReportCharts } from './components';
import { DownloadOutlined } from '@ant-design/icons';
import { TABLES, CONCEPTS, REPORT } from 'modules/core/constants';
import reports from 'modules/reports';
import planning from 'modules/planning';
import configuration from 'modules/configuration';
import login from 'modules/login';

const OtherReportDetail = ({
  periodDetail,
  reportTableData,
  reportColumns,
  lineReportAccount,
  customReportsDetail,
  fetchPeriodDetail,
  fetchReportColumns,
  fetchCustomReportDetail,
  fetchOtherReportStructure,
  downloadCusomReportXLS,
  loggedUser: { email },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [tableSettings, setTableSettings] = useState(
    TABLES.DEFAULT_SETTINGS(email)
  );
  const [selectedDates, setSelectedDates] = useState({});

  let { periodId, reportId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setIsScreenLoading(true);
    Promise.all([
      fetchPeriodDetail(periodId),
      fetchReportColumns(reportId, { period_id: periodId }),
      fetchCustomReportDetail(reportId, {
        period_id: periodId,
        type: REPORT.REPORTS_KEYS.FNL_KEY,
      }),
      fetchOtherReportStructure(reportId, { row_info: 'True' }),
    ])
      .then(() => setIsScreenLoading(false))
      .catch(e => console.log(e));
  }, [
    reportId,
    periodId,
    fetchPeriodDetail,
    fetchReportColumns,
    fetchCustomReportDetail,
    fetchOtherReportStructure,
  ]);

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_ANALYSIS'),
          url: `/analisis/${periodId}`,
        },
        {
          name: t('REPORT_PLIKA_CARD_TITLE'),
          url: `/analisis/${periodId}/reportesplika?tab=${CONCEPTS.TYPE.FINANCIAL_KEY}`,
        },
        {
          name: t('LABEL_FINANCIAL'),
          url: `/analisis/${periodId}/reportesplika?tab=${CONCEPTS.TYPE.FINANCIAL_KEY}`,
        },
        {
          name: t('LABEL_CUSTOM_REPORTS'),
        },
        { name: customReportsDetail.name ? customReportsDetail.name : '' },
      ]}
    />
  );

  const renderRangePicker = () => (
    <RangePicker
      onSetRange={setSelectedDates}
      periodDate={{
        startDate: periodDetail?.start_date,
        endDate: periodDetail?.end_date,
      }}
      defaultPickerValue={
        !_.isEmpty(periodDetail)
          ? [moment(periodDetail?.start_date), moment(periodDetail?.end_date)]
          : null
      }
      defaultValue={
        !_.isEmpty(selectedDates) && [
          moment(selectedDates['start-date'], 'YYYY-MM-01'),
          moment(selectedDates['end-date'], 'YYYY-MM-01'),
        ]
      }
    />
  );

  const renderTopActions = () => (
    <Space direction="horizontal">
      {renderRangePicker()}
      <Typography.Body>{` ${t('FIELD_LINE_REPORT')}: ${
        !_.isEmpty(lineReportAccount) && _.keys(lineReportAccount)[0]
      }`}</Typography.Body>
    </Space>
  );

  const renderTableActions = () => (
    <ButtonActionBar>
      <Button
        type="text"
        shape="circle"
        title={t('ACTION_DOWNLOAD_TABLE')}
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={() =>
          downloadCusomReportXLS(
            reportId,
            { period_id: periodId, ...selectedDates },
            t('REPORT_FILE_NAME_DEFAULT', {
              reportName: customReportsDetail.name,
              periodName: periodDetail.name,
            })
          )
        }
      />
      <TableSettingsModal
        onConfirm={setTableSettings}
        options={reportColumns.filter(column => column.is_transaction)}
        settings={tableSettings}
      />
    </ButtonActionBar>
  );

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading}>
        {!isScreenLoading && (
          <>
            {renderHeader()}
            <Row gutter={[24, 24]}>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>
                <OtherReportCharts
                  reportId={reportId}
                  periodId={periodId}
                  selectedDates={selectedDates}
                  lineReportAccount={
                    !_.isEmpty(lineReportAccount) &&
                    _.keys(lineReportAccount)[0].toLowerCase()
                  }
                  dimensionName={reportColumns[0]?.title.toLowerCase()}
                />
              </Col>
              <Col span={24}>{renderTableActions()}</Col>
              <Col span={24}>
                <OtherReportTable
                  reportId={reportId}
                  periodId={periodId}
                  selectedDates={selectedDates}
                  reportColumns={reportColumns}
                  tableSettings={tableSettings}
                />
              </Col>
            </Row>
          </>
        )}
      </Spin>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  customReportsDetail: reports.selectors.getCustomReportDetail(state),
  reportColumns: reports.selectors.getCustomReportColumns(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
  lineReportAccount: configuration.selectors.getOtherReportsStructure(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchReportColumns: reports.actions.fetchCustomReportColumns,
  downloadCusomReportXLS: reports.actions.downloadCusomReportXLS,
  fetchCustomReportDetail: reports.actions.fetchCustomReportDetail,
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  fetchOtherReportStructure: configuration.actions.fetchOtherReportStructure,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherReportDetail);
