import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Space,
  Tooltip,
  message,
  notification,
  Row,
  Col,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  usePagination,
  useToggle,
  useCollapser,
  useSorter,
} from 'modules/core/customHooks';
import {
  TableSettingsModal,
  Typography,
  ButtonActionBar,
  ShowCommentsButton,
  DeleteSelectionButton,
} from 'modules/core/components';
import {
  calculateScrollX,
  dateMMYYYY,
  formItemsHelper,
  columnsHelper,
} from 'modules/core/utils';
import {
  PROJECTION,
  COLUMNS,
  ROWS,
  TABLES,
  ACCES_USERS,
} from 'modules/core/constants';
import planning from 'modules/planning';
import login from 'modules/login';

import './RealControlTable.scss';

const APPROVE_DATE_KEY = 0;

//TO DO: ajustar con la data proveniente de back
const RealControlTable = ({
  projectionId,
  selectedDates,
  refetch: { needRefetch = false, setRefetch = null, filtersRefetch = null },
  projectionColumnsImporte,
  // projectionColumns,
  projectionRows: { transactions, count },
  fetchProjectionColumns,
  fetchProjectionRows,
  download,
  canDelete = false,
  fetchProjectionDetail,
  deleteControlRow,
  filters,
  filterOptions,
  onFilter,
  syncProjectionComment,
  createTransactionComment,
  deleteTransactionComment,
  loggedUser: { email },
}) => {
  const [isCollapsed, toogleIsCollapsed, isCollapsing] = useCollapser();
  const [showRowsComment, toggleShowRowsComment] = useToggle(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS(email),
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
    showNegativeInRed: false,
  });
  const [tablePagination, setTablePagination, totalizerRows] = usePagination({
    totalizerRows: 1,
  });
  const [tableSorter, setTableSorter] = useSorter();
  const [dataSelection, setDataSelection] = useState([]);
  const [isDeletingSelection, setIsDeletingSelection] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchProjectionColumns(projectionId);
  }, [fetchProjectionColumns, projectionId, needRefetch]);

  useEffect(() => {
    setIsTableLoading(true);
    fetchProjectionRows(projectionId, {
      ...tablePagination,
      ...selectedDates,
      ...filters,
      ...tableSorter,
    }).then(() => {
      setIsTableLoading(false);
      if (needRefetch) {
        setRefetch(prevRefetch => {
          return { ...prevRefetch, evolution: false };
        });
      }
    });
  }, [
    fetchProjectionRows,
    projectionId,
    filters,
    tableSorter,
    selectedDates,
    tablePagination,
    needRefetch,
    setRefetch,
  ]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const handleOnFilter = newFilters => {
    setTablePagination({ current: 1 });
    onFilter(newFilters);
  };

  const handleOnChangeTable = (pagination, filters, sorter) => {
    if (needRefetch) {
      setTablePagination({ current: 1 });
      setTableSorter();
    } else {
      setTablePagination(pagination);
      setTableSorter(sorter);
    }
  };

  const handleDeleteSelection = () => {
    setIsDeletingSelection(true);
    Promise.all(
      dataSelection.map(id =>
        deleteControlRow(id).catch(() =>
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_DELETE_SELECTION_ERROR'),
            duration: 0,
          })
        )
      )
    ).then(() => {
      filtersRefetch(true);
      Promise.all([
        fetchProjectionRows(projectionId, {
          ...tablePagination,
          ...selectedDates,
          ...filters,
        }),
        fetchProjectionDetail(),
      ]).then(() => {
        setIsDeletingSelection(false);
        message.success(t('FEEDBACK_DELETE_SELECTION_SUCCESS'));
      });
      setDataSelection([]);
    });
  };

  const onCommentCell = ({ columnId, rowId, transactionId, text }) => {
    createTransactionComment({ transactionId, text })
      .then(response => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionRows',
          newComment: response.payload.data,
        });
      })
      .catch(() => message.error(t('FEEDBACK_DEFAULT_ERROR')));
  };

  const onDeleteCommentCell = ({ columnId, rowId, commentId }) => {
    deleteTransactionComment({ commentId })
      .then(() => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionRows',
          commentId,
        });
      })
      .catch(() => message.error(t('FEEDBACK_DEFAULT_ERROR')));
  };

  const renderTableActions = () => (
    <Row className="control-table-actions">
      <Col span={12}>
        <ShowCommentsButton onClick={toggleShowRowsComment} />
        {dataSelection.length > 0 && (
          <DeleteSelectionButton
            onConfirm={handleDeleteSelection}
            disabled={!canDelete}
            isDeletingAll={
              dataSelection.length === tablePagination.page_size ||
              dataSelection.length === count
            }
          />
        )}
      </Col>
      <Col span={12}>
        <ButtonActionBar>
          <Button
            type="text"
            shape="circle"
            icon={<Typography.Icon icon={DownloadOutlined} />}
            onClick={download}
            title={t('ACTION_DOWNLOAD_TABLE')}
          />
          <TableSettingsModal
            onConfirm={setTableSettings}
            options={projectionColumnsImporte.filter(
              (column, index) =>
                !column.is_transaction &&
                index !== COLUMNS.FIRST_INDEX &&
                column.data_index !== COLUMNS.TOTAL_DATA_INDEX
            )}
            settings={tableSettings}
            formItems={formItemsHelper.itemsDataView(
              tableSettings.negativeValuesType,
              tableSettings.showNegativeInRed
            )}
          />
        </ButtonActionBar>
      </Col>
    </Row>
  );

  const renderTransactionControl = ({ date, published }) => (
    <Space>
      {dateMMYYYY(date, 'YYYY-MM')}
      <Tooltip title={t(PROJECTION.STATUS_COLUMN[published].title)}>
        <div
          className="real-control-column-status"
          data-type={PROJECTION.STATUS_COLUMN[published].status}
        />
      </Tooltip>
    </Space>
  );

  const renderTransactionColumn = column =>
    columnsHelper.renderTransactionPlanning({
      title: renderTransactionControl({
        date: column.title,
        published: column.published,
      }),
      date: column.title,
      cellFormat: {
        format: tableSettings.format,
        showNegativeInRed: tableSettings.showNegativeInRed,
      },
      width: column.width,
      disabled: true,
      showCalculateValue: false,
      comments: {
        onComment: onCommentCell,
        onDeleteComment: onDeleteCommentCell,
      },
      has_restriction,
    });

  const columnsGenerator = () =>
    columnsHelper.generator({
      columns: projectionColumnsImporte,
      filteredColumns: tableSettings.visibleData,
      cellFormat: {
        format: tableSettings.format,
        showNegativeInRed: tableSettings.showNegativeInRed,
      },
      filterProps: {
        filterOptions,
        filters,
        onFilter: handleOnFilter,
      },
      collapserProps: {
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      sorter: true,
      renderTransaction: renderTransactionColumn,
      has_restriction,
    });

  const showRowSelection =
    canDelete &&
    projectionColumnsImporte.filter(
      element =>
        element.is_transaction && element.published === APPROVE_DATE_KEY
    ).length <= 0;

  const renderRowSelection = () => {
    return {
      getCheckboxProps: record => {
        return {
          ...(record.id === ROWS.TOTALIZER_ID && {
            className: 'hide-check',
            disabled: true,
          }),
        };
      },
      columnWidth: 24,
      selectedRowKeys: dataSelection,
      onChange: idsRows => setDataSelection(idsRows),
    };
  };

  return (
    <>
      {renderTableActions()}
      <Table
        rowKey="id"
        className="real-control-table"
        rowSelection={showRowSelection ? renderRowSelection() : undefined}
        rowClassName={record =>
          record.id === ROWS.TOTALIZER_ID && ROWS.TYPES[ROWS.TOTALIZER]
        }
        size="small"
        loading={isTableLoading || isDeletingSelection}
        onChange={handleOnChangeTable}
        columns={columnsGenerator()}
        dataSource={transactions}
        pagination={{
          pageSize: tablePagination.page_size + totalizerRows,
          current: tablePagination.page,
          total: count > 0 ? count + totalizerRows : count,
          size: 'small',
          pageSizeOptions: ['10', '20', '30'],
          showSizeChanger: true,
        }}
        {...(!isCollapsing && {
          scroll: {
            x: calculateScrollX(columnsGenerator()),
            y: 445,
          },
        })}
        locale={columnsHelper.renderSorterLocale()}
      />
    </>
  );
};

const mapStateToProps = state => ({
  projectionColumnsImporte:
    planning.selectors.getProjectionsColumnsImporte(state),
  //NOTE: COMENTADO HASTA QUE ESTE HABILITADA LA VISION POR PXQ EN FINANCIERO REAL.
  // projectionColumns: planning.selectors.getProjectionColumns(state),
  projectionRows: planning.selectors.getProjectionRows(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchProjectionColumns: planning.actions.fetchProjectionColumns,
  fetchProjectionRows: planning.actions.fetchProjectionRows,
  deleteControlRow: planning.actions.deleteBudgetRow,
  syncProjectionComment: planning.actions.syncProjectionComment,
  createTransactionComment: planning.actions.createTransactionComment,
  deleteTransactionComment: planning.actions.deleteTransactionComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealControlTable);
