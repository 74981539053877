export const NAME = 'configuration';

// ++ DIMENSIONES ++
export const FETCH_DIMENSIONS_LIST = `${NAME}/FETCH_DIMENSIONS_LIST`;
export const FETCH_DIMENSIONS_LIST_SUCCESS = `${NAME}/FETCH_DIMENSIONS_LIST_SUCCESS`;
export const FETCH_DIMENSIONS_LIST_FAIL = `${NAME}/FETCH_DIMENSIONS_LIST_FAIL`;

export const FETCH_DIMENSION_DETAIL = `${NAME}/FETCH_DIMENSION_DETAIL`;
export const FETCH_DIMENSION_DETAIL_SUCCESS = `${NAME}/FETCH_DIMENSION_DETAIL_SUCCESS`;
export const FETCH_DIMENSION_DETAIL_FAIL = `${NAME}/FETCH_DIMENSION_DETAIL_FAIL`;

export const FETCH_VALUES_DIMENSION = `${NAME}/FETCH_VALUES_DIMENSION`;
export const FETCH_VALUES_DIMENSION_SUCCESS = `${NAME}/FETCH_VALUES_DIMENSION_SUCCESS`;
export const FETCH_VALUES_DIMENSION_FAIL = `${NAME}/FETCH_VALUES_DIMENSION_FAIL`;

export const FETCH_DIMENSION_VALUES = `${NAME}/FETCH_DIMENSION_VALUES`;
export const FETCH_DIMENSION_VALUES_SUCCESS = `${NAME}/FETCH_DIMENSION_VALUES_SUCCESS`;
export const FETCH_DIMENSION_VALUES_FAIL = `${NAME}/FETCH_DIMENSION_VALUES_FAIL`;

export const FETCH_DIMENSION_VALUES_PARENTS = `${NAME}/FETCH_DIMENSION_VALUES_PARENTS`;
export const FETCH_DIMENSION_VALUES_PARENTS_SUCCESS = `${NAME}/FETCH_DIMENSION_VALUES_PARENTS_SUCCESS`;
export const FETCH_DIMENSION_VALUES_PARENTS_FAIL = `${NAME}/FETCH_DIMENSION_VALUES_PARENTS_FAIL`;

export const FETCH_DIMENSION_TEMPLATE_XLS = `${NAME}/FETCH_DIMENSION_TEMPLATE_XLS`;

export const FETCH_DIMENSION_VALUES_CONSOLIDATED_XLS = `${NAME}/FETCH_DIMENSION_VALUES_CONSOLIDATED_XLS`;

export const UPLOAD_DIMENSION_VALUES_XLS = `${NAME}/UPLOAD_DIMENSION_VALUES_XLS`;

export const CREATE_DIMENSION = `${NAME}/CREATE_DIMENSION`;
export const CREATE_DIMENSION_SUCCESS = `${NAME}/CREATE_DIMENSION_SUCCESS`;
export const CREATE_DIMENSION_FAIL = `${NAME}/CREATE_DIMENSION_FAIL`;

export const DELETE_DIMENSION = `${NAME}/DELETE_DIMENSION`;
export const DELETE_DIMENSION_SUCCESS = `${NAME}/DELETE_DIMENSION_SUCCESS`;
export const DELETE_DIMENSION_FAIL = `${NAME}/DELETE_DIMENSION_FAIL`;

export const DELETE_ALL_DIMENSION_VALUES = `${NAME}/DELETE_ALL_DIMENSION_VALUES`;
export const DELETE_ALL_DIMENSION_VALUES_SUCCESS = `${NAME}/DELETE_ALL_DIMENSION_VALUES_SUCCESS`;
export const DELETE_ALL_DIMENSION_VALUES_FAIL = `${NAME}/DELETE_ALL_DIMENSION_VALUES_FAIL`;

export const EDIT_CONCEPT_DIMENSION = `${NAME}/EDIT_CONCEPT_DIMENSION`;
export const EDIT_CONCEPT_DIMENSION_SUCCESS = `${NAME}/EDIT_CONCEPT_DIMENSION_SUCCESS`;
export const EDIT_CONCEPT_DIMENSION_FAIL = `${NAME}/EDIT_CONCEPT_DIMENSION_FAIL`;

export const EDIT_DIMENSION_NAME = `${NAME}/EDIT_DIMENSION_NAME`;
export const EDIT_DIMENSION_NAME_SUCCESS = `${NAME}/EDIT_DIMENSION_NAME_SUCCESS`;
export const EDIT_DIMENSION_NAME_FAIL = `${NAME}/EDIT_DIMENSION_NAME_FAIL`;

export const CREATE_DIMENSION_VALUE = `${NAME}/CREATE_DIMENSION_VALUE`;
export const CREATE_DIMENSION_VALUE_SUCCESS = `${NAME}/CREATE_DIMENSION_VALUE_SUCCESS`;
export const CREATE_DIMENSION_VALUE_FAIL = `${NAME}/CREATE_DIMENSION_VALUE_FAIL`;

export const EDIT_DIMENSION_VALUE = `${NAME}/EDIT_DIMENSION_VALUE`;
export const EDIT_DIMENSION_VALUE_SUCCESS = `${NAME}/EDIT_DIMENSION_VALUE_SUCCESS`;
export const EDIT_DIMENSION_VALUE_FAIL = `${NAME}/EDIT_DIMENSION_VALUE_FAIL`;

export const DELETE_DIMENSION_VALUE = `${NAME}/DELETE_DIMENSION_VALUE`;
export const DELETE_DIMENSION_VALUE_SUCCESS = `${NAME}/DELETE_DIMENSION_VALUE_SUCCESS`;
export const DELETE_DIMENSION_VALUE_FAIL = `${NAME}/DELETE_DIMENSION_VALUE_FAIL`;

export const DELETE_CONCEPT_DIMENSION = `${NAME}/DELETE_CONCEPT_DIMENSION`;
export const DELETE_CONCEPT_DIMENSION_SUCCESS = `${NAME}/DELETE_CONCEPT_DIMENSION_SUCCESS`;
export const DELETE_CONCEPT_DIMENSION_FAIL = `${NAME}/DELETE_CONCEPT_DIMENSION_FAIL`;

// ++ USERS ++
export const FETCH_USERS_LIST = `${NAME}/FETCH_USERS_LIST`;
export const FETCH_USERS_LIST_SUCCESS = `${NAME}/FETCH_USERS_LIST_SUCCESS`;
export const FETCH_USERS_LIST_FAIL = `${NAME}/FETCH_USERS_LIST_FAIL`;

export const FETCH_USER_DETAIL = `${NAME}/FETCH_USER_DETAIL`;
export const FETCH_USER_DETAIL_SUCCESS = `${NAME}/FETCH_USER_DETAIL_SUCCESS`;
export const FETCH_USER_DETAIL_FAIL = `${NAME}/FETCH_USER_DETAIL_FAIL`;

export const FETCH_USER_TEMPLATE_XLS = `${NAME}/FETCH_USER_TEMPLATE_XLS`;

export const FETCH_USER_CONSOLIDATED_XLS = `${NAME}/FETCH_USER_CONSOLIDATED_XLS`;

export const UPLOAD_USERS_XLS = `${NAME}/UPLOAD_USERS_XLS`;

export const FETCH_USER_ROLES = `${NAME}/FETCH_USER_ROLES`;
export const FETCH_USER_ROLES_SUCCESS = `${NAME}/FETCH_USER_ROLES_SUCCESS`;
export const FETCH_USER_ROLES_FAIL = `${NAME}/FETCH_USER_ROLES_FAIL`;

export const CREATE_USER = `${NAME}/CREATE_USER`;
export const CREATE_USER_SUCCESS = `${NAME}/CREATE_USER_SUCCESS`;
export const CREATE_USER_FAIL = `${NAME}/CREATE_USER_FAIL`;

export const EDIT_USER = `${NAME}/EDIT_USER`;
export const EDIT_USER_SUCCESS = `${NAME}/EDIT_USER_SUCCESS`;
export const EDIT_USER_FAIL = `${NAME}/EDIT_USER_FAIL`;

export const DELETE_USER = `${NAME}/DELETE_USER`;
export const DELETE_USER_SUCCESS = `${NAME}/DELETE_USER_SUCCESS`;
export const DELETE_USER_FAIL = `${NAME}/DELETE_USER_FAIL`;

// ++ PLAN ACCOUNTS ++
export const FETCH_PLAN_ACCOUNTS_CONSOLIDATED_XLS = `${NAME}/FETCH_PLAN_ACCOUNTS_CONSOLIDATED_XLS`;

export const CREATE_PLAN_ACCOUNT = `${NAME}/CREATE_PLAN_ACCOUNT`;
export const CREATE_PLAN_ACCOUNT_SUCCESS = `${NAME}/CREATE_PLAN_ACCOUNT_SUCCESS`;
export const CREATE_PLAN_ACCOUNT_FAIL = `${NAME}/CREATE_PLAN_ACCOUNT_FAIL`;

export const DELETE_PLAN_ACCOUNT = `${NAME}/DELETE_PLAN_ACCOUNT`;
export const DELETE_PLAN_ACCOUNT_SUCCESS = `${NAME}/DELETE_PLAN_ACCOUNT_SUCCESS`;
export const DELETE_PLAN_ACCOUNT_FAIL = `${NAME}/DELETE_PLAN_ACCOUNT_FAIL`;

export const EDIT_PLAN_ACCOUNT = `${NAME}/EDIT_PLAN_ACCOUNT`;
export const EDIT_PLAN_ACCOUNT_SUCCESS = `${NAME}/EDIT_PLAN_ACCOUNT_SUCCESS`;
export const EDIT_PLAN_ACCOUNT_FAIL = `${NAME}/EDIT_PLAN_ACCOUNT_FAIL`;

// ++ DISPLAY CONFIGURATIONS
export const FETCH_DISPLAY_SETTINGS = `${NAME}/FETCH_DISPLAY_SETTINGS`;
export const FETCH_DISPLAY_SETTINGS_SUCCESS = `${NAME}/FETCH_DISPLAY_SETTINGS_SUCCESS`;
export const FETCH_DISPLAY_SETTINGS_FAIL = `${NAME}/FETCH_DISPLAY_SETTINGS_FAIL`;

export const CREATE_DISPLAY_SETTINGS = `${NAME}/CREATE_DISPLAY_SETTINGS`;
export const UPDATE_DISPLAY_SETTINGS = `${NAME}/UPDATE_DISPLAY_SETTINGS`;

// ++ MODIFICADORES
export const FETCH_MODIFIERS_LIST = `${NAME}/FETCH_MODIFIERS_LIST`;
export const FETCH_MODIFIERS_LIST_SUCCESS = `${NAME}/FETCH_MODIFIERS_LIST_SUCCESS`;
export const FETCH_MODIFIERS_LIST_FAIL = `${NAME}/FETCH_MODIFIERS_LIST_FAIL`;

// ++ VARIABLES
export const FETCH_VARIABLES_LIST = `${NAME}/FETCH_VARIABLES_LIST`;
export const FETCH_VARIABLES_LIST_SUCCESS = `${NAME}/FETCH_VARIABLES_LIST_SUCCESS`;
export const FETCH_VARIABLES_LIST_FAIL = `${NAME}/FETCH_VARIABLES_LIST_FAIL`;

export const FETCH_VARIABLES_KPI = `${NAME}/FETCH_VARIABLES_KPI`;
export const FETCH_VARIABLES_KPI_SUCCESS = `${NAME}/FETCH_VARIABLES_KPI_SUCCESS`;
export const FETCH_VARIABLES_KPI_FAIL = `${NAME}/FETCH_VARIABLES_KPI_FAIL`;

export const CREATE_VARIABLE = `${NAME}/CREATE_VARIABLE`;
export const UPDATE_VARIABLE = `${NAME}/UPDATE_VARIABLE`;
export const DELETE_VARIABLE = `${NAME}/DELETE_VARIABLE`;
export const UPDATE_VARIABLE_VALUES = `${NAME}/UPDATE_VARIABLE_VALUES`;

export const FETCH_BEHAVIOUR_LIST = `${NAME}/FETCH_BEHAVIOUR_LIST`;
export const FETCH_BEHAVIOUR_LIST_SUCCESS = `${NAME}/FETCH_BEHAVIOUR_LIST_SUCCESS`;
export const FETCH_BEHAVIOUR_LIST_FAIL = `${NAME}/FETCH_BEHAVIOUR_LIST_FAIL`;

export const FETCH_VARIABLE_DETAIL = `${NAME}/FETCH_VARIABLE_DETAIL`;
export const FETCH_VARIABLE_DETAIL_SUCCESS = `${NAME}/FETCH_VARIABLE_DETAIL_SUCCESS`;
export const FETCH_VARIABLE_DETAIL_FAIL = `${NAME}/FETCH_VARIABLE_DETAIL_FAIL`;

export const SET_VARIABLES_VALUES = `${NAME}/SET_VARIABLES_VALUES`;

// ++ FORMULAS
export const FETCH_FORMULAS_LIST = `${NAME}/FETCH_FORMULAS_LIST`;
export const FETCH_FORMULAS_LIST_SUCCESS = `${NAME}/FETCH_FORMULAS_LIST_SUCCESS`;
export const FETCH_FORMULAS_LIST_FAIL = `${NAME}/FETCH_FORMULAS_LIST_FAIL`;

export const FETCH_FORMULA_DETAIL = `${NAME}/FETCH_FORMULA_DETAIL`;
export const FETCH_FORMULA_DETAIL_SUCCESS = `${NAME}/FETCH_FORMULA_DETAIL_SUCCESS`;
export const FETCH_FORMULA_DETAIL_FAIL = `${NAME}/FETCH_FORMULA_DETAIL_FAIL`;

export const CREATE_FORMULA_FACTOR = `${NAME}/CREATE_FORMULA_FACTOR`;

export const UPDATE_FORMULA = `${NAME}/UPDATE_FORMULA`;

// ++ CONCEPTOS
export const CREATE_CONCEPT_ASSOCIATION = `${NAME}/CREATE_CONCEPT_ASSOCIATION`;
export const DELETE_CONCEPT_ASSOCIATION = `${NAME}/DELETE_CONCEPT_ASSOCIATION`;

//factor
export const CREATE_FACTOR = `${NAME}/CREATE_FACTOR`;
export const UPDATE_FACTOR = `${NAME}/UPDATE_FACTOR`;
export const DELETE_FACTOR = `${NAME}/DELETE_FACTOR`;

export const FETCH_FACTORES_LIST = `${NAME}/FETCH_FACTORES_LIST`;
export const FETCH_FACTORES_LIST_SUCCESS = `${NAME}/FETCH_FACTORES_LIST_SUCCESS`;
export const FETCH_FACTORES_LIST_FAIL = `${NAME}/FETCH_FACTORES_LIST_FAIL`;

export const FETCH_FACTORES_BEHAVIOURS = `${NAME}/FETCH_FACTORES_BEHAVIOURS`;
export const FETCH_FACTORES_BEHAVIOURS_SUCCESS = `${NAME}/FETCH_FACTORES_BEHAVIOURS_SUCCESS`;
export const FETCH_FACTORES_BEHAVIOURS_FAIL = `${NAME}/FETCH_FACTORES_BEHAVIOURS_FAIL`;

//eliminar fórmula
export const DELETE_FORMULA = `${NAME}/DELETE_FORMULA`;
export const DELETE_CASCADE = `${NAME}/DELETE_CASCADE`;
export const DESACTIVATE_FORMULA = `${NAME}/DESACTIVATE_FORMULA`;

//crear nuevo reporte
export const CREATE_OTHER_REPORT = `${NAME}/CREATE_OTHER_REPORT`;

//actualizar nombre de reporte
export const UPDATE_REPORT_NAME = `${NAME}/UPDATE_REPORT_NAME`;
export const UPDATE_REPORT_NAME_SUCCESS = `${NAME}/UPDATE_REPORT_NAME_SUCCESS`;
export const UPDATE_REPORT_NAME_FAIL = `${NAME}/UPDATE_REPORT_NAME_FAIL`;

//listado otros reportes config
export const FETCH_OTHER_REPORT_LIST = `${NAME}/FETCH_OTHER_REPORT_LIST`;
export const FETCH_OTHER_REPORT_LIST_SUCCESS = `${NAME}/FETCH_OTHER_REPORT_LIST_SUCCESS`;
export const FETCH_OTHER_REPORT_LIST_FAIL = `${NAME}/FETCH_OTHER_REPORT_LIST_FAIL`;

export const DELETE_OTHER_REPORT = `${NAME}/DELETE_OTHER_REPORT`;

export const FETCH_OTHER_REPORT_STRUCTURE = `${NAME}/FETCH_OTHER_REPORT_STRUCTURE`;
export const FETCH_OTHER_REPORT_STRUCTURE_SUCCESS = `${NAME}/FETCH_OTHER_REPORT_STRUCTURE_SUCCESS`;
export const FETCH_OTHER_REPORT_STRUCTURE_FAIL = `${NAME}/FETCH_OTHER_REPORT_STRUCTURE_FAIL`;

// ++ ROLES ++
export const FETCH_ROLES_LIST = `${NAME}/FETCH_ROLES_LIST`;
export const FETCH_ROLES_LIST_SUCCESS = `${NAME}/FETCH_ROLES_LIST_SUCCESS`;
export const FETCH_ROLES_LIST_FAIL = `${NAME}/FETCH_ROLES_LIST_FAIL`;

export const CREATE_ROLE = `${NAME}/CREATE_ROLE`;
export const CREATE_ROLE_SUCCESS = `${NAME}/CREATE_ROLE_SUCCESS`;
export const CREATE_ROLE_FAIL = `${NAME}/CREATE_ROLE_FAIL`;

export const DUPLICATE_ROLE = `${NAME}/DUPLICATE_ROLE`;
export const DUPLICATE_ROLE_SUCCESS = `${NAME}/DUPLICATE_ROLE_SUCCESS`;
export const DUPLICATE_ROLE_FAIL = `${NAME}/DUPLICATE_ROLE_FAIL`;

export const EDIT_ROLE = `${NAME}/EDIT_ROLE`;
export const EDIT_ROLE_SUCCESS = `${NAME}/EDIT_ROLE_SUCCESS`;
export const EDIT_ROLE_FAIL = `${NAME}/EDIT_ROLE_FAIL`;

export const DELETE_ROLE = `${NAME}/DELETE_ROLE`;
export const DELETE_ROLE_SUCCESS = `${NAME}/DELETE_ROLE_SUCCESS`;
export const DELETE_ROLE_FAIL = `${NAME}/DELETE_ROLE_FAIL`;

export const EDIT_ROLE_OPERATIONS = `${NAME}/EDIT_ROLE_OPERATIONS`;

export const SAVE_ROLE_OPERATIONS = `${NAME}/SAVE_ROLE_OPERATIONS`;
export const SAVE_ROLE_OPERATIONS_SUCCESS = `${NAME}/SAVE_ROLE_OPERATIONS_SUCCESS`;
export const SAVE_ROLE_OPERATIONS_FAIL = `${NAME}/SAVE_ROLE_OPERATIONS_FAIL`;

export const FETCH_ROLE_DIMENSIONS_LIST = `${NAME}/FETCH_ROLE_DIMENSIONS_LIST`;
export const FETCH_ROLE_DIMENSIONS_LIST_SUCCESS = `${NAME}/FETCH_ROLE_DIMENSIONS_LIST_SUCCESS`;
export const FETCH_ROLE_DIMENSIONS_LIST_FAIL = `${NAME}/FETCH_ROLE_DIMENSIONS_LIST_FAIL`;

export const FETCH_ROLE_DIMENSIONS_VALUES = `${NAME}/FETCH_ROLE_DIMENSIONS_VALUES`;
export const FETCH_ROLE_DIMENSIONS_VALUES_SUCCESS = `${NAME}/FETCH_ROLE_DIMENSIONS_VALUES_SUCCESS`;
export const FETCH_ROLE_DIMENSIONS_VALUES_FAIL = `${NAME}/FETCH_ROLE_DIMENSIONS_VALUES_FAIL`;

export const SELECT_ROLE_DIMENSION = `${NAME}/SELECT_ROLE_DIMENSION`;
export const CLEAN_ROLE_DIMENSION = `${NAME}/CLEAN_ROLE_DIMENSION`;

export const ASIGN_ROLE_OPERATIONS = `${NAME}/ASIGN_ROLE_OPERATIONS`;
export const ASIGN_ROLE_OPERATIONS_SUCCESS = `${NAME}/ASIGN_ROLE_OPERATIONS_SUCCESS`;
export const ASIGN_ROLE_OPERATIONS_FAIL = `${NAME}/ASIGN_ROLE_OPERATIONS_FAIL`;

export const FETCH_CONCEPT_ACCOUNT_ASSOCIATION_TEMPLATE_XLS = `${NAME}/FETCH_CONCEPT_ACCOUNT_ASSOCIATION_TEMPLATE_XLS`;
export const UPLOAD_CONCEPT_ACCOUNT_ASSOCIATION_XLS = `${NAME}/UPLOAD_CONCEPT_ACCOUNT_ASSOCIATION_XLS`;
export const EDIT_CONCEPT_CASCADER = `${NAME}/EDIT_CONCEPT_CASCADER`;

//MULTIMONEDA
export const CREATE_CURRENCIES = `${NAME}/CREATE_CURRENCIES`;

export const FETCH_CURRENCY_LIST = `${NAME}/FETCH_CURRENCY_LIST`;
export const FETCH_CURRENCY_LIST_SUCCESS = `${NAME}/FETCH_CURRENCY_LIST_SUCCESS`;
export const FETCH_CURRENCY_LIST_FAIL = `${NAME}/FETCH_CURRENCY_LIST_FAIL`;

export const SET_CURRENCY_ACTION = `${NAME}/SET_CURRENCY_ACTION`;
export const SET_CURRENCY_ACTION_SUCCESS = `${NAME}/SET_CURRENCY_ACTION_SUCCESS`;
export const SET_CURRENCY_ACTION_FAIL = `${NAME}/SET_CURRENCY_ACTION_FAIL`;

export const PREDEFINE_CURRENCY = `${NAME}/PREDEFINE_CURRENCY`;
export const PREDEFINE_CURRENCY_SUCCESS = `${NAME}/PREDEFINE_CURRENCY_SUCCESS`;
export const PREDEFINE_CURRENCY_FAIL = `${NAME}/PREDEFINE_CURRENCY_FAIL`;

// ONBOARDING
export const FETCH_ONBOARDING_STEPS = `${NAME}/FETCH_ONBOARDING_STEPS`;
export const FETCH_ONBOARDING_STEPS_SUCCESS = `${NAME}/FETCH_ONBOARDING_STEPS_SUCCESS`;
export const FETCH_ONBOARDING_STEPS_FAIL = `${NAME}/FETCH_ONBOARDING_STEPS_FAIL`;

export const SET_ONBOARDING_STEP = `${NAME}/SET_ONBOARDING_STEP`;
export const SET_ONBOARDING_STEP_SUCCESS = `${NAME}/SET_ONBOARDING_SUCCESS`;
export const SET_ONBOARDING_STEP_FAIL = `${NAME}/SET_ONBOARDING_FAIL`;
