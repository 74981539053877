import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { Row, Col, Button, notification, Spin, Tooltip } from 'antd';
import { SiderLayout } from 'modules/core/layouts';
import {
  PeriodNavigator,
  ButtonActionBar,
  CreationExitModal,
} from 'modules/core/components';
import {
  DistributionCreationInfo,
  DistributionOriginTable,
  DistributionDestinationTable,
} from './components';
import { isUserAllowed, renderPrefix } from 'modules/core/utils';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import { ROLES, CONCEPTS } from 'modules/core/constants';
import login from 'modules/login';
import planning from 'modules/planning';
import distributions from 'modules/distributions';
import { useTranslation } from 'react-i18next';
import control from 'modules/control';

import './DistributionCreation.scss';
import _ from 'lodash';

const { CONTROL__DISTRIBUTIONS__CREATE } = ROLES;

const DistributionCreation = ({
  approveDates,
  creationDate,
  creationAccount,
  originColumns,
  originRow,
  originRowFormatted,
  originBalance,
  destinationRows,
  destinationRowsFormatted,
  // destinationDimensionOptions,
  // destinationDimension,
  creationWarnings,
  fetchApproveDates,
  cleanPublishedDates,
  exitDistributionCreation,
  fetchDistributionOriginColumns,
  createDistribution,
  duplicateDistribution,
}) => {
  const [isLoadingDates, setIsLoadingDates] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [isCreatingDistribution, setIsCreatingDistribution] = useState(false);
  const [successCreation, setSuccessCreation] = useState(false);
  const [dataDuplicated, setDataDuplicated] = useState({});
  const [comment, setComment] = useState('');
  const functionalCurrencyData = useFunctionalCurrency();
  let { periodId } = useParams();
  let history = useHistory();
  const { t } = useTranslation();

  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  var duplicatedId = urlParams.get('duplicatedId');

  useEffect(() => {
    fetchApproveDates(periodId, {
      concept_type: CONCEPTS.TYPE.ECONOMIC_KEY,
    }).then(() => setIsLoadingDates(false));
    return () => {
      cleanPublishedDates();
      exitDistributionCreation();
    };
  }, [
    fetchApproveDates,
    cleanPublishedDates,
    exitDistributionCreation,
    periodId,
  ]);

  useEffect(() => {
    fetchDistributionOriginColumns().then(() => setIsTableLoading(false));
  }, [fetchDistributionOriginColumns]);

  useEffect(() => {
    duplicateDistribution(duplicatedId).then(response =>
      setDataDuplicated(response.payload.data)
    );
  }, [duplicateDistribution, duplicatedId]);

  const onConfirmCreation = () => {
    setIsCreatingDistribution(true);
    createDistribution({
      date: creationDate,
      comment,
      origin: {
        dv_filter: originRowFormatted,
        amount: originBalance,
      },
      destinations: destinationRowsFormatted,
    })
      .then(response => {
        setIsCreatingDistribution(false);
        setSuccessCreation(true);
        notification.success({
          message: t('DISTRIBUTION_CREATE_SUCCESS_FEEDBACK_TITLE'),
          description: t('DISTRIBUTION_SUCCESS_FEEDBACK_DESCRIPTION'),
        });
      })
      .catch(() => {
        setIsCreatingDistribution(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('DISTRIBUTION_CREATE_FAIL_FEEDBACK_DESRIPTION'),
        });
      });
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_CONTROL'),
          url: `/seguimiento/${periodId}`,
        },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/seguimiento/${periodId}/economico?tab=distribuciones`,
        },
        { name: t('DISTRIBUTION_CREATE_ACTION') },
      ]}
    />
  );

  const handleClickCancel = () => {
    if (creationDate || comment !== '') {
      setConfirmedNavigation(true);
      setShowExitModal(true);
    } else {
      history.push(`/seguimiento/${periodId}/economico?tab=distribuciones`);
    }
  };

  const renderBottomActions = () => (
    <ButtonActionBar position="center">
      <Button
        disabled={isCreatingDistribution}
        onClick={() => handleClickCancel()}
      >
        {t('ACTION_CANCEL')}
      </Button>
      {isUserAllowed(CONTROL__DISTRIBUTIONS__CREATE) && (
        <Tooltip title={creationWarnings ? t('ALERT_NULL_DATA') : ''}>
          <Button
            type="primary"
            disabled={creationWarnings}
            loading={isCreatingDistribution}
            onClick={onConfirmCreation}
          >
            {t('DISTRIBUTION_CREATE_ACTION')}
          </Button>
        </Tooltip>
      )}
    </ButtonActionBar>
  );

  const renderNewDistributionBottomActions = () => {
    return (
      <ButtonActionBar position="center">
        <Button
          onClick={() => {
            setComment('');
            setSuccessCreation(false);
            exitDistributionCreation();
          }}
        >
          {t('DISTRIBUTION_NEW_CREATE_ACTION')}
        </Button>
        <Button
          type="primary"
          onClick={() =>
            history.push(
              `/seguimiento/${periodId}/economico?tab=distribuciones`
            )
          }
        >
          {t('ACTION_FINISH')}
        </Button>
      </ButtonActionBar>
    );
  };

  const handleExitConfirm = () => {
    if (lastLocation) {
      setShowExitModal(false);
      history.push(lastLocation);
    } else {
      history.push(`/seguimiento/${periodId}/economico?tab=distribuciones`);
    }
  };

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation) {
      setShowExitModal(true);
      setLastLocation(nextLocation);
      setConfirmedNavigation(true);
      return false;
    }
    return true;
  };

  return (
    <SiderLayout>
      <Prompt
        when={!successCreation && (creationDate || comment !== '')}
        message={handleBlockedNavigation}
      />
      {renderHeader()}
      <Spin spinning={isTableLoading} />
      {!isTableLoading && (
        <div className="distribution-creation-container">
          <Row gutter={[24, 24]}>
            <Col span={20}>
              <DistributionCreationInfo
                valueDate={creationDate}
                dateOptions={approveDates}
                valueAccount={creationAccount}
                comment={comment}
                setComment={setComment}
                isLoadingDates={isLoadingDates}
                successCreation={successCreation}
                dataDuplicated={{
                  duplicatedComment: dataDuplicated.comment,
                  duplicatedDate: dataDuplicated.date,
                }}
              />
            </Col>
            <Col span={24}>
              <DistributionOriginTable
                isTableLoading={isTableLoading}
                valueDate={creationDate}
                valueAccount={creationAccount}
                disabled={!creationDate || !creationAccount}
                originColumns={originColumns}
                dataSource={[{ id: 0, ...originRow }]}
                originBalance={originBalance}
                originRowFormatted={originRowFormatted}
                successCreation={successCreation}
                prefix={renderPrefix(functionalCurrencyData)}
                dataOrigin={
                  !_.isEmpty(dataDuplicated) ? dataDuplicated.origin : undefined
                }
              />
            </Col>
            <Col span={24}>
              <DistributionDestinationTable
                successCreation={successCreation}
                originColumns={originColumns}
                valueDate={creationDate}
                valueAccount={creationAccount}
                dataSource={destinationRows}
                disabled={
                  !duplicatedId &&
                  (!creationDate || !creationAccount || !originBalance)
                }
              />
            </Col>
          </Row>
          {!successCreation && renderBottomActions()}
          {successCreation && renderNewDistributionBottomActions()}
          <CreationExitModal
            visible={showExitModal}
            onClose={() => {
              setShowExitModal(false);
              setConfirmedNavigation(false);
            }}
            onConfirm={handleExitConfirm}
          />
        </div>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  approveDates: control.selectors.getApproveDates(state),
  creationDate: distributions.selectors.getCreationDate(state),
  creationAccount: distributions.selectors.getCreationAccount(state),
  originColumns: distributions.selectors.getOriginColumns(state),
  originRow: distributions.selectors.getOriginRow(state),
  originRowFormatted: distributions.selectors.getOriginRowFormatted(state),
  originBalance: distributions.selectors.getOriginBalance(state),
  destinationRows: distributions.selectors.getDestinationRows(state),
  destinationRowsFormatted:
    distributions.selectors.getDestinationRowsFormatted(state),
  destinationDimensionOptions:
    distributions.selectors.getDestinationDimensionOptions(state),
  destinationDimension: distributions.selectors.getDestinationDimension(state),
  creationWarnings: distributions.selectors.getCreationWarnings(state),
});

const mapDispatchToProps = {
  fetchApproveDates: control.actions.fetchApproveDates,
  cleanPublishedDates: planning.actions.cleanPublishedDates, //checkear esta funcion
  exitDistributionCreation: distributions.actions.exitDistributionCreation,
  fetchDistributionOriginColumns:
    distributions.actions.fetchDistributionOriginColumns,
  createDistribution: distributions.actions.createDistribution,
  duplicateDistribution: distributions.actions.duplicateDistribution,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionCreation);
