import { COLUMNS } from 'modules/core/constants';
import i18n from 'i18next';

// NOTE: Para la tabla de detalle.
const columnsDetail = (prefix = '$') => [
  {
    title: i18n.t('FIELD_CONCEPT'),
    dataIndex: 'concept',
    fixed: 'left',
    width: COLUMNS.WIDTH.EXPANDABLE,
    ellipsis: true,
  },
  {
    title: i18n.t('REAL'),
    dataIndex: 'real',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
  },
  {
    title: i18n.t('LABEL_BUDGET'),
    dataIndex: 'budget',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    ellipsis: true,
  },
  {
    title: i18n.t('VARIATION_MONEY', { prefix }),
    dataIndex: 'budget_var$',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    description: i18n.t('LABEL_BUDGET'),
  },
  {
    title: i18n.t('VARIATION_PERCENTAGE'),
    dataIndex: 'budget_var%',
    align: 'right',
    width: COLUMNS.WIDTH.VARIATION_PERCENTAGE,
    description: i18n.t('LABEL_BUDGET'),
  },
  {
    title: i18n.t('REAL_LAST_PERIOD'),
    dataIndex: 'prev_real',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    ellipsis: true,
  },
  {
    title: i18n.t('VARIATION_MONEY', { prefix }),
    dataIndex: 'prev_real_var$',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    description: i18n.t('REAL_LAST_PERIOD'),
  },
  {
    title: i18n.t('VARIATION_PERCENTAGE'),
    dataIndex: 'prev_real_var%',
    align: 'right',
    width: COLUMNS.WIDTH.VARIATION_PERCENTAGE,
    description: i18n.t('REAL_LAST_PERIOD'),
  },
  {
    title: i18n.t('LAST_FORECAST'),
    dataIndex: 'last_forecast',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    ellipsis: true,
  },
  {
    title: i18n.t('VARIATION_MONEY', { prefix }),
    dataIndex: 'last_forecast_var$',
    align: 'right',
    width: COLUMNS.WIDTH.REPORT_TRANSACTION,
    description: i18n.t('LAST_FORECAST'),
  },
  {
    title: i18n.t('VARIATION_PERCENTAGE'),
    dataIndex: 'last_forecast_var%',
    align: 'right',
    width: COLUMNS.WIDTH.VARIATION_PERCENTAGE,
    description: i18n.t('LAST_FORECAST'),
  },
];
export default columnsDetail;
