import React, { useState } from 'react';
import { Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';

const ActionModal = ({
  consolidationId,
  visible,
  onFetchDetail,
  onConfirm = () => {},
  onClose,
  onAction,
  isActionApprove,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirm = () => {
    setIsLoading(true);
    onAction(consolidationId)
      .then(() => {
        onFetchDetail();
        onConfirm();
        onClose();
        setIsLoading(false);
      })
      .catch(() => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_ACTION_DEFAULT_ERROR', {
            action: isActionApprove
              ? t('ACTION_APPROVE').toLowerCase()
              : t('ACTION_PUBLISH').toLowerCase(),
          }),
        });
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title={t('FORECAST_APPROVE_MODAL_TITLE', {
        action: isActionApprove ? t('ACTION_APPROVE') : t('ACTION_PUBLISH'),
      })}
      visible={visible}
      destroyOnClose={true}
      onOk={handleConfirm}
      okText={isActionApprove ? t('ACTION_APPROVE') : t('ACTION_PUBLISH')}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onClose}
      centered
      width={568}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      {isActionApprove
        ? t('FORECAST_APPROVE_MODAL_TEXT')
        : t('FORECAST_PUBLISH_MODAL_TEXT')}
    </Modal>
  );
};

export default ActionModal;
