import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';

import planning from 'modules/planning';

const FormItem = Form.Item;

const CreateBudgetModal = ({
  visible,
  concept,
  onCancel,
  copyData,
  duplicateBudget,
  onSuccessCreate,
  forecastId = null,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ name: copyData ? copyData.name : undefined });
    }
  }, [visible, form, copyData]);

  const handleCopy = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true);
        duplicateBudget(
          copyData.id,
          { name: values.name },
          { forecast: forecastId }
        )
          .then(() => {
            // Todo: Agregar "VER" link cuando back devuevla el ID del nuevo ppto creado
            message.success(t('PLANNING_COPY_BUDGET_SUCCESS_FEEDBACK'));
            setIsLoading(false);
            if (onSuccessCreate) onSuccessCreate();
            onClose();
          })
          .catch(() => {
            message.error(t('PLANNING_COPY_BUDGET_ERROR_FEEDBACK'));
            setIsLoading(false);
            onClose();
          });
      })
      .catch(e => {});
  };

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={
        copyData ? t('ACTION_COPY') : t('PLANNING_CREATE_FORM_BUDGET_TITLE')
      }
      centered
      visible={visible}
      destroyOnClose={true}
      onCancel={onClose}
      onOk={handleCopy}
      okText={copyData ? t('ACTION_COPY') : t('ACTION_CREATE')}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form form={form} layout="vertical">
        <FormItem
          name="name"
          label={t('FIELD_NAME')}
          rules={[
            { required: true, message: t('REQUIRED_FIELD') },
            { max: 80, message: t('REQUIRED_FIELD_CHARACTERS', { cant: 80 }) },
          ]}
        >
          <Input
            placeholder={t('PLANNING_CREATE_FORM_BUDGET_PLACEHOLDER', {
              name: concept ? t(concept.name) : t('LABEL_BUDGET'),
            })}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  createBudget: planning.actions.createProjection,
  duplicateBudget: planning.actions.duplicateBudget,
};

export default connect(null, mapDispatchToProps)(CreateBudgetModal);
