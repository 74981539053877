import { React } from 'react';
import { Space, Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const DeleteAndUpdate = ({
  dataCellEdit,
  onCancel,
  onConfirm,
  onSave,
  isEditing,
}) => {
  let { t } = useTranslation();

  return (
    <Space direction="horizontal" size="middle">
      {!_.isEmpty(dataCellEdit) ? (
        <Popconfirm
          placement="bottomRight"
          title={t('POPCONFIRM_EDIT_CANCEL')}
          okText={t('YES')}
          onConfirm={onConfirm}
          cancelText={t('NO')}
          okbuttonprops={{ loading: isEditing }}
        >
          <Button type="link">{t('ACTION_CANCEL')}</Button>
        </Popconfirm>
      ) : (
        <Button type="link" onClick={onCancel}>
          {t('ACTION_CANCEL')}
        </Button>
      )}
      <Button type="link" onClick={onSave} disabled={_.isEmpty(dataCellEdit)}>
        {t('ACTION_SAVE_CHANGES')}
      </Button>
    </Space>
  );
};
export default DeleteAndUpdate;
