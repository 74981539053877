import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { UploadModal } from 'modules/core/components';
import configuration from 'modules/configuration';

const DimensionUploadFile = ({
  dimensionId,
  visible,
  onCancel,
  onSuccessUpload,
  uploadDimensionValuesXLS,
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();

  const onCloseUploadModal = () => {
    onCancel();
    setFileList([]);
  };

  const onConfirmUpload = () => {
    setIsUploadingFile(true);
    uploadDimensionValuesXLS(fileList, { dimension: dimensionId })
      .then(() => {
        notification.success({
          message: t('FEEDBACK_UPLOAD_FILE_SUCCESS'),
        });
        onSuccessUpload();
        setIsUploadingFile(false);
        onCloseUploadModal();
      })
      .catch(() => {
        notification.error({ message: t('FEEDBACK_UPLOAD_FILE_ERROR') });
        setIsUploadingFile(false);
        onCloseUploadModal();
      });
  };

  const onAddUploadFile = file => {
    setFileList([file]);
    return false;
  };

  return (
    <UploadModal
      title={t('CONFIG_DIMENSION_VALUES_UPLOAD_TITLE')}
      visible={visible}
      onCancel={onCloseUploadModal}
      onConfirm={onConfirmUpload}
      uploadProps={{
        name: 'file',
        accept: '.xls, .xlsx',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: file => setFileList([]),
        fileList: fileList,
      }}
      isUploadingFile={isUploadingFile}
    />
  );
};

const mapDispatchToProps = {
  uploadDimensionValuesXLS: configuration.actions.uploadDimensionValuesXLS,
};

export default connect(null, mapDispatchToProps)(DimensionUploadFile);
