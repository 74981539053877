import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spin } from 'antd';
import {
  Typography,
  ConnectIntegrationModal,
  ScreenSuccess,
  IntegrationsList,
} from 'modules/core/components';
import { INTEGRATIONS } from 'modules/core/constants';
import imagePlika from 'assets/images/logo/circle-logo.svg';
import login from 'modules/login';

import './Integrations.scss';

const Integrations = ({
  dataSource,
  isdataLoading,
  fetchIntegrationList,
  loggedUser: { email },
}) => {
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [integrationData, setIntegrationData] = useState({
    name: '',
    id: null,
  });
  const [showScreenSuccess, setShowScreenSuccess] = useState(false);
  const { t } = useTranslation();

  //NOTE: PROVISIORIO FILTRADO SOLO ODOO HASTA QUE FUNCIONE QUICKBOOK
  const auxIntegrationsList = [
    ...dataSource
      .filter(data => data.name === 'Odoo')
      .map(integration => {
        return {
          ...integration,
          type: INTEGRATIONS.KEYS.INTEGRATION_KEY,
          ...INTEGRATIONS.DATA[integration.name],
        };
      }),
    {
      id: 3,
      name: t('INTERATION_PLIKA_SETTING_CARD_TEXT'),
      image: imagePlika,
      type: INTEGRATIONS.KEYS.MANUAL_KEY,
    },
  ];

  const renderHeader = () => (
    <div className="integrations-title">
      <Typography.Headline level={3} type="primary">
        {t('DASHBOARD_WELLCOME')}
      </Typography.Headline>
      <Typography.Body>
        {t('INTEGRATIONS_CONFIG_PLIKA_SUBTITLE')}
      </Typography.Body>
    </div>
  );

  return (
    <div className="integrations-container">
      <Spin spinning={isdataLoading} />
      {!showScreenSuccess && !isdataLoading && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>{renderHeader()}</Col>
            <Col span={24}>
              <IntegrationsList
                title={'INTERATION_INPORT_DATA_SECTION_TITLE'}
                dataSource={auxIntegrationsList.filter(
                  integration =>
                    integration.type === INTEGRATIONS.KEYS.INTEGRATION_KEY
                )}
                setIntegrationData={setIntegrationData}
                setShowConnectModal={setShowConnectModal}
                onRefetchIntegrationList={() => fetchIntegrationList()}
              />
            </Col>
            <Col span={24}>
              <IntegrationsList
                title={'INTEGRATION_LOAD_MANUAL_SECTION_TITLE'}
                dataSource={auxIntegrationsList.filter(
                  integration =>
                    integration.type === INTEGRATIONS.KEYS.MANUAL_KEY
                )}
                onRefetchIntegrationList={() => fetchIntegrationList()}
              />
            </Col>
          </Row>
          <ConnectIntegrationModal
            visible={showConnectModal}
            onCancel={() => setShowConnectModal(false)}
            integrationData={integrationData}
            setShowScreenSuccess={() => setShowScreenSuccess(true)}
          />
        </>
      )}
      {showScreenSuccess && (
        <ScreenSuccess
          email={email}
          name={integrationData.name}
          onClose={() => setShowScreenSuccess(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

export default connect(mapStateToProps, null)(Integrations);
