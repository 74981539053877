import * as types from './constants';
import { saveAs } from 'file-saver';
import i18n from 'i18next';

export const fetchAdjustmentSelectorColumns = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_SELECTOR_COLUMNS,
    payload: {
      request: {
        url: `/adjustment/adjustments/columns`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const uploadAdjustmentFile =
  (data, params = {}) =>
  async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('file', file);
    });
    const response = await dispatch({
      type: types.UPLOAD_ADJUSTMENT_FILE,
      payload: {
        request: {
          url: `/adjustment/adjustments/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editAdjustmentValues =
  (data = {}) =>
  dispatch =>
    dispatch({
      type: types.EDIT_ADJUSTMENT_VALUES,
      payload: { data },
    });

export const addAdjustmentRow = () => dispatch =>
  dispatch({
    type: types.ADD_ADJUSTMENT_ROW,
  });

export const copyAdjustmentRow = rowId => dispatch =>
  dispatch({
    type: types.COPY_ADJUSTMENT_ROW,
    payload: { rowId },
  });

export const removeAdjustmentRow = rowId => dispatch =>
  dispatch({
    type: types.REMOVE_ADJUSTMENT_ROW,
    payload: { rowId },
  });

export const editAdjustmentRow = (rowId, data) => dispatch =>
  dispatch({
    type: types.EDIT_ADJUSTMENT_ROW,
    payload: { rowId, data },
  });

export const exitAdjustmentCreation = () => dispatch =>
  dispatch({
    type: types.EXIT_ADJUSTMENT_CREATION,
  });

export const createAdjustment =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_ADJUSTMENT,
      payload: {
        request: {
          url: `/adjustment/adjustments/adjust/`,
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchAdjustmentKPIS =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_ADJUSTMENT_KPIS,
      payload: {
        request: {
          url: `/adjustment/adjustments/kpis/`,
          method: 'GET',
          params: {
            period_id: periodId,
            ...params,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const downloadAdjustmentsXLS =
  (filename = i18n.t('ADJUSTMENT_LIST_DEFAULT_FILE_NAME'), params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_ADJUSTMENTS_XLS,
      payload: {
        request: {
          url: `/adjustment/adjustments/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const downloadSingleAdjustmentsXLS =
  (
    adjustmentId,
    filename = i18n.t('ADJUSTMENT_DEFAULT_FILE_NAME'),
    params = {}
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_SINGLE_ADJUSTMENTS_XLS,
      payload: {
        request: {
          url: `/adjustment/adjustments/${adjustmentId}/detail_consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const fetchAdjustmentTable =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_ADJUSTMENT_TABLE,
      payload: {
        request: {
          url: `/adjustment/adjustments/`,
          method: 'GET',
          params: {
            period_id: periodId,
            ...params,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchAdjustmentDetailTable =
  (adjustmentId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_ADJUSTMENT_DETAIL_TABLE,
      payload: {
        request: {
          url: `/adjustment/adjustments/${adjustmentId}/details/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchColumnsAdjustmentDetail =
  (adjustmentId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_COLUMNS_ADJUSTMENT_DETAIL,
      payload: {
        request: {
          url: `/adjustment/adjustments/${adjustmentId}/detail_columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const duplicateAdjustment = adjustmentId => async dispatch => {
  const response = await dispatch({
    type: types.DUPLICATE_ADJUSTMENT,
    payload: {
      request: {
        url: `/adjustment/adjustments/${adjustmentId}/duplicate/`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const cancelAdjustment =
  (adjustmentId, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CANCEL_ADJUSTMENT,
      payload: {
        request: {
          url: `/adjustment/adjustments/${adjustmentId}/cancel/`,
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const downloadAdjustmentTemplateXLS =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_ADJUSTMENT_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/adjustment/adjustments/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, i18n.t('ADJUSTMENT_MOVEMENT_FILE_NAME'));
    return Promise.resolve();
  };
