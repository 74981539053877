const COMPARISON_KEY = 'comparison';
const SEGMENTED_KEY = 'segmented';
const VERTICAL_KEY = 'vertical';
const EVOLUTION_KEY = 'evolution';
const PLSEGMENTED_KEY = 'plsegmented';
const DETALLE_RDN = 'detallerdn';
const SEGREGATED_KEY = 'segregated';

const REPORTE_KEY = 'reporte';
const CUSTOM_KEY = 'custom';

const PNL_KEY = 'pnl';
const FNL_KEY = 'fnl';

const AMOUNT = 'Importe';

const REPORTS_KEYS = {
  COMPARISON_KEY: COMPARISON_KEY,
  SEGMENTED_KEY: SEGMENTED_KEY,
  VERTICAL_KEY: VERTICAL_KEY,
  EVOLUTION_KEY: EVOLUTION_KEY,
  PLSEGMENTED_KEY: PLSEGMENTED_KEY,
  REPORTE_KEY: REPORTE_KEY,
  CUSTOM_KEY: CUSTOM_KEY,
  AMOUNT: AMOUNT,
  DETALLE_RDN: DETALLE_RDN,
  PNL_KEY: PNL_KEY,
  FNL_KEY: FNL_KEY,
  SEGREGATED_KEY: SEGREGATED_KEY,
};

const LOAD = [
  { id: 'plan', name: 'LABEL_ACCOUNTS' },
  { id: 'exchangeRate', name: 'LABEL_EXCHANGE_RATE' },
  { id: 'reportStructure', name: 'LABEL_REPORT_STRUCTURE' },
];

const FORMAT = [
  { id: 'eerr', name: 'EERR' },
  { id: 'esp', name: 'ESP' },
  { id: 'compras', name: 'LABEL_SHOPPING' },
  { id: 'expenses', name: 'LABEL_EXPENSES' },
];

const QUICKSIGHT_TYPES_LIST = {
  LOAD,
  FORMAT,
};

const REPORT = {
  REPORTS_KEYS,
  QUICKSIGHT_TYPES_LIST,
};

export default REPORT;
