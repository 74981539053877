import React, { useEffect, useState } from 'react';
import { DatePicker, Alert, Row, Col, Space, Table, Button } from 'antd';
import { DollarOutlined, DownloadOutlined } from '@ant-design/icons';
import { columnsHelper, calculateScrollX } from 'modules/core/utils';
import {
  ButtonActionBar,
  References,
  Typography,
  TableSettingsModal,
  CellStyle,
} from 'modules/core/components';
import { connect } from 'react-redux';
import { ROWS, TABLES, COLUMNS, ACCES_USERS } from 'modules/core/constants';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import reports from 'modules/reports';
import login from 'modules/login';
import { useParams } from 'react-router-dom';

const { RangePicker } = DatePicker;

const formatDates = comparisionMonthDate => {
  return {
    first_date_range: comparisionMonthDate.first_date_range.map(date =>
      moment(date).format('YYYY-MM-DD')
    ),
    second_date_range: comparisionMonthDate.second_date_range.map(date =>
      moment(date).format('YYYY-MM-DD')
    ),
  };
};

const ComparisionMonth = ({
  reportFunctionalCurrencyData,
  isDataLoading,
  periodDetail,
  fetchComparativeMonthsRows,
  fetchComparativeMonthsColumns,
  downloadComparativeMonthsXLS,
  reportComparativeMonthsColumns,
  reportComparativeMonthsRows,
  loggedUser: { email },
}) => {
  const [comparisionMonthDate, setComparisionMonthDate] = useState({
    first_date_range: null,
    second_date_range: null,
  });
  const [comparationTableSettings, setComparationTableSettings] = useState(
    TABLES.DEFAULT_SETTINGS(email)
  );
  const [isComparativeDataLoading, setIsComparativeDataLoading] =
    useState(false);
  let { periodId, reportId } = useParams();
  const { t } = useTranslation();

  const has_date_range_selected =
    comparisionMonthDate?.first_date_range?.length > 0 &&
    comparisionMonthDate?.second_date_range?.length > 0;

  const FORMATS_TYPES = {
    '0,0': 'integer',
    '0,0.00': 'decimals',
    '0[.]0a': 'millions',
  };

  useEffect(() => {
    if (has_date_range_selected) {
      const endpointParams = {
        period_id: periodId,
        ...formatDates(comparisionMonthDate),
      };
      setIsComparativeDataLoading(true);
      Promise.all([
        fetchComparativeMonthsRows(reportId, endpointParams),
        fetchComparativeMonthsColumns(reportId, endpointParams),
      ]).then(() => setIsComparativeDataLoading(false));
    }
  }, [
    comparisionMonthDate,
    periodId,
    reportId,
    fetchComparativeMonthsColumns,
    fetchComparativeMonthsRows,
    has_date_range_selected,
  ]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const onSaveSelectDates = (dates, nameDate) =>
    setComparisionMonthDate(prevState => {
      return { ...prevState, [nameDate]: dates };
    });

  const renderTableActions = () => (
    <ButtonActionBar position="space-between">
      <Space direction="horizontal">
        <References
          referencesItems={[
            {
              type: '123',
              title: t('LABEL_REALES'),
              description: t('CONTROL_REAL_DATA'),
            },
            ...(reportFunctionalCurrencyData
              ? [
                  {
                    type: <DollarOutlined />,
                    title:
                      reportFunctionalCurrencyData &&
                      reportFunctionalCurrencyData.code,
                    description:
                      reportFunctionalCurrencyData &&
                      t('REFERENCE_DESCRIPTION', {
                        currencyName: t(
                          reportFunctionalCurrencyData.name
                        ).toLowerCase(),
                      }),
                  },
                ]
              : []),
          ]}
        />
      </Space>
      <Space direction="horizontal">
        <Button
          type="text"
          shape="circle"
          title={t('ACTION_DOWNLOAD_TABLE')}
          icon={<Typography.Icon icon={DownloadOutlined} />}
          onClick={() =>
            downloadComparativeMonthsXLS(
              reportId,
              {
                period_id: periodId,
                format_number: FORMATS_TYPES[comparationTableSettings.format],
                ...formatDates(comparisionMonthDate),
              },
              t('REPORT_COMPARISON_MONTH_FILE_NAME', {
                periodName: periodDetail.name,
              })
            )
          }
        />
        <TableSettingsModal
          onConfirm={setComparationTableSettings}
          settings={comparationTableSettings}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderRangesPickers = () => {
    const disabledDate = (date, key) => {
      const pickerDates = moment(date._d).format('YYYY-MM-01');
      const datesSelectedFirstRange =
        comparisionMonthDate.first_date_range &&
        pickerDates >=
          moment(comparisionMonthDate.first_date_range[0]).format(
            'YYYY-MM-01'
          ) &&
        pickerDates <=
          moment(comparisionMonthDate.first_date_range[1]).format('YYYY-MM-01');

      return (
        !(
          pickerDates >= periodDetail?.start_date &&
          pickerDates <= periodDetail?.end_date
        ) ||
        (key === 'second_date_range' && datesSelectedFirstRange)
      );
    };

    return (
      <Space direction="horizontal">
        {_.keys(comparisionMonthDate).map(key => (
          <RangePicker
            key={key}
            disabledDate={date => disabledDate(date, key)}
            defaultPickerValue={
              !_.isEmpty(periodDetail)
                ? [
                    moment(periodDetail?.start_date),
                    moment(periodDetail?.end_date),
                  ]
                : null
            }
            onChange={dates => onSaveSelectDates(dates, key)}
            picker="month"
          />
        ))}
      </Space>
    );
  };

  const renderAlert = () => (
    <Alert
      message={t('REPORT_COMPARISON_MONTH_INFORMATIVE_MESSAGE_ALERT')}
      closable
      type="info"
      showIcon
    />
  );

  const renderTable = () => (
    <Table
      rowKey="id"
      size="small"
      columns={rendrColumns()}
      dataSource={reportComparativeMonthsRows}
      rowClassName={record => ROWS.TYPES[record.type]}
      loading={
        isDataLoading || !has_date_range_selected || isComparativeDataLoading
      }
      defaultExpandAllRows={false}
      scroll={{
        x: calculateScrollX(rendrColumns(), 10),
        y: 500,
      }}
      pagination={false}
      components={columnsHelper.tableComponents}
    />
  );

  const rendrColumns = () => {
    return reportComparativeMonthsColumns.map(col => {
      return {
        ...(!col.is_transaction && {
          title: t('FIELD_CONCEPT'),
          width: COLUMNS.WIDTH.EXPANDABLE,
          fixed: 'left',
          render: record => record[col.data_index],
        }),
        ...(col.is_transaction && {
          ...col,
          width: COLUMNS.WIDTH.REPORT_TRANSACTION,
          render: record => {
            if (record[col.data_index]) {
              if (col.data_index.includes('%')) {
                return (
                  <CellStyle.VariationTable value={record[col.data_index]} />
                );
              }
              return (
                <CellStyle.Number
                  value={record[col.data_index]}
                  format={comparationTableSettings.format}
                  has_restriction={has_restriction}
                />
              );
            }
            return '-';
          },
        }),
      };
    });
  };

  return (
    <>
      <Row gutter={[16, 12]}>
        <Col span={24}>{renderAlert()}</Col>
        <Col span={24}>{renderRangesPickers()}</Col>
        {(comparisionMonthDate?.first_date_range?.length > 0 ||
          comparisionMonthDate?.second_date_range?.length > 0) && (
          <>
            <Col span={24}>{renderTableActions()}</Col>
            <Col span={24}>{renderTable()}</Col>
          </>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  reportComparativeMonthsRows:
    reports.selectors.getReportComparativeMonthsRows(state),
  reportComparativeMonthsColumns:
    reports.selectors.getReportComparativeMonthsColumns(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchComparativeMonthsRows: reports.actions.fetchComparativeMonthsRows,
  fetchComparativeMonthsColumns: reports.actions.fetchComparativeMonthsColumns,
  downloadComparativeMonthsXLS: reports.actions.downloadComparativeMonthsXLS,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparisionMonth);
