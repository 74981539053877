import React, { useState } from 'react';
import { Table, Popconfirm, Space, Button, message, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  InputCell,
  Typography,
  DeleteAndUpdate,
} from 'modules/core/components';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { connect } from 'react-redux';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import reports from 'modules/reports';

const {
  SETTINGS__REPORTS__REPORTSPNL__EDIT,
  SETTINGS__REPORTS__REPORTSPNL__DELETE,
} = ROLES;

const ReportListTable = ({
  searchValue,
  dataSource,
  functionSearch,
  isScreenLoading,
  onFetchReportList,
  deleteReport,
  editReport,
}) => {
  const [editRow, setEditRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const [deleteReportId, setDeleteReportId] = useState(null);
  const { t } = useTranslation();
  let history = useHistory();

  const onCellEdit = (name, value) =>
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      render: (name, record) =>
        editRow && record.id === editRow ? (
          <InputCell
            initialValue={name}
            onCellUpdate={onCellEdit}
            name="name"
          />
        ) : (
          name
        ),
    },
    {
      title: t('CONFIG_REPORTS_GROUPING_FIELD'),
      dataIndex: 'grouper_count',
    },
    {
      title: t('CONFIG_REPORTS_TOTALIZING_FILED'),
      dataIndex: 'totalizer_count',
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id, record) => {
        return id !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_REPORT_DELETE_TITLE')}
            okText={t('ACTION_DELETE')}
            okButtonProps={{ loading: isDeletingReport }}
            onConfirm={() => onDeleteReport()}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteReportId(null)}
            visible={deleteReportId === id}
          >
            <Space size="middle">
              <Button
                type="text"
                shape="circle"
                icon={<Typography.Icon icon={EyeOutlined} />}
                title={t('ACTION_OPEN_DETAIL')}
                onClick={() =>
                  history.push(
                    `/configuracion/misdatos/reportes/economicos/reporte/${id}`
                  )
                }
              />
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => setEditRow(id),
                    disabled: !isUserAllowed(
                      SETTINGS__REPORTS__REPORTSPNL__EDIT
                    ),
                  },
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteReportId(id),
                    disabled: !isUserAllowed(
                      SETTINGS__REPORTS__REPORTSPNL__DELETE
                    ),
                  },
                ]}
              />
            </Space>
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={onEditReportName}
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        );
      },
    },
  ];

  const onCancel = () => setEditRow(null);

  const onConfirm = () => {
    setEditRow(null);
    setDataCellEdit({});
  };

  const onEditReportName = () => {
    if (!_.isEmpty(dataCellEdit)) {
      setIsEditing(true);
      editReport(editRow, dataCellEdit)
        .then(() => {
          onFetchReportList().then(() => {
            setIsEditing(false);
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
            setEditRow(null);
            setDataCellEdit({});
          });
        })
        .catch(() => {
          setIsEditing(false);
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          });
          setEditRow(null);
          setDataCellEdit({});
        });
    } else {
      setEditRow(null);
      setDataCellEdit({});
    }
  };

  const onDeleteReport = () => {
    setIsDeletingReport(true);
    deleteReport(deleteReportId)
      .then(() =>
        onFetchReportList().then(() => {
          message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
          setDeleteReportId(null);
          setIsDeletingReport(false);
        })
      )
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_FAIL'),
          duration: 0,
        });
        setDeleteReportId(null);
        setIsDeletingReport(false);
      });
  };

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={isScreenLoading || isEditing}
      columns={columns}
      dataSource={
        searchValue === null
          ? dataSource
          : functionSearch(dataSource, searchValue)
      }
      pagination={{
        pageSize: 10,
        size: 'small',
        hideOnSinglePage: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  editReport: reports.actions.editReport,
  deleteReport: reports.actions.deleteReport,
};

export default connect(null, mapDispatchToProps)(ReportListTable);
