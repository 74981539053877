const ACCOUNTS_LABEL = 'Cuentas';
const ACCOUNTS_ID = 1;
const ACCOUNT_LABEL = 'Cuenta';

const ACCOUNTS = {
  id: ACCOUNTS_ID,
  label: ACCOUNTS_LABEL,
};

const REPORTS_LABEL = 'Reportes';
const REPORTS_ID = 10001;

const REPORTS = {
  id: REPORTS_ID,
  label: REPORTS_LABEL,
  url: '/report/reports/?flat=True',
};

const REPORTS_CUSTOM_LABEL = 'Reportes personalizados';
const REPORTS_CUSTOM_ID = 10002;

const REPORTS_CUSTOM = {
  id: REPORTS_CUSTOM_ID,
  label: REPORTS_CUSTOM_LABEL,
  url: '/report/custom',
};

const ALL_FIELDS_ID = 0;
// NOTE: No deberia usarse, quedo legacy por la parte de factores
const ALL_FIELDS_ID_STRING = '0';

const LEVEL_KEY = 'Nivel';

const DIMENSIONS = {
  ACCOUNTS,
  ACCOUNT_LABEL,
  REPORTS,
  REPORTS_CUSTOM,
  ALL_FIELDS_ID,
  ALL_FIELDS_ID_STRING,
  LEVEL_KEY,
};

export default DIMENSIONS;
