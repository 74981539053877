import { NAME } from './constants';
import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18next';
import { ROWS, COLUMNS } from 'modules/core/constants';

export const getReportDetail = state => state[NAME].reportDetail;
export const getReportList = state => state[NAME].reportList;
export const getReportColumnsDefault = state => state[NAME].reportColumns;

export const getReportColumns = createSelector(
  getReportColumnsDefault,
  reportColumns => {
    const dataReportColumns = {};
    _.keys(reportColumns).forEach(key => {
      Object.assign(dataReportColumns, {
        [key]: [
          ...reportColumns[key].map(column => {
            return {
              ...column,
              ...(!column.is_transaction && {
                width:
                  column.data_index === COLUMNS.TOTAL_DATA_INDEX
                    ? COLUMNS.WIDTH.TRANSACTION
                    : COLUMNS.WIDTH.EXPANDABLE,
              }),
              ...(column.is_transaction && {
                width: COLUMNS.WIDTH.TRANSACTION,
              }),
            };
          }),
        ],
      });
    });
    return dataReportColumns;
  }
);

export const getReportKPIsDefault = state => state[NAME].reportKPIs;

export const getReportKPIs = createSelector(
  getReportKPIsDefault,
  reportKPIs => {
    const dataKpis = {};
    _.keys(reportKPIs).forEach(key =>
      Object.assign(dataKpis, {
        [key]: [...reportKPIs[key]].sort(function (kpiA, kpiB) {
          if (kpiA.order > kpiB.order) return 1;
          if (kpiA.order < kpiB.order) return -1;
          return 0;
        }),
      })
    );
    return dataKpis;
  }
);

export const getReportTableByPeriods = state =>
  state[NAME].reportTableByPeriods;
export const getReportDetailTable = state => state[NAME].reportDetailTable;
export const getReportStructure = state => state[NAME].reportStructure;
export const getReportSegmentedDataRow = state =>
  state[NAME].reportSegmentedDataRow;
export const getReportSegmentedColumns = state =>
  state[NAME].reportSegmentedColumns;
export const getReportVerticalComparisonDataRow = state =>
  state[NAME].reportVerticalComparisonDataRow;
export const getReportVerticalComparisonColumns = state =>
  state[NAME].reportVerticalComparisonColumns;
export const getReportVerticalSegmentedDataRow = state =>
  state[NAME].reportVerticalSegmentedDataRow;
export const getReportVerticalSegmentedColumns = state =>
  state[NAME].reportVerticalSegmentedColumns;

export const getDimensionTransactionsList = state =>
  state[NAME].dimensionTransactionsList;

export const getDimensionValuesByRow = state =>
  state[NAME].dimensionValuesByRow;

export const getReportChartData = createSelector(
  getReportColumnsDefault,
  getReportTableByPeriods,
  (reportColumns, reportData) => {
    // Preparo el eje X
    const horizontalAxis = reportColumns.columns
      .filter(column => {
        if (reportData.startDate && reportData.endDate) {
          return (
            column.is_transaction &&
            column.title >= reportData.startDate &&
            column.title <= reportData.endDate
          );
        }
        return column.is_transaction;
      })
      .map(column => column.title);

    return _.map(reportData.rows, (report, reportKey) => {
      // Filtro la maxima row totalizadora.
      const maxReportElement = !reportData.lineId
        ? report
            .filter(elem => elem.type === ROWS.TOTALIZER)
            .reduce(
              (prev, current) => (prev.y > current.y ? prev : current),
              {}
            )
        : report.find(data => data.id === reportData.lineId);

      const valuesAxisY = horizontalAxis.map(period => {
        // Igualo los periodos para que no haya defasaje de años.
        // TODO: Analizar en periodos mayores a 12 meses.
        const yValue =
          reportKey === 'prev_real'
            ? maxReportElement[moment(period).add(-1, 'y').format('YYYY-MM-DD')]
            : maxReportElement[period];
        return {
          x: moment(period).format('MMM'),
          y: yValue ? yValue : '0',
        };
      });

      return {
        id: i18n.t(reportKey),
        data: valuesAxisY.asMutable({ deep: true }),
      };
    });
  }
);

export const getCustomReportColumns = state => state[NAME].customReportColumns;
export const getCustomReportRows = state => state[NAME].customReportRows;
export const getCustomReportList = state => state[NAME].customReportsList;
export const getCustomReportDetail = state => state[NAME].customReportsDetail;

export const getCustomReportGraphData = state =>
  state[NAME].customReportGraphData;

export const getCustomReportBarData = createSelector(
  getCustomReportGraphData,
  customReportGraphData => {
    const barData = customReportGraphData.bar;
    if (_.isEmpty(barData)) {
      return { alldimensions: [], values: [] };
    }
    let allDimensions = [];
    const barDataTransformed = _.reduce(
      barData,
      (accum, values, projectionKey) => {
        _.forEach(values, (value, valueKey) => {
          allDimensions.push(valueKey);

          accum[valueKey] = {
            ...accum[valueKey],
            dimension: valueKey,
            [projectionKey]: value,
          };
        });
        return accum;
      },
      {}
    );
    return {
      allDimensions: [...new Set(allDimensions)],
      values: _.values(barDataTransformed),
    };
  }
);

export const getCustomReportPieData = createSelector(
  getCustomReportGraphData,
  customReportGraphData => {
    const pieData = customReportGraphData.pie;
    if (_.isEmpty(pieData)) {
      return [];
    }
    const pieDataTransformed = _.reduce(
      pieData,
      (accum, values, projectionKey) => {
        const itemTotalAmount = _.reduce(
          values,
          (accum, el) => accum + el.value,
          0
        );

        // NOTE: Valido que tenga montos y armo la estructura para el grafico
        if (itemTotalAmount) {
          accum[projectionKey] = {
            ...accum[projectionKey],
            id: projectionKey,
            totalAmount: itemTotalAmount,
            values: _.map(values, (el, key) => {
              return {
                id: key,
                label: key,
                amount: el.value,
                percentage: el.percentage,
              };
            }),
          };
        }

        return accum;
      },
      {}
    );
    return _.values(pieDataTransformed);
  }
);

//financiero

export const getFinancialReportsList = state =>
  state[NAME].financialReportsList;

export const getFinancialReportColumnsDefault = state =>
  state[NAME].financialReportColumns;

export const getFinancialReportColumns = createSelector(
  getFinancialReportColumnsDefault,
  financialReportColumns => {
    const dataFinancialReportColumns = {};
    _.keys(financialReportColumns).forEach(key => {
      Object.assign(dataFinancialReportColumns, {
        [key]: [
          ...financialReportColumns[key].map(column => {
            return {
              ...column,
              ...(!column.is_transaction && {
                width:
                  column.data_index === COLUMNS.TOTAL_DATA_INDEX
                    ? COLUMNS.WIDTH.TRANSACTION
                    : COLUMNS.WIDTH.EXPANDABLE,
              }),
              ...(column.is_transaction && {
                width: COLUMNS.WIDTH.TRANSACTION,
              }),
            };
          }),
        ],
      });
    });
    return dataFinancialReportColumns;
  }
);

export const getFinancialReportRows = state => state[NAME].financialReportRows;
export const getFinancialReportChartDataDefault = state =>
  state[NAME].financialReportChartData;

export const getFinancialReportChartData = createSelector(
  getFinancialReportChartDataDefault,
  financialReportChartData => {
    const idsAux = { real: 'Real', prev_real: 'Real periodo anterior' };
    let chartData = [];

    _.forEach(financialReportChartData.data, (projection, key) => {
      const ids = _.keys(projection);
      const auxData = ids.map(id => {
        return {
          id: `${idsAux[key]} ${id}`,
          data: projection[id]
            .asMutable()
            .filter(date => {
              if (
                financialReportChartData.financialStartDate &&
                financialReportChartData.financialEndDate
              ) {
                return (
                  date.x >= financialReportChartData.financialStartDate &&
                  date.x <= financialReportChartData.financialEndDate
                );
              }
              return date;
            })
            .map(dataDate => {
              return {
                ...dataDate,
                x: moment(dataDate.x).format('MMM'),
              };
            }),
        };
      });
      chartData = [...chartData, ...auxData];
    });
    return chartData;
  }
);

export const getFinancialReportKPIsDefault = state =>
  state[NAME].financialReportKPIs;

export const getFinancialReportKPIs = createSelector(
  getFinancialReportKPIsDefault,
  financialReportKPIs => {
    const dataKpis = {};
    _.keys(financialReportKPIs).forEach(key =>
      Object.assign(dataKpis, {
        [key]: [...financialReportKPIs[key]].sort(function (kpiA, kpiB) {
          if (kpiA.order > kpiB.order) return 1;
          if (kpiA.order < kpiB.order) return -1;
          return 0;
        }),
      })
    );
    return dataKpis;
  }
);

export const getHasInitialSituation = state => state[NAME].hasInitialSituation;

//reporte rdn
export const getReportRuleData = state => state[NAME].reportRuleData;

//comparative months
export const getReportComparativeMonthsRows = state =>
  state[NAME].reportComparativeMonthsRows;
export const getReportComparativeMonthsColumns = state =>
  state[NAME].reportComparativeMonthsColumns;

export const getSegregatedAnalysisRows = state =>
  state[NAME].segregatedAnalysisRows;
export const getSegregatedAnalysisColumns = state =>
  state[NAME].segregatedAnalysisColumns;

export const getAvailableDimensions = state => state[NAME].availableDimensions;
