import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'modules/core/customHooks';
import { Button, Checkbox, Drawer, message, Space, Spin, Tooltip } from 'antd';
import { ButtonActionBar } from 'modules/core/components';
import RoleDimensionSelector from './RoleDimensionSelector';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';

const { SETTINGS__SECURITY__ROLES__CREATE, SETTINGS__SECURITY__ROLES__EDIT } =
  ROLES;

const RoleDimensionsDrawer = ({
  visible,
  roleId,
  operationName,
  operationKeys,
  roleDimensions,
  onClose,
  fetchDimensionsList,
  fetchRoleDimensionsValues,
  selectRoleDimension,
  cleanAllRoleDimension,
  asignRoleDimensions,
  isLastChild,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDimensions, setIsLoadingDimensions] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [replySubmodules, toogleReplySubmodules] = useToggle();
  const { t } = useTranslation();

  useEffect(() => {
    fetchDimensionsList({
      flat: 'True',
      writeable: 'True',
      roleDimensions: true,
    })
      .then(() => setIsLoadingDimensions(false))
      .catch(() => null);
  }, [fetchDimensionsList]);

  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      fetchRoleDimensionsValues({ roleId, operations: operationKeys })
        .then(() => setIsLoading(false))
        .catch(() => null);
    }
  }, [visible, roleId, operationKeys, fetchRoleDimensionsValues]);

  const canUserEdit = isUserAllowed([
    SETTINGS__SECURITY__ROLES__CREATE,
    SETTINGS__SECURITY__ROLES__EDIT,
  ]);

  const onCancel = () => {
    onClose();
    cleanAllRoleDimension();
  };

  const onSave = () => {
    setIsSaving(true);
    const data = {
      roleId,
      operations: operationKeys,
      reply_submodules: replySubmodules,
      dimensions: _.map(roleDimensions, (dim, key) => {
        return {
          id: key,
          values: dim.values,
          selected_all: dim.selected_all ? dim.selected_all : false,
        };
      }),
    };
    asignRoleDimensions(data)
      .then(() => {
        setIsSaving(false);
        if (replySubmodules) {
          toogleReplySubmodules();
        }
        message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
      })
      .catch(() => {
        setIsSaving(false);
        message.error(t('FEEDBACK_SAVE_CHANGES_FAIL'));
      });
  };

  return (
    <Drawer
      title={t('CONFIG_ROLES_DRAWER_ROLE_DIMENSIONS_TITLE', {
        name: operationName,
      })}
      placement="right"
      maskClosable={false}
      onClose={onCancel}
      visible={visible}
      width={500}
      footer={
        <ButtonActionBar position={isLastChild ? 'end' : 'space-between'}>
          {!isLastChild && (
            <Tooltip title={t('CONFIG_ROLES_REPLY_SUBMODULES_TOOLTIP')}>
              <Checkbox
                onChange={toogleReplySubmodules}
                checked={replySubmodules}
              >
                {t('CONFIG_ROLES_REPLY_SUBMODULES')}
              </Checkbox>
            </Tooltip>
          )}
          <Space>
            <Button onClick={onCancel}>{t('ACTION_CANCEL')}</Button>
            <Button
              type="primary"
              onClick={onSave}
              loading={isSaving}
              disabled={isLoading || isLoadingDimensions || !canUserEdit}
            >
              {t('ACTION_SAVE_CHANGES')}
            </Button>
          </Space>
        </ButtonActionBar>
      }
    >
      <Spin spinning={isLoading || isLoadingDimensions} />
      {!isLoading &&
        !isLoadingDimensions &&
        _.map(roleDimensions, (dim, key) => (
          <RoleDimensionSelector
            key={key}
            disabled={!canUserEdit}
            dimension={{
              id: key,
              url: dim.url,
              label: dim.label,
              value: dim.values,
              isAll: dim.isAll,
            }}
            onChange={(values, selected_all) =>
              selectRoleDimension({ dimensionId: key, values, selected_all })
            }
          />
        ))}
    </Drawer>
  );
};

const mapStateToProps = state => ({
  roleDimensions: configuration.selectors.getRoleDimensions(state),
});

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchRoleDimensionsValues: configuration.actions.fetchRoleDimensionsValues,
  selectRoleDimension: configuration.actions.selectRoleDimension,
  cleanAllRoleDimension: configuration.actions.cleanAllRoleDimension,
  asignRoleDimensions: configuration.actions.asignRoleDimensions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleDimensionsDrawer);
