import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { useCollapser } from 'modules/core/customHooks';
import { calculateScrollX, columnsHelper } from 'modules/core/utils';
import { useParams } from 'react-router-dom';
import {
  CONCEPTS,
  COLUMNS,
  PROJECTION,
  ACCES_USERS,
} from 'modules/core/constants';
import control from 'modules/control';
import login from 'modules/login';

import './FactorTable.scss';

const isForecast = type => type === PROJECTION.FORECAST;

const FactorTable = ({
  dataSource,
  columns,
  onChange,
  loading,
  pagination,
  tableSettings: { visibleData, format, showNegativeInRed },
  canDelete,
  dataSelection: { selection, setSelection },
  onCellEdit,
  editable,
  filters,
  filterOptions,
  onFilter,
  comments: { showRowsComment, onComment, onDeleteComment },
  fetchMonthsToApprove,
  fetchApproveDates,
  monthsToApprove,
  approveDates,
  projectionType,
  loggedUser: { email },
}) => {
  const [isCollapsed, toogleIsCollapsed, isCollapsing] = useCollapser();
  let { periodId } = useParams();

  useEffect(() => {
    Promise.all([
      fetchMonthsToApprove(periodId, {
        concept_type: CONCEPTS.TYPE.ECONOMIC_KEY,
      }),
      fetchApproveDates(periodId, { concept_type: CONCEPTS.TYPE.ECONOMIC_KEY }),
    ]);
  }, [fetchMonthsToApprove, fetchApproveDates, periodId]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const monthsPublishAndApprove = [...monthsToApprove, ...approveDates];

  const renderTransactionColumn = column =>
    columnsHelper.renderTransactionPlanning({
      date: column.title,
      ...(isForecast(projectionType) && {
        className: !column.editable && COLUMNS.TYPES.TOTALIZER,
      }),
      cellFormat: { format, showNegativeInRed },
      disabled:
        !editable ||
        (isForecast(projectionType) &&
          monthsPublishAndApprove.includes(column.title)),
      onCellEdit,
      showCalculateValue: false,
      comments: {
        onComment: onComment,
        onDeleteComment: onDeleteComment,
      },
      has_restriction,
    });

  const columnsGenerator = () =>
    columnsHelper.generator({
      columns,
      filteredColumns: visibleData,
      cellFormat: { format, showNegativeInRed },
      filterProps: { filterOptions, filters, onFilter },
      collapserProps: {
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      // sorter: true,
      renderTransaction: renderTransactionColumn,
      has_restriction,
    });

  return (
    <Table
      size="small"
      rowKey="id"
      key={columnsGenerator().length}
      rowSelection={
        canDelete
          ? {
              getCheckboxProps: record => ({
                className: record.id === 0 ? 'hide-check' : '',
                disabled: record.id === 0,
              }),
              selectedRowKeys: selection,
              onChange: idsRows => setSelection(idsRows),
            }
          : undefined
      }
      loading={loading}
      onChange={onChange}
      columns={columnsGenerator()}
      dataSource={loading !== true ? dataSource : []}
      pagination={{
        ...pagination,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
      locale={columnsHelper.renderSorterLocale()}
      {...(!isCollapsing && {
        scroll: {
          x: calculateScrollX(columnsGenerator()),
          y: 445,
        },
      })}
    />
  );
};

const mapStateToProps = state => ({
  monthsToApprove: control.selectors.getMonthsToApprove(state),
  approveDates: control.selectors.getApproveDates(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchMonthsToApprove: control.actions.fetchMonthsToApprove,
  fetchApproveDates: control.actions.fetchApproveDates,
};

export default connect(mapStateToProps, mapDispatchToProps)(FactorTable);
