import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  usePagination,
  useSorter,
  useToggle,
  useFunctionalCurrency,
} from 'modules/core/customHooks';
import {
  Row,
  Col,
  Spin,
  message,
  Popconfirm,
  Button,
  notification,
  Space,
  Card,
  Radio,
} from 'antd';
import {
  ButtonActionBar,
  References,
  Typography,
  TableSettingsModal,
  UploadDraggerScreen,
  projectionSuccessUploadNotice,
  KPIValue,
  DropdownMenu,
  ProjectionUploadModal,
  ShowCommentsButton,
  DeleteSelectionButton,
  InputNumberPercentage,
  ButtonsSwitchDataType,
} from 'modules/core/components';
import {
  DownloadOutlined,
  SolutionOutlined,
  FileExcelOutlined,
  DeleteOutlined,
  DollarOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import PlanningTable from './PlanningTable/PlanningTable';
import {
  isApproved,
  isInElaboration,
  isPublished,
  isUserAllowed,
  formItemsHelper,
  GA,
  renderPrefix,
  isAmount,
} from 'modules/core/utils';
import {
  ROLES,
  CONCEPTS,
  TABLES,
  COLUMNS,
  PROJECTION,
  REPORT,
} from 'modules/core/constants';
import planning from 'modules/planning';
import login from 'modules/login';

const {
  PLANNING__EXPENSES__CREATE,
  PLANNING__SALES__CREATE,
  PLANNING__EXPENSES__EDIT,
  PLANNING__SALES__EDIT,
  PLANNING__EXPENSES__DELETE,
  PLANNING__SALES__DELETE,
  PLANNING__EXPENSES__UPLOAD,
  PLANNING__SALES__UPLOAD,
  PLANNING__EXPENSES__EXCHANGE_RATE,
  PLANNING__SALES__EXCHANGE_RATE,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PLANNING__CONCEPT = {
  [CONCEPTS.KEYS.SALES]: {
    EDIT: [PLANNING__SALES__CREATE, PLANNING__SALES__EDIT],
    DELETE: PLANNING__SALES__DELETE,
    UPLOAD: PLANNING__SALES__UPLOAD,
    EXCHANGE_RATE: PLANNING__SALES__EXCHANGE_RATE,
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    EDIT: [PLANNING__EXPENSES__CREATE, PLANNING__EXPENSES__EDIT],
    DELETE: PLANNING__EXPENSES__DELETE,
    UPLOAD: PLANNING__EXPENSES__UPLOAD,
    EXCHANGE_RATE: PLANNING__EXPENSES__EXCHANGE_RATE,
  },
};

const PlanningAmountDetail = ({
  projection,
  projectionColumns,
  projectionRows,
  projectionKpis,
  filterTableOptions,
  fetchProjectionDetail,
  fetchProjectionColumns,
  fetchProjectionRows,
  fetchProjectionKpis,
  fetchProjectionFilters,
  downloadProjectionXLS,
  updateTransaction,
  updateTransactionForward,
  updateTransactionAll,
  deleteBudgetRow,
  deleteAllProjectionRows,
  downloadTemplateXLS,
  uploadProjectionXLS,
  syncProjectionComment,
  createTransactionComment,
  deleteTransactionComment,
  editTransactionOriginCurrency,
  createTransactionOriginCurrency,
  fetchOriginRows,
  updateTransactionForwardOrigin,
  pxq,
  fetchPXQ,
  enablePXQ,
  updateCellPXQ,
  createPXQ,
  setDataUpdateCellPXQ,
  projectionColumnsImporte,
  loggedUser: { email },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isKpisLoading, setIsKpisLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [tablePagination, setTablePagination, totalizerRows] = usePagination({
    totalizerRows: 1,
  });
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS(email),
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
    showNegativeInRed: false,
  });
  const [showRowsComment, toggleShowRowsComment] = useToggle(false);
  const [filters, setFilters] = useState({});
  const [tableSorter, setTableSorter] = useSorter();
  const [refetchFilters, setRefetchFilters] = useState(true);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [isDeletingSelection, setIsDeletingSelection] = useState(false);
  const [dataSelection, setDataSelection] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [optionLoadFile, setOptionLoadFile] = useState(
    PROJECTION.LOAD_TYPES.CONVENTIONAL.id
  );
  const [dataUndoCellEditAll, setDataUndoCellEditAll] = useState([]);
  const [tableTypeKey, setTableTypeKey] = useState(REPORT.REPORTS_KEYS.AMOUNT);
  const [isEnabling, setIsEnabling] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  let { budgetId, periodId, conceptName } = useParams();
  let history = useHistory();
  const { t } = useTranslation();

  const isOptionLaodFileFlat =
    optionLoadFile === PROJECTION.LOAD_TYPES.PLANE.id;

  useEffect(() => {
    fetchPXQ();
  }, [fetchPXQ]);

  useEffect(() => {
    setIsScreenLoading(true);
    fetchProjectionColumns(budgetId, { pxq: 'True' }).then(() =>
      setIsScreenLoading(false)
    );
  }, [budgetId, fetchProjectionColumns]);

  useEffect(() => {
    if (projection.concept.id && refetchFilters) {
      fetchProjectionFilters(projection.concept.id, {
        conventional: budgetId,
        ...filters,
      })
        .then(() => setRefetchFilters(false))
        .catch(() => setRefetchFilters(false));
    }
  }, [
    fetchProjectionFilters,
    budgetId,
    projection.concept.id,
    refetchFilters,
    filters,
  ]);

  useEffect(() => {
    setIsKpisLoading(true);
    fetchProjectionKpis(budgetId, { ...filters }).then(() =>
      setIsKpisLoading(false)
    );
  }, [fetchProjectionKpis, budgetId, filters]);

  useEffect(() => {
    setIsTableLoading(true);
    fetchProjectionRows(budgetId, {
      ...tablePagination,
      ...filters,
      ...tableSorter,
      formula_id: pxq.length > 0 ? pxq[0].id : null,
    }).then(() => setIsTableLoading(false));
  }, [
    fetchProjectionRows,
    budgetId,
    tablePagination,
    filters,
    tableSorter,
    pxq,
  ]);

  const canUserEdit = isUserAllowed(
    PLANNING__CONCEPT[projection.concept.name].EDIT
  );
  const canUserUpload = isUserAllowed(
    PLANNING__CONCEPT[projection.concept.name].UPLOAD
  );
  const canUserDelete = isUserAllowed(
    PLANNING__CONCEPT[projection.concept.name].DELETE
  );
  const canUserExchangeRateView = isUserAllowed(
    PLANNING__CONCEPT[projection.concept.name].EXCHANGE_RATE
  );

  //TODO: REVISAR Y AJUSTAR CUANDO BACK NOS MANDE EL NOMBRE DEL ESTADO PUBLICADO
  const isProjectionStatusEditable =
    isInElaboration(projection.status) || isPublished(projection.status);

  const isTableEditable =
    canUserEdit &&
    isProjectionStatusEditable &&
    projection.variables &&
    projection.variables.length === 0;

  const notificationErrorBase = {
    message: t('FEEDBACK_DEFAULT_ERROR'),
    duration: 0,
  };

  const handleMessageError = () => message.error(t('FEEDBACK_DEFAULT_ERROR'));

  const onSuccessUploadFile = response => {
    projectionSuccessUploadNotice({
      projectionType: 'presupuesto',
      errorFile: response.payload.data.error_file,
    });
    setTablePagination({ current: 1 });
    setRefetchFilters(true);
    setOptionLoadFile(PROJECTION.LOAD_TYPES.CONVENTIONAL.id);
    fetchProjectionDetail(budgetId);
    fetchProjectionKpis(budgetId, { ...filters });
    GA.event(GA.ACTIONS.FILE_UPLOAD_BUDGET);
  };

  const onFailUploadFile = error => {
    notification.error({
      ...notificationErrorBase,
      key: 'error_file',
      description: error.response.data,
    });
    GA.event(GA.ACTIONS.FILE_UPLOAD_BUDGET_ERROR);
  };

  const handleMessageSuccess = () => {
    message.destroy('loading_edit');
    message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES', 0.5));
  };

  const onEditTransaction = (editFn, { qp = {}, ...data }) =>
    //setDataUndoCellEditAll = null
    {
      message.loading({
        key: 'loading_edit',
        content: t('FEEDBACK_LOADING_CHANGES'),
      });
      editFn(data, qp)
        .then(response => {
          //NOTE: COMENTADO PORQUE ESTA TIRANDO ERROR, ANALIZAR PORQUE SE HABIA AGREGADO
          // setDataUndoCellEditAll &&
          //   setDataUndoCellEditAll(response.payload.data[1]);
          Promise.all([
            fetchProjectionKpis(budgetId, { ...filters }),
            fetchProjectionRows(budgetId, {
              ...tablePagination,
              ...tableSorter,
              ...filters,
              formula_id: pxq.length > 0 ? pxq[0].id : null,
            }),
          ]).finally(() => {
            data.parentId
              ? fetchOriginRows(data.parentId, { expand_row: true }).then(
                  () => {
                    handleMessageSuccess();
                  }
                )
              : handleMessageSuccess();
          });
        })
        .catch(handleMessageError);
    };

  const handleOnCalculateValue = (data, isOriginCurrencyRow) => {
    if (isOriginCurrencyRow) {
      if (data.transactionId) {
        onEditTransaction(editTransactionOriginCurrency, {
          qp: { add: 'True' },
          ...data,
        });
      }
    } else {
      onEditTransaction(updateTransaction, {
        qp: { add: 'True' },
        ...data,
      });
    }
  };

  const handleCellEdit = (data, isOriginCurrencyRow, data_index) => {
    if (isOriginCurrencyRow) {
      if (data.transactionId) {
        onEditTransaction(editTransactionOriginCurrency, data);
      } else {
        onEditTransaction(createTransactionOriginCurrency, data);
      }
    } else {
      if (data_index && data_index !== 'amount') {
        handleCellEditPXQ(data);
      } else {
        onEditTransaction(
          updateTransaction,
          {
            ...(pxq.length > 0 && { qp: { formula_id: pxq[0].id } }),
            ...data,
          }
          //setDataUndoCellEditAll
        );
      }
    }
  };

  const handleCellEditForward = (data, isOriginCurrencyRow) => {
    const auxEndpoint = isOriginCurrencyRow
      ? updateTransactionForwardOrigin
      : updateTransactionForward;
    onEditTransaction(auxEndpoint, {
      ...data,
      ...(!data.endDate && {
        endDate: projection.period.end_date,
      }),
    });
  };

  const handleCellEditAll = ({ value, undoParams = null }) => {
    onEditTransaction(updateTransactionAll, {
      projectionId: budgetId,
      value,
      qp: undoParams ? { ...undoParams } : { ...filters },
    });
  };

  const handleOnChangeTable = (pagination, filters, sorter) => {
    setTablePagination(pagination);
    setTableSorter(sorter);
  };

  const onCommentCell = ({ columnId, rowId, transactionId, text }) => {
    createTransactionComment({ transactionId, text })
      .then(response => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionAmountsRows',
          newComment: response.payload.data,
        });
      })
      .catch(handleMessageError);
  };

  const onDeleteCommentCell = ({ columnId, rowId, commentId }) => {
    deleteTransactionComment({ commentId })
      .then(() => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionAmountsRows',
          commentId,
        });
      })
      .catch(handleMessageError);
  };

  const handleDeleteSelection = () => {
    setIsDeletingSelection(true);
    Promise.all(
      dataSelection.map(id =>
        deleteBudgetRow(id).catch(() =>
          notification.error({
            ...notificationErrorBase,
            description: t('FEEDBACK_DELETE_SELECTION_ERROR'),
          })
        )
      )
    ).then(() => {
      setRefetchFilters(true);
      Promise.all([
        fetchProjectionRows(budgetId, {
          ...tablePagination,
          ...tableSorter,
          ...filters,
          formula_id: pxq.length > 0 ? pxq[0].id : null,
        }),
        fetchProjectionDetail(budgetId),
      ]).then(() => {
        setIsDeletingSelection(false);
        message.success(t('FEEDBACK_DELETE_SELECTION_SUCCESS'));
      });
      setDataSelection([]);
    });
  };

  const onDeleteAll = () => {
    setIsTableLoading(true);
    setRefetchFilters(true);
    deleteAllProjectionRows(budgetId, {
      filter: PROJECTION.LOAD_TYPES.CONVENTIONAL.id,
    })
      .then(() => {
        setShowDeleteAll(false);
        Promise.all([
          fetchProjectionRows(budgetId, {
            ...tablePagination,
            ...tableSorter,
            ...filters,
            formula_id: pxq.length > 0 ? pxq[0].id : null,
          }),
          fetchProjectionDetail(budgetId),
        ]).then(() => {
          setIsTableLoading(false);
          message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
        });
      })
      .catch(() => {
        setIsTableLoading(false);
        setShowDeleteAll(false);
        notification.error({
          ...notificationErrorBase,
          description: t('FEEDBACK_DELETE_DATA_ERROR'),
        });
      });
  };

  const handleUndoCellEditAll = () => {
    const dataUndo = dataUndoCellEditAll.pop();
    handleCellEditAll({
      value: dataUndo.value,
      undoParams: { ...dataUndo.qp, percentage_rollback: 'True' },
    });
    setDataUndoCellEditAll([...dataUndoCellEditAll]);
  };

  const optionsLoadTypes = () => (
    <Space direction="vertical" style={{ textAlign: 'start' }}>
      <span>{`${t('FIELD_LOAD_TYPE')}:`}</span>
      <Radio.Group
        value={optionLoadFile}
        onChange={value => setOptionLoadFile(value.target.value)}
      >
        <Space direction="vertical">
          {PROJECTION.LOAD_TYPES_LIST.map(loadType => (
            <Radio value={loadType.id} key={loadType.id}>
              {t(loadType.name)}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Space>
  );

  const renderNoDataScreen = () => (
    <UploadDraggerScreen
      disabled={!canUserUpload}
      description={
        <Space direction="vertical">
          {t('PLANNING_EMPTY_SCREEN_UPLOAD_SUBTITLE')}
          {optionsLoadTypes()}
        </Space>
      }
      downloadTemplate={() =>
        downloadTemplateXLS(
          budgetId,
          `${t('PLANNING_TEMPLATE_NAME', {
            concept: projection.name,
            periodName: projection.period.name,
            loadTypeName: `(${t('FIELD_AMOUNT')} ${
              isOptionLaodFileFlat
                ? '- ' + t(PROJECTION.LOAD_TYPES.PLANE.name)
                : ''
            })`,
          })} `,
          isOptionLaodFileFlat && { file_format: optionLoadFile }
        )
      }
      downloadTooltip={
        isOptionLaodFileFlat
          ? t(PROJECTION.LOAD_TYPES.PLANE.name)
          : t(PROJECTION.LOAD_TYPES.CONVENTIONAL.name)
      }
      uploadFile={fileList =>
        uploadProjectionXLS(
          budgetId,
          fileList,
          isOptionLaodFileFlat && { file_format: optionLoadFile }
        )
      }
      onSuccessUpload={onSuccessUploadFile}
      onFailUpload={onFailUploadFile}
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Button
          type="primary"
          onClick={() => setShowUploadModal(true)}
          disabled={isApproved(projection.status) || !canUserUpload}
        >
          {t('ACTION_UPLOAD_FILE')}
        </Button>
        <Button
          icon={<SolutionOutlined />}
          onClick={() =>
            history.push(
              `/planificacion/${periodId}/${conceptName}/actividad/${budgetId}`
            )
          }
        >
          {t('ACTION_ACTIVITIES')}
        </Button>
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_LOAD_EXCHANGE_RATE'),
              icon: <DollarOutlined />,
              onClick: () =>
                history.push(
                  `/planificacion/${periodId}/tipodecambio/${budgetId}`
                ),
              disabled: !canUserExchangeRateView,
            },
            {
              title: t('ACTION_DOWNLOAD_TEMPLATE'),
              icon: <FileExcelOutlined />,
              submenu: [
                ...PROJECTION.LOAD_TYPES_LIST.map(loadType => {
                  return {
                    title: t(loadType.name),
                    key: loadType.id,
                    onClick: () => {
                      setOptionLoadFile(loadType.id);
                      downloadTemplateXLS(
                        budgetId,
                        t('PLANNING_TEMPLATE_NAME', {
                          concept: projection.name,
                          periodName: projection.period.name,
                          loadTypeName: `(${t('FIELD_AMOUNT')} ${
                            loadType.id === PROJECTION.LOAD_TYPES.PLANE.id
                              ? '- ' + t(PROJECTION.LOAD_TYPES.PLANE.name)
                              : ''
                          })`,
                        }),
                        loadType.id === PROJECTION.LOAD_TYPES.PLANE.id && {
                          file_format: loadType.id,
                        }
                      );
                    },
                  };
                }),
              ],
            },
            {
              title: t('ACTION_DELETE_ALL'),
              icon: <DeleteOutlined />,
              onClick: () => setShowDeleteAll(true),
              disabled:
                !canUserDelete ||
                !(
                  isInElaboration(projection.status) ||
                  isPublished(projection.status)
                ),
            },
          ]}
        />
        <Popconfirm
          placement="left"
          title={t('POPCONFIRM_DELETE_ALL_PAGES', {
            projectionName: projection && projection.name,
          })}
          okText={t('ACTION_DELETE')}
          onConfirm={onDeleteAll}
          cancelText={t('ACTION_CANCEL')}
          onCancel={() => setShowDeleteAll(false)}
          visible={showDeleteAll}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderKPIs = () => (
    <Row gutter={[24, 0]}>
      <>
        {isKpisLoading &&
          [1, 2, 3].map(index => (
            <Col span={8} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isKpisLoading &&
          projectionKpis.map((kpi, index) => (
            <Col span={8} key={index}>
              <Card style={{ height: '100%' }}>
                <Space>
                  <KPIValue
                    size="medium"
                    prefix={renderPrefix(functionalCurrencyData, kpi.prefix)}
                    fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                    value={
                      kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'
                    }
                  />
                  {kpi.variation && (
                    <KPIValue
                      value={
                        kpi.variation
                          ? numeral(kpi.variation).format('0%')
                          : '-'
                      }
                      type="secondary"
                    />
                  )}
                </Space>
                <br />
                <Typography.Body level={3} type="secondary">
                  {kpi.name}
                </Typography.Body>
              </Card>
            </Col>
          ))}
      </>
    </Row>
  );

  const handleNotificationError = () => {
    notification.error({
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: t('PLANNING_PXQ_DESCRIPTION_ENABLE_NOTIFICATION_ERROR'),
      duration: 0,
    });
  };

  const onCreateAndEnablePXQ = () => {
    setIsEnabling(true);
    createPXQ()
      .then(() => {
        fetchPXQ().then(response => {
          enablePXQ(response.payload.data.results[0].id, {
            projection: budgetId,
          })
            .then(() => {
              setIsEnabling(false);
            })
            .catch(() => {
              handleNotificationError();
              setIsEnabling(false);
            });
        });
      })
      .catch(() => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('PLANNING_PXQ_DESCRIPTION_CREATE_NOTIFICATION_ERROR'),
          duration: 0,
        });
        setIsEnabling(false);
      });
  };

  const onEnablePXQ = () => {
    setIsEnabling(true);
    enablePXQ(pxq[0].id, { projection: budgetId })
      .then(() => {
        fetchPXQ().then(() => setIsEnabling(false));
      })
      .catch(() => {
        handleNotificationError();
        setIsEnabling(false);
      });
  };

  const handleCellEditPXQ = data => {
    message.loading({
      key: 'loading_edit',
      content: t('FEEDBACK_LOADING_CHANGES'),
    });
    const { transaction_id, ...restData } = data;
    updateCellPXQ(pxq[0].id, { transaction_id }, { ...restData })
      .then(response => {
        setDataUpdateCellPXQ(response.payload.data);
        message.destroy('loading_edit');
        message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
      })
      .catch(() => handleMessageError());
  };

  const renderTableActions = () => {
    const auxValue =
      dataUndoCellEditAll.length > 0
        ? dataUndoCellEditAll[dataUndoCellEditAll.length - 1].value * 100
        : 0;
    return (
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Space>
            <ButtonsSwitchDataType
              tableTypeKey={tableTypeKey}
              onChange={value => {
                setTableTypeKey(value.target.value);
                if (pxq.length === 0) {
                  onCreateAndEnablePXQ();
                } else if (
                  pxq[0].projections.length === 0 ||
                  (pxq[0].projections.length > 0 &&
                    !pxq[0].projections.includes(parseInt(budgetId)))
                ) {
                  onEnablePXQ();
                }
              }}
            />
            <References
              referencesItems={[
                {
                  color: '#0047ba',
                  type: 'Ab',
                  title: t('LABEL_VARIABLES'),
                  description: t('PLANNING_VALUES_VARS_APPLIED'),
                },
                ...(functionalCurrencyData
                  ? [
                      {
                        type: <DollarOutlined />,
                        title:
                          functionalCurrencyData && functionalCurrencyData.code,
                        description:
                          functionalCurrencyData &&
                          t('REFERENCE_DESCRIPTION', {
                            currencyName: t(
                              functionalCurrencyData.name
                            ).toLowerCase(),
                          }),
                      },
                    ]
                  : []),
              ]}
            />
            <ShowCommentsButton onClick={toggleShowRowsComment} />
            {dataSelection.length > 0 && (
              <DeleteSelectionButton
                onConfirm={handleDeleteSelection}
                disabled={!canUserDelete}
                isDeletingAll={
                  dataSelection.length === tablePagination.page_size ||
                  dataSelection.length === projectionRows.count
                }
              />
            )}
            {/* NOTE: ISAMOUNT PROVISORIO SOLO PARA VISTA IMPORTE HASTA QUE FUNCIONE EN PXQ */}
            {isTableEditable && isAmount(tableTypeKey) && (
              <>
                <InputNumberPercentage
                  value={auxValue}
                  disabled={!isTableEditable}
                  onPressEnter={value => {
                    handleCellEditAll({ value });
                    setDataUndoCellEditAll(prevState => {
                      return [...prevState, { value, qp: { ...filters } }];
                    });
                  }}
                />
                {dataUndoCellEditAll.length > 0 && (
                  <Button
                    type="text"
                    shape="circle"
                    icon={<Typography.Icon icon={UndoOutlined} level={1} />}
                    onClick={() => handleUndoCellEditAll()}
                    title={t('ACTION_UNDO')}
                  />
                )}
              </>
            )}
          </Space>
        </Col>
        <Col span={12}>
          <ButtonActionBar>
            <Button
              type="text"
              shape="circle"
              icon={<Typography.Icon icon={DownloadOutlined} />}
              onClick={() =>
                downloadProjectionXLS(
                  budgetId,
                  t('PLANNING_EVOLUTION_TABLE_FILE_NAME', {
                    concept: projection.name,
                    periodName: projection.period.name,
                    loadTypeName: `(${t('FIELD_AMOUNT')})`,
                  }),
                  { filter: PROJECTION.LOAD_TYPES.CONVENTIONAL.id, ...filters }
                )
              }
              title={t('ACTION_DOWNLOAD_TABLE')}
            />
            <TableSettingsModal
              onConfirm={setTableSettings}
              options={projectionColumns.filter(
                (column, index) =>
                  !column.is_transaction &&
                  index !== COLUMNS.FIRST_INDEX &&
                  column.data_index !== COLUMNS.TOTAL_DATA_INDEX
              )}
              settings={tableSettings}
              formItems={formItemsHelper.itemsDataView(
                tableSettings.negativeValuesType,
                tableSettings.showNegativeInRed
              )}
            />
          </ButtonActionBar>
        </Col>
      </Row>
    );
  };

  const handleOnFilter = newFilters => {
    setFilters(newFilters);
    setTablePagination({ current: 1 });
    setRefetchFilters(true);
  };

  const renderTable = () => (
    <PlanningTable
      columns={projectionColumns}
      columnsImporte={projectionColumnsImporte}
      dataSource={
        isAmount(tableTypeKey)
          ? projectionRows.transactions
          : projectionRows.transactions.map(transaction => {
              return { ...transaction, children: null };
            })
      }
      loading={isTableLoading || isDeletingSelection || isEnabling}
      editable={isTableEditable}
      canDelete={canUserDelete && isProjectionStatusEditable}
      onCalculateValue={handleOnCalculateValue}
      onCellEdit={handleCellEdit}
      onCellEditForward={handleCellEditForward}
      onChange={handleOnChangeTable}
      pagination={{
        pageSize: tablePagination.page_size + totalizerRows,
        current: tablePagination.page,
        total:
          projectionRows.count > 0
            ? projectionRows.count + totalizerRows
            : projectionRows.count,
      }}
      dataSelection={{
        selection: dataSelection,
        setSelection: data => setDataSelection(data),
      }}
      tableSettings={tableSettings}
      filters={filters}
      filterOptions={filterTableOptions}
      onFilter={handleOnFilter}
      comments={{
        showRowsComment,
        onComment: onCommentCell,
        onDeleteComment: onDeleteCommentCell,
      }}
      isPublishedOrElaboration={isProjectionStatusEditable}
      tableTypeKey={tableTypeKey}
    />
  );

  return (
    <Spin spinning={isScreenLoading}>
      {!isScreenLoading &&
        !isTableLoading &&
        !projection.has_conventional_data &&
        renderNoDataScreen()}
      {!isScreenLoading && projection.has_conventional_data && (
        <Row gutter={[8, 24]}>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>{renderKPIs()}</Col>
          <Col span={24}>
            {renderTableActions()}
            {renderTable()}
          </Col>
        </Row>
      )}
      <ProjectionUploadModal
        title={t('PLANNING_UPLOAD_MODAL_TITLE')}
        visible={showUploadModal}
        extraBody={optionsLoadTypes()}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={fileList =>
          uploadProjectionXLS(
            budgetId,
            fileList,
            isOptionLaodFileFlat && { file_format: optionLoadFile }
          )
        }
        onSuccessUpload={onSuccessUploadFile}
        onFailUpload={onFailUploadFile}
      />
    </Spin>
  );
};

const mapStateToProps = state => ({
  projection: planning.selectors.getProjectionDetail(state),
  projectionColumns: planning.selectors.getProjectionColumns(state),
  projectionRows: planning.selectors.getProjectionAmountsRows(state),
  projectionKpis: planning.selectors.getProjectionAmountsKpis(state),
  filterTableOptions: planning.selectors.getFilterTableOptions(state),
  projectionColumnsImporte:
    planning.selectors.getProjectionsColumnsImporte(state),
  pxq: planning.selectors.getPXQ(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
  fetchProjectionColumns: planning.actions.fetchProjectionColumns,
  fetchProjectionRows: planning.actions.fetchProjectionAmountsRows,
  fetchProjectionKpis: planning.actions.fetchProjectionAmountsKpis,
  fetchProjectionFilters: planning.actions.fetchProjectionFilters,
  downloadProjectionXLS: planning.actions.downloadProjectionXLS,
  updateTransaction: planning.actions.updateTransaction,
  updateTransactionForward: planning.actions.updateTransactionForward,
  updateTransactionAll: planning.actions.updateTransactionAll,
  deleteBudgetRow: planning.actions.deleteBudgetRow,
  deleteAllProjectionRows: planning.actions.deleteAllProjectionRows,
  downloadTemplateXLS: planning.actions.downloadTemplateXLS,
  uploadProjectionXLS: planning.actions.uploadProjectionXLS,
  syncProjectionComment: planning.actions.syncProjectionComment,
  createTransactionComment: planning.actions.createTransactionComment,
  deleteTransactionComment: planning.actions.deleteTransactionComment,
  editTransactionOriginCurrency: planning.actions.editTransactionOriginCurrency,
  createTransactionOriginCurrency:
    planning.actions.createTransactionOriginCurrency,
  fetchOriginRows: planning.actions.fetchOriginRows,
  updateTransactionForwardOrigin:
    planning.actions.updateTransactionForwardOrigin,
  fetchPXQ: planning.actions.fetchPXQ,
  enablePXQ: planning.actions.enablePXQ,
  updateCellPXQ: planning.actions.updateCellPXQ,
  createPXQ: planning.actions.createPXQ,
  setDataUpdateCellPXQ: planning.actions.setDataUpdateCellPXQ,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningAmountDetail);
