import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  notification,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import { ButtonActionBar, AddAndDeleteFormItem } from 'modules/core/components';
import { handleSelectAll, generateSelectOptions } from 'modules/core/utils';
import { LeftCircleOutlined } from '@ant-design/icons';
import { DIMENSIONS, PROJECTION } from 'modules/core/constants';

const FormItem = Form.Item;

const INITIAL_STATE_SELECTION = { accounts: true, dimensions: false };

const CreateFormulaModal = ({
  visible,
  onCancel,
  dimensionsList: { dimensions },
  accountsList,
  optionsBehaviour,
  createFormulaFactor,
  refresh,
  areValuesLoading,
}) => {
  const [formFormula] = Form.useForm();
  const [formFactor] = Form.useForm();
  const [isNext, setIsNext] = useState(false);
  const [dataCreateFormula, setDataCreateFormula] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(INITIAL_STATE_SELECTION);
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      formFormula.setFieldsValue({
        account: accountsList.values.asMutable().map(account => account.id),
      });
    }
  }, [accountsList, formFormula, visible]);

  const onClose = () => {
    formFormula.resetFields();
    formFactor.resetFields();
    setIsNext(false);
    setAreAllSelected(INITIAL_STATE_SELECTION);
    onCancel();
  };

  const handleCreate = (dataFormula, dataFactor = []) => {
    const infoDimensions = {};
    dataFormula.dimensions &&
      dataFormula.dimensions.forEach(id =>
        Object.assign(infoDimensions, { [`${id}`]: [] })
      );

    const data = {
      name: dataFormula.name,
      dimensions: {
        [DIMENSIONS.ACCOUNTS.id]: !areAllSelected.accounts
          ? dataFormula.account
          : [],
        ...infoDimensions,
      },
      factores: dataFactor,
      type: dataFormula.type,
    };

    setIsCreating(true);
    createFormulaFactor(data)
      .then(() => {
        refresh();
        setIsCreating(false);
        setDataCreateFormula({});
        notification.success({
          message: t('LABEL_BUSINESS_RULE'),
          description: t('CONFIG_FACTOR_CREATE_FEEDBACK_SUCCESS'),
        });
        onClose();
      })
      .catch(() => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_CREATION_FAIL'),
          duration: 0,
        });
        setIsCreating(false);
        setDataCreateFormula({});
        onClose();
      });
  };

  const handleValidateFormula = () => {
    formFormula
      .validateFields()
      .then(values => {
        handleCreate(values);
      })
      .catch(e => {});
  };

  const handleValidateFormulaIsNext = () => {
    formFormula
      .validateFields()
      .then(values => {
        setDataCreateFormula(values);
        setIsNext(true);
      })
      .catch(e => {});
  };

  const handleValidateFactor = () => {
    formFactor
      .validateFields()
      .then(values => {
        handleCreate(dataCreateFormula, values.factores);
      })
      .catch(e => {});
  };

  const renderFooterButtons = ({ button1, button2, button3 }) => {
    return (
      <>
        <Button
          type="link"
          icon={button1.icon && button1.icon}
          onClick={button1.onClick}
          disabled={isCreating}
        >
          {t(button1.name)}
        </Button>
        <Space direction="horizontal">
          <Button disabled={isCreating} onClick={button2.onClick}>
            {t(button2.name)}
          </Button>
          <Button type="primary" loading={isCreating} onClick={button3.onClick}>
            {t(button3.name)}
          </Button>
        </Space>
      </>
    );
  };

  return (
    <Modal
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      forceRender
      onCancel={onClose}
      visible={visible}
      title={
        !isNext
          ? t('CONFIG_FORMULAS_CREATE_ACTION')
          : t('CONFIG_FORMULAS_ADD_FACTORS', {
              name: formFormula.getFieldValue('name'),
            })
      }
      footer={
        <>
          <ButtonActionBar position="space-between">
            {!isNext &&
              renderFooterButtons({
                button1: {
                  name: 'ACTION_CANCEL',
                  onClick: () => onClose(),
                  icon: null,
                },
                button2: {
                  name: 'CONFIG_FORMULAS_SAVE_ACTION',
                  onClick: () => handleValidateFormula(),
                },
                button3: {
                  name: 'CONFIG_FACTOR_CREATE_ACTION',
                  onClick: () => handleValidateFormulaIsNext(),
                },
              })}
            {isNext &&
              renderFooterButtons({
                button1: {
                  name: 'ACTION_RETURN',
                  onClick: () => setIsNext(false),
                  icon: <LeftCircleOutlined />,
                },
                button2: {
                  name: 'ACTION_CANCEL',
                  onClick: () => onClose(),
                },
                button3: {
                  name: 'ACTION_CREATE',
                  onClick: () => handleValidateFactor(),
                },
              })}
          </ButtonActionBar>
        </>
      }
    >
      {!isNext && (
        <Form form={formFormula} layout="vertical" hideRequiredMark={false}>
          <Row gutter={24}>
            <Col span={24}>
              <FormItem
                name="name"
                label={t('FIELD_NAME')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Input
                  placeholder={t('CONFIG_FORMULAS_CREATE_MODAL_PLACEHOLDER')}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name="account"
                label={t('FIELD_ACCOUNTS_IMPACTED')}
                rules={[
                  {
                    required: areAllSelected.accounts ? false : true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  showArrow
                  placeholder={t('ACTION_SELECT')}
                  loading={areValuesLoading}
                  allowClear
                  showSearch
                  onChange={values =>
                    handleSelectAll({
                      values,
                      allValues: accountsList.values
                        .asMutable()
                        .map(account => account.id),
                      areAllSelected: areAllSelected.accounts,
                      onSetValues: values =>
                        formFormula.setFieldsValue({ account: values }),
                      onSetAreAllSelected: state =>
                        setAreAllSelected(prevState => {
                          return { ...prevState, accounts: state };
                        }),
                    })
                  }
                  style={{ width: 510 }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={generateSelectOptions({
                    options: accountsList.values,
                    includeOptionAll: true,
                  })}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name="dimensions"
                label={t('FIELD_DIMENSION_DIMENSIONS')}
              >
                <Select
                  mode="multiple"
                  maxTagCount={2}
                  allowClear
                  showArrow
                  showSearch
                  onChange={values =>
                    handleSelectAll({
                      values,
                      allValues: dimensions.asMutable().map(dim => dim.id),
                      areAllSelected: areAllSelected.dimensions,
                      onSetValues: values =>
                        formFormula.setFieldsValue({ dimensions: values }),
                      onSetAreAllSelected: state =>
                        setAreAllSelected(prevState => {
                          return { ...prevState, dimensions: state };
                        }),
                    })
                  }
                  placeholder={t('ACTION_SELECT')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={generateSelectOptions({
                    options: dimensions,
                    includeOptionAll: true,
                  })}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
      {isNext && (
        <Form
          form={formFactor}
          layout="vertical"
          initialValues={{ factores: [''] }}
        >
          <Form.List name="factores">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Row gutter={24} key={field.key}>
                      <Col span={12}>
                        <FormItem
                          name={[field.name, 'name']}
                          label={[field.label, t('FIELD_NAME')]}
                          rules={[
                            { required: true, message: t('REQUIRED_FIELD') },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_FACTOR_CREATE_MODAL_PLACEHOLDER'
                            )}
                          />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          name={[field.name, 'type']}
                          label={[field.label, t('FIELD_TYPE')]}
                          rules={[
                            { required: true, message: t('REQUIRED_FIELD') },
                          ]}
                        >
                          <Select
                            placeholder={t('ACTION_SELECT')}
                            options={generateSelectOptions({
                              options: PROJECTION.FACTORS_TYPE_OPTIONS.map(
                                factor => {
                                  return { ...factor, name: t(factor.name) };
                                }
                              ),
                            })}
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          name={[field.name, 'behaviour']}
                          label={[field.label, t('LABEL_FORMAT')]}
                          rules={[
                            { required: true, message: t('REQUIRED_FIELD') },
                          ]}
                        >
                          <Select
                            placeholder={t('ACTION_SELECT')}
                            options={generateSelectOptions({
                              options: optionsBehaviour,
                            })}
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <AddAndDeleteFormItem
                          fieldsLength={fields.length}
                          index={index}
                          disabled={isCreating}
                          addData={{
                            onClick: () => add(),
                            buttonName: 'CONFIG_FACTOR_ADD_NEW_ACTION',
                          }}
                          deleteData={{
                            onClick: () => remove(field.name),
                            buttonName: 'CONFIG_FACTOR_DELETE_ACTION',
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </Form>
      )}
    </Modal>
  );
};

const mapDispatchToProps = {
  createFormulaFactor: configuration.actions.createFormulaFactor,
};

export default connect(null, mapDispatchToProps)(CreateFormulaModal);
