import React from 'react';
import { CHARTS } from 'modules/core/constants';
import { ResponsiveLine } from '@nivo/line';
import ChartContainer from '../ChartContainer/ChartContainer';
import numeral from 'numeral';

const LineChart = ({
  className = '',
  data = [],
  emptyMessage = '',
  containerHeight,
  margin = {},
  value,
  prefix,
  ...props
}) => (
  <ChartContainer className={className} style={{ height: containerHeight }}>
    {data.length <= 0 && emptyMessage}
    {data.length > 0 && (
      <ResponsiveLine
        data={data}
        margin={{ top: 15, right: 170, bottom: 40, left: 70, ...margin }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          reverse: false,
        }}
        yFormat={value => `${prefix} ${numeral(value).format('0,0[.]0 a')}`}
        colors={CHARTS.COLORS}
        curve="natural"
        pointSize={10}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 110,
            translateY: -20,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 10,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            toggleSerie: true,
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        {...props}
      />
    )}
  </ChartContainer>
);

export default LineChart;
