import { Tooltip, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography } from 'modules/core/components';
import { PROJECTION } from 'modules/core/constants';
import i18n from 'i18next';

const { OptGroup, Option } = Select;

export const renderInformativeIcon = dataBudget => {
  return (
    <Tooltip
      placement="topLeft"
      title={i18n.t('POPOVER_NOT_CONSOLIDATED_FIXED', {
        type: !dataBudget
          ? i18n.t('LABEL_BUDGET').toLowerCase()
          : i18n.t('LABEL_FORECAST').toLowerCase(),
      })}
    >
      <Typography.Icon icon={ExclamationCircleOutlined} type="warning" />
    </Tooltip>
  );
};

const renderOptionSelect = ({
  label,
  consoList,
  parentId,
  disabled = false,
}) => {
  return (
    <OptGroup label={label}>
      {consoList.map(conso => (
        <Option
          key={`${parentId}-${conso.id}`}
          title={conso.name}
          disabled={disabled}
        >
          <Tooltip
            placement="right"
            title={
              disabled
                ? i18n.t('REPORT_FINANCIAL_NO_DATA_INFORMATIVE_TOOLTIP_TITLE')
                : ''
            }
          >
            {conso.name}
          </Tooltip>
        </Option>
      ))}
    </OptGroup>
  );
};

export const renderBudgetSelect = consoBudgetList => {
  return renderOptionSelect({
    label: i18n.t('LABEL_BUDGET'),
    consoList: consoBudgetList,
    parentId: PROJECTION.BUDGET,
  });
};

export const renderForecastSelect = ({
  consoForecastList,
  disabled = false,
}) => {
  return renderOptionSelect({
    label: i18n.t('LAST_FORECAST'),
    consoList: consoForecastList,
    parentId: PROJECTION.LAST_FORECAST,
    disabled,
  });
};
