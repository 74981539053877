import React from 'react';
import { Avatar, Space, Typography } from 'antd';

import './UserAvatar.scss';

const UserAvatar = ({
  avatarLetter = '',
  fullname = '',
  size = 'small',
  isItalic = false,
}) => (
  <Space className="configuration-user-avatar">
    <Avatar className="user-avatar" size={size}>
      {avatarLetter.toUpperCase()}
    </Avatar>
    <Typography.Text ellipsis title={fullname}>
      {!isItalic ? fullname : <i> {fullname} </i>}
    </Typography.Text>
  </Space>
);

export default UserAvatar;
