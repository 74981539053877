import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, List, Button, Tooltip, Space } from 'antd';
import { SolutionOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { isUserAllowed } from 'modules/core/utils';
import {
  ListCard,
  ButtonActionBar,
  Typography,
  ScreenWellDone,
  StatusTag,
  ActionModal,
  PublishedOrApprovedDateCalendar,
} from 'modules/core/components';
import { ROLES, PROJECTION, CONCEPTS } from 'modules/core/constants';
import control from 'modules/control';

const {
  // CONTROL__REAL__CONSOLIDATED__PUBLISH,
  CONTROL__REAL__STATUS_CHANGE__APPROVE,
  CONTROL__REAL__STATUS_CHANGE__PUBLISH,
  // CONTROL__REAL__EXPENSES__VIEW,
  // CONTROL__REAL__SALES__VIEW,
} = ROLES;

//NOTE: comentado hasta que se puedan agregar los roles
// const CONTROL__REAL__CONCEPT__VIEW = {
//   [CONCEPTS.KEYS.SALES]: CONTROL__REAL__SALES__VIEW,
//   [CONCEPTS.KEYS.EXPENSES]: CONTROL__REAL__EXPENSES__VIEW,
// };

const RealList = ({
  fetchMonthsToPublish,
  fetchMonthsToApprove,
  monthsToPublish,
  monthsToApprove,
  publishMonth,
  approveMonth,
  onFetchProjections,
  dataSource,
}) => {
  const [showScreenSuccess, setShowScreenSuccess] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [modalActionType, setModalActionType] = useState(null);
  const [date, setDate] = useState(null);
  const { t } = useTranslation();
  let history = useHistory();
  let { periodId } = useParams();

  const isActionPublish = modalActionType === PROJECTION.STATUS.PUBLISHED;
  const userCanPublish = isUserAllowed(CONTROL__REAL__STATUS_CHANGE__PUBLISH);
  const userCanApprove = isUserAllowed(CONTROL__REAL__STATUS_CHANGE__APPROVE);

  useEffect(() => {
    Promise.all([
      fetchMonthsToPublish(periodId, {
        concept_type: CONCEPTS.TYPE.FINANCIAL_KEY,
      }),
      fetchMonthsToApprove(periodId, {
        concept_type: CONCEPTS.TYPE.FINANCIAL_KEY,
      }),
    ]);
  }, [fetchMonthsToPublish, fetchMonthsToApprove, periodId, showScreenSuccess]);

  let lastPublishedDate = dataSource.map(p => p.last_publish_date);

  let lastApprovedDate = dataSource.map(a => a.approve_date);

  let lastStatus = dataSource.map(s => s.status);

  const renderTopActions = () => (
    <>
      {lastPublishedDate[0] !== null && (
        <PublishedOrApprovedDateCalendar
          value={
            (lastApprovedDate[0] ? lastApprovedDate[0] : null) ||
            (lastPublishedDate[0] !== null ? lastPublishedDate[0] : null)
          }
          status={lastStatus[0]}
        />
      )}
      <ButtonActionBar>
        <>
          <Tooltip
            title={
              monthsToPublish.length === 0 &&
              !dataSource.every(
                data =>
                  moment(data.last_publish_date).format('YYYY-MM-01') ===
                  moment(data.period.end_date).format('YYYY-MM-01')
              )
                ? t('CONTROL_MODAL_NOT_LOADED_DATA_TOOLTIP_TITLE')
                : monthsToPublish.length === 0 &&
                  dataSource.every(
                    data =>
                      moment(data.last_publish_date).format('YYYY-MM-01') ===
                      moment(data.period.end_date).format('YYYY-MM-01')
                  )
                ? t('CONTROL_ALL_PUBLISH_DATES_TOOLTIP_TITLE')
                : ''
            }
          >
            <Button
              type="primary"
              ghost
              onClick={() => {
                setModalActionType(PROJECTION.STATUS.PUBLISHED);
                setShowActionModal(true);
              }}
              disabled={
                monthsToPublish.length === 0 ||
                dataSource.every(
                  data =>
                    moment(data.last_publish_date).format('YYYY-MM-01') ===
                    moment(data.period.end_date).format('YYYY-MM-01')
                ) ||
                !userCanPublish
              }
            >
              {t('CONTROL_MODAL_ACTION_DATA_TITLE', {
                action: t('ACTION_PUBLISH'),
              })}
            </Button>
          </Tooltip>
          <Tooltip
            title={
              monthsToApprove.length === 0
                ? t('CONTROL_MODAL_NOT_PUBLISHED_DATA_TOOLTIP_TITLE')
                : monthsToApprove.length === 0 ||
                  dataSource.every(
                    data =>
                      moment(data.approve_date).format('YYYY-MM-01') ===
                      moment(data.period.end_date).format('YYYY-MM-01')
                  )
                ? t('CONTROL_ALL_APPROVE_DATES_TOOLTIP_TITLE')
                : ''
            }
          >
            <Button
              type="primary"
              onClick={() => {
                setModalActionType(PROJECTION.STATUS.APPROVED);
                setShowActionModal(true);
              }}
              disabled={
                monthsToApprove.length === 0 ||
                dataSource.every(
                  data =>
                    moment(data.approve_date).format('YYYY-MM-01') ===
                    moment(data.period.end_date).format('YYYY-MM-01')
                ) ||
                !userCanApprove
              }
            >
              {t('CONTROL_MODAL_ACTION_DATA_TITLE', {
                action: t('ACTION_APPROVE'),
              })}
            </Button>
          </Tooltip>
        </>
      </ButtonActionBar>
    </>
  );

  return (
    <>
      {!showScreenSuccess && (
        <>
          <Row gutter={[24, 24]}>
            <Col span={24}>{renderTopActions()}</Col>
            <Col span={24}>
              <List
                grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
                dataSource={dataSource}
                renderItem={tracing => {
                  const {
                    id,
                    period: { id: periodId },
                    name,
                    concept,
                    status,
                  } = tracing;
                  return (
                    <List.Item>
                      <ListCard
                        title={name}
                        type={concept.name}
                        projectionId={periodId}
                        extra={
                          <Space align="center">
                            <StatusTag status={status} />
                            <Button
                              type="text"
                              title={t('ACTION_ACTIVITIES')}
                              onClick={() =>
                                history.push(
                                  `/seguimiento/${periodId}/financiero/${name.toLowerCase()}/actividad/${id}`
                                )
                              }
                              icon={<Typography.Icon icon={SolutionOutlined} />}
                              shape="circle"
                            />
                          </Space>
                        }
                        actions={[
                          <ButtonActionBar position="start">
                            <Button
                              type="link"
                              onClick={() =>
                                history.push(
                                  `/seguimiento/${periodId}/financiero/${name.toLowerCase()}/${id}`
                                )
                              }
                            >
                              {t('ACTION_ENTER')}
                              <RightCircleOutlined />
                            </Button>
                          </ButtonActionBar>,
                        ]}
                        emptyState={{
                          description: t(
                            'CONTROL_CARD_EMPTY_STATE_DESCRIPTION',
                            {
                              conceptName: t(concept.name).toLowerCase(),
                            }
                          ),
                        }}
                      />
                    </List.Item>
                  );
                }}
              />
            </Col>
          </Row>
          <ActionModal
            visible={showActionModal}
            isActionPublish={isActionPublish}
            availableDate={isActionPublish ? monthsToPublish : monthsToApprove}
            onConfirm={() => setShowScreenSuccess(true)}
            onClose={() => setShowActionModal(false)}
            onAction={isActionPublish ? publishMonth : approveMonth}
            onFetchProjections={onFetchProjections}
            onSetDate={setDate}
            date={date}
            periodId={periodId}
            conceptType={CONCEPTS.TYPE.FINANCIAL_KEY}
          />
        </>
      )}
      {showScreenSuccess && (
        <ScreenWellDone
          title={t('FEEDBACK_WELLDONE')}
          description={t(
            'CONTROL_CONCEPT_ACTION_SUCCESS_FEEDBACK_DESCRIPTION',
            {
              action: isActionPublish
                ? t('ACTION_PUBLISH').toLowerCase()
                : t('ACTION_APPROVE').toLowerCase(),
              endDate: `${moment(date).format('MMMM YYYY')}`,
              concept1: `${t('LABEL_OPERATING_INCOME').toLowerCase()}, ${t(
                'LABEL_EXTRAORDINARY_INCOME'
              ).toLowerCase()}`,
              concept2: `${t('LABEL_OPERATING_EXPENSES').toLowerCase()}, ${t(
                'LABEL_EXTRAORDINARY_EXPENSES'
              ).toLowerCase()}`,
            }
          )}
          onConfirm={() => {
            setDate(null);
            setShowScreenSuccess(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  monthsToPublish: control.selectors.getMonthsToPublish(state),
  monthsToApprove: control.selectors.getMonthsToApprove(state),
});

const mapDispatchToProps = {
  fetchMonthsToPublish: control.actions.fetchMonthsToPublish,
  fetchMonthsToApprove: control.actions.fetchMonthsToApprove,
  publishMonth: control.actions.publishMonth,
  approveMonth: control.actions.approveMonth,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealList);
