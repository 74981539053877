import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  List,
  Card,
  Tooltip,
  Button,
  Popconfirm,
  message,
  notification,
  Input,
  Spin,
  Typography as TypographyAnt,
  Modal,
  Space,
} from 'antd';
import {
  InfoCircleOutlined,
  RightCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  RangePicker,
  DropdownMenu,
  ButtonActionBar,
  Typography,
} from 'modules/core/components';
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import planning from 'modules/planning';

const { Text } = TypographyAnt;
const {
  PLANNING,
  PLANNING__EXPENSES__CREATE,
  PLANNING__SALES__CREATE,
  PLANNING__EXPENSES__EDIT,
  PLANNING__SALES__EDIT,
  PLANNING__EXPENSES__DELETE,
  PLANNING__SALES__DELETE,
  CONTROL,
  FORECAST,
  ANALYSIS,
} = ROLES;

const PeriodList = ({
  dataSource,
  onFetchPeriods,
  deletePeriod,
  editPeriod,
  disabled = false,
}) => {
  const [editPeriodData, setEditPeriodData] = useState({
    id: null,
    name: null,
  });
  const [editPeriodDate, setEditPeriodDate] = useState({
    id: null,
    date: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletingPeriod, setIsDeletingPeriod] = useState(false);
  const [deletePeriodId, setDeletePeriodId] = useState(null);
  const { t } = useTranslation();
  let history = useHistory();

  const canUserEdit = isUserAllowed([
    PLANNING__EXPENSES__CREATE,
    PLANNING__SALES__CREATE,
    PLANNING__EXPENSES__EDIT,
    PLANNING__SALES__EDIT,
  ]);

  const canuserDelete = isUserAllowed([
    PLANNING__EXPENSES__DELETE,
    PLANNING__SALES__DELETE,
  ]);

  const renderModalConfirmHardDelete = () =>
    Modal.confirm({
      title: t('FIELD_IMPORTANT'),
      icon: <InfoCircleOutlined style={{ color: 'blue' }} />,
      centered: true,
      content: (
        <Space direction="vertical">
          <span>{t('PERIOD_DELETE_WITH_DATA_MODAL_CONFIRM_TEXT')}</span>
          <span>{t('MODAL_DELETE_CONFIRM_TEXT')}</span>
        </Space>
      ),
      okText: t('ACTION_CONFIRM'),
      onOk: () => onDeletePeriod(true),
      cancelText: t('ACTION_RETURN'),
      onCancel: () => onResetData(),
    });

  const onResetData = () => {
    setDeletePeriodId(null);
    setIsDeletingPeriod(false);
  };

  const onDeletePeriod = (hardDelete = false) => {
    const endpointParams = {
      ...(hardDelete && {
        hard_delete: hardDelete,
      }),
    };
    setIsDeletingPeriod(true);
    deletePeriod(deletePeriodId, endpointParams)
      .then(() => {
        onFetchPeriods();
        message.success(t('PERIOD_DELETE_SUCCESS_FEEDBACK'));
        onResetData();
      })
      .catch(error => {
        error.response.status === 400
          ? renderModalConfirmHardDelete()
          : notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: error.response.data,
              duration: 0,
            });
        onResetData();
      });
  };

  const onEditPeriod = value => {
    if (value !== editPeriodData.name) {
      setIsEditing(true);
      editPeriod(editPeriodData.id, { name: value })
        .then(() => {
          onFetchPeriods();
          message.success(t('PERIOD_EDIT_NAME_SUCCES_FEEDBACK'));
          setIsEditing(false);
          setEditPeriodData({ id: null, name: null });
        })
        .catch(error => {
          setIsEditing(false);
          setEditPeriodData({ id: null, name: null });
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: error.response.data[0],
            duration: 0,
          });
        });
    } else setEditPeriodData({ id: null, name: null });
  };

  const handleDatesEdit = () => {
    const data = {
      start_date: moment(editPeriodDate.date[0]).format('YYYY-MM-01'),
      end_date: moment(editPeriodDate.date[1]).format('YYYY-MM-01'),
    };
    setIsEditing(true);
    editPeriod(editPeriodDate.id, data)
      .then(() => {
        onFetchPeriods();
        message.success(t('PERIOD_EDIT_DATE_SUCCES_FEEDBACK'));
        setIsEditing(false);
        setEditPeriodDate({ id: null, date: [] });
      })
      .catch(error => {
        setIsEditing(false);
        setEditPeriodDate({ id: null, date: [] });
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error.response.data[0],
          duration: 0,
        });
      });
  };

  const onEnterPeriod = periodId => {
    if (isUserAllowed(getOperationsByKeys([PLANNING]))) {
      return history.push(`/planificacion/${periodId}`);
    } else if (isUserAllowed(getOperationsByKeys([CONTROL]))) {
      return history.push(`/seguimiento/${periodId}`);
    } else if (isUserAllowed(getOperationsByKeys([FORECAST]))) {
      return history.push(`/forecast/${periodId}`);
    } else if (isUserAllowed(getOperationsByKeys([ANALYSIS]))) {
      return history.push(`/analisis/${periodId}`);
    } else {
      return null;
    }
  };

  return (
    <List
      className="period-list"
      grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4 }}
      dataSource={dataSource}
      renderItem={period => {
        const { id, name, user_full_name, start_date, end_date, has_data } =
          period;
        return (
          <List.Item>
            <Card
              className="period-card"
              title={
                <Spin spinning={isEditing && editPeriodData.id === id}>
                  {editPeriodData.id === id ? (
                    <Input
                      autoFocus
                      disabled={isEditing}
                      defaultValue={name}
                      onBlur={e => onEditPeriod(e.target.value)}
                      onPressEnter={e => onEditPeriod(e.target.value)}
                    />
                  ) : (
                    <Text title={name} style={{ width: 250 }} ellipsis={true}>
                      {name}
                    </Text>
                  )}
                </Spin>
              }
              headStyle={{ height: 56 }}
              bodyStyle={{ height: 126 }}
              extra={
                <Popconfirm
                  placement="bottomRight"
                  title={t('PERIOD_DELETE_CONFIRM_TEXT')}
                  okText={t('ACTION_DELETE')}
                  cancelText={t('ACTION_CANCEL')}
                  okButtonProps={{ loading: isDeletingPeriod }}
                  onConfirm={() => onDeletePeriod()}
                  onCancel={() => setDeletePeriodId(null)}
                  visible={deletePeriodId === id}
                >
                  <ButtonActionBar>
                    <Tooltip
                      title={t('FIELD_CREATOR_INFO', {
                        username: user_full_name,
                      })}
                    >
                      <Typography.Icon
                        icon={InfoCircleOutlined}
                        style={{ marginLeft: 5 }}
                      />
                    </Tooltip>
                    <DropdownMenu
                      title={t('ACTION_MORE')}
                      menu={[
                        {
                          title: t('ACTION_EDIT_NAME'),
                          icon: <EditOutlined />,
                          disabled: !canUserEdit,
                          onClick: () =>
                            setEditPeriodData({ id: id, name: name }),
                        },
                        {
                          title: t('ACTION_DELETE'),
                          icon: <DeleteOutlined />,
                          disabled: !canuserDelete,
                          onClick: () => setDeletePeriodId(id),
                        },
                      ]}
                    />
                  </ButtonActionBar>
                </Popconfirm>
              }
              actions={[
                <ButtonActionBar position="start">
                  <Tooltip
                    title={
                      disabled ? t('PERIOD_CONFIG_TOOL_TOOLTIP_TITLE') : ''
                    }
                  >
                    <Button
                      type="link"
                      onClick={() => onEnterPeriod(id)}
                      disabled={disabled}
                    >
                      {t('ACTION_ENTER')}
                      <RightCircleOutlined />
                    </Button>
                  </Tooltip>
                </ButtonActionBar>,
              ]}
            >
              <Typography.Body level={2}>
                {t('PERIOD_DATES_LABEL')}
              </Typography.Body>
              <Spin spinning={isEditing && editPeriodDate.id === id}>
                <RangePicker
                  className={`period-card-date-picker`}
                  style={
                    editPeriodDate.id === id
                      ? { marginBottom: 5, borderColor: '#2065c7' }
                      : {}
                  }
                  disabled={has_data} //TODO: CHEQUEAR QUE FUNCIONE CUANDO BACK CORRIJA
                  allowClear={false}
                  allowEmpty={false}
                  format="MMMM YYYY"
                  onCalendarChange={val =>
                    setEditPeriodDate({ id: id, date: val })
                  }
                  value={
                    editPeriodDate.id === id
                      ? editPeriodDate.date
                      : [
                          moment(start_date, 'YYYY-MM-01'),
                          moment(end_date, 'YYYY-MM-01'),
                        ]
                  }
                />
                {editPeriodDate.id === id && (
                  <ButtonActionBar>
                    <Button
                      size="small"
                      onClick={() =>
                        setEditPeriodDate({
                          id: null,
                          date: [],
                        })
                      }
                    >
                      {t('ACTION_CANCEL')}
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={handleDatesEdit}
                    >
                      {t('ACTION_SAVE')}
                    </Button>
                  </ButtonActionBar>
                )}
              </Spin>
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

const mapDispatchToProps = {
  deletePeriod: planning.actions.deletePeriod,
  editPeriod: planning.actions.editPeriod,
};

export default connect(null, mapDispatchToProps)(PeriodList);
