import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Typography } from 'modules/core/components';
import { DimensionSelect, ValuesSelect } from './components';

const ContainerSelects = ({
  loading = false,
  optionsDimensionsSelect = [],
  disabledDimensionsSelect = false,
  onChangeDimensionsSelect = () => {},
  optionsValuesSelect = [],
  onChangeValuesSelect = () => {},
  disabledValuesSelect = false,
  onBlurValuesSelects = () => {},
  LabelSelect = '',
}) => {
  const { t } = useTranslation();
  return (
    <Space direction="vertical">
      <Typography.Body level={3}>{t(LabelSelect)}</Typography.Body>
      <Space direction="horizontal">
        <DimensionSelect
          options={optionsDimensionsSelect}
          disabled={disabledDimensionsSelect}
          onChange={onChangeDimensionsSelect}
        />
        <ValuesSelect
          loading={loading}
          options={optionsValuesSelect}
          onChange={onChangeValuesSelect}
          onBlur={onBlurValuesSelects}
          disabled={disabledValuesSelect}
        />
      </Space>
    </Space>
  );
};

export default ContainerSelects;
