import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  usePagination,
  useToggle,
  useCollapser,
  useSorter,
  useFunctionalCurrency,
} from 'modules/core/customHooks';
import numeral from 'numeral';
import {
  Spin,
  Card,
  Button,
  Space,
  Row,
  Col,
  Table,
  Popconfirm,
  notification,
  message,
} from 'antd';
import {
  DownloadOutlined,
  SolutionOutlined,
  CopyOutlined,
  DeleteOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  DropdownMenu,
  Typography,
  KPIValue,
  EmptyScreen,
  TableSettingsModal,
  References,
  AccessLink,
  ShowCommentsButton,
  ScenarioDrawer,
  ButtonsSwitchDataType,
} from 'modules/core/components';
import { CreateBudgetModal } from 'modules/planning/components';
import emptyStateImage from 'assets/images/compositions/empty-big.svg'; // ToDo: imagen nueva estética.
import {
  calculateScrollX,
  columnsHelper,
  isInElaboration,
  isPublished,
  isUserAllowed,
  formItemsHelper,
  renderPrefix,
  userCanAcces,
  isAmount,
} from 'modules/core/utils';
import { useTranslation } from 'react-i18next';
import {
  ROLES,
  CONCEPTS,
  COLUMNS,
  ROWS,
  TABLES,
  PROJECTION,
  ACCES_USERS,
  REPORT,
} from 'modules/core/constants';
import planning from 'modules/planning';
import login from 'modules/login';

import './ProjectionDetailSummary.scss';

//TODO: AGREGAR ROLES FORECAST CUANDO ESTEN
const {
  PLANNING__EXPENSES__APPLY_VARS,
  PLANNING__SALES__APPLY_VARS,
  PLANNING__EXPENSES__DUPLICATE,
  PLANNING__SALES__DUPLICATE,
  PLANNING__EXPENSES__DELETE,
  PLANNING__SALES__DELETE,
  PLANNING__EXPENSES__EXCHANGE_RATE,
  PLANNING__SALES__EXCHANGE_RATE,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PORJECTION__CONCEPT = {
  [PROJECTION.BUDGET]: {
    [CONCEPTS.KEYS.SALES]: {
      APPLY_VARS: PLANNING__SALES__APPLY_VARS,
      DUPLICATE: PLANNING__SALES__DUPLICATE,
      DELETE: PLANNING__SALES__DELETE,
      EXCHANGE_RATE: PLANNING__SALES__EXCHANGE_RATE,
    },
    [CONCEPTS.KEYS.EXPENSES]: {
      APPLY_VARS: PLANNING__EXPENSES__APPLY_VARS,
      DUPLICATE: PLANNING__EXPENSES__DUPLICATE,
      DELETE: PLANNING__EXPENSES__DELETE,
      EXCHANGE_RATE: PLANNING__EXPENSES__EXCHANGE_RATE,
    },
  },
  [PROJECTION.FORECAST]: {
    [CONCEPTS.KEYS.SALES]: {
      APPLY_VARS: PLANNING__SALES__APPLY_VARS,
      DUPLICATE: PLANNING__SALES__DUPLICATE,
      DELETE: PLANNING__SALES__DELETE,
      EXCHANGE_RATE: PLANNING__SALES__EXCHANGE_RATE,
    },
    [CONCEPTS.KEYS.EXPENSES]: {
      APPLY_VARS: PLANNING__EXPENSES__APPLY_VARS,
      DUPLICATE: PLANNING__EXPENSES__DUPLICATE,
      DELETE: PLANNING__EXPENSES__DELETE,
      EXCHANGE_RATE: PLANNING__EXPENSES__EXCHANGE_RATE,
    },
  },
};

const PROJECTION_URL = {
  [PROJECTION.BUDGET]: 'planificacion',
  [PROJECTION.FORECAST]: PROJECTION.FORECAST,
};

const isForecast = type => type === PROJECTION.FORECAST;

const PlanningDetailSummary = ({
  projectionId,
  projection,
  projectionColumns,
  projectionRows,
  projectionKpis,
  filterTableOptions,
  fetchProjectionDetail,
  fetchProjectionColumns,
  fetchProjectionRows,
  fetchProjectionFilters,
  fetchProjectionKpis,
  downloadProjectionXLS,
  deleteAllProjectionRows,
  syncProjectionComment,
  createTransactionComment,
  deleteTransactionComment,
  loggedUser: { email },
  pxq,
  fetchPXQ,
  projectionColumnsImporte,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isCollapsed, toogleIsCollapsed, isCollapsing] = useCollapser();
  const [isKpisLoading, setIsKpisLoading] = useState(true);
  const [showSimulationDrawer, setShowSimulationDrawer] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS(email),
    showNegativeInRed: false,
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
  });
  const [tablePagination, setTablePagination, totalizerRows] = usePagination({
    totalizerRows: 1,
  });
  const [showRowsComment, toggleShowRowsComment] = useToggle(false);
  const [filters, setFilters] = useState({});
  const [tableSorter, setTableSorter] = useSorter();
  const [refetchFilters, setRefetchFilters] = useState(true);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [columnsSummary, setColumnsSummary] = useState([]);
  const [columnsSummaryImporte, setColumnsSummaryImporte] = useState([]);
  const [tableTypeKey, setTableTypeKey] = useState(REPORT.REPORTS_KEYS.AMOUNT);
  const functionalCurrencyData = useFunctionalCurrency();
  let { periodId, conceptName, forecastId } = useParams();
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    fetchPXQ();
  }, [fetchPXQ]);

  useEffect(() => {
    const endpointParam = {
      ...(!isForecast(projection.type) && {
        pxq: 'True',
      }),
    };
    setIsScreenLoading(true);
    Promise.all([
      fetchProjectionDetail(projectionId),
      fetchProjectionColumns(projectionId, endpointParam),
    ]).then(() => setIsScreenLoading(false));
  }, [
    projectionId,
    fetchProjectionColumns,
    fetchProjectionDetail,
    projection.type,
  ]);

  useEffect(() => {
    setColumnsSummary(projectionColumns);
    setColumnsSummaryImporte(projectionColumnsImporte);
  }, [projectionColumns, projectionColumnsImporte]);

  useEffect(() => {
    if (projection.concept.id && refetchFilters) {
      fetchProjectionFilters(projection.concept.id, {
        projection: projectionId,
        ...filters,
      })
        .then(() => setRefetchFilters(false))
        .catch(() => setRefetchFilters(false));
    }
  }, [
    fetchProjectionFilters,
    projectionId,
    projection,
    refetchFilters,
    filters,
  ]);

  useEffect(() => {
    setIsKpisLoading(true);
    fetchProjectionKpis(projectionId, { ...filters }).then(() =>
      setIsKpisLoading(false)
    );
  }, [projectionId, fetchProjectionKpis, filters]);

  useEffect(() => {
    setIsTableLoading(true);
    fetchProjectionRows(projectionId, {
      ...tablePagination,
      ...filters,
      ...tableSorter,
      formula_id: pxq.length > 0 ? pxq[0].id : null,
    }).then(() => setIsTableLoading(false));
  }, [
    fetchProjectionRows,
    projectionId,
    tablePagination,
    filters,
    tableSorter,
    pxq,
  ]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const canUserExchangeRateView = isUserAllowed(
    PORJECTION__CONCEPT[projection.type][projection.concept.name].EXCHANGE_RATE
  );

  const onDeleteAll = () => {
    setIsTableLoading(true);
    setRefetchFilters(true);
    deleteAllProjectionRows(projectionId)
      .then(() => {
        setShowDeleteAll(false);
        Promise.all([
          fetchProjectionRows(projectionId, {
            ...tablePagination,
            ...filters,
            formula_id: pxq.length > 0 ? pxq[0].id : null,
          }),
          fetchProjectionDetail(projectionId),
        ]).then(() => {
          setIsTableLoading(false);
          message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
        });
      })
      .catch(() => {
        setShowDeleteAll(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_DATA_ERROR'),
          duration: 0,
        });
      });
  };

  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        {isUserAllowed(
          PORJECTION__CONCEPT[projection.type][projection.concept.name]
            .APPLY_VARS
        ) && (
          <Button
            onClick={() => setShowSimulationDrawer(true)}
            disabled={!isInElaboration(projection.status)}
          >
            {t('ACTION_STAGE')}
          </Button>
        )}
        <Button
          icon={<SolutionOutlined />}
          onClick={() =>
            history.push(
              `/${
                PROJECTION_URL[projection.type]
              }/${periodId}/${conceptName}/actividad/${projectionId}`
            )
          }
        >
          {t('ACTION_ACTIVITIES')}
        </Button>
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            ...(!isForecast(projection.type) ||
            (isForecast(projection.type) &&
              userCanAcces({
                patchAcces: ACCES_USERS.PATCH_ACCES_CURRENCY_USERS,
                email,
              }))
              ? [
                  {
                    title: t('ACTION_LOAD_EXCHANGE_RATE'),
                    icon: <DollarOutlined />,
                    onClick: () =>
                      history.push(
                        `/${
                          PROJECTION_URL[projection.type]
                        }/${periodId}/tipodecambio/${projectionId}`
                      ),
                    disabled: !canUserExchangeRateView,
                  },
                ]
              : []),
            {
              title: t('ACTION_COPY'),
              icon: <CopyOutlined />,
              onClick: () => setShowCopyModal(true),
              disabled: !isUserAllowed(
                PORJECTION__CONCEPT[projection.type][projection.concept.name]
                  .DUPLICATE
              ),
            },
            ...(!isForecast(projection.type)
              ? [
                  {
                    title: t('ACTION_DELETE_ALL'),
                    icon: <DeleteOutlined />,
                    onClick: () => setShowDeleteAll(true),
                    disabled:
                      !isUserAllowed(
                        PORJECTION__CONCEPT[projection.type][
                          projection.concept.name
                        ].DELETE
                      ) ||
                      !(
                        isInElaboration(projection.status) ||
                        isPublished(projection.status)
                      ),
                  },
                ]
              : []),
          ]}
        />
        <Popconfirm
          placement="left"
          title={t('POPCONFIRM_DELETE_ALL_PAGES', {
            projectionName: projection && projection.name,
          })}
          okText={t('ACTION_DELETE')}
          onConfirm={onDeleteAll}
          cancelText={t('ACTION_CANCEL')}
          onCancel={() => setShowDeleteAll(false)}
          visible={showDeleteAll}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderKPIs = () => (
    <Row gutter={[24, 0]}>
      <>
        {isKpisLoading &&
          [1, 2, 3].map(index => (
            <Col span={8} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isKpisLoading &&
          projectionKpis.map((kpi, index) => (
            <Col span={8} key={index}>
              <Card style={{ height: '100%' }}>
                <Space>
                  <KPIValue
                    size="medium"
                    prefix={renderPrefix(functionalCurrencyData, kpi.prefix)}
                    fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                    value={
                      kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'
                    }
                  />
                  {kpi.variation && (
                    <KPIValue
                      value={
                        kpi.variation
                          ? numeral(kpi.variation).format('0%')
                          : '-'
                      }
                      type="secondary"
                    />
                  )}
                </Space>
                <br />
                <Typography.Body level={3} type="secondary">
                  {kpi.name}
                </Typography.Body>
              </Card>
            </Col>
          ))}
      </>
    </Row>
  );

  // TODO: PROJECTION.ROWS INCLUYE EL COUNT TOTAL , NO DE LA PAGINA. VER COMO SOLUCIONAR
  const renderTableActions = () => (
    <Row>
      <Col span={12}>
        <Space>
          {pxq.length > 0 &&
            pxq[0].projections.length > 0 &&
            pxq[0].projections.includes(parseInt(projectionId)) && (
              <ButtonsSwitchDataType
                tableTypeKey={tableTypeKey}
                onChange={value => setTableTypeKey(value.target.value)}
              />
            )}
          <References
            referencesItems={[
              {
                color: '#0047ba',
                type: 'Ab',
                title: t('LABEL_VARIABLES'),
                description: t('PLANNING_VALUES_VARS_APPLIED'),
              },
              {
                color: '#e1effa',
                type: '⬤',
                title: t('LABEL_FACTORS'),
                description: t('PLANNING_VALUES_FACTORS_APPLIED'),
              },
              ...(functionalCurrencyData
                ? [
                    {
                      type: <DollarOutlined />,
                      title:
                        functionalCurrencyData && functionalCurrencyData.code,
                      description:
                        functionalCurrencyData &&
                        t('REFERENCE_DESCRIPTION', {
                          currencyName: t(
                            functionalCurrencyData.name
                          ).toLowerCase(),
                        }),
                    },
                  ]
                : []),
            ]}
          />
          <ShowCommentsButton onClick={toggleShowRowsComment} />
        </Space>
      </Col>
      <Col span={12}>
        <ButtonActionBar>
          <AccessLink linkName={projection.concept.name} />
          <Button
            type="text"
            shape="circle"
            icon={<Typography.Icon icon={DownloadOutlined} />}
            onClick={() =>
              downloadProjectionXLS(
                projectionId,
                t('PLANNING_EVOLUTION_TABLE_FILE_NAME', {
                  concept: projection.name,
                  periodName: projection.period.name,
                }),
                { ...filters }
              )
            }
            title={t('ACTION_DOWNLOAD_TABLE')}
          />
          <TableSettingsModal
            onConfirm={setTableSettings}
            options={projectionColumns.filter(
              (column, index) =>
                !column.is_transaction &&
                index !== COLUMNS.FIRST_INDEX &&
                column.data_index !== COLUMNS.TOTAL_DATA_INDEX
            )}
            settings={tableSettings}
            formItems={formItemsHelper.itemsDataView(
              tableSettings.negativeValuesType,
              tableSettings.showNegativeInRed
            )}
          />
        </ButtonActionBar>
      </Col>
    </Row>
  );

  const handleVariablesApply = id => {
    const auxUrl = isForecast(projection.type)
      ? `/${periodId}/${forecastId}/${conceptName}/${id}`
      : `/${periodId}/${conceptName}/${id}`;
    if (projectionId !== id)
      history.push(`/${PROJECTION_URL[projection.type]}${auxUrl}`);
    setIsTableLoading(true);
    Promise.all([
      fetchProjectionDetail(id),
      fetchProjectionRows(id, {
        ...tablePagination,
        ...filters,
        formula_id: pxq.length > 0 ? pxq[0].id : null,
      }),
    ]).then(() => setIsTableLoading(false));
  };

  const onFilter = newFilters => {
    setFilters(newFilters);
    setTablePagination({ current: 1 });
    setRefetchFilters(true);
  };

  const onCommentCell = ({ columnId, rowId, transactionId, text }) => {
    createTransactionComment({ transactionId, text })
      .then(response => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionRows',
          newComment: response.payload.data,
        });
      })
      .catch(() => message.error(t('FEEDBACK_DEFAULT_ERROR')));
  };

  const onDeleteCommentCell = ({ columnId, rowId, commentId }) => {
    deleteTransactionComment({ commentId })
      .then(() => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionRows',
          commentId,
        });
      })
      .catch(() => message.error(t('FEEDBACK_DEFAULT_ERROR')));
  };

  const renderTransactionColumn = column =>
    columnsHelper.renderTransactionPlanning({
      date: column.title,
      ...(isForecast(projection.type) && {
        className: !column.editable
          ? COLUMNS.TYPES.TOTALIZER
          : COLUMNS.TYPES.TRANSACTION,
      }),
      cellFormat: {
        format: tableSettings.format,
        showNegativeInRed: tableSettings.showNegativeInRed,
      },
      width: column.width,
      disabled: true,
      showCalculateValue: false,
      comments: {
        onComment: onCommentCell,
        onDeleteComment: onDeleteCommentCell,
      },
      has_restriction,
    });

  const renderTransactionColumnPXQ = column =>
    columnsHelper.renderTransactionPlanningPXQ({
      column,
      ...(isForecast(projection.type) && {
        className: !column.editable
          ? COLUMNS.TYPES.TOTALIZER
          : COLUMNS.TYPES.TRANSACTION,
      }),
      cellFormat: {
        format: tableSettings.format,
        showNegativeInRed: tableSettings.showNegativeInRed,
      },
      width: column.width,
      disabled: true,
      showCalculateValue: false,
      comments: {
        onComment: onCommentCell,
        onDeleteComment: onDeleteCommentCell,
      },
      has_restriction,
    });

  const columnsGeneratorImporte = () =>
    columnsHelper.generator({
      columns: columnsSummaryImporte,
      filteredColumns: tableSettings.visibleData,
      cellFormat: {
        format: tableSettings.format,
        showNegativeInRed: tableSettings.showNegativeInRed,
      },
      filterProps: { filterOptions: filterTableOptions, filters, onFilter },
      collapserProps: {
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      sorter: true,
      renderTransaction: renderTransactionColumn,
      onResize: setColumnsSummary,
      has_restriction,
    });

  const columnsGeneratorPXQ = () =>
    columnsHelper.generator({
      columns: columnsSummary,
      filteredColumns: tableSettings.visibleData,
      cellFormat: {
        format: tableSettings.format,
        showNegativeInRed: tableSettings.showNegativeInRed,
      },
      filterProps: { filterOptions: filterTableOptions, filters, onFilter },
      collapserProps: {
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      sorter: true,
      renderTransaction: renderTransactionColumnPXQ,
      onResize: setColumnsSummary,
      has_restriction,
    });

  const handleOnChangeTable = (pagination, filters, sorter) => {
    setTablePagination(pagination);
    setTableSorter(sorter);
  };

  const renderRowClassName = record => {
    if (record.id === ROWS.TOTALIZER_ID) {
      return ROWS.TYPES[ROWS.TOTALIZER];
    }
    if (record.has_variable_applied || record.has_rule_applied) {
      return `${
        record.has_variable_applied ? ROWS.TYPES.APPLIED_VAR_ROW : ''
      } ${record.has_rule_applied ? ROWS.TYPES.APPLIED_RULE_ROW : ''}`;
    }
  };

  const renderTable = () => {
    return (
      <Table
        rowKey="id"
        className="planning-table"
        rowClassName={renderRowClassName}
        size="small"
        loading={isTableLoading}
        onChange={handleOnChangeTable}
        columns={
          isAmount(tableTypeKey)
            ? columnsGeneratorImporte()
            : columnsGeneratorPXQ()
        }
        dataSource={!isTableLoading && projectionRows.transactions}
        pagination={{
          pageSize: tablePagination.page_size + totalizerRows,
          current: tablePagination.page,
          total:
            projectionRows.count > 0
              ? projectionRows.count + totalizerRows
              : projectionRows.count,
          pageSizeOptions: ['10', '20', '30'],
          size: 'small',
          showSizeChanger: true,
        }}
        components={columnsHelper.tableComponents}
        locale={columnsHelper.renderSorterLocale()}
        {...(!isCollapsing && {
          scroll: {
            x: calculateScrollX(
              isAmount(tableTypeKey)
                ? columnsGeneratorImporte()
                : columnsGeneratorPXQ()
            ),
            y: 445,
          },
        })}
      />
    );
  };

  return (
    <Spin spinning={isScreenLoading}>
      {!isScreenLoading && projection.has_transactions && (
        <Row gutter={[8, 24]}>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>{renderKPIs()}</Col>
          <Col span={24}>
            {renderTableActions()}
            {renderTable()}
          </Col>
        </Row>
      )}
      {!isScreenLoading && !isTableLoading && !projection.has_transactions && (
        <EmptyScreen
          image={emptyStateImage}
          title={t('EMPTY_STATE_NO_DATA_TITLE')}
          description={t('PLANNING_SUMMARY_EMPTY_SCREEN_DESCRIPTION')}
        />
      )}
      {!isScreenLoading && (
        <CreateBudgetModal
          visible={showCopyModal}
          onCancel={() => setShowCopyModal(false)}
          copyData={{
            id: projectionId,
            name: t('COPY_NAME', {
              name: projection && projection.name,
            }),
          }}
          forecastId={isForecast(projection.type) ? forecastId : null}
        />
      )}
      {projection.id && showSimulationDrawer && (
        <ScenarioDrawer
          visible={showSimulationDrawer}
          onClose={() => setShowSimulationDrawer(false)}
          selected={projection.variables}
          budgetId={projection.id}
          type={projection.type}
          onApplySuccess={handleVariablesApply}
          isForecast={isForecast(projection.type)}
        />
      )}
    </Spin>
  );
};

const mapStateToProps = state => ({
  projection: planning.selectors.getProjectionDetail(state),
  projectionColumns: planning.selectors.getProjectionColumns(state),
  projectionRows: planning.selectors.getProjectionRows(state),
  projectionKpis: planning.selectors.getProjectionKpis(state),
  filterTableOptions: planning.selectors.getFilterTableOptions(state),
  loggedUser: login.selectors.getWhoAmI(state),
  projectionColumnsImporte:
    planning.selectors.getProjectionsColumnsImporte(state),
  pxq: planning.selectors.getPXQ(state),
});

const mapDispatchToProps = {
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
  fetchProjectionColumns: planning.actions.fetchProjectionColumns,
  fetchProjectionRows: planning.actions.fetchProjectionRows,
  fetchProjectionFilters: planning.actions.fetchProjectionFilters,
  downloadProjectionXLS: planning.actions.downloadProjectionXLS,
  fetchProjectionKpis: planning.actions.fetchProjectionKpis,
  deleteAllProjectionRows: planning.actions.deleteAllProjectionRows,
  syncProjectionComment: planning.actions.syncProjectionComment,
  createTransactionComment: planning.actions.createTransactionComment,
  deleteTransactionComment: planning.actions.deleteTransactionComment,
  fetchPXQ: planning.actions.fetchPXQ,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningDetailSummary);
