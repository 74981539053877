import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Typography } from 'modules/core/components';

import './DropdownMenu.scss';

const { Item: MenuItem, SubMenu } = Menu;

/** Cuando existen multiples opciones para extender funcionalidades,
 * se utiliza DropdownMenu para agruparlas en un menu. */
const DropdownMenu = ({
  menu,
  title,
  icon,
  disabled,
  children,
  triggerStyled,
  ...props
}) => (
  <Dropdown
    {...props}
    disabled={disabled}
    overlay={
      <Menu>
        {menu.map(option => {
          const { submenu, ...itemProps } = option;
          if (submenu) {
            return (
              <SubMenu key={itemProps.key} {...itemProps}>
                {submenu.map(submenuItem => (
                  <MenuItem key={submenuItem.key} {...submenuItem}>
                    {submenuItem.title}
                  </MenuItem>
                ))}
              </SubMenu>
            );
          }
          return (
            <MenuItem key={itemProps.key} {...itemProps}>
              {itemProps.title}
            </MenuItem>
          );
        })}
      </Menu>
    }
  >
    {children ? (
      <span
        title={title}
        className="core-dropdown-menu-trigger"
        data-disabled={disabled}
      >
        {children}
      </span>
    ) : (
      <Button
        type="text"
        title={title}
        shape="circle"
        icon={icon ? icon : <Typography.Icon icon={MoreOutlined} />}
        disabled={disabled}
      />
    )}
  </Dropdown>
);

export default DropdownMenu;
