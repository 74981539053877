import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'modules/core/customHooks';
import { SiderLayout } from 'modules/core/layouts';
import {
  PeriodNavigator,
  IntegrationUploadFile,
} from 'modules/core/components';
import { Tabs, Spin } from 'antd';
import PlanningList from '../PlanningList/PlanningList';
import ConsolidationList from '../ConsolidationList/ConsolidationList';
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils';
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants';
import planning from 'modules/planning';

const { PLANNING__EXPENSES, PLANNING__SALES, PLANNING__CONSOLIDATED } = ROLES;
const CONSOLIDACION = 'consolidacion';

const PlanningHome = ({
  period,
  conceptsList,
  fetchPeriodDetail,
  fetchConceptsList,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const { t } = useTranslation();
  let { periodId } = useParams();
  let query = useQuery();

  useEffect(() => {
    Promise.all([fetchPeriodDetail(periodId), fetchConceptsList()]).then(() =>
      setIsScreenLoading(false)
    );
  }, [fetchPeriodDetail, periodId, fetchConceptsList]);

  const PANEL__ACCESS = {
    [PROJECTION.CONSOLIDATED]: isUserAllowed(
      getOperationsByKeys([PLANNING__CONSOLIDATED])
    ),
    [CONCEPTS.KEYS.SALES]: isUserAllowed(
      getOperationsByKeys([PLANNING__SALES])
    ),
    [CONCEPTS.KEYS.EXPENSES]: isUserAllowed(
      getOperationsByKeys([PLANNING__EXPENSES])
    ),
  };

  const showDefaultPanelKey = () => {
    if (PANEL__ACCESS[CONCEPTS.KEYS.SALES]) {
      return CONCEPTS.KEYS.SALES;
    } else if (PANEL__ACCESS[CONCEPTS.KEYS.EXPENSES]) {
      return CONCEPTS.KEYS.EXPENSES;
    } else if (PANEL__ACCESS[PROJECTION.CONSOLIDATED]) {
      return CONSOLIDACION;
    }
  };

  const renderHeader = () => (
    <PeriodNavigator items={[{ name: t('LABEL_PLANNING') }]} />
  );

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      {!isScreenLoading && (
        <>
          <IntegrationUploadFile periodId={periodId} />
          <Tabs
            defaultActiveKey={
              query.get('tab') !== null
                ? query.get('tab')
                : showDefaultPanelKey()
            }
            size="small"
          >
            {conceptsList.values.map(
              concept =>
                PANEL__ACCESS[concept.name] && (
                  <Tabs.TabPane tab={t(concept.name)} key={concept.name}>
                    <PlanningList periodId={periodId} concept={concept} />
                  </Tabs.TabPane>
                )
            )}
            {PANEL__ACCESS[PROJECTION.CONSOLIDATED] && (
              <Tabs.TabPane tab={t('LABEL_CONSOLIDATION')} key={CONSOLIDACION}>
                <ConsolidationList
                  periodDetail={period}
                  onFetchPeriodDetail={() => fetchPeriodDetail(periodId)}
                />
              </Tabs.TabPane>
            )}
          </Tabs>
        </>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  conceptsList: planning.selectors.getConceptsList(state),
  period: planning.selectors.getPeriodDetail(state),
});

const mapDispatchToProps = {
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  fetchConceptsList: planning.actions.fetchConceptsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanningHome);
