import moment from 'moment';
import numeral from 'numeral';

const columns = (t, prefix) => [
  {
    title: t('LABEL_USER'),
    dataIndex: 'user',
    width: 200,
  },
  {
    title: t('FIELD_CREATION_DATE'),
    dataIndex: 'created_on',
    render: value =>
      `${moment(value).format('DD/MM/YYYY')} a las ${moment(value).format(
        'HH:mm'
      )}`,
  },
  {
    title: t('ADJUSTMENT_DATE_FIELD'),
    dataIndex: 'date',
    render: value => moment(value).format('MMMM YYYY'),
  },
  {
    title: t('ADJUSTMENT_TYPE_FIELD'),
    dataIndex: 'type',
  },
  {
    title: t('FIELD_OBSERVATIONS'),
    dataIndex: 'comment',
    ellipsis: true,
  },
  {
    title: t('FIELD_AMOUNT'),
    dataIndex: 'amount',
    align: 'right',
    width: 120,
    render: amount => `${prefix} ${numeral(amount).format('0,0')}`,
  },
];

export default columns;
