import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { usePagination } from 'modules/core/customHooks';
import { Table, Space, Typography } from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import planning from 'modules/planning';

const { Link } = Typography;

// TODO: Implementar paginación cuando sea necesario (falta backend)
const FileUploadDetails = ({
  fetchFileTracks,
  fileTracks: { values, count },
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tablePagination, setTablePagination] = usePagination();
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    fetchFileTracks().then(() => {
      setIsTableLoading(false);
    });
  }, [fetchFileTracks]);

  const fileDetailsColumns = [
    {
      title: t('FIELD_DATE'),
      dataIndex: 'created_on',
      width: 80,
      render: value =>
        `${moment(value).format('DD/MM/YYYY')} ${t('DATE_AT_TIME')} ${moment(
          value
        ).format('HH:mm')}`,
    },
    {
      title: t('LABEL_NAME_FILE'),
      dataIndex: 'name_file',
      width: 100,
      render: (name_file, row) => (
        <Link href={row.url_file} target="_blank">
          {name_file}
        </Link>
      ),
    },
    {
      title: t('LABEL_FILE_TYPE'),
      dataIndex: 'type_file',
      width: 150,
    },
    {
      title: t('FIELD_LOADING_SOURCE'),
      dataIndex: 'integration',
      width: 100,
      render: integration =>
        integration ? t('FIELD_INTEGRATION_LOAD') : t('FIELD_MANUAL_LOAD'),
    },
    {
      title: t('LOADING_STATUS'),
      dataIndex: 'has_error',
      width: 30,
      render: has_error =>
        has_error ? (
          <Space direction="horizontal" size="small">
            <CloseCircleFilled className="details-tracking-screen-error" />
            {t('LOADING_STATUS_ERROR')}
          </Space>
        ) : (
          <Space direction="horizontal" size="small">
            <CheckCircleFilled className="details-tracking-screen-complete" />
            {t('LOADING_STATUS_SUCCESS')}
          </Space>
        ),
    },
    {
      title: t('LABEL_LOAD_DETAILS'),
      dataIndex: 'errors',
      width: 100,
      render: errors =>
        errors.map((el, index) => (
          <div key={index}>
            {el.message}
            <br />
          </div>
        )),
    },
  ];

  return (
    <Table
      rowKey="id"
      size="small"
      bordered
      columns={fileDetailsColumns}
      dataSource={values}
      loading={isTableLoading}
      onChange={setTablePagination}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: count,
        size: 'small',
        pageSizeOptions: ['10', '20', '30'],
        showSizeChanger: true,
      }}
    />
  );
};

const mapStateToProps = state => ({
  fileTracks: planning.selectors.getFileTracks(state),
});

const mapDispatchToProps = {
  fetchFileTracks: planning.actions.fetchFileTracks,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadDetails);
