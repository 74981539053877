import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Row,
  Col,
  Typography,
  Form,
  Radio,
  Select,
  Slider,
  Switch,
  Space,
  Tooltip,
  InputNumber,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CHARTS } from 'modules/core/constants';
import { generateSelectOptions } from 'modules/core/utils';

const FormItem = Form.Item;
const { Text } = Typography;

const ChartsSettingsModal = ({
  visible,
  selectedGraphType,
  settingOptions,
  settingsValues,
  onCancel,
  onOk,
}) => {
  const [form] = Form.useForm();
  const [generalForm] = Form.useForm();
  const { t } = useTranslation();

  const handleOnCancel = () => {
    form.resetFields();
    generalForm.resetFields();
    onCancel();
  };

  const onConfirm = () => {
    Promise.all([form.validateFields(), generalForm.validateFields()])
      .then(([formValue, generalValues]) => {
        onOk({
          chartSettings: formValue,
          generalSettings: generalValues,
        });
        handleOnCancel();
      })
      .catch(e => {});
  };

  const renderGeneralForPie = () => (
    <Row>
      <Col span={8}>
        <Form.Item label={t('LABEL_FORMAT')} name="labelFormat">
          <Radio.Group>
            <Space direction="vertical">
              {settingOptions.general.labelFormat.map(el => (
                <Radio value={el} key={el}>
                  {t(CHARTS.I18N[el])}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t('LABEL_VALUE_POSITION')} name="labelPosition">
          <Radio.Group>
            <Space direction="vertical">
              {settingOptions.general.labelPosition.map(el => (
                <Radio value={el} key={el}>
                  {t(CHARTS.I18N[el])}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t('LABEL_VALUE_TYPE')} name="valueFormat">
          <Radio.Group>
            <Space direction="vertical">
              {settingOptions.general.valueFormat.map(el => (
                <Radio value={el} key={el}>
                  {t(CHARTS.I18N[el])}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );

  const renderGeneralForm = () => {
    return (
      <Form
        form={generalForm}
        layout="horizontal"
        hideRequiredMark={false}
        initialValues={settingsValues.general}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label={t('CHARTS_VALUES_QUANTITY')} name="dataSize">
              <InputNumber min={2} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('FIELD_LABEL')}
              name="showLabel"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.showLabel !== currentValues.showLabel
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('showLabel') && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        label={t('LABEL_FONT_SIZE')}
                        name="labelFontSize"
                      >
                        <Slider min={12} max={18} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      {selectedGraphType === CHARTS.TYPES.PIE &&
                        renderGeneralForPie()}
                    </Col>
                  </>
                )
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const renderPieForm = () => {
    return (
      <Form
        form={form}
        layout="horizontal"
        hideRequiredMark={false}
        initialValues={settingsValues.pie}
      >
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item
              label={t('FIELD_TOTAL_AMOUNT')}
              name="showTotalAmount"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.showTotalAmount !== currentValues.showTotalAmount
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('showTotalAmount') && (
                  <Form.Item label={t('LABEL_FONT_SIZE')} name="totalFontSize">
                    <Slider min={18} max={35} />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            {/* TODO: Antd 4.7.0 has support for tooltips, change when upgrade */}
            <Form.Item
              label={
                <Space direction="horizontal">
                  {t('FIELD_LEGENDS')}
                  <Tooltip
                    title={t('CHARTS_TOOLTIP_INFO_LEGENDS')}
                    placement="bottomRight"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              name="showLegends"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const renderBarForm = () => {
    return (
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark={false}
        initialValues={settingsValues.bar}
      >
        <Row gutter={24}>
          <Col span={24}>
            <FormItem label={t('LABEL_POSITION_MODE')} name="groupMode">
              <Radio.Group>
                <Space direction="vertical">
                  {settingOptions.bar.groupMode.map(el => (
                    <Radio value={el} key={el}>
                      {t(CHARTS.I18N[el])}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('LABEL_DIMENSION_FILTER')}
              name="filteredValues"
            >
              <Select
                mode="multiple"
                allowClear
                options={generateSelectOptions({
                  options: settingOptions.bar.filteredValues.map(el => {
                    return { id: el, name: el };
                  }),
                })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Modal
      title={t('DISPLAY_MODAL_SETTINGS_TITLE')}
      visible={visible}
      closable={false}
      onCancel={handleOnCancel}
      okText={t('ACTION_ACEPT')}
      cancelText={t('ACTION_CANCEL')}
      onOk={onConfirm}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text strong>{t('CHARTS_MODAL_SETTINGS_SUBTITLE')}</Text>
        </Col>
        <Col span={24}>
          {selectedGraphType === CHARTS.TYPES.PIE && renderPieForm()}
          {selectedGraphType === CHARTS.TYPES.BAR && renderBarForm()}
          {renderGeneralForm()}
        </Col>
      </Row>
    </Modal>
  );
};

export default ChartsSettingsModal;
