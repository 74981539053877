import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Select, message } from 'antd';
import { AddAndDeleteFormItem } from 'modules/core/components';
import { handleSelectAll, generateSelectOptions } from 'modules/core/utils';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import { useTranslation } from 'react-i18next';

const ConfigurationCreateModal = ({
  visible,
  onCancel,
  fetchUserRoles,
  userRoles,
  createUser,
  onRefetchUsersList,
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    fetchUserRoles();
  }, [fetchUserRoles]);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoadingModal(true);
        const data = values.users.map(infoUser => {
          return {
            ...infoUser,
            roles: infoUser.roles.map(rol => parseInt(rol)),
          };
        });
        createUser(data)
          .then(() =>
            onRefetchUsersList().then(() => {
              setIsLoadingModal(false);
              message.success(t('FEEDBACK_CREATE_SUCCESS'));
              onClose();
            })
          )
          .catch(() => {
            setIsLoadingModal(false);
            message.error(t('FEEDBACK_CREATION_FAIL'));
            onClose();
          });
      })
      .catch(e => {});
  };

  const handleSetFormListValues = (values, index) => {
    const fields = form.getFieldsValue();
    const { users } = fields;
    Object.assign(users[index], { roles: values });
    return form.setFieldsValue({ users });
  };

  return (
    <Modal
      title={t('CONFIG_USERS_MODAL_CREATE_USER_TITLE')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={onConfirm}
      onCancel={onClose}
      width={570}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical" initialValues={{ users: [''] }}>
        <Form.List name="users">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <>
                    <Row gutter={24} type="flex" justify="center">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'email']}
                          fieldKey={[field.fieldKey, 'email']}
                          label={[field.label, t('FIELD_EMAIL')]}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              type: 'email',
                              message: t(
                                'LOGIN_FORM_INPUT_EMAIL_CAPTION_FORMAT'
                              ),
                            },
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'LOGIN_FORM_INPUT_EMAIL_PLACEHOLDER'
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'roles']}
                          fieldKey={[field.fieldKey, 'roles']}
                          label={[field.label, t('LABEL_ROLE')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t('ACTION_SELECT')}
                            mode="multiple"
                            showArrow
                            allowClear
                            showSearch
                            maxTagCount={1}
                            optionFilterProp="children"
                            onChange={values =>
                              handleSelectAll({
                                values,
                                allValues: userRoles
                                  .asMutable()
                                  .map(user => user.id),
                                areAllSelected,
                                onSetValues: values =>
                                  handleSetFormListValues(values, index),
                                onSetAreAllSelected: state =>
                                  setAreAllSelected(state),
                              })
                            }
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            options={generateSelectOptions({
                              options: userRoles.map(user => {
                                return { ...user, name: user.display_name };
                              }),
                              includeOptionAll: true,
                            })}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <AddAndDeleteFormItem
                      fieldsLength={fields.length}
                      index={index}
                      disabled={isLoadingModal}
                      addData={{
                        onClick: () => add(),
                        buttonName: 'CONFIG_USERS_ADD_USER_ACTION',
                      }}
                      deleteData={{
                        onClick: () => remove(field.name),
                        buttonName: 'CONFIG_USERS_DELET_USER_ACTION',
                      }}
                    />
                  </>
                ))}
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  userRoles: configuration.selectors.getUserRoles(state),
});

const mapDispatchToProps = {
  fetchUserRoles: configuration.actions.fetchUserRoles,
  createUser: configuration.actions.createUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationCreateModal);
