import * as types from './constants';

export const createConfigurationOdoo =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_CONFIGURATION_ODOO,
      payload: {
        request: {
          url: '/auth/integrations/create_configuration_odoo/',
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchIntegrationList = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_INTEGRATION_LIST,
    payload: {
      request: {
        url: '/auth/integrations/list/',
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const updateStatus =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UPDATE_STATUS,
      payload: {
        request: {
          url: '/auth/integrations/update_status/',
          method: 'PUT',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchIntegrationTrackingList = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_INTEGRATION_TRACKING_LIST,
    payload: {
      request: {
        url: '/auth/integrations/list_log/',
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};
