import React from 'react';
import { connect } from 'react-redux';
import { Col, List, Button } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { SiderLayout } from 'modules/core/layouts';
import {
  PeriodNavigator,
  ListCard,
  ButtonActionBar,
} from 'modules/core/components';
import { CONCEPTS, ACCES_USERS } from 'modules/core/constants';
import { userCanAcces } from 'modules/core/utils';
import financialCardImage from 'assets/images/compositions/financial-card.svg';
import economicCardImage from 'assets/images/compositions/economic-card.svg';
import login from 'modules/login';

import './ControlHome.scss';

const ControlHome = ({ loggedUser: { email } }) => {
  const { t } = useTranslation();
  let history = useHistory();
  let { periodId } = useParams();

  const renderHeader = () => (
    <PeriodNavigator items={[{ name: t('LABEL_CONTROL') }]} />
  );

  const financialData = [
    {
      id: CONCEPTS.TYPE.ECONOMIC_ID,
      name: t('LABEL_ECONOMIC_CONTROL'),
      key: CONCEPTS.TYPE.ECONOMIC_KEY,
      title: t('CONTROL_EMPTY_ECONOMIC_CARD_TITLE'),
      image: economicCardImage,
    },
    {
      id: CONCEPTS.TYPE.FINANCIAL_ID,
      name: t('LABEL_FINANCIAL_CONTROL'),
      key: CONCEPTS.TYPE.FINANCIAL_KEY,
      title: t('CONTROL_EMPTY_FINANCIAL_CARD_TITLE'),
      image: financialCardImage,
    },
  ];

  const renderControlCards = () => (
    <List
      grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
      dataSource={financialData}
      renderItem={control => {
        const { id, name, key, title, image } = control;
        return id === CONCEPTS.TYPE.FINANCIAL_ID &&
          !userCanAcces({
            patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
            email,
          }) ? null : (
          <List.Item>
            <ListCard
              type={key}
              title={name}
              actions={[
                <ButtonActionBar position="start">
                  <Button
                    type="link"
                    onClick={() =>
                      history.push(`/seguimiento/${periodId}/${id}`)
                    }
                  >
                    {t('ACTION_ENTER')}
                    <RightCircleOutlined />
                  </Button>
                </ButtonActionBar>,
              ]}
              emptyState={{
                title: title,
                description: t('CONTROL_EMPTY_FINANCIAL_CARD_DESCRIPTION'),
              }}
              image={image}
            />
          </List.Item>
        );
      }}
    />
  );

  return (
    <SiderLayout>
      <Col span={24}>{renderHeader()}</Col>
      <Col span={24}>{renderControlCards()}</Col>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

export default connect(mapStateToProps, null)(ControlHome);
