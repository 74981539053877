import React from 'react';
import { CHARTS } from 'modules/core/constants';
import { ResponsivePie } from '@nivo/pie';
import ChartContainer from '../ChartContainer/ChartContainer';

const PieChart = ({
  className = '',
  data = [],
  emptyMessage = '',
  containerHeight,
  margin = {},
  ...props
}) => (
  <ChartContainer className={className} style={{ height: containerHeight }}>
    {data.length <= 0 && emptyMessage}
    {data.length > 0 && (
      <ResponsivePie
        data={data}
        colors={CHARTS.COLORS}
        margin={{ top: 20, right: 100, bottom: 20, left: 100, ...margin }}
        innerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        arcLinkLabelsDiagonalLength={8}
        arcLinkLabelsStraightLength={8}
        arcLinkLabelsSkipAngle={5}
        legends={[
          {
            anchor: 'left',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 88,
            itemHeight: 24,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            toggleSerie: true,
            symbolSize: 12,
            symbolShape: 'circle',
          },
        ]}
        {...props}
      />
    )}
  </ChartContainer>
);

export default PieChart;
