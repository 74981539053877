import React, { useState } from 'react';
import {
  notification,
  message,
  Table,
  Tooltip,
  Popconfirm,
  Button,
  Space,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { usePagination } from 'modules/core/customHooks';
import { InputCell, SelectCell, DropdownMenu } from 'modules/core/components';
import _ from 'lodash';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import { ROLES, PROJECTION_ELEMENTS, PROJECTION } from 'modules/core/constants';
import { isUserAllowed } from 'modules/core/utils';

const {
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__DELETE,
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__EDIT,
} = ROLES;

//TODO: VER TEMA DE COMO MOSTRAR UNA CONFIRMACION U OTRA EN ELIMINACION DE FACTOR, SI LA FORMULA ESTA O NO APLICADA.
const FactoresTable = ({
  dataSource,
  loading,
  onFetchFactorsList,
  optionsBehaviour,
  editFactor,
  deleteFactor,
}) => {
  const [tablePagination, setTablePagination] = usePagination();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [deleteFactorId, setDeleteFactorId] = useState(null);
  const [isDeletingFactor, setIsDeletingFactor] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [showConfirmSaveChanges, setShowConfirmSaveChanges] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const { t } = useTranslation();

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });
  };

  const renderOptionsRows = (id, factorName) => {
    return (
      <Popconfirm
        placement="bottomRight"
        title={t('CONFIG_FACTOR_POPCONFIRM_DELETE_TITLE')}
        okText={t('ACTION_DELETE')}
        okButtonProps={{ loading: isDeletingFactor }}
        onConfirm={() => onDeleteFactor(id, factorName)}
        cancelText={t('ACTION_CANCEL')}
        onCancel={() => setDeleteFactorId(null)}
        visible={deleteFactorId === id}
      >
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={_.compact([
            {
              title: t('ACTION_EDIT'),
              icon: <EditOutlined />,
              onClick: () => setEditRow(id),
              disabled: !isUserAllowed(
                SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__EDIT
              ),
            },
            {
              title: t('ACTION_DELETE'),
              icon: <DeleteOutlined />,
              onClick: () => setDeleteFactorId(id),
              disabled: !isUserAllowed(
                SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__DELETE
              ),
            },
          ])}
        />
      </Popconfirm>
    );
  };

  const renderCancelButton = () => {
    return (
      <Popconfirm
        placement="bottomRight"
        title={t('POPCONFIRM_EDIT_CANCEL')}
        okText={t('YES')}
        onConfirm={() => {
          setShowCancelConfirm(false);
          setEditRow(null);
          setDataCellEdit({});
        }}
        cancelText={t('NO')}
        onCancel={() => setShowCancelConfirm(false)}
        visible={showCancelConfirm && !_.isEmpty(dataCellEdit)}
      >
        <Button
          type="link"
          onClick={() => {
            _.isEmpty(dataCellEdit)
              ? setEditRow(null)
              : setShowCancelConfirm(true);
          }}
          disabled={showConfirmSaveChanges}
        >
          {t('ACTION_CANCEL')}
        </Button>
      </Popconfirm>
    );
  };

  const renderConfirmButton = () => {
    return (
      <Popconfirm
        placement="bottomRight"
        title={t('PLANNING_FACTOR_POPCONFIRM_SAVE_CHANGES')}
        okText={t('YES')}
        onConfirm={() => onEditFactor()}
        cancelText={t('NO')}
        onCancel={() => {
          setShowConfirmSaveChanges(false);
          setEditRow(null);
          setDataCellEdit({});
        }}
        visible={showConfirmSaveChanges}
      >
        <Button
          type="link"
          onClick={() =>
            !dataCellEdit.type && !dataCellEdit.behaviour
              ? onEditFactor()
              : setShowConfirmSaveChanges(
                  dataCellEdit.type || dataCellEdit.behaviour
                )
          }
          disabled={_.isEmpty(dataCellEdit) || showCancelConfirm}
        >
          {t('ACTION_SAVE_CHANGES')}
        </Button>
      </Popconfirm>
    );
  };

  const renderEditButtons = () => (
    <Space direction="horizontal" size="middle">
      {renderCancelButton()}
      {renderConfirmButton()}
    </Space>
  );

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      width: 230,
      editable: true,
      render: (name, record) => {
        return record.id === editRow ? (
          <InputCell
            initialValue={name}
            onCellUpdate={onCellEdit}
            name="name"
          />
        ) : (
          name
        );
      },
    },
    {
      title: t('LABEL_FORMAT'),
      dataIndex: 'behaviour',
      width: 200,
      render: (behaviour, record) => {
        return record.id === editRow ? (
          <SelectCell
            initialValue={behaviour.id}
            onCellUpdate={onCellEdit}
            name="behaviour"
            options={optionsBehaviour.map(behaviour => {
              return { id: behaviour.id, name: behaviour.name };
            })}
          />
        ) : (
          <Tooltip title={behaviour.description ? behaviour.description : ''}>
            {behaviour.name}
          </Tooltip>
        );
      },
    },
    {
      title: t('FIELD_TYPE'),
      dataIndex: 'type',
      width: 200,
      render: (type, record) => {
        return record.id === editRow ? (
          <SelectCell
            initialValue={type}
            onCellUpdate={onCellEdit}
            name="type"
            options={PROJECTION.FACTORS_TYPE_OPTIONS.map(factor => {
              return { ...factor, name: t(factor.name) };
            })}
          />
        ) : type === 'principal' ? (
          t('FIELD_PRINCIPAL')
        ) : (
          t('FIELD_SECONDARY')
        );
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      width: 100,
      render: (id, record) => {
        if (id !== editRow) {
          return renderOptionsRows(id, record.name);
        } else {
          return renderEditButtons();
        }
      },
    },
  ];

  const onEditFactor = () => {
    if (!_.isEmpty(dataCellEdit)) {
      editFactor(editRow, dataCellEdit)
        .then(() => {
          setIsTableLoading(true);
          onFetchFactorsList().then(() => {
            setIsTableLoading(false);
            setEditRow(null);
            setDataCellEdit({});
            setShowConfirmSaveChanges(false);
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          });
        })
        .catch(() => {
          setIsTableLoading(false);
          setEditRow(null);
          setDataCellEdit({});
          setShowConfirmSaveChanges(false);
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          });
        });
    }
  };

  const onResetData = () => {
    setIsDeletingFactor(false);
    setDeleteFactorId(null);
  };

  const onDeleteFactor = (id, factorName = '', keepData = null) => {
    //NOTE: keep_data se le envia para validar si el usuario eligió borrar o conservar datos. Borrar => keep_data: false, conservar => keep_data: true
    const endpointParams = {
      ...(keepData !== null && {
        keep_data: keepData,
      }),
    };
    setIsDeletingFactor(true);
    deleteFactor(id, endpointParams)
      .then(response => {
        if (
          response.payload.data.code ===
          PROJECTION_ELEMENTS.FACTOR.CODES_DELETE.KEEP_DATA
        ) {
          setDeleteFactorId(null);
          Modal.confirm({
            title: t('FIELD_ATENTION'),
            centered: true,
            content: (
              <Space direction="vertical">
                <span>
                  {t('CONFIG_FACTOR_CONFIRM_ACTION_MODAL_DESCRIPTION', {
                    factorName: factorName,
                  })}
                </span>
                <span>
                  {t('CONFIG_FORMULAS_CONFIRM_ACTION_POPCONFIRM_TITLE')}
                </span>
              </Space>
            ),
            okText: t('ACTION_CLEAR'),
            onOk: () => onDeleteFactor(id, factorName, false),
            cancelText: t('ACTION_KEEP'),
            onCancel: () => onDeleteFactor(id, factorName, true),
          });
        } else {
          onFetchFactorsList().then(() => {
            message.success(t('CONFIG_FACTOR_DELETE_FEEDBACK_SUCCESS'));
            onResetData();
          });
        }
      })
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_FAIL'),
          duration: 0,
        });
        onResetData();
      });
  };

  return (
    <Table
      rowKey="id"
      size="small"
      bordered
      loading={loading || isTableLoading || isDeletingFactor}
      columns={columns}
      dataSource={dataSource}
      onChange={setTablePagination}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: dataSource.length,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  fetchFactoresBehaviours: configuration.actions.fetchFactoresBehaviours,
  editFactor: configuration.actions.editFactor,
  deleteFactor: configuration.actions.deleteFactor,
};

export default connect(null, mapDispatchToProps)(FactoresTable);
