import Immutable from 'seamless-immutable';
import * as types from './constants';

const initialState = Immutable({
  realColumns: [],
  realRows: {
    transactions: [],
    count: 0,
  },
  controlDetailKPIS: [],
  monthsToPublish: [],
  approveDates: [],
  monthsToApprove: [],
  publishedMonths: [],
});

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let data;
  switch (action.type) {
    case types.FETCH_REAL_COLUMNS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        realColumns: data.columns,
      });
    case types.FETCH_REAL_ROWS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        realRows: {
          transactions: data.results.rows.map((row, index) => {
            return { ...row, id: index };
          }),
          count: data.count,
        },
      });
    case types.FETCH_CONTROL_DETAIL_KPIS_SUCCESS:
      data = action.payload.data;
      return state.merge({
        controlDetailKPIS: data.kpis,
      });
    case types.FETCH_MONTHS_TO_PUBLISH_SUCCESS:
      data = action.payload.data;
      return state.merge({
        monthsToPublish: data,
      });
    case types.FETCH_MONTHS_TO_APPROVE_SUCCESS:
      return state.merge({
        monthsToApprove: action.payload.data,
      });
    case types.FETCH_APPROVE_DATES_SUCCESS:
      data = action.payload.data;
      return state.merge({
        approveDates: data,
      });

    //BORRAR
    case types.FETCH_PUBLISHED_MONTHS:
      return state.merge({
        publishedMonths: action.payload.data,
      });

    default:
      return state;
  }
};
