import React from 'react';
import { Button } from 'antd';
import { ONBOARDING } from '../constants';
import { ButtonActionBar } from 'modules/core/components';
import i18n from 'i18next';

export const getOnboardingStepsContent = ({ text, action, plika_url }) => {
  const renderButtonGo = ({ action }) => (
    <Button style={{ paddingLeft: 3 }} type="link" onClick={action}>
      {i18n.t('ACTION_GO')}
    </Button>
  );

  const renderButtonMoreInfo = ({ plika_url }) => (
    <a
      style={{ paddingLeft: 3 }}
      href={plika_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18n.t('LABEL_MORE_INFO')}
    </a>
  );

  return (
    <div style={ONBOARDING.STEPS_STYLES}>
      <div>
        {i18n.t(text)}
        {plika_url ? renderButtonMoreInfo({ plika_url }) : null}
        <ButtonActionBar position="center">
          {renderButtonGo({ action })}
        </ButtonActionBar>
      </div>
    </div>
  );
};

//NOTE: RESUELTO DE ESTA FROMA PORQUE NO ME TOMABA LAS TRADUCCIONES. REVEER
export const setLocaleNamesTranslate = () => {
  const localeNamesTranslate = {};
  ONBOARDING.LOCALE_NAMES.forEach(localName =>
    Object.assign(localeNamesTranslate, {
      [localName.key]: i18n.t(localName.label),
    })
  );

  return localeNamesTranslate;
};
