import React from 'react';
import { Space, Input, Typography } from 'antd';
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import { dateMMMMYYYY } from 'modules/core/utils';
import { useTranslation } from 'react-i18next';

import './AdjustmentDetailInfo.scss';

const { Text } = Typography;

const AdjustmentDetailInfoItem = ({
  title = '',
  icon = null,
  content,
  isTextArea = false,
}) => (
  <Space className="adjustment-detail-info-item" direction="vertical">
    <Text>{title}</Text>
    {isTextArea ? (
      <Input.TextArea
        value={content}
        disabled
        autoSize
        style={{ width: 300 }}
      />
    ) : (
      <Input value={content} suffix={icon} disabled />
    )}
  </Space>
);

const AdjustmentDetailInfo = ({
  date = null,
  type = '-',
  comment: { original = '', canceled = '' },
}) => {
  const { t } = useTranslation();
  return (
    <Space size="large" align="top">
      <AdjustmentDetailInfoItem
        title={t('FIELD_YEAR_MONTH')}
        icon={<CalendarOutlined />}
        content={date ? dateMMMMYYYY(date, 'YYYY-MM-DD') : '-'}
      />
      <AdjustmentDetailInfoItem
        title={t('ADJUSTMENT_TYPE_FIELD')}
        icon={<DownOutlined />}
        content={type}
      />
      <AdjustmentDetailInfoItem
        title={t('CONTROL_ORIGINAL_OBS_FILED')}
        isTextArea
        content={original}
      />
      {canceled !== '' && (
        <AdjustmentDetailInfoItem
          title={t('CONTROL_CANCELED_OBS_FILED')}
          isTextArea
          content={canceled}
        />
      )}
    </Space>
  );
};

export default AdjustmentDetailInfo;
