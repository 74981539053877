import React, { useEffect, useState } from 'react';
import { Spin, Tabs, Tooltip } from 'antd';
import SegmentedAnalysis from '../SegmentedAnalysis/SegmentedAnalysis';
import VerticalAnalysis from '../VerticalAnalysis/VerticalAnalysis';
import SegregatedAnalysis from '../SegregatedAnalysis/SegregatedAnalysis';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import planning from 'modules/planning';
import { REPORT } from 'modules/core/constants';
import _ from 'lodash';

const ReportSegmentedHome = ({
  filters,
  selectedDates,
  periodDetail,
  fetchDimensionsList,
  dimensionsList,
  fetchDimensionsValuesList,
  dimensionsValuesList,
  periodId,
  reportId,
  consolidationListForecast,
  consolidationListBudget,
  consolidatedPredefined,
  activeOptionPeriodTable,
  functionalCurrencyData,
}) => {
  const [loadingDimensions, setLoadingDimensions] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoadingDimensions(true);
    Promise.all([
      fetchDimensionsList({ flat: 'True', writeable: 'True' }),
      fetchDimensionsValuesList(periodId),
    ]).then(() => setLoadingDimensions(false));
  }, [fetchDimensionsList, fetchDimensionsValuesList, periodId]);

  const dimensionsListData =
    dimensionsList &&
    dimensionsList.dimensions.filter(dimension =>
      _.keys(dimensionsValuesList).includes(dimension.id.toString())
    );

  return (
    <>
      <Spin spinning={loadingDimensions} />
      {!loadingDimensions && (
        <Tabs
          size="small"
          defaultActiveKey={
            dimensionsListData.length === 0
              ? REPORT.REPORTS_KEYS.VERTICAL_KEY
              : REPORT.REPORTS_KEYS.SEGMENTED_KEY
          }
        >
          <Tabs.TabPane
            tab={
              <Tooltip
                title={
                  dimensionsListData.length === 0
                    ? t('REPORT_TAB_SEGMENTED_ANALYSIS_TOOLTIP_TITLE')
                    : ''
                }
              >
                {t('REPORT_TAB_SEGMENTED_ANALYSIS')}
              </Tooltip>
            }
            disabled={dimensionsListData.length === 0}
            key={REPORT.REPORTS_KEYS.SEGMENTED_KEY}
          >
            <SegmentedAnalysis
              loadingDimensions={loadingDimensions}
              filters={filters}
              selectedDates={selectedDates}
              periodDetail={periodDetail}
              optionsSelectDimension={dimensionsListData}
              periodId={periodId}
              reportId={reportId}
              consolidationListForecast={consolidationListForecast}
              consolidationListBudget={consolidationListBudget}
              activeOptionPeriodTable={activeOptionPeriodTable}
              functionalCurrencyData={functionalCurrencyData}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('REPORT_TAB_VERTICAL_ANALYSIS')}
            key={REPORT.REPORTS_KEYS.VERTICAL_KEY}
          >
            <VerticalAnalysis
              filters={filters}
              selectedDates={selectedDates}
              periodDetail={periodDetail}
              periodId={periodId}
              reportId={reportId}
              dimensionsList={dimensionsList}
              consolidationListForecast={consolidationListForecast}
              consolidationListBudget={consolidationListBudget}
              activeOptionPeriodTable={activeOptionPeriodTable}
              consolidatedPredefined={consolidatedPredefined}
              functionalCurrencyData={functionalCurrencyData}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('REPORT_TAB_SEGREGATED_ANALYSIS')}
            key={REPORT.REPORTS_KEYS.SEGREGATED_KEY}
          >
            <SegregatedAnalysis
              reportFunctionalCurrencyData={functionalCurrencyData}
              dimensionsList={dimensionsList}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  dimensionsValuesList: planning.selectors.getDimensionsValuesList(state),
});

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchDimensionsValuesList: planning.actions.fetchDimensionsValuesList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSegmentedHome);
