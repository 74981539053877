import { Button, message, notification } from 'antd';
import { ButtonActionBar } from 'modules/core/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import reports from 'modules/reports';
import moment from 'moment';
import _ from 'lodash';

const ExtraActions = ({ downloadFlatxls, periodDetail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onDownload = () => {
    setIsLoading(true);
    downloadFlatxls({
      period_date: !_.isEmpty(periodDetail)
        ? moment(periodDetail.start_date).format('DD-MM-YYYY')
        : '',
    })
      .then(() => {
        message.success(t('FEEDBACK_DOWNLOAD_FILE_SUCCESS'));
        setIsLoading(false);
      })
      .catch(() => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DOWNLOAD_FILE_ERROR'),
          duration: 0,
        });
        setIsLoading(false);
      });
  };

  return (
    <ButtonActionBar>
      <Button type="primary" loading={isLoading} onClick={() => onDownload()}>
        {t('LABEL_FLAT_DOWNLOAD')}
      </Button>
    </ButtonActionBar>
  );
};

const mapDispatchToProps = {
  downloadFlatxls: reports.actions.downloadFlatxls,
};

export default connect(null, mapDispatchToProps)(ExtraActions);
