import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Col,
  Row,
  Input,
  Modal,
  Alert,
  Space,
  Button,
  message,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { isUserAllowed } from 'modules/core/utils';
import { REPORT } from 'modules/core/constants';
import reports from 'modules/reports';

const FormItem = Form.Item;

const CreateReportModal = ({
  rolesAvailables = [],
  createReport,
  onFetchReportList,
  reportCreationType = REPORT.REPORTS_KEYS.PNL_KEY,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const userCanCreate = isUserAllowed(rolesAvailables);

  const onCancel = () => {
    form.resetFields();
    setShowModal(false);
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true);
        createReport({ name: values.name, type: reportCreationType })
          .then(() => {
            onFetchReportList().then(() => {
              message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
              setIsLoading(false);
              onCancel();
            });
          })
          .catch(() => {
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: t('FEEDBACK_CREATION_FAIL'),
              duration: 0,
            });
            setIsLoading(false);
            onCancel();
          });
      })
      .catch(e => {});
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setShowModal(true)}
        disabled={!userCanCreate}
      >
        {t('ACTION_CREATE_NEW')}
      </Button>
      <Modal
        title={t('CONFIG_REPORT_CREATE_ACTION')}
        visible={showModal}
        onCancel={!isLoading ? onCancel : null}
        destroyOnClose={true}
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={onConfirm}
        confirmLoading={isLoading}
        okButtonProps={{ disabled: isLoading }}
      >
        <Space direction="vertical" size="large">
          <Alert
            showIcon
            closable
            type="info"
            message={t('CONFIG_REPORT_CREATE_ACTION')}
            description={t('CONFIG_REPORT_CREATE_MODAL_TEXT')}
          />
          <Form form={form} layout="vertical">
            <Row gutter={[16, 6]}>
              <Col>
                <FormItem
                  label={t('CONFIG_REPORT_CREATE_MODAL_NAME_LABEL')}
                  name="name"
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <Input
                    placeholder={t(
                      'CONFIG_REPORT_CREATE_MODAL_NAME_PLACEHOLDER'
                    )}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Space>
      </Modal>
    </>
  );
};

const mapDispatchToProps = {
  createReport: reports.actions.createReport,
};

export default connect(null, mapDispatchToProps)(CreateReportModal);
