import React, { useState } from 'react';
import { Modal, Button, Radio, Space } from 'antd';
import { Typography } from 'modules/core/components';
import { useTranslation } from 'react-i18next';

const CreatePlanAccountModal = ({
  visible,
  defaultValue,
  options = [],
  onCancel,
  onConfirm,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const { t } = useTranslation();

  return (
    <Modal
      title={t('ACTION_CREATE_NEW')}
      visible={visible}
      centered
      onCancel={onCancel}
      closable={true}
      footer={
        <>
          <Button onClick={onCancel}>{t('ACTION_CANCEL')}</Button>
          <Button type="primary" onClick={() => onConfirm(selectedOption)}>
            {t('ACTION_NEXT')}
          </Button>
        </>
      }
    >
      <Space size="middle" direction="vertical">
        <Typography.Body level={2}>
          {t('CONFIG_ACCOUNTS_CHOOSE_OPTION_MODAL_TITLE')}
        </Typography.Body>
        <Radio.Group
          onChange={e => setSelectedOption(e.target.value)}
          value={selectedOption}
        >
          {options.map((option, index) => (
            <Radio key={index} value={option.id}>
              {option.name}
            </Radio>
          ))}
        </Radio.Group>
      </Space>
    </Modal>
  );
};

export default CreatePlanAccountModal;
