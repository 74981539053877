import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePagination, useFunctionalCurrency } from 'modules/core/customHooks';
import _ from 'lodash';
import numeral from 'numeral';
import {
  Spin,
  Card,
  Button,
  Row,
  Col,
  Space,
  Table,
  Tag,
  Alert,
  Tooltip,
} from 'antd';
import {
  CloseCircleOutlined,
  CopyOutlined,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  DropdownMenu,
  RangePicker,
  EmptyScreen,
  KPIValue,
  Typography,
} from 'modules/core/components';
import { isUserAllowed, renderPrefix } from 'modules/core/utils';
import { ROLES, CONCEPTS } from 'modules/core/constants';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import initialColumns from './columns';
import adjustments from 'modules/adjustments';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import control from 'modules/control';

const {
  CONTROL__ADJUSTMENTS__CREATE,
  CONTROL__ADJUSTMENTS__DUPLICATE,
  CONTROL__ADJUSTMENTS__DELETE,
} = ROLES;

const AdjustmentsList = ({
  period: { periodId, periodName },
  adjustmentKPIS,
  adjustmentList: { count, values },
  fetchAdjustmentKPIS,
  fetchAdjustmentTable,
  downloadAdjustmentsXLS,
  downloadSingleAdjustmentsXLS,
  duplicateAdjustment,
  approveDates,
  fetchApproveDates,
  exitAdjustmentCreation,
  periodDetail,
}) => {
  const [isKpisLoading, setIsKpisLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [selectedDates, setSelectedDates] = useState({});
  const functionalCurrencyData = useFunctionalCurrency();
  const [tablePagination, setTablePagination] = usePagination();
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    fetchApproveDates(periodId, { concept_type: CONCEPTS.TYPE.ECONOMIC_KEY });
    return () => {
      exitAdjustmentCreation();
    };
  }, [fetchApproveDates, exitAdjustmentCreation, periodId]);

  useEffect(() => {
    fetchAdjustmentKPIS(periodId, { ...selectedDates }).then(() =>
      setIsKpisLoading(false)
    );
  }, [fetchAdjustmentKPIS, periodId, selectedDates]);

  useEffect(() => {
    setIsTableLoading(true);
    fetchAdjustmentTable(periodId, {
      ...tablePagination,
      ...selectedDates,
    }).then(() => setIsTableLoading(false));
  }, [fetchAdjustmentTable, tablePagination, periodId, selectedDates]);

  const userCanCreate = isUserAllowed(CONTROL__ADJUSTMENTS__CREATE);

  const renderTopAlert = () => (
    <Alert
      showIcon
      closable
      type="warning"
      message={t('ADJUSTMENT_EMPTY_STATE_ALERT_TITLE')}
      description={t('ADJUSTMENT_EMPTY_STATE_ALERT_TEXT')}
    />
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('ADJUSTMENT_EMPTY_STATE_TITLE')}
      description={t('ADJUSTMENT_EMPTY_STATE_TEXT')}
      footer={
        userCanCreate && (
          <Tooltip
            title={
              approveDates.length === 0
                ? t('ADJUSTMENT_EMPTY_STATE_APPROVE_DATA_TOOLTIP')
                : ''
            }
          >
            <Button
              type="primary"
              onClick={() =>
                history.push(`/seguimiento/${periodId}/economico/ajustes`)
              }
              disabled={approveDates.length === 0}
            >
              {t('ADJUSTMENT_CREATE_ACTION')}
            </Button>
          </Tooltip>
        )
      }
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      {userCanCreate && (
        <Button
          type="primary"
          onClick={() =>
            history.push(`/seguimiento/${periodId}/economico/ajustes/`)
          }
        >
          {t('ADJUSTMENT_CREATE_ACTION')}
        </Button>
      )}
    </ButtonActionBar>
  );

  const renderKPIs = () => (
    <Row gutter={[24, 0]} style={{ paddingLeft: 5 }}>
      <>
        {isKpisLoading &&
          [1, 2, 3].map(index => (
            <Col span={8} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isKpisLoading &&
          adjustmentKPIS.map((kpi, index) => (
            <Col span={8} key={index}>
              <Card>
                <Space direction="vertical">
                  <KPIValue
                    size="medium"
                    prefix={
                      kpi.prefix
                        ? renderPrefix(functionalCurrencyData, kpi.prefix)
                        : ''
                    }
                    fullValue={numeral(kpi.amount).format('0,0.00')}
                    value={numeral(kpi.amount).format('0[.]0a')}
                  />
                  <Typography.Body level={3} type="secondary">
                    {kpi.title}
                  </Typography.Body>
                </Space>
              </Card>
            </Col>
          ))}
      </>
    </Row>
  );

  const renderTableActions = () => (
    <ButtonActionBar>
      <Button
        type="text"
        shape="circle"
        title={t('ACTION_DOWNLOAD_TABLE')}
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={() =>
          downloadAdjustmentsXLS(
            t('ADJUSTMENT_DATA_TABLE_FILE_NAME', { periodName: periodName })
          )
        }
      />
    </ButtonActionBar>
  );

  const renderAdjustmentTable = () => {
    const columns = [
      ...initialColumns(t, renderPrefix(functionalCurrencyData)),
      {
        title: t('FIELD_ACTIONS'),
        dataIndex: 'id',
        align: 'right',
        width: 200,
        render: (record, row) => (
          <Space size="middle">
            {row.canceled ? <Tag>{t('STATE_TAG_CANCELED')}</Tag> : ''}
            <Button
              type="text"
              shape="circle"
              onClick={() =>
                history.push(
                  `/seguimiento/${periodId}/economico/ajustes/${record}`
                )
              }
              icon={<Typography.Icon icon={EyeOutlined} />}
              title={t('ADJUSTMENT_VIEW_ACTION_TITLE')}
            />
            <DropdownMenu
              title={t('ACTION_MORE')}
              menu={_.compact([
                {
                  title: t('ACTION_DUPLICATE'),
                  icon: <CopyOutlined />,
                  onClick: () =>
                    duplicateAdjustment(record).then(() =>
                      history.push(
                        `/seguimiento/${periodId}/economico/ajustes?duplicatedId=${record}`
                      )
                    ),
                  disabled: !isUserAllowed(CONTROL__ADJUSTMENTS__DUPLICATE),
                },
                ...[
                  !row.canceled && {
                    title: t('ACTION_ANULATE'),
                    icon: <CloseCircleOutlined />,
                    onClick: () =>
                      history.push(
                        `/seguimiento/${periodId}/economico/ajustes/${record}`
                      ),
                    disabled: !isUserAllowed(CONTROL__ADJUSTMENTS__DELETE),
                  },
                ],
                {
                  title: t('ACTION_DOWNLOAD'),
                  icon: <DownloadOutlined />,
                  onClick: () =>
                    downloadSingleAdjustmentsXLS(
                      record,
                      t('ADJUSTMENT_DATA_TYPE_FILE_NAME', {
                        type: row.type,
                        periodName: periodName,
                      })
                    ),
                },
              ])}
            />
          </Space>
        ),
      },
    ];

    return (
      <Table
        rowKey="id"
        size="small"
        bordered
        loading={isTableLoading}
        onChange={setTablePagination}
        columns={columns}
        dataSource={values}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total: count,
          size: 'small',
          showSizeChanger: false,
        }}
      />
    );
  };

  return (
    <>
      <Spin spinning={isTableLoading && isKpisLoading} size="large" />
      {(!isKpisLoading || !isTableLoading) && (
        <>
          {count <= 0 && _.isEmpty(selectedDates) && (
            <Row gutter={[24, 24]}>
              {approveDates.length === 0 && (
                <Col span={24}> {renderTopAlert()}</Col>
              )}
              <Col span={24}>{renderNoDataScreen()}</Col>
            </Row>
          )}
          {(count > 0 || (count === 0 && !_.isEmpty(selectedDates))) && (
            <Row gutter={[8, 24]}>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>
                <RangePicker
                  onSetRange={setSelectedDates}
                  periodDate={{
                    startDate: periodDetail?.start_date,
                    endDate: periodDetail?.end_date,
                  }}
                  defaultPickerValue={
                    !_.isEmpty(periodDetail)
                      ? [
                          moment(periodDetail?.start_date),
                          moment(periodDetail?.end_date),
                        ]
                      : null
                  }
                  defaultValue={
                    !_.isEmpty(selectedDates) && [
                      moment(selectedDates['start-date'], 'YYYY-MM-01'),
                      moment(selectedDates['end-date'], 'YYYY-MM-01'),
                    ]
                  }
                />
              </Col>
              <Col span={24}>{renderKPIs()}</Col>
              <Col span={24}>{renderTableActions()}</Col>
              <Col span={24}>{renderAdjustmentTable()}</Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  adjustmentKPIS: adjustments.selectors.getAdjustmentKPIS(state),
  adjustmentList: adjustments.selectors.getAdjustmentList(state),
  approveDates: control.selectors.getApproveDates(state),
});

const mapDispatchToProps = {
  fetchAdjustmentKPIS: adjustments.actions.fetchAdjustmentKPIS,
  fetchAdjustmentTable: adjustments.actions.fetchAdjustmentTable,
  downloadAdjustmentsXLS: adjustments.actions.downloadAdjustmentsXLS,
  downloadSingleAdjustmentsXLS:
    adjustments.actions.downloadSingleAdjustmentsXLS,
  duplicateAdjustment: adjustments.actions.duplicateAdjustment,
  fetchApproveDates: control.actions.fetchApproveDates,
  exitAdjustmentCreation: adjustments.actions.exitAdjustmentCreation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentsList);
