import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, notification, Button } from 'antd';
import {
  LoginForm,
  ConfirmPasswordForm,
  EmailConfirmForm,
} from 'modules/login/components';
import login from 'modules/login';
import { useTranslation } from 'react-i18next';
import {
  GA,
  renderLoginAndResetFormTitle,
  renderLoginWelcome,
} from 'modules/core/utils';

import './Login.scss';

const Login = ({ history, onLogin, onLogout, whoAmI, confirmPassword }) => {
  const [isLogging, setIsLogging] = useState(false);
  const [showConfirmPasswordForm, setShowConfirmPasswordForm] = useState(false);
  const [showEmailConfirmForm, setShowEmailConfirmForm] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onSubmitLogin = values => {
    setIsLogging(true);
    onLogin({ username: values.email, password: values.password })
      .then(() =>
        whoAmI().then(response => {
          if (!response.payload.data.password_confirmed) {
            form.resetFields();
            setShowConfirmPasswordForm(true);
            setIsLogging(false);
          } else {
            GA.init(response.payload.data.id);
            GA.set({
              userId: response.payload.data.id,
              society: response.payload.data.society,
            });
            GA.startSession();
            GA.event(GA.ACTIONS.USER_LOGIN);
            history.push('/');
          }
        })
      )
      .catch(error => {
        setIsLogging(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('LOGIN_FEEDBACK_ERROR'),
        });
      });
  };

  const onSubmitChange = values => {
    setIsLogging(true);
    confirmPassword(values)
      .then(() => onLogout().then(() => history.push('/login')))
      .catch(error => {
        setIsLogging(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error,
        });
      });
  };

  const renderConfirmOrLoginForm = () => (
    <>
      {renderLoginAndResetFormTitle({
        title: showConfirmPasswordForm
          ? 'LOGIN_FORM_TITLE_CREATE_PASSWORD'
          : 'LOGIN_FORM_TITLE',
        level: 6,
        align: 'center',
      })}
      {showConfirmPasswordForm ? (
        <ConfirmPasswordForm
          form={form}
          onFinish={onSubmitChange}
          loading={isLogging}
        />
      ) : (
        <LoginForm form={form} onFinish={onSubmitLogin} loading={isLogging} />
      )}
    </>
  );

  const renderEmailConfirmForm = () => (
    <>
      {renderLoginAndResetFormTitle({
        title: 'LOGIN_FORM_RESET_PASSWORD_TITLE',
        level: 6,
        align: 'center',
      })}
      <EmailConfirmForm />
    </>
  );

  const renderForgotPasswordButton = () => (
    <Button
      onClick={() => setShowEmailConfirmForm(!showEmailConfirmForm)}
      type="link"
    >
      {!showEmailConfirmForm
        ? t('LOGIN_FORGOT_PASSWORD_ACTION')
        : t('LOGIN_GO_BACK_SESSION_ACTION')}
    </Button>
  );

  return (
    <div className="plika-login-container">
      <Row>
        <Col span={10} offset={1} className="brand-hero">
          {renderLoginWelcome()}
        </Col>
        <Col span={10} offset={3} className="login-form-container">
          <div
            className={
              showConfirmPasswordForm ? 'signin-sector' : 'login-sector'
            }
          >
            {!showEmailConfirmForm
              ? renderConfirmOrLoginForm()
              : renderEmailConfirmForm()}
            {!showConfirmPasswordForm && renderForgotPasswordButton()}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = {
  onLogin: login.actions.onLogin,
  onLogout: login.actions.onLogout,
  whoAmI: login.actions.whoAmI,
  confirmPassword: login.actions.confirmPassword,
};

export default connect(null, mapDispatchToProps)(Login);
