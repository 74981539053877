import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Input, message } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import configuration from 'modules/configuration';

const KEY_CREATE = 'create';

const ActionRoleModal = ({
  visible,
  onCancel,
  onSuccess,
  createRole,
  duplicateRole,
  initialValues = {},
  actionType,
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }
  }, [visible, form, initialValues]);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onCreate = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoadingModal(true);
        createRole(values)
          .then(() => {
            onSuccess();
            setIsLoadingModal(false);
            message.success(t('FEEDBACK_CREATE_SUCCESS'));
            onClose();
          })
          .catch(() => {
            setIsLoadingModal(false);
            message.error(t('FEEDBACK_CREATION_FAIL'));
            onClose();
          });
      })
      .catch(e => {});
  };

  const onDuplicate = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoadingModal(true);
        const data = {
          id_rol: initialValues.id_rol,
          new_name: values.name + ' - ' + t('CONFIG_ROLES_LABEL_COPY'),
          new_code: values.code + ' - ' + t('CONFIG_ROLES_LABEL_COPY'),
        };
        duplicateRole(data)
          .then(() => {
            onSuccess();
            setIsLoadingModal(false);
            message.success(t('FEEDBACK_CREATE_SUCCESS'));
            onClose();
          })
          .catch(() => {
            setIsLoadingModal(false);
            message.error(t('FEEDBACK_CREATION_FAIL'));
            onClose();
          });
      })
      .catch(e => {});
  };

  return (
    <Modal
      title={
        actionType === KEY_CREATE
          ? t('CONFIG_ROLES_MODAL_CREATE_ROLE_TITLE')
          : t('CONFIG_ROLES_MODAL_DUPLICATE_ROLE_TITLE')
      }
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      centered
      okText={
        actionType === KEY_CREATE
          ? t('CONFIG_ROLES_CREATE_ACTION')
          : t('CONFIG_ROLES_DUPLICATE_ACTION')
      }
      cancelText={t('ACTION_CANCEL')}
      onOk={actionType === KEY_CREATE ? () => onCreate() : () => onDuplicate()}
      onCancel={onClose}
      width={570}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical" initialValues={{ users: [''] }}>
        <Row gutter={24} type="flex" justify="center">
          <Col span={12}>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input
                placeholder={t('CONFIG_ROLES_ACTION_MODAL_NAME_PLACEHOLDER')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('FIELD_CODE')}
              name="code"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input
                placeholder={t('CONFIG_ROLES_ACTION_MODAL_CODE_PLACEHOLDER')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  createRole: configuration.actions.createRole,
  duplicateRole: configuration.actions.duplicateRole,
};

export default connect(null, mapDispatchToProps)(ActionRoleModal);
