// TODO: APLICAR i18n
const TYPES = {
  extension: 'Ampliación',
  reduction: 'Reducción',
  reclassification: 'Reclasificación',
};

const ADJUSTMENTS = { TYPES };

export default ADJUSTMENTS;
