import {
  SyncOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import imageOdoo from 'assets/images/integraciones/Odoo_logo.svg';
import imageQuickBooks from 'assets/images/integraciones/Intuit_QuickBooks_logo.svg';

const INTEGRATION_KEY = 'integration';
const MANUAL_KEY = 'manual';
const DATA_KEY = 'data';

const KEYS = {
  INTEGRATION_KEY: INTEGRATION_KEY,
  MANUAL_KEY: MANUAL_KEY,
  DATA_KEY: DATA_KEY,
};

const STATUS = {
  success: {
    color: 'success',
    title: 'STATE_TAG_FINISHED_SUCCESS',
    icon: <CheckCircleOutlined />,
    message: 'MESSAGE_PROCESS_STATUS_SUCCESS',
  },
  pending: {
    color: 'default',
    title: 'STATE_TAG_PENDING',
    icon: <ClockCircleOutlined />,
    message: 'MESSAGE_PROCESS_STATUS_PENDING',
  },
  processing: {
    color: 'processing',
    title: 'STATE_TAG_PROCESSING',
    icon: <SyncOutlined />,
    message: 'MESSAGE_PROCESS_STATUS_PROCESSING',
  },
  error: {
    color: 'error',
    title: 'LOADING_STATUS_ERROR',
    icon: <CloseCircleOutlined />,
  },
  disconect: {
    color: 'default',
    title: 'STATE_TAG_DESCONNECTED',
    icon: <MinusCircleOutlined />,
  },
};

const DATA = {
  Odoo: { image: imageOdoo },
  Quickbook: {
    image: imageQuickBooks,
    url: '', //NOTE: URL DE QUICKBOOK
  },
};

const INTEGRATIONS = { KEYS, STATUS, DATA };

export default INTEGRATIONS;
