import { useState } from 'react';

const ORDERS_SYMBOL = {
  ascend: 'A',
  descend: 'D',
};

const initialState = {};

// A custom hook that manage Antd table sorter
function useSorter() {
  const [sorter, setSorter] = useState(initialState);

  function handleSorter({ field, order }) {
    if (order) {
      setSorter({ order_by: `${ORDERS_SYMBOL[order]}-${field}` });
    } else {
      setSorter(initialState);
    }
  }

  return [sorter, handleSorter];
}

export default useSorter;
