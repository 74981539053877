import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Spin, Row, Col, Space, Alert, Popover } from 'antd';
import {
  ButtonActionBar,
  EmptyScreen,
  Typography,
} from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { PeriodModal, PeriodsList } from './components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import planning from 'modules/planning';
import configuration from 'modules/configuration';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';

import './Periods.scss';

const { PLANNING__EXPENSES__CREATE, PLANNING__SALES__CREATE } = ROLES;
const CONFIG_MODULE_URL = '/configuracion/';

const Periods = ({
  periods,
  fetchPeriods,
  fetchDimensionsList,
  fetchDimensionValues,
  hasDataConfig,
}) => {
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    setIsScreenLoading(true);
    fetchPeriods().then(() => setIsScreenLoading(false));
  }, [fetchPeriods]);

  const canUserCreate = isUserAllowed([
    PLANNING__EXPENSES__CREATE,
    PLANNING__SALES__CREATE,
  ]);

  useEffect(() => {
    setIsConfigLoading(true);
    Promise.all([
      fetchDimensionValues({
        flat: 'True',
        dimension_name: 'Nivel',
        writeable: 'True',
      }),
      fetchDimensionsList({ flat: 'True', writeable: 'True' }),
    ])
      .then(() => setIsConfigLoading(false))
      .catch(() => setIsConfigLoading(false));
  }, [fetchDimensionsList, fetchDimensionValues]);

  const renderSubheader = () =>
    canUserCreate && (
      <ButtonActionBar>
        <Button type="primary" onClick={() => setShowCreationModal(true)}>
          {t('PERIOD_CREATE_ACTION')}
        </Button>
      </ButtonActionBar>
    );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PERIOD_TITLE')}
      description={t('PERIOD_EMPTY_STATE_TEXT')}
      footer={
        canUserCreate && (
          <Popover content={renderGoToConfigButton({ url: CONFIG_MODULE_URL })}>
            <Button type="primary" onClick={() => setShowCreationModal(true)}>
              {t('ACTION_START')}
            </Button>
          </Popover>
        )
      }
    />
  );

  const renderGoToConfigButton = ({ url }) => {
    return (
      <Space direction="vertical" size="small" align="center">
        {t('PERIOD_ONBOARDING_TOOLTIP_INFO1')}
        {t('PERIOD_ONBOARDING_TOOLTIP_INFO2')}
        <Button type="link" onClick={() => history.push(url)}>
          {t('ACTION_GO_TO_CONFIGURE')}
        </Button>
      </Space>
    );
  };

  const renderAlertInfo = () => (
    <Alert
      message={t('PERIOD_ALERT_TITLE')}
      description={t('PERIOD_ALERT_DESCRIPTION')}
      type="info"
      showIcon
      closable
    />
  );

  const refresData = () => {
    setIsScreenLoading(true);
    fetchPeriods().then(() => setIsScreenLoading(false));
  };

  return (
    <SiderLayout>
      <div className="periods-container">
        <Spin spinning={isScreenLoading || isConfigLoading} size="large" />
        {!isScreenLoading && periods.length <= 0 && renderNoDataScreen()}
        {!isScreenLoading && !isConfigLoading && periods.length > 0 && (
          <Row gutter={[24, 24]}>
            {!isConfigLoading && !hasDataConfig && (
              <Col span={24}>{renderAlertInfo()}</Col>
            )}
            <Col span={24}>
              <Typography.Headline level={5}>
                {t('PERIOD_TITLE')}
              </Typography.Headline>
            </Col>
            <Col span={24}>{renderSubheader()}</Col>
            <Col span={24}>
              <PeriodsList
                dataSource={periods}
                onFetchPeriods={refresData}
                disabled={!isConfigLoading && !hasDataConfig}
              />
            </Col>
          </Row>
        )}
        <PeriodModal
          visible={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          onFetchPeriods={refresData}
        />
      </div>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  periods: planning.selectors.getPeriods(state),
  hasDataConfig: configuration.selectors.getHasDataConfig(state),
});

const mapDispatchToProps = {
  fetchPeriods: planning.actions.fetchPeriods,
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Periods);
