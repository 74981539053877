import { SelectType } from 'modules/core/components';
import { useTranslation } from 'react-i18next';

const ValuesSelect = ({ loading, options, onBlur, onChange, disabled }) => {
  const { t } = useTranslation();
  return (
    <SelectType.Multiple
      placeholder={t('ACTION_SELECT_VALUES')}
      loading={loading}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      style={{ width: 200 }}
    />
  );
};

export default ValuesSelect;
