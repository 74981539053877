import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const AdjustmentCreationExitModal = ({ visible, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('ACTION_EXIT')}
      visible={visible}
      closable={true}
      cancelText={t('ACTION_RETURN')}
      onCancel={onClose}
      okText={t('ACTION_EXIT')}
      onOk={onConfirm}
      destroyOnClose={true}
    >
      {t('EXIT_MODAL_DESCRIPTION')}
    </Modal>
  );
};

export default AdjustmentCreationExitModal;
