import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { RightCircleOutlined } from '@ant-design/icons';
import {
  List,
  Card,
  Button,
  Row,
  Col,
  Tooltip,
  Spin,
  Typography as TypographyAnt,
  Input,
  Space,
} from 'antd';
import {
  ButtonActionBar,
  Typography,
  CreateOtherReportModal,
  EmptyScreen,
} from 'modules/core/components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES, REPORT } from 'modules/core/constants';
import reports from 'modules/reports';
import configuration from 'modules/configuration';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';

const { ANALYSIS__CUSTOM_REPORTS__CREATE } = ROLES;

const { Text } = TypographyAnt;
const { Search } = Input;

// TODO: A futuro se podria pedir a back que venga toda la info en el endpoint fetchCustomReportList,
// en vez de completarla desde front con fetchCustomReportListConfig
const ListOther = ({
  customReportsList,
  customReportsListConfig,
  fetchCustomReportList,
  fetchCustomReportListConfig,
  fetchReportList,
  reportList: { reportData },
}) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const { t } = useTranslation();
  let history = useHistory();
  let { periodId } = useParams();

  useEffect(() => {
    setIsDataLoading(true);
    Promise.all([
      fetchCustomReportList({
        period_id: periodId,
        type: REPORT.REPORTS_KEYS.PNL_KEY,
      }),
      fetchCustomReportListConfig({ type: REPORT.REPORTS_KEYS.PNL_KEY }),
      fetchReportList({ period_id: periodId }),
    ]).then(() => setIsDataLoading(false));
  }, [
    periodId,
    fetchCustomReportList,
    fetchCustomReportListConfig,
    fetchReportList,
  ]);

  const canUserCreate = isUserAllowed(ANALYSIS__CUSTOM_REPORTS__CREATE);

  const functionSearch = (values, searchValue) => {
    const results = [];
    values.forEach(value => {
      if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
        results.push(value);
      }
    });
    return results;
  };

  const renderCreateOtherReportModal = () => (
    <CreateOtherReportModal
      reportData={reportData.filter(
        report => report.type === REPORT.REPORTS_KEYS.PNL_KEY
      )}
      rolesAvailables={[ANALYSIS__CUSTOM_REPORTS__CREATE]}
      onFetchOtherReportList={() =>
        fetchCustomReportList({
          period_id: periodId,
          type: REPORT.REPORTS_KEYS.PNL_KEY,
        })
      }
      onFetchCustomReportConfig={() =>
        fetchCustomReportListConfig({ type: REPORT.REPORTS_KEYS.PNL_KEY })
      }
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        {renderCreateOtherReportModal()}
      </Space>
    </ButtonActionBar>
  );

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_REPORTS_EMPTY_STATE_TITLE')}
      footer={renderCreateOtherReportModal()}
    />
  );

  const renderCardActions = ({ id, has_data, has_config }) => (
    <ButtonActionBar position="start">
      <Tooltip
        title={!has_config && !canUserCreate ? t('ALERT_NOT_ALLOWED') : ''}
      >
        <Button
          type="link"
          disabled={
            (!has_config && !canUserCreate) || (has_config && !has_data)
          }
          onClick={() =>
            history.push(
              has_config
                ? `/analisis/${periodId}/reportesplika/economicos/personalizados/${id}`
                : `/configuracion/misdatos/reportes/economicos/personalizados/${id}`
            )
          }
        >
          {has_config ? t('ACTION_ENTER') : t('ACTION_CONFIGURATE')}
          <RightCircleOutlined />
        </Button>
      </Tooltip>
    </ButtonActionBar>
  );

  const renderCardBody = ({ title, content }) => (
    <Col span={24}>
      <Typography.Body level={2}>
        <b> {t(title)}:</b> {content}
      </Typography.Body>
    </Col>
  );

  const renderReportsList = () => (
    <List
      loading={isDataLoading}
      style={{ paddingLeft: 6 }}
      grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 3, xxl: 3 }}
      dataSource={
        searchValue === null
          ? customReportsList
          : functionSearch(customReportsList, searchValue)
      }
      renderItem={value => {
        const { id, name, has_data, has_config } = value;
        const reportConfig = customReportsListConfig.data.find(
          el => el.id === id
        );

        return (
          <List.Item>
            <Card
              className="other-report-list-card"
              headStyle={{ height: 56 }}
              title={
                <Text title={name} style={{ width: 250 }} ellipsis={true}>
                  {name}
                </Text>
              }
              actions={[renderCardActions({ id, has_data, has_config })]}
            >
              <Row>
                {renderCardBody({
                  title: 'FIELD_LINE_REPORT',
                  content: reportConfig?.report_row?.name,
                })}
                {renderCardBody({
                  title: 'FIELD_DIMENSION',
                  content: reportConfig?.dimension?.name,
                })}
              </Row>
            </Card>
          </List.Item>
        );
      }}
    />
  );

  return (
    <Spin spinning={isDataLoading}>
      {customReportsList.length === 0 && !isDataLoading && renderEmptyScreen()}
      {customReportsList.length !== 0 && !isDataLoading && (
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderUserActions()}</Col>
          <Col span={24}>{renderReportsList()}</Col>
        </Row>
      )}
    </Spin>
  );
};

const mapStateToProps = state => ({
  customReportsList: reports.selectors.getCustomReportList(state),
  customReportsListConfig: configuration.selectors.getOtherReportsList(state),
  reportList: reports.selectors.getReportList(state),
});

const mapDispatchToProps = {
  fetchCustomReportList: reports.actions.fetchCustomReportList,
  fetchCustomReportListConfig: configuration.actions.fetchOtherReportsList,
  fetchReportList: reports.actions.fetchReportList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListOther);
