import React from 'react';
import { Space, Input, Typography } from 'antd';
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import { dateMMMMYYYY } from 'modules/core/utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { TextArea } = Input;

const DistributionDetailInfo = ({
  date,
  account,
  comment,
  commentAnulation,
  canceled,
}) => {
  const { t } = useTranslation();

  return (
    <Space align="top" size="large">
      <Space direction="vertical">
        <Text>
          <Text type="danger">*</Text> {t('DISTRIBUTION_SELECT_DATE_LABEL')}
        </Text>
        <Input
          disabled
          value={dateMMMMYYYY(date, 'YYYY-MM-DD')}
          suffix={<CalendarOutlined />}
          style={{ width: 200 }}
        />
      </Space>
      <Space direction="vertical">
        <Text>
          <Text type="danger">*</Text>
          {t('DISTRIBUTION_ACCOUNT_FIELD')}
        </Text>
        <Input
          disabled
          value={account}
          style={{ width: 200 }}
          suffix={<DownOutlined />}
        />
      </Space>
      <Space direction="vertical">
        <Text>{t('CONTROL_ORIGINAL_OBS_FILED')}</Text>
        <TextArea
          value={comment}
          disabled
          autoComplete="off"
          autoSize={{ maxRows: 1 }}
          style={{ width: 350 }}
        />
      </Space>
      {canceled && (
        <Space direction="vertical">
          <Text>{t('CONTROL_CANCELED_OBS_FILED')}</Text>
          <TextArea
            value={commentAnulation}
            disabled
            autoComplete="off"
            autoSize={{ maxRows: 1 }}
            style={{ width: 350 }}
          />
        </Space>
      )}
    </Space>
  );
};

export default DistributionDetailInfo;
