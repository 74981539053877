import React, { useState } from 'react';
import { Row, Col, Form, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  renderLoginAndResetFormTitle,
  renderLoginWelcome,
  renderPasswordFormItem,
} from 'modules/core/utils';
import login from 'modules/login';
import { connect } from 'react-redux';

import './ResetPasswordForm.scss';

const FormItem = Form.Item;

const ResetPasswordForm = ({ resetPassword }) => {
  const [isReseting, setIsReseting] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  let history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const user_id = parseInt(urlParams.get('user_id'));
  const timestamp = parseInt(urlParams.get('timestamp'));
  const signature = urlParams.get('signature');

  const handleConfirm = () => {
    form.validateFields().then(values => {
      const data = {
        user_id,
        timestamp,
        signature,
        password: values.password_confirm,
      };

      setIsReseting(true);
      resetPassword(data)
        .then(() => {
          notification.success({
            message: t('FEEDBACK_VERY_GOOD'),
            key: 'close',
            description: (
              <>
                <span>
                  {t('LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_1')}
                </span>
                <span
                  className="link-go-to-login"
                  onClick={() => {
                    history.push(`/login`);
                    notification.close('close');
                  }}
                >
                  {t('LOGIN')}
                </span>
                <span>
                  {t('LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_2')}
                </span>
              </>
            ),
            duration: 0,
          });
          setIsReseting(false);
        })
        .catch(() => {
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_CONFIRM_ERROR_DESCRIPTION'),
            duration: 0,
          });
          setIsReseting(false);
        });
    });
  };

  return (
    <div className="plika-login-container">
      <Row>
        <Col span={10} offset={1} className="brand-hero">
          {renderLoginWelcome()}
        </Col>
        <Col span={10} offset={3} className="login-form-container ">
          <div className="login-sector">
            {renderLoginAndResetFormTitle({
              title: 'LOGIN_FORM_RESET_PASSWORD_TITLE',
              level: 6,
              align: 'center',
            })}
            <Form
              form={form}
              layout="vertical"
              className="password-reset-form"
              hideRequiredMark={true}
            >
              <div className="password-reset-form-inputs">
                {renderPasswordFormItem({ size: 'large' })}
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isReseting}
                    size="large"
                    block
                    onClick={() => handleConfirm()}
                  >
                    {t('ACTION_CONFIRM')}
                  </Button>
                </FormItem>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = {
  resetPassword: login.actions.resetPassword,
};

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
