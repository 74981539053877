import React, { useState, useEffect } from 'react';
import { Select, Space, Tooltip, Col, Row, Empty } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VerticalComparisonTable, VerticalSegmentedTable } from './components';
import _ from 'lodash';
import { SelectType } from 'modules/core/components';
import { ACCES_USERS, PROJECTION, REPORT } from 'modules/core/constants';
import configuration from 'modules/configuration';
import reports from 'modules/reports';
import login from 'modules/login';
import { generateSelectOptions } from 'modules/core/utils';

import './VerticalAnalysis.scss';

const isSegmentedOption = option => {
  return option === REPORT.REPORTS_KEYS.SEGMENTED_KEY;
};

const SELECT_OPTIONS = [
  {
    id: REPORT.REPORTS_KEYS.COMPARISON_KEY,
    name: 'FIELD_COMPARISONS',
  },
  {
    id: REPORT.REPORTS_KEYS.SEGMENTED_KEY,
    name: 'FIELD_SEGMENTED',
  },
];

//NOTE: selector de linea P&L provisorio, cuando se implemente cambio, borrarlo.
const VerticalAnalysis = ({
  dimensionValues,
  fetchDimensionValues,
  filters,
  selectedDates,
  periodDetail,
  fetchReportStructure,
  reportStructure,
  dimensionsList,
  periodId,
  reportId,
  fetchDimensionValuesByRow,
  dimensionValuesByRow,
  consolidationListForecast,
  consolidationListBudget,
  activeOptionPeriodTable,
  consolidatedPredefined,
  functionalCurrencyData,
  loggedUser: { email },
}) => {
  const [activeByVerticalTable, setActiveByVerticalTable] = useState(
    [...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS].some(el =>
      email.includes(el)
    )
      ? { value: PROJECTION.REAL, id: null }
      : activeOptionPeriodTable
  );
  const [optionVertical, setOptionVertical] = useState(
    REPORT.REPORTS_KEYS.COMPARISON_KEY
  );
  const [loadingDimensionsValues, setLoadingDimensionsValues] = useState(false);
  const [loadingDimensionsVertical, setLoadingDimensionsVertical] =
    useState(false);
  const [loadingReportStructure, setLoadingReportStructure] = useState(false);
  const [segmentedId, setSegmentedId] = useState(null);
  const [comparisonId, setComparisonId] = useState(null);
  const [dimensionValueId, setDimensionValueId] = useState(null);
  const [reportStructureId, setReportStructureId] = useState(null);
  const [comparisonSelections, setComparisonSelections] = useState(
    consolidatedPredefined
  );
  const { t } = useTranslation();

  useEffect(() => {
    setLoadingReportStructure(true);
    fetchReportStructure(reportId).then(() => setLoadingReportStructure(false));
  }, [fetchReportStructure, reportId]);

  useEffect(() => {
    if (reportStructureId) {
      setLoadingDimensionsVertical(true);
      fetchDimensionValuesByRow(reportId, {
        period_id: periodId,
        report_row: reportStructureId,
      }).then(() => setLoadingDimensionsVertical(false));
    }
  }, [fetchDimensionValuesByRow, periodId, reportId, reportStructureId]);

  useEffect(() => {
    comparisonId && setLoadingDimensionsValues(true);
    fetchDimensionValues({
      dimension: comparisonId,
      writeable: 'True',
    }).then(() => setLoadingDimensionsValues(false));
  }, [fetchDimensionValues, comparisonId]);

  const dataConsolidatedDefault = {
    conso_forecast_predef: periodDetail.conso_forecast_predef,
    conso_budget_predef: periodDetail.conso_budget_predef,
  };

  const dimensionsListData =
    dimensionsList.dimensions.length !== 0 &&
    dimensionsList.dimensions.filter(dimension =>
      _.keys(dimensionValuesByRow).includes(dimension.id.toString())
    );

  const handleSelect = id => {
    id.includes(PROJECTION.BUDGET)
      ? setComparisonSelections(prevState => {
          return { ...prevState, budget: id };
        })
      : setComparisonSelections(prevState => {
          return { ...prevState, last_forecast: id };
        });
  };

  const renderReportSelect = () => {
    return (
      <Select
        className="report-selectors"
        style={{ marginBottom: 10 }}
        placeholder={t('ROW_SELECT_PLACEHOLDER')}
        onChange={id => setReportStructureId(id)}
        options={generateSelectOptions({ options: reportStructure.values })}
      />
    );
  };

  const onSetOptionVertical = value => {
    setOptionVertical(value);
    if (isSegmentedOption(value)) {
      !segmentedId && setSegmentedId(dimensionsListData[0].id);
    }
  };

  const renderOptionVerticalSelect = () => {
    return (
      <Select
        className="report-selectors"
        value={optionVertical}
        onChange={value => onSetOptionVertical(value)}
        options={generateSelectOptions({
          options: SELECT_OPTIONS.map(option => {
            return { ...option, name: t(option.name) };
          }),
        })}
      />
    );
  };

  const renderId = () => {
    return !isSegmentedOption(optionVertical) ? comparisonId : segmentedId;
  };

  const onSetDimensionId = id => {
    if (!isSegmentedOption(optionVertical)) {
      id && setComparisonId(id);
      setDimensionValueId(null);
    } else {
      id && setSegmentedId(id);
    }
  };

  const renderDimensionSelect = () => {
    return (
      <Select
        className="report-selectors"
        allowClear
        key={renderId()}
        defaultValue={renderId()}
        onChange={id => onSetDimensionId(id)}
        placeholder={t('DIMENSION_PLACEHOLDER')}
        options={generateSelectOptions({ options: dimensionsListData })}
      />
    );
  };

  const renderDimensionValueSelect = () => {
    return (
      <Select
        className="report-selectors"
        loading={loadingDimensionsValues}
        value={dimensionValueId ? dimensionValueId : undefined}
        onChange={value => setDimensionValueId(value)}
        disabled={!comparisonId}
        placeholder={t('VALUE_PLACEHOLDER')}
        options={generateSelectOptions({
          options: comparisonId
            ? dimensionValues.values.length !== 0 &&
              dimensionValues.values.filter(value =>
                dimensionValuesByRow[comparisonId].includes(value.id)
              )
            : [],
        })}
      />
    );
  };

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        <Tooltip
          placement="rightTop"
          title={
            !reportStructureId
              ? t('REPORT_VERTICAL_ANALYSIS_STRUCTURE_TOOLTIP_TITLE')
              : ''
          }
        >
          {renderReportSelect()}
        </Tooltip>
      </Col>
      {!loadingReportStructure &&
        reportStructureId &&
        dimensionsListData.length !== 0 &&
        !loadingDimensionsVertical && (
          <>
            <Col span={24}>
              <Space>
                {isSegmentedOption(optionVertical) ? (
                  <SelectType.Evolution
                    activeOptionTable={activeByVerticalTable}
                    setActiveByPeriodTable={setActiveByVerticalTable}
                    dataConsolidatedDefault={dataConsolidatedDefault}
                    consolidationsPredef={{
                      budget: periodDetail.conso_budget_predef,
                      forecast: periodDetail.conso_forecast_predef,
                    }}
                    consolidationLists={{
                      budget: consolidationListBudget,
                      forecast: consolidationListForecast,
                    }}
                  />
                ) : (
                  <SelectType.Comparison
                    selections={comparisonSelections}
                    onSelectId={handleSelect}
                    consolidationsPredef={{
                      budget: periodDetail.conso_budget_predef,
                      forecast: periodDetail.conso_forecast_predef,
                    }}
                    consolidationLists={{
                      budget: consolidationListBudget,
                      forecast: consolidationListForecast,
                    }}
                  />
                )}
                {renderOptionVerticalSelect()}
                {renderDimensionSelect()}
                {!isSegmentedOption(optionVertical) && (
                  <Tooltip
                    title={
                      !comparisonId
                        ? t('REPORT_VERTICAL_COMPARISON_TOOLTIP_TITLE')
                        : ''
                    }
                  >
                    {renderDimensionValueSelect()}
                  </Tooltip>
                )}
              </Space>
            </Col>
            <Col span={24}>
              {!isSegmentedOption(optionVertical) && (
                <VerticalComparisonTable
                  loadingDimensions={loadingDimensionsVertical}
                  periodId={periodId}
                  reportId={reportId}
                  filters={filters}
                  selectedDates={selectedDates}
                  periodDetail={periodDetail}
                  comparisonId={comparisonId}
                  dimensionValueId={dimensionValueId}
                  reportStructureId={reportStructureId}
                  functionalCurrencyData={functionalCurrencyData}
                />
              )}
              {isSegmentedOption(optionVertical) && (
                <VerticalSegmentedTable
                  activeByVerticalTable={activeByVerticalTable}
                  loadingDimensions={loadingDimensionsVertical}
                  periodId={periodId}
                  reportId={reportId}
                  filters={filters}
                  selectedDates={selectedDates}
                  periodDetail={periodDetail}
                  segmentedId={segmentedId}
                  reportStructureId={reportStructureId}
                  functionalCurrencyData={functionalCurrencyData}
                />
              )}
            </Col>
          </>
        )}
      {!loadingReportStructure &&
        reportStructureId &&
        dimensionsListData.length === 0 &&
        !loadingDimensionsVertical && (
          <Col span={24}>
            <Empty
              description={t('REPORT_TAB_SEGMENTED_ANALYSIS_TOOLTIP_TITLE')}
            />
          </Col>
        )}
    </Row>
  );
};

const mapStateToProps = state => ({
  dimensionValues: configuration.selectors.getDimensionValues(state),
  reportStructure: reports.selectors.getReportStructure(state),
  dimensionValuesByRow: reports.selectors.getDimensionValuesByRow(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchReportStructure: reports.actions.fetchReportStructure,
  fetchDimensionValuesByRow: reports.actions.fetchDimensionValuesByRow,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalAnalysis);
