import Immutable from 'seamless-immutable';
import * as types from './constants';

const initialState = Immutable({
  forecasts: [],
  forecastDetail: {},
  //Todavía no están construído desde backend
  forecastKPIS: [],
  forecastCreatable: {
    creatable: true,
    message: '',
  },
  projections: [],
});

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FORECASTS_SUCCESS:
      return state.merge({
        forecasts: action.payload.data.results,
      });

    case types.CREATABLE_FORECAST_SUCCESS:
      return state.merge({
        forecastCreatable: action.payload.data,
      });

    case types.FETCH_FORECAST_DETAIL_SUCCESS:
      return state.merge({
        forecastDetail: action.payload.data.results[0],
      });

    case types.FETCH_FORECAST_PROJECTIONS_SUCCESS:
      return state.merge({
        projections: action.payload.data,
      });

    default:
      return state;
  }
};
