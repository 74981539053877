import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { MailOutlined } from '@ant-design/icons';

import './EmailConfirmForm.scss';
import login from 'modules/login';
import { connect } from 'react-redux';

const FormItem = Form.Item;

const EmailConfirmForm = ({ emailConfirm }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleConfirm = () =>
    form.validateFields().then(values => {
      setIsConfirming(true);
      emailConfirm({ login: values.email })
        .then(() => {
          notification.success({
            message: t('FEEDBACK_WELLDONE'),
            description: t('LOGIN_FORM_RESET_PASSWORD_NOTIFICATION_TEXT'),
          });
          setIsConfirming(false);
        })
        .catch(() => {
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_CONFIRM_ERROR_DESCRIPTION'),
            duration: 0,
          });
          setIsConfirming(false);
        });
    });

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className="email-confirm-pass-form"
        hideRequiredMark={true}
      >
        <div className="email-confirm-pass-form-inputs">
          <FormItem
            label={t('LOGIN_FORM_INPUT_EMAIL_LABEL')}
            name="email"
            validateTrigger="onBlur"
            rules={[
              {
                type: 'email',
                message: t('LOGIN_FORM_INPUT_EMAIL_CAPTION_FORMAT'),
              },
              {
                required: true,
                message: t('LOGIN_FORM_INPUT_EMAIL_CAPTION_EMPTY'),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t('LOGIN_FORM_INPUT_EMAIL_PLACEHOLDER')}
              suffix={<MailOutlined />}
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              loading={isConfirming}
              size="large"
              block
              onClick={() => handleConfirm()}
            >
              {t('ACTION_CONFIRM')}
            </Button>
          </FormItem>
        </div>
      </Form>
    </>
  );
};

const mapDispatchToProps = {
  emailConfirm: login.actions.emailConfirm,
};

export default connect(null, mapDispatchToProps)(EmailConfirmForm);
