import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ConfigirationHome from './ConfigurationHome/ConfigurationHome';
import { DimensionsList, DimensionDetail } from './Dimensions';
import UserDetail from './Security/UserDetail/UserDetail';
import PlanAccounts from './PlanAccounts/PlanAccounts';
import EconomicReportsDetail from './Reports/containers/EconomicReports/ReportsDetail/ReportsDetail';
import FinancialReportsDetail from './Reports/containers/FinancialReports/ReportsDetail/ReportsDetail';
import EconomicOtherReportsDetail from './Reports/containers/EconomicReports/OtherReportsDetail/OtherReportsDetail';
import FinancialOtherReportsDetail from './Reports/containers/FinancialReports/OtherReportsDetail/OtherReportsDetail';
import { ModificadoresHome, FormulaDetail } from './Modificadores';
import SecurityHome from './Security/SecurityHome/SecurityHome';
import { IntegrationTrackingScreen } from 'modules/core/components';
import { ReportsTypeHome } from './Reports';
import {
  ConceptsHome,
  ConceptsDetail,
  FinancialConceptsDetail,
} from './Concepts';

const Home = () => (
  <Switch>
    <Route exact path="/configuracion" component={ConfigirationHome} />
    <Route
      exact
      path="/configuracion/misdatos/seguridad"
      component={SecurityHome}
    />
    <Route
      exact
      path="/configuracion/misdatos/dimensiones"
      component={DimensionsList}
    />
    <Route
      exact
      path="/configuracion/misdatos/dimensiones/:dimensionId"
      component={DimensionDetail}
    />
    <Route exact path="/configuracion/misdatos/plan" component={PlanAccounts} />
    <Route
      exact
      path="/configuracion/misdatos/conceptos"
      component={ConceptsHome}
    />
    <Route
      exact
      path="/configuracion/misdatos/conceptos/economicos/:conceptId"
      component={ConceptsDetail}
    />
    <Route
      exact
      path="/configuracion/misdatos/usuarios/:userId/"
      component={UserDetail}
    />
    <Route
      exact
      path="/configuracion/misdatos/conceptos/financieros/:conceptId"
      component={FinancialConceptsDetail}
    />
    <Route
      exact
      path="/configuracion/misdatos/usuarios/:userId/:myProfile"
      component={UserDetail} // TODO:  Reemplazar por componente Perfil cuando se desarolle
    />
    <Route
      exact
      path="/configuracion/misdatos/reportes"
      component={ReportsTypeHome}
    />
    <Route
      exact
      path="/configuracion/misdatos/reportes/economicos/reporte/:reportId"
      component={EconomicReportsDetail}
    />
    <Route
      exact
      path="/configuracion/misdatos/reportes/economicos/personalizados/:otherReportId"
      component={EconomicOtherReportsDetail}
    />
    <Route
      exact
      path="/configuracion/misdatos/reportes/financieros/reporte/:reportId"
      component={FinancialReportsDetail}
    />
    <Route
      exact
      path="/configuracion/misdatos/reportes/financieros/personalizados/:otherReportId"
      component={FinancialOtherReportsDetail}
    />
    <Route
      exact
      path="/configuracion/misdatos/elementosdeproyeccion/"
      component={ModificadoresHome}
    />
    <Route
      exact
      path="/configuracion/misdatos/elementosdeproyeccion/regla/:formulaId"
      component={FormulaDetail}
    />
    <Route
      exact
      path="/configuracion/integraciones/:integrationName/actividad/"
      component={IntegrationTrackingScreen}
    />
    <Redirect to="/configuracion" />
  </Switch>
);

export default Home;
