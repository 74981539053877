import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Spin,
  Row,
  Col,
  Table,
  Tabs,
  Space,
  Button,
  Alert,
  Select,
  Tooltip,
} from 'antd';
import { SiderLayout } from 'modules/core/layouts';
import { useTranslation } from 'react-i18next';
import {
  RangePicker,
  PeriodNavigator,
  FiltersContainer,
  TopCollapsableMenu,
  ButtonActionBar,
  Typography,
  TableSettingsModal,
  AccessLink,
  SelectType,
  References,
} from 'modules/core/components';
import {
  DownloadOutlined,
  // CheckOutlined,
  // ControlOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import {
  calculateScrollX,
  columnsHelper,
  isPublished,
  isApproved,
  dateMMYYYY,
  renderPrefix,
  generateSelectOptions,
  orderBySelection,
  getOnExpandParams,
} from 'modules/core/utils';
import {
  PROJECTION,
  ROWS,
  TABLES,
  REPORT,
  CONCEPTS,
  ACCES_USERS,
} from 'modules/core/constants';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import { ReportKPICarousel, ReportLineChart } from 'modules/reports/components';
import ReportSegmentedHome from '../ReportSegmentedHome/ReportSegmentedHome';
import ReportRuleDetail from '../ReportRuleDetail/ReportRuleDetail';
import reports from 'modules/reports';
import planning from 'modules/planning';
import login from 'modules/login';
import moment from 'moment';
import ComparisonHome from '../ComparisonHome/ComparisonHome';
import _ from 'lodash';

import './ReportDetail.scss';

const isBudget = projection => projection === PROJECTION.BUDGET;

const isForecast = projection => projection === PROJECTION.LAST_FORECAST;

const isEvolution = key => key === REPORT.REPORTS_KEYS.EVOLUTION_KEY;

const isComparison = key => key === REPORT.REPORTS_KEYS.COMPARISON_KEY;

const FORMATS_TYPES = {
  '0,0': 'integer',
  '0,0.00': 'decimals',
  '0[.]0a': 'millions',
};

//NOTE: EDITIONMODE KPIS COMENTADO HASTA QUE SE PUEDA GUARDAR DESDE BACK LOS CAMBIOS.

const ReportDetail = ({
  reportColumns: { columns, prev_columns },
  reportDetail,
  reportKPIs,
  fetchPeriodDetail,
  periodDetail,
  reportTableByPeriods,
  reportChartData,
  fetchReportDetail,
  fetchReportColumns,
  fetchReportData,
  fetchReportOpenRow,
  downloadEvolutionXLS,
  consolidationList,
  fetchConsolidationList,
  consolidatedPredefined,
  activeOptionPeriodTable,
  setReportLineSelected,
  loggedUser: { email },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isChangingConso, setIsChangingConso] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [periodTableSettings, setPeriodTableSettings] = useState(
    TABLES.DEFAULT_SETTINGS(email)
  );
  const [activeByPeriodTable, setActiveByPeriodTable] = useState({});
  const [periodTableColumns, setPeriodTableColumns] = useState([]);
  const [isExpandingByPeriod, setIsExpandingByPeriod] = useState(false);
  const [expandedByPeriodRow, setExpandedByPeriodRow] = useState([]);
  const [filters, setFilters] = useState({});
  const [comparisonSelections, setComparisonSelections] = useState({});
  // const [showEditionMode, setShowEditionMode] = useState(false);
  const [tabKey, setTabKey] = useState(REPORT.REPORTS_KEYS.EVOLUTION_KEY);
  const functionalCurrencyData = useFunctionalCurrency();
  let { periodId, reportId } = useParams();
  const { t } = useTranslation();

  const reportFunctionalCurrencyData = reportDetail.name
    ? reportDetail.has_exr
      ? PROJECTION.CURRENCIES_OPTIONS.find(
          currency => currency.code === reportDetail.has_exr.to_currency_code
        )
      : functionalCurrencyData
    : null;

  useEffect(() => {
    fetchPeriodDetail(periodId).then(() => {
      setIsScreenLoading(false);
    });
  }, [periodId, fetchPeriodDetail]);

  useEffect(() => {
    setIsDataLoading(true);
    Promise.all([
      fetchReportDetail(reportId, { period_id: periodId }),
      fetchReportColumns(reportId, { period_id: periodId }),
      fetchConsolidationList(),
    ]).then(() => setIsDataLoading(false));
  }, [
    periodId,
    reportId,
    selectedDates,
    filters,
    fetchReportDetail,
    fetchReportColumns,
    fetchConsolidationList,
  ]);

  //NOTE: hardcodeado para cliente especifico solo para v1.
  useEffect(() => {
    const auxActiveByPeriodTable = [
      ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
    ].some(el => email.includes(el))
      ? { value: PROJECTION.REAL, id: null }
      : activeOptionPeriodTable;
    setComparisonSelections(consolidatedPredefined);
    setActiveByPeriodTable(auxActiveByPeriodTable);
  }, [consolidatedPredefined, activeOptionPeriodTable, email]);

  //TODO: CHECKEAR PORQUE SE LLAMA DOS VECES EL ENDPOINT. REVISAR SI SE PUEDE ARMAR DE OTRA MANERA
  useEffect(() => {
    if (
      activeByPeriodTable.value ||
      comparisonSelections.budget ||
      comparisonSelections.last_forecast
    ) {
      setIsChangingConso(true);
      const endpointParams = {
        period_id: periodId,
        ...selectedDates,
        ...filters,
        ...(isEvolution(tabKey) &&
          (isBudget(activeByPeriodTable.value) ||
            isForecast(activeByPeriodTable.value)) && {
            consolidado1: activeByPeriodTable.id,
          }),
        ...(isComparison(tabKey) && {
          consolidado1: _.split(comparisonSelections.budget, '-', 2)[1],
          consolidado2: _.split(comparisonSelections.last_forecast, '-', 2)[1],
        }),
      };

      fetchReportData(reportId, endpointParams).then(() =>
        setIsChangingConso(false)
      );
    }
  }, [
    fetchReportData,
    filters,
    periodId,
    reportId,
    tabKey,
    selectedDates,
    comparisonSelections.budget,
    comparisonSelections.last_forecast,
    activeByPeriodTable.value,
    activeByPeriodTable.id,
  ]);

  useEffect(() => {
    setPeriodTableColumns(
      activeByPeriodTable.value === PROJECTION.REAL_LAST_PERIOD
        ? prev_columns
        : columns
    );
  }, [columns, prev_columns, activeByPeriodTable]);

  const dataConsolidatedDefault = {
    conso_forecast_predef: periodDetail.conso_forecast_predef,
    conso_budget_predef: periodDetail.conso_budget_predef,
  };

  //TODO: checkear si es la mejor manera
  const consolidationListBudget = consolidationList.filter(
    conso =>
      conso.period.id === parseInt(periodId) &&
      conso.type === PROJECTION.CONSOLIDATED &&
      (isApproved(conso.status) || isPublished(conso.status))
  );
  const consolidationListForecast = consolidationList.filter(
    conso =>
      conso.period.id === parseInt(periodId) &&
      conso.type === PROJECTION.CONSOLIDATED_FORECAST &&
      (isApproved(conso.status) || isPublished(conso.status))
  );

  const hasReportData =
    reportTableByPeriods.rows[activeByPeriodTable.value] &&
    reportTableByPeriods.rows[activeByPeriodTable.value].length !== 0;

  const endpointParamsEvolution = {
    ...(isEvolution(tabKey) &&
      isBudget(activeByPeriodTable.value) && {
        consolidado1: activeByPeriodTable.id,
      }),
  };

  // TODO: Al contraer deberia quitar a los hijos del elemento en la lista. Pensar una estrcutra de parent-chld / array
  // TODO: Quitar elementos del reducer al contraer
  const onExpandByPeriod = (expanded, record) => {
    setIsExpandingByPeriod(true);
    if (expanded) {
      fetchReportOpenRow(record.id, {
        ...endpointParamsEvolution,
        ...getOnExpandParams(record, periodId, selectedDates, filters),
      }).then(() => {
        setIsExpandingByPeriod(false);
        setExpandedByPeriodRow(prevExpanded => [...prevExpanded, record.id]);
      });
    } else {
      setExpandedByPeriodRow(prevExpanded =>
        prevExpanded.filter(item => item !== record.id)
      );
      setIsExpandingByPeriod(false);
    }
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_ANALYSIS'),
          url: `/analisis/${periodId}`,
        },
        {
          name: t('REPORT_PLIKA_CARD_TITLE'),
          url: `/analisis/${periodId}/reportesplika?tab=${CONCEPTS.TYPE.ECONOMIC_KEY}`,
        },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/analisis/${periodId}/reportesplika?tab=${CONCEPTS.TYPE.ECONOMIC_KEY}`,
        },
        {
          name: t('LABEL_REPORTS'),
        },
        { name: reportDetail.name ? reportDetail.name : '' },
      ]}
    />
  );

  const renderEvolutionTableActions = () => (
    <ButtonActionBar position="space-between">
      <References
        referencesItems={[
          {
            color: '#a73ba1',
            type: 'Ab',
            title: t('LABEL_RATIO'),
            description: t('REPORT_VALUES_RATIO'),
          },
          ...(reportFunctionalCurrencyData
            ? [
                {
                  type: <DollarOutlined />,
                  title:
                    reportFunctionalCurrencyData &&
                    reportFunctionalCurrencyData.code,
                  description:
                    reportFunctionalCurrencyData &&
                    t('REFERENCE_DESCRIPTION', {
                      currencyName: t(
                        reportFunctionalCurrencyData.name
                      ).toLowerCase(),
                    }),
                },
              ]
            : []),
        ]}
      />
      <Space direction="horizontal">
        <Button
          type="text"
          shape="circle"
          title={t('ACTION_DOWNLOAD_TABLE')}
          icon={<Typography.Icon icon={DownloadOutlined} />}
          onClick={() =>
            downloadEvolutionXLS(
              reportId,
              {
                period_id: periodId,
                format_number: FORMATS_TYPES[periodTableSettings.format],
                ...selectedDates,
                ...filters,
                ...endpointParamsEvolution,
              },
              t('REPORT_EVOLUTION_FILE_NAME', {
                periodName: periodDetail.name,
              })
            )
          }
        />
        <TableSettingsModal
          onConfirm={setPeriodTableSettings}
          settings={periodTableSettings}
        />
      </Space>
    </ButtonActionBar>
  );

  const generatorEvolutionColumns = () =>
    columnsHelper.generatorReportColumns({
      columns: periodTableColumns,
      onResize: setPeriodTableColumns,
      cellFormat: {
        format: periodTableSettings.format,
        showNegativeInRed: periodTableSettings.showNegativeInRed,
      },
      has_restriction: [
        ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
      ].some(el => email.includes(el)),
    });

  const renderReportTables = () => (
    <Tabs
      size="small"
      tabBarExtraContent={<AccessLink linkName="pnl" />}
      onChange={key => setTabKey(key)}
    >
      <Tabs.TabPane
        tab={t('FIELD_EVOLUTION')}
        key={REPORT.REPORTS_KEYS.EVOLUTION_KEY}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <SelectType.Evolution
              activeOptionTable={activeByPeriodTable}
              setActiveByPeriodTable={setActiveByPeriodTable}
              dataConsolidatedDefault={dataConsolidatedDefault}
              consolidationsPredef={{
                budget: periodDetail.conso_budget_predef,
                forecast: periodDetail.conso_forecast_predef,
              }}
              loading={isDataLoading}
              consolidationLists={{
                budget: consolidationListBudget,
                forecast: consolidationListForecast,
              }}
            />
          </Col>
          <Col span={24}>{renderEvolutionTableActions()}</Col>
        </Row>
        <Table
          rowKey="id"
          size="small"
          columns={generatorEvolutionColumns()}
          dataSource={
            !_.isEmpty(reportTableByPeriods) &&
            reportTableByPeriods.rows[activeByPeriodTable.value]
          }
          rowClassName={record => ROWS.TYPES[record.type]}
          loading={isExpandingByPeriod || isDataLoading || isChangingConso}
          onExpand={onExpandByPeriod}
          expandedRowKeys={expandedByPeriodRow}
          defaultExpandAllRows={false}
          scroll={{ x: calculateScrollX(generatorEvolutionColumns()), y: 420 }}
          pagination={false}
          components={columnsHelper.tableComponents}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={t('FIELD_COMPARISONS')}
        key={REPORT.REPORTS_KEYS.COMPARISON_KEY}
      >
        <ComparisonHome
          comparisonSelections={comparisonSelections}
          setComparisonSelections={setComparisonSelections}
          isDataLoading={isDataLoading}
          reportFunctionalCurrencyData={reportFunctionalCurrencyData}
          periodDetail={periodDetail}
          selectedDates={selectedDates}
          filters={filters}
          consolidationListBudget={consolidationListBudget}
          consolidationListForecast={consolidationListForecast}
          isChangingConso={isChangingConso}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={t('REPORT_TAB_SEGMENTED_PL')}
        key={REPORT.REPORTS_KEYS.PLSEGMENTED_KEY}
      >
        <ReportSegmentedHome
          filters={filters}
          selectedDates={selectedDates}
          periodDetail={periodDetail}
          periodId={periodId}
          reportId={reportId}
          consolidationListForecast={consolidationListForecast}
          consolidationListBudget={consolidationListBudget}
          consolidatedPredefined={consolidatedPredefined}
          activeOptionPeriodTable={activeOptionPeriodTable}
          functionalCurrencyData={reportFunctionalCurrencyData}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <Tooltip
            title={
              !periodDetail.conso_budget_predef
                ? t('REPORT_RDN_DETAIL_INFORMATIVE_TOOLTIP_TITLE')
                : ''
            }
          >
            {t('REPORT_TAB_RDN_DETAIL')}
          </Tooltip>
        }
        key={REPORT.REPORTS_KEYS.DETALLE_RDN}
        disabled={!periodDetail.conso_budget_predef}
      >
        <ReportRuleDetail
          consolidationListBudget={consolidationListBudget}
          consoPredefinedId={
            periodDetail.conso_budget_predef &&
            periodDetail.conso_budget_predef.id
          }
          functionalCurrencyData={reportFunctionalCurrencyData}
        />
      </Tabs.TabPane>
    </Tabs>
  );

  const renderAlertInfo = () => {
    return (
      <Alert
        type="info"
        closable
        showIcon
        message={t('REPORT_ALERT_INFO_MESSAGE')}
      />
    );
  };

  // const renderActionsKpis = () => {
  //   return (
  //     <ButtonActionBar>
  //       <Button
  //         type="text"
  //         shape="circle"
  //         title={
  //           !showEditionMode
  //             ? t('DISPLAY_MODAL_SETTINGS_TITLE')
  //             : t('ACTION_SAVE_CHANGES')
  //         }
  //         onClick={() =>
  //           !showEditionMode
  //             ? setShowEditionMode(true)
  //             : setShowEditionMode(false)
  //         }
  //         icon={
  //           <Typography.Icon
  //             icon={!showEditionMode ? ControlOutlined : CheckOutlined}
  //           />
  //         }
  //       />
  //     </ButtonActionBar>
  //   );
  // };

  // const renderAlertInfoKpis = () => {
  //   return (
  //     <Alert
  //       showIcon
  //       closable
  //       type="info"
  //       description={t('REPORT_EDITION_KPIS_INFORMATIVE_ALERT_DESCRIPTION')}
  //     />
  //   );
  // };

  const renderSelectReporLine = () => {
    const reportLineId = reportTableByPeriods.rows[
      activeByPeriodTable.value
    ].find(report => report.type === ROWS.TOTALIZER && report.level === 0).id;
    const reportLinesOptions = reportTableByPeriods.rows[
      activeByPeriodTable.value
    ].map(reportLine => {
      return { id: reportLine.id, name: reportLine.concept };
    });
    return (
      <Select
        bordered={false}
        style={{ width: 150 }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={reportLineId}
        onChange={id => setReportLineSelected(id)}
        options={generateSelectOptions({
          options: orderBySelection(
            !reportTableByPeriods.lineId
              ? [reportLineId]
              : [reportTableByPeriods.lineId],
            reportLinesOptions
          ),
        })}
      />
    );
  };

  const chartTitle = `${t('REPORT_GRAPHIC_TITLE')} ${
    !_.isEmpty(selectedDates)
      ? `(${dateMMYYYY(selectedDates['start-date'], 'YYYY-MM')} - ${dateMMYYYY(
          selectedDates['end-date'],
          'YYYY-MM'
        )})`
      : ''
  }`;

  const renderChartTitle = () => {
    return (
      <>
        {chartTitle}
        {hasReportData && renderSelectReporLine()}
      </>
    );
  };

  const renderReportLineName = () => {
    if (hasReportData) {
      if (reportTableByPeriods.lineId !== undefined) {
        return reportTableByPeriods.rows[activeByPeriodTable.value].find(
          report => report.id === reportTableByPeriods.lineId
        ).concept;
      }
      return reportTableByPeriods.rows[activeByPeriodTable.value].find(
        report => report.type === ROWS.TOTALIZER && report.level === 0
      ).concept;
    }

    return '';
  };

  return (
    <SiderLayout
      className="report-detail-container"
      header={
        <TopCollapsableMenu>
          <Space size="small" className="report-detail-space">
            {!_.isEmpty(periodDetail) && (
              <RangePicker
                onSetRange={setSelectedDates}
                periodDate={{
                  startDate: periodDetail?.start_date,
                  endDate: periodDetail?.end_date,
                }}
                defaultPickerValue={
                  !_.isEmpty(periodDetail)
                    ? [
                        moment(periodDetail?.start_date),
                        moment(periodDetail?.end_date),
                      ]
                    : null
                }
                defaultValue={
                  !_.isEmpty(selectedDates) && [
                    moment(selectedDates['start-date'], 'YYYY-MM-01'),
                    moment(selectedDates['end-date'], 'YYYY-MM-01'),
                  ]
                }
              />
            )}
            {/*NOTE: pasar id periodo solo para el caso de reportes */}
            <FiltersContainer
              periodId={periodId}
              filter={filters}
              onFilter={setFilters}
            />
          </Space>
        </TopCollapsableMenu>
      }
    >
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <Row gutter={[8, 16]}>
            {(consolidationListBudget.length <= 0 ||
              consolidationListForecast.length <= 0) && (
              <Col span={24}>{!isDataLoading && renderAlertInfo()}</Col>
            )}
            {/* <Col span={24}>{renderActionsKpis()}</Col>
            {showEditionMode && <Col span={24}>{renderAlertInfoKpis()}</Col>} */}
            <Col span={24}>
              <ReportKPICarousel
                dataKPI={reportKPIs.totalizers_kpis}
                isLoading={isDataLoading}
                // showEditionMode={showEditionMode}
                renderPrefix={defaultPrefix =>
                  renderPrefix(reportFunctionalCurrencyData, defaultPrefix)
                }
                isEconomic={true}
              />
            </Col>
            <Col span={24}>{renderReportTables()}</Col>
            <Col span={24}>
              <ReportLineChart
                title={renderChartTitle()}
                chartTitle={`${chartTitle} ${renderReportLineName()}`}
                data={reportChartData}
                prefix={renderPrefix(reportFunctionalCurrencyData)}
              />
            </Col>
          </Row>
        </>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  reportDetail: reports.selectors.getReportDetail(state),
  reportColumns: reports.selectors.getReportColumns(state),
  reportKPIs: reports.selectors.getReportKPIs(state),
  reportTableByPeriods: reports.selectors.getReportTableByPeriods(state),
  reportChartData: reports.selectors.getReportChartData(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
  consolidationList: planning.selectors.getConsolidationList(state),
  consolidatedPredefined: planning.selectors.getConsolidatedPredefined(state),
  activeOptionPeriodTable: planning.selectors.getActiveOptionPeriodTable(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  fetchReportDetail: reports.actions.fetchReportDetail,
  fetchReportColumns: reports.actions.fetchReportColumns,
  fetchReportData: reports.actions.fetchReportData,
  fetchReportOpenRow: reports.actions.fetchReportOpenRow,
  downloadEvolutionXLS: reports.actions.downloadEvolutionXLS,
  fetchConsolidationList: planning.actions.fetchConsolidationList,
  setReportLineSelected: reports.actions.setReportLineSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
