import { Select } from 'antd';

const MultipleSelect = ({
  options = [],
  placeholder = '',
  onChange = () => {},
  disabled = false,
  onBlur = () => {},
  loading = false,
  ...props
}) => {
  return (
    <Select
      {...props}
      placeholder={placeholder}
      showSearch
      loading={loading}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      allowClear
      showArrow
      mode="multiple"
      maxTagCount={1}
      maxTagTextLength={8}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default MultipleSelect;
