import React, { useState, useEffect } from 'react';
import {
  Spin,
  Row,
  Col,
  Tabs,
  Table,
  Space,
  Button,
  // Alert,
  message,
} from 'antd';
import { connect } from 'react-redux';
import {
  DownloadOutlined,
  // ControlOutlined,
  // CheckOutlined,
} from '@ant-design/icons';
import { SiderLayout } from 'modules/core/layouts';
import {
  PeriodNavigator,
  TopCollapsableMenu,
  RangePicker,
  FiltersContainer,
  ButtonActionBar,
  References,
  TableSettingsModal,
  Typography,
  SelectType,
} from 'modules/core/components';
import { InitialSituationTable } from './components';
import { ReportKPICarousel, ReportLineChart } from 'modules/reports/components';
import {
  columnsHelper,
  calculateScrollX,
  dateMMYYYY,
} from 'modules/core/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  CONCEPTS,
  REPORT,
  TABLES,
  PROJECTION,
  ROWS,
  ACCES_USERS,
} from 'modules/core/constants';
import moment from 'moment';
import _ from 'lodash';
import planning from 'modules/planning';
import reports from 'modules/reports';
import login from 'modules/login';

import './ReportDetail.scss';

const INITIAL_STATE = { value: PROJECTION.REAL, id: null };

//TODO: AGREGAR EXPANDED ROWS CUANDO SE PUEDA
//NOTE: EDITIONMODE KPIS COMENTADO HASTA QUE SE PUEDA GUARDAR DESDE BACK LOS CAMBIOS.
const ReportDetail = ({
  fetchPeriodDetail,
  periodDetail,
  fetchConsolidationList,
  consolidationList,
  fetchReportDetail,
  reportDetail,
  fetchFinancialReportRows,
  financialReportRows,
  fetchFinancialReportColumns,
  financialReportColumns: { columns, prev_columns },
  fetchFinancialReportChartData,
  financialReportChartData,
  downloadEvolutionXLS,
  financialReportKPIs,
  hasInitialSituation,
  editInitialSituation,
  loggedUser: { email },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [periodTableSettings, setPeriodTableSettings] = useState(
    TABLES.DEFAULT_SETTINGS(email)
  );
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [activeByPeriodTable, setActiveByPeriodTable] = useState(INITIAL_STATE);
  const [periodTableColumns, setPeriodTableColumns] = useState([]);
  const [dataCellEdit, setDataCellEdit] = useState({});
  // const [showEditionMode, setShowEditionMode] = useState(false);
  let { periodId, reportId } = useParams();
  const { t } = useTranslation();

  //NOTE: ESTA PUESTO PRIMERO PORQUE ES NECESARIO EN UN USEEFFECT.REVER
  const dataInitialSituation = reportDetail.name
    ? reportDetail.initial_situation
    : null;

  const manual_initial_situation = reportDetail.name
    ? reportDetail.manual_initial_situation
    : null;

  useEffect(() => {
    fetchPeriodDetail(periodId).then(() => {
      setIsScreenLoading(false);
    });
  }, [periodId, fetchPeriodDetail]);

  useEffect(() => {
    setIsScreenLoading(true);
    Promise.all([
      fetchConsolidationList(),
      fetchFinancialReportColumns(reportId, { period_id: periodId }),
      fetchFinancialReportChartData(reportId, {
        period_id: periodId,
        ...selectedDates,
        ...filters,
      }),
    ]).then(() => setIsScreenLoading(false));
  }, [
    fetchConsolidationList,
    fetchFinancialReportColumns,
    fetchFinancialReportChartData,
    periodId,
    reportId,
    selectedDates,
    filters,
  ]);

  useEffect(() => {
    fetchReportDetail(reportId, { period_id: periodId });
  }, [fetchReportDetail, reportId, periodId]);

  useEffect(() => {
    setIsDataLoading(true);
    const endpointParams = {
      ...(dataInitialSituation && {
        ...(dataInitialSituation.real_initial_situation && {
          real_initial_situation: dataInitialSituation.real_initial_situation,
        }),
        ...(dataInitialSituation.prev_real_initial_situation && {
          prev_real_initial_situation:
            dataInitialSituation.prev_real_initial_situation,
        }),
        ...(dataInitialSituation.last_forecast_initial_situation && {
          last_forecast_initial_situation:
            dataInitialSituation.last_forecast_initial_situation,
        }),
      }),
      ...selectedDates,
      ...filters,
    };
    fetchFinancialReportRows(reportId, {
      period_id: periodId,
      ...endpointParams,
    }).then(() => setIsDataLoading(false));
  }, [
    fetchFinancialReportRows,
    reportId,
    periodId,
    selectedDates,
    filters,
    reportDetail,
    reportDetail.initialSituation,
    reportDetail.name,
    dataInitialSituation,
  ]);

  useEffect(() => {
    setPeriodTableColumns(
      activeByPeriodTable.value === PROJECTION.REAL_LAST_PERIOD
        ? prev_columns
        : columns
    );
  }, [columns, prev_columns, activeByPeriodTable]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const dataConsolidatedDefault = {
    conso_forecast_predef: periodDetail.conso_forecast_predef,
    conso_budget_predef: periodDetail.conso_budget_predef,
  };

  //TODO: provisorio hasta que se pueda seleccionar mas de un conoslidado de forecast desde back
  const consolidationListForecast = consolidationList.filter(
    conso =>
      conso.period.id === parseInt(periodId) &&
      conso.type === PROJECTION.CONSOLIDATED_FORECAST &&
      conso.id === periodDetail.conso_forecast_predef.id
  );

  const has_data_initial_situation =
    activeByPeriodTable.value !== PROJECTION.REAL &&
    !_.isEmpty(hasInitialSituation) &&
    hasInitialSituation[PROJECTION.REAL] &&
    !hasInitialSituation[activeByPeriodTable.value] &&
    !manual_initial_situation[activeByPeriodTable.value];

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_ANALYSIS'),
          url: `/analisis/${periodId}`,
        },
        {
          name: t('REPORT_PLIKA_CARD_TITLE'),
          url: `/analisis/${periodId}/reportesplika?tab=${CONCEPTS.TYPE.FINANCIAL_KEY}`,
        },
        {
          name: t('LABEL_FINANCIAL'),
          url: `/analisis/${periodId}/reportesplika?tab=${CONCEPTS.TYPE.FINANCIAL_KEY}`,
        },
        {
          name: t('LABEL_REPORTS'),
        },
        { name: reportDetail.name ? reportDetail.name : '' },
      ]}
    />
  );

  const renderTableActions = () => (
    <ButtonActionBar position="space-between">
      <References
        referencesItems={[
          {
            color: '#a73ba1',
            type: 'Ab',
            title: t('LABEL_RATIO'),
            description: t('REPORT_VALUES_RATIO'),
          },
        ]}
      />
      <Space direction="horizontal">
        <Button
          type="text"
          shape="circle"
          title={t('ACTION_DOWNLOAD_TABLE')}
          icon={<Typography.Icon icon={DownloadOutlined} />}
          onClick={() =>
            downloadEvolutionXLS(
              reportId,
              { period_id: periodId, ...selectedDates, ...filters },
              t('REPORT_EVOLUTION_FILE_NAME', {
                periodName: periodDetail.name,
              })
            )
          }
        />
        <TableSettingsModal
          onConfirm={setPeriodTableSettings}
          settings={periodTableSettings}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderHeaderLayout = () => (
    <TopCollapsableMenu>
      <Space size="small" className="report-detail-space">
        {!_.isEmpty(periodDetail) && (
          <RangePicker
            onSetRange={setSelectedDates}
            periodDate={{
              startDate: periodDetail?.start_date,
              endDate: periodDetail?.end_date,
            }}
            defaultPickerValue={
              !_.isEmpty(periodDetail)
                ? [
                    moment(periodDetail?.start_date),
                    moment(periodDetail?.end_date),
                  ]
                : null
            }
            defaultValue={
              !_.isEmpty(selectedDates) && [
                moment(selectedDates['start-date'], 'YYYY-MM-01'),
                moment(selectedDates['end-date'], 'YYYY-MM-01'),
              ]
            }
          />
        )}
        {/*NOTE: pasar id periodo solo para el caso de reportes */}
        <FiltersContainer
          periodId={periodId}
          filter={filters}
          onFilter={setFilters}
        />
      </Space>
    </TopCollapsableMenu>
  );

  const onRefetch = () => {
    const endpointParams = {
      ...(dataInitialSituation.real_initial_situation && {
        real_initial_situation: dataInitialSituation.real_initial_situation,
      }),
      ...(dataInitialSituation.prev_real_initial_situation && {
        prev_real_initial_situation:
          dataInitialSituation.prev_real_initial_situation,
      }),
      ...(dataInitialSituation.last_forecast_initial_situation && {
        last_forecast_initial_situation:
          dataInitialSituation.last_forecast_initial_situation,
      }),
      ...selectedDates,
      ...filters,
    };
    fetchFinancialReportRows(reportId, {
      period_id: periodId,
      ...endpointParams,
    });
  };

  const handleCellEdit = data => {
    message.loading({
      key: 'loading_edit',
      content: t('FEEDBACK_LOADING_CHANGES'),
    });
    const endpointData = {
      [`${activeByPeriodTable.value}_initial_situation`]: data.amount,
    };
    editInitialSituation(reportId, { period_id: periodId }, endpointData)
      .then(() => {
        Promise.all([onRefetch()]).finally(() => {
          message.destroy('loading_edit');
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES', 0.5));
        });
      })
      .catch(() => {
        message.error(t('FEEDBACK_DEFAULT_ERROR'));
      });
  };

  const columnsGenerator = () =>
    columnsHelper.generatorReportColumns({
      columns: periodTableColumns,
      onResize: setPeriodTableColumns,
      cellFormat: {
        format: periodTableSettings.format,
        showNegativeInRed: periodTableSettings.showNegativeInRed,
      },
      editable:
        manual_initial_situation &&
        manual_initial_situation[activeByPeriodTable.value],
      onCellEdit: handleCellEdit,
      has_restriction,
    });

  const renderEvolutionTable = () => (
    <Table
      rowKey="id"
      size="small"
      columns={columnsGenerator()}
      dataSource={financialReportRows[activeByPeriodTable.value]}
      rowClassName={record => ROWS.TYPES[record.type]}
      expandIconColumnIndex={-1} //TODO: Cuando este el expandir desde back, borrarlo.
      loading={isDataLoading}
      scroll={{ x: calculateScrollX(columnsGenerator()), y: 420 }}
      pagination={false}
      components={columnsHelper.tableComponents}
    />
  );

  const renderStatusInitialSituation = () => {
    //NOTE: no pongo real porque admito que siempre va a haber real para poder ver los datos
    return {
      prev_real:
        financialReportRows[PROJECTION.REAL_LAST_PERIOD].length !== 0 ||
        (!_.isEmpty(hasInitialSituation) &&
          hasInitialSituation[PROJECTION.REAL]),
      last_forecast:
        financialReportRows[PROJECTION.LAST_FORECAST].length !== 0 ||
        (!_.isEmpty(hasInitialSituation) &&
          hasInitialSituation[PROJECTION.REAL]),
    };
  };

  const renderInitialSituationTable = () => (
    <InitialSituationTable
      onCellEdit={onCellEdit}
      dataCellEdit={dataCellEdit}
      setDataCellEdit={setDataCellEdit}
      columns={columns}
      financialReportRows={financialReportRows}
      activeByPeriodTableValue={activeByPeriodTable.value}
      onRefetchFinancialReportRows={() => onRefetch()}
      onRefetchReportDetail={() =>
        fetchReportDetail(reportId, { period_id: periodId })
      }
    />
  );

  const onCellEdit = (name, value) => setDataCellEdit({ [name]: value });

  const renderTabs = () => (
    <Tabs size="small">
      <Tabs.TabPane
        tab={t('FIELD_EVOLUTION')}
        key={REPORT.REPORTS_KEYS.EVOLUTION_KEY}
      >
        {/* TODO: VER COMO AJUSTAR EL INFORMATIVE ICON PARA QUE EN EL CASO DE FINANCIERO SOLO MUESTRE EL FORECAST */}
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <SelectType.Evolution
              activeOptionTable={activeByPeriodTable}
              setActiveByPeriodTable={setActiveByPeriodTable}
              dataConsolidatedDefault={dataConsolidatedDefault}
              consolidationsPredef={{
                budget: periodDetail.conso_budget_predef,
                forecast: periodDetail.conso_forecast_predef,
              }}
              loading={isScreenLoading}
              consolidationLists={{
                budget: [],
                forecast: consolidationListForecast,
              }}
              initialSituation={renderStatusInitialSituation()}
            />
          </Col>
          <Col span={24}>
            {!has_data_initial_situation && renderTableActions()}
          </Col>
        </Row>
        {has_data_initial_situation
          ? renderInitialSituationTable()
          : renderEvolutionTable()}
      </Tabs.TabPane>
    </Tabs>
  );

  const renderChartTitle = () =>
    `${t('REPORT_GRAPHIC_TITLE', {
      reportLineName:
        financialReportRows[activeByPeriodTable.value].length !== 0
          ? financialReportRows[activeByPeriodTable.value].find(
              report => report.type === ROWS.TOTALIZER && report.level === 0
            ).concept
          : '',
    })}
        ${
          !_.isEmpty(selectedDates)
            ? `(${dateMMYYYY(
                selectedDates['start-date'],
                'YYYY-MM'
              )}- ${dateMMYYYY(selectedDates['end-date'], 'YYYY-MM')})`
            : ''
        }
    `;

  const renderChart = () => {
    return (
      <ReportLineChart
        title={renderChartTitle()}
        data={financialReportChartData}
      />
    );
  };

  // const renderActionsKpis = () => {
  //   return (
  //     <ButtonActionBar>
  //       <Button
  //         type="text"
  //         shape="circle"
  //         title={
  //           !showEditionMode
  //             ? t('DISPLAY_MODAL_SETTINGS_TITLE')
  //             : t('ACTION_SAVE_CHANGES')
  //         }
  //         onClick={() =>
  //           !showEditionMode
  //             ? setShowEditionMode(true)
  //             : setShowEditionMode(false)
  //         }
  //         icon={
  //           <Typography.Icon
  //             icon={!showEditionMode ? ControlOutlined : CheckOutlined}
  //           />
  //         }
  //       />
  //     </ButtonActionBar>
  //   );
  // };

  // const renderAlertInfoKpis = () => {
  //   return (
  //     <Alert
  //       showIcon
  //       closable
  //       type="info"
  //       description={t('REPORT_EDITION_KPIS_INFORMATIVE_ALERT_DESCRIPTION')}
  //     />
  //   );
  // };

  const renderKPIS = ({ dataKpis, isGrouper }) => (
    <ReportKPICarousel
      dataKPI={dataKpis}
      isGrouper={isGrouper}
      isLoading={isDataLoading}
      // showEditionMode={showEditionMode}
    />
  );

  return (
    <SiderLayout
      className="financial-report-detail-container"
      header={renderHeaderLayout()}
    >
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <Row gutter={[24, 24]}>
            {/* <Col span={24}>{renderActionsKpis()}</Col>
            {showEditionMode && <Col span={24}>{renderAlertInfoKpis()}</Col>} */}
            <Col span={24}>
              {renderKPIS({
                dataKpis: financialReportKPIs[activeByPeriodTable.value],
                isGrouper: false,
              })}
            </Col>
            <Col span={24}>{renderTabs()}</Col>
            <Col span={24}>{renderChart()}</Col>
          </Row>
        </>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  periodDetail: planning.selectors.getPeriodDetail(state),
  consolidationList: planning.selectors.getConsolidationList(state),
  consolidatedPredefined: planning.selectors.getConsolidatedPredefined(state),
  activeOptionPeriodTable: planning.selectors.getActiveOptionPeriodTable(state),
  reportDetail: reports.selectors.getReportDetail(state),
  financialReportColumns: reports.selectors.getFinancialReportColumns(state),
  financialReportRows: reports.selectors.getFinancialReportRows(state),
  financialReportChartData:
    reports.selectors.getFinancialReportChartData(state),
  financialReportKPIs: reports.selectors.getFinancialReportKPIs(state),
  hasInitialSituation: reports.selectors.getHasInitialSituation(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  fetchConsolidationList: planning.actions.fetchConsolidationList,
  fetchReportDetail: reports.actions.fetchReportDetail,
  fetchFinancialReportColumns: reports.actions.fetchFinancialReportColumns,
  fetchFinancialReportRows: reports.actions.fetchFinancialReportRows,
  fetchFinancialReportChartData: reports.actions.fetchFinancialReportChartData,
  downloadEvolutionXLS: reports.actions.downloadEvolutionXLS,
  editInitialSituation: reports.actions.editInitialSituation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
