import Immutable from 'seamless-immutable';
import * as types from './constants';
import { ADJUSTMENTS } from 'modules/core/constants';

const initialCreation = {
  index: 0,
  date: null,
  type: null,
  comment: '',
  initialRow: [],
  rows: {},
};

const initialState = Immutable({
  selectorColumns: [],
  availableDates: [],
  availableTypes: ADJUSTMENTS.TYPES,
  creation: initialCreation,
  adjustmentKPIS: [],
  adjustmentList: {
    count: 0,
    values: [],
  },
  adjustmentColumns: [],
  adjustmentDetail: {
    original: {},
    reversion: {},
  },
});

const createRows = (columns, id, initialRows = []) => {
  if (initialRows.length > 0) {
    return initialRows.reduce((newRows, row, index) => {
      const rowIndex = id + index;
      return Object.assign(newRows, {
        [rowIndex]: createValues(columns, rowIndex, row),
      });
    }, {});
  } else {
    return { [id]: createValues(columns, id) };
  }
};

// TODO: Cambiar el initialValues cuando se cambie en el backend ya que hace referencia a info en duplicar o xls viene name.
const createValues = (columns, id, initialValues = {}) => {
  // Valido si el movimiento tiene valores iniciales (Duplicar, XLS o ControlDetail/ReportDetail)
  // En caso de que no tenga valores iniciales, voy completando con null.
  const completInitialValues = item => {
    if (initialValues.hasOwnProperty(item.data_index)) {
      if (item.data_index === 'amount') {
        return initialValues[item.data_index];
      }
      return {
        id: initialValues[item.data_index].id,
        name:
          initialValues[item.data_index].info ||
          initialValues[item.data_index].name,
      };
    }
    return null;
  };

  return columns.reduce(
    (objectRow, item) => {
      return {
        ...objectRow,
        [item.data_index]: completInitialValues(item),
      };
    },
    { id }
  );
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ADJUSTMENT_KPIS_SUCCESS:
      return state.merge({
        adjustmentKPIS: action.payload.data.kpis,
      });

    case types.FETCH_ADJUSTMENT_TABLE_SUCCESS:
      return state.merge({
        adjustmentList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      });

    case types.FETCH_COLUMNS_ADJUSTMENT_DETAIL_SUCCESS:
      return state.merge({
        adjustmentColumns: action.payload.data.columns,
      });

    case types.FETCH_ADJUSTMENT_DETAIL_TABLE_SUCCESS:
      return state.merge({
        adjustmentDetail: action.payload.data,
      });

    case types.EDIT_ADJUSTMENT_VALUES:
      return state.merge({
        creation: {
          ...state.creation,
          ...action.payload.data,
        },
      });

    case types.FETCH_SELECTOR_COLUMNS_SUCCESS:
      return state.merge({
        selectorColumns: action.payload.data.columns,
        creation: {
          ...state.creation,
          index:
            state.creation.initialRow.length > 0
              ? state.creation.initialRow.length
              : state.creation.index + 1,
          initialRow: [],
          rows: {
            ...createRows(
              action.payload.data.columns,
              state.creation.index,
              state.creation.initialRow
            ),
          },
        },
      });

    case types.ADD_ADJUSTMENT_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          index: state.creation.index + 1,
          rows: {
            ...state.creation.rows,
            ...createRows(state.selectorColumns, state.creation.index),
          },
        },
      });

    case types.COPY_ADJUSTMENT_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          index: state.creation.index + 1,
          rows: {
            ...state.creation.rows,
            [state.creation.index]: {
              ...state.creation.rows[action.payload.rowId],
              id: state.creation.index,
            },
          },
        },
      });

    case types.REMOVE_ADJUSTMENT_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          rows: state.creation.rows.without(action.payload.rowId),
        },
      });

    case types.EDIT_ADJUSTMENT_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          rows: {
            ...state.creation.rows,
            [action.payload.rowId]: {
              ...state.creation.rows[action.payload.rowId],
              ...action.payload.data,
            },
          },
        },
      });

    case types.UPLOAD_ADJUSTMENT_FILE_SUCCESS:
      return state.merge({
        creation: {
          ...state.creation,
          index: state.creation.index + action.payload.data.length,
          rows: {
            ...state.creation.rows,
            ...createRows(
              state.selectorColumns,
              state.creation.index,
              action.payload.data
            ),
          },
        },
      });

    case types.DUPLICATE_ADJUSTMENT_SUCCESS:
      return state.merge({
        creation: {
          ...state.creation,
          date: action.payload.data.date,
          type: action.payload.data.type,
          initialRow: action.payload.data.rows,
        },
      });

    case types.CREATE_ADJUSTMENT_SUCCESS:
    case types.EXIT_ADJUSTMENT_CREATION:
      return state.merge({
        availableDates: [],
        creation: initialCreation,
      });

    case types.FETCH_ADJUSTMENT_AVAILABLE_DATES_SUCCESS:
      return state.merge({
        availableDates: action.payload.data,
      });

    default:
      return state;
  }
};
