import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConfigProvider, Select } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { GA, generateSelectOptions } from 'modules/core/utils';
import PrivateRoute from './PrivateRoute';
import { Home } from 'modules/home/containers';
import { Login, ResetPasswordForm } from 'modules/login/containers';
import { Home as HomeConfigurations } from 'modules/configuration/containers';
import { Home as HomePeriods } from 'modules/periods/containers';
import { Home as HomePlanning } from 'modules/planning/containers';
import { Home as HomeReports } from 'modules/reports/containers';
import { Home as HomeControl } from 'modules/control/containers';
import { Home as HomeForecast } from 'modules/forecast/containers';
import { NotFoundScreen } from 'modules/core/components';
import login from 'modules/login';

// i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationES, translationEN } from 'modules/core/i18n';

// Formatters
import numeral from 'numeral';

// LocalProvider configuration
import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';
import moment from 'moment';
import 'moment/locale/es';

try {
  moment.locale('es');
} catch (e) {
  console.warn('TypeError: es locale already registered.');
}

// Config i18n
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      es: { translation: translationES },
      en: { translation: translationEN },
    },
    lng: 'es',
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false,
    },
  });

// Config numeral
// load a locale
try {
  numeral.register('locale', 'es', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'M',
      billion: 'B',
      trillion: 'T',
    },
    ordinal: function (number) {
      var b = number % 10;
      return b === 1 || b === 3
        ? 'er'
        : b === 2
        ? 'do'
        : b === 7 || b === 0
        ? 'mo'
        : b === 8
        ? 'vo'
        : b === 9
        ? 'no'
        : 'to';
    },
    currency: {
      symbol: '$',
    },
  });
} catch (e) {
  console.warn('TypeError: es locale already registered.');
}

// switch between locales
try {
  numeral.locale('es');
} catch (e) {
  console.warn('TypeError: es locale already registered.');
}

const LANGUAJE_OPTIONS = [
  {
    id: 'es',
    name: i18n.t('ES'),
  },
  {
    id: 'en',
    name: i18n.t('EN'),
  },
];

// todo: analizar cuando cambia de lenguaje en el login.
const App = ({
  isAuthenticating,
  whoAmI,
  loggedUser: { userId, lang },
  setLang,
}) => {
  const [locale, setLocale] = useState(esES);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticating) whoAmI();
  }, [whoAmI, isAuthenticating]);

  useEffect(() => {
    if (userId && lang !== i18n.language) {
      setIsLoading(true);
      i18n.changeLanguage(lang);
      numeral.locale(lang);
      moment.locale(lang);
      setLocale(lang === 'es' ? esES : enUS);
      setIsLoading(false);
    }
  }, [lang, userId]);

  //Google Analytics
  useEffect(() => {
    if (userId) GA.init(userId);
  }, [userId]);

  if (isAuthenticating) {
    return <>{i18n.t('FEEDBACK_LOADING')}</>;
  }

  const changeLang = lng => {
    i18n.changeLanguage(lng);
    numeral.locale(lng);
    moment.locale(lng);
    setLocale(lng === 'es' ? esES : enUS);
    if (userId && lang !== lng) setLang(userId, lng);
  };

  return (
    <ConfigProvider locale={locale}>
      <Select
        style={{
          position: 'fixed',
          zIndex: 2,
          top: 13,
          right: 10,
          width: 100,
        }}
        suffixIcon={<GlobalOutlined />}
        defaultValue={i18n.language}
        onChange={lng => changeLang(lng)}
        loading={isLoading}
        options={generateSelectOptions({ options: LANGUAJE_OPTIONS })}
      />
      <Switch>
        <PrivateRoute exact path="/login" component={Login} />
        <PrivateRoute
          exact
          path="/restablecercontraseña"
          component={ResetPasswordForm}
        />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/configuracion" component={HomeConfigurations} />
        <PrivateRoute path="/periodos" component={HomePeriods} />
        <PrivateRoute path="/planificacion" component={HomePlanning} />
        <PrivateRoute path="/seguimiento" component={HomeControl} />
        <PrivateRoute path="/forecast" component={HomeForecast} />
        <PrivateRoute path="/analisis" component={HomeReports} />
        {/* Paginas de estados / errores  */}
        <Route component={NotFoundScreen} />
      </Switch>
    </ConfigProvider>
  );
};

const mapStateToProps = state => ({
  isAuthenticating: login.selectors.getIsAuthenticating(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  whoAmI: login.actions.whoAmI,
  setLang: login.actions.editUserLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
