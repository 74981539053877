import { Empty } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import reports from 'modules/reports';
import { useTranslation } from 'react-i18next';

const ValidationNotData = ({
  isDimensionsLoading,
  availableDimensions,
  dimensionsListData,
}) => {
  const { t } = useTranslation();
  return (
    !isDimensionsLoading &&
    (_.isEmpty(availableDimensions) ||
      (!_.isEmpty(availableDimensions) && dimensionsListData.length <= 1)) && (
      <Empty
        description={
          _.isEmpty(availableDimensions)
            ? t('REPORT_SEGREGATED_ANALYSIS_NOT_DATA_EMPTY_DESCRIPTION')
            : t(
                'REPORT_SEGREGATED_ANALYSIS_HAS_ONE_DIMENSION_EMPTY_DESCRIPTION'
              )
        }
      />
    )
  );
};

const mapStateToProps = state => ({
  availableDimensions: reports.selectors.getAvailableDimensions(state),
});

export default connect(mapStateToProps, null)(ValidationNotData);
