import React from 'react';
import { Space, InputNumber } from 'antd';
import { Typography } from 'modules/core/components';
import moment from 'moment';

const InputMonth = ({
  index,
  month,
  disabled = false,
  onChange,
  style = {},
  className = '',
  ...props
}) => {
  return (
    <Space direction="vertical" key={index} style={style} className={className}>
      <Typography.Subtitle level={2}>
        {moment(month).format('MMM YYYY')}
      </Typography.Subtitle>
      <InputNumber {...props} disabled={disabled} onChange={onChange} />
    </Space>
  );
};

export default InputMonth;
