const report = {
  REPORT_CARD_TITLE_NO_CONFIG:
    'We need you to configure the structure of your report to be able to show you the data.',
  REPORT_CARD_TITLE_NO_DATA:
    'When the economic budget is under review or approved, here we will present a summary of the most important indicators of your report.',
  REPORT_GRAPHIC_TITLE: 'Evolution and monthly comparison',
  REPORT_TOOLTIP_CONFIGURATION_TITLE: 'The report has not been configured yet',
  REPORT_ALERT_INFO_MESSAGE:
    'Consolidated budgets and forecasts will not be displayed until they are published.',
  REPORT_STRUCTURE_FILE_NAME: 'Plika - Settings report (structure)',
  REPORT_EVOLUTION_FILE_NAME_DEFAULT: 'Plika - Settings report',
  REPORT_EVOLUTION_FILE_NAME:
    'Plika - Analysis Report - {{periodName}} (Monthly evolution)',
  REPORT_COMPARISON_FILE_NAME:
    'Plika - Analysis Report - {{periodName}} (Comparisons)',
  REPORT_CUSTOM_FILE_NAME_DEFAULT: 'Plika - Report',
  REPORT_FILE_NAME_DEFAULT: 'PLIKA - {{reportName}} - {{periodname}} (Report)',
  REPORT_EVOLUTION_SEGMENTED_ANALYSIS_FILE_NAME:
    'Plika - Analysis Report - {{periodName}} (Segmented Analysis)',
  REPORT_EVOLUTION_VERTICAL_ANALYSIS_COMPARISON_FILE_NAME:
    'Plika - Analysis Report - {{periodName}} (Comparative Vertical Analysis)',
  REPORT_EVOLUTION_VERTICAL_ANALYSIS_SEGMENTED_FILE_NAME:
    'Plika - Analysis Report - {{periodName}} (Segmented Vertical Analysis)',
  REPORT_QUICKSIGHT_PRINT_REPORT_FILE_NAME: 'Plika - Printed report',

  REPORT_OTHER_CARD_BODY_TEXT: 'Login to see details of the report',
  REPORT_OTHER_CHECKBOX_TEXT: 'Convert sign in selected line of report',
  REPORT_OTHER_CHECKBOX_TEXT_TOOTIP:
    'By converting the sign you will see negative values to positive reflected in your table.',
  REPORT_OTHER_VIEW_DETAIL_ACCOUNTS_TOOLTIP: 'See details of accounts',
  REPORT_OTHER_CHART_CARD_TITLE:
    'Comparison of {{linereportaccount}} by {{dimensionname}}',

  REPORT_TAB_SEGMENTED_ANALYSIS: 'Segmented Analysis',
  REPORT_TAB_VERTICAL_ANALYSIS: 'Vertical Analysis',
  REPORT_TAB_SEGMENTED_PL: `Segmented report`,
  REPORT_TAB_RDN_DETAIL: 'RDN Detail',
  REPORT_TAB_SEGREGATED_ANALYSIS: 'Segregated analysis',
  REPORT_VERTICAL_COMPARISON_TOOLTIP_TITLE:
    'Select a dimension first to choose a value',
  REPORT_VERTICAL_ANALYSIS_STRUCTURE_TOOLTIP_TITLE:
    'Select a report line first to see the data',
  REPORT_TAB_SEGMENTED_ANALYSIS_TOOLTIP_TITLE:
    'We do not have dimensions amounts charged to display data',
  REPORT_DIMENSION_SHOW_CANT_LABEL: 'Amount of visible dimensions',
  REPORT_EDITION_KPIS_INFORMATIVE_ALERT_DESCRIPTION:
    'Changes made to the order and display of KPIs will not be permanently reflected.',
  REPORT_RDN_DETAIL_INFORMATIVE_TOOLTIP_TITLE:
    'In order to see this report you have to set a consolidated budget',
  REPORT_RDN_DETAIL_EMPTY_SCREEN_DESCRIPTION: `There isn't business rule data to display`,

  REPORT_PLIKA_CARD_TITLE: 'Plika reports',
  REPORT_PLIKA_CARD_BODY_TITLE: 'Analyze your most relevant metrics',
  REPORT_PLIKA_CARD_BODY_DESCRIPTION:
    'Access your reports, to visualize the most relevant metrics of your data, evolution graphics and comparisons.',

  //reporte self-service
  REPORT_SELF_SERVICE_FIELD: 'Self-Services Quicksight reports',
  REPORT_SELF_SERVICE_CARD_BODY_TITLE:
    'Create reports with the information you want',
  REPORT_SELF_SERVICE_ENTER_PERIOD_DESCRIPTION:
    'Visualize and organize the information you want accompanied by graphics to analyze your data easily and quickly.',
  REPORT_SELF_SERVICE_LOAD_DATA_BUTTON_TITLE: 'Load XLS data',
  REPORT_SELF_SERVICE_REPORT_PRINT_BUTTON_TITLE: 'Print reports',
  REPORT_SELF_SERVICE_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'If you click this button, you will be able to use the data you have loaded in Plika to assemble a Self-Service report in Quicksight.',

  REPORT_VALUES_RATIO: 'Values ​​with ratio',

  //multimoneda
  REPORT_DUPLICATE_MODAL: 'Duplicate report',
  REPORT_DUPLICATE_NAME: 'Report name',
  REPORT_DUPLICATE_NAME_PLACEHOLDER: 'For example: EERR converted to USD',
  REPORT_DUPLICATE_CHOOSE_CURRENCY:
    'Choose the functional currency in which you want to visualize your report',
  REPORT_DUPLICATE_LOAD_EXCHANGE_RATE: 'Load your exchange rate',
  REPORT_DUPLICATE_DROPDOWN_ITEM: 'Duplicate and convert',

  //financiero

  REPORT_FINANCIAL_NO_INITIAL_SITUATION_ALERT_DESCRIPTION:
    'We have no initial situation so you can see the detail of your financial report. Please enter the value in at least one of the following fields to be able to visualize it.',
  REPORT_FINANCIAL_INITIAL_SITUATION_MODAL_TITLE: 'Initial situation',
  REPORT_FINANCIAL_ENTER_VALUE_INFORMATIVE_TOOLTIP_TITLE:
    'You must enter at least one value',
  REPORT_FINANCIAL_NO_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'There are no data loaded from the previous period to visualize this section',
  REPOR_FINANCIAL_NO_INITIAL_SITUATION_PREV_REAL_ALERT_DESCRIPTION:
    'We have no information from the previous period to be able to show this section. We need that you enter a value to establish as an initial situation and thus, to be able to show you information.',
  REPORT_FINANCIAL_CARD_TITLE_NO_DATA:
    'Here we will present a summary of the most important indicators of your report. To be able to see them, the data must be published and approved up to the last month you have uploaded',

  REPORT_COMPARISON_MONTH_INFORMATIVE_MESSAGE_ALERT:
    'You must first choose the dates to compare to be able to visualize the table.',
  REPORT_COMPARISON_MONTH_FILE_NAME:
    'Plika - Analysis Profit & Loss - {{periodName}} (monthly comparative).xlsx',

  REPORT_SEGREGATED_ANALYSIS_CHOOSE_PRINCIPAL_DIMENSION_VALUES_TYPOGRAPHY:
    'Choose the values ​​of the dimension that you want to visualize as a click',
  REPORT_SEGREGATED_ANALYSIS_CHOOSE_SECONDARY_DIMENSION_VALUES_TYPOGRAPHY:
    'Choose the values ​​of the dimension that you want to visualize as secondary',
  REPORT_SEGREGATED_ANALYSIS_OPTION_DEFAULT_COLUMNS_VIEW_RADIO_GROUP:
    'Default (report configuration)',
  REPORT_SEGREGATED_ANALYSIS_NOT_DATA_EMPTY_DESCRIPTION:
    'According to the configuration of this report we have no information to show.',
  REPORT_SEGREGATED_ANALYSIS_HAS_ONE_DIMENSION_EMPTY_DESCRIPTION: `We only find data from a dimension for this report, so you can't perform your analysis`,
};

export default report;
