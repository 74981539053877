import {
  getOnboardingStepsContent,
  setLocaleNamesTranslate,
} from 'modules/core/utils';

//URL's PLIKA
const PLANNING_URL =
  'https://getplika.com/manual-de-usuario-plika/modulosplika/planificacion/';

const CONTROL_URL =
  'https://getplika.com/manual-de-usuario-plika/modulosplika/seguimiento/';

const FORECAST_URL =
  'https://getplika.com/manual-de-usuario-plika/modulosplika/modulos/';

const REPORTS_URL =
  'https://getplika.com/manual-de-usuario-plika/modulosplika/analisis/';

const onboardingDashboardSteps = (history, periodId) => [
  {
    content: getOnboardingStepsContent({
      text: 'ONBOARDING_PLANNING_DESCRIPTION',
      action: () => history.push(`/planificacion/${periodId}`),
      plika_url: PLANNING_URL,
    }),
    target: '.planning',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'ONBOARDING_CONTROL_DESCRIPTION',
      action: () => history.push(`/seguimiento/${periodId}`),
      plika_url: CONTROL_URL,
    }),
    target: '.control',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'ONBOARDING_FORECAST_DESCRIPTION',
      action: () => history.push(`/forecast/${periodId}`),
      plika_url: FORECAST_URL,
    }),
    target: '.forecast',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'ONBOARDING_REPORT_DESCRIPTION',
      action: () => history.push(`/analisis/${periodId}`),
      plika_url: REPORTS_URL,
    }),
    target: '.reports',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
];

export default onboardingDashboardSteps;
