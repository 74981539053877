import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navigator } from 'modules/core/components';
import planning from 'modules/planning';

const PeriodNavigator = ({ periodsList, fetchPeriodList, items = [] }) => {
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let { periodId } = useParams();
  let location = useLocation();

  useEffect(() => {
    periodsList &&
      setCurrentPeriod(
        periodsList.find(period => period.id === parseInt(periodId))
      );
  }, [periodId, periodsList]);

  useEffect(() => {
    fetchPeriodList().then(() => setIsLoading(false));
  }, [fetchPeriodList]);

  return (
    !isLoading && (
      <Navigator
        items={[
          {
            name: currentPeriod.name,
            children:
              periodsList.length > 1
                ? [
                    ...periodsList
                      .filter(period => period.id !== parseInt(periodId))
                      .map(period => ({
                        name: period.name,
                        url: `/${location.pathname.split('/')[1]}/${
                          period.id
                        }/${location.search}`,
                      })),
                  ]
                : null,
          },
          ...items,
        ]}
      />
    )
  );
};

const mapStateToProps = state => ({
  periodsList: planning.selectors.getPeriods(state),
});

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriods,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodNavigator);
