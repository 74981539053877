const planning = {
  //Files names
  PLANNING_TEMPLATE_NAME_DEFAULT: 'Plika Template- Planning',
  PLANNING_TEMPLATE_NAME:
    'Plika Template - Planning {{concept}} - {{periodName}} {{loadTypeName}}',
  PLANNING_EVOLUTION_TABLE_FILE_NAME: `Plika - Planning {{concept}} - {{periodName}} {{loadTypeName}} (monthly evolution)`,
  PLANNING_CONSOLIDATION_EVOLUTION_TABLE_FILE_NAME: `Plika - Pllaning {{consoName}}({{reportName}}) - {{periodName}} (Consolidation)`,
  PLANNING_EVOLTION_FILE_NAME: 'Plika - Planning (monthly evolution)',

  PLANNING_DISABLED_CREATION_TOOLTIP:
    'You cannot create new budgets because this period already has approved budgets',
  PLANNING_DISABLED_DELETE_TOOLTIP: 'Unable to delete an approved budget',

  //Text modal create budget
  PLANNING_CREATE_FORM_BUDGET_TITLE: 'Create new budget',
  PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK:
    'The budget was created successfully.',
  PLANNING_CREATE_BUDGET_ERROR_FEEDBACK:
    "We couldn't create the budget. Please try again.",
  PLANNING_CREATE_FORM_BUDGET_PLACEHOLDER: 'For example:{{name}} new',

  //Texts modal deadline
  PLANNING_CREATE_FORM_DEADLINE_DATE_PLACEHOLDER: 'Select day',
  PLANNING_CREATE_FORM_DEADLINE_TIME_PLACEHOLDER: 'Select time',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_LABEL: 'Create reminder',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE: 'None',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_IN_DEADLINE: 'On deadline',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_ONE_DAY_BEFORE:
    '1 day before',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_TWO_DAY_NEFORE:
    ' 2 days before',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_CHECKBOX_LABEL:
    'Send deadline notification by e-mail',
  PLANNING_CREATE_DEADLINE_SUCCESS_FEEDBACK:
    'The upload deadline has been set.',
  PLANNING_CREATE_DEADLINE_ERROR_FEEDBACK:
    'We were unable to set the upload deadline. Please try again.',
  PLANNING_UPDATE_DEADLINE_SUCCESS_FEEDBACK:
    'Upload deadline has been updated successfully.',
  PLANNING_UPDATE_DEADLINE_ERROR_FEEDBACK:
    'We were unable to update the upload deadline. Please try again.',
  PLANNING_DEADLINE_MODAL_TITLE: '{{file}} upload deadline',

  //Cards empty
  PLANNING_EMPTY_CARD_BODY: 'Enter and start budgeting',

  //Popconfirm delete
  PLANNING_TEXT_DELETE_BUDGET_POPCONFIRM:
    'Are you sure you want to delete this budget?',

  //Notification budget
  PLANNING_COPY_BUDGET_SUCCESS_FEEDBACK:
    'Budget copy was created successfully.',
  PLANNING_COPY_BUDGET_ERROR_FEEDBACK:
    'We were unable to create budget copy. Please try again.',
  PLANNING_DELETE_BUDGET_SUCCESS_FEEDBACK: 'Budget was successfully removed.',
  PLANNING_DELETE_BUDGET_ERROR_FEEDBACK:
    "We couldn't delete this budget. Please try again.",
  PLANNING_EDIT_NAME_BUDGET_SUCCESS_FEEDBACK:
    'Budget name was successfully edited.',
  PLANNING_EDIT_NAME_BUDGET_ERROR_FEEDBACK:
    'We were unable to edit budget name. Please try again.',

  //Popconfirm consolidation
  PLANNING_TEXT_DELETE_CONSOLIDATION_POPCONFIRM:
    'Are you sure you want to remove this consolidation?',

  //Modal consolidation create
  PLANNING_CONSOLIDATION_CREATE_FORM_TITLE: 'Create new consolidation',
  PLANNING_CONSOLIDATION_CREATE_FORM_INPUT_PLACEHOLDER:
    'For example: new consolidation',
  //Empty screen load file
  PLANNING_EMPTY_SCREEN_UPLOAD_SUBTITLE: 'Start loading your budget',
  PLANNING_UPLOAD_MODAL_TITLE: 'Load budget',

  //Drawer scenario
  PLANNING_VARS_EMPTY_SCREEN_UPLOAD_SUBTITLE: 'Start loading your variables',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES_VALUES:
    'The variable has no assigned values yet.',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES: 'There are no variables yet',
  PLANNING_VARIABLE_INCOMPLETE_VALUES_TOOLTIP:
    'Values must be completed for all months',

  PLANNING_VARIABLE_APPLY_SUCCESS_FEEDBACK:
    'The variables were applied successfully.',
  PLANNING_VARIABLE_APPLY_ERROR_FEEDBACK:
    'We could not apply the variable. Please try again.',

  PLANNING_VARIABLE_EDIT_TOOLTIP: 'Click for variable edit',
  PLANNING_VARIABLE_CONFIG_TOOLTIP:
    'To edit this field, please go to variables configuration',
  PLANNING_VARIABLE_CONFIG_LINK: 'Go to variables list',
  PLANNING_VARIABLE_CONFIG_LINK_ACTION: 'Edit from configuration',

  PLANNING_VARIABLES_RETURN_CONFIRM_TITLE: 'Return to variables` list ',
  PLANNING_VARIABLES_RETURN_CONFIRM_TEXT:
    'Are you sure you want to return to variables` list?',
  PLANNING_VARIABLES_RETURN_CONFIRM_TOOLTIP:
    ' If you return without saving you will lose the values loaded so far',

  PLANNING_ACTION_MODAL_TITLE: '{{action}} budget',
  PLANNING_APPROVE_MODAL_TEXT:
    'You are about to finalize the budget process for your period. Are you sure you want to do it? Remember that once you approve the budget, you can no longer modify any data or upload more files.',
  PLANNING_SCREEN_SUCCESS_FEEDBACK:
    'You have just {{action}} the {{period}} :)', //NOTE: COMENTADO  HASTA QUE SE PUEDA NOTIFICAR. Do you want to break the news to everyone who participated in this planning?',
  PLANNIG_PUBLISH_MODAL_TEXT:
    'You are about to publish your budget. In this instance you can edit, continue loading data, compare quotes each other and visualize your data analysis, monitoring and forecast.',

  //Empty screen
  PLANNING_EMPTY_SCREEN_TITLE: "You don't have any budget created yet!",
  PLANNING_EMPTY_SCREEN_BODY:
    'Create budget, set priorities and build the number of projections as well as different scenarios you have',
  PLANNING_BUDGET_CREATE_ACTION: 'Create budget',

  //Empty screen consolidation
  PLANNING_CONSOLIDATION_EMPTY_SCREEN_TITLE:
    "You still don't have any consolidation created!",
  PLANNING_CONSOLIDATION_ALERT_WARNING_TITLE:
    "We still don't have all the data we need so you can create your consolidations",
  PLANNING_CONSOLIDATION_ALERT_WARNING_BODY:
    'In order for you to create consolidations we must have at least a Sales budget and an Expense budget created. Once you have, you can create all the consolidations that you consider necessary.',
  PLANNING_CONSOLIDATION_NO_SALES_WARNING: 'No Sales with data loaded',
  PLANNING_CONSOLIDATION_NO_EXPENSES_WARNING: 'No Expenses with data loaded',
  PLANNING_CONVENTIONAL_UPLOAD_TITLE: 'Conventional load',
  PLANNING_UPLOAD_TYPE_LABEL: 'Type of load',
  PLANNING_SELECT_FACTOR_LABEL: 'Select a factor',
  PLANNING_VALUES_VARS_APPLIED: 'Values with variables applied',
  PLANNING_VALUES_FACTORS_APPLIED: 'Values with factors applied',

  PLANNING_CONSOLIDATION_BUDGET_FIXED_POPOVER_TEXT:
    'Selected this budget as Default. This means you will visualize your data across the tool.',
  PLANNING_CONSOLIDATION_NOT_DATA_BUDGET_TOOLTIP_TITLE:
    'You do not have data loaded into sales and expenses. To make a consolidation you must have data on budgets.',
  PLANNING_CONSOLIDATION_UPDATE_DATA_TOOLTIP_TITLE:
    'Data was edited in Sales or Expenses. Tap the refresh button.',
  PLANNING_CONSOLIDATION_NOT_UPDATE_DATA_TOOLTIP_TITLE: 'No pending updates.',
  PLANNING_CONSOLIDATION_MODIFY_BUDGET_POPCONFIRM_TITLE:
    'If you change the settings for the consolidated, all data will be updated. Are you sure you want to continue?',
  PLANNING_CONSOLIDATION_INFORMATIVE_TOOLTIP_TITLE:
    'Click to set this budget as default.',

  //reglas de negocio
  PLANNING_FORMULAS_INFORMATIVE_ALERT:
    'Remember that until you don`t load all its factors amounts, you won`t see that business rule reflected in the summary.',
  PLANNING_FORMULAS_DESCRIPTION_NOTIFICATION_SUCCESS:
    'To display enter the factor details`.',
  PLANNING_FACTOR_EMPTY_STATE_TITLE: 'Let`s get started loading a factor!',
  PLANNING_FORMULAS_EMPTY_SCREEN_TITLE:
    'You still have no business rules configured!',
  PLANNING_SUMMARY_EMPTY_SCREEN_DESCRIPTION:
    'Start loading your budget by amount and/or rule to be able to see your summary.',
  PLANNING_FACTOR_BACK_TO_RULES_ACTION: 'Back to rules` list',
  PLANNING_FORMULA_GO_TO_LOAD_FACTOR_TEXT: 'to start charging.',
  PLANING_FORMULA_UNAPPLY_POPCONFIRM_TEXT:
    'Are you sure you want to {{action}} the rule?',
  PLANNING_FORMULA_APPLY_TOOLTIP_TITLE:
    'Apply this rule to view it in the summary.',
  PLANNING_FORMULA_APPLY_POPCONFIRM_PENDING_TEXT:
    'Are you sure you want to apply the business rule without all the factors loaded?',
  PLANNING_FORMULA_UNAPPLY_TOOLTIP_TITLE:
    'Disengages the business rule to edit/load factor data.',
  PLANNING_FORMULA_LOAD_DATA_TOOLTIP_TITLE:
    'You must upload data to enable this action.',
  PLANNING_FACTOR_REPLICATE_INFORMATIVE_ALERT_DESCRIPTION:
    'Replicate the values ​​of one factor to another factor in the business rule you want.',
  PLANNING_FORMULA_BUTTON_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'You must break the rule to be able to load more information.',
  PLANNING_FACTOR_POPCONFIRM_SAVE_CHANGES:
    'Are you sure you want to save the changes made? Keep in mind that this will impact the calculation and its results.',

  PLANNING_PXQ_DESCRIPTION_ENABLE_NOTIFICATION_ERROR:
    'We could not enable PXQ for projection.',
  PLANNING_PXQ_DESCRIPTION_CREATE_NOTIFICATION_ERROR:
    'We could not get PXQ information from the projection.',

  PLANNING_DISPLAY_NEGATIVE_DATA_FORM_LABEL: 'Display Negative values',

  //multimoneda
  PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_1: 'Enter',
  PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_2: 'here',
  PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_3:
    'to load the exchange rate, and so start budgeting.',
  PLANNING_CURRENCY_LOAD_EXCHANGE_RATE_TOOLTIP_TITLE:
    'You must first load the exchange rate to be able to enter',
  PLANNING_CURRENCY_EMPTY_SCREEN_TITLE:
    'You do not have any activated origin currencies to show yet!',
  PLANNING_CURRENCY_CANCEL_POPCONFIRM_TITLE:
    'Are you sure you want to cancel the changes?',
  PLANNING_CURRENCY_LOAD_ALL_EXCHANGE_RATE_ALERT_DESCRIPTION:
    'To save the exchange rate for every month they must have a loaded value.',
  PLANNING_CURRENCY_ALL_VALUES_TOOLTIP_TITLE:
    'You must complete with values every month',
};

export default planning;
