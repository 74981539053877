import React from 'react';
import { connect } from 'react-redux';
import { Card, List, Button, Row, Col } from 'antd';
import {
  PeriodNavigator,
  ButtonActionBar,
  Typography,
} from 'modules/core/components';
import { useHistory, useParams } from 'react-router-dom';
import reportsSelfServiceImg from 'assets/images/compositions/reports-self-service.svg';
import reportsPlikaImg from 'assets/images/compositions/reports-plika.svg';
import { RightCircleOutlined } from '@ant-design/icons';
import { SiderLayout } from 'modules/core/layouts';
import { useTranslation } from 'react-i18next';
import { ACCES_USERS } from 'modules/core/constants';
import login from 'modules/login';

import './ReportCardsHome.scss';

const ReportsCardsHome = ({ loggedUser: { email } }) => {
  const { t } = useTranslation();
  let history = useHistory();
  let { periodId } = useParams();

  const renderHeader = () => (
    <PeriodNavigator items={[{ name: t('LABEL_ANALYSIS') }]} />
  );

  const reportCardsLids = [
    {
      id: 1,
      title: 'REPORT_PLIKA_CARD_TITLE',
      description: {
        title: 'REPORT_PLIKA_CARD_BODY_TITLE',
        description: 'REPORT_PLIKA_CARD_BODY_DESCRIPTION',
      },
      urlName: 'reportesplika',
      img: reportsPlikaImg,
    },
    ...([...ACCES_USERS.PATCH_ACCES_DASHBOARD_QUICKSIGHT_USERS].some(el =>
      email.includes(el)
    ) //NOTE: HARDCODEADO POR AHORA. REVER CUANDO ESTE EL ENDPOINT CON LOS USUARIOS QUE PUEDEN ENTRAR
      ? [
          {
            id: 2,
            title: 'REPORT_SELF_SERVICE_FIELD',
            description: {
              title: 'REPORT_SELF_SERVICE_CARD_BODY_TITLE',
              description: 'REPORT_SELF_SERVICE_ENTER_PERIOD_DESCRIPTION',
            },
            urlName: 'reportesselfservice',
            img: reportsSelfServiceImg,
          },
        ]
      : []),
  ];

  const renderCardDescription = ({ title, description, img }) => (
    <Row align="middle">
      <Col span={16}>
        <Typography.Body level={2}>{title}</Typography.Body>
        <br />
        {description && (
          <Typography.Body level={3} type="secondary">
            {description}
          </Typography.Body>
        )}
      </Col>
      <Col span={4} offset={2}>
        <img alt="img" height="100" src={img} />
      </Col>
    </Row>
  );

  return (
    <SiderLayout>
      {renderHeader()}
      <List
        grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
        dataSource={reportCardsLids}
        renderItem={infoReport => {
          const { id, title, description, urlName, img } = infoReport;
          return (
            <List.Item>
              <Card
                key={id}
                className="reports-list-cards"
                title={t(title)}
                data-type={urlName}
                actions={[
                  <ButtonActionBar position="start">
                    <Button
                      type="link"
                      onClick={() =>
                        history.push(`/analisis/${periodId}/${urlName}`)
                      }
                    >
                      {t('ACTION_ENTER')}
                      <RightCircleOutlined />
                    </Button>
                  </ButtonActionBar>,
                ]}
              >
                {renderCardDescription({
                  title: t(description.title),
                  description: t(description.description),
                  img,
                })}
              </Card>
            </List.Item>
          );
        }}
      />
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

export default connect(mapStateToProps, null)(ReportsCardsHome);
