import React, { useEffect, useState } from 'react';
import { Table, Typography, Space } from 'antd';
import { useFunctionalCurrency, usePagination } from 'modules/core/customHooks';
import { connect } from 'react-redux';
import planning from 'modules/planning';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { renderPrefix } from 'modules/core/utils';

const { Link } = Typography;

const ActivityTrackingDetail = ({
  projectionId,
  tracks: { values, count },
  fetchTracks,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [tablePagination, setTablePagination] = usePagination();
  const functionalCurrencyData = useFunctionalCurrency();
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    fetchTracks(projectionId, tablePagination).then(() =>
      setIsTableLoading(false)
    );
  }, [fetchTracks, projectionId, tablePagination]);

  const columns = [
    {
      title: t('FIELD_DATE'),
      dataIndex: 'timestamp',
      render: value =>
        `${moment(value).format('DD/MM/YYYY')} ${t('DATE_AT_TIME')} ${moment(
          value
        ).format('HH:mm')}`,
    },
    {
      title: t('LABEL_USER'),
      dataIndex: 'user',
    },
    {
      title: t('FIELD_ACTION'),
      dataIndex: 'action',
    },
    {
      title: t('FIELD_LOADING_SOURCE'),
      dataIndex: 'integration',
      width: 150,
      render: integration =>
        integration ? t('FIELD_INTEGRATION_LOAD') : t('FIELD_MANUAL_LOAD'),
    },
    {
      title: t('FIELD_DESCRIPTION'),
      dataIndex: 'description',
      width: 120,
      render: description => {
        if (description.input_file !== null) {
          if (description.error_file !== null) {
            return description.name === 'file_load' ? (
              <Space direction="vertical">
                <Link href={description.input_file.url} target="_blank">
                  {description.input_file.name}
                </Link>
                <Link href={description.error_file.url} target="_blank">
                  {description.error_file.name}
                </Link>
              </Space>
            ) : (
              description.name
            );
          } else {
            return description.name === 'file_load' ? (
              <Link href={description.input_file.url} target="_blank">
                {description.input_file.name}
              </Link>
            ) : (
              description.name
            );
          }
        } else {
          return description.name;
        }
      },
    },
    {
      title: t('FIELD_IMPACT'),
      dataIndex: 'impact',
      align: 'right',
      render: value =>
        value || value !== '-'
          ? `${renderPrefix(functionalCurrencyData)} ${numeral(value).format(
              '0,0'
            )}`
          : '-',
    },
  ];

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={isTableLoading}
      onChange={setTablePagination}
      columns={columns}
      dataSource={values}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: count,
        size: 'small',
      }}
    />
  );
};

const mapStateToProps = state => ({
  tracks: planning.selectors.getUserTracks(state),
});

const mapDispatchToProps = {
  fetchTracks: planning.actions.fetchUserTracks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTrackingDetail);
