import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'modules/core/customHooks';
import { SiderLayout } from 'modules/core/layouts';
import { PeriodNavigator } from 'modules/core/components';
import { Tabs, Spin } from 'antd';
import { ProjectionsList, ConsolidationList } from './components';
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils';
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants';
import planning from 'modules/planning';
import forecast from 'modules/forecast';

const { FORECAST__EXPENSES, FORECAST__SALES, FORECAST__CONSOLIDATED } = ROLES;

const ForecastList = ({
  forecastDetail,
  conceptsList,
  fetchForecastDetail,
  fetchConceptsList,
  periodDetail,
  fetchPeriodDetail,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const { t } = useTranslation();
  let { periodId, forecastId } = useParams();
  let query = useQuery();

  useEffect(() => {
    Promise.all([
      fetchPeriodDetail(periodId),
      fetchForecastDetail(forecastId),
      fetchConceptsList(),
    ]).then(() => setIsScreenLoading(false));
  }, [
    fetchForecastDetail,
    fetchPeriodDetail,
    periodId,
    forecastId,
    fetchConceptsList,
  ]);

  const PANEL__ACCESS = {
    [PROJECTION.CONSOLIDATED]: isUserAllowed(
      getOperationsByKeys([FORECAST__CONSOLIDATED])
    ),
    [CONCEPTS.KEYS.SALES]: isUserAllowed(
      getOperationsByKeys([FORECAST__SALES])
    ),
    [CONCEPTS.KEYS.EXPENSES]: isUserAllowed(
      getOperationsByKeys([FORECAST__EXPENSES])
    ),
  };

  const showDefaultPanelKey = () => {
    if (PANEL__ACCESS[CONCEPTS.KEYS.SALES]) {
      return CONCEPTS.KEYS.SALES;
    } else if (PANEL__ACCESS[CONCEPTS.KEYS.EXPENSES]) {
      return CONCEPTS.KEYS.EXPENSES;
    } else if (PANEL__ACCESS[PROJECTION.CONSOLIDATED]) {
      return 'consolidacion';
    }
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        { name: t('LABEL_FORECAST'), url: `/forecast/${periodId}` },
        {
          name: `${t('LABEL_DETAIL')}: ${
            forecastDetail.name ? forecastDetail.name : ''
          }`,
        },
      ]}
    />
  );

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      {!isScreenLoading && (
        <Tabs
          defaultActiveKey={
            query.get('tab') !== null ? query.get('tab') : showDefaultPanelKey()
          }
          size="small"
        >
          {conceptsList.values.map(
            concept =>
              PANEL__ACCESS[concept.name] && (
                <Tabs.TabPane tab={t(concept.name)} key={concept.name}>
                  <ProjectionsList concept={concept} />
                </Tabs.TabPane>
              )
          )}
          {PANEL__ACCESS[PROJECTION.CONSOLIDATED] && (
            <Tabs.TabPane tab={t('LABEL_CONSOLIDATION')} key="consolidacion">
              <ConsolidationList periodDetail={periodDetail} />
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  forecastDetail: forecast.selectors.getForecastDetail(state),
  conceptsList: planning.selectors.getConceptsList(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
});

const mapDispatchToProps = {
  fetchForecastDetail: forecast.actions.fetchForecastDetail,
  fetchConceptsList: planning.actions.fetchConceptsList,
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastList);
