import * as types from './constants';
import i18n from 'i18next';
import { saveAs } from 'file-saver';
import queryString from 'query-string';

export const fetchForecasts =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FORECASTS,
      payload: {
        request: {
          url: '/planning/forecasts/',
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchForecastDetail = id => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_FORECAST_DETAIL,
    payload: {
      request: {
        url: `/planning/forecasts/`,
        method: 'GET',
        params: {
          id: id,
        },
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const createForecast =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_FORECAST,
      payload: {
        request: {
          url: '/planning/forecasts/',
          method: 'POST',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const deleteForecast = forecastId => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_FORECAST,
    payload: {
      request: {
        url: `/planning/forecasts/${forecastId}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchForecastCreatable =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATABLE_FORECAST,
      payload: {
        request: {
          url: '/planning/forecasts/creatable/',
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchForecastProjections =
  (forecastId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FORECAST_PROJECTIONS,
      payload: {
        request: {
          url: `/planning/forecasts/${forecastId}/projections/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editForecastName = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_FORECAST_NAME,
    payload: {
      request: {
        url: `/planning/forecasts/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const downloadForecastEvolutionXLS =
  (
    reportId,
    params = {},
    filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_FORECAST_EVOLUTION_XLS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/excel_download-forecast-consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };
