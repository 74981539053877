export const NAME = 'distributions';

export const FETCH_DISTRIBUTIONS = `${NAME}/FETCH_DISTRIBUTIONS`;
export const FETCH_DISTRIBUTIONS_SUCCESS = `${NAME}/FETCH_DISTRIBUTIONS_SUCCESS`;
export const FETCH_DISTRIBUTIONS_FAIL = `${NAME}/FETCH_DISTRIBUTIONS_FAIL`;

export const FETCH_DISTRIBUTION_ORIGIN_COLUMNS = `${NAME}/FETCH_DISTRIBUTION_ORIGIN_COLUMNS`;
export const FETCH_DISTRIBUTION_ORIGIN_COLUMNS_SUCCESS = `${NAME}/FETCH_DISTRIBUTION_ORIGIN_COLUMNS_SUCCESS`;
export const FETCH_DISTRIBUTION_ORIGIN_COLUMNS_FAIL = `${NAME}/FETCH_DISTRIBUTION_ORIGIN_COLUMNS_FAIL`;

export const EDIT_DISTRIBUTION_ORIGIN_ROW = `${NAME}/EDIT_DISTRIBUTION_ORIGIN_ROW`;
export const EDIT_DISTRIBUTION_ORIGIN_ROW_SUCCESS = `${NAME}/EDIT_DISTRIBUTION_ORIGIN_ROW_SUCCESS`;
export const EDIT_DISTRIBUTION_ORIGIN_ROW_FAIL = `${NAME}/EDIT_DISTRIBUTION_ORIGIN_ROW_FAIL`;

export const FETCH_DISTRIBUTION_ORIGIN_BALANCE = `${NAME}/FETCH_DISTRIBUTION_ORIGIN_BALANCE`;
export const FETCH_DISTRIBUTION_ORIGIN_BALANCE_SUCCESS = `${NAME}/FETCH_DISTRIBUTION_ORIGIN_BALANCE_SUCCESS`;
export const FETCH_DISTRIBUTION_ORIGIN_BALANCE_FAIL = `${NAME}/FETCH_DISTRIBUTION_ORIGIN_BALANCE_FAIL`;

export const EDIT_DISTRIBUTION_VALUES = `${NAME}/EDIT_DISTRIBUTION_VALUES`;
export const ADD_DISTRIBUTION_DESTINATION_ROW = `${NAME}/ADD_DISTRIBUTION_DESTINATION_ROW`;
export const EDIT_DISTRIBUTION_DESTINATION_ROW = `${NAME}/EDIT_DISTRIBUTION_DESTINATION_ROW`;
export const REMOVE_DISTRIBUTION_DESTINATION_ROW = `${NAME}/REMOVE_DISTRIBUTION_DESTINATION_ROW`;
export const RESET_DISTRIBUTION_DESTINATION_ROWS = `${NAME}/RESET_DISTRIBUTION_DESTINATION_ROWS`;
export const COPY_DISTRIBUTION_ROWS = `${NAME}/COPY_DISTRIBUTION_ROWS`;

export const DOWNLOAD_DISTRIBUTION_TEMPLATE_XLS = `${NAME}/DOWNLOAD_DISTRIBUTION_TEMPLATE_XLS`;

export const UPLOAD_DISTRIBUTION_FILE = `${NAME}/UPLOAD_DISTRIBUTION_FILE`;
export const UPLOAD_DISTRIBUTION_FILE_SUCCESS = `${NAME}/UPLOAD_DISTRIBUTION_FILE_SUCCESS`;
export const UPLOAD_DISTRIBUTION_FILE_FAIL = `${NAME}/UPLOAD_DISTRIBUTION_FILE_FAIL`;

export const CREATE_DISTRIBUTION = `${NAME}/CREATE_DISTRIBUTION`;
export const CREATE_DISTRIBUTION_SUCCESS = `${NAME}/CREATE_DISTRIBUTION_SUCCESS`;
export const CREATE_DISTRIBUTION_FAIL = `${NAME}/CREATE_DISTRIBUTION_FAIL`;

export const EXIT_DISTRIBUTION_CREATION = `${NAME}/EXIT_DISTRIBUTION_CREATION`;

export const CANCEL_DISTRIBUTION = `${NAME}/CANCEL_DISTRIBUTION`;
export const CANCEL_DISTRIBUTION_SUCCESS = `${NAME}/CANCEL_DISTRIBUTION_SUCCESS`;
export const CANCEL_DISTRIBUTION_FAIL = `${NAME}/CANCEL_DISTRIBUTION_FAIL`;

export const FETCH_DISTRIBUTION_DETAIL = `${NAME}/FETCH_DISTRIBUTION_DETAIL`;
export const FETCH_DISTRIBUTION_DETAIL_SUCCESS = `${NAME}/FETCH_DISTRIBUTION_DETAIL_SUCCESS`;
export const FETCH_DISTRIBUTION_DETAIL_FAIL = `${NAME}/FETCH_DISTRIBUTION_DETAIL_FAIL`;

export const DUPLICATE_DISTRIBUTION = `${NAME}/DUPLICATE_DISTRIBUTION`;
export const DUPLICATE_DISTRIBUTION_SUCCESS = `${NAME}/DUPLICATE_DISTRIBUTION_SUCCESS`;
export const DUPLICATE_DISTRIBUTION_FAIL = `${NAME}/DUPLICATE_DISTRIBUTION_FAIL`;

export const DOWNLOAD_DISTRIBUTIONS_XLS = `${NAME}/DOWNLOAD_DISTRIBUTIONS_XLS`;
export const DOWNLOAD_SINGLE_DISTRIBUTIONS_XLS = `${NAME}/DOWNLOAD_SINGLE_DISTRIBUTIONS_XLS`;
