import React, { useState, useEffect } from 'react';
import { Spin, Select, Typography } from 'antd';
import { client } from 'httpclient';
import { useTranslation } from 'react-i18next';
import { handleSelectAll, generateSelectOptions } from 'modules/core/utils';

const { Text } = Typography;

const RoleDimensionSelector = ({
  dimension: { id = null, url, label = '', value = [], isAll = false },
  onChange,
  ...otherProps
}) => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [selectorOptions, setSelectorOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      setIsLoadingOptions(true);
      client
        .get(url)
        .then(response => {
          setSelectorOptions(response.data.results);
          setIsLoadingOptions(false);
          if (isAll) {
            onChange(
              response.data.results.map(el => el.id),
              true
            );
            setAreAllSelected(true);
          }
        })
        .catch(() => console.log('Dimension error'));
    }
    // eslint-disable-next-line
  }, [id, url]);

  return (
    <>
      <Text>
        <Text type="danger">*</Text> {label}
      </Text>
      <Spin size="small" spinning={isLoadingOptions}>
        <Select
          showSearch
          showArrow
          allowClear
          style={{ width: '100%', marginBottom: 24 }}
          placeholder={t('VALUES_PLACEHOLDER')}
          onChange={values =>
            handleSelectAll({
              values,
              allValues: selectorOptions.map(option => option.id),
              areAllSelected,
              onSetValues: (values, selected_all) =>
                onChange(values, selected_all),
              onSetAreAllSelected: state => setAreAllSelected(state),
            })
          }
          value={value.asMutable()}
          loading={isLoadingOptions}
          notFoundContent={
            isLoadingOptions ? (
              <Spin size="small" />
            ) : (
              t('FEEDBACK_DEFAULT_NOT_FOUND')
            )
          }
          mode="multiple"
          maxTagCount={1}
          maxTagTextLength={12}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          {...otherProps}
          options={generateSelectOptions({
            options: selectorOptions,
            includeOptionAll: true,
          })}
        />
      </Spin>
    </>
  );
};

export default RoleDimensionSelector;
