import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePagination } from 'modules/core/customHooks';
import { Button, Space, /*Alert,*/ Col, Row, Card, Spin, Input } from 'antd';
import {
  EmptyScreen,
  Typography,
  ButtonActionBar,
  //DropdownMenu,
  KPIValue,
} from 'modules/core/components';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import numeral from 'numeral';
//import { FileExcelOutlined } from '@ant-design/icons';
// import ConfigurationUploadModal from '../../../components/ConfigurationUploadModal/ConfigurationUploadModal';
import { VariablesTable, CreateVariableModal } from './components';
import { isUserAllowed } from 'modules/core/utils';
import { DIMENSIONS, ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';

import './Variables.scss';

const { Search } = Input;
const { SETTINGS__PROJECTION_ELEMENTS__VARIABLES__CREATE } = ROLES;

//TODO: Permisos de creación y edición
const VariablesList = ({
  variablesRows,
  variablesKpis,
  fetchVariablesList,
  fetchVariablesKpis,
  createVariable,
  dimensionValues,
  fetchDimensionValues,
  fetchDimensionsList,
  dimensionsList,
  fetchBehaviourOptions,
  optionsBehaviour,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isKpiLoading, setIsKpiLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  // const [showUploadModal, setShowUploadModal] = useState(false);
  const [duplicateVariable, setDuplicateVariable] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [tablePagination, setTablePagination] = usePagination();
  const [isDimensionBehaviourLoading, setIsDimensionBehaviourLoading] =
    useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    fetchVariablesList({
      writeable: 'False',
      search: searchValue,
      ...tablePagination,
    }).then(() => {
      setIsScreenLoading(false);
      setIsTableLoading(false);
    });
  }, [fetchVariablesList, searchValue, tablePagination]);

  useEffect(() => {
    setIsDimensionBehaviourLoading(true);
    Promise.all([
      fetchDimensionValues({
        dimension: DIMENSIONS.ACCOUNTS.id,
        flat: 'True',
        writeable: 'True',
      }),
      fetchDimensionsList({ flat: 'True', writeable: 'True' }),
      fetchBehaviourOptions(),
    ]).then(() => setIsDimensionBehaviourLoading(false));
  }, [fetchDimensionValues, fetchDimensionsList, fetchBehaviourOptions]);

  useEffect(() => {
    if (!searchValue)
      fetchVariablesKpis({ writeable: 'False' }).then(() =>
        setIsKpiLoading(false)
      );
  }, [fetchVariablesKpis, searchValue]);

  const userCanCreate = isUserAllowed(
    SETTINGS__PROJECTION_ELEMENTS__VARIABLES__CREATE
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_VARS_EMPTY_SCREEN_UPLOAD_SUBTITLE')}
      footer={
        // <Space direction="horizontal" size="large">
        //   <Button type="secondary" onClick={() => setShowUploadModal(true)}>
        //     {t('ACTION_UPLOAD_FILE')}
        //   </Button>
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('CONFIG_VAR_CREATE_ACTION')}
        </Button>
        // </Space>
      }
    />
  );

  //comentado hasta que esten los endpoints de descargar plantilla y cragar archivo
  // const renderTopAlert = () => (
  //   <Alert
  //     showIcon
  //     closable
  //     type="info"
  //     message={
  //       <Typography.Headline level={6} type={'primary'}>
  //         {t('UPLOAD_FILE_TITLE')}
  //       </Typography.Headline>
  //     }
  //     description={
  //       <div>
  //         {t('INFO_UPLOAD_FILE_BODY_1')}
  //         <div>
  //           {t('INFO_UPLOAD_FILE_BODY_2')}{' '}
  //           <span className="variables-download-template">
  //             {t('ACTION_DOWNLOAD_TEMPLATE')}
  //           </span>
  //         </div>
  //       </div>
  //     }
  //   />
  // );

  //comentado hasta que este lo de descargar plantilla y cargar archivo
  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="variable-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('ACTION_CREATE_NEW')}
        </Button>
        {/* <Button onClick={() => setShowUploadModal(true)}>
          {t('ACTION_UPLOAD_FILE')}
        </Button> */}
        {/* <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_DOWNLOAD_TEMPLATE'),
              icon: <FileExcelOutlined />,
            },
          ]}
        /> */}
      </Space>
    </ButtonActionBar>
  );

  const renderKpis = () => (
    <Card style={{ height: 144 }}>
      <Spin spinning={isKpiLoading} />
      {!isKpiLoading && (
        <Row align="middle">
          <Col span={10}>
            <KPIValue size="large" value={variablesKpis.amount} />
            <Typography.Body level={3} type="secondary">
              {variablesKpis.title}
            </Typography.Body>
          </Col>
          <Col span={14}>
            <Row gutter={[8, 8]}>
              {variablesKpis.children &&
                variablesKpis.children.map((kpi, index) => (
                  <Col span={24} key={index}>
                    <Row>
                      <Col span={12}>
                        <KPIValue prefix={kpi.prefix} value={kpi.amount} />
                        <br />
                        <Typography.Body level={3} type="secondary">
                          {kpi.title}
                        </Typography.Body>
                      </Col>
                      <Col span={12}>
                        <KPIValue
                          value={
                            kpi.percentage
                              ? numeral(kpi.percentage).format('0%')
                              : '-'
                          }
                          type="secondary"
                        />
                      </Col>
                    </Row>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  );

  const onRefreshData = () =>
    Promise.all([
      fetchVariablesList({ writeable: 'False' }),
      fetchVariablesKpis({ writeable: 'False' }),
    ]);

  return (
    <Space className="variables-container" direction="vertical" size="small">
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading &&
        !isTableLoading &&
        variablesRows.length <= 0 &&
        !searchValue && (
          <>
            {/* {renderTopAlert()} */}
            {renderNoDataScreen()}
          </>
        )}
      {((!isScreenLoading && variablesRows.length > 0) ||
        isTableLoading ||
        searchValue) && (
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={8}>{renderKpis()}</Col>
          <Col span={24}>
            <VariablesTable
              tableLoading={isTableLoading || isDimensionBehaviourLoading}
              dataSource={variablesRows}
              onDuplicateRow={data => {
                setDuplicateVariable(data);
                setShowCreateModal(true);
              }}
              onRefreshData={onRefreshData}
              handleTableChange={setTablePagination}
              tablePagination={tablePagination}
              accountsList={dimensionValues}
              dimensionsList={dimensionsList}
              optionsBehaviour={optionsBehaviour}
            />
          </Col>
        </Row>
      )}
      {!isScreenLoading && (
        <CreateVariableModal
          title={t('CONFIG_VAR_CREATE_MODAL_TITLE')}
          visible={showCreateModal}
          initialValues={duplicateVariable}
          onRefreshData={onRefreshData}
          onCancel={() => {
            setShowCreateModal(false);
            setDuplicateVariable({});
          }}
          onConfirm={createVariable}
          accountsList={dimensionValues}
          dimensionsList={dimensionsList}
          optionsBehaviour={optionsBehaviour}
        />
      )}
      {/* {!isScreenLoading && (
        <ConfigurationUploadModal
          title={t('CONFIG_VAR_UPLOAD_MODAL_TITLE')}
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          onSuccessUpload={() =>
            //setTablePagination({ current: 1 })
            fetchVariablesList({ writeable: 'False' })
          }
          uploadValuesXLS={file =>
            uploadVariablesValuesXLS(file, { variable: variableId })
          }
          setIsTableLoading={setIsTableLoading}
          nameConfig={t('LABEL_VARIABLES')}
        />
      )} */}
    </Space>
  );
};

const mapStateToProps = state => ({
  variablesRows: configuration.selectors.getVariablesList(state),
  variablesKpis: configuration.selectors.getVariablesKpis(state),
  dimensionValues: configuration.selectors.getDimensionValues(state),
  dimensionsList: configuration.selectors.getDimensionsList(state),
  optionsBehaviour: configuration.selectors.getBehaviourList(state),
});

const mapDispatchToProps = {
  fetchVariablesList: configuration.actions.fetchVariablesList,
  fetchVariablesKpis: configuration.actions.fetchVariablesKpis,
  createVariable: configuration.actions.createVariable,
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchBehaviourOptions: configuration.actions.fetchBehaviourList,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariablesList);
