import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PlanningDetail from './PlanningDetail/PlanningDetail';
import PlanningHome from './PlanningHome/PlanningHome';
import ConsolidationDetail from './ConsolidationDetail/ConsolidationDetail';
import {
  ProjectionTrackingScreen,
  ExchangeRateScreen,
} from 'modules/core/components';

const Home = () => (
  <Switch>
    <Route exact path="/planificacion/:periodId" component={PlanningHome} />
    <Route
      exact
      path="/planificacion/:periodId/consolidacion/:consolidationId"
      component={ConsolidationDetail}
    />
    <Route
      exact
      path="/planificacion/:periodId/:conceptName(ventas|gastos)/actividad/:projectionId"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/planificacion/:periodId/:conceptName(ventas|gastos)/:budgetId"
      component={PlanningDetail}
    />
    <Route
      exact
      path="/planificacion/:periodId/tipodecambio/:budgetId"
      component={ExchangeRateScreen}
    />
    <Redirect to="/planificacion" />
  </Switch>
);

export default Home;
