import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePagination } from 'modules/core/customHooks';
import { Space, Button, Row, Col, Spin, Input } from 'antd';
import { EmptyScreen, ButtonActionBar } from 'modules/core/components';
import { CreateFormulaModal, FormulasTable } from './components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES, DIMENSIONS } from 'modules/core/constants';
import configuration from 'modules/configuration';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';

import './FormulasList.scss';

const { Search } = Input;
const { SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__CREATE } = ROLES;

const FormulasList = ({
  fetchFormulasList,
  formulasRows,
  fetchDimensionsList,
  dimensionsList,
  fetchDimensionValues,
  dimensionValues,
  fetchFactoresBehaviours,
  optionsBehaviour,
}) => {
  const { t } = useTranslation();
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [tablePagination, setTablePagination] = usePagination();
  const [areValuesLoading, setAreValuesLoading] = useState(false);

  useEffect(() => {
    setIsTableLoading(true);
    fetchFormulasList({
      writeable: 'False',
      search: searchValue,
      ...tablePagination,
    }).then(() => {
      setIsScreenLoading(false);
      setIsTableLoading(false);
    });
  }, [fetchFormulasList, tablePagination, searchValue]);

  useEffect(() => {
    setAreValuesLoading(true);
    Promise.all([
      fetchDimensionValues({
        dimension: DIMENSIONS.ACCOUNTS.id,
        flat: 'True',
        writeable: 'True',
      }),
      fetchDimensionsList({ flat: 'True', writeable: 'True' }),
      fetchFactoresBehaviours(),
    ]).then(() => setAreValuesLoading(false));
  }, [fetchDimensionsList, fetchDimensionValues, fetchFactoresBehaviours]);

  const userCanCreate = isUserAllowed(
    SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__CREATE
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_FORMULAS_EMPTY_SCREEN_TITLE')}
      description={t('CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('CONFIG_FORMULAS_CREATE_ACTION')}
        </Button>
      }
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <Search
        className="formulas-search"
        placeholder={t('ACTION_SEARCH')}
        allowClear={true}
        onSearch={search => setSearchValue(search)}
      />
      <Button
        type="primary"
        onClick={() => setShowCreateModal(true)}
        disabled={!userCanCreate}
      >
        {t('ACTION_CREATE_NEW')}
      </Button>
    </ButtonActionBar>
  );

  const reloadTable = () => {
    setIsTableLoading(true);
    fetchFormulasList({
      writeable: 'False',
      search: searchValue,
      ...tablePagination,
    }).then(() => {
      setIsTableLoading(false);
    });
  };

  return (
    <Space className="formulas-container" direction="vertical" size="small">
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading &&
        formulasRows.count <= 0 &&
        !searchValue &&
        !isTableLoading &&
        renderNoDataScreen()}
      {!isScreenLoading && (formulasRows.count > 0 || searchValue) && (
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderTopActions()}</Col>
          {/* KPIS */}
          <Col span={24}>
            <FormulasTable
              loading={isTableLoading}
              count={formulasRows.count}
              dataSource={formulasRows.results}
              handleTableChange={setTablePagination}
              tablePagination={tablePagination}
              accountsList={dimensionValues}
              dimensionsList={dimensionsList}
              onFetchFormulasList={() =>
                fetchFormulasList({
                  writeable: 'False',
                  search: searchValue,
                  ...tablePagination,
                })
              }
            />
          </Col>
        </Row>
      )}
      <CreateFormulaModal
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        refresh={reloadTable}
        accountsList={dimensionValues}
        dimensionsList={dimensionsList}
        optionsBehaviour={optionsBehaviour}
        areValuesLoading={areValuesLoading}
      />
    </Space>
  );
};

const mapStateToProps = state => ({
  formulasRows: configuration.selectors.getFormulasList(state),
  dimensionsList: configuration.selectors.getDimensionsList(state),
  dimensionValues: configuration.selectors.getDimensionValues(state),
  optionsBehaviour: configuration.selectors.getFactoresBehaviours(state),
});

const mapDispatchToProps = {
  fetchFormulasList: configuration.actions.fetchFormulasList,
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchFactoresBehaviours: configuration.actions.fetchFactoresBehaviours,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulasList);
