import { NAME } from './constants';
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getDistributionList = state => state[NAME].distributionList;
export const getDistributionDetail = state => state[NAME].distributionDetail;
export const getOriginColumns = state => state[NAME].originColumns;

export const getDestinationDimensionOptions = createSelector(
  getOriginColumns,
  originColumns =>
    originColumns.filter(column => column.data_index !== 'amount')
);

export const getCreationDate = state => state[NAME].creation.date;
export const getCreationAccount = state => state[NAME].creation.account;
export const getOriginBalance = state => state[NAME].creation.originBalance;
export const getOriginRow = state => state[NAME].creation.originRow;
export const getDestinationDimension = state =>
  state[NAME].creation.destinationDimension;

export const getOriginRowFormatted = createSelector(
  getOriginRow,
  getCreationAccount,
  (originRow, creationAccount) =>
    _({ 1: { id: creationAccount }, ...originRow })
      .pickBy(
        (value, key) => key !== 'id' && key !== 'amount' && value !== null
      )
      .mapValues('id')
      .value()
);

export const getDestinationRows = state =>
  Object.values(state[NAME].creation.destinationRows);

export const getDestinationRowsFormatted = createSelector(
  getDestinationRows,
  destinationRows => {
    return destinationRows.map(row => {
      const objectPrueba = {};
      _.forEach(row, (data, data1) => {
        if (data1 !== 'id' && data1 !== 'amount') {
          if (data) {
            Object.assign(objectPrueba, { [data1]: data.id });
          }
        }
      });
      return {
        dv_filter: objectPrueba,
        percentage: row.amount,
      };
    });
  }
);

export const getCreationWarnings = createSelector(
  getDestinationRows,
  getOriginBalance,
  (destinationRows, originBalance) => {
    if (
      !originBalance ||
      destinationRows.reduce(
        (accumulator, row) => accumulator + row.amount,
        0
      ) !== 100
    ) {
      return true;
    }
    return false;
  }
);
