import React, { useState } from 'react';
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  message,
  Space,
  notification,
} from 'antd';
import { AddAndDeleteFormItem, SelectType } from 'modules/core/components';
import { ACCES_USERS, CONCEPTS, DIMENSIONS } from 'modules/core/constants';
import { LeftCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import { useTranslation } from 'react-i18next';
import {
  generateSelectOptions,
  generateSelectConceptOptions,
  userCanAcces,
  handleSelectAll,
} from 'modules/core/utils';
import _ from 'lodash';
import login from 'modules/login';

const CreateAccountModal = ({
  visible,
  onCancel,
  onShowPlanAccountModal,
  createPlanAccount,
  onRefetchDimensioValues,
  conceptsList: { values },
  dimensionValuesParents: { valuesParents },
  loggedUser: { email },
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onPrepareConceptData = selectedConcepts => {
    let selectedAllIds = [];
    const selectedIds = _.compact([
      ...selectedConcepts.map(conceptData => {
        if (!(conceptData.length > 1)) {
          selectedAllIds = [
            ...selectedAllIds,
            ...values
              .filter(concept => concept.type === conceptData[0])
              .map(concept => concept.id),
          ];
        }
        return conceptData[1];
      }),
    ]);
    return [...selectedAllIds, ...selectedIds];
  };

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoadingModal(true);
        const data = values.accounts.map(account => {
          return {
            ...account,
            ...(userCanAcces({
              patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
              email,
            }) && {
              concept_id: onPrepareConceptData(account.concept_id),
            }),
          };
        });
        createPlanAccount(data, { dimension_name: DIMENSIONS.ACCOUNT_LABEL })
          .then(() =>
            onRefetchDimensioValues().then(() => {
              message.success(t('FEEDBACK_CREATE_SUCCESS'));
              setIsLoadingModal(false);
              onClose();
            })
          )
          .catch(error => {
            //TODO: AGREGAR NOTIFICATION CUANDO HAYA MENSAJES COMPLETOS PARA MOSTRAR EN TODAS LAS INTERACCIONES DE ERROR
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: error,
              duration: 0,
            });
            setIsLoadingModal(false);
            onClose();
          });
      })
      .catch(e => {});
  };

  const handleSetFormListValues = (values, index) => {
    const fields = form.getFieldsValue();
    const { accounts } = fields;
    Object.assign(accounts[index], { concept_id: values });
    return form.setFieldsValue({ accounts });
  };

  const renderCascaderSelect = () => (
    <SelectType.Cascader options={generateSelectConceptOptions({ values })} />
  );

  const renderNormalSelect = index => (
    <Select
      placeholder={t('ACTION_SELECT')}
      mode="multiple"
      showArrow
      allowClear
      showSearch
      maxTagCount={1}
      maxTagTextLength={8}
      onChange={data =>
        handleSelectAll({
          values: data,
          allValues: values
            .filter(val => val.type === CONCEPTS.TYPE.ECONOMIC_KEY)
            .asMutable()
            .map(account => account.id),
          areAllSelected: areAllSelected,
          onSetValues: values => handleSetFormListValues(values, index),
          onSetAreAllSelected: state => setAreAllSelected(state),
        })
      }
      options={generateSelectOptions({
        options: values.filter(val => val.type === CONCEPTS.TYPE.ECONOMIC_KEY),
        includeOptionAll: true,
      })}
    />
  );

  return (
    <Modal
      title={t('CONFIG_ACCOUNTS_CREATE_NEW_ACCOUNT')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 550 }}
      visible={visible}
      centered
      closable
      destroyOnClose
      onCancel={onClose}
      footer={
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="link"
            icon={<LeftCircleOutlined />}
            onClick={() => {
              onShowPlanAccountModal(true);
              onClose();
            }}
            disabled={isLoadingModal}
          >
            {t('ACTION_RETURN')}
          </Button>
          <Space direction="horizontal" size="middle">
            <Button onClick={onClose} disabled={isLoadingModal}>
              {t('ACTION_CANCEL')}
            </Button>
            <Button type="primary" onClick={onConfirm} loading={isLoadingModal}>
              {t('ACTION_CREATE')}
            </Button>
          </Space>
        </Space>
      }
    >
      <Form form={form} layout="vertical" initialValues={{ accounts: [''] }}>
        <Form.List name="accounts">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row key={field.key} type="flex" justify="end">
                    <Row gutter={24} type="flex">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'code']}
                          label={[field.label, t('FIELD_CODE')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_ACCOUNTS_CODE_INPUT_ACCOUNT_PLACEHOLDER'
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'name']}
                          label={[field.label, t('FIELD_NAME')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_ACCOUNTS_NAME_INPUT_ACCOUNT_PLACEHOLDER'
                            )}
                          ></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'parent_id']}
                          label={[
                            field.label,
                            t('CONFIG_ACCOUNTS_LEVEL_PARENT_LABEL'),
                          ]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t('ACTION_SELECT')}
                            options={generateSelectOptions({
                              options: valuesParents && valuesParents,
                            })}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'concept_id']}
                          label={[field.label, t('FIELD_ASSOCIATED_CONCEPT')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          {/* NOTE: PROVISORIO HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS */}
                          {userCanAcces({
                            patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
                            email,
                          })
                            ? renderCascaderSelect()
                            : renderNormalSelect(index)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <AddAndDeleteFormItem
                      fieldsLength={fields.length}
                      index={index}
                      disabled={isLoadingModal}
                      addData={{
                        onClick: () => add(),
                        buttonName: 'CONFIG_ACCOUNTS_ADD_NEW_ACCOUNT_ACTION',
                      }}
                      deleteData={{
                        onClick: () => remove(field.name),
                        buttonName: 'CONFIG_ACCOUNTS_DELETE_ACCOUNT_ACTION',
                      }}
                    />
                  </Row>
                ))}
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  createPlanAccount: configuration.actions.createPlanAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountModal);
