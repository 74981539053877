import Immutable from 'seamless-immutable';
import * as types from './constants';

const initialLoggedUser = {
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  society: null,
  roles: [],
  groups: [],
  operations: [],
  lang: null,
};

const initialState = Immutable({
  isAuthenticating: true,
  isAuthenticated: false,
  loggedUser: initialLoggedUser,
});

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let data;
  switch (action.type) {
    case types.USER_WHO_AM_I_SUCCESS:
      data = action.payload.data;
      return state.merge({
        isAuthenticating: false,
        isAuthenticated: data.password_confirmed,
        loggedUser: {
          userId: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          society: data.society,
          roles: data.roles,
          groups: [...new Set(data.roles.map(role => role.group))],
          operations: [
            ...new Set(
              data.roles
                .map(role => role.operations_asign.map(el => el.code))
                .flat()
            ),
          ],
          lang: data.lang ? data.lang : 'es',
        },
      });

    case types.USER_LOGIN_FAIL:
    case types.USER_WHO_AM_I_FAIL:
      return state.merge({
        isAuthenticating: false,
        isAuthenticated: false,
        loggedUser: initialLoggedUser,
      });

    case types.USER_SETTING_LANG_SUCCESS:
      data = action.payload.data;
      return state.merge({
        loggedUser: { ...state.loggedUser, lang: data.lang },
      });

    default:
      return state;
  }
};
