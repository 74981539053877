import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Space,
  Alert,
  Button,
  notification,
  message,
  Popconfirm,
} from 'antd';
import { InputCell } from 'modules/core/components';
import { dateMMYYYY } from 'modules/core/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import reports from 'modules/reports';
import _ from 'lodash';

const InitialSituationTable = ({
  onCellEdit,
  dataCellEdit,
  setDataCellEdit,
  columns,
  financialReportRows,
  activeByPeriodTableValue,
  editInitialSituation,
  onRefetchFinancialReportRows,
  onRefetchReportDetail,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showPopconfirm, setShowPopconfirm] = useState(false);
  let { periodId, reportId } = useParams();
  const { t } = useTranslation();

  const onResetData = () => {
    setIsSaving(false);
    setDataCellEdit({});
    setShowPopconfirm(false);
  };

  const onSetInitialSituation = () => {
    setIsSaving(true);
    const data = {
      [`${activeByPeriodTableValue}_initial_situation`]: parseInt(
        dataCellEdit[`${activeByPeriodTableValue}_initial_situation`]
      ),
    };
    editInitialSituation(reportId, { period_id: periodId }, data)
      .then(() => {
        Promise.all([
          onRefetchFinancialReportRows(),
          onRefetchReportDetail(),
        ]).then(() => {
          onResetData();
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
        });
      })
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        });
        onResetData();
      });
  };

  const onCancel = () => {
    setDataCellEdit({});
    setShowPopconfirm(false);
  };

  const columnsInitialSituation = [
    {
      dataIndex: 'concept',
      title: t('FIELD_CONCEPT'),
      width: 300,
    },
    {
      dataIndex:
        columns.length !== 0
          ? columns.filter(col => col.is_transaction)[0].data_index
          : '',
      title: dateMMYYYY(
        columns.length !== 0
          ? columns.filter(col => col.is_transaction)[0].data_index
          : '',
        'YYYY-MM'
      ),
      width: 300,
      render: () => (
        <InputCell
          onCellUpdate={onCellEdit}
          name={`${activeByPeriodTableValue}_initial_situation`}
        />
      ),
    },
    {
      dataIndex: '',
      title: t('FIELD_ACTIONS'),
      width: 150,
      align: 'right',
      render: () => (
        <Popconfirm
          placement="bottomRight"
          title={t('POPCONFIRM_SAVE_CHANGES')}
          okText={t('ACTION_SAVE')}
          onConfirm={() => onSetInitialSituation()}
          okButtonProps={{ loading: isSaving, disabled: isSaving }}
          cancelButtonProps={{ disabled: isSaving }}
          cancelText={t('ACTION_CANCEL')}
          onCancel={() => onCancel()}
          visible={showPopconfirm}
        >
          <Button
            type="link"
            disabled={_.isEmpty(dataCellEdit) || isSaving}
            onClick={() => setShowPopconfirm(true)}
          >
            {t('ACTION_SAVE_CHANGES')}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const auxDataInitialSituation = [
    {
      concept: 'Sit. Inicial',
      [dateMMYYYY(
        columns.length !== 0
          ? columns.filter(col => col.is_transaction)[0].data_index
          : '',
        'YYYY-MM'
      )]: '',
    },
  ];

  const dataSource =
    financialReportRows[activeByPeriodTableValue].length !== 0
      ? [financialReportRows[activeByPeriodTableValue][0]]
      : [...auxDataInitialSituation];

  return (
    <Space direction="vertical">
      <Alert
        message={t('REPORT_FINANCIAL_INITIAL_SITUATION_MODAL_TITLE')}
        description={t(
          'REPOR_FINANCIAL_NO_INITIAL_SITUATION_PREV_REAL_ALERT_DESCRIPTION'
        )}
        type="info"
        showIcon
      />
      <Table
        size="small"
        rowKey="concept"
        columns={columnsInitialSituation}
        loading={isSaving}
        dataSource={dataSource}
        pagination={false}
      />
    </Space>
  );
};

const mapDispatchToProps = {
  editInitialSituation: reports.actions.editInitialSituation,
};

export default connect(null, mapDispatchToProps)(InitialSituationTable);
