import React from 'react';
import { Radio } from 'antd';
import { PROJECTION, REPORT } from 'modules/core/constants';
import { useTranslation } from 'react-i18next';

const ButtonsSwitchDataType = ({ tableTypeKey, onChange }) => {
  const { t } = useTranslation();

  return (
    <Radio.Group
      size="small"
      options={[
        {
          label: t('FIELD_AMOUNT'),
          value: REPORT.REPORTS_KEYS.AMOUNT,
        },
        {
          label: 'PxQ',
          value: PROJECTION.PXQ_KEY,
        },
      ]}
      onChange={onChange}
      value={tableTypeKey}
      optionType="button"
      buttonStyle="solid"
    />
  );
};

export default ButtonsSwitchDataType;
