import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import {
  NumberCell,
  TableContextMenu,
  CalculateValueInput,
} from 'modules/core/components';
import CommentPopover from '../CommentPopover/CommentPopover';
import adjustments from 'modules/adjustments';
import distributions from 'modules/distributions';

const TransactionCell = ({
  initialValue,
  hasVarsOrRule,
  format,
  has_restriction,
  type = '',
  disabledInput = false,
  showContextMenu = false,
  showNegativeInRed,
  onCalculateValue,
  onCellEdit,
  onCellEditForward = null,
  comments: {
    hasComment = false,
    commentList = [],
    onComment = null,
    onDeleteComment = null,
  },
  showCalculateValue,
  adjustment = false,
  editAdjustmentValues,
  editDistributionValues,
}) => {
  const [previousValue, setPreviousValue] = useState(null);
  const [canUndo, setCanUndo] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showCalculateValueInput, setShowCalculateValueInput] = useState(false);
  let history = useHistory();
  let { periodId } = useParams();

  const renderCommentPopover = () => {
    if (commentList.length <= 0 && onComment == null) {
      return null;
    }

    return (
      <CommentPopover
        visible={showComment}
        onVisibleChange={setShowComment}
        data={commentList}
        onComment={onComment}
        onDelete={onDeleteComment}
      >
        <span className="transaction-cell-comment" data-visible={hasComment} />
      </CommentPopover>
    );
  };

  return (
    <>
      {renderCommentPopover()}
      <TableContextMenu
        visible={showContextMenu}
        undo={{
          visible: !disabledInput,
          disabled: previousValue === null,
          onClick: () => setCanUndo(true),
        }}
        comment={{
          visible: onComment !== null,
          onClick: () => setShowComment(true),
        }}
        replicateValue={{
          visible: onCellEditForward !== null,
          disabled: disabledInput,
          onClick: () => {
            onCellEditForward();
            setPreviousValue(null);
          },
        }}
        calculateValue={{
          visible: showCalculateValue,
          disabled: disabledInput,
          onClick: () => setShowCalculateValueInput(true),
        }}
        adjustment={{
          visible: adjustment ? true : false,
          disabled: adjustment.disabled,
          onClick: () => {
            editAdjustmentValues({
              date: adjustment.date,
              initialRow: [adjustment.row],
            });
            history.push(`/seguimiento/${periodId}/economico/ajustes/`);
          },
        }}
        distribution={{
          visible: adjustment ? true : false,
          disabled: adjustment.disabled,
          onClick: () => {
            editDistributionValues({
              date: adjustment.date,
              account: parseInt(adjustment.row[1].id),
              initialOriginRow: _.omit(adjustment.row, ['1']),
            });
            history.push(`/seguimiento/${periodId}/economico/distribuciones/`);
          },
        }}
      >
        {showCalculateValueInput ? (
          <CalculateValueInput
            initialValue={initialValue}
            onCellUpdate={onCalculateValue}
            onCancel={() => setShowCalculateValueInput(false)}
          />
        ) : (
          <NumberCell
            format={format}
            has_restriction={has_restriction}
            hasVarsOrRule={hasVarsOrRule}
            initialValue={initialValue}
            type={type}
            disabled={disabledInput}
            showNegativeInRed={showNegativeInRed}
            onCellUpdate={onCellEdit}
            undoAction={{
              canUndo,
              previousValue,
              setPreviousValue,
              setCanUndo,
            }}
          />
        )}
      </TableContextMenu>
    </>
  );
};

const mapDispatchToProps = {
  editAdjustmentValues: adjustments.actions.editAdjustmentValues,
  editDistributionValues: distributions.actions.editDistributionValues,
};

export default connect(null, mapDispatchToProps)(TransactionCell);
