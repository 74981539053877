import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { calculateScrollX, columnsHelper } from 'modules/core/utils';
import { ROWS, COLUMNS, ACCES_USERS } from 'modules/core/constants';
import { useCollapser } from 'modules/core/customHooks';
import planning from 'modules/planning';
import login from 'modules/login';

import './ForecastTable.scss';

const ForecastTable = ({
  loading,
  onChange,
  columns,
  dataSource,
  pagination,
  editable,
  onCalculateValue,
  onCellEdit,
  onCellEditForward,
  tableSettings: { visibleData, format, showNegativeInRed },
  filters,
  filterOptions,
  onFilter,
  comments: { showRowsComment, onComment, onDeleteComment },
  fetchOriginRows,
  loggedUser: { email },
}) => {
  const [isCollapsed, toogleIsCollapsed, isCollapsing] = useCollapser();
  const [isExpandingRow, setIsExpandingRow] = useState(false);
  const [expandedRow, setExpandedRow] = useState([]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const renderTransactionColumn = column =>
    columnsHelper.renderTransactionPlanning({
      date: column.title,
      className: !column.editable && COLUMNS.TYPES.TOTALIZER,
      cellFormat: { format, showNegativeInRed },
      width: column.width,
      disabled: !editable || !column.editable,
      onCalculateValue,
      onCellEdit,
      onCellEditForward,
      comments: {
        onComment: onComment,
        onDeleteComment: onDeleteComment,
      },
      has_restriction,
    });

  const columnsGenerator = () =>
    columnsHelper.generator({
      columns,
      filteredColumns: visibleData,
      cellFormat: { format, showNegativeInRed },
      filterProps: { filterOptions, filters, onFilter },
      collapserProps: {
        width: 25,
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      sorter: true,
      renderTransaction: renderTransactionColumn,
      has_restriction,
    });

  const onExpandRow = (expanded, record) => {
    setIsExpandingRow(true);
    if (expanded) {
      fetchOriginRows(record.id, { expand_row: true }).then(() => {
        setIsExpandingRow(false);
        setExpandedRow(prevExpanded => [...prevExpanded, record.id]);
      });
    } else {
      setExpandedRow(prevExpanded =>
        prevExpanded.filter(item => item !== record.id)
      );
      setIsExpandingRow(false);
    }
  };

  return (
    <Table
      rowKey="id"
      className="forecast-table"
      rowClassName={record => record.id === 0 && ROWS.TYPES[ROWS.TOTALIZER]}
      size="small"
      loading={loading || isExpandingRow}
      onChange={onChange}
      columns={columnsGenerator()}
      dataSource={dataSource}
      pagination={{
        ...pagination,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
      onExpand={onExpandRow}
      expandedRowKeys={expandedRow}
      locale={columnsHelper.renderSorterLocale()}
      {...(!isCollapsing && {
        scroll: {
          x: calculateScrollX(columnsGenerator()),
          y: 445,
        },
      })}
    />
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchOriginRows: planning.actions.fetchOriginRows,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastTable);
