export const NAME = 'reports';

export const FETCH_REPORT_LIST = `${NAME}/FETCH_REPORT_LIST`;
export const FETCH_REPORT_LIST_SUCCESS = `${NAME}/FETCH_REPORT_LIST_SUCCESS`;
export const FETCH_REPORT_LIST_FAIL = `${NAME}/FETCH_REPORT_LIST_FAIL`;

export const FETCH_REPORT_DETAIL = `${NAME}/FETCH_REPORT_DETAIL`;
export const FETCH_REPORT_DETAIL_SUCCESS = `${NAME}/FETCH_REPORT_DETAIL_SUCCESS`;
export const FETCH_REPORT_DETAIL_FAIL = `${NAME}/FETCH_REPORT_DETAIL_FAIL`;

export const FETCH_REPORT_COLUMNS = `${NAME}/FETCH_REPORT_COLUMNS`;
export const FETCH_REPORT_COLUMNS_SUCCESS = `${NAME}/FETCH_REPORT_COLUMNS_SUCCESS`;
export const FETCH_REPORT_COLUMNS_FAIL = `${NAME}/FETCH_REPORT_COLUMNS_FAIL`;

export const FETCH_REPORT_DATA = `${NAME}/FETCH_REPORT_DATA`;
export const FETCH_REPORT_DATA_SUCCESS = `${NAME}/FETCH_REPORT_DATA_SUCCESS`;
export const FETCH_REPORT_DATA_FAIL = `${NAME}/FETCH_REPORT_DATA_FAIL`;

export const FETCH_REPORT_STRUCTURE = `${NAME}/FETCH_REPORT_STRUCTURE`;
export const FETCH_REPORT_STRUCTURE_SUCCESS = `${NAME}/FETCH_REPORT_STRUCTURE_SUCCESS`;
export const FETCH_REPORT_STRUCTURE_FAIL = `${NAME}/FETCH_REPORT_STRUCTURE_FAIL`;

export const FETCH_REPORT_ROW = `${NAME}/FETCH_REPORT_ROW`;
export const FETCH_REPORT_ROW_SUCCESS = `${NAME}/FETCH_REPORT_ROW_SUCCESS`;
export const FETCH_REPORT_ROW_FAIL = `${NAME}/FETCH_REPORT_ROW_FAIL`;

export const FETCH_REPORT_OPEN_ROW = `${NAME}/FETCH_REPORT_OPEN_ROW`;
export const FETCH_REPORT_OPEN_ROW_SUCCESS = `${NAME}/FETCH_REPORT_OPEN_ROW_SUCCESS`;
export const FETCH_REPORT_OPEN_ROW_FAIL = `${NAME}/FETCH_REPORT_OPEN_ROW_FAIL`;

export const CREATE_REPORT = `${NAME}/CREATE_REPORT`;
export const CREATE_REPORT_SUCCESS = `${NAME}/CREATE_REPORT_SUCCESS`;
export const CREATE_REPORT_FAIL = `${NAME}/CREATE_REPORT_FAIL`;

export const EDIT_REPORT = `${NAME}/EDIT_REPORT`;
export const EDIT_REPORT_SUCCESS = `${NAME}/EDIT_REPORT_SUCCESS`;
export const EDIT_REPORT_FAIL = `${NAME}/EDIT_REPORT_FAIL`;

export const DELETE_REPORT = `${NAME}/DELETE_REPORT`;
export const DELETE_REPORT_SUCCESS = `${NAME}/DELETE_REPORT_SUCCESS`;
export const DELETE_REPORT_FAIL = `${NAME}/DELETE_REPORT_FAIL`;

export const UPLOAD_REPORT_VALUES_XLS = `${NAME}/UPLOAD_REPORT_VALUES_XLS`;
export const FETCH_REPORT_TEMPLATE_XLS = `${NAME}/FETCH_REPORT_TEMPLATE_XLS`;
export const FETCH_REPORT_CONSOLIDATED_XLS = `${NAME}/FETCH_REPORT_CONSOLIDATED_XLS`;

export const DOWNLOAD_EVOLUTION_XLS = `${NAME}/DOWNLOAD_EVOLUTION_XLS`;
export const DOWNLOAD_COMPARE_XLS = `${NAME}/DOWNLOAD_COMPARE_XLS`;
export const DOWNLOAD_CUSTOM_XLS = `${NAME}/DOWNLOAD_CUSTOM_XLS`;

export const FETCH_CUSTOM_REPORTS_LIST = `${NAME}/FETCH_CUSTOM_REPORTS_LIST`;
export const FETCH_CUSTOM_REPORTS_LIST_SUCCESS = `${NAME}/FETCH_CUSTOM_REPORTS_LIST_SUCCESS`;
export const FETCH_CUSTOM_REPORTS_LIST_FAIL = `${NAME}/FETCH_CUSTOM_REPORTS_LIST_FAIL`;

export const FETCH_CUSTOM_REPORT_ROW = `${NAME}/FETCH_CUSTOM_REPORT_ROW`;
export const FETCH_CUSTOM_REPORT_ROW_SUCCESS = `${NAME}/FETCH_CUSTOM_REPORT_ROW_SUCCESS`;
export const FETCH_CUSTOM_REPORT_ROW_FAIL = `${NAME}/FETCH_CUSTOM_REPORT_ROW_FAIL`;

export const FETCH_CUSTOM_REPORT_COLUMNS = `${NAME}/FETCH_CUSTOM_REPORT_COLUMNS`;
export const FETCH_CUSTOM_REPORT_COLUMNS_SUCCESS = `${NAME}/FETCH_CUSTOM_REPORT_COLUMNS_SUCCESS`;
export const FETCH_CUSTOM_REPORT_COLUMNS_FAIL = `${NAME}/FETCH_CUSTOM_REPORT_COLUMNS_FAIL`;

export const FETCH_REPORT_SEGMENTED_ROW = `${NAME}/FETCH_REPORT_SEGMENTED_ROW`;
export const FETCH_REPORT_SEGMENTED_ROW_SUCCESS = `${NAME}/FETCH_REPORT_SEGMENTED_ROW_SUCCESS`;
export const FETCH_REPORT_SEGMENTED_ROW_FAIL = `${NAME}/FETCH_REPORT_SEGMENTED_ROW_FAIL`;

export const FETCH_REPORT_SEGMENTED_COLUMNS = `${NAME}/FETCH_REPORT_SEGMENTED_COLUMNS`;
export const FETCH_REPORT_SEGMENTED_COLUMNS_SUCCESS = `${NAME}/FETCH_REPORT_SEGMENTED_COLUMNS_SUCCESS`;
export const FETCH_REPORT_SEGMENTED_COLUMNS_FAIL = `${NAME}/FETCH_REPORT_SEGMENTED_COLUMNS_FAIL`;

export const DOWNLOAD_EVOLUTION_SEGMENTED_XLS = `${NAME}/DOWNLOAD_EVOLUTION_SEGMENTED_XLS`;

export const FETCH_REPORT_VERTICAL_COMPARISON_ROW = `${NAME}/FETCH_REPORT_VERTICAL_COMPARISON_ROW`;
export const FETCH_REPORT_VERTICAL_COMPARISON_ROW_SUCCESS = `${NAME}/FETCH_REPORT_VERTICAL_COMPARISON_ROW_SUCCESS`;
export const FETCH_REPORT_VERTICAL_COMPARISON_ROW_FAIL = `${NAME}/FETCH_REPORT_VERTICAL_COMPARISON_ROW_FAIL`;

export const FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS = `${NAME}/FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS`;
export const FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS_SUCCESS = `${NAME}/FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS_SUCCESS`;
export const FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS_FAIL = `${NAME}/FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS_FAIL`;

export const FETCH_REPORT_VERTICAL_SEGMENTED_ROW = `${NAME}/FETCH_REPORT_VERTICAL_SEGMENTED_ROW`;
export const FETCH_REPORT_VERTICAL_SEGMENTED_ROW_SUCCESS = `${NAME}/FETCH_REPORT_VERTICAL_SEGMENTED_ROW_SUCCESS`;
export const FETCH_REPORT_VERTICAL_SEGMENTED_ROW_FAIL = `${NAME}/FETCH_REPORT_VERTICAL_SEGMENTED_ROW_FAIL`;

export const FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS = `${NAME}/FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS`;
export const FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS_SUCCESS = `${NAME}/FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS_SUCCESS`;
export const FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS_FAIL = `${NAME}/FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS_FAIL`;

export const DOWNLOAD_EVOLUTION_VERTICAL_COMPARISON_XLS = `${NAME}/DOWNLOAD_EVOLUTION_VERTICAL_COMPARISON_XLS`;

export const DOWNLOAD_EVOLUTION_VERTICAL_SEGMENTED_XLS = `${NAME}/DOWNLOAD_EVOLUTION_VERTICAL_SEGMENTED_XLS`;

export const FETCH_DIMENSION_TRANSACTIONS_LIST = `${NAME}/FETCH_DIMENSION_TRANSACTIONS_LIST`;
export const FETCH_DIMENSION_TRANSACTIONS_LIST_SUCCESS = `${NAME}/FETCH_DIMENSION_TRANSACTIONS_LIST_SUCCESS`;
export const FETCH_DIMENSION_TRANSACTIONS_LIST_FAIL = `${NAME}/FETCH_DIMENSION_TRANSACTIONS_LIST_FAIL`;

export const FETCH_CUSTOM_REPORT_LIST = `${NAME}/FETCH_CUSTOM_REPORT_LIST`;
export const FETCH_CUSTOM_REPORT_LIST_SUCCESS = `${NAME}/FETCH_CUSTOM_REPORT_LIST_SUCCESS`;
export const FETCH_CUSTOM_REPORT_LIST_FAIL = `${NAME}/FETCH_CUSTOM_REPORT_LIST_FAIL`;

export const FETCH_CUSTOM_REPORT_DETAIL = `${NAME}/FETCH_CUSTOM_REPORT_DETAIL`;
export const FETCH_CUSTOM_REPORT_DETAIL_SUCCESS = `${NAME}/FETCH_CUSTOM_REPORT_DETAIL_SUCCESS`;
export const FETCH_CUSTOM_REPORT_DETAIL_FAIL = `${NAME}/FETCH_CUSTOM_REPORT_DETAIL_FAIL`;

export const FETCH_DIMENSION_VALUES_BY_ROW = `${NAME}/FETCH_DIMENSION_VALUES_BY_ROW`;
export const FETCH_DIMENSION_VALUES_BY_ROW_SUCCESS = `${NAME}/FETCH_DIMENSION_VALUES_BY_ROW_SUCCESS`;
export const FETCH_DIMENSION_VALUES_BY_ROW_FAIL = `${NAME}/FETCH_DIMENSION_VALUES_BY_ROW_FAIL`;

export const FETCH_CUSTOM_REPORT_GRAPH_DATA = `${NAME}/FETCH_CUSTOM_REPORT_GRAPH_DATA`;
export const FETCH_CUSTOM_REPORT_GRAPH_DATA_SUCCESS = `${NAME}/FETCH_CUSTOM_REPORT_GRAPH_DATA_SUCCESS`;
export const FETCH_CUSTOM_REPORT_GRAPH_DATA_FAIL = `${NAME}/FETCH_CUSTOM_REPORT_GRAPH_DATA_FAIL`;

export const EDIT_REPORT_KPIS = `${NAME}/EDIT_REPORT_KPIS`;

//FINANCIERO

export const FETCH_FINANCIAL_REPORT_LIST = `${NAME}/FETCH_FINANCIAL_REPORT_LIST`;
export const FETCH_FINANCIAL_REPORT_LIST_SUCCESS = `${NAME}/FETCH_FINANCIAL_REPORT_LIST_SUCCESS`;
export const FETCH_FINANCIAL_REPORT_LIST_FAIL = `${NAME}/FETCH_FINANCIAL_REPORT_LIST_FAIL`;

export const FETCH_FINANCIAL_REPORT_COLUMNS = `${NAME}/FETCH_FINANCIAL_REPORT_COLUMNS`;
export const FETCH_FINANCIAL_REPORT_COLUMNS_SUCCESS = `${NAME}/FETCH_FINANCIAL_REPORT_COLUMNS_SUCCESS`;
export const FETCH_FINANCIAL_REPORT_COLUMNS_FAIL = `${NAME}/FETCH_FINANCIAL_REPORT_COLUMNS_FAIL`;

export const FETCH_FINANCIAL_REPORT_ROWS = `${NAME}/FETCH_FINANCIAL_REPORT_ROWS`;
export const FETCH_FINANCIAL_REPORT_ROWS_SUCCESS = `${NAME}/FETCH_FINANCIAL_REPORT_ROWS_SUCCESS`;
export const FETCH_FINANCIAL_REPORT_ROWS_FAIL = `${NAME}/FETCH_FINANCIAL_REPORT_ROWS_FAIL`;

export const FETCH_FINANCIAL_REPORT_CHART_DATA = `${NAME}/FETCH_FINANCIAL_REPORT_CHART_DATA`;
export const FETCH_FINANCIAL_REPORT_CHART_DATA_SUCCESS = `${NAME}/FETCH_FINANCIAL_REPORT_CHART_DATA_SUCCESS`;
export const FETCH_FINANCIAL_REPORT_CHART_DATA_FAIL = `${NAME}/FETCH_FINANCIAL_REPORT_CHART_DATA_FAIL`;

export const EDIT_INITIAL_SITUATION = `${NAME}/EDIT_INITIAL_SITUATION`;

export const SET_INITIAL_SITUATION = `${NAME}/SET_INITIAL_SITUATION`;

export const SET_REPORT_LINE_SELECTED = `${NAME}/SET_REPORT_LINE_SELECTED`;

//reporte RDN
export const FETCH_REPORT_RULE_DATA = `${NAME}/FETCH_REPORT_RULE_DATA`;
export const FETCH_REPORT_RULE_DATA_SUCCESS = `${NAME}/FETCH_REPORT_RULE_DATA_SUCCESS`;
export const FETCH_REPORT_RULE_DATA_FAIL = `${NAME}/FETCH_REPORT_RULE_DATA_FAIL`;

//multimoneda reporte
export const CREATE_EXCHANGE_RATE_REPORT = `${NAME}/CREATE_EXCHANGE_RATE_REPORT`;
export const DUPLICATE_REPORT = `${NAME}/DUPLICATE_REPORT`;

//COMPARATIVA MENSUAL
export const FETCH_COMPARATIVE_MONTHS_ROWS = `${NAME}/FETCH_COMPARATIVE_MONTHS_ROWS`;
export const FETCH_COMPARATIVE_MONTHS_ROWS_SUCCESS = `${NAME}/FETCH_COMPARATIVE_MONTHS_ROWS_SUCCESS`;
export const FETCH_COMPARATIVE_MONTHS_ROWS_FAIL = `${NAME}/FETCH_COMPARATIVE_MONTHS_ROWS_FAIL`;

export const FETCH_COMPARATIVE_MONTHS_COLUMNS = `${NAME}/FETCH_COMPARATIVE_MONTHS_COLUMNS`;
export const FETCH_COMPARATIVE_MONTHS_COLUMNS_SUCCESS = `${NAME}/FETCH_COMPARATIVE_MONTHS_COLUMNS_SUCCESS`;
export const FETCH_COMPARATIVE_MONTHS_COLUMNS_FAIL = `${NAME}/FETCH_COMPARATIVE_MONTHS_COLUMNS_FAIL`;

export const DOWNLOAD_COMPARATIVE_MONTHS_XLS = `${NAME}/DOWNLOAD_COMPARATIVE_MONTHS_XLS`;

//reporte segregado

export const FETCH_SEGREGATED_ANALYSIS_ROWS = `${NAME}/FETCH_SEGREGATED_ANALYSIS_ROWS`;
export const FETCH_SEGREGATED_ANALYSIS_ROWS_SUCCESS = `${NAME}/FETCH_SEGREGATED_ANALYSIS_ROWS_SUCCESS`;
export const FETCH_SEGREGATED_ANALYSIS_ROWS_FAIL = `${NAME}/FETCH_SEGREGATED_ANALYSIS_ROWS_FAIL`;

export const FETCH_SEGREGATED_ANALYSIS_COLUMNS = `${NAME}/FETCH_SEGREGATED_ANALYSIS_COLUMNS`;
export const FETCH_SEGREGATED_ANALYSIS_COLUMNS_SUCCESS = `${NAME}/FETCH_SEGREGATED_ANALYSIS_COLUMNS_SUCCESS`;
export const FETCH_SEGREGATED_ANALYSIS_COLUMNS_FAIL = `${NAME}/FETCH_SEGREGATED_ANALYSIS_COLUMNS_FAIL`;

//dimensiones disponibles en reporte segregado
export const FETCH_AVAILABLE_DIMENSIONS = `${NAME}/FETCH_AVAILABLE_DIMENSIONS`;
export const FETCH_AVAILABLE_DIMENSIONS_SUCCESS = `${NAME}/FETCH_AVAILABLE_DIMENSIONS_SUCCESS`;
export const FETCH_AVAILABLE_DIMENSIONS_FAIL = `${NAME}/FETCH_AVAILABLE_DIMENSIONS_FAIL`;

//bajada plana
export const DOWNLOAD_FLAT_XLS = `${NAME}/DOWNLOAD_FLAT_XLS`;
