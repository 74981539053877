import React, { useState } from 'react';
import { Input } from 'antd';

const CellInput = ({
  defaultValue,
  onUpdateValue,
  disabled = true,
  prefix,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  const updateValue = () => {
    // Verifico que el usuario no ponga solo - o vacio
    if (inputValue !== '-' && inputValue !== '') {
      // Si el usuario no cambio nada, no actualizo
      if (parseFloat(defaultValue) !== parseFloat(inputValue)) {
        onUpdateValue(parseFloat(inputValue));
      }
    } else {
      setInputValue(defaultValue);
    }
  };

  const handleChange = event => {
    const { value } = event.target;
    // Valida que sea un numero real y . para separar decimales
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (
      (!Number.isNaN(value) && reg.test(value)) ||
      value === '' ||
      value === '-'
    ) {
      setInputValue(value);
    }
  };

  const handleBlur = event => {
    updateValue(inputValue);
  };

  return (
    <Input
      prefix={prefix}
      value={inputValue}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default CellInput;
