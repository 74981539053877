import React from 'react';
import { Button, Space } from 'antd';
import { EmptyScreen } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import image from 'assets/images/compositions/feedback-image.svg';
import { useHistory } from 'react-router-dom';
import { INTEGRATIONS } from 'modules/core/constants';

// TODO: Notificar
const ScreenSuccess = ({ email, name, onClose }) => {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <EmptyScreen
      image={image}
      title={t('INTEGRATION_CONFIGURATION_SUCCESS_SCREEN_TITLE')}
      description={
        <Space direction="vertical">
          {t('INTEGRATION_ACTIVATION_SUCCESS_DESCRIPTION_1', { email })}
          {t('INTEGRATION_ACTIVATION_SUCCESS_DESCRIPTION_2')}
        </Space>
      }
      footer={
        <Space direction="horizontal">
          <Button
            onClick={() => {
              history.push(
                `/configuracion?tab=${INTEGRATIONS.KEYS.INTEGRATION_KEY}`
              );
              onClose();
            }}
          >
            {t('INTEGRATION_NEW_CONNECTION_BUTTON_ACTION')}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              history.push(`/configuracion/integraciones/${name}/actividad`)
            }
          >
            {t('INTEGRATION_VIEW_PROCESS_DATA_BUTTON_ACTION')}
          </Button>
        </Space>
      }
    />
  );
};

export default ScreenSuccess;
