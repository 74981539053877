import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin, List, Card, Row, Col, Space, Tooltip } from 'antd';
import { RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography, Onboarding } from 'modules/core/components';
import {
  isUserAllowed,
  getOperationsByKeys,
  userCanAcces,
} from 'modules/core/utils';
import { ROLES, CONCEPTS, ACCES_USERS } from 'modules/core/constants';
import configuration from 'modules/configuration';
import planning from 'modules/planning';
import login from 'modules/login';

import onboardingConfigSteps from './onboardingConfigSteps';

import './Configuration.scss';

const {
  SETTINGS__PLAN_ACCOUNTS,
  SETTINGS__CONCEPTS,
  SETTINGS__DIMENSIONS,
  SETTINGS__PROJECTION_ELEMENTS,
  SETTINGS__PROJECTION_ELEMENTS__CURRENCY,
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES,
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES,
  SETTINGS__REPORTS,
  SETTINGS__SECURITY,
  SETTINGS__SECURITY__ROLES,
  SETTINGS__SECURITY__USERS,
} = ROLES;

const ConfigurationCard = ({
  title,
  total,
  onClick = null,
  renderListItem,
  disabled = false,
  disabledTooltip = '',
  itemKey,
}) => {
  const { t } = useTranslation();

  return (
    <div className={itemKey}>
      <Card
        className={`configuration-card ${disabled ? 'disabled' : ''}`}
        headStyle={{ minHeight: 56 }}
        title={
          <div
            onClick={!disabled ? onClick : null}
            className="configuration-card-title"
            data-hoverable={onClick !== null}
          >
            {title}
          </div>
        }
        extra={
          disabled ? (
            <Tooltip
              title={
                disabledTooltip
                  ? disabledTooltip
                  : t('CONFIG_DISABLED_ACCES_NO_ACCOUNTS_TOOLTIP')
              }
            >
              <Typography.Icon icon={ExclamationCircleOutlined} />
            </Tooltip>
          ) : null
        }
      >
        {total !== 0 ? (
          <div className="configuration-card-body">
            <Typography.Headline
              level={5}
              onClick={!disabled ? onClick : null}
              data-hoverable={onClick !== null}
            >
              {total}
            </Typography.Headline>
            <ul className="configuration-card-list">{renderListItem}</ul>
          </div>
        ) : (
          <Space className="info-empty-card" direction="vertical" size="small">
            <Typography.Body level={1} type={'primary'}>
              {t('EMPTY_STATE_NO_DATA_TITLE')}
            </Typography.Body>
            <Typography.Body level={2} type={'secondary'}>
              {t('CONFIG_CARD_EMPTY_STATE_TEXT', {
                config: t(title).toLowerCase(),
              })}
            </Typography.Body>
          </Space>
        )}
      </Card>
    </div>
  );
};

const PROJECTION_ELEMENTS_TYPE = {
  'Reglas de negocio': {
    key: 'rules',
    role: [SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES],
  },
  Variables: { key: 'vars', role: [SETTINGS__PROJECTION_ELEMENTS__VARIABLES] },
  Moneda: { key: 'currency', role: [SETTINGS__PROJECTION_ELEMENTS__CURRENCY] },
};

const Configuration = ({
  dimensionsList,
  dimensionValues,
  conceptsList,
  modifiersList,
  fetchDimensionsList,
  fetchDimensionValues,
  fetchConceptsList,
  fetchModifiersList,
  loggedUser: { email },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    setIsScreenLoading(true);
    const isFetcheable = rolesArray =>
      isUserAllowed(getOperationsByKeys(rolesArray));

    Promise.all([
      isFetcheable([SETTINGS__PLAN_ACCOUNTS]) &&
        fetchDimensionValues({
          flat: 'True',
          dimension_name: 'Nivel',
          writeable: 'True',
        }),
      isFetcheable([SETTINGS__CONCEPTS]) && fetchConceptsList(),
      isFetcheable([SETTINGS__DIMENSIONS]) &&
        fetchDimensionsList({ flat: 'True', writeable: 'True' }),
      isFetcheable([SETTINGS__PROJECTION_ELEMENTS]) && fetchModifiersList(),
    ])
      .then(() => setIsScreenLoading(false))
      .catch(() => setIsScreenLoading(false));
  }, [
    fetchModifiersList,
    fetchDimensionsList,
    fetchDimensionValues,
    fetchConceptsList,
  ]);

  //NOTE: PROVISORIO HASTA QUE PUEDA VERSE EN TODOS LOS USUARIOS
  const renderConceptsList = () => {
    if (
      userCanAcces({
        patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
        email,
      })
    ) {
      return CONCEPTS.TYPE_LIST;
    }

    return CONCEPTS.TYPE_LIST.filter(
      concept => concept.id === CONCEPTS.TYPE.ECONOMIC_KEY
    );
  };

  const listData = [
    {
      title: t('LABEL_ACCOUNTS'),
      url: '/configuracion/misdatos/plan/',
      key: 'accounts',
      allowedFor: [SETTINGS__PLAN_ACCOUNTS],
      count: dimensionValues.count,
      renderListItem: !isScreenLoading
        ? dimensionValues.values.map(value => (
            <li key={value.id} data-hoverable={false}>
              {value.name}
            </li>
          ))
        : null,
    },
    {
      title: t('LABEL_CONCEPTS'),
      url: '/configuracion/misdatos/conceptos/',
      key: 'concepts',
      allowedFor: [SETTINGS__CONCEPTS],
      count: '',
      //TO DO: agregar permisos de financiero y economicos
      renderListItem:
        conceptsList.count !== 0 &&
        renderConceptsList().map(type => (
          <li
            key={type.id}
            onClick={() =>
              history.push(`/configuracion/misdatos/conceptos/?tab=${type.id}`)
            }
          >
            {t(type.name)}
            <RightOutlined className="enter-icon" />
          </li>
        )),
    },
    {
      title: t('LABEL_DIMENSIONS'),
      url: '/configuracion/misdatos/dimensiones/',
      key: 'dimensions',
      allowedFor: [SETTINGS__DIMENSIONS],
      count: dimensionsList.count,
      renderListItem:
        dimensionsList.count !== 0 &&
        dimensionsList.dimensions.map(dimension => (
          <li
            key={dimension.id}
            onClick={() =>
              history.push(
                `/configuracion/misdatos/dimensiones/${dimension.id}`
              )
            }
          >
            {dimension.name}
            <RightOutlined className="enter-icon" />
          </li>
        )),
    },
    {
      title: t('LABEL_PROJECTION_ELEMENTS'),
      url: '/configuracion/misdatos/elementosdeproyeccion/',
      key: 'projectionElements',
      allowedFor: [SETTINGS__PROJECTION_ELEMENTS],
      count: modifiersList.count,
      disabled: dimensionValues.count === 0,
      renderListItem:
        modifiersList.count !== 0
          ? modifiersList.results.map(
              variable =>
                isUserAllowed(
                  getOperationsByKeys(PROJECTION_ELEMENTS_TYPE[variable].role)
                ) && (
                  <li
                    key={variable}
                    onClick={() =>
                      history.push(
                        `/configuracion/misdatos/elementosdeproyeccion?tab=${PROJECTION_ELEMENTS_TYPE[variable].key}`
                      )
                    }
                  >
                    {variable}
                    <RightOutlined className="enter-icon" />
                  </li>
                )
            )
          : '',
    },
    {
      title: t('LABEL_REPORTS'),
      url: '/configuracion/misdatos/reportes/',
      key: 'reports',
      allowedFor: [SETTINGS__REPORTS],
      count: '',
      disabled: dimensionValues.count === 0,
      renderListItem: renderConceptsList().map(report => (
        // TODO: AGREGAR PERMISOS DE FINANCIERO Y ECONOMICO CUANDO ESTEN
        <li
          key={report.id}
          className={dimensionValues.count === 0 ? 'li-report' : ''}
          onClick={() =>
            dimensionValues.count !== 0 &&
            history.push(`/configuracion/misdatos/reportes/?tab=${report.id}`)
          }
        >
          {t(report.name)}
          <RightOutlined className="enter-icon" />
        </li>
      )),
    },
    {
      title: t('LABEL_SECURITY'),
      url: '/configuracion/misdatos/seguridad/',
      key: 'security',
      allowedFor: [SETTINGS__SECURITY],
      count: '',
      renderListItem: (
        <>
          {isUserAllowed(getOperationsByKeys([SETTINGS__SECURITY__ROLES])) && (
            <li
              onClick={() =>
                history.push(`/configuracion/misdatos/seguridad/?tab=roles`)
              }
            >
              {t('LABEL_ROLES')}
              <RightOutlined className="enter-icon" />
            </li>
          )}
          {isUserAllowed(getOperationsByKeys([SETTINGS__SECURITY__USERS])) && (
            <li
              onClick={() =>
                history.push(`/configuracion/misdatos/seguridad/?tab=users`)
              }
            >
              {t('LABEL_USERS')}
              <RightOutlined className="enter-icon" />
            </li>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="configuration-container">
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Spin spinning={isScreenLoading} />
          {!isScreenLoading && (
            <>
              <Onboarding
                steps={onboardingConfigSteps(history)}
                keyIdentifier={'config'}
              />
              <List
                grid={{ gutter: 24, column: 6 }}
                dataSource={listData.filter(el =>
                  isUserAllowed(getOperationsByKeys(el.allowedFor))
                )}
                renderItem={item => (
                  <List.Item>
                    <ConfigurationCard
                      title={item.title}
                      onClick={item.url ? () => history.push(item.url) : null}
                      total={item.count}
                      renderListItem={item.renderListItem}
                      disabled={item.disabled}
                      itemKey={item.key}
                    />
                  </List.Item>
                )}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  dimensionValues: configuration.selectors.getDimensionValues(state),
  conceptsList: planning.selectors.getConceptsList(state),
  modifiersList: configuration.selectors.getModifiersList(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchConceptsList: planning.actions.fetchConceptsList,
  fetchModifiersList: configuration.actions.fetchModifiersCardList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
