import React from 'react';
import { Row, Col, Card, Carousel /*Button, Input, Space*/ } from 'antd';
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  // EyeOutlined,
  // EyeInvisibleOutlined,
} from '@ant-design/icons';
import { Typography, KPIValue } from 'modules/core/components';
import numeral from 'numeral';
import reports from 'modules/reports';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';

import './ReportKPICarousel.scss';

const DATA_KPIS = {
  Presupuesto: { name: 'BYTD', title: 'LABEL_BUDGET' },
  Real: { name: 'AYTD', title: 'LABEL_REAL' },
  'Real período anterior': { name: 'LYYTD', title: 'REAL_LAST_PERIOD' },
  'Último forecast': { name: 'FYTD', title: 'LAST_FORECAST' },
};

//NOTE: EDITIONMODE KPIS COMENTADO HASTA QUE SE PUEDA GUARDAR DESDE BACK LOS CAMBIOS.

const ReportDetailKPICard = ({
  title,
  value,
  prefix,
  name,
  subkpis,
  isGrouper,
  renderPrefix,
  isEconomic,
  // showEditionMode,
  // id,
  // visible,
  // editReportKpis,
  // order,
}) => {
  const { t } = useTranslation();

  // const renderIcons = () => {
  //   return (
  //     <Space direction="horizontal">
  //       <Input
  //         size="small"
  //         title={t('INPUT_ORDER_KPIS_TITLE')}
  //         style={{ width: 30 }}
  //         defaultValue={order}
  //         key={id}
  //         onChange={value =>
  //           editReportKpis(id, {
  //             isGrouper,
  //             visible,
  //             order:
  //               value.target.value === ''
  //                 ? order
  //                 : parseInt(value.target.value),
  //           })
  //         }
  //       />
  //       <Button
  //         type="text"
  //         shape="circle"
  //         style={{ padding: 0 }}
  //         title={visible ? t('ACTION_HIDE') : t('ACTION_SHOW')}
  //         icon={
  //           <Typography.Icon
  //             icon={visible ? EyeOutlined : EyeInvisibleOutlined}
  //           />
  //         }
  //         onClick={() =>
  //           editReportKpis(id, { isGrouper, visible: !visible, order })
  //         }
  //       />
  //     </Space>
  //   );
  // };

  return (
    <Card
      className="report-detail-kpi-card"
      title={title}
      size="small"
      data-type={isGrouper ? 'grouper' : 'default'}
      // extra={showEditionMode && renderIcons()}
    >
      <Row align="middle" gutter={[8, 8]}>
        <Col span={24}>
          <KPIValue
            size="medium"
            fullValue={value && numeral(value).format('0,0.00')}
            value={value ? numeral(value).format('0[.]0a') : '-'}
            prefix={renderPrefix ? renderPrefix(prefix) : prefix}
          />
          <Typography.Body
            title={isEconomic ? t(DATA_KPIS[name].title) : title}
            level={3}
            type="secondary"
          >
            {isEconomic ? DATA_KPIS[name].name : t('FIELD_TOTAL')}
          </Typography.Body>
        </Col>
        {subkpis.map((kpi, index) => (
          <Col span={isEconomic ? 8 : 12} key={index}>
            <KPIValue
              size="extra-small"
              prefix={renderPrefix ? renderPrefix(kpi.prefix) : kpi.prefix}
              fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
              value={kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'}
              variationValue={kpi.variation}
            />
            <br />
            <Typography.Body
              title={isEconomic ? t(DATA_KPIS[kpi.name].title) : kpi.title}
              level={3}
              type="secondary"
            >
              {isEconomic ? DATA_KPIS[kpi.name].name : kpi.title}
            </Typography.Body>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

const CarouselArrowPrev = ({ className, onClick }) => (
  <LeftCircleOutlined
    className={`${className} report-kpi-carousel-arrow`}
    onClick={onClick}
  />
);

const CarouselArrowNext = ({ className, onClick }) => (
  <RightCircleOutlined
    className={`${className} report-kpi-carousel-arrow`}
    onClick={onClick}
  />
);

const ReportKPICarousel = ({
  dataKPI = [],
  isGrouper = false,
  isLoading = false,
  // showEditionMode,
  editReportKpis,
  renderPrefix = null,
  isEconomic = false,
}) => {
  const renderReportDetailKPICard = (
    index,
    value,
    title,
    prefix,
    name,
    id,
    visible,
    children,
    order,
    renderPrefix,
    isEconomic
  ) => {
    return (
      <ReportDetailKPICard
        key={index}
        title={title}
        value={value}
        prefix={prefix}
        name={name}
        id={id}
        visible={visible}
        subkpis={children}
        isGrouper={isGrouper}
        // showEditionMode={showEditionMode}
        editReportKpis={editReportKpis}
        order={order}
        renderPrefix={renderPrefix}
        isEconomic={isEconomic}
      />
    );
  };

  if (isLoading) {
    return (
      <Row gutter={[24, 0]}>
        {[1, 2, 3, 4].map(index => (
          <Col span={6} key={index}>
            <Card loading={true} />
          </Col>
        ))}
      </Row>
    );
  }

  if (!isLoading) {
    return (
      <Carousel
        className="report-kpi-carousel"
        slidesToShow={4}
        slidesToScroll={1}
        initialSlide={0}
        infinite={false}
        dots={false}
        arrows={true}
        nextArrow={<CarouselArrowNext />}
        prevArrow={<CarouselArrowPrev />}
        responsive={[
          {
            breakpoint: 1580,
            settings: { slidesToShow: 4, slidesToScroll: 1 },
          },
          {
            breakpoint: 1350,
            settings: { slidesToShow: 3, slidesToScroll: 1 },
          },
          {
            breakpoint: 1100,
            settings: { slidesToShow: 2, slidesToScroll: 1 },
          },
          {
            breakpoint: 850,
            settings: { slidesToShow: 1, slidesToScroll: 1 },
          },
        ]}
      >
        {/* {showEditionMode
          ? dataKPI.map((kpi, index) => {
              const {
                id,
                title,
                value,
                prefix,
                name,
                children,
                visible,
                order,
              } = kpi;
              return renderReportDetailKPICard(
                index,
                value,
                title,
                prefix,
                name,
                id,
                visible,
                children,
                order,
                renderPrefix,
                isEconomic
              );
            })
          :  */}
        {_.compact(
          dataKPI.map((kpi, index) => {
            const { id, title, value, prefix, name, children, visible, order } =
              kpi;
            return visible
              ? renderReportDetailKPICard(
                  index,
                  value,
                  title,
                  prefix,
                  name,
                  id,
                  visible,
                  children,
                  order,
                  renderPrefix,
                  isEconomic
                )
              : null;
          })
        )}
      </Carousel>
    );
  }
};

const mapDispatchToProps = {
  editReportKpis: reports.actions.editReportKpis,
};

export default connect(null, mapDispatchToProps)(ReportKPICarousel);
