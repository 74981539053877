import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spin, Table, Row, Col } from 'antd';
import { Navigator } from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { UserAvatar } from 'modules/configuration/components';
import { INTEGRATIONS } from 'modules/core/constants';
import configuration from 'modules/configuration';
import login from 'modules/login';

const UserDetail = ({
  userDetail: {
    first_name,
    last_name,
    email,
    roles,
    status,
    password_confirmed,
  },
  fetchUserDetail,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const { t } = useTranslation();
  let { userId, myProfile } = useParams();

  useEffect(() => {
    fetchUserDetail(userId, { flat: 'True' }).then(() =>
      setIsScreenLoading(false)
    );
  }, [userId, fetchUserDetail]);

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={
        myProfile
          ? [{ name: t('LABEL_PROFILE') }]
          : [
              {
                name: t('LABEL_CONFIG'),
                url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
              },
              {
                name: t('LABEL_MY_DATA'),
                url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
              },
              {
                name: t('LABEL_USERS'),
                url: '/configuracion/misdatos/seguridad/?tab=users',
              },
              {
                name: t('LABEL_DETAIL'),
              },
            ]
      }
    />
  );

  const renderUserAvatar = () => {
    if (password_confirmed) {
      return (
        <UserAvatar
          size="large"
          avatarLetter={`${last_name[0]}${first_name[0]}`}
          fullname={`${last_name}, ${first_name}`}
        />
      );
    }
    return (
      <UserAvatar
        size="large"
        avatarLetter={email[0]}
        fullname={email.substr(0, email.indexOf('@'))}
        isItalic={true}
      />
    );
  };

  const renderDetailTable = () => (
    <Table
      bordered
      size="small"
      rowKey="profile"
      columns={[
        { title: t('LABEL_PROFILE'), dataIndex: 'profile' },
        { title: '', dataIndex: 'value' },
      ]}
      dataSource={[
        { profile: t('FIELD_EMAIL'), value: email },
        { profile: t('CONFIG_USER_COMPANY_POSITION_FIELD'), value: '-' },
        {
          profile: t('LABEL_ROLES'),
          value: roles.length > 0 ? roles.join(', ') : '-',
        },
        {
          profile: t('FIELD_STATUS'),
          value:
            status === 'active'
              ? t('STATE_TAG_ACTIVE')
              : t('STATE_TAG_INACTIVE'),
        },
      ]}
      pagination={false}
    />
  );
  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && (
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Col span={12} offset={10}>
            {renderUserAvatar()}
          </Col>
          <Col span={24}>{renderDetailTable()}</Col>
        </Row>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  userDetail: configuration.selectors.getUserDetail(state),
});

const mapDispatchToProps = {
  fetchUserDetail: configuration.actions.fetchUserDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
