import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import EconomicReportHome from '../../../EconomicReports/ReportsHome/ReportsHome';
import FinancialReportHome from '../../../FinancialReports/ReportsHome/ReportsHome';
import { SiderLayout } from 'modules/core/layouts';
import { PeriodNavigator } from 'modules/core/components';
import { userCanAcces } from 'modules/core/utils';
import { useQuery } from 'modules/core/customHooks';
import { useTranslation } from 'react-i18next';
import { ACCES_USERS, CONCEPTS } from 'modules/core/constants';
import login from 'modules/login';
import planning from 'modules/planning';
import { useParams } from 'react-router-dom';
import { ExtraActions } from './components';

// TODO: AGREGAR PERMISOS ECONOMICO-FINANCIERO
const ReportTypeHome = ({
  loggedUser: { email },
  fetchPeriodDetail,
  periodDetail,
}) => {
  let { periodId } = useParams();
  let query = useQuery();

  const [activeTab, setActiveTab] = useState(
    query.get('tab') !== null ? query.get('tab') : CONCEPTS.TYPE.ECONOMIC_KEY
  );
  const { t } = useTranslation();

  useEffect(() => {
    fetchPeriodDetail(periodId);
  }, [fetchPeriodDetail, periodId]);

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        { name: t('LABEL_ANALYSIS'), url: `/analisis/${periodId}` },
        { name: t('REPORT_PLIKA_CARD_TITLE') },
      ]}
    />
  );

  return (
    <SiderLayout>
      <>
        {renderHeader()}
        {[...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS].some(el =>
          email.includes(el)
        ) && <ExtraActions periodDetail={periodDetail} />}
        <Tabs size="small" onChange={setActiveTab} defaultActiveKey={activeTab}>
          <Tabs.TabPane
            key={CONCEPTS.TYPE.ECONOMIC_KEY}
            tab={t('LABEL_ECONOMIC')}
          >
            <EconomicReportHome />
          </Tabs.TabPane>
          {userCanAcces({
            patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
            email,
          }) && (
            <Tabs.TabPane
              key={CONCEPTS.TYPE.FINANCIAL_KEY}
              tab={t('LABEL_FINANCIAL')}
            >
              <FinancialReportHome />
            </Tabs.TabPane>
          )}
        </Tabs>
      </>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
});

const mapDispatchToProps = {
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTypeHome);
