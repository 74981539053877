import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Space,
  Table,
  Popconfirm,
  message,
  notification,
  Spin,
  Input,
} from 'antd';
import {
  ButtonActionBar,
  EmptyScreen,
  Typography,
  CreateOtherReportModal,
  DropdownMenu,
  InputCell,
  DeleteAndUpdate,
} from 'modules/core/components';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePagination } from 'modules/core/customHooks';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { isUserAllowed } from 'modules/core/utils';
import { REPORT, ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';
import _ from 'lodash';

const {
  SETTINGS__REPORTS__CUSTOM_REPORTS__CREATE,
  SETTINGS__REPORTS__CUSTOM_REPORTS__DELETE,
  SETTINGS__REPORTS__CUSTOM_REPORTS__EDIT,
} = ROLES;

const { Search } = Input;

//TODO: VER PORQUE NO APARECE EL REPORTE PERSONALIZADO CREADO EN CONFIG
const OtherReportsList = ({
  fnlReportsData,
  otherReportsList: { data, count },
  fetchOtherReportsList,
  deleteOtherReport,
  updateOtherReportName,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [deleteReportId, setDeleteReportId] = useState(null);
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const [tablePagination, setTablePagination] = usePagination();
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setIsScreenLoading(true);
    fetchOtherReportsList({
      ...tablePagination,
      type: REPORT.REPORTS_KEYS.FNL_KEY,
    }).then(() => setIsScreenLoading(false));
  }, [fetchOtherReportsList, tablePagination]);

  const renderCreateOtherReportModal = () => (
    <CreateOtherReportModal
      reportData={fnlReportsData}
      rolesAvailables={[SETTINGS__REPORTS__CUSTOM_REPORTS__CREATE]}
      onFetchOtherReportList={() =>
        fetchOtherReportsList({
          ...tablePagination,
          type: REPORT.REPORTS_KEYS.FNL_KEY,
        })
      }
    />
  );

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_REPORTS_EMPTY_STATE_TITLE')}
      footer={renderCreateOtherReportModal()}
    />
  );

  const functionSearch = (values, searchValue) => {
    const results = [];
    values.forEach(value => {
      if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
        results.push(value);
      }
    });
    return results;
  };

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="configuration-reports-list-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        {renderCreateOtherReportModal()}
      </Space>
    </ButtonActionBar>
  );

  const onDeleteReport = () => {
    setIsDeletingReport(true);
    deleteOtherReport(deleteReportId, { type: REPORT.REPORTS_KEYS.FNL_KEY })
      .then(() =>
        fetchOtherReportsList({
          ...tablePagination,
          type: REPORT.REPORTS_KEYS.FNL_KEY,
        }).then(() => {
          setIsDeletingReport(false);
          setDeleteReportId(null);
          message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
        })
      )
      .catch(() => {
        setIsDeletingReport(false);
        setDeleteReportId(null);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_FAIL'),
          duration: 0,
        });
      });
  };

  const onCellEdit = (name, value) =>
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });

  const renderTable = () => {
    const columns = [
      {
        title: t('FIELD_NAME'),
        dataIndex: 'name',
        render: (name, record) =>
          editRow && record.id === editRow ? (
            <InputCell
              initialValue={name}
              onCellUpdate={onCellEdit}
              name="name"
            />
          ) : (
            name
          ),
      },
      {
        title: t('LABEL_REPORT'),
        dataIndex: 'report',
        render: data => data.name,
      },
      {
        title: t('FIELD_LINE_REPORT'),
        dataIndex: 'report_row',
        render: data => data.name,
      },
      {
        title: t('FIELD_DIMENSION'),
        dataIndex: 'dimension',
        render: data => data.name,
      },
      {
        title: t('FIELD_ACTIONS'),
        dataIndex: 'id',
        align: 'right',
        width: 150,
        render: (id, record) => {
          return id !== editRow ? (
            <Popconfirm
              placement="bottomRight"
              title={t('CONFIG_REPORT_DELETE_TITLE')}
              okText={t('ACTION_DELETE')}
              okButtonProps={{ loading: isDeletingReport }}
              onConfirm={() => onDeleteReport()}
              cancelText={t('ACTION_CANCEL')}
              onCancel={() => setDeleteReportId(null)}
              visible={deleteReportId === id}
            >
              <Space size="middle">
                <Button
                  type="text"
                  shape="circle"
                  icon={<Typography.Icon icon={EyeOutlined} />}
                  title={t('ACTION_OPEN_DETAIL')}
                  onClick={() =>
                    history.push(
                      `/configuracion/misdatos/reportes/financieros/personalizados/${record.id}`
                    )
                  }
                />
                <DropdownMenu
                  title={t('ACTION_MORE')}
                  menu={[
                    {
                      title: t('ACTION_EDIT'),
                      icon: <EditOutlined />,
                      onClick: () => setEditRow(id),
                      disabled: !isUserAllowed(
                        SETTINGS__REPORTS__CUSTOM_REPORTS__EDIT
                      ),
                    },
                    {
                      title: t('ACTION_DELETE'),
                      icon: <DeleteOutlined />,
                      onClick: () => setDeleteReportId(id),
                      disabled: !isUserAllowed(
                        SETTINGS__REPORTS__CUSTOM_REPORTS__DELETE
                      ),
                    },
                  ]}
                />
              </Space>
            </Popconfirm>
          ) : (
            <DeleteAndUpdate
              onCancel={onCancel}
              onConfirm={onConfirm}
              onSave={onEditReportName}
              dataCellEdit={dataCellEdit}
            />
          );
        },
      },
    ];

    const onCancel = () => setEditRow(null);

    const onConfirm = () => {
      setEditRow(null);
      setDataCellEdit({});
    };

    const onEditReportName = () => {
      if (!_.isEmpty(dataCellEdit)) {
        setIsEditing(true);
        updateOtherReportName(editRow, dataCellEdit, {
          type: REPORT.REPORTS_KEYS.FNL_KEY,
        })
          .then(() => {
            fetchOtherReportsList({
              ...tablePagination,
              type: REPORT.REPORTS_KEYS.FNL_KEY,
            }).then(() => {
              setIsEditing(false);
              setEditRow(null);
              setDataCellEdit({});
              message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES', 0.5));
            });
          })
          .catch(() => {
            setIsEditing(false);
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: t('FEEDBACK_EDIT_REPORT_NAME_ERROR'),
              duration: 0,
            });
            setEditRow(null);
            setDataCellEdit({});
          });
      }
    };

    return (
      <Table
        rowKey="id"
        bordered
        size="small"
        loading={isScreenLoading || isDeletingReport || isEditing}
        columns={columns}
        onChange={setTablePagination}
        dataSource={
          searchValue === null ? data : functionSearch(data, searchValue)
        }
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total:
            searchValue === null || searchValue === ''
              ? count
              : functionSearch(data, searchValue).length,
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }}
      />
    );
  };

  return (
    <>
      <Spin spinning={isScreenLoading} />
      <Row gutter={[24, 24]}>
        {count <= 0 && !isScreenLoading && !searchValue && renderEmptyScreen()}
        {count > 0 && !isScreenLoading && (
          <>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>{renderTable()}</Col>
          </>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  otherReportsList: configuration.selectors.getOtherReportsList(state),
});

const mapDispatchToProps = {
  fetchOtherReportsList: configuration.actions.fetchOtherReportsList,
  deleteOtherReport: configuration.actions.deleteOtherReport,
  updateOtherReportName: configuration.actions.updateOtherReportName,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherReportsList);
