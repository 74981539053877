import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { PeriodNavigator } from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils';
import { ROLES, PROJECTION, CONCEPTS } from 'modules/core/constants';
import planning from 'modules/planning';
import { RealList } from './components';
import login from 'modules/login';

const { CONTROL__REAL } = ROLES;

//TO DO: agregarlos cuando se puedan filtrar los nuevos conceptos
// const CONTROL__REAL__CONCEPT__VIEW = {
//   [CONCEPTS.KEYS.SALES]: CONTROL__REAL__SALES__VIEW,
//   [CONCEPTS.KEYS.EXPENSES]: CONTROL__REAL__EXPENSES__VIEW,
// };

const Real = ({ projections, fetchProjections, loggedUser: { email } }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const { t } = useTranslation();
  let { periodId } = useParams();

  const canUserAccessReal = isUserAllowed(getOperationsByKeys([CONTROL__REAL]));

  useEffect(() => {
    setIsScreenLoading(true);
    Promise.all([
      fetchProjections({ period: periodId, type: PROJECTION.REAL }),
    ]).then(() => {
      setIsScreenLoading(false);
    });
  }, [fetchProjections, periodId]);

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        { name: t('LABEL_CONTROL'), url: `/seguimiento/${periodId}` },
        { name: t('LABEL_FINANCIAL') },
      ]}
    />
  );

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <Tabs size="small">
            {canUserAccessReal && (
              <Tabs.TabPane tab="Real" key="real">
                {projections.length > 0 && (
                  <RealList
                    dataSource={projections.filter(
                      projection =>
                        projection.concept.type === CONCEPTS.TYPE.FINANCIAL_KEY
                    )}
                    onFetchProjections={() =>
                      fetchProjections({
                        period: periodId,
                        type: PROJECTION.REAL,
                      })
                    }
                  />
                )}
              </Tabs.TabPane>
            )}
          </Tabs>
        </>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  projections: planning.selectors.getProjections(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchProjections: planning.actions.fetchProjections,
};

export default connect(mapStateToProps, mapDispatchToProps)(Real);
