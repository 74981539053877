import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { ConfigurationUploadModal } from 'modules/configuration/components';
import planning from 'modules/planning';
import { ConceptsTable } from './components';

//TODO: DOWNLOAD TABLE DATA
const ConceptsList = ({
  uploadConceptValuesXLS,
  isTableLoading,
  onFetchConceptsList,
  economicConceptsValues,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <ConceptsTable
            dataSource={economicConceptsValues}
            tableLoading={isTableLoading}
          />
        </Col>
      </Row>
      <ConfigurationUploadModal
        title={t('CONFIG_CONCEPTS_UPLOAD_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        onSuccessUpload={() => onFetchConceptsList()}
        uploadValuesXLS={file => uploadConceptValuesXLS(file)}
      />
    </>
  );
};

const mapDispatchToProps = {
  uploadConceptValuesXLS: planning.actions.uploadConceptValuesXLS,
};

export default connect(null, mapDispatchToProps)(ConceptsList);
