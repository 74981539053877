import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select, Button, Radio, Space, Form } from 'antd';
import { ControlOutlined } from '@ant-design/icons';
import { Typography } from 'modules/core/components';
import { TABLES } from 'modules/core/constants';
import _ from 'lodash';
import { generateSelectOptions, handleSelectAll } from 'modules/core/utils';

const NUMBER_TYPES = [
  { key: 'integer', value: '0,0' },
  { key: 'decimals', value: '0,0.00' },
  { key: 'millions', value: '0[.]0a' },
];

const isNegativeValueBracket = value => {
  return TABLES.NEGATIVE_VALUES_TYPES.BRACKET === value;
};

//TODO: REVISAR ESTE COMPONENTE. MEJORAR SU ORGANIZACION.
const TableSettingsModal = ({
  title,
  onConfirm = () => {},
  options = [],
  type = '',
  settings: {
    visibleData,
    format,
    negativeValuesType = TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
  },
  formItems = {},
}) => {
  const [visible, setVisible] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(true);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const handleConfirm = () => {
    form
      .validateFields()
      .then(values => {
        const data = {
          visibleData: values.dimensions,
          format: isNegativeValueBracket(values.negativeValuesType)
            ? `(${values.format})`
            : values.format,
        };
        if (!_.isEmpty(formItems)) {
          const otherData = _.keys(formItems)
            .map(key => formItems[key])[0]
            .map(item => ({
              [item.name]: values[item.name],
            }));
          onConfirm(_.defaults(data, ...Object.values(otherData)));
        } else onConfirm(data);
        setVisible(false);
      })
      .catch(e => {});
  };

  return (
    <>
      <Button
        type="text"
        shape="circle"
        icon={<Typography.Icon icon={ControlOutlined} />}
        onClick={() => setVisible(true)}
        title={t('DISPLAY_MODAL_SETTINGS_TITLE')}
      />
      {visible && (
        <Modal
          title={title ? title : t('DISPLAY_MODAL_SETTINGS_TITLE')}
          centered
          visible={visible}
          destroyOnClose={true}
          onCancel={handleClose}
          onOk={handleConfirm}
          okText={t('ACTION_APPLY')}
          cancelText={t('ACTION_CANCEL')}
          bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        >
          <Space direction="vertical" size="middle">
            <Typography.Body>{t('TABLE_SETTING_MODAL_TITLE')}</Typography.Body>
            <Form form={form} layout="vertical">
              <Form.Item
                name="format"
                label={t('DISPLAY_TABLE_SETTING')}
                initialValue={
                  isNegativeValueBracket(negativeValuesType)
                    ? format.slice(1, -1)
                    : format
                }
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {NUMBER_TYPES.map(option => (
                      <Radio value={option.value} key={option.key}>
                        {t(option.key)}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              {!_.isEmpty(formItems) &&
                formItems.dataView &&
                formItems.dataView.map((item, index) => (
                  <Form.Item
                    valuePropName={item.prop && item.prop}
                    key={index}
                    name={item.name}
                    label={item.label}
                    initialValue={item.initialValue}
                  >
                    {item.component}
                  </Form.Item>
                ))}
              {options && options.length > 0 && (
                <Form.Item
                  name="dimensions"
                  label={t('LABEL_DIMENSION_FILTER')}
                  initialValue={
                    visibleData
                      ? visibleData
                      : options &&
                        options.map(option =>
                          type === 'verticalSegmented'
                            ? option.title
                            : option.data_index
                        )
                  }
                >
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    showSearch={true}
                    mode="multiple"
                    onChange={values =>
                      handleSelectAll({
                        values,
                        allValues: options.map(op => op.data_index),
                        areAllSelected,
                        onSetValues: values =>
                          form.setFieldsValue({ dimensions: values }),
                        onSetAreAllSelected: state => setAreAllSelected(state),
                      })
                    }
                    placeholder={t('COLUMNS_SETTING_MODAL_PLACEHOLDER')}
                    options={generateSelectOptions({
                      options: options.map(op => {
                        return {
                          id: op.data_index,
                          name: `${op.title} ${
                            op.description ? op.description : ''
                          }`,
                        };
                      }),
                      includeOptionAll: true,
                    })}
                  />
                </Form.Item>
              )}
              {/* Form items customs */}
              {!_.isEmpty(formItems) &&
                formItems.tableView &&
                formItems.tableView.map((item, index) => (
                  <Form.Item
                    key={index}
                    name={item.name}
                    label={item.label}
                    initialValue={item.initialValue}
                  >
                    {item.component}
                  </Form.Item>
                ))}
              {!_.isEmpty(formItems) &&
                formItems.columnView &&
                formItems.columnView.map((item, index) => (
                  <Form.Item key={index} name={item.name} label={item.label}>
                    {item.component}
                  </Form.Item>
                ))}
            </Form>
          </Space>
        </Modal>
      )}
    </>
  );
};

export default TableSettingsModal;
