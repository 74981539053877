import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ValidationHasData, ValidationNotData } from './components';
import _ from 'lodash';
import reports from 'modules/reports';

const SegregatedAnalysis = ({
  reportFunctionalCurrencyData,
  dimensionsList,
  fetchAvailableDimensions,
  availableDimensions,
}) => {
  const [isDimensionsLoading, setIsDimensionsLoading] = useState(false);
  let { periodId, reportId } = useParams();

  useEffect(() => {
    setIsDimensionsLoading(true);
    fetchAvailableDimensions(reportId, { period_id: periodId }).then(() =>
      setIsDimensionsLoading(false)
    );
  }, [fetchAvailableDimensions, reportId, periodId]);

  const dimensionsListData =
    dimensionsList.dimensions.length !== 0 &&
    dimensionsList.dimensions.filter(dimension =>
      _.keys(availableDimensions).includes(dimension.id.toString())
    );

  return (
    <>
      <Spin spinning={isDimensionsLoading} />
      <ValidationNotData
        isDimensionsLoading={isDimensionsLoading}
        dimensionsListData={dimensionsListData}
      />
      <ValidationHasData
        isDimensionsLoading={isDimensionsLoading}
        dimensionsListData={dimensionsListData}
        reportFunctionalCurrencyData={reportFunctionalCurrencyData}
      />
    </>
  );
};

const mapStateToProps = state => ({
  availableDimensions: reports.selectors.getAvailableDimensions(state),
});

const mapDispatchToProps = {
  fetchAvailableDimensions: reports.actions.fetchAvailableDimensions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegregatedAnalysis);
