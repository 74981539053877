import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SiderLayout } from 'modules/core/layouts';
import {
  ButtonActionBar,
  Typography,
  EmptyScreen,
} from 'modules/core/components';
import { Card, List, Button, Row, Col, Collapse, Space } from 'antd';
import {
  isUserAllowed,
  getPeriodOperationsKeys,
  getOperationsByKeys,
} from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import { useTranslation } from 'react-i18next';
import {
  RightCircleOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import login from 'modules/login';
import planning from 'modules/planning';
import { useHistory } from 'react-router-dom';
import DATA_DASHBOARD from './mockDataDashboard';

import './DashboardGeneral.scss';

const { Panel } = Collapse;

const { SETTINGS } = ROLES;

const DashboardGeneral = ({
  loggedUser: { firstName },
  hasDataConfig,
  periodsList,
  fetchPeriodList,
}) => {
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    fetchPeriodList();
  }, [fetchPeriodList]);

  const canGoToPeriods = isUserAllowed(getPeriodOperationsKeys());
  const canGoToConfig = isUserAllowed(getOperationsByKeys([SETTINGS]));

  const renderHeader = () => (
    <div className="dashboard-title">
      <Typography.Headline level={3} type="primary">
        {t('DASHBOARD_USER_HELLO', { name: firstName })}
      </Typography.Headline>
      <Typography.Body>{t('DASHBOARD_SUBTITLE')}</Typography.Body>
    </div>
  );

  const renderSubtitle = ({ name }) => (
    <div style={{ paddingBottom: 16 }}>
      <Typography.Subtitle>{t(name)}</Typography.Subtitle>
    </div>
  );

  const renderContacts = () => {
    const renderName = name => <Typography.Body>{name}</Typography.Body>;

    const renderMail = mail => (
      <Space direction="horizontal">
        <Typography.Icon icon={MailOutlined} />
        <Typography.Body>
          <a href={`mailto:${mail}`}>{mail}</a>
        </Typography.Body>
      </Space>
    );

    const renderNumber = (numero, linkWhatsapp) => (
      <Space direction="horizontal">
        <Typography.Icon icon={WhatsAppOutlined} />
        <Typography.Body>
          <a target="_blank" rel="noopener noreferrer" href={linkWhatsapp}>
            {numero}
          </a>
        </Typography.Body>
      </Space>
    );

    const renderLink = (link, index) => (
      <Space direction="horizontal" key={index}>
        <Typography.Body>{t('DASHBOARD_WHATSAPP_CONTACT')}</Typography.Body>
        <Typography.Body>
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {t('DASHBOARD_WHATSAPP_CONTACT_LINK')}
          </a>
        </Typography.Body>
      </Space>
    );

    return (
      <Collapse accordion={true} defaultActiveKey={[1]}>
        {DATA_DASHBOARD.contacts.map(contact => (
          <Panel header={t(contact.title)} key={contact.id}>
            {contact.description.map((des, index) =>
              !des.link ? (
                <Space direction="vertical" key={index}>
                  {renderName(des.name)}
                  {renderMail(des.mail)}
                  {renderNumber(des.numero, des.linkWhatsapp)}
                </Space>
              ) : (
                renderLink(des.link, index)
              )
            )}
          </Panel>
        ))}
      </Collapse>
    );
  };

  const renderVideo = () => (
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/videoseries?list=PLJo0bl0cWTAO31mQYzB1FH3ErJFWmUi1t"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );

  const renderListCards = () => (
    <List
      grid={{ gutter: 24, column: 4 }}
      dataSource={DATA_DASHBOARD.dataSource}
      renderItem={item => (
        <List.Item>
          <Card
            key={item.id}
            className="list-card-novedades"
            data-type="novedades"
            bodyStyle={{ backgroundColor: '#e1effa', padding: 0 }}
            actions={[
              <ButtonActionBar position="space-between">
                <Button
                  type="link"
                  onClick={() => window.open(item.link, '_blank')}
                >
                  {t(item.textoBoton)}
                  <RightCircleOutlined />
                </Button>
              </ButtonActionBar>,
            ]}
          >
            <>
              <img
                className="container-imagen"
                src={item.img}
                alt={item.name}
              />
              <div className="container-description">
                <Typography.Subtitle>{t(item.name)}</Typography.Subtitle>
                <Typography.Body level={3}>
                  {t(item.description)}
                </Typography.Body>
              </div>
            </>
          </Card>
        </List.Item>
      )}
    />
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('DASHBOARD_WELLCOME')}
      description={t('DASHBOARD_EMPTY_STATE_DESCRIPTION')}
      footer={
        <ButtonActionBar>
          {canGoToConfig && (
            <Button
              type="primary"
              onClick={() => history.push(`/configuracion`)}
            >
              {t('DASHBOARD_CONFIG_ACTION')}
            </Button>
          )}
          {canGoToPeriods && (
            <Button type="primary" onClick={() => history.push(`/periodos`)}>
              {t('DASHBOARD_PERIODS_ENTER_ACTION')}
            </Button>
          )}
        </ButtonActionBar>
      }
    />
  );

  return (
    <SiderLayout>
      {periodsList.length > 0 && hasDataConfig && (
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Col span={24}>
            {renderSubtitle({ name: 'DASHBOARD_NEWS_PLIKA' })}
            {renderListCards()}
          </Col>
          <Col span={12}>
            {renderSubtitle({ name: 'LABEL_TUTORIALS' })}
            {renderVideo()}
          </Col>
          <Col span={12}>
            {renderSubtitle({ name: 'DASHBOARD_SUPPORT_INFO' })}
            {renderContacts()}
          </Col>
        </Row>
      )}
      {(periodsList.length <= 0 || !hasDataConfig) && renderNoDataScreen()}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  periodsList: planning.selectors.getPeriods(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriods,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGeneral);
