import { NAME } from './constants';
import { createSelector } from 'reselect';
import { COLUMNS } from 'modules/core/constants';

export const getRealColumnsRaw = state => state[NAME].realColumns;
export const getRealColumns = createSelector(
  getRealColumnsRaw,
  realColumnsRaw =>
    realColumnsRaw.map(column => {
      if (COLUMNS.ALL_TRANSACTION.includes(column.data_index)) {
        return {
          ...column,
          width: COLUMNS.WIDTH.TRANSACTION,
          is_transaction: true,
        };
      }
      return {
        ...column,
        width:
          column.data_index === COLUMNS.TOTAL_DATA_INDEX
            ? COLUMNS.WIDTH.TRANSACTION
            : COLUMNS.WIDTH.DIMENSION,
        is_transaction: false,
      };
    })
);

export const getRealRows = state => state[NAME].realRows;
export const getControlDetailKpis = state => state[NAME].controlDetailKPIS;
export const getMonthsToPublish = state => state[NAME].monthsToPublish;
export const getMonthsToApprove = state => state[NAME].monthsToApprove;
export const getApproveDates = state => state[NAME].approveDates;
export const getPublishedMonths = state => state[NAME].publishedMonths;
