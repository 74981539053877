import React, { useState } from 'react';
import { Modal, Input, Col, Row, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { TextArea } = Input;

const ConfirmationModal = ({
  title = '',
  bodyText = '',
  okText = '',
  visible,
  onClose,
  onConfirm,
  loading = false,
  canceled = false,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  return (
    <Modal
      title={title}
      visible={visible}
      centered
      confirmLoading={loading}
      okButtonProps={{ disabled: loading }}
      closable={!loading}
      destroyOnClose={true}
      footer={
        <>
          <Button onClick={onClose}>{t('ACTION_CANCEL')}</Button>
          <Button type="primary" onClick={() => onConfirm(comment)}>
            {okText}
          </Button>
        </>
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>{bodyText}</Col>
        {canceled && (
          <Col span={24}>
            <Text>
              {t('ADJUSTMENT_DISTRIBUTION_MODAL_CANCEL_COMMENT_TEXT')}
            </Text>
            <TextArea
              autoComplete="off"
              autoSize={{ maxRows: 1 }}
              onChange={value => setComment(value.target.value)}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
