import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReportCardsHome from './ReportCardsHome/ReportCardsHome';
import { ReportTypeHome, ReportDashboards } from './ReportCardsHome/components';
import EconomicReportDetail from './EconomicReports/ReportDetail/ReportDetail';
import EconomicOtherReportDetail from './EconomicReports/OtherReportDetail/OtherReportDetail';
import FinancialReportDetail from './FinancialReports/ReportDetail/ReportDetail';
import FinancialOtherReportDetail from './FinancialReports/OtherReportDetail/OtherReportDetail';

const Home = () => (
  <Switch>
    <Route exact path="/analisis/:periodId" component={ReportCardsHome} />
    <Route
      exact
      path="/analisis/:periodId/reportesplika"
      component={ReportTypeHome}
    />
    <Route
      exact
      path="/analisis/:periodId/reportesselfservice"
      component={ReportDashboards}
    />
    <Route
      exact
      path="/analisis/:periodId/reportesplika/economicos/reportes/:reportId"
      component={EconomicReportDetail}
    />
    <Route
      exact
      path="/analisis/:periodId/reportesplika/financieros/reportes/:reportId"
      component={FinancialReportDetail}
    />
    <Route
      exact
      path="/analisis/:periodId/reportesplika/economicos/personalizados/:reportId"
      component={EconomicOtherReportDetail}
    />
    <Route
      exact
      path="/analisis/:periodId/reportesplika/financieros/personalizados/:reportId"
      component={FinancialOtherReportDetail}
    />
    <Redirect to="/analisis" />
  </Switch>
);

export default Home;
