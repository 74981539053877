import React from 'react';
import { Table, Space } from 'antd';
import { Typography } from 'modules/core/components';
import numeral from 'numeral';

import './AdjustmentDetailTable.scss';

const AdjustmentDetailTable = ({
  isLoading = false,
  title = '',
  dataSource = [],
  columns = [],
  prefix,
}) => {
  const columnsGenerator = columns =>
    columns.map((column, index) => {
      return {
        dataIndex: column.data_index,
        title: column.title,
        ellipsis: true,
        ...(column.data_index === 'amount' && {
          align: 'right',
          width: 120,
          render: amount => `${prefix} ${numeral(amount).format('0,0')}`,
        }),
      };
    });

  return (
    <Space
      className="adjustment-detail-table"
      direction="vertical"
      size="large"
    >
      <Typography.Headline level={5}>{title}</Typography.Headline>
      <Table
        rowKey="id"
        size="small"
        bordered
        loading={isLoading}
        columns={columnsGenerator(columns)}
        dataSource={dataSource}
        pagination={{
          size: 'small',
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
      />
    </Space>
  );
};

export default AdjustmentDetailTable;
