import { NAME } from './constants';
import { createSelector } from 'reselect';
import { COLUMNS, PROJECTION } from 'modules/core/constants';
import _ from 'lodash';

export const getPeriods = state => state[NAME].periods;
export const getPeriodDetail = state => state[NAME].periodDetail;
export const getConceptsList = state => state[NAME].conceptsList;
export const getConceptsDetail = state => state[NAME].conceptsDetail;
export const getProjections = state => state[NAME].projections;
export const getProjectionDetail = state => state[NAME].projectionDetail;
export const getProjectionColumnsDefault = state =>
  state[NAME].projectionColumns;

export const getProjectionColumns = createSelector(
  getProjectionColumnsDefault,
  projectionColumns =>
    projectionColumns.map(col => {
      return {
        ...col,
        ...(col.is_transaction && {
          width: COLUMNS.WIDTH.DEFAULT,
        }),
        ...(!col.is_transaction && {
          width:
            col.data_index === COLUMNS.TOTAL_DATA_INDEX
              ? COLUMNS.WIDTH.TRANSACTION
              : COLUMNS.WIDTH.DIMENSION,
        }),
      };
    })
);

export const getProjectionsColumnsImporte = createSelector(
  getProjectionColumnsDefault,
  projectionColumns =>
    projectionColumns.map(col => {
      return !col.is_transaction
        ? {
            ...col,
            width:
              col.data_index === COLUMNS.TOTAL_DATA_INDEX
                ? COLUMNS.WIDTH.TRANSACTION
                : COLUMNS.WIDTH.DIMENSION,
          }
        : {
            width: COLUMNS.WIDTH.TRANSACTION,
            data_index: col.data_index,
            is_transaction: col.is_transaction,
            published: col.published,
            title: col.title,
            ...(col.editable && { editable: col.editable }),
          };
    })
);

export const getProjectionRows = state => state[NAME].projectionRows;
export const getProjectionAmountsRows = state =>
  state[NAME].projectionAmountsRows;
export const getProjectionKpis = state => state[NAME].projectionKPIS;
export const getProjectionAmountsKpis = state =>
  state[NAME].projectionAmountsKPIS;
export const getUserTracks = state => state[NAME].userTracks;
export const getFileTracks = state => state[NAME].fileTracks;
// NOTE: Quizas cuando se muevan todos los filtros a la cabecera, ya no sea necesario este selector.
export const getFilterDimensions = state => state[NAME].filterDimensions;
export const getFilterTableOptions = createSelector(
  getFilterDimensions,
  filterDimensions =>
    filterDimensions.reduce((acc, el) => {
      return {
        ...acc,
        [el.name]: {
          id: el.id,
          options: el.values,
        },
      };
    }, {})
);
export const getPublishedAccounts = state => state[NAME].publishedAccounts;
export const getConsolidationList = state => state[NAME].consolidationList;
export const getUploadLimitDate = state => state[NAME].projectionDeadline;
export const getConsolidationKPIS = state => state[NAME].consolidationKPIS;
export const getFormulasAmountList = state => state[NAME].formulasAmountList;
export const getFactorRows = state => state[NAME].factorRows;
export const getFactorColumns = state => state[NAME].factorColumns;
export const getDimensionsValuesList = state =>
  state[NAME].dimensionsValuesList;

export const getConsolidatedPredefined = createSelector(
  getPeriodDetail,
  periodDetail => {
    return {
      budget: periodDetail.conso_budget_predef
        ? `${PROJECTION.BUDGET}-${periodDetail.conso_budget_predef.id}`
        : null,
      last_forecast: periodDetail.conso_forecast_predef
        ? `${PROJECTION.LAST_FORECAST}-${periodDetail.conso_forecast_predef.id}`
        : null,
    };
  }
);

export const getActiveOptionPeriodTable = createSelector(
  getPeriodDetail,
  periodDetail => {
    return periodDetail.conso_budget_predef
      ? {
          value: PROJECTION.BUDGET,
          id: periodDetail.conso_budget_predef.id,
        }
      : {
          value: PROJECTION.REAL,
          id: null,
        };
  }
);

//multimoneda
export const getExchangeRateListDefault = state => state[NAME].exchangeRateList;

export const getExchangeRateList = createSelector(
  getExchangeRateListDefault,
  exchangeRateList => {
    return exchangeRateList.map(exchangeRate => {
      return {
        ...exchangeRate,
        areVisibleButtonsActions: !_.isEqual(
          exchangeRate.originAmounts,
          exchangeRate.exr_by_month
        ),
      };
    });
  }
);

export const getMonthsList = state => state[NAME].monthsList;
export const getCurrenciesTracks = state => state[NAME].currenciesTracks;
export const getDashboardUrl = state => state[NAME].dashboardUrl;
export const getDashboardDatasources = state =>
  state[NAME].dashboardDatasources;
export const getPXQ = state => state[NAME].pxq;
