import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  List,
  Card,
  Row,
  Col,
  Tooltip,
  Switch,
  Spin,
  Popconfirm,
  notification,
  Button,
  Modal,
  Space,
} from 'antd';
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons';
import { EmptyScreen, ButtonActionBar } from 'modules/core/components';
import { isUserAllowed } from 'modules/core/utils';
import { PROJECTION, ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import moment from 'moment';
import { CreateCurrencyModal } from './components';

const {
  SETTINGS__PROJECTION_ELEMENTS__CURRENCY__ACTIVATE,
  SETTINGS__PROJECTION_ELEMENTS__CURRENCY__CREATE,
} = ROLES;

const CurrencyList = ({
  currencyList,
  fetchCurrencyList,
  setCurrencyAction,
  predefineCurrency,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [currencySwitch, setCurrencySwitch] = useState({ active: false });
  const { t } = useTranslation();

  const userCanActivate = isUserAllowed(
    SETTINGS__PROJECTION_ELEMENTS__CURRENCY__ACTIVATE
  );

  useEffect(() => {
    setIsScreenLoading(true);
    fetchCurrencyList().then(() => {
      setIsScreenLoading(false);
    });
  }, [fetchCurrencyList]);

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_CURRENCY_EMPTY_SCREEN_TITLE')}
      description={t('CONFIG_CURRENCY_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <CreateCurrencyModal
          buttonName={t('CONFIG_CURRENCY_CREATE_ACTION')}
          rolesAvailables={[SETTINGS__PROJECTION_ELEMENTS__CURRENCY__CREATE]}
        />
      }
    />
  );

  /*---- filtro de monedas -----*/

  //functional currency
  const functionalC = currencyList.data.filter(
    currency => currency.value_type === PROJECTION.FUNCTIONAL_KEY
  );

  //origin currency
  const originC = currencyList.data.filter(
    currency => currency.value_type === PROJECTION.ORIGIN_KEY
  );

  /*NOTE: este filtrado lo hago para poder deshabilitar el boton de fijado*/
  const fixedCurrency =
    currencyList.data.filter(currency => currency.predefined === true).length >
    0;

  const handleActivateCurrency = id => {
    setCurrencyAction(id, { active: true })
      .then(() => {
        fetchCurrencyList();
        setCurrencySwitch({ active: false });
        notification.success({
          message: t('FEEDBACK_WELLDONE'),
          description: t('CONFIG_CURRENCY_FEEDBACK_SUCCESS', {
            action: t('CONFIG_CURRENCY_ACTION_ACTIVATED').toLowerCase(),
          }),
        });
      })
      .catch(() => {
        setCurrencySwitch({ active: false });
        notification.error({
          message: t('CONFIG_CURRENCY_FEEDBACK_ERROR'),
          description: t('CONFIG_CURRENCY_FEEDBACK_FAIL', {
            action: t('CONFIG_CURRENCY_ACTION_ACTIVATE').toLowerCase(),
          }),
          duration: 0,
        });
      });
  };

  const handleDeactivateCurrency = id => {
    setCurrencyAction(id, { active: false })
      .then(() => {
        fetchCurrencyList();
        setCurrencySwitch({ active: false });
        notification.success({
          message: t('FEEDBACK_WELLDONE'),
          description: t('CONFIG_CURRENCY_FEEDBACK_SUCCESS', {
            action: t('CONFIG_CURRENCY_ACTION_DEACTIVATED').toLowerCase(),
          }),
        });
      })
      .catch(() => {
        setCurrencySwitch({ active: false });
        notification.error({
          message: t('CONFIG_CURRENCY_FEEDBACK_ERROR'),
          description: t('CONFIG_CURRENCY_FEEDBACK_FAIL', {
            action: t('CONFIG_CURRENCY_ACTION_DEACTIVATE').toLowerCase(),
          }),
          duration: 0,
        });
      });
  };

  const handleFixCurrency = id => {
    predefineCurrency(id)
      .then(() => {
        fetchCurrencyList();
        notification.success({
          message: t('FEEDBACK_WELLDONE'),
          description: t('CONFIG_CURRENCY_FEEDBACK_SUCCESS', {
            action: t('CONFIG_CURRENCY_ACTION_FIXED').toLowerCase(),
          }),
        });
      })
      .catch(() => {
        notification.error({
          message: t('CONFIG_CURRENCY_FEEDBACK_ERROR'),
          description: t('CONFIG_CURRENCY_FEEDBACK_FAIL', {
            action: t('CONFIG_CURRENCY_POPCONFIRM_OKTEXT').toLowerCase(),
          }),
          duration: 0,
        });
      });
  };

  const renderModalConfirm = (id, code) =>
    Modal.confirm({
      title: t('FIELD_ATENTION'),
      centered: true,
      content: (
        <Space direction="vertical">
          <span>
            {t('CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_1', {
              code,
            })}
          </span>
          <span>
            {t('CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_2')}
          </span>
        </Space>
      ),
      okText: t('ACTION_FIX'),
      onOk: () => handleFixCurrency(id),
      cancelText: t('ACTION_CANCEL'),
    });

  const renderCards = () => (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <List
            header={t('CONFIG_CURRENCY_HEADER_ORIGIN')}
            grid={{ gutter: 16, column: 5 }}
            dataSource={originC}
            renderItem={item => (
              <List.Item>
                <Card
                  title={item.code}
                  extra={
                    <Popconfirm
                      placement="bottomRight"
                      title={t(
                        'CONFIG_CURRENCY_DEACTIVATE_CURRENCY_POPCONFIRM_TEXT',
                        {
                          action: currencySwitch.active
                            ? t('CONFIG_CURRENCY_ACTION_ACTIVATE').toLowerCase()
                            : t(
                                'CONFIG_CURRENCY_ACTION_DEACTIVATE'
                              ).toLowerCase(),
                        }
                      )}
                      okText={
                        currencySwitch.active
                          ? t('CONFIG_CURRENCY_ACTION_ACTIVATE')
                          : t('CONFIG_CURRENCY_ACTION_DEACTIVATE')
                      }
                      onConfirm={
                        item.active
                          ? () => handleDeactivateCurrency(item.id)
                          : () => handleActivateCurrency(item.id)
                      }
                      cancelText={t('ACTION_CANCEL')}
                    >
                      <Tooltip
                        title={
                          !item.active
                            ? t('CONFIG_CURRENCY_ACTIVATE')
                            : t('CONFIG_CURRENCY_DEACTIVATE')
                        }
                      >
                        <Switch
                          checked={item.active ? true : false}
                          onChange={state =>
                            setCurrencySwitch({ active: state })
                          }
                          disabled={!userCanActivate}
                        />
                      </Tooltip>
                    </Popconfirm>
                  }
                >
                  <p> {t('FIELD_LAST_UPDATED')} </p>
                  <p>
                    {`${moment(item.last_updated).format('DD/MM/YYYY')} ${t(
                      'DATE_AT_TIME'
                    )} ${moment(item.last_updated).format('HH:mm')} `}
                  </p>
                </Card>
              </List.Item>
            )}
          />
        </Col>
        <Col span={24}>
          <List
            header={t('CONFIG_CURRENCY_HEADER_FUNCTIONAL')}
            grid={{ gutter: 24 }}
            dataSource={functionalC}
            renderItem={item => (
              <List.Item>
                <Card
                  title={item.code}
                  actions={[
                    <ButtonActionBar>
                      <Button
                        icon={
                          <Tooltip title={t('CONFIG_CURRENCY_FIXBUTTON_TITLE')}>
                            {!item.predefined ? (
                              <PushpinOutlined />
                            ) : (
                              <PushpinFilled />
                            )}
                          </Tooltip>
                        }
                        type="text"
                        shape="circle"
                        disabled={fixedCurrency || !userCanActivate}
                        onClick={() => renderModalConfirm(item.id, item.code)}
                      />
                    </ButtonActionBar>,
                  ]}
                  style={{ width: '175px' }}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <CreateCurrencyModal
        buttonName={t('ACTION_CREATE_NEW')}
        rolesAvailables={[SETTINGS__PROJECTION_ELEMENTS__CURRENCY__CREATE]}
      />
    </ButtonActionBar>
  );

  return (
    <Spin spinning={isScreenLoading} size="large">
      {!isScreenLoading && (
        <>
          {currencyList.data.length <= 0 && renderEmptyScreen()}
          {currencyList.data.length > 0 && (
            <Row gutter={[24, 24]}>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>{renderCards()}</Col>
            </Row>
          )}
        </>
      )}
    </Spin>
  );
};

const mapStateToProps = state => ({
  currencyList: configuration.selectors.getCurrencyList(state),
});

const mapDispatchToProps = {
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  setCurrencyAction: configuration.actions.setCurrencyAction,
  predefineCurrency: configuration.actions.predefineCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyList);
