import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Spin, Button, Row, Col, Space, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { SiderLayout } from 'modules/core/layouts';
import {
  ButtonActionBar,
  PeriodNavigator,
  ConfirmationModal,
  Typography,
} from 'modules/core/components';
import { AdjustmentDetailInfo, AdjustmentDetailTable } from './components';
import { useQuery, useFunctionalCurrency } from 'modules/core/customHooks';
import { dateMMMMYYYY, isUserAllowed, renderPrefix } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import login from 'modules/login';
import planning from 'modules/planning';
import adjustments from 'modules/adjustments';
import { useTranslation } from 'react-i18next';

import './AdjustmentDetail.scss';

const { CONTROL__ADJUSTMENTS__CREATE, CONTROL__ADJUSTMENTS__DELETE } = ROLES;

const AdjustmentDetail = ({
  loggedUser,
  fetchPeriodDetail,
  periodDetail,
  fetchAdjustmentDetailTable,
  fetchColumnsAdjustmentDetail,
  adjustmentColumns,
  adjustmentDetail: { original, reversion },
  downloadSingleAdjustmentsXLS,
  cancelAdjustment,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCancelingAdjustment, setIsCancelingAdjustment] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  let { periodId, adjustmentId } = useParams();
  let history = useHistory();
  let query = useQuery();
  const { t } = useTranslation();

  useEffect(() => {
    setIsScreenLoading(true);
    fetchPeriodDetail(periodId).then(() => setIsScreenLoading(false));
  }, [fetchPeriodDetail, periodId]);

  useEffect(() => {
    Promise.all([
      fetchAdjustmentDetailTable(adjustmentId),
      fetchColumnsAdjustmentDetail(adjustmentId),
    ]).then(() => setIsTableLoading(false));
  }, [fetchAdjustmentDetailTable, fetchColumnsAdjustmentDetail, adjustmentId]);

  const handleConfirmationModal = anulationComment => {
    setIsCancelingAdjustment(true);
    cancelAdjustment(adjustmentId, { comment: anulationComment })
      .then(() => {
        notification.success({
          message: t('ADJUSTMENT_CANCEL_CONFIRM_SUCCESS_FEEDBACK_TITLE'),
          description: t('ADJUSTMENT_SUCCESS_FEEDBACK_DESCRIPTION'),
        });
        fetchAdjustmentDetailTable(adjustmentId).then(() => {
          setIsCancelingAdjustment(true);
          setShowCancelModal(false);
        });
      })
      .catch(() => {
        setIsCancelingAdjustment(false);
        setShowCancelModal(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t(
            'ADJUSTMENT_CANCEL_CONFIRM_FAIL_FEEDBACK_DESCRIPTION',
            { userName: loggedUser.firstName }
          ),
        });
      });
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_CONTROL'),
          url: `/seguimiento/${periodId}`,
        },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/seguimiento/${periodId}/economico?tab=ajustes`,
        },
        {
          name: _.isEmpty(reversion)
            ? t('ADJUSTMENT_DETAIL_TITLE')
            : t('ADJUSTMENT_CANCELED_DETAIL_TITLE'),
        },
      ]}
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <Button
        type="text"
        shape="circle"
        title={t('ADJUSTMENT_DOWNLOAD_MOVEMENT_ACTION_TITLE')}
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={() =>
          downloadSingleAdjustmentsXLS(
            adjustmentId,
            t('ADJUSTMENT_DATA_TYPE_FILE_NAME', {
              type: original.type,
              periodName: periodDetail.name,
            })
          )
        }
      />
    </ButtonActionBar>
  );

  const renderCreatedBottomActions = () => (
    <>
      {isUserAllowed(CONTROL__ADJUSTMENTS__CREATE) && (
        <Button
          onClick={() =>
            history.push(`/seguimiento/${periodId}/economico/ajustes`)
          }
        >
          {t('ADJUSTMENT_NEW_CREATE_ACTION')}
        </Button>
      )}
      <Button
        type="primary"
        onClick={() =>
          history.push(`/seguimiento/${periodId}/economico?tab=ajustes`)
        }
      >
        {t('ACTION_FINISH')}
      </Button>
    </>
  );

  const renderDefaultBottomActions = () => (
    <>
      <Button
        type={!_.isEmpty(reversion) ? 'primary' : 'default'}
        onClick={() =>
          history.push(`/seguimiento/${periodId}/economico?tab=ajustes`)
        }
      >
        {!_.isEmpty(reversion) ? t('ACTION_ACEPT') : t('ACTION_RETURN')}
      </Button>
      {_.isEmpty(reversion) && isUserAllowed(CONTROL__ADJUSTMENTS__DELETE) && (
        <Button onClick={() => setShowCancelModal(true)} type="primary">
          {t('ACTION_ANULATE')}
        </Button>
      )}
    </>
  );

  return (
    <SiderLayout>
      <Spin size="large" spinning={isScreenLoading} />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <div className="adjustment-detail-container">
            <Row gutter={[8, 24]}>
              {!_.isEmpty(original) && (
                <>
                  <Col span={20}>
                    <AdjustmentDetailInfo
                      date={
                        !_.isEmpty(reversion) ? reversion.date : original.date
                      }
                      type={original.type}
                      comment={{
                        original: original.comment,
                        canceled: !_.isEmpty(reversion)
                          ? reversion.comment
                          : '',
                      }}
                    />
                  </Col>
                  <Col span={4}>{renderTopActions()}</Col>
                  <Col span={24}>
                    <AdjustmentDetailTable
                      title={t('FIELD_ORIGINAL')}
                      isLoading={isTableLoading}
                      dataSource={original.rows}
                      columns={adjustmentColumns}
                      prefix={renderPrefix(functionalCurrencyData)}
                    />
                  </Col>
                </>
              )}
              {!_.isEmpty(reversion) && (
                <Col span={24}>
                  <AdjustmentDetailTable
                    title={t('FIELD_CANCELLATION')}
                    isLoading={isTableLoading}
                    dataSource={reversion.rows}
                    columns={adjustmentColumns}
                    prefix={renderPrefix(functionalCurrencyData)}
                  />
                </Col>
              )}
            </Row>
            {!isTableLoading && (
              <ButtonActionBar position="center">
                <Space size="middle">
                  {query.get('created') === 'true'
                    ? renderCreatedBottomActions()
                    : renderDefaultBottomActions()}
                </Space>
              </ButtonActionBar>
            )}
          </div>
        </>
      )}
      {!isScreenLoading && !_.isEmpty(original) && (
        <ConfirmationModal
          title={t('ADJUSTMENT_CANCEL_CONFIRM_TITLE')}
          bodyText={t('ADJUSTMENT_CANCEL_CONFIRM_TEXT', {
            userName: loggedUser.firstName,
            añoMes: dateMMMMYYYY(original.date, 'YYYY-MM-DD'),
          })}
          okText={t('ACTION_ANULATE')}
          visible={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={handleConfirmationModal}
          loading={isCancelingAdjustment}
          canceled={_.isEmpty(reversion)}
        />
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
  adjustmentDetail: adjustments.selectors.getAdjustmentDetail(state),
  adjustmentColumns: adjustments.selectors.getAdjustmentColumns(state),
});

const mapDispatchToProps = {
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  downloadSingleAdjustmentsXLS:
    adjustments.actions.downloadSingleAdjustmentsXLS,
  fetchAdjustmentDetailTable: adjustments.actions.fetchAdjustmentDetailTable,
  fetchColumnsAdjustmentDetail:
    adjustments.actions.fetchColumnsAdjustmentDetail,
  cancelAdjustment: adjustments.actions.cancelAdjustment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentDetail);
