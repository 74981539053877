import React from 'react';
import { Typography } from 'modules/core/components';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './DistributionTableHeader.scss';

const DistributionTableHeader = ({ title = '', disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="distribution-table-header">
      <Typography.Subtitle level={2} type={disabled ? 'disable' : 'primary'}>
        {title}
      </Typography.Subtitle>
      {title === t('DISTRIBUTION_TO_FIELD') && (
        <Tooltip
          title={!disabled ? t('DISTRIBUTION_PERCENTAGE_INFO_TOOLTIP') : ''}
          placement="topRight"
        >
          <Typography.Icon
            icon={InfoCircleOutlined}
            type={disabled ? 'disable' : 'primary'}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default DistributionTableHeader;
