import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
// import { Typography } from 'modules/core/components';

import './ConfigurationEmptyAlert.scss';

const ConfigurationEmptyAlert = ({ onDownloadTemplate }) => {
  const { t } = useTranslation();
  return (
    <Alert
      showIcon
      closable
      type="info"
      message={t('UPLOAD_FILE_TITLE')}
      description={
        <div>
          {t('INFO_UPLOAD_FILE_BODY_1')}
          <div>
            {t('INFO_UPLOAD_FILE_BODY_2')}{' '}
            <span
              className="configuration-empty-alert-download-tempate"
              onClick={onDownloadTemplate}
            >
              {t('ACTION_DOWNLOAD_TEMPLATE')}
            </span>
          </div>
        </div>
      }
    />
  );
};

export default ConfigurationEmptyAlert;
