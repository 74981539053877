import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Divider, Space } from 'antd';
import { generateSelectOptions, orderBySelection } from 'modules/core/utils';
import _ from 'lodash';

const FilterSelect = ({
  label,
  dimensionId,
  onChangeSelection,
  selection,
  options,
}) => {
  const [valuesItems, setValuesItems] = useState(
    !_.isEmpty(selection) ? selection[`${dimensionId}`] : []
  );

  const { t } = useTranslation();

  const onDeleteAllItems = idDimension => {
    setValuesItems([]);
    onChangeSelection([], idDimension);
  };

  const onDeselectItem = (idItem, idDimension) => {
    const auxValues = _.remove(valuesItems, function (id) {
      return id !== idItem;
    });
    setValuesItems(auxValues);
    onChangeSelection(auxValues, idDimension);
  };

  return (
    <Select
      value={valuesItems}
      key={dimensionId}
      mode="multiple"
      maxTagCount={1}
      showSearch
      optionFilterProp="children"
      style={{ width: 180 }}
      placeholder={label}
      maxTagTextLength={10}
      onChange={idItem => setValuesItems(idItem)}
      onDeselect={idItem => onDeselectItem(idItem, dimensionId)}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      dropdownRender={menu => (
        <>
          {menu}
          <Divider />
          <Space>
            <Button type="link" onClick={() => onDeleteAllItems(dimensionId)}>
              {t('ACTION_CLEAR')}
            </Button>
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={() => {
                onChangeSelection(valuesItems, dimensionId);
                menu.props.onToggleOpen();
              }}
            >
              {t('ACTION_APPLY')}
            </Button>
          </Space>
        </>
      )}
      options={generateSelectOptions({
        options: orderBySelection(valuesItems, options),
      })}
    />
  );
};

export default FilterSelect;
