import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  notification,
  Select,
} from 'antd';
import { AddAndDeleteFormItem, SelectType } from 'modules/core/components';
import {
  generateSelectConceptOptions,
  generateSelectOptions,
  userCanAcces,
} from 'modules/core/utils';
import { ACCES_USERS, CONCEPTS } from 'modules/core/constants';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import configuration from 'modules/configuration';
import planning from 'modules/planning';
import login from 'modules/login';
import _ from 'lodash';

const ConfigurationCreateModal = ({
  visible,
  onCancel,
  createDimension,
  onFetchDimensionList,
  fetchConceptsList,
  conceptsList: { values },
  loggedUser: { email },
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    fetchConceptsList();
  }, [fetchConceptsList]);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  //NOTE:REVISAR SOLICION
  const onPrepareConceptData = selectedConcepts => {
    let selectedAllIds = [];
    const selectedIds = _.compact([
      ...selectedConcepts.map(conceptData => {
        if (!(conceptData.length > 1)) {
          selectedAllIds = [
            ...selectedAllIds,
            ...values
              .filter(concept => concept.type === conceptData[0])
              .map(concept => concept.id),
          ];
        }
        return conceptData[1];
      }),
    ]);
    return [...selectedAllIds, ...selectedIds];
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoadingModal(true);
        const data = values.dimensions.map(infoDimension => {
          return {
            ...infoDimension,
            concept_ids: userCanAcces({
              patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
              email,
            })
              ? onPrepareConceptData(infoDimension.concept_ids)
              : infoDimension.concept_ids.map(id => parseInt(id)),
          };
        });
        createDimension(data)
          .then(() =>
            onFetchDimensionList().then(() => {
              message.success(t('FEEDBACK_CREATE_SUCCESS'));
              setIsLoadingModal(false);
              onClose();
            })
          )
          .catch(() => {
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: t('FEEDBACK_CREATION_FAIL'),
              duration: 0,
            });
            setIsLoadingModal(false);
            onClose();
          });
      })
      .catch(e => {});
  };

  const renderCascaderSelect = () => (
    <SelectType.Cascader options={generateSelectConceptOptions({ values })} />
  );

  const renderNormalSelect = () => (
    <Select
      placeholder={t('ACTION_SELECT')}
      mode="multiple"
      maxTagCount={1}
      showArrow
      options={generateSelectOptions({
        options: values.filter(val => val.type === CONCEPTS.TYPE.ECONOMIC_KEY),
      })}
    />
  );

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_EMPTY_STATE_CREATE_ACTION')}
      visible={visible}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={onConfirm}
      onCancel={onClose}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical" initialValues={{ dimensions: [''] }}>
        <Form.List name="dimensions">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => {
                  return (
                    <Row gutter={24} key={field.key} type="flex" justify="end">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'name']}
                          label={[field.label, t('FIELD_NAME')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_DIMENSIONS_INPUT_NAME_PLACEHOLDER'
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'concept_ids']}
                          label={[
                            field.label,
                            t('FIELD_ASSOCIATED_CONCEPT_CONCEPTS'),
                          ]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          {/* NOTE: PROVISORIO HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS */}
                          {userCanAcces({
                            patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
                            email,
                          })
                            ? renderCascaderSelect()
                            : renderNormalSelect()}
                        </Form.Item>
                      </Col>
                      <AddAndDeleteFormItem
                        fieldsLength={fields.length}
                        index={index}
                        disabled={isLoadingModal}
                        addData={{
                          onClick: () => add(),
                          buttonName: 'CONFIG_DIMENSIONS_ADD_NEW_ACTION',
                        }}
                        deleteData={{
                          onClick: () => remove(field.name),
                          buttonName: 'CONFIG_DIMENSIONS_DELETE_ACTION',
                        }}
                      />
                    </Row>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  conceptsList: planning.selectors.getConceptsList(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  createDimension: configuration.actions.createDimension,
  fetchConceptsList: planning.actions.fetchConceptsList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationCreateModal);
