import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Table, Empty, Space, Spin } from 'antd';
import {
  CellStyle,
  ButtonActionBar,
  TableSettingsModal,
  References,
} from 'modules/core/components';
import {
  dateMMYYYY,
  calculateScrollX,
  generateSelectOptions,
  columnPadding,
} from 'modules/core/utils';
import { DollarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import reports from 'modules/reports';
import login from 'modules/login';
import { useParams } from 'react-router-dom';
import {
  REPORT,
  ROWS,
  TABLES,
  COLUMNS,
  ACCES_USERS,
} from 'modules/core/constants';
import _ from 'lodash';

const ReportRuleDetail = ({
  fetchReportRuleData,
  reportRuleData: { columns, rows },
  consolidationListBudget,
  consoPredefinedId,
  functionalCurrencyData,
  loggedUser: { email },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [consoId, setConsoId] = useState(consoPredefinedId);
  const [tableSettings, setTableSettings] = useState(
    TABLES.DEFAULT_SETTINGS(email)
  );
  const { t } = useTranslation();
  let { periodId, reportId } = useParams();

  useEffect(() => {
    setIsDataLoading(true);
    fetchReportRuleData(reportId, { consolidated_id: consoId }).then(() => {
      setIsDataLoading(false);
      setIsScreenLoading(false);
    });
  }, [fetchReportRuleData, reportId, periodId, consoPredefinedId, consoId]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const renderColumnsNumbers = ({ value, type }) => {
    if (value) {
      return (
        <CellStyle.Number
          value={value}
          format={type === ROWS.RATIO ? '%' : tableSettings.format}
          showNegativeInRed={tableSettings.showNegativeInRed}
          type={type}
          has_restriction={has_restriction}
        />
      );
    } else {
      return '-';
    }
  };

  const generateColumns = columns => {
    return [
      ...columns.map((column, index) => {
        return {
          key: index,
          ...(!column.is_transaction && {
            title: column.title,
            dataIndex: column.data_index,
            ...(column.title.toUpperCase() === COLUMNS.TOTAL_DATA_INDEX
              ? {
                  className: COLUMNS.TYPES.TOTALIZER,
                  width: COLUMNS.WIDTH.TRANSACTION,
                  align: 'right',
                }
              : {
                  width: COLUMNS.WIDTH.DEFAULT,
                }),
            render: (value, record) => {
              if (typeof value === 'string') {
                return columnPadding(value, record.level, record.type);
              }
              return renderColumnsNumbers({ value, type: record.type });
            },
          }),
          ...(column.is_transaction && {
            title: dateMMYYYY(column.title, 'YYYY-MM'),
            children: column.children.map(chil => {
              return {
                title: chil.title,
                width: COLUMNS.WIDTH.REPORT_TRANSACTION,
                ...(chil.title !== REPORT.REPORTS_KEYS.AMOUNT
                  ? {
                      children: chil.children.map(chil => {
                        return {
                          title: chil.title,
                          dataIndex: chil.data_index,
                          width: COLUMNS.WIDTH.REPORT_TRANSACTION,
                          render: (value, record) => {
                            const auxObjectKeys = _.keys(record);
                            if (auxObjectKeys.includes(column.title)) {
                              if (record[column.title]) {
                                return (
                                  typeof record[column.title] === 'object' &&
                                  renderColumnsNumbers({
                                    value:
                                      record[column.title][chil.data_index],
                                    type: record.type,
                                  })
                                );
                              } else {
                                return '-';
                              }
                            }
                            return '-';
                          },
                        };
                      }),
                    }
                  : {
                      dataIndex: chil.data_index,
                      render: (value, record) => {
                        const valueAux =
                          value && value.hasOwnProperty('amount')
                            ? value.amount
                            : value;
                        return renderColumnsNumbers({
                          value: valueAux,
                          type: record.type,
                        });
                      },
                    }),
              };
            }),
          }),
        };
      }),
    ];
  };

  const renderTableActions = () => (
    <ButtonActionBar position="space-between">
      <References
        referencesItems={[
          {
            color: '#a73ba1',
            type: 'Ab',
            title: t('LABEL_RATIO'),
            description: t('REPORT_VALUES_RATIO'),
          },
          ...(functionalCurrencyData
            ? [
                {
                  type: <DollarOutlined />,
                  title: functionalCurrencyData && functionalCurrencyData.code,
                  description:
                    functionalCurrencyData &&
                    t('REFERENCE_DESCRIPTION', {
                      currencyName: t(
                        functionalCurrencyData.name
                      ).toLowerCase(),
                    }),
                },
              ]
            : []),
        ]}
      />
      <Space direction="horizontal">
        <TableSettingsModal
          onConfirm={setTableSettings}
          settings={tableSettings}
        />
      </Space>
    </ButtonActionBar>
  );

  return (
    <>
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && (columns.length === 0 || rows.length === 0) && (
        <Empty description={t('REPORT_RDN_DETAIL_EMPTY_SCREEN_DESCRIPTION')} />
      )}
      {!isScreenLoading && columns.length > 0 && rows.length > 0 && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Select
                className="report-detail-table-actions-select"
                defaultValue={consoPredefinedId}
                placeholder={t('ACTION_SELECT')}
                onChange={value => setConsoId(value)}
                options={generateSelectOptions({
                  options: consolidationListBudget.map(conso => {
                    return { id: conso.id, name: conso.name };
                  }),
                })}
              />
            </Col>
            <Col span={24}>{renderTableActions()}</Col>
          </Row>
          <Table
            size="small"
            columns={generateColumns(columns)}
            rowClassName={record => ROWS.TYPES[record.type]}
            dataSource={rows}
            pagination={false}
            loading={isDataLoading}
            scroll={{
              x: calculateScrollX(columns),
              y: 445,
            }}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  reportRuleData: reports.selectors.getReportRuleData(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchReportRuleData: reports.actions.fetchReportRuleData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportRuleDetail);
