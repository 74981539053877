const configuration = {
  //xls names
  CONFIG_USERS_TEMPLATE: 'Plika Template - Settings Users.xlsx',
  CONFIG_CONCEPTS_TEMPLATE: 'Plika Template - Settings Concepts.xlsx',
  CONFIG_ECONOMIC_REPORT_TEMPLATE:
    'Plika Template - Settings economic report.xlsx',
  CONFIG_FINANCIAL_REPORT_TEMPLATE:
    'Plika Template - Settings financial report.xlsx',
  CONFIG_VALUES_TEMPLATE_NAME:
    'Plika Template - Settings {{name}} (values).xlsx',
  CONFIG_VALUES_FILE_NAME: 'Plika - Settings {{name}} (values).xls',
  CONFIG_ACCOUNTS_TEMPLATE_NAME:
    'Plika Template - Settings Chart of accounts.xlsx',
  CONFIG_DIMENSIONS_TEMPLATE_NAME: 'Plika Template - Settings Dimensions',
  CONFIG_DIMENSIONS_VALUES_TEMPLATE_NAME_DEFAULT:
    'Plika Template-  Settings Dimensions(values).xlsx',
  CONFIG_ACCOUNTS_STRUCTURE_FILE_NAME:
    'Plika - Settings Chart of accounts (structure).xlsx',
  CONFIG_STRUCTURE_FILE_NAME: 'Plika - Settings {{name}} (structure).xlsx',
  CONFIG_DIMENSIONS_VALUES_FILE_NAME:
    'Plika - Settings dimension (values).xlsx',
  CONFIG_USERS_FILE_NAME: 'Plika - Settings Users (listing).xlsx',
  CONFIG_ACCOUNT_CONCEPT_ASSOCIATION_TEMPLATE_NAME:
    'Plika Template - Account-Concept Association',
  CONFIG_CONCEPTS_FILE_NAME:
    'Plika - Settings Concepts {{conceptType}} ({{conceptName}}).xlsx',

  // General
  CONFIG_CARD_EMPTY_STATE_TEXT:
    'Enter and start creating or uploading {{config}}',
  CONFIG_DISABLED_ACCES_NO_ACCOUNTS_TOOLTIP:
    'To access first load your chart of accounts.',
  CONFIG_SETTINGS_MODAL_TITLE: 'Preferences',
  CONFIG_SETTINGS_MODAL_TEXT:
    'How do you want your team to view the {{dimension}} dimension?',
  CONFIG_SETTINGS_MODAL_ALERT:
    'Warning! What you define here as a preference will impact the display of the entire product.',
  CONFIG_SETTINGS_OPTION_BOTH: 'By code and description.',
  CONFIG_SETTINGS_OPTION_CODE: 'By code only.',
  CONFIG_SETTINGS_OPTION_NAME: 'By description only',

  //Conceptos
  CONFIG_CONCEPTS_EMPTY_STATE_TITLE: 'The loading of concepts begins',
  CONFIG_CONCEPTS_UPLOAD_MODAL_TITLE: 'Upload concepts',
  CONFIG_CONCEPTS_DIMENSIONS_EMPTY_STATE_TITLE:
    'The loading of concept dimensions begins',
  CONFIG_CONCEPTS_EMPTY_DIENSIONS_TITLE:
    'There are no other dimensions to associate yet',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_ACTION: 'Associate dimension',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_MODAL_TITLE:
    'Associate dimension to concept',
  CONFIG_CONCEPT_ASSOCIATE_TO_DIMENSION_FIELD: 'Dimension(s) to associate',
  CONFIG_CONCEPT_ASSOCIATE_MODAL_SELECT_LABEL:
    'Select the dimension(s) you want to use to budget',
  CONFIG_CONCEPT_ASSOCIATE_REMOVE_TITLE:
    'Are you sure you want to remove "{{name}}" dimension?',
  CONFIG_FIELD_ECONOMIC_CONCEPTS: 'Economic concepts',
  CONFIG_FIELD_FINANCIAL_CONCEPTS: 'Financial concepts',

  //Dimensiones
  CONFIG_DIMENSIONS_EMPTY_STATE_TITLE: 'The loading of dimension values begins',
  CONFIG_DIMENSION_VALUES_UPLOAD_MODAL_TITLE: 'Upload dimension values',
  CONFIG_DIMENSIONS_EMPTY_STATE_CREATE_ACTION: 'Create dimension',
  CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE:
    'The actions you take on the associated dimensions will not impact on the periods prior to the current one',
  CONFIG_DIMENSIONS_POPCONFIRM_DELETE_DIMENSION_TITLE:
    'Are you sure you want to delete this dimension?',
  CONFIG_DIMENSIONS_INPUT_NAME_PLACEHOLDER: 'For example: Branch',
  CONFIG_DIMENSIONS_DELETE_ACTION: 'Delete dimension',
  CONFIG_DIMENSIONS_ADD_NEW_ACTION: 'Add another dimension',
  CONFIG_DIMENSIONS_VALUES_POPCONFIRM_DELETE_VALUE_TITLE:
    'Are you sure you want to delete this dimension value?',
  CONFIG_DIMENSIONS_VALUES_MODAL_CREATE_NEW_VALUE_TITLE:
    'Create dimension value',
  CONFIG_DIMENSIONS_VALUES_INPUT_CODE_PLACEHOLDER: 'For example: NY',
  CONFIG_DIMENSIONS_VALUES_INPUT_NAME_PLACEHOLDER: 'For example: New York',
  CONFIG_DIMENSIONS_VALUES_DELETE_ACTION: 'Remove dimension value',
  CONFIG_DIMENSIONS_VALUES_ADD_NEW_ACTION: 'Add another dimension value',
  CONFIG_DIMENSIONS_VALUES_EMPTY_STATE_TITLE: 'There are not loaded values!',
  CONFIG_DIMENSIONS_NOT_ALL_EDIT_SUCCESS_FEEDBACK:
    'Not all values are properly edited',

  //Plan de cuentas
  CONFIG_ACCOUNTS_EMPTY_STATE_TITLE:
    'The loading of chart of accounts values begins',
  CONFIG_ACCOUNTS_UPLOAD_MODAL_TITLE: 'Upload chart of accounts',
  CONFIG_ACCOUNTS_CHOOSE_OPTION_MODAL_NAME:
    'Lines of work or sub lines of work level', //no se si esta sea la mejor traduccion, pero 'Line of work' es lo que mas se asemeja a 'rubro'
  CONFIG_ACCOUNTS_CREATE_LEVEL_ACTION: 'Create level',
  CONFIG_ACCOUNTS_EMPTY_STATE_DESCRIPTION:
    'We know that the single chart of accounts is essential for the recording of accounting operations. Include all accounts that use your accounting to record operations, classified by nature.',
  CONFIG_ACCOUNTS_INFO_TOOLTIP_TITLE:
    'The actions you take on the levels will not impact on the periods prior to the current one',
  CONFIG_ACCOUNTS_CONFIRM_DELETE_LEVEL_TITLE:
    'Are you sure you want to delete this level?',
  CONFIG_ACCOUNTS_INFORMATIVE_MODAL_TITLE:
    'The load of your chart of accounts is composed of two elements',
  CONFIG_ACCOUNTS_INFORMATIVE_MODAL_ITEM_ONE:
    '1. Levels: Headings and sub-headings of the chart of accounts that will indicate the classification, nature and ordering of the accounts.',
  CONFIG_ACCOUNTS_INFORMATIVE_MODAL_ITEM_TWO:
    '2. Accounts: The accounting accounts that you are going to use for all transactions in Plika, indicating in which level of the above they are grouped.',
  CONFIG_ACCOUNTS_CHOOSE_OPTION_MODAL_TITLE:
    'Choose the element you want to create',
  CONFIG_ACCOUNTS_CODE_INPUT_LEVEL_PLACEHOLDER: 'For example: 1',
  CONFIG_ACCOUNTS_NAME_INPUT_LEVEL_PLACEHOLDEL: 'For example:. Active',
  CONFIG_ACCOUNTS_ADD_NEW_LEVEL_ACTION: 'Add another level',
  CONFIG_ACCOUNTS_DELETE_LEVEL_ACTION: 'Delete level',
  CONFIG_ACCOUNTS_LEVEL_PARENT_LABEL: 'Parent level',
  CONFIG_ACCOUNTS_CREATE_NEW_ACCOUNT: 'Create account',
  CONFIG_ACCOUNTS_CODE_INPUT_ACCOUNT_PLACEHOLDER: 'For example: 1.1.01.01.001',
  CONFIG_ACCOUNTS_NAME_INPUT_ACCOUNT_PLACEHOLDER: 'For example: Cash',
  CONFIG_ACCOUNTS_DELETE_ACCOUNT_ACTION: 'Delete account',
  CONFIG_ACCOUNTS_ADD_NEW_ACCOUNT_ACTION: 'Add another account',
  CONFIG_ACCOUNTS_ASSOCIATION_ALERT_DESCRIPTION:
    'Remember that you must associate the accounts with their respective concepts. For that, download the template that we currently have and upload the file with the changes.',
  CONFIG_ACCOUNTS_EDIT_INFORMATIVE_TOOLTIP_TITLE:
    'You can only access the concept edition if you are on a row of type account.',

  //Reportes
  CONFIG_REPORTS_EMPTY_STATE_TITLE: 'Create your first report!',
  CONFIG_REPORT_DETAIL_EMPTY_STATE_TITLE: 'The loading of reports begins',
  CONFIG_REPORTS_GROUPING_FIELD: 'Grouping lines',
  CONFIG_REPORTS_TOTALIZING_FILED: 'Totalizer lines',
  CONFIG_REPORT_STRUCTURE_FIELD: 'Structure',
  CONFIG_REPORT_DELETE_TITLE: 'Are you sure you want to delete this report?',
  CONFIG_REPORT_UPLOAD_ACTION: 'Load report',
  CONFIG_REPORT_CREATE_ACTION: 'Create report',
  CONFIG_REPORT_CREATE_MODAL_TEXT:
    'It is necessary that you put a name to your report, you can edit the same as many times as you want.',
  CONFIG_REPORT_CREATE_MODAL_NAME_LABEL:
    'Enter a name for the report you want to create',
  CONFIG_REPORT_CREATE_MODAL_NAME_PLACEHOLDER:
    'For example: P&L - Manager report',
  // VER QUE ONDA DESDE ACA
  CONFIG_OTHER_REPORT_CREATE_MODAL_NAME_PLACEHOLDER:
    'For example: Premiuns office',
  CONFIG_OTHER_REPORT_SELECT_REPORT_LABEL: 'Choose a report',
  // CONFIG_OTHER_REPORT_SELECT_REPORT_TOOLTIP:
  // 'Insertar descripción/explicación acá',
  CONFIG_OTHER_REPORT_SELECT_DIMENSION_LABEL:
    'I chose the dimension by which you want to view your report',

  //Usuarios
  CONFIG_USERS_EMPTY_STATE_TITLE: 'The loading of users begins',
  CONFIG_USERS_UPLOAD_MODAL_TITLE: 'Upload users',
  CONFIG_USER_COMPANY_POSITION_FIELD: 'Role in the company',
  CONFIG_USERS_TOOLTIP_INFO_TITLE:
    'The actions you take on users will not impact on the periods prior to the current one',
  CONFIG_USERS_POPCOFIRM_DELETE_USER_TITLE:
    'Are you sure you want to delete this user?',
  CONFIG_USERS_MODAL_CREATE_USER_TITLE: 'Create user',
  CONFIG_USERS_DELET_USER_ACTION: 'Delete user',
  CONFIG_USERS_ADD_USER_ACTION: 'Add another user',

  //Variables
  CONFIG_VAR_CREATE_MODAL_TITLE: 'Create variable',
  CONFIG_VAR_CREATE_MODAL_NAME_PLACEHOLDER: 'For example:  Inflation',
  CONFIG_VAR_CREATE_MODAL_VALUE_TYPE_PLACEHOLDER: 'For example:  Percentage',
  CONFIG_VAR_CREATE_MODAL_BEHAVIOR_PLACEHOLDER:
    'For example:  data * ( 1 + variable )',
  CONFIG_VAR_CREATE_MODAL_ACCOUNTS_PLACEHOLDER: 'For example:  Account 10001',
  CONFIG_VAR_UPLOAD_MODAL_TITLE: 'Upload variables',
  CONFIG_VAR_DELETE_CONFIRM_TOOLTIP:
    'Are you sure you want to delete the variable {{name}}?',
  CONFIG_VAR_ACCOUNTS_NOT_SELECTED_PLACEHOLDER: 'No accounts selected', //NOTE: Revisar bien cuando este disponible la opcion de editar seleccion de cuenta en el subdrawer
  CONFIG_VAR_DIMENSIONS_INFORMATIVE_ALERT_DESCRIPTION:
    'All changes made will impact on data where the variable is applied.',
  CONFIG_VAR_CREATE_ACTION: 'Create variable',

  // Reglas de negocio
  CONFIG_FORMULA_FIELD_NAME_TABLE: 'Business rule name',
  CONFIG_FORMULAS_EMPTY_SCREEN_TITLE: 'The loading of your business rules',
  CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION:
    'Create your business rules to obtain amounts that you can later use to calculate your budgets',
  CONFIG_FACTOR_EMPTY_SCREEN_TITLE:
    'You still have no loaded factors in this rule!',
  CONFIG_FACTOR_EMPTY_SCREEN_DESCRIPTION:
    'Create your factors behavior for this business rule',
  CONFIG_FACTOR_CREATE_FEEDBACK_SUCCESS:
    'You have created your business rule successfully! And you can view it on the table, creating new rules and new factors.',
  CONFIG_FORMULAS_INFORMATIVE_TOOLTIP: 'You still have no loaded factors',
  CONFIG_FORMULAS_CREATE_ACTION: 'Create business rule',
  CONFIG_FORMULAS_SAVE_ACTION: 'Save rule',
  CONFIG_FORMULAS_CONFIRM_ACTION_POPCONFIRM_TITLE:
    'Do you want to delete the data currently calculated? Or do you prefer to keep it?',
  CONFIG_FACTOR_CONFIRM_ACTION_MODAL_DESCRIPTION:
    'You are about to delete factor {{factorName}}. Keep in mind that this will impact the calculation and its results.',
  CONFIG_FACTOR_CREATE_MODAL_PLACEHOLDER: 'For example: Initial amount',
  CONFIG_FACTOR_ADD_NEW_ACTION: 'Add another factor',
  CONFIG_FACTOR_DELETE_ACTION: 'Remove factor',
  CONFIG_FACTOR_CREATE_ACTION: 'Create factor',
  CONFIG_FACTOR_POPCONFIRM_DELETE_TITLE:
    'Are you sure you want to remove this factor?',
  CONFIG_FACTOR_DELETE_FEEDBACK_SUCCESS: 'The factor was successfully removed',
  CONFIG_FORMULAS_ADD_FACTORS:
    'Create factor(s) for the business rule {{name}}',
  CONFIG_FORMULAS_CREATE_MODAL_PLACEHOLDER: 'For example: Sales revenue',
  CONFIG_FORMULAS_POPCONFIRM_DELETE_TITLE:
    'Are you sure you want to remove this business rule?',
  CONFIG_FORMULAS_DELETE_FEEDBACK_SUCCESS:
    'The business rule was successfully removed',
  CONFIG_FORMULAS_MODAL_INACTIVE_CONFIRM_TEXT:
    'Do you want to deactivate it anyway?',
  CONFIG_FORMULA_NAME: 'Business rule {{name}}',
  CONFIG_FORMULAS_CONFIRM_ACTION_MODAL_DESCRIPTION:
    'This rule has data loaded in it.',
  CONFIG_VAR_DIMENSIONS_NOT_SELECTED_PLACEHOLDER: 'No selected dimensions',
  CONFIG_VAR_EDIT_DIMENSIONS_ACTION: 'Edit values',
  CONFIG_VAR_EDIT_DIMENSIONS_MODAL_TITLE: 'Edit dimension',
  CONFIG_REPLICATE_FACTOR_TITLE: 'Replicate factor',
  CONFIG_REPLICATE_FACTOR_LABEL: 'Destination factor',

  //Roles
  CONFIG_ROLES_EMPTY_STATE_DESCRIPTION:
    'You still do not have roles loaded for your users, start configuring them',
  CONFIG_ROLES_CREATE_ACTION: 'Create role',
  CONFIG_ROLES_DUPLICATE_ACTION: 'Duplicate',
  CONFIG_ROLES_VIEW_TITLE: 'View role',
  CONFIG_ROLES_MODAL_CREATE_ROLE_TITLE: 'Create new role',
  CONFIG_ROLES_MODAL_DUPLICATE_ROLE_TITLE: 'Duplicate role',
  CONFIG_ROLES_DRAWER_ROLE_OPERATIONS_TITLE: 'Role security: {{name}}',
  CONFIG_ROLES_DRAWER_ROLE_DIMENSIONS_TITLE: 'Detail: {{name}}',
  CONFIG_ROLES_POPCONFIRM_DELETE_ROLE_TITLE:
    'Are you sure you want to delete this role?',
  CONFIG_ROLES_REPLY_SUBMODULES: 'Replicate in submodules',
  CONFIG_ROLES_REPLY_SUBMODULES_TOOLTIP:
    'The configuration of dimensions will be replicated in submodules',
  CONFIG_ROLES_ACTION_MODAL_NAME_PLACEHOLDER: 'For example: Sales Manager',
  CONFIG_ROLES_ACTION_MODAL_CODE_PLACEHOLDER: 'For example: SM',
  CONFIG_ROLES_LABEL_COPY: 'copy',

  //onboarding
  CONFIG_ONBOARDING_STEP_DESCRIPTION_SECURITY:
    'Create roles and permissions and then assign them to users you create for your team.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PLAN_ACCOUNT:
    'Create and load information for your account plan, levels for the structure and accounts to be nested in the levels levels.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_CONCEPT:
    'Here you find sales and expenses concepts to organize and group your information.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_DIMENSIONS:
    'Create in a personalized dimensions and its values to classify, order or group information, they will be associated with the concepts.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PROJECTIONS_ELEMENTS:
    'Create variables and business rules to load data, apply calculations and simulate scenarios in the planning module.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_REPORT:
    'Visualize and create your reports. Create the structure of your income statement and weapon even more detailed custom reports.',
  CONFIG_ONBOARDING_SKIP_TOOLTIP_TITLE:
    'If you skip you can take the tour again by clicking on ? help icon -> Take onboarding tour',

  //moneda
  CONFIG_CURRENCY_EMPTY_SCREEN_TITLE:
    'You still do not have currencies created!',
  CONFIG_CURRENCY_EMPTY_SCREEN_DESCRIPTION:
    'Create origin and functional currencies.The origin currencies will be in which you can upload your values in Plika, the funcional currencies in which you will see them reflected.',
  CONFIG_CURRENCY_HEADER_FUNCTIONAL: 'Functional currency',
  CONFIG_CURRENCY_HEADER_ORIGIN: 'Origin currency',
  CONFIG_CURRENCY_ACTIVATE: 'Activate currency',
  CONFIG_CURRENCY_DEACTIVATE: 'Deactivate currency',
  CONFIG_CURRENCY_DEACTIVATE_CURRENCY_POPCONFIRM_TEXT:
    'Are you sure you want to {{action}} this currency?',
  CONFIG_CURRENCY_ACTION_ACTIVATE: 'Activate',
  CONFIG_CURRENCY_ACTION_DEACTIVATE: 'Deactivate',
  CONFIG_CURRENCY_DELETE_ACTION: 'Delete currency',
  CONFIG_CURRENCY_ADD_ACTION: 'Add another currency',
  CONFIG_CURRENCY_CREATE_ACTION: 'Create currency',
  CONFIG_CURRENCY_CREATE_MODAL_TITLE: 'Create new currency',
  CONFIG_CURRENCY_CREATE_FEEDBACK_FAIL_DESCRIPTION:
    'The following codes were not created because there are already: {{currencies}}',
  CONFIG_CURRENCY_FIXBUTTON_TITLE: 'Fix currency',
  CONFIG_CURRENCY_POPCONFIRM_OKTEXT: 'Fix',
  CONFIG_CURRENCY_FEEDBACK_SUCCESS: 'Currency {{action}} successfully',
  CONFIG_CURRENCY_FEEDBACK_FAIL:
    'It was not possible to {{action}} this currency',
  CONFIG_CURRENCY_FEEDBACK_ERROR: 'Error',
  CONFIG_CURRENCY_ACTION_ACTIVATED: 'Activated',
  CONFIG_CURRENCY_ACTION_DEACTIVATED: 'Deactivated',
  CONFIG_CURRENCY_ACTION_FIXED: 'Fixed',
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_1:
    'You are about to fix the currency with code {{code}} as a functional currency of the entire tool definitively. This means that if you do, you will not be able to undo the action.',
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_2:
    'Are you sure you want to fix this currency?',

  //mockData listado monedas
  CONFIG_CURRENCY_USD: 'American dollar',
  CONFIG_CURRENCY_EUR: 'Euro',
  CONFIG_CURRENCY_ARS: 'Argentine Peso',
  CONFIG_CURRENCY_CLP: 'Chilean Peso',
  CONFIG_CURRENCY_COP: 'Colombian Peso',
  CONFIG_CURRENCY_MXN: 'Mexican Peso',
  CONFIG_CURRENCY_UYU: 'Uruguayan Peso',
  CONFIG_CURRENCY_PEN: 'Peruvian Sun',
};

export default configuration;
