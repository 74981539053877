import React from 'react';
import {
  Modal,
  Form,
  Space,
  Alert,
  Row,
  Col,
  Input,
  message,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import reports from 'modules/reports';

const FormItem = Form.Item;

const InitialSituationModal = ({
  showInitialSituationModal,
  setShowInitialSituationModal,
  isSaving,
  setIsSaving,
  periodId,
  reportId,
  setReportId,
  setInitialSituation,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  let history = useHistory();

  const onClose = () => {
    form.resetFields();
    setIsSaving(false);
  };

  const onConfirm = () => {
    form.validateFields().then(values => {
      setIsSaving(true);

      const data = {
        ...(values.real && {
          real_initial_situation: values.real,
        }),
        ...(values.prev_real && {
          prev_real_initial_situation: values.prev_real,
        }),
        ...(values.last_forecast && {
          last_forecast_initial_situation: values.last_forecast,
        }),
      };
      setInitialSituation(reportId, data, { period_id: periodId })
        .then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          onClose();
          history.push(
            `/analisis/${periodId}/reportesplika/financieros/reportes/${reportId}`
          );
        })
        .catch(error => {
          onClose();
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: error ? error : t('FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          });
        });
    });
  };

  return (
    <Modal
      visible={showInitialSituationModal}
      title={t('REPORT_FINANCIAL_INITIAL_SITUATION_MODAL_TITLE')}
      okButtonProps={{ loading: isSaving }}
      forceRender
      onCancel={() => {
        setShowInitialSituationModal(false);
        setReportId(null);
      }}
      okText={t('ACTION_SAVE')}
      onOk={onConfirm}
    >
      <Space direction="vertical" size="middle">
        <Alert
          showIcon
          type="info"
          description={t(
            'REPORT_FINANCIAL_NO_INITIAL_SITUATION_ALERT_DESCRIPTION'
          )}
        />
        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <FormItem
                label={t('LABEL_REAL')}
                name="real"
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input placeholder={t('ENTER_AMOUNT_PLACEHOLDER')} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={t('REAL_LAST_PERIOD')} name="prev_real">
                <Input placeholder={t('ENTER_AMOUNT_PLACEHOLDER')} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label={t('LAST_FORECAST')} name="last_forecast">
                <Input placeholder={t('ENTER_AMOUNT_PLACEHOLDER')} />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Space>
    </Modal>
  );
};

const mapDispatchToProps = {
  setInitialSituation: reports.actions.setInitialSituation,
};

export default connect(null, mapDispatchToProps)(InitialSituationModal);
