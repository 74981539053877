import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Row, Col } from 'antd';
import { Typography, ScreenSuccess } from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { Configuration, Integrations } from './components';
import { INTEGRATIONS } from 'modules/core/constants';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'modules/core/customHooks';
import login from 'modules/login';

const ConfigurationHome = ({ loggedUser: { email } }) => {
  const [activeTab, setActiveTab] = useState(INTEGRATIONS.KEYS.DATA_KEY);
  const [showScreenSuccess, setShowScreenSuccess] = useState(false);
  const [integrationData, setIntegrationData] = useState({
    name: '',
    id: null,
  });

  let query = useQuery();
  const { t } = useTranslation();

  return (
    <SiderLayout>
      {!showScreenSuccess && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Typography.Headline level={5}>
              {t('LABEL_CONFIG')}
            </Typography.Headline>
          </Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey={
                query.get('tab') !== null
                  ? query.get('tab')
                  : INTEGRATIONS.KEYS.DATA_KEY
              }
              size="small"
              onChange={key => setActiveTab(key)}
            >
              <Tabs.TabPane
                tab={t('LABEL_MY_DATA')}
                key={INTEGRATIONS.KEYS.DATA_KEY}
              >
                {(activeTab === INTEGRATIONS.KEYS.DATA_KEY ||
                  query.get('tab') === INTEGRATIONS.KEYS.DATA_KEY) && (
                  <Configuration />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('LABEL_INTEGRATION')}
                key={INTEGRATIONS.KEYS.INTEGRATION_KEY}
              >
                {(activeTab === INTEGRATIONS.KEYS.INTEGRATION_KEY ||
                  query.get('tab') === INTEGRATIONS.KEYS.INTEGRATION_KEY) && (
                  <Integrations
                    setIntegrationData={setIntegrationData}
                    setShowScreenSuccess={setShowScreenSuccess}
                    integrationData={integrationData}
                  />
                )}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      )}
      {showScreenSuccess && (
        <ScreenSuccess
          email={email}
          name={integrationData.name}
          onClose={() => setShowScreenSuccess(false)}
        />
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

export default connect(mapStateToProps, null)(ConfigurationHome);
