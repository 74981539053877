import React from 'react';
import { Button } from 'antd';
import { Typography } from 'modules/core/components';
import { MessageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ShowCommentsButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="text"
      shape="circle"
      icon={<Typography.Icon icon={MessageOutlined} level={1} />}
      onClick={onClick}
      title={t('ACTION_SHOW_COMMENTS')}
    />
  );
};

export default ShowCommentsButton;
