import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DownloadOutlined, DollarOutlined } from '@ant-design/icons';
import { Table, Button, Space } from 'antd';
import {
  TableSettingsModal,
  ButtonActionBar,
  Typography,
  CellStyle,
  References,
} from 'modules/core/components';
import {
  calculateScrollX,
  formItemsHelper,
  columnPadding,
} from 'modules/core/utils';
import {
  ROWS,
  TABLES,
  COLUMNS,
  REPORT,
  ACCES_USERS,
} from 'modules/core/constants';
import reports from 'modules/reports';
import login from 'modules/login';

//TODO: CUANDO SE AJUSTE EL NOMBRE DEL TOTAL AL ESTANDARIZADO, USAR LA CONSTANTE DE ARCHIVO TABLE
const TOTAL_REPORT_KEY = 'Total';

const FORMATS_TYPES = {
  '0,0': 'integer',
  '0,0.00': 'decimals',
  '0[.]0a': 'millions',
};

//TODO: EXPANDABLEROWS
const VerticalSegmentedTable = ({
  fetchReportVerticalSegmentedColumns,
  fetchReportVerticalSegmentedDataRow,
  reportVerticalSegmentedColumns,
  reportVerticalSegmentedDataRow,
  downloadEvolutionVerticalSegmentedXLS,
  activeByVerticalTable,
  loadingDimensionsValues,
  periodId,
  reportId,
  filters,
  selectedDates,
  periodDetail,
  segmentedId,
  reportStructureId,
  functionalCurrencyData,
  loggedUser: { email },
}) => {
  const [tableSettings, setTableSettings] = useState({
    cantItems: 5,
    ...TABLES.DEFAULT_SETTINGS(email),
  });
  const [isTableaLoading, setIsTableLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    const endpointsParams = {
      period_id: periodId,
      dimension: segmentedId,
      report_row: reportStructureId,
      amount: tableSettings.cantItems,
    };
    Promise.all([
      fetchReportVerticalSegmentedColumns(reportId, endpointsParams),
      fetchReportVerticalSegmentedDataRow(reportId, {
        ...endpointsParams,
        ...selectedDates,
        ...filters,
      }),
    ]).then(() => setIsTableLoading(false));
  }, [
    fetchReportVerticalSegmentedDataRow,
    fetchReportVerticalSegmentedColumns,
    reportId,
    periodId,
    segmentedId,
    reportStructureId,
    tableSettings.cantItems,
    selectedDates,
    filters,
  ]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const renderColumnsNumbers = (title, value) => {
    const isAmmout = title => title === REPORT.REPORTS_KEYS.AMOUNT;

    if (isAmmout(title))
      return (
        <CellStyle.Number
          value={value}
          format={tableSettings.format}
          has_restriction={has_restriction}
        />
      );

    return <CellStyle.Number value={value} format="%" />;
  };

  const columnsGenerator = columns =>
    columns && [
      ...columns
        .filter(column =>
          tableSettings.visibleData
            ? !column.is_transaction ||
              tableSettings.visibleData.includes(column.title)
            : true
        )
        .map((column, index) => {
          return {
            key: index,
            ...(!column.is_transaction && {
              ...column,
              dataIndex: column.data_index,
              width: COLUMNS.WIDTH.EXPANDABLE,
              fixed: 'left',
              ...(column.title === TOTAL_REPORT_KEY && {
                title: column.title,
                children: column.children.map(dataChildren => {
                  return {
                    width: COLUMNS.WIDTH.TRANSACTION,
                    className: COLUMNS.TYPES.TOTALIZER,
                    align: 'right',
                    fixed: 'left',
                    dataIndex: dataChildren.data_index,
                    title: dataChildren.title,
                    render: transaction =>
                      renderColumnsNumbers(dataChildren.title, transaction),
                  };
                }),
              }),
              render: (name, row) => columnPadding(name, row.level),
            }),
            ...(column.is_transaction && {
              ...column,
              ellipsis: true,
              title: column.title,
              children: column.children.map(dataChildren => {
                return {
                  width: COLUMNS.WIDTH.TRANSACTION,
                  dataIndex: dataChildren.data_index,
                  title: dataChildren.title,
                  render: transaction =>
                    renderColumnsNumbers(dataChildren.title, transaction),
                };
              }),
            }),
          };
        }),
    ];

  const renderTableActions = () => {
    return (
      <ButtonActionBar position="space-between">
        <References
          referencesItems={[
            ...(functionalCurrencyData
              ? [
                  {
                    type: <DollarOutlined />,
                    title:
                      functionalCurrencyData && functionalCurrencyData.code,
                    description:
                      functionalCurrencyData &&
                      t('REFERENCE_DESCRIPTION', {
                        currencyName: t(
                          functionalCurrencyData.name
                        ).toLowerCase(),
                      }),
                  },
                ]
              : []),
          ]}
        />
        <Space direction="horizontal">
          <Button
            type="text"
            shape="circle"
            title={t('ACTION_DOWNLOAD_TABLE')}
            icon={<Typography.Icon icon={DownloadOutlined} />}
            onClick={() =>
              downloadEvolutionVerticalSegmentedXLS(
                reportId,
                {
                  period_id: periodId,
                  format_number: FORMATS_TYPES[tableSettings.format],
                  dimension: segmentedId,
                  report_row: reportStructureId,
                  ...selectedDates,
                  ...filters,
                },
                t('REPORT_EVOLUTION_VERTICAL_ANALYSIS_SEGMENTED_FILE_NAME', {
                  periodName: periodDetail.name,
                })
              )
            }
          />

          <TableSettingsModal
            onConfirm={data => {
              // NOTA: Al cambiar cantidad de dimensiones visibles,
              // setteamos las seleccionadas en NULL para que se vean todas
              if (data.cantItems !== tableSettings.cantItems)
                setTableSettings({ ...data, visibleData: null });
              else setTableSettings(data);
            }}
            options={
              !_.isEmpty(reportVerticalSegmentedColumns) &&
              reportVerticalSegmentedColumns[
                activeByVerticalTable.value
              ].filter(column => column.is_transaction)
            }
            settings={tableSettings}
            type="verticalSegmented"
            formItems={formItemsHelper.itemsTableView(tableSettings.cantItems)}
          />
        </Space>
      </ButtonActionBar>
    );
  };

  return (
    <>
      {!isTableaLoading && renderTableActions()}
      <Table
        rowKey="id"
        size="small"
        loading={loadingDimensionsValues || isTableaLoading}
        columns={columnsGenerator(
          reportVerticalSegmentedColumns[activeByVerticalTable.value]
        )}
        dataSource={reportVerticalSegmentedDataRow[activeByVerticalTable.value]}
        rowClassName={record => ROWS.TYPES[record.type]}
        expandIconColumnIndex={-1} //TODO: Cuando este el expandir desde back, borrarlo.
        scroll={{
          x: calculateScrollX(
            reportVerticalSegmentedColumns[activeByVerticalTable.value],
            10
          ),
          y: 400,
        }}
        pagination={false}
      />
    </>
  );
};

const mapStateToProps = state => ({
  reportVerticalSegmentedDataRow:
    reports.selectors.getReportVerticalSegmentedDataRow(state),
  reportVerticalSegmentedColumns:
    reports.selectors.getReportVerticalSegmentedColumns(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchReportVerticalSegmentedDataRow:
    reports.actions.fetchReportVerticalSegmentedDataRow,
  fetchReportVerticalSegmentedColumns:
    reports.actions.fetchReportVerticalSegmentedColumns,
  downloadEvolutionVerticalSegmentedXLS:
    reports.actions.downloadEvolutionVerticalSegmentedXLS,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalSegmentedTable);
