import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePagination } from 'modules/core/customHooks';
import { Drawer, Button, Table, Tooltip, message, Input, Space } from 'antd';
import { ButtonActionBar, Typography } from 'modules/core/components';
import { CONCEPTS } from 'modules/core/constants';
import VariableDetailDrawer from './VariableDetailDrawer';
import { ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import configuration from 'modules/configuration';
import planning from 'modules/planning';
import { useTranslation } from 'react-i18next';
import control from 'modules/control';

const { Search } = Input;

const ScenarioSimulationDrawer = ({
  visible,
  onClose,
  applyVariables,
  onApplySuccess,
  variables,
  selected = [],
  budgetId,
  type,
  fetchVariablesList,
  budgetVarSelected = [],
  fetchMonthsToApprove,
  fetchApproveDates,
  monthsToApprove,
  approveDates,
  isForecast,
}) => {
  const [variableDetailId, setVariableDetailId] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedRowIds, setSelectedRowsIds] = useState(
    selected ? [...selected] : []
  );
  const [isSelectionChanged, setIsSelectionChanged] = useState(false); // todo: ver de usar una funcion
  const [searchValue, setSearchValue] = useState(null);
  const [tablePagination, setTablePagination] = usePagination();
  let { periodId } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    fetchVariablesList({
      search: searchValue,
      drawer: true,
      projection_id: budgetId,
    }).then(() => {
      setIsTableLoading(false);
      setSelectedRowsIds([...selected]);
    });
  }, [fetchVariablesList, budgetId, selected, searchValue]);

  useEffect(() => {
    Promise.all([
      fetchMonthsToApprove(periodId, {
        concept_type: CONCEPTS.TYPE.ECONOMIC_KEY,
      }),
      fetchApproveDates(periodId, { concept_type: CONCEPTS.TYPE.ECONOMIC_KEY }),
    ]);
  }, [fetchMonthsToApprove, fetchApproveDates, periodId]);

  const VariablesTable = () => {
    const columns = [
      {
        title: t('LABEL_VARIABLE'),
        dataIndex: 'name',
        width: 250,
      },
      {
        dataIndex: 'has_values',
        render: (has_values, row) =>
          !has_values &&
          !budgetVarSelected.includes(row.id) && (
            <Tooltip title={t('PLANNING_DRAWER_SCENARIO_NO_VARIABLES_VALUES')}>
              <Typography.Icon
                icon={ExclamationCircleOutlined}
                type="warning"
              />
            </Tooltip>
          ),
      },
      {
        dataIndex: 'id',
        width: 60,
        fixed: 'right',
        render: (id, row) => {
          if (!budgetVarSelected.includes(id)) {
            return (
              <Button
                type="text"
                shape="circle"
                title={t('LABEL_DETAIL')}
                icon={<Typography.Icon icon={EyeOutlined} />}
                onClick={() => setVariableDetailId(id)}
              />
            );
          }
        },
      },
    ];
    const rowSelection = {
      selectedRowKeys: [...selectedRowIds, ...budgetVarSelected],
      onChange: selection => {
        setSelectedRowsIds(selection);
        setIsSelectionChanged(true);
      },
      getCheckboxProps: record => ({
        disabled:
          !record.has_values ||
          (type === 'consolidated' && budgetVarSelected.includes(record.id)),
        name: record.name,
      }),
    };
    return (
      <Table
        rowKey="id"
        rowSelection={{
          type: 'Checkbox',
          ...rowSelection,
        }}
        loading={isTableLoading}
        onChange={setTablePagination}
        columns={columns}
        dataSource={variables}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          count: variables.length,
          size: 'small',
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: !searchValue
            ? t('PLANNING_DRAWER_SCENARIO_NO_VARIABLES')
            : t('FEEDBACK_DEFAULT_NOT_FOUND'),
        }}
      />
    );
  };

  const handleCancel = () => {
    setSelectedRowsIds([...selected, ...budgetVarSelected]);
    setIsSelectionChanged(false);
    onClose();
  };

  const handleAplicar = () => {
    setIsSaving(true);
    let auxSelected = selectedRowIds.filter(
      row => !budgetVarSelected.includes(row)
    );
    applyVariables(budgetId, auxSelected)
      .then(appliedId => {
        onApplySuccess(appliedId);
        setIsSaving(false);
        handleCancel();
        message.success(t('PLANNING_VARIABLE_APPLY_SUCCESS_FEEDBACK'));
      })
      .catch(error => {
        setIsSaving(false);
        handleCancel();
        message.error(t('PLANNING_VARIABLE_APPLY_ERROR_FEEDBACK'));
      });
  };
  return (
    <Drawer
      className="scenario-simulation-drawer"
      visible={visible}
      title={t('ACTION_STAGE')}
      closable={true}
      onClose={handleCancel}
      width={480}
      footer={
        <ButtonActionBar>
          <Button onClick={handleCancel}>{t('ACTION_CANCEL')}</Button>
          <Button
            type="primary"
            onClick={handleAplicar}
            disabled={!isSelectionChanged}
            loading={isSaving}
          >
            {t('ACTION_APPLY')}
          </Button>
        </ButtonActionBar>
      }
    >
      <Space direction="vertical" size="middle">
        <ButtonActionBar>
          <Search
            style={{ width: 200 }}
            placeholder={t('ACTION_SEARCH')}
            allowClear={true}
            onSearch={search => setSearchValue(search)}
          />
        </ButtonActionBar>
        <VariablesTable />
        {variableDetailId && (
          <VariableDetailDrawer
            visible={variableDetailId !== null}
            variableId={variableDetailId}
            periodId={periodId}
            onClose={() => setVariableDetailId(null)}
            applicated={selected.includes(variableDetailId)}
            budgetId={budgetId}
            onChangesDone={() => {
              setIsTableLoading(true);
              fetchVariablesList({
                drawer: true,
                period_id: periodId,
                projection_id: budgetId,
              }).then(() => setIsTableLoading(false));
            }}
            monthsPublishAndApprove={[...monthsToApprove, ...approveDates]}
            isForecast={isForecast}
          />
        )}
      </Space>
    </Drawer>
  );
};

const mapStateToProps = state => ({
  variables: configuration.selectors.getVariablesList(state),
  monthsToApprove: control.selectors.getMonthsToApprove(state),
  approveDates: control.selectors.getApproveDates(state),
});

const mapDispatchToProps = {
  fetchVariablesList: configuration.actions.fetchVariablesList,
  applyVariables: planning.actions.applyVariables,
  fetchMonthsToApprove: control.actions.fetchMonthsToApprove,
  fetchApproveDates: control.actions.fetchApproveDates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioSimulationDrawer);
