export const NAME = 'forecast';

export const FETCH_FORECASTS = `${NAME}/FETCH_FORECASTS`;
export const FETCH_FORECASTS_SUCCESS = `${NAME}/FETCH_FORECASTS_SUCCESS`;
export const FETCH_FORECASTS_FAIL = `${NAME}/FETCH_FORECASTS_FAIL`;

export const FETCH_FORECAST_DETAIL = `${NAME}/FETCH_FORECAST_DETAIL`;
export const FETCH_FORECAST_DETAIL_SUCCESS = `${NAME}/FETCH_FORECAST_DETAIL_SUCCESS`;
export const FETCH_FORECAST_DETAIL_FAIL = `${NAME}/FETCH_FORECASTS_FAIL`;

export const CREATE_FORECAST = `${NAME}/CREATE_FORECAST`;
export const CREATE_FORECAST_SUCCESS = `${NAME}/CREATE_FORECAST_SUCCESS`;
export const CREATE_FORECAST_FAIL = `${NAME}/CREATE_FORECAST_FAIL`;

export const DELETE_FORECAST = `${NAME}/DELETE_FORECAST`;
export const DELETE_FORECAST_SUCCESS = `${NAME}/DELETE_FORECAST_SUCCESS`;
export const DELETE_FORECAST_FAIL = `${NAME}/DELETE_FORECAST_FAIL`;

export const CREATABLE_FORECAST = `${NAME}/CREATABLE_FORECAST`;
export const CREATABLE_FORECAST_SUCCESS = `${NAME}/CREATABLE_FORECAST_SUCCESS`;
export const CREATABLE_FORECAST_FAIL = `${NAME}/CREATABLE_FORECAST_FAIL`;

export const FETCH_FORECAST_PROJECTIONS = `${NAME}/FETCH_FORECAST_PROJECTIONS`;
export const FETCH_FORECAST_PROJECTIONS_SUCCESS = `${NAME}/FETCH_FORECAST_PROJECTIONS_SUCCESS`;
export const FETCH_FORECAST_PROJECTIONS_FAIL = `${NAME}/FETCH_FORECAST_PROJECTIONS_FAIL`;

export const EDIT_FORECAST_NAME = `${NAME}/EDIT_FORECAST_NAME`;
export const EDIT_FORECAST_NAME_SUCCESS = `${NAME}/EDIT_FORECAST_NAME_SUCCESS`;
export const EDIT_FORECAST_NAME_FAIL = `${NAME}/EDIT_FORECAST_NAME_FAIL`;

export const DOWNLOAD_FORECAST_EVOLUTION_XLS = `${NAME}/DOWNLOAD_FORECAST_EVOLUTION_XLS`;
