import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Menu, Typography } from 'antd';

import './Navigator.scss';

const { Text } = Typography;

const MAX_LENGTH_STRING = 20;

const Navigator = ({ items, size = '', floated = true }) => (
  <Breadcrumb
    className="core-navigator"
    separator="/"
    data-floated={floated}
    data-size={size}
  >
    {items.map((item, index) => {
      const { name, url, children, ...otherProps } = item;

      const overlay = children ? (
        <Menu
          style={{
            maxHeight: 500,
            overflowY: 'scroll',
            position: 'absolute',
            right: 'auto',
          }}
        >
          {children.map((child, index) => {
            const { name, url } = child;
            return (
              <Menu.Item key={index}>
                <Link to={url}>{name}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      ) : (
        false
      );

      const renderItemName = () => (
        <Text
          title={name}
          style={{ width: 150 }}
          ellipsis={name?.length > MAX_LENGTH_STRING}
        >
          {name}
        </Text>
      );

      return (
        <Breadcrumb.Item key={index} overlay={overlay} {...otherProps}>
          {url ? <Link to={url}>{renderItemName()}</Link> : renderItemName()}
        </Breadcrumb.Item>
      );
    })}
  </Breadcrumb>
);
export default Navigator;
