const integrations = {
  INTEGRATIONS_CONFIG_PLIKA_SUBTITLE: 'Comencemos a configurar la herramienta',
  INTERATION_INPORT_DATA_SECTION_TITLE: 'Importar datos',
  INTEGRATION_LOAD_MANUAL_SECTION_TITLE: 'Carga manual',
  INTEGRATION_ENTER_USER_INPUT_PLACEHOLDER: 'Ingresa usuario',
  INTEGRATION_ENTER_PASSWORD_INPUT_PLACEHOLDER: 'Ingresa contraseña',
  INTEGRATION_URL_FORMITEM_LABEL: 'URL',
  INTEGRATION_ENTER_URL_INPUT_PLACEHOLDER: 'Ingresa una url',
  INTEGRATION_DATABASE_NAME_FORMITEM_LABEL: 'Nombre base de datos',
  INTEGRATION_ENTER_DATABASE_INPUT_PLACEHOLDER:
    'Ingresa nombre de base de datos',
  INTEGRATION_PORT_FORMITEM_LABEL: 'Puerto',
  INTEGRATION_ENTER_PORT_INPUT_PLACEHOLDER: 'Ingresa puerto',
  INTEGRATION_SELECT_PERIOD_INPUT_PLACEHOLDER: 'Selecciona un período',
  INTEGRATION_PLIKA_LOGO_IMG_ALT: 'Logo plika',
  INTEGRATION_LOGO_IMG_ALT: 'Logo integración',
  INTEGRATION_DATA_INTEGRATION_MODAL_TITLE: 'Datos {{name}}',
  INTEGRATION_CONNECTION_DATA_DIVIDER_TEXT: 'Datos para conexión',
  INTEGRATION_CONFIGURATION_SUCCESS_SCREEN_TITLE: '¡Configuración exitosa!',
  INTEGRATION_ACTIVATION_SUCCESS_DESCRIPTION_1:
    'La información se esta procesando, enviaremos un mail a {{email}} cuando se terminen de cargar los datos.',
  INTEGRATION_ACTIVATION_SUCCESS_DESCRIPTION_2: '¿Cómo desas continuar?',
  INTEGRATION_NEW_CONNECTION_BUTTON_ACTION: 'Nueva conexión',
  INTEGRATION_VIEW_PROCESS_DATA_BUTTON_ACTION: 'Ver detalle del proceso',
  INTERATION_CONNECTED_SECTION_TITLE: 'Conectados',
  INTERATION_MORE_OPTIONS_SECTION_TITLE: 'Más opciones',
  INTEGRATION_OPTION_SECTION_TITLE: 'Opciones',

  INTEGRATIONS_FEEDBACK_IMPORT_ERROR_DESCRIPTION:
    'Ocurrió un error al momento de importar los datos.',
  INTEGRATIONS_FEEDBACK_ACTIVATE_DEACTIVATE_ERROR_DESCRIPTION:
    'Ocurrió un error al {{action}} la integración.',

  INTERATION_PLIKA_SETTING_CARD_TEXT: 'Configuración Plika',

  INTEGRATION_FEEDBACK_SUCCESS_MESSAGE: '{{action}} exitosa',
  INTEGRATIONS_FEEDBACK_DEACTIVATE_SUCCESS_TEXT: 'Desactivación',
  INTEGRATIONS_FEEDBACK_ACTIVATE_SUCCESS_TEXT: 'Activación',

  INTEGRATIONS_ACTIVATE_DESACTIVATE_POPCONFIRM_TITLE:
    'Estas seguro que quieres {{action}} {{name}}?',
};

export default integrations;
