import Immutable from 'seamless-immutable';
import * as types from './constants';

const initialCreation = {
  index: 0,
  date: null,
  account: null,
  initialOriginRow: {},
  originRow: {},
  originBalance: null,
  destinationDimension: null,
  destinationRows: {},
  initialDestinationRow: [],
};

const initialState = Immutable({
  distributionList: {
    count: 0,
    values: [],
  },
  originColumns: [],
  creation: initialCreation,
  distributionDetail: {
    date: null,
    account: null,
    origin: {
      columns: [],
      values: [],
    },
    destinations: [],
  },
});

const createRows = (columns, id, initialRows = []) => {
  if (initialRows.length > 0) {
    return initialRows.reduce((newRows, row, index) => {
      const rowIndex = id + index;
      return Object.assign(newRows, {
        [rowIndex]: createValues(columns, rowIndex, row),
      });
    }, {});
  } else {
    return { [id]: createValues(columns, id) };
  }
};

const createValues = (columns, id, initialValues = {}) => {
  // Valido si el movimiento tiene valores iniciales (Duplicar, XLS o ControlDetail/ReportDetail)
  // En caso de que no tenga valores iniciales, voy completando con null.
  const completInitialValues = item => {
    if (initialValues.hasOwnProperty(item.data_index)) {
      if (item.data_index === 'amount') {
        return initialValues[item.data_index];
      }
      return {
        id: initialValues[item.data_index].id,
        name:
          initialValues[item.data_index].info ||
          initialValues[item.data_index].name,
      };
    }
    return null;
  };

  return columns.reduce(
    (objectRow, item) => {
      return {
        ...objectRow,
        [item.data_index]: completInitialValues(item),
      };
    },
    { id }
  );
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DISTRIBUTIONS_SUCCESS:
      return state.merge({
        distributionList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      });

    case types.FETCH_DISTRIBUTION_DETAIL_SUCCESS:
      return state.merge({
        distributionDetail: {
          ...action.payload.data,
          account: action.payload.data.account.name,
          origin: {
            values: [action.payload.data.origin.values],
            columns: action.payload.data.origin.columns,
          },
          destinations: action.payload.data.destinations,
        },
      });

    case types.FETCH_DISTRIBUTION_ORIGIN_COLUMNS_SUCCESS:
      const filterColumns = action.payload.data.columns.filter(
        column => column.title !== 'Cuenta'
      );
      return state.merge({
        originColumns: filterColumns,
        creation: {
          ...state.creation,
          initialOriginRow: {},
          originRow: createValues(
            filterColumns,
            state.creation.initialOriginRow
          ),
          index:
            state.creation.initialDestinationRow.length > 0
              ? state.creation.initialDestinationRow.length
              : state.creation.index + 1,
          initialDestinationRow: [],
          destinationRows: createRows(
            filterColumns,
            state.creation.index,
            state.creation.initialDestinationRow
          ),
        },
      });

    case types.FETCH_DISTRIBUTION_ORIGIN_BALANCE_SUCCESS:
      return state.merge({
        creation: {
          ...state.creation,
          originBalance: action.payload.data.transaction
            ? action.payload.data.transaction.amount
            : null,
        },
      });

    case types.EDIT_DISTRIBUTION_VALUES:
      return state.merge({
        creation: {
          ...state.creation,
          ...action.payload.data,
        },
      });

    case types.EDIT_DISTRIBUTION_ORIGIN_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          originRow: {
            ...state.creation.originRow,
            ...action.payload.data,
          },
        },
      });

    case types.ADD_DISTRIBUTION_DESTINATION_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          index: state.creation.index + 1,
          destinationRows: {
            ...state.creation.destinationRows,
            ...createRows(state.originColumns, state.creation.index),
          },
        },
      });

    case types.EDIT_DISTRIBUTION_DESTINATION_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          destinationRows: {
            ...state.creation.destinationRows,
            [action.payload.rowId]: {
              ...state.creation.destinationRows[action.payload.rowId],
              ...action.payload.data,
            },
          },
        },
      });

    //NOTE: CONSULTAR COMO FUNCIONA CON LA NUEVA FORMA DE DATA.
    case types.UPLOAD_DISTRIBUTION_FILE_SUCCESS:
      return state.merge({
        creation: {
          ...state.creation,
          destinationRows: action.payload.data.reduce(
            (destinationRows, row) => {
              return {
                ...destinationRows,
                [row.id]: {
                  id: row.id,
                  dimensionId: row.dimension_id,
                  dimensionValue: row.name,
                  amount: row.percentage,
                },
              };
            },
            {}
          ),
        },
      });

    case types.DUPLICATE_DISTRIBUTION_SUCCESS:
      return state.merge({
        creation: {
          ...state.creation,
          index: action.payload.data.destinations.length + 1,
          date: action.payload.data.date,
          account: action.payload.data.account.id,
          initialOriginRow: action.payload.data.origin,
          destinationDimension: action.payload.data.destinations.dimension_id,
          destinationRows: action.payload.data.destinations.reduce(
            (destinations, row, index) => {
              return {
                ...destinations,
                [index]: {
                  id: index,
                  amount: row.percentage,
                  ...row,
                },
              };
            },
            {}
          ),
        },
      });

    case types.REMOVE_DISTRIBUTION_DESTINATION_ROW:
      return state.merge({
        creation: {
          ...state.creation,
          destinationRows: state.creation.destinationRows.without(
            action.payload.rowId
          ),
        },
      });

    case types.RESET_DISTRIBUTION_DESTINATION_ROWS:
      return state.merge({
        creation: {
          ...state.creation,
          destinationRows: {},
        },
      });

    case types.COPY_DISTRIBUTION_ROWS:
      return state.merge({
        creation: {
          ...state.creation,
          index: state.creation.index + 1,
          destinationRows: {
            ...state.creation.destinationRows,
            [state.creation.index]: {
              ...state.creation.destinationRows[action.payload.rowId],
              id: state.creation.index,
            },
          },
        },
      });

    case types.EXIT_DISTRIBUTION_CREATION:
      return state.merge({
        creation: initialCreation,
      });

    default:
      return state;
  }
};
