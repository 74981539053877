import adjustments from './adjustments';
import configuration from './configuration';
import control from './control';
import dashboard from './dashboard';
import distributions from './distributions';
import forecast from './forecast';
import general from './general';
import login from './login';
import periods from './periods';
import planning from './planning';
import report from './report';
import integrations from './integrations';

const index = {
  ...adjustments,
  ...configuration,
  ...dashboard,
  ...general,
  ...login,
  ...periods,
  ...planning,
  ...report,
  ...control,
  ...distributions,
  ...forecast,
  ...integrations,
};

export default index;
