import { ButtonSica, ButtonOther, ButtonZarcam } from './components';

export const renderButtonsByClients = ({ dataSica, dataZarcam, dataOther }) => {
  return {
    acurio: <ButtonOther dataOther={dataOther} />,
    sicaelec: <ButtonSica dataSica={dataSica} />,
    zarcam19676: <ButtonZarcam dataZarcam={dataZarcam} />,
    despegar: <ButtonOther dataOther={dataOther} />,
  };
};
