import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { TABLES } from 'modules/core/constants';
import { generateSelectOptions } from 'modules/core/utils';
import { connect } from 'react-redux';
import {
  ContainerSelects,
  SegregatedAnalysisTable,
  TableActions,
} from './components';
import reports from 'modules/reports';
import login from 'modules/login';
import configuration from 'modules/configuration';
import { getIdsToDisabled, onSaveIdsSelected } from './util';

const LIMIT_NUMBER_VALUES_FIRST_DIMENSION = 2;
const LIMIT_NUMBER_VALUES_SECOND_DIMENSION = 3;

const ValidationHasData = ({
  isDimensionsLoading,
  availableDimensions,
  dimensionsListData,
  reportFunctionalCurrencyData,
  fetchDimensionValues,
  loggedUser: { email },
}) => {
  const [isDimensionsValuesLoading, setIsDimensionsValuesLoading] =
    useState(false);
  const [segregatedTableSettings, setSegregatedTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS(email),
    visualizationColumn: TABLES.VISUALIZATION_COLUMNS_TABLE_TYPES.DEFAULT,
  });
  const [parentsValuesIdsSelected, setParentValuesIdsSelected] = useState({
    first_dimension: null,
    second_dimension: null,
  });
  const [childrensValuesIdsSelected, setChildrensValuesIdsSelected] = useState({
    first_dimension_values: [],
    second_dimension_values: [],
  });
  const [dimensionSelected, setDimensionSelected] = useState({
    id: null,
    key: '',
  });
  const [idsSelected, setIdsSelected] = useState([]);
  const [valuestSelect, setValuestSelect] = useState({
    first_dimension: [],
    second_dimension: [],
  });

  useEffect(() => {
    if (dimensionSelected.id) {
      setIsDimensionsValuesLoading(true);
      fetchDimensionValues({
        dimension: dimensionSelected.id,
        writeable: 'True',
      }).then(response => {
        setIsDimensionsValuesLoading(false);
        setValuestSelect(prevState => {
          return {
            ...prevState,
            [dimensionSelected.key]: response.payload.data.results,
          };
        });
      });
    }
  }, [fetchDimensionValues, dimensionSelected]);

  const has_some_values_selected =
    childrensValuesIdsSelected?.first_dimension_values?.length > 0 ||
    childrensValuesIdsSelected?.second_dimension_values?.length > 0;

  const optionsParentFirstDimension =
    parentsValuesIdsSelected?.first_dimension &&
    valuestSelect?.first_dimension?.length !== 0
      ? valuestSelect.first_dimension.filter(value =>
          availableDimensions[
            parentsValuesIdsSelected?.first_dimension
          ].includes(value.id)
        )
      : [];

  const optionsParentSecondDimension =
    parentsValuesIdsSelected?.second_dimension &&
    valuestSelect?.second_dimension.length !== 0
      ? valuestSelect.second_dimension.filter(value =>
          availableDimensions[
            parentsValuesIdsSelected?.second_dimension
          ].includes(value.id)
        )
      : [];

  return (
    !isDimensionsLoading &&
    !_.isEmpty(availableDimensions) &&
    dimensionsListData.length > 1 && (
      <Row gutter={[24, 12]}>
        <Col span={12}>
          <ContainerSelects
            loading={isDimensionsLoading}
            optionsDimensionsSelect={generateSelectOptions({
              options: dimensionsListData,
            })}
            onChangeDimensionsSelect={val => {
              onSaveIdsSelected(
                setParentValuesIdsSelected,
                'first_dimension',
                val
              );
              setDimensionSelected({ id: val, key: 'first_dimension' });
              if (!val) {
                setParentValuesIdsSelected({
                  first_dimension: null,
                  second_dimension: null,
                });
                setChildrensValuesIdsSelected({
                  first_dimension_values: [],
                  second_dimension_values: [],
                });
              }
            }}
            optionsValuesSelect={generateSelectOptions({
              options: optionsParentFirstDimension,
              selection: getIdsToDisabled(
                LIMIT_NUMBER_VALUES_FIRST_DIMENSION,
                optionsParentFirstDimension,
                idsSelected
              ),
            })}
            onChangeValuesSelect={values => {
              setIdsSelected(values);
              if (values.length === 0) {
                onSaveIdsSelected(
                  setChildrensValuesIdsSelected,
                  'first_dimension_values',
                  values
                );
                setParentValuesIdsSelected(prevState => {
                  return { ...prevState, second_dimension: null };
                });
                setChildrensValuesIdsSelected(prevState => {
                  return { ...prevState, second_dimension_values: [] };
                });
              }
            }}
            disabledValuesSelect={!parentsValuesIdsSelected.first_dimension}
            onBlurValuesSelects={() =>
              onSaveIdsSelected(
                setChildrensValuesIdsSelected,
                'first_dimension_values',
                idsSelected
              )
            }
            LabelSelect={
              'REPORT_SEGREGATED_ANALYSIS_CHOOSE_PRINCIPAL_DIMENSION_VALUES_TYPOGRAPHY'
            }
          />
        </Col>
        <Col span={12}>
          <ContainerSelects
            loading={isDimensionsValuesLoading}
            optionsDimensionsSelect={generateSelectOptions({
              options: dimensionsListData,
              selection: [
                parentsValuesIdsSelected?.first_dimension?.toString(),
              ],
            })}
            disabledDimensionsSelect={
              !childrensValuesIdsSelected.first_dimension_values.length > 0 ||
              !parentsValuesIdsSelected.first_dimension
            }
            onChangeDimensionsSelect={val => {
              onSaveIdsSelected(
                setParentValuesIdsSelected,
                'second_dimension',
                val
              );
              setDimensionSelected({ id: val, key: 'second_dimension' });
              if (!val) {
                setChildrensValuesIdsSelected(prevState => {
                  return { ...prevState, second_dimension_values: [] };
                });
              }
            }}
            optionsValuesSelect={generateSelectOptions({
              options: optionsParentSecondDimension,
              selection: getIdsToDisabled(
                LIMIT_NUMBER_VALUES_SECOND_DIMENSION,
                optionsParentSecondDimension,
                idsSelected
              ),
            })}
            onChangeValuesSelect={values => {
              setIdsSelected(values);
              if (values.length === 0) {
                onSaveIdsSelected(
                  setChildrensValuesIdsSelected,
                  'second_dimension_values',
                  values
                );
              }
            }}
            disabledValuesSelect={
              !parentsValuesIdsSelected.second_dimension ||
              !parentsValuesIdsSelected.first_dimension ||
              !childrensValuesIdsSelected.first_dimension_values.length > 0
            }
            onBlurValuesSelects={() =>
              onSaveIdsSelected(
                setChildrensValuesIdsSelected,
                'second_dimension_values',
                idsSelected
              )
            }
            LabelSelect="REPORT_SEGREGATED_ANALYSIS_CHOOSE_SECONDARY_DIMENSION_VALUES_TYPOGRAPHY"
          />
        </Col>
        {has_some_values_selected && (
          <>
            <Col span={24}>
              <TableActions
                reportFunctionalCurrencyData={reportFunctionalCurrencyData}
                setSegregatedTableSettings={setSegregatedTableSettings}
                segregatedTableSettings={segregatedTableSettings}
              />
            </Col>
            <Col span={24}>
              <SegregatedAnalysisTable
                segregatedTableSettings={segregatedTableSettings}
                parentsValuesIdsSelected={parentsValuesIdsSelected}
                childrensValuesIdsSelected={childrensValuesIdsSelected}
              />
            </Col>
          </>
        )}
      </Row>
    )
  );
};

const mapStateToProps = state => ({
  availableDimensions: reports.selectors.getAvailableDimensions(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationHasData);
