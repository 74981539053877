import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Select, Button, Alert, Space } from 'antd';
import { AddAndDeleteFormItem } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import configuration from 'modules/configuration';
import {
  orderBySelection,
  handleSelectAll,
  generateSelectOptions,
} from 'modules/core/utils';
import _ from 'lodash';

const EditDimensionModal = ({
  visible,
  onCancel,
  onConfirm,
  fetchValuesDimension,
  valuesDimension: { values },
  dimensionsList,
  dimensionsSelected,
  dataAccount,
  setDataJoin,
  dataJoin,
}) => {
  const [dataDimensions, setDataDimensions] = useState(dimensionsSelected);
  const [dimensionId, setDimensionId] = useState(null);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    dimensionId &&
      fetchValuesDimension({
        dimension: dimensionId,
        writeable: 'True',
      });
  }, [fetchValuesDimension, dimensionId]);

  const onClose = () => {
    form.resetFields();
    setDimensionId(null);
    onCancel();
  };

  const onPrepareData = () => {
    form
      .validateFields()
      .then(values => {
        const dataComplete = {};
        values.dimensions.map(
          infoDimension =>
            infoDimension.dimensionValue &&
            Object.assign(dataComplete, {
              [`${infoDimension.dimension}`]: infoDimension.dimensionValue.map(
                id => parseInt(id)
              ),
            })
        );
        setDataJoin({ ...dataJoin, dimensions: dataComplete });
        onConfirm({ ...dataComplete, ...dataAccount });
        onClose();
      })
      .catch(e => {});
  };

  const handleSetFormListValues = (values, index) => {
    const fields = form.getFieldsValue();
    const { dimensions } = fields;
    Object.assign(dimensions[index], { dimensionValue: values });
    return form.setFieldsValue({ dimensions });
  };

  return (
    <Modal
      title={t('CONFIG_VAR_EDIT_DIMENSIONS_MODAL_TITLE')}
      visible={visible}
      okText={t('ACTION_SAVE')}
      onOk={onPrepareData}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onClose}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      destroyOnClose
      closable
    >
      <Space direction="vertical">
        <Alert
          description={t('CONFIG_VAR_DIMENSIONS_INFORMATIVE_ALERT_DESCRIPTION')}
          type="info"
          showIcon
          closable
        />
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            dimensions:
              dataDimensions.length !== 0 ? [...dataDimensions] : [''],
          }}
        >
          <Form.List name="dimensions">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Row gutter={24} key={field.key} justify="end">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'dimension']}
                          label={[field.label, t('FIELD_DIMENSION')]}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: t('REQUIRED_FIELD'),
                          //   },
                          // ]}
                        >
                          <Select
                            placeholder={t('ACTION_SELECT')}
                            disabled={dataDimensions[index]}
                            showArrow
                            onChange={id => {
                              setDimensionId(id);
                              // Se limpia el campo de dimensionValue
                              const fields = form.getFieldsValue();
                              const { dimensions } = fields;
                              Object.assign(dimensions[field.name], {
                                dimensionValue: [],
                              });
                              form.setFieldsValue({ dimensions });
                            }}
                            options={generateSelectOptions({
                              options: dimensionsList?.dimensions,
                              selection: form
                                .getFieldValue()
                                .dimensions.map(
                                  dimension => dimension?.dimension
                                ),
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        style={
                          dataDimensions[index] &&
                          dataDimensions[index].dimensionValue.length > 0 &&
                          dataDimensions[index].dimension !== dimensionId
                            ? { display: 'flex', alignItems: 'center' }
                            : {}
                        }
                        span={12}
                      >
                        {dataDimensions[index] &&
                        dataDimensions[index].dimensionValue.length > 0 &&
                        dataDimensions[index].dimension !== dimensionId ? (
                          <Button
                            type="text"
                            onClick={() =>
                              setDimensionId(dataDimensions[index].dimension)
                            }
                          >
                            {t('CONFIG_VAR_EDIT_DIMENSIONS_ACTION')}
                          </Button>
                        ) : (
                          <Form.Item
                            name={[field.name, 'dimensionValue']}
                            label={[field.label, t('FIELD_DIMENSION_VALUES')]}
                          >
                            <Select
                              placeholder={t('ACTION_SELECT')}
                              mode="multiple"
                              showArrow
                              allowClear
                              showSearch
                              maxTagCount={1}
                              maxTagTextLength={12}
                              onMouseEnter={() => {
                                if (dataDimensions[index]) {
                                  setDimensionId(
                                    dataDimensions[index].dimension
                                  );
                                } else {
                                  if (
                                    (!dimensionId &&
                                      !form.getFieldValue('dimensions')[
                                        field.name
                                      ]) ||
                                    (dimensionId &&
                                      !form.getFieldValue('dimensions')[
                                        field.name
                                      ])
                                  ) {
                                    setDimensionId(null);
                                  } else {
                                    setDimensionId(
                                      form.getFieldValue('dimensions')[
                                        field.name
                                      ]['dimension']
                                    );
                                  }
                                }
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={data =>
                                handleSelectAll({
                                  values: data,
                                  allValues: values
                                    .asMutable()
                                    .map(val => val.id),
                                  areAllSelected,
                                  onSetValues: values =>
                                    handleSetFormListValues(values, index),
                                  onSetAreAllSelected: state =>
                                    setAreAllSelected(state),
                                })
                              }
                              disabled={
                                !dataDimensions[index] &&
                                !form.getFieldValue('dimensions')[field.name]
                              }
                              options={generateSelectOptions({
                                options: orderBySelection(
                                  dataDimensions[index]
                                    ? dataDimensions[index].dimensionValue
                                    : [],
                                  values
                                ),
                                includeOptionAll: true,
                              })}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <AddAndDeleteFormItem
                        fieldsLength={fields.length}
                        index={index}
                        addData={{
                          onClick: () => add(),
                          buttonName: 'CONFIG_DIMENSIONS_ADD_NEW_ACTION',
                        }}
                        deleteData={{
                          onClick: () => {
                            if (field.name === 0) {
                              add();
                              remove(field.name);
                            } else {
                              remove(field.name);
                            }
                            const auxDimension = _.pull(
                              dataDimensions,
                              dataDimensions[index]
                            );
                            setDataDimensions(auxDimension);
                          },
                          buttonName: 'CONFIG_DIMENSIONS_DELETE_ACTION',
                        }}
                        otherCondition={
                          dataDimensions.length === 0
                            ? fields.length > 1
                            : fields.length >= 1
                        }
                      />
                    </Row>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Space>
    </Modal>
  );
};

const mapStateToProps = state => ({
  valuesDimension: configuration.selectors.getValuesDimension(state),
});

const mapDispatchToProps = {
  fetchValuesDimension: configuration.actions.fetchValuesDimension,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDimensionModal);
