import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Button, Space } from 'antd';
import { DownloadOutlined, DollarOutlined } from '@ant-design/icons';
import {
  TableSettingsModal,
  ButtonActionBar,
  Typography,
  CellStyle,
  References,
} from 'modules/core/components';
import { calculateScrollX, columnPadding } from 'modules/core/utils';
import { ROWS, TABLES, COLUMNS, ACCES_USERS } from 'modules/core/constants';
import reports from 'modules/reports';
import login from 'modules/login';

//TODO: POR AHORA ACA HASTA QUE SE PUEDAN ESTANDARIZAR LAS COLUMNAS A TODAS IGUALES CUANDO SON COMPARACIONES. CUANDO ESTE, MANEJARLO COMO EN LA TABLA COMPARACIONES DE SEGUIMIENTO
const NUMBER_PERCENTAGE = [
  'Presupuesto_%',
  'Real_%',
  'Real periodo anterior_%',
  'Ultimo forecast_%',
];
const NUMBER_DECIMAL = [
  'Real',
  'Presupuesto',
  'Ultimo forecast',
  'Real periodo anterior',
];

const FORMATS_TYPES = {
  '0,0': 'integer',
  '0,0.00': 'decimals',
  '0[.]0a': 'millions',
};

//TODO: EXPANDABLEROWS
const VerticalComparisonTable = ({
  fetchReportVerticalComparisonDataRow,
  reportVerticalComparisonDataRow,
  fetchReportVerticalComparisonColumns,
  reportVerticalComparisonColumns,
  downloadEvolutionVerticalComparisonXLS,
  reportId,
  periodId,
  filters,
  selectedDates,
  periodDetail,
  comparisonId,
  dimensionValueId,
  reportStructureId,
  functionalCurrencyData,
  loggedUser: { email },
}) => {
  const [tableSettings, setTableSettings] = useState(
    TABLES.DEFAULT_SETTINGS(email)
  );
  const [isTableaLoading, setIsTableLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const endpointsParams = {
      period_id: periodId,
      report_row: reportStructureId,
      ...(dimensionValueId && {
        dimension_value: dimensionValueId,
      }),
    };
    setIsTableLoading(true);
    Promise.all([
      fetchReportVerticalComparisonDataRow(reportId, {
        ...endpointsParams,
        ...selectedDates,
        ...filters,
      }),
      fetchReportVerticalComparisonColumns(reportId, endpointsParams),
    ]).then(() => setIsTableLoading(false));
  }, [
    fetchReportVerticalComparisonDataRow,
    fetchReportVerticalComparisonColumns,
    reportId,
    periodId,
    dimensionValueId,
    reportStructureId,
    selectedDates,
    filters,
  ]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const renderTableActions = () => {
    return (
      <ButtonActionBar position="space-between">
        <References
          referencesItems={[
            ...(functionalCurrencyData
              ? [
                  {
                    type: <DollarOutlined />,
                    title:
                      functionalCurrencyData && functionalCurrencyData.code,
                    description:
                      functionalCurrencyData &&
                      t('REFERENCE_DESCRIPTION', {
                        currencyName: t(
                          functionalCurrencyData.name
                        ).toLowerCase(),
                      }),
                  },
                ]
              : []),
          ]}
        />
        <Space direction="horizontal">
          <Button
            type="text"
            shape="circle"
            title={t('ACTION_DOWNLOAD_TABLE')}
            icon={<Typography.Icon icon={DownloadOutlined} />}
            onClick={() =>
              downloadEvolutionVerticalComparisonXLS(
                reportId,
                {
                  period_id: periodId,
                  dimension: comparisonId,
                  format_number: FORMATS_TYPES[tableSettings.format],
                  dimension_id: dimensionValueId,
                  report_row: reportStructureId,
                  ...selectedDates,
                  ...filters,
                },
                t('REPORT_EVOLUTION_VERTICAL_ANALYSIS_COMPARISON_FILE_NAME', {
                  periodName: periodDetail.name,
                })
              )
            }
          />
          <TableSettingsModal
            onConfirm={setTableSettings}
            options={reportVerticalComparisonColumns.filter(
              column => column.is_transaction
            )}
            settings={tableSettings}
          />
        </Space>
      </ButtonActionBar>
    );
  };

  const columnsGenerator = columns => [
    ...columns
      .filter(column =>
        tableSettings.visibleData
          ? !column.is_transaction ||
            tableSettings.visibleData.includes(column.data_index)
          : true
      )
      .map((column, index) => {
        return {
          key: index,
          ellipsis: true,
          dataIndex: column.data_index,
          ...(!column.is_transaction && {
            title: column.title,
            width: COLUMNS.WIDTH.EXPANDABLE,
            fixed: 'left',
            render: (name, row) => columnPadding(name, row.level),
          }),
          ...(column.is_transaction && {
            title: column.title,
            width: COLUMNS.WIDTH.TRANSACTION,
            align: 'right',
            render: transaction => {
              if (NUMBER_DECIMAL.includes(column.data_index))
                return (
                  <CellStyle.Number
                    value={transaction}
                    format={tableSettings.format}
                    has_restriction={has_restriction}
                  />
                );
              if (NUMBER_PERCENTAGE.includes(column.data_index))
                return <CellStyle.Number value={transaction} format="%" />;
              return transaction;
            },
          }),
        };
      }),
  ];

  return (
    <>
      {!isTableaLoading && renderTableActions()}
      <Table
        rowKey="id"
        size="small"
        loading={isTableaLoading}
        columns={columnsGenerator(reportVerticalComparisonColumns)}
        dataSource={reportVerticalComparisonDataRow}
        rowClassName={record => ROWS.TYPES[record.type]}
        expandIconColumnIndex={-1} //TODO: Cuando este el expandir desde back, borrarlo.
        scroll={{
          x: calculateScrollX(
            columnsGenerator(reportVerticalComparisonColumns),
            10
          ),
          y: 400,
        }}
        pagination={false}
      />
    </>
  );
};

const mapStateToProps = state => ({
  reportVerticalComparisonDataRow:
    reports.selectors.getReportVerticalComparisonDataRow(state),
  reportVerticalComparisonColumns:
    reports.selectors.getReportVerticalComparisonColumns(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchReportVerticalComparisonDataRow:
    reports.actions.fetchReportVerticalComparisonDataRow,
  fetchReportVerticalComparisonColumns:
    reports.actions.fetchReportVerticalComparisonColumns,
  downloadEvolutionVerticalComparisonXLS:
    reports.actions.downloadEvolutionVerticalComparisonXLS,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalComparisonTable);
