import {
  getOnboardingStepsContent,
  setLocaleNamesTranslate,
} from 'modules/core/utils';

const onboardingConfigSteps = history => [
  {
    content: getOnboardingStepsContent({
      text: 'CONFIG_ONBOARDING_STEP_DESCRIPTION_SECURITY',
      action: () => history.push('/configuracion/misdatos/seguridad/'),
    }),
    target: '.security',
    locale: setLocaleNamesTranslate(),
    placement: 'left-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'CONFIG_ONBOARDING_STEP_DESCRIPTION_PLAN_ACCOUNT',
      action: () => history.push('/configuracion/misdatos/plan/'),
    }),
    target: '.accounts',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'CONFIG_ONBOARDING_STEP_DESCRIPTION_CONCEPT',
      action: () => history.push('/configuracion/misdatos/conceptos/'),
    }),
    target: '.concepts',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'CONFIG_ONBOARDING_STEP_DESCRIPTION_DIMENSIONS',
      action: () => history.push('/configuracion/misdatos/dimensiones/'),
    }),
    target: '.dimensions',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'CONFIG_ONBOARDING_STEP_DESCRIPTION_PROJECTIONS_ELEMENTS',
      action: () =>
        history.push('/configuracion/misdatos/elementosdeproyeccion/'),
    }),
    target: '.projectionElements',
    locale: setLocaleNamesTranslate(),
    placement: 'right-start',
  },
  {
    content: getOnboardingStepsContent({
      text: 'CONFIG_ONBOARDING_STEP_DESCRIPTION_REPORT',
      action: () => history.push('/configuracion/misdatos/reportes/'),
    }),
    target: '.reports',
    locale: setLocaleNamesTranslate(),
    placement: 'left-start',
  },
];

export default onboardingConfigSteps;
