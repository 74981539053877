import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { usePagination } from 'modules/core/customHooks';
import {
  Button,
  Row,
  Col,
  message,
  Popconfirm,
  notification,
  Spin,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  ButtonActionBar,
  Navigator,
  EmptyScreen,
  DeleteSelectionButton,
  DropdownMenu,
} from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { CreatDimensionModal, DimensionsTable } from './components/index';
import { isUserAllowed } from 'modules/core/utils';
import { INTEGRATIONS, ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';

import './DimensionsList.scss';

const { SETTINGS__DIMENSIONS__CREATE, SETTINGS__DIMENSIONS__DELETE } = ROLES;

const DimensionsList = ({
  fetchDimensionsList,
  dimensionsList: { dimensions, count },
  deleteDimension,
  deleteAllDimensionValues,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [dataSelection, setDataSelection] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [tablePagination, setTablePagination] = usePagination();
  const { t } = useTranslation();

  useEffect(() => {
    fetchDimensionsList({ flat: 'True', writeable: 'True' }).then(() =>
      setIsTableLoading(false)
    );
  }, [fetchDimensionsList]);

  const userCanCreate = isUserAllowed(SETTINGS__DIMENSIONS__CREATE);

  const userCanDelete = isUserAllowed(SETTINGS__DIMENSIONS__DELETE);

  const onSetDataRowSelection = data => setDataSelection(data);

  const onDeleteSelections = () => {
    setIsDeleting(true);
    Promise.all(
      dataSelection.map(id =>
        deleteDimension(id).catch(error => {
          setIsDeleting(false);
          message.error(t('FEEDBACK_DELETE_SELECTION_ERROR'));
          setDataSelection([]);
        })
      )
    ).then(() =>
      fetchDimensionsList({ flat: 'True', writeable: 'True' }).then(() => {
        setIsDeleting(false);
        message.success(t('FEEDBACK_DELETE_SELECTION_SUCCESS'));
        setDataSelection([]);
      })
    );
  };

  const onDeleteAllValues = () => {
    setIsDeleting(true);
    deleteAllDimensionValues()
      .then(() => {
        setIsDeleting(false);
        setShowDeleteAll(false);
        fetchDimensionsList({ flat: 'True', writeable: 'True' });
        message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
      })
      .catch(error => {
        setIsDeleting(false);
        setShowDeleteAll(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_VALUES_ERROR'),
          duration: 0,
        });
      });
  };

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_DIMENSIONS')}
      footer={
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('CONFIG_DIMENSIONS_EMPTY_STATE_CREATE_ACTION')}
        </Button>
      }
    />
  );

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        { name: t('LABEL_DIMENSIONS') },
      ]}
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar className="dimensions-list-actions">
      <Button
        type="primary"
        onClick={() => setShowCreateModal(true)}
        disabled={!userCanCreate}
      >
        {t('ACTION_CREATE_NEW')}
      </Button>
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_DELETE_ALL'),
            icon: <DeleteOutlined />,
            onClick: () => setShowDeleteAll(true),
            disabled: !userCanDelete,
          },
        ]}
      />
      <Popconfirm
        placement="left"
        title={t('POPCONFIRM_DELETE_ALL_PAGES', {
          projectionName: t('LABEL_DIMENSIONS').toLowerCase(),
        })}
        okText={t('ACTION_DELETE')}
        onConfirm={onDeleteAllValues}
        cancelText={t('ACTION_CANCEL')}
        onCancel={() => setShowDeleteAll(false)}
        visible={showDeleteAll}
      />
    </ButtonActionBar>
  );

  const renderTableActions = () =>
    dataSelection.length > 0 && (
      <DeleteSelectionButton
        onConfirm={onDeleteSelections}
        disabled={!userCanDelete}
        isDeletingAll={
          dataSelection.length === tablePagination.page_size ||
          dataSelection.length === count
        }
      />
    );

  return (
    <SiderLayout>
      <div className="dimensions-list-container">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Spin spinning={isTableLoading} />
          {count <= 0 && !isTableLoading && (
            <Col span={24}>{renderNoDataScreen()}</Col>
          )}
          {count > 0 && (
            <>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderTableActions()}</Col>
              <Col span={24}>
                <DimensionsTable
                  dataSource={dimensions}
                  count={count}
                  onChange={setTablePagination}
                  tableLoading={isTableLoading || isDeleting}
                  pagination={tablePagination}
                  dataSelection={dataSelection}
                  onSetRowSelection={id => {
                    onSetDataRowSelection(id);
                  }}
                  onFetchDimensionList={() =>
                    fetchDimensionsList({ flat: 'True', writeable: 'True' })
                  }
                />
              </Col>
            </>
          )}
          <CreatDimensionModal
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            onFetchDimensionList={() =>
              fetchDimensionsList({ flat: 'True', writeable: 'True' })
            }
          />
        </Row>
      </div>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
});

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  deleteDimension: configuration.actions.deleteDimension,
  deleteAllDimensionValues: configuration.actions.deleteAllDimensionValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(DimensionsList);
