import React, { useState, useEffect } from 'react';
import { client } from 'httpclient';
import queryString from 'query-string';
import { Spin, Row, Col, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { KPIValue, Typography } from 'modules/core/components';
import emptyImage from 'assets/images/compositions/empty-card.svg';
import numeral from 'numeral';
import { CONCEPTS, REPORT } from 'modules/core/constants';

//TODO: CHECKEAR COMO SE VEN LOS KPIS CUANDO HAYA DATOS Y ESTE EL ENDPOINT SUBIDO
const FETCH_URL_KPIS = {
  // EX: `/report/reports/${id}/card-kpis/?period_id=${periodId}`
  pnl: { part1: '/report/reports/', part2: '/card-kpis/' },
  // EX: `/planning/periods/${id}/controlling-card-kpis/?concept=${concept}`
  fnl: {
    part1: '/report/financial/',
    part2: '/card-kpis/',
  },
  [CONCEPTS.KEYS.SALES]: {
    part1: '/planning/periods/',
    part2: '/controlling-card-kpis/',
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    part1: '/planning/periods/',
    part2: '/controlling-card-kpis/',
  },
  [CONCEPTS.KEYS.EXTRAORDINARY_INCOME]: {
    part1: '/planning/periods/',
    part2: '/controlling-card-kpis/',
  },
  [CONCEPTS.KEYS.EXTRAORDINARY_EXPENSES]: {
    part1: '/planning/periods/',
    part2: '/controlling-card-kpis/',
  },
  [CONCEPTS.KEYS.OPERATING_INCOME]: {
    part1: '/planning/periods/',
    part2: '/controlling-card-kpis/',
  },
  [CONCEPTS.KEYS.OPERATING_EXPENSES]: {
    part1: '/planning/periods/',
    part2: '/controlling-card-kpis/',
  },
};

const ProjectionListKPI = ({
  type,
  periodId,
  projectionId,
  emptyState: { title, description },
  image = null,
  hasData,
  renderPrefix,
}) => {
  const [kpis, setKpis] = useState({ value: null });
  const [isKpisLoading, setIsKpisLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const PARAMS = {
      pnl: { period_id: periodId },
      fnl: { period_id: periodId },
      [CONCEPTS.KEYS.SALES]: { concept: type },
      [CONCEPTS.KEYS.EXPENSES]: { concept: type },
      [CONCEPTS.KEYS.EXTRAORDINARY_INCOME]: { concept: type },
      [CONCEPTS.KEYS.EXTRAORDINARY_EXPENSES]: { concept: type },
      [CONCEPTS.KEYS.OPERATING_INCOME]: { concept: type },
      [CONCEPTS.KEYS.OPERATING_EXPENSES]: { concept: type },
    };

    if (FETCH_URL_KPIS[type] && hasData) {
      setIsKpisLoading(true);
      client
        .get(
          `${
            FETCH_URL_KPIS[type].part1 +
            projectionId +
            FETCH_URL_KPIS[type].part2
          }?${queryString.stringify(PARAMS[type])}`
        )
        .then(response => {
          setKpis(response.data.kpis[0]);
          setIsKpisLoading(false);
        })
        .catch(() => setIsKpisLoading(false));
    } else {
      setIsKpisLoading(false);
    }
  }, [type, projectionId, hasData, periodId]);

  const renderEmptyKpis = () => (
    <Row align="middle">
      <Col span={14}>
        <Typography.Body level={2}>
          {title ? title : t('EMPTY_STATE_NO_DATA_TITLE')}
        </Typography.Body>
        <br />
        {description && (
          <Typography.Body level={3} type="secondary">
            {description}
          </Typography.Body>
        )}
      </Col>
      <Col span={4} offset={2}>
        <img alt="img" height="100" src={image ? image : emptyImage} />
      </Col>
    </Row>
  );

  const renderKpis = kpis => {
    if (kpis) {
      return (
        <Row align="middle">
          <Col span={12}>
            <KPIValue
              size="large"
              fullValue={kpis.value && numeral(kpis.value).format('0,0.00')}
              value={kpis.value ? numeral(kpis.value).format('0[.]0a') : '-'}
              prefix={renderPrefix ? renderPrefix(kpis.prefix) : kpis.prefix}
            />
          </Col>
          <Col span={12}>
            {kpis.children &&
              kpis.children.map((kpi, index) => (
                <Row gutter={[16, 8]} key={index}>
                  <Col span={12}>
                    <KPIValue
                      prefix={
                        renderPrefix ? renderPrefix(kpi.prefix) : kpi.prefix
                      }
                      fullValue={
                        kpi.value && numeral(kpi.value).format('0,0.00')
                      }
                      value={
                        kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'
                      }
                    />
                    <br />
                    <Typography.Body level={3} type="secondary">
                      {kpi.name}
                    </Typography.Body>
                  </Col>
                  <Col span={12}>
                    <KPIValue
                      isVariation={true}
                      value={kpi.variation ? kpi.variation : '-'}
                      type="secondary"
                    />
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
      );
    }
    return <Empty />;
  };

  return (
    <>
      <Spin spinning={isKpisLoading} size="large" />
      {!isKpisLoading && (
        <>
          {(hasData &&
            (type === REPORT.REPORTS_KEYS.PNL_KEY ||
              type === REPORT.REPORTS_KEYS.FNL_KEY)) ||
          kpis.value !== null
            ? renderKpis(kpis)
            : renderEmptyKpis()}
        </>
      )}
    </>
  );
};

export default ProjectionListKPI;
