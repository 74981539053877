import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { Table, Row, Col, Input } from 'antd';
import { CellSelect } from './Cells';
import { DistributionTableHeader } from '../../../../components';
import distributions from 'modules/distributions';
import { useTranslation } from 'react-i18next';

const DistributionOriginTable = ({
  valueDate = null,
  valueAccount = null,
  disabled,
  dataSource,
  originColumns,
  isTableLoading,
  originRowFormatted,
  originBalance,
  fetchDistributionOriginBalance,
  editDistributionOriginRow,
  successCreation,
  prefix,
  dataOrigin,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchDistributionOriginBalance({
      date: valueDate,
      dv_filter: originRowFormatted,
    }).catch(error => console.log(t('DISTRIBUTION_NOT_AMOUNT_FAIL_FEEDBACK')));
  }, [fetchDistributionOriginBalance, valueDate, originRowFormatted, t]);

  const dimensionColumns = originColumns
    .filter(item => item.data_index !== 'amount')
    .map(item => {
      return {
        title: item.title,
        dataIndex: item.data_index,
        width: 200,
        render: (record, row) => {
          return (
            <CellSelect
              key={item.dimension}
              label={item.title}
              disabled={disabled || successCreation}
              fetchData={{
                date: valueDate,
                desired_dimension_id: item.data_index,
                value_id: valueAccount,
                dimensionId: 1,
              }}
              value={dataOrigin ? dataOrigin[item.data_index] : undefined}
              onChange={newValue =>
                editDistributionOriginRow({ [item.data_index]: newValue })
              }
            />
          );
        },
      };
    });

  //NOTE: Por como funciona antd con los scroll de tabla y las columnas con ancho fijo,
  // es necesario dejar al menos una columna sin ancho fijo.
  // https://ant.design/components/table/#components-table-demo-fixed-columns
  const columns = [
    { title: '', dataIndex: 'id', render: id => '' },
    {
      title: t('FIELD_AMOUNT'),
      dataIndex: 'amount',
      width: 200,
      fixed: 'right',
      render: (record, row) => (
        <Input
          value={numeral(originBalance).format('0,0[.]00')}
          prefix={prefix}
          disabled={true}
        />
      ),
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <DistributionTableHeader
          title={t('DISTIBUTION_ORIGIN_FIELD')}
          disabled={successCreation ? successCreation : disabled}
        />
        <Table
          rowKey="id"
          size="small"
          showHeader={false}
          loading={isTableLoading}
          dataSource={dataSource}
          columns={[...dimensionColumns, ...columns]}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

const mapDispatchToProps = {
  fetchDistributionOriginBalance:
    distributions.actions.fetchDistributionOriginBalance,
  editDistributionOriginRow: distributions.actions.editDistributionOriginRow,
};

export default connect(null, mapDispatchToProps)(DistributionOriginTable);
