const ACCES_DEFAULT_USERS = [
  'mguillen@test.com.ar',
  'cperotti@test.com.ar',
  'cperotti@bluedraft.com.ar',
  'pnavone@gtar.com.ar',
  'usuario0@capacitacion0.com',
  'jbevilacqua@comerciales.com',
  'mguillen@patagonia.com.ar',
  'usuario8@capacitacion8.com',
  'usuario9@capacitacion9.com',
  'usuario10@capacitacion10.com',
  'usuario12@capacitacion12.com',
  'usuario13@capacitacion13.com',
  'usuario3@capacitacion3.com',
  'usuario2@capacitacion2.com',
];

const ACCES_CURRENCY_USERS = [
  'pnavone@partners.com.ar',
  ...ACCES_DEFAULT_USERS,
];

const ACCES_FINANCIAL_USERS = [
  'cperotti@onboarding.com',
  'jbevilacqua@demo.com',
  ...ACCES_DEFAULT_USERS,
];

//NOTE: HARDCODEADO POR AHORA. DESPUES DEPENDE DE LOS USUARIOS QUE TENGAN PERMISO
const ACCES_DASHBOARD_QUICKSIGHT_USERS = [
  '@test',
  '@sicaelec',
  '@comerciales',
  '@capacitacion0',
  '@capacitacion8',
  '@capacitacion9',
  '@capacitacion10',
  '@capacitacion12',
  '@capacitacion13',
  '@capacitacion3',
  '@capacitacion2',
  '@patagonia',
  '@acurio',
  '@despegar',
  '@zarcam',
];

const ACCES_INTEGRATIONS_USERS = ['pnavone@ucc.edu.ar'];

const ACCES_FLAT_DOWNLOAD_BUTTON_USERS = ['@despegar', '@test'];

const PATCH_ACCES_DEFAULT_USERS = [...ACCES_DEFAULT_USERS];

const PATCH_ACCES_CURRENCY_USERS = [...ACCES_CURRENCY_USERS];

const PATCH_ACCES_FINANCIAL_USERS = [...ACCES_FINANCIAL_USERS];

const PATCH_ACCES_INTEGRATION_USERS = [...ACCES_INTEGRATIONS_USERS];

const PATCH_ACCES_DASHBOARD_QUICKSIGHT_USERS = [
  ...ACCES_DASHBOARD_QUICKSIGHT_USERS,
];

const PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS = [
  ...ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
];

const ACCES_USERS = {
  PATCH_ACCES_DEFAULT_USERS,
  PATCH_ACCES_CURRENCY_USERS,
  PATCH_ACCES_INTEGRATION_USERS,
  PATCH_ACCES_FINANCIAL_USERS,
  PATCH_ACCES_DASHBOARD_QUICKSIGHT_USERS,
  PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
};

export default ACCES_USERS;
