import React, { useEffect, useState } from 'react';
import { client } from 'httpclient';
import {
  Card,
  Col,
  Row,
  Spin,
  Space,
  Popconfirm,
  Button,
  Tooltip,
  Input,
  message,
  Typography as TypographyAnt,
} from 'antd';
import numeral from 'numeral';
import {
  KPIValue,
  Typography,
  StatusTag,
  ButtonActionBar,
  DropdownMenu,
} from 'modules/core/components';
import {
  RightCircleOutlined,
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import emptyImage from 'assets/images/compositions/empty-card.svg';
import { connect } from 'react-redux';
import planning from 'modules/planning';
import login from 'modules/login';
import { useTranslation } from 'react-i18next';
import { isApproved, isPublished, isUserAllowed } from 'modules/core/utils';
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants';
import { useHistory } from 'react-router-dom';

import './BudgetCard.scss';

const {
  PLANNING__EXPENSES__EDIT,
  PLANNING__EXPENSES__DUPLICATE,
  PLANNING__EXPENSES__DELETE,
  PLANNING__SALES__EDIT,
  PLANNING__SALES__DUPLICATE,
  PLANNING__SALES__DELETE,
  PLANNING__CONSOLIDATED__EDIT,
  PLANNING__CONSOLIDATED__DUPLICATE,
  PLANNING__CONSOLIDATED__DELETE,
  PLANNING__EXPENSES__EXCHANGE_RATE,
  PLANNING__SALES__EXCHANGE_RATE,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PLANNING__CONCEPT = {
  [PROJECTION.CONSOLIDATED]: {
    EDIT: PLANNING__CONSOLIDATED__EDIT,
    DUPLICATE: PLANNING__CONSOLIDATED__DUPLICATE,
    DELETE: PLANNING__CONSOLIDATED__DELETE,
  },
  [CONCEPTS.KEYS.SALES]: {
    EDIT: PLANNING__SALES__EDIT,
    DUPLICATE: PLANNING__SALES__DUPLICATE,
    DELETE: PLANNING__SALES__DELETE,
    EXCHANGE_RATE: PLANNING__SALES__EXCHANGE_RATE,
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    EDIT: PLANNING__EXPENSES__EDIT,
    DUPLICATE: PLANNING__EXPENSES__DUPLICATE,
    DELETE: PLANNING__EXPENSES__DELETE,
    EXCHANGE_RATE: PLANNING__EXPENSES__EXCHANGE_RATE,
  },
};

const FETCH_URL_KPIS = {
  [PROJECTION.CONSOLIDATED]: `consolidation-card-kpis/`,
  [CONCEPTS.KEYS.SALES]: `card-kpis/`,
  [CONCEPTS.KEYS.EXPENSES]: `card-kpis/`,
};

const { Text } = TypographyAnt;

const BudgetCard = ({
  budgetId,
  periodId,
  title,
  type = 'consolidated',
  className = '',
  image = null,
  status,
  has_updated_exchangerates = true,
  onDuplicate,
  onOpen,
  onOpenActivities = null,
  reloadList,
  editBudgetName,
  deleteBudget,
  extraFooter = null,
  onFetchPeriodDetail,
  extraOptionExchangeRate = null,
  renderPrefix,
}) => {
  const [titleValue, setTitleValue] = useState(title);
  const [kpis, setKpis] = useState([]);
  const [isKpisLoading, setIsKpisLoading] = useState(true);
  const [budgetDelete, setBudgetDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editName, setEditName] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    if (FETCH_URL_KPIS[type]) {
      client
        .get(`/planning/projections/${budgetId}/${FETCH_URL_KPIS[type]}`)
        .then(response => {
          if (type === PROJECTION.CONSOLIDATED) {
            setKpis(response.data.kpi);
          } else {
            setKpis(response.data.kpis[0]);
          }
          setIsKpisLoading(false);
        })
        .catch(() => {
          setKpis(null);
          setIsKpisLoading(false);
        });
    }
  }, [type, budgetId]);

  const canUserExchangeRateView = isUserAllowed(
    PLANNING__CONCEPT[type].EXCHANGE_RATE
  );

  const renderBodyKpis = () => {
    if (canUserExchangeRateView) {
      if (!has_updated_exchangerates) {
        return (
          <span>
            {t('PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_1')}{' '}
            <span
              className="projection-link-go-to-card"
              onClick={() =>
                history.push(
                  `/planificacion/${periodId}/tipodecambio/${budgetId}`
                )
              }
            >
              {t('PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_2')}
            </span>{' '}
            {t('PLANNING_CURRENCY_EMPTY_DESCRIPTION_CARD_BODY_3')}
          </span>
        );
      }
    }
    return t('PLANNING_EMPTY_CARD_BODY');
  };

  const renderEmptyKpis = () => (
    <Row align="middle">
      <Col span={14}>
        <Typography.Body level={2}>
          {t('EMPTY_STATE_NO_DATA_TITLE')}
        </Typography.Body>
        <br />
        <Typography.Body level={3} type="secondary">
          {renderBodyKpis()}
        </Typography.Body>
      </Col>
      <Col span={4} offset={2}>
        <img alt="img" height="100" src={image ? image : emptyImage} />
      </Col>
    </Row>
  );

  const renderCardFooter = () => (
    <ButtonActionBar position="space-between">
      <Tooltip
        title={
          !has_updated_exchangerates
            ? t('PLANNING_CURRENCY_LOAD_EXCHANGE_RATE_TOOLTIP_TITLE')
            : ''
        }
      >
        <Button
          type="link"
          onClick={onOpen}
          disabled={!has_updated_exchangerates && !isApproved(status)}
        >
          {t('ACTION_ENTER')}
          <RightCircleOutlined />
        </Button>
      </Tooltip>
      {extraFooter}
    </ButtonActionBar>
  );

  const handleDelete = () => {
    setIsDeleting(true);
    deleteBudget(budgetId)
      .then(() => {
        message.success(t('PLANNING_DELETE_BUDGET_SUCCESS_FEEDBACK'));
        setIsDeleting(false);
        setBudgetDelete(false);
        reloadList();
        isPublished(status) && onFetchPeriodDetail();
      })
      .catch(() => {
        message.error(t('PLANNING_DELETE_BUDGET_ERROR_FEEDBACK'));
        setIsDeleting(false);
        setBudgetDelete(false);
      });
  };

  const handleEdit = value => {
    if (value !== titleValue) {
      setIsEditing(true);
      editBudgetName(budgetId, { name: value })
        .then(() => {
          message.success(t('PLANNING_EDIT_NAME_BUDGET_SUCCESS_FEEDBACK'));
          setEditName(false);
          setIsEditing(false);
          setTitleValue(value);
        })
        .catch(() => {
          message.error(t('PLANNING_EDIT_NAME_BUDGET_ERROR_FEEDBACK'));
          setEditName(false);
          setIsEditing(false);
        });
    } else {
      setEditName(false);
      setIsEditing(false);
    }
  };

  const renderCardMoreActions = () => (
    <Popconfirm
      placement="bottomRight"
      title={t('POPCONFIRM_DELETE_DEFAULT_TITLE', { name: title })}
      okText={t('ACTION_DELETE')}
      okButtonProps={{ loading: isDeleting }}
      onConfirm={handleDelete}
      cancelText={t('ACTION_CANCEL')}
      onCancel={() => setBudgetDelete(false)}
      visible={budgetDelete}
    >
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          ...(extraOptionExchangeRate ? extraOptionExchangeRate : []),
          {
            title: t('ACTION_EDIT_NAME'),
            icon: <EditOutlined />,
            onClick: () => setEditName(true),
            disabled: !isUserAllowed(PLANNING__CONCEPT[type].EDIT),
          },
          {
            title: t('ACTION_COPY'),
            icon: <CopyOutlined />,
            onClick: () => onDuplicate(titleValue),
            disabled: !isUserAllowed(PLANNING__CONCEPT[type].DUPLICATE),
          },
          {
            title: (
              <Tooltip
                placement="right"
                title={
                  isApproved(status)
                    ? t('PLANNING_DISABLED_DELETE_TOOLTIP')
                    : ''
                }
              >
                {t('ACTION_DELETE')}
              </Tooltip>
            ),
            icon: <DeleteOutlined />,
            disabled:
              !isUserAllowed(PLANNING__CONCEPT[type].DELETE) ||
              isApproved(status),
            onClick: () => setBudgetDelete(true),
          },
          ...(onOpenActivities
            ? [
                {
                  title: t('ACTION_ACTIVITIES'),
                  icon: <SolutionOutlined />,
                  onClick: onOpenActivities,
                },
              ]
            : []),
        ]}
      />
    </Popconfirm>
  );

  const renderCardData = () => (
    <Row align="middle">
      <Col span={24}>
        <KPIValue
          size="large"
          fullValue={kpis.value && numeral(kpis.value).format('0,0.00')}
          value={kpis.value ? numeral(kpis.value).format('0[.]0a') : '-'}
          prefix={renderPrefix(kpis.prefix ? kpis.prefix : '$')}
        />
        <Typography.Body level={3} type="secondary">
          {kpis.name}
        </Typography.Body>
      </Col>
    </Row>
  );

  return (
    <Card
      title={
        <Spin spinning={isEditing}>
          {editName ? (
            <Input
              autoFocus
              disabled={isEditing}
              defaultValue={titleValue}
              onBlur={e => handleEdit(e.target.value)}
              onPressEnter={e => handleEdit(e.target.value)}
            />
          ) : (
            <Text title={titleValue} style={{ width: 180 }} ellipsis={true}>
              {titleValue}
            </Text>
          )}
        </Spin>
      }
      className={`projection-list-card ${className}`}
      data-type={type}
      headStyle={{ height: 56 }}
      bodyStyle={{ height: 128, display: 'flex', alignItems: 'center' }}
      bordered={false}
      actions={[renderCardFooter()]}
      extra={
        <Space align="center">
          <StatusTag status={status} />
          {renderCardMoreActions()}
        </Space>
      }
    >
      <Spin spinning={isKpisLoading} />
      {kpis && kpis.value !== null && !isKpisLoading && renderCardData()}
      {(!kpis || (kpis && kpis.value === null)) &&
        !isKpisLoading &&
        renderEmptyKpis()}
    </Card>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  editBudgetName: planning.actions.editBudgetName,
  deleteBudget: planning.actions.deleteBudget,
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetCard);
