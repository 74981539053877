import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Space, Spin } from 'antd';
import FilterSelect from './FilterSelect';
import planning from 'modules/planning';

import './FiltersContainer.scss';

const FiltersContainer = ({
  onFilter,
  filter,
  filterDimensions,
  fetchProjectionFilters,
  projectionId,
  factorId,
  periodId,
  conventional,
  isRefresh = false,
  setIsRefresh,
  idConcept = false,
}) => {
  useEffect(() => {
    const endpointsParams = {
      ...(projectionId && {
        projection: projectionId,
      }),
      ...(conventional && {
        conventional: conventional,
      }),
      ...(periodId && {
        period: periodId,
      }),
      ...(factorId && {
        factor_id: factorId,
      }),
    };

    fetchProjectionFilters(idConcept && idConcept, endpointsParams)
      .then(() => isRefresh && setIsRefresh())
      .catch(() => {});
  }, [
    fetchProjectionFilters,
    projectionId,
    factorId,
    periodId,
    isRefresh,
    idConcept,
    conventional,
    setIsRefresh,
  ]);

  const onSelectItems = (values, idDimension) =>
    onFilter({ ...filter, [idDimension]: values });

  return (
    <Spin size="small" spinning={isRefresh}>
      <Space className="filter-space">
        {filterDimensions
          .filter(data => data.values.length !== 0)
          .map((dimension, index) => (
            <Col span={4} key={index} style={{ padding: '10px 0px' }}>
              <FilterSelect
                key={dimension.id}
                label={dimension.name}
                dimensionId={dimension.id}
                onChangeSelection={onSelectItems}
                selection={filter}
                options={dimension.values}
              />
            </Col>
          ))}
      </Space>
    </Spin>
  );
};

const mapStateToProps = state => ({
  filterDimensions: planning.selectors.getFilterDimensions(state),
});

const mapDispatchToProps = {
  fetchProjectionFilters: planning.actions.fetchProjectionFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersContainer);
