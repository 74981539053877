import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button,
  Space,
  Popover,
  Divider,
  Input,
  Typography as TypographyAntd,
  Tooltip,
} from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import {
  ButtonActionBar,
  DropdownMenu,
  Typography,
} from 'modules/core/components';

const { TextArea } = Input;
const { Paragraph } = TypographyAntd;

const CommentPopoverContent = ({
  data = [],
  setVisible,
  onComment = null,
  onDelete = null,
  // onResolve = null,
}) => {
  const [newCommentText, setNewCommentText] = useState('');
  const { t } = useTranslation();

  const renderAuthor = ({ author, date }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
        <Typography.Subtitle
          style={{ display: 'block', width: 180, wordWrap: 'break-word' }}
        >
          {author}
        </Typography.Subtitle>
        <Typography.Body level={3}>{`${moment(
          date
        ).calendar()}`}</Typography.Body>
      </div>
    );
  };

  const renderComentIcon = ({ id, showResolve }) => {
    return (
      <Space size={2}>
        {/* TODO: No se muestra hasta que este implementado desde backend */}
        {false && showResolve && (
          <Tooltip title={t('TOOLTIP_CHECK_SOLVED_TITLE')}>
            <Button
              type="text"
              shape="circle"
              icon={<Typography.Icon level={2} icon={CheckOutlined} />}
              // onClick={() => onResolve(id)}
            />
          </Tooltip>
        )}
        {onDelete !== null && (
          <DropdownMenu
            title={t('ACTION_MORE')}
            menu={[
              {
                title: t('ACTION_DELETE'),
                icon: <DeleteOutlined />,
                onClick: () => onDelete(id),
              },
            ]}
          />
        )}
      </Space>
    );
  };

  const renderComment = ({
    comment: { id, author, created_on: date, text },
    isFirstComment,
  }) => (
    <Space key={id} direction="vertical" style={{ width: '100%' }}>
      <ButtonActionBar position="space-between">
        {renderAuthor({ author, date })}
        {/* FIXME: Hacer validación para que estos iconos los pueda ver solo el usuario que hizo el comentario. */}
        {renderComentIcon({ id, showResolve: isFirstComment })}
      </ButtonActionBar>
      <Paragraph
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: t('ACTION_READ_MORE'),
        }}
      >
        {text}
      </Paragraph>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
    </Space>
  );

  const renderReplyArea = () =>
    onComment !== null && (
      <>
        <TextArea
          value={newCommentText}
          placeholder={t('WRITE_COMMENT_PLACEHOLDER')}
          onChange={value => setNewCommentText(value.target.value)}
        />
        <ButtonActionBar>
          <Button
            onClick={() => {
              setVisible(false);
              setNewCommentText('');
            }}
          >
            {t('ACTION_CANCEL')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onComment(newCommentText);
              setNewCommentText('');
            }}
            disabled={!newCommentText}
          >
            {t('ACTION_COMMENT')}
          </Button>
        </ButtonActionBar>
      </>
    );

  return (
    <Space
      direction="vertical"
      style={{
        paddingRight: 12,
        marginRight: -12,
        marginLeft: -8,
        width: 260,
        maxHeight: 300,
        overflowY: 'auto',
      }}
    >
      {data.length > 0 &&
        data.map((comment, index) =>
          renderComment({
            comment,
            isFirstComment: index === 0,
          })
        )}
      {renderReplyArea()}
    </Space>
  );
};

const CommentPopover = ({
  visible,
  onVisibleChange,
  data = [],
  onComment,
  onDelete,
  children,
}) => {
  return (
    <Popover
      visible={visible}
      trigger="click"
      onVisibleChange={visible => onVisibleChange(visible)}
      content={
        <CommentPopoverContent
          setVisible={onVisibleChange}
          data={data}
          onComment={onComment}
          onDelete={onDelete}
        />
      }
    >
      {children}
    </Popover>
  );
};

export default CommentPopover;
