import Immutable from 'seamless-immutable';
import * as types from './constants';

const initialState = Immutable({
  reportList: {
    count: 0,
    reportData: [],
  },
  reportDetail: {
    name: '',
  },
  reportColumns: { columns: [], prev_columns: [] },
  reportKPIs: { grouper_kpis: [], totalizers_kpis: [] },
  reportDetailTable: [],
  reportStructure: {
    name: '',
    values: [],
  },
  reportTableByPeriods: {
    rows: {
      budget: [],
      real: [],
      last_forecast: [],
      prev_real: [],
    },
    lineId: undefined,
    startDate: undefined,
    endDate: undefined,
  },
  customReportColumns: [],
  customReportRows: [],
  //provisorio de esta forma hasta que se trabaje expandableRow.
  reportSegmentedDataRow: {},
  reportSegmentedColumns: {},
  reportVerticalComparisonDataRow: [],
  reportVerticalComparisonColumns: [],
  reportVerticalSegmentedDataRow: {},
  reportVerticalSegmentedColumns: {},
  dimensionTransactionsList: {},
  customReportsList: [],
  customReportsDetail: {},
  dimensionValuesByRow: {},
  customReportGraphData: {
    pie: [],
    bar: [],
  },
  financialReportsList: [],
  financialReportColumns: { columns: [], prev_columns: [] },
  financialReportRows: {
    real: [],
    prev_real: [],
    last_forecast: [],
  },
  financialReportChartData: {
    data: {},
    financialStartDate: undefined,
    financialEndDate: undefined,
  },
  financialReportKPIs: { grouper_kpis: [], totalizers_kpis: [] },
  hasInitialSituation: {},
  reportRuleData: {
    columns: [],
    rows: [],
  },
  reportComparativeMonthsRows: [],
  reportComparativeMonthsColumns: [],
  segregatedAnalysisRows: [],
  segregatedAnalysisColumns: [],
  availableDimensions: {},
});

const addNewNode = (nodes, nodeId, newNode) => {
  let newNodes = [];
  nodes.forEach(node => {
    if (node.id === nodeId) {
      node = {
        ...node,
        children:
          newNode &&
          newNode.map(newNodeAux => {
            return {
              ...newNodeAux,
              level: node.level,
              id: `${node.id}_${newNodeAux.id}_${newNodeAux.concept}`,
            };
          }),
      };
    }
    if (node.id !== nodeId && node.children) {
      node = {
        ...node,
        children: addNewNode(node.children, nodeId, newNode),
      };
    }
    newNodes.push(node);
  });
  return newNodes;
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REPORT_LIST_SUCCESS:
      return state.merge({
        reportList: {
          count: action.payload.data.count,
          reportData: action.payload.data.results,
        },
      });

    case types.FETCH_REPORT_DETAIL_SUCCESS:
      return state.merge({
        reportDetail: action.payload.data,
      });

    case types.FETCH_REPORT_COLUMNS_SUCCESS:
      return state.merge({
        reportColumns: action.payload.data,
      });

    case types.FETCH_REPORT_DATA_SUCCESS:
      const startDate =
        action.meta.previousAction.payload.request.params['start-date'];
      const endDate =
        action.meta.previousAction.payload.request.params['end-date'];
      return state.merge({
        reportKPIs: {
          grouper_kpis: action.payload.data.kpis.grouper_kpis.map(
            (dataKpi, index) => {
              return { ...dataKpi, visible: true, order: index + 1 };
            }
          ),
          totalizers_kpis: action.payload.data.kpis.totalizers_kpis
            .reverse()
            .map((dataKpi, index) => {
              return { ...dataKpi, visible: true, order: index + 1 };
            }),
        },
        reportTableByPeriods: {
          rows: { ...action.payload.data.report_open },
          lineId: undefined,
          startDate,
          endDate,
        },
        reportDetailTable: action.payload.data.report,
      });
    case types.FETCH_REPORT_STRUCTURE_SUCCESS:
      return state.merge({
        reportStructure: {
          name: action.payload.data.name,
          values: action.payload.data.rows,
        },
      });
    case types.FETCH_REPORT_ROW_SUCCESS:
      return state.merge({
        reportDetailTable: addNewNode(
          state.reportDetailTable.asMutable({ deep: true }),
          action.payload.data.parent_id,
          action.payload.data.rows
        ),
      });
    case types.FETCH_CUSTOM_REPORT_COLUMNS_SUCCESS:
      return state.merge({
        customReportColumns: action.payload.data.reporte,
      });
    case types.FETCH_CUSTOM_REPORT_ROW_SUCCESS:
      return state.merge({
        customReportRows: action.payload.data.reporte,
      });
    case types.FETCH_REPORT_OPEN_ROW_SUCCESS:
      const parentId = action.payload.config.params.row_id_front;
      let auxReportTableByPeriods = state.reportTableByPeriods.rows.asMutable({
        deep: true,
      });
      return state.merge({
        reportTableByPeriods: {
          ...state.reportTableByPeriods,
          rows: {
            budget: addNewNode(
              auxReportTableByPeriods.budget,
              parentId,
              action.payload.data.rows.budget
            ),
            real: addNewNode(
              auxReportTableByPeriods.real,
              parentId,
              action.payload.data.rows.real
            ),
            last_forecast: addNewNode(
              auxReportTableByPeriods.last_forecast,
              parentId,
              action.payload.data.rows.last_forecast
            ),
            prev_real: addNewNode(
              auxReportTableByPeriods.prev_real,
              parentId,
              action.payload.data.rows.prev_real
            ),
          },
        },
      });

    case types.FETCH_REPORT_SEGMENTED_ROW_SUCCESS:
      return state.merge({
        reportSegmentedDataRow: action.payload.data,
      });

    case types.FETCH_REPORT_SEGMENTED_COLUMNS_SUCCESS:
      return state.merge({
        reportSegmentedColumns: action.payload.data,
      });

    case types.FETCH_REPORT_VERTICAL_COMPARISON_ROW_SUCCESS:
      return state.merge({
        reportVerticalComparisonDataRow: action.payload.data.reporte,
      });

    case types.FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS_SUCCESS:
      return state.merge({
        reportVerticalComparisonColumns: action.payload.data.reporte,
      });

    case types.FETCH_REPORT_VERTICAL_SEGMENTED_ROW_SUCCESS:
      return state.merge({
        reportVerticalSegmentedDataRow: action.payload.data,
      });

    case types.FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS_SUCCESS:
      return state.merge({
        reportVerticalSegmentedColumns: action.payload.data,
      });

    case types.FETCH_DIMENSION_TRANSACTIONS_LIST_SUCCESS:
      return state.merge({
        dimensionTransactionsList: action.payload.data,
      });

    case types.FETCH_CUSTOM_REPORT_LIST_SUCCESS:
      return state.merge({
        customReportsList: action.payload.data,
      });

    case types.FETCH_CUSTOM_REPORT_DETAIL_SUCCESS:
      return state.merge({
        customReportsDetail: action.payload.data,
      });

    case types.FETCH_DIMENSION_VALUES_BY_ROW_SUCCESS:
      return state.merge({
        dimensionValuesByRow: action.payload.data,
      });

    case types.FETCH_CUSTOM_REPORT_GRAPH_DATA_SUCCESS:
      return state.merge({
        customReportGraphData: {
          bar: action.payload.data.bars,
          pie: action.payload.data.cake,
        },
      });

    case types.EDIT_REPORT_KPIS:
      const kpiKey = action.payload.isGrouper
        ? 'grouper_kpis'
        : 'totalizers_kpis';
      return state.merge({
        reportKPIs: {
          ...state.reportKPIs,
          [kpiKey]: state.reportKPIs[kpiKey].map(dataKpi => {
            return dataKpi.id === action.payload.kpiId
              ? {
                  ...dataKpi,
                  visible: action.payload.visible,
                  order: action.payload.order,
                }
              : dataKpi;
          }),
        },
      });

    case types.FETCH_FINANCIAL_REPORT_LIST_SUCCESS:
      return state.merge({
        financialReportsList: action.payload.data,
      });

    case types.FETCH_FINANCIAL_REPORT_COLUMNS_SUCCESS:
      return state.merge({
        financialReportColumns: action.payload.data,
      });

    case types.FETCH_FINANCIAL_REPORT_ROWS_SUCCESS:
      return state.merge({
        financialReportKPIs: {
          real: action.payload.data.kpis.real.map((dataKpi, index) => {
            return { ...dataKpi, visible: true, order: index + 1 };
          }),
          prev_real: action.payload.data.kpis.prev_real.map(
            (dataKpi, index) => {
              return { ...dataKpi, visible: true, order: index + 1 };
            }
          ),
          last_forecast: action.payload.data.kpis.last_forecast.map(
            (dataKpi, index) => {
              return { ...dataKpi, visible: true, order: index + 1 };
            }
          ),
        },
        financialReportRows: action.payload.data.report_open,
        hasInitialSituation: action.payload.data.has_initial_situation,
      });

    case types.FETCH_FINANCIAL_REPORT_CHART_DATA_SUCCESS:
      const financialStartDate =
        action.meta.previousAction.payload.request.params['start-date'];
      const financialEndDate =
        action.meta.previousAction.payload.request.params['end-date'];
      return state.merge({
        financialReportChartData: {
          data: action.payload.data.graph_data[0],
          financialStartDate,
          financialEndDate,
        },
      });

    case types.SET_REPORT_LINE_SELECTED:
      return state.merge({
        reportTableByPeriods: {
          ...state.reportTableByPeriods,
          lineId: action.payload,
        },
      });

    case types.FETCH_REPORT_RULE_DATA_SUCCESS:
      return state.merge({
        reportRuleData: {
          columns: action.payload.data.columns,
          rows: action.payload.data.rows.map((row, index) => {
            return { ...row, key: index };
          }),
        },
      });

    case types.FETCH_COMPARATIVE_MONTHS_ROWS_SUCCESS:
      return state.merge({
        reportComparativeMonthsRows: action.payload.data.rows,
      });

    case types.FETCH_COMPARATIVE_MONTHS_COLUMNS_SUCCESS:
      return state.merge({
        reportComparativeMonthsColumns: action.payload.data.columns,
      });

    case types.FETCH_SEGREGATED_ANALYSIS_ROWS_SUCCESS:
      return state.merge({
        segregatedAnalysisRows: action.payload.data.rows,
      });

    case types.FETCH_SEGREGATED_ANALYSIS_COLUMNS_SUCCESS:
      return state.merge({
        segregatedAnalysisColumns: action.payload.data.columns,
      });

    case types.FETCH_AVAILABLE_DIMENSIONS_SUCCESS:
      return state.merge({
        availableDimensions: action.payload.data,
      });

    default:
      return state;
  }
};
