import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Alert,
  Radio,
  Typography,
  Space,
  notification,
} from 'antd';
import configuration from 'modules/configuration';

const { Text } = Typography;

const DisplaySettingsModal = ({
  messageAlert,
  dimensionName = '',
  dimensionId,
  displaySettings,
  fetchDisplaySettings,
  createDisplaySettings,
  updateDisplaySettings,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [radioValue, setRadioValue] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchDisplaySettings({ dimension: dimensionId }).then(() =>
      setIsLoading(false)
    );
  }, [fetchDisplaySettings, dimensionId, showSettingsModal]);

  const handleConfirm = () => {
    if (!displaySettings.id) {
      createDisplaySettings({ dimension: dimensionId, field: radioValue })
        .then(() => {
          notification.success({
            message: t('FEEDBACK_CHANGES_SAVED_SUCCESS'),
          });
          setShowSettingsModal(false);
        })
        .catch(error => notification.error(error.message));
    } else {
      updateDisplaySettings(displaySettings.id, { field: radioValue })
        .then(() => {
          notification.success({
            message: t('FEEDBACK_CHANGES_SAVED_SUCCESS'),
          });
          setShowSettingsModal(false);
        })
        .catch(error => notification.error(error.message));
    }
  };

  const handleCancel = () => {
    setRadioValue(null);
    setShowSettingsModal(false);
  };

  return (
    <>
      <Button onClick={() => setShowSettingsModal(true)}>
        {t('CONFIG_SETTINGS_MODAL_TITLE')}
      </Button>
      <Modal
        title={t('CONFIG_SETTINGS_MODAL_TITLE')}
        centered
        visible={showSettingsModal}
        destroyOnClose={true}
        onCancel={handleCancel}
        onOk={handleConfirm}
        okText={t('ACTION_APPLY')}
        cancelText={t('ACTION_CANCEL')}
        okButtonProps={{ disabled: radioValue === null }}
      >
        {!isLoading && (
          <Space direction="vertical" size="large">
            <Alert
              message={
                messageAlert ? messageAlert : t('CONFIG_SETTINGS_MODAL_ALERT')
              }
              type="info"
              showIcon
            />
            <Text>
              {t('CONFIG_SETTINGS_MODAL_TEXT', { dimension: dimensionName })}
            </Text>
            <Radio.Group
              onChange={e => setRadioValue(e.target.value)}
              defaultValue={displaySettings ? displaySettings.field : null}
            >
              {[
                { key: 'both', description: t('CONFIG_SETTINGS_OPTION_BOTH') },
                { key: 'code', description: t('CONFIG_SETTINGS_OPTION_CODE') },
                { key: 'name', description: t('CONFIG_SETTINGS_OPTION_NAME') },
              ].map(option => (
                <Radio
                  key={option.key}
                  value={option.key}
                  style={{
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                  }}
                >
                  {option.description}
                </Radio>
              ))}
            </Radio.Group>
          </Space>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  displaySettings: configuration.selectors.getDisplaySettings(state),
});

const mapDispatchToProps = {
  fetchDisplaySettings: configuration.actions.fetchDisplaySettings,
  createDisplaySettings: configuration.actions.createDisplaySettings,
  updateDisplaySettings: configuration.actions.updateDisplaySettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplaySettingsModal);
