import { Table } from 'antd';
import { ACCES_USERS, ROWS } from 'modules/core/constants';
import { calculateScrollX } from 'modules/core/utils';
import { columnsGenerator } from './columns';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import reports from 'modules/reports';
import login from 'modules/login';

const SegregatedAnalysisTable = ({
  segregatedAnalysisRows,
  segregatedAnalysisColumns,
  segregatedTableSettings,
  fetchSegregatedAnalysisRows,
  fetchSegregatedAnalysisColumns,
  parentsValuesIdsSelected,
  childrensValuesIdsSelected,
  loggedUser: { email },
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  let { periodId, reportId } = useParams();

  const has_ids_selected =
    parentsValuesIdsSelected?.first_dimension &&
    parentsValuesIdsSelected?.second_dimension &&
    childrensValuesIdsSelected?.first_dimension_values?.length > 0 &&
    childrensValuesIdsSelected?.second_dimension_values?.length > 0;

  useEffect(() => {
    if (has_ids_selected) {
      setIsTableLoading(true);
      const endpointParams = {
        period_id: periodId,
        ...parentsValuesIdsSelected,
        ...childrensValuesIdsSelected,
      };
      Promise.all([
        fetchSegregatedAnalysisRows(reportId, endpointParams),
        fetchSegregatedAnalysisColumns(reportId, {
          ...endpointParams,
          show_by: segregatedTableSettings.visualizationColumn,
        }),
      ]).then(() => setIsTableLoading(false));
    }
  }, [
    fetchSegregatedAnalysisRows,
    fetchSegregatedAnalysisColumns,
    childrensValuesIdsSelected,
    parentsValuesIdsSelected,
    segregatedTableSettings.visualizationColumn,
    has_ids_selected,
    periodId,
    reportId,
  ]);

  const has_values_selected =
    childrensValuesIdsSelected?.first_dimension_values?.length > 0 &&
    childrensValuesIdsSelected?.second_dimension_values?.length > 0;

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  return (
    <Table
      rowKey="id"
      size="small"
      columns={columnsGenerator(
        segregatedAnalysisColumns,
        segregatedTableSettings.format,
        has_restriction
      )}
      dataSource={segregatedAnalysisRows}
      rowClassName={record => ROWS.TYPES[record.type]}
      loading={!has_values_selected || isTableLoading}
      scroll={{
        x: calculateScrollX(
          columnsGenerator(
            segregatedAnalysisColumns,
            segregatedTableSettings.format,
            has_restriction
          ),
          10
        ),
        y: 400,
      }}
      pagination={false}
    />
  );
};

const mapStateToProps = state => ({
  segregatedAnalysisRows: reports.selectors.getSegregatedAnalysisRows(state),
  segregatedAnalysisColumns:
    reports.selectors.getSegregatedAnalysisColumns(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchSegregatedAnalysisRows: reports.actions.fetchSegregatedAnalysisRows,
  fetchSegregatedAnalysisColumns:
    reports.actions.fetchSegregatedAnalysisColumns,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SegregatedAnalysisTable);
