import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  message,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  generateSelectOptions,
  isInElaboration,
  isInitiated,
  isPublished,
  isUserAllowed,
} from 'modules/core/utils';
import { PROJECTION, ROLES } from 'modules/core/constants';
import planning from 'modules/planning';

const { PLANNING__CONSOLIDATED__CREATE } = ROLES;

const FormItem = Form.Item;

const CreateConsolidationModal = ({
  periodId,
  text = '',
  projections,
  onFetchConsolidationList,
  createConsolidation,
  fetchProjections,
  consolidationApproved = [],
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    fetchProjections({ type: PROJECTION.BUDGET, period: periodId });
  }, [fetchProjections, periodId]);

  const handleConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true);
        const data = {
          name: values.name,
          period_id: periodId,
          projection_ids: [values.sales, values.expenses],
        };
        createConsolidation(data)
          .then(() => {
            message.success(t('PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK'));
            onFetchConsolidationList().then(() => setIsLoading(false));
            onClose();
          })
          .catch(() => {
            onClose();
            setIsLoading(false);
            message.error(t('PLANNING_COPY_BUDGET_ERROR_FEEDBACK'));
          });
      })
      .catch(e => {});
  };

  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  return (
    <>
      <Tooltip
        title={
          consolidationApproved.length > 0
            ? t('PLANNING_DISABLED_CREATION_TOOLTIP')
            : projections.every(data => isInitiated(data.status))
            ? t('PLANNING_CONSOLIDATION_NOT_DATA_BUDGET_TOOLTIP_TITLE')
            : ''
        }
      >
        <Button
          type="primary"
          onClick={() => setShowModal(true)}
          disabled={
            !isUserAllowed(PLANNING__CONSOLIDATED__CREATE) ||
            consolidationApproved.length > 0 ||
            projections.every(data => isInitiated(data.status)) //NOTE: DESHBAILITO sI ESTAN TODOS LOS PPTOS EN ESTADO INICIADO.
          }
        >
          {`${t('ACTION_CREATE')} ${text}`}
        </Button>
      </Tooltip>
      <Modal
        title={t('PLANNING_CONSOLIDATION_CREATE_FORM_TITLE')}
        centered
        visible={showModal}
        destroyOnClose={true}
        onCancel={onClose}
        onOk={handleConfirm}
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <FormItem
                name="name"
                label={t('FIELD_NAME')}
                rules={[
                  { required: true, message: t('REQUIRED_FIELD') },
                  {
                    max: 80,
                    message: t(' REQUIRED_FIELD_CHARACTERS', { cant: 80 }),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'PLANNING_CONSOLIDATION_CREATE_FORM_INPUT_PLACEHOLDER'
                  )}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="sales"
                label={t('LABEL_SALES')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  notFoundContent={t('PLANNING_CONSOLIDATION_NO_SALES_WARNING')}
                  options={generateSelectOptions({
                    options:
                      projections &&
                      projections.filter(
                        projection =>
                          projection.concept.name === 'Ventas' &&
                          projection.has_transactions &&
                          (isInElaboration(projection.status) ||
                            isPublished(projection.status))
                      ),
                  })}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="expenses"
                label={t('LABEL_EXPENSES')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  notFoundContent={t(
                    'PLANNING_CONSOLIDATION_NO_EXPENSES_WARNING'
                  )}
                  options={generateSelectOptions({
                    options:
                      projections &&
                      projections.filter(
                        projection =>
                          projection.concept.name === 'Gastos' &&
                          projection.has_transactions &&
                          (isInElaboration(projection.status) ||
                            isPublished(projection.status))
                      ),
                  })}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  projections: planning.selectors.getProjections(state),
});

const mapDispatchToProps = {
  createConsolidation: planning.actions.createConsolidation,
  fetchProjections: planning.actions.fetchProjections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateConsolidationModal);
