import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Spin,
  Button,
  Space,
  Row,
  Col,
  Tabs,
  notification,
  Tooltip,
  message,
  Popconfirm,
} from 'antd';
import {
  FileExcelOutlined,
  SolutionOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  DropdownMenu,
  RangePicker,
  projectionSuccessUploadNotice,
  ProjectionUploadModal,
  UploadDraggerScreen,
  PeriodNavigator,
  // FiltersContainer,
  AccessLink,
  LastModificationDate,
} from 'modules/core/components';
import {
  ComparationTable,
  ControlKPIs,
  ProjectionControlTable,
} from './components';
import { SiderLayout } from 'modules/core/layouts';
import {
  isInElaboration,
  isPublished,
  isUserAllowed,
  GA,
  renderPrefix,
} from 'modules/core/utils';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import { ROLES, CONCEPTS, TAB_KEYS } from 'modules/core/constants';
import control from 'modules/control';
import planning from 'modules/planning';
import moment from 'moment';
import _ from 'lodash';

import './ControlDetail.scss';

const {
  CONTROL__REAL__SALES__DELETE,
  CONTROL__REAL__EXPENSES__DELETE,
  CONTROL__REAL__EXPENSES__UPLOAD,
  CONTROL__REAL__SALES__UPLOAD,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const CONTROL__REAL__CONCEPT = {
  [CONCEPTS.KEYS.SALES]: {
    DELETE: CONTROL__REAL__SALES__DELETE,
    UPLOAD: CONTROL__REAL__SALES__UPLOAD,
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    DELETE: CONTROL__REAL__EXPENSES__DELETE,
    UPLOAD: CONTROL__REAL__EXPENSES__UPLOAD,
  },
};

// TODO: Cambiar estado 'approved' por el que realmente corresponda para el estado publicado
const ControlDetail = ({
  fetchProjectionDetail,
  projectionDetail: {
    name,
    status,
    period,
    concept,
    has_transactions,
    approve_date,
  },
  filterTableOptions,
  downloadTemplateXLS,
  uploadProjectionXLS,
  downloadProjectionXLS,
  deleteAllProjectionRows,
  periodDetail,
  fetchPeriodDetail,
  fetchProjectionFilters,
  fetchProjectionRows,
  projectionRows: { lastModificationDate },
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(TAB_KEYS.TAB_KEY_EVOLUTION);
  const [selectedDates, setSelectedDates] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [refetchTransactions, setRefetchTransactions] = useState({
    comparation: false,
    evolution: false,
    kpis: false,
  });
  const [filters, setFilters] = useState({});
  const [refetchFilters, setRefetchFilters] = useState(true);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  const { t } = useTranslation();
  let history = useHistory();
  let { periodId, controlId, conceptName } = useParams();

  useEffect(() => {
    setIsScreenLoading(true);
    Promise.all([
      fetchProjectionDetail(controlId),
      fetchPeriodDetail(periodId),
      fetchProjectionRows(controlId),
    ]).then(() => setIsScreenLoading(false));
  }, [
    fetchProjectionDetail,
    fetchPeriodDetail,
    fetchProjectionRows,
    controlId,
    periodId,
  ]);

  useEffect(() => {
    if (concept && concept.id && refetchFilters) {
      fetchProjectionFilters(concept.id, { projection: controlId, ...filters })
        .then(() => setRefetchFilters(false))
        .catch(() => setRefetchFilters(false));
    }
  }, [fetchProjectionFilters, controlId, concept, refetchFilters, filters]);

  const canUserUpload =
    !isScreenLoading &&
    isUserAllowed(CONTROL__REAL__CONCEPT[concept.name].UPLOAD);
  const canUserDelete =
    !isScreenLoading &&
    isUserAllowed(CONTROL__REAL__CONCEPT[concept.name].DELETE);

  const onSuccessUploadFile = response => {
    projectionSuccessUploadNotice({
      projectionType: 'seguimiento',
      errorFile: response.payload.data.error_file,
    });
    GA.event(GA.ACTIONS.FILE_UPLOAD_REAL);
    fetchProjectionDetail(controlId).then(() => {
      setRefetchTransactions({ comparation: true, evolution: true, kpi: true });
      setRefetchFilters(true);
    });
  };

  const onFailUploadFile = error => {
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_UPLOAD_FILE_ERROR'),
      description: error.response.data,
      duration: 0,
    });
    GA.event(GA.ACTIONS.FILE_UPLOAD_REAL_ERROR);
  };

  const onDeleteAll = () => {
    deleteAllProjectionRows(controlId)
      .then(() => {
        fetchProjectionDetail(controlId).then(() => {
          setShowDeleteAll(false);
          setRefetchFilters(true);
          // TODO: Revisar si es necesario este refetch
          setRefetchTransactions({
            comparation: true,
            evolution: false,
            kpi: false,
          });
          message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
        });
      })
      .catch(() => {
        setShowDeleteAll(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_DATA_ERROR'),
          duration: 0,
        });
      });
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        { name: t('LABEL_CONTROL'), url: `/seguimiento/${periodId}` },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/seguimiento/${periodId}/economico`,
        },
        { name: name ? name : '' },
      ]}
    />
  );

  const renderTopActions = () => (
    <>
      <LastModificationDate lastModificationDate={lastModificationDate} />
      <ButtonActionBar position="space-between">
        <RangePicker
          onSetRange={setSelectedDates}
          periodDate={{
            startDate: periodDetail?.start_date,
            endDate: periodDetail?.end_date,
          }}
          defaultPickerValue={
            !_.isEmpty(periodDetail)
              ? [
                  moment(periodDetail?.start_date),
                  moment(periodDetail?.end_date),
                ]
              : null
          }
          defaultValue={
            !_.isEmpty(selectedDates) && [
              moment(selectedDates['start-date'], 'YYYY-MM-01'),
              moment(selectedDates['end-date'], 'YYYY-MM-01'),
            ]
          }
        />
        <Space size="middle">
          <Tooltip
            title={
              moment(period.end_date).format('YYYY-MM-01') ===
              moment(approve_date).format('YYYY-MM-01')
                ? t('CONTROL_UPLOAD_FILE_TOOLTIP_TITLE')
                : ''
            }
          >
            <Button
              onClick={() => setShowUploadModal(true)}
              type="primary"
              disabled={
                moment(period.end_date).format('YYYY-MM-01') ===
                  moment(approve_date).format('YYYY-MM-01') || !canUserUpload
              }
            >
              {t('ACTION_UPLOAD_FILE')}
            </Button>
          </Tooltip>
          <DropdownMenu
            title={t('ACTION_MORE')}
            menu={[
              {
                title: t('ACTION_ACTIVITIES'),
                icon: <SolutionOutlined />,
                onClick: () =>
                  history.push(
                    `/seguimiento/${periodId}/economico/${conceptName}/actividad/${controlId}`
                  ),
              },
              {
                title: t('ACTION_DOWNLOAD_TEMPLATE'),
                icon: <FileExcelOutlined />,
                onClick: () => {
                  downloadTemplateXLS(
                    controlId,
                    t('CONTOL_TEMPLATE_FILE_NAME', {
                      name: name,
                      period: period.name,
                    })
                  );
                },
              },
              {
                title: t('ACTION_DELETE_ALL'),
                icon: <DeleteOutlined />,
                onClick: () => setShowDeleteAll(true),
                disabled:
                  !canUserDelete ||
                  !(isInElaboration(status) || isPublished(status)),
              },
            ]}
          />
          <Popconfirm
            title={t('POPCONFIRM_DELETE_ALL_PAGES', {
              projectionName: name,
            })}
            placement="right"
            okText={t('ACTION_DELETE')}
            onConfirm={onDeleteAll}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setShowDeleteAll(false)}
            visible={showDeleteAll}
          />
        </Space>
      </ButtonActionBar>
    </>
  );

  // NOTE: Cuando se cambie la UI de la tabla de Comparaciones se pueden usar estos filtros.
  // const renderComparationFilters = () => (
  //   <FiltersContainer
  //     projectionId={controlId}
  //     idConcept={concept.id}
  //     filter={filters}
  //     onFilter={setFilters}
  //     isRefresh={isRefresh}
  //     setIsRefresh={() => setIsRefresh(false)}
  //   />
  // );

  const renderKPIs = () => (
    <ControlKPIs
      periodId={periodId}
      filters={
        filters
        // activeTab === TAB_KEYS.TAB_KEY_EVOLUTION ? filters : filtersComparation
      }
      selectedDates={selectedDates}
      conceptName={concept.name}
      refetch={{
        needRefetch: refetchTransactions.kpi,
        setRefetch: setRefetchTransactions,
      }}
      renderPrefix={defaultPrefix =>
        renderPrefix(functionalCurrencyData, defaultPrefix)
      }
    />
  );

  const renderControlTables = () => (
    <Tabs
      defaultActiveKey={activeTab}
      size="small"
      tabBarExtraContent={<AccessLink linkName={concept.name} />}
      onChange={setActiveTab}
    >
      {/* NOTE: Los filtros entraban en conflicto al ser compartidos, para evitar problemas se pusieron los condicionales en las tabs */}
      <Tabs.TabPane tab={t('FIELD_EVOLUTION')} key={TAB_KEYS.TAB_KEY_EVOLUTION}>
        {activeTab === TAB_KEYS.TAB_KEY_EVOLUTION && (
          <ProjectionControlTable
            periodId={periodId}
            canDelete={
              canUserDelete && (isInElaboration(status) || isPublished(status))
            }
            fetchProjectionDetail={() => fetchProjectionDetail(controlId)}
            refetch={{
              needRefetch: refetchTransactions.evolution,
              setRefetch: setRefetchTransactions,
              filtersRefetch: setRefetchFilters,
            }}
            projectionId={controlId}
            history={history}
            selectedDates={selectedDates}
            download={() =>
              downloadProjectionXLS(
                controlId,
                t('CONTROL_MENSUAL_EVOLUTION_FILE_NAME', {
                  name: name,
                  period: period.name,
                }),
                { ...filters }
              )
            }
            filters={filters}
            filterOptions={filterTableOptions}
            onFilter={newFilters => {
              setFilters(newFilters);
              setRefetchFilters(true);
            }}
            functionalCurrencyData={functionalCurrencyData}
          />
        )}
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={t('FIELD_COMPARISONS')}
        key={TAB_KEYS.TAB_KEY_COMPARATION}
      >
        {activeTab === TAB_KEYS.TAB_KEY_COMPARATION && (
          <ComparationTable
            periodId={periodId}
            periodDetail={periodDetail}
            conceptName={concept.name}
            projectionName={name}
            periodName={period.name}
            refetch={{
              needRefetch: refetchTransactions.comparation,
              setRefetch: setRefetchTransactions,
            }}
            concept={concept.name}
            selectedDates={selectedDates}
            filters={filters}
            filterOptions={filterTableOptions}
            onFilter={newFilters => {
              setFilters(newFilters);
              setRefetchFilters(true);
            }}
            isComparison={activeTab === TAB_KEYS.TAB_KEY_COMPARATION}
            functionalCurrencyData={functionalCurrencyData}
          />
        )}
      </Tabs.TabPane>
    </Tabs>
  );

  return (
    <SiderLayout className="control-detail-container">
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      {!isScreenLoading && has_transactions && (
        <Row gutter={[8, 24]}>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>{renderKPIs()}</Col>
          <Col span={24}>{renderControlTables()}</Col>
        </Row>
      )}
      {!isScreenLoading && !has_transactions && (
        <UploadDraggerScreen
          disabled={!canUserUpload}
          description={t('ECONOMIC_CONTROL_EMPTY_SCREEN_DRAG_TITLE')}
          downloadTemplate={() =>
            downloadTemplateXLS(
              controlId,
              t('CONTOL_TEMPLATE_FILE_NAME', {
                name: name,
                period: period.name,
              })
            )
          }
          uploadFile={fileList => uploadProjectionXLS(controlId, fileList)}
          onSuccessUpload={onSuccessUploadFile}
          onFailUpload={onFailUploadFile}
        />
      )}
      <ProjectionUploadModal
        title={t('ECONOMIC_CONTROL_UPLOAD_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={fileList => uploadProjectionXLS(controlId, fileList)}
        onSuccessUpload={onSuccessUploadFile}
        onFailUpload={onFailUploadFile}
      />
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  projectionDetail: planning.selectors.getProjectionDetail(state),
  kpis: control.selectors.getControlDetailKpis(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
  filterTableOptions: planning.selectors.getFilterTableOptions(state),
  projectionRows: planning.selectors.getProjectionRows(state),
});

const mapDispatchToProps = {
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
  downloadTemplateXLS: planning.actions.downloadTemplateXLS,
  uploadProjectionXLS: planning.actions.uploadProjectionXLS,
  downloadProjectionXLS: planning.actions.downloadProjectionXLS,
  deleteAllProjectionRows: planning.actions.deleteAllProjectionRows,
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  fetchProjectionFilters: planning.actions.fetchProjectionFilters,
  fetchProjectionRows: planning.actions.fetchProjectionRows,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlDetail);
