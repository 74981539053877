import React from 'react';
import { CHARTS } from 'modules/core/constants';
import { ResponsiveBar } from '@nivo/bar';
import ChartContainer from '../ChartContainer/ChartContainer';

const BarChart = ({
  className = '',
  indexBy = '',
  keys = [],
  data = [],
  emptyMessage = '',
  containerHeight,
  axisLeft = {},
  axisBottom = {},
  margin = {},
  ...props
}) => (
  <ChartContainer className={className} style={{ height: containerHeight }}>
    {data.length <= 0 && emptyMessage}
    {data.length > 0 && (
      <ResponsiveBar
        indexBy={indexBy}
        keys={keys}
        data={data}
        colors={CHARTS.COLORS}
        margin={{ top: 20, right: 20, bottom: 50, left: 60, ...margin }}
        groupMode="grouped"
        enableGridX={false}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          ...axisLeft,
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          ...axisBottom,
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 80,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 150,
            itemHeight: 40,
            itemOpacity: 0.75,
            symbolSize: 10,
            symbolShape: 'circle',
          },
        ]}
        {...props}
      />
    )}
  </ChartContainer>
);

export default BarChart;
