import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Spin,
  Menu,
  List,
  Breadcrumb,
  Space,
  Row,
  Col,
} from 'antd';
import { RightCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import {
  ButtonActionBar,
  Typography,
  EmptyScreen,
  Onboarding,
} from 'modules/core/components';
import {
  isUserAllowed,
  getOperationsByKeys,
  getPeriodOperationsKeys,
} from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import planning from 'modules/planning';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import onboardingDashboardSteps from './onboardingDashboardSteps';

import './Dashboard.scss';

const { SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS } = ROLES;

const Dashboard = ({ fetchPeriodList, periodsList, hasDataConfig }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [currentPeriod, setCurrentPeriod] = useState(undefined);
  const { t } = useTranslation();
  let history = useHistory();

  const canGoToConfig = isUserAllowed(getOperationsByKeys([SETTINGS]));
  const canGoToPeriods = isUserAllowed(getPeriodOperationsKeys());

  useEffect(() => {
    setIsScreenLoading(true);
    if (canGoToPeriods) {
      fetchPeriodList().then(() => setIsScreenLoading(false));
    } else {
      setIsScreenLoading(false);
    }
  }, [canGoToPeriods, fetchPeriodList]);

  // TODO: EN VEZ DE ENVIAR A /periodos ABRIR MODAL
  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('DASHBOARD_WELLCOME')}
      description={t('DASHBOARD_EMPTY_STATE_DESCRIPTION')}
      footer={
        <ButtonActionBar>
          {canGoToConfig && (
            <Button
              type="primary"
              onClick={() => history.push(`/configuracion`)}
            >
              {t('DASHBOARD_CONFIG_ACTION')}
            </Button>
          )}
          {canGoToPeriods && (
            <Button type="primary" onClick={() => history.push(`/periodos`)}>
              {t('DASHBOARD_PERIODS_ENTER_ACTION')}
            </Button>
          )}
        </ButtonActionBar>
      }
    />
  );

  const renderPeriodHeader = () => {
    const periodMenu = (
      <Menu
        style={{
          maxHeight: 500,
          overflowY: 'scroll',
          position: 'absolute',
          right: 'auto',
        }}
      >
        {periodsList
          .filter(
            period =>
              period.id !==
              parseInt(currentPeriod ? currentPeriod.id : periodsList[0].id)
          )
          .map(period => (
            <Menu.Item key={period.id} onClick={() => setCurrentPeriod(period)}>
              {period.name}
            </Menu.Item>
          ))}
      </Menu>
    );
    return (
      <Space className="dashboard-header">
        <Typography.Icon icon={CalendarOutlined} />
        <Breadcrumb className="dashboard-period-navigator">
          <Breadcrumb.Item overlay={periodMenu}>
            {currentPeriod ? currentPeriod.name : periodsList[0].name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Space>
    );
  };

  const renderPeriodList = () => {
    let periodId = currentPeriod ? currentPeriod.id : periodsList[0].id;
    const renderData = [
      {
        key: 'planning',
        title: t('LABEL_PLANNING'),
        description: t('PLANNING_ENTER_TITLE'),
        enterLink: () => history.push(`/planificacion/${periodId}/`),
        allowedFor: [PLANNING],
        itemClass: 'planning',
      },
      {
        key: 'control',
        title: t('LABEL_CONTROL'),
        description: t('CONTROL_ENTER_TITLE'),
        enterLink: () => history.push(`/seguimiento/${periodId}/`),
        allowedFor: [CONTROL],
        itemClass: 'control',
      },
      {
        key: 'forecast',
        title: t('LABEL_FORECAST'),
        description: t('FORECAST_ENTER_TITLE'),
        enterLink: () => history.push(`/forecast/${periodId}/`),
        allowedFor: [FORECAST],
        itemClass: 'forecast',
      },
      {
        key: 'reports',
        title: t('LABEL_ANALYSIS'),
        description: t('REPORTS_ENTER_TITLE'),
        enterLink: () => history.push(`/analisis/${periodId}/`),
        allowedFor: [ANALYSIS],
        itemClass: 'reports',
      },
    ];

    return (
      <>
        <Onboarding
          steps={onboardingDashboardSteps(history, periodId)}
          keyIdentifier={'dash'}
        />
        <List
          style={{ paddingLeft: 6 }}
          grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 4 }}
          dataSource={renderData.filter(navItem =>
            isUserAllowed(getOperationsByKeys(navItem.allowedFor))
          )}
          renderItem={data => {
            const { key, title, description, enterLink, itemClass } = data;
            return (
              <List.Item>
                <div className={`${itemClass}`}>
                  <Card
                    hoverable
                    actions={[
                      <ButtonActionBar position="start">
                        <Button type="link" onClick={enterLink}>
                          {t('ACTION_ENTER')}
                          <RightCircleOutlined />
                        </Button>
                      </ButtonActionBar>,
                    ]}
                  >
                    <div className={`dashboard-card ${key}`}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Typography.Headline level={5}>
                            {title}
                          </Typography.Headline>
                        </Col>
                        <Col span={24}>
                          <Typography.Body className="dashboard-card-description">
                            {description}
                          </Typography.Body>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </List.Item>
            );
          }}
        />
      </>
    );
  };

  return (
    <div className="app-dashboard">
      <Spin spinning={isScreenLoading}>
        {!isScreenLoading && periodsList.length > 0 && hasDataConfig && (
          <>
            {renderPeriodHeader()}
            {renderPeriodList()}
          </>
        )}
        {!isScreenLoading &&
          (periodsList.length <= 0 || !hasDataConfig) &&
          renderNoDataScreen()}
      </Spin>
    </div>
  );
};

const mapStateToProps = state => ({
  periodsList: planning.selectors.getPeriods(state),
});

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriods,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
