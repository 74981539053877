export const NAME = 'home';

export const CREATE_CONFIGURATION_ODOO = `${NAME}/CREATE_CONFIGURATION_ODOO`;

export const FETCH_INTEGRATION_LIST = `${NAME}/FETCH_INTEGRATION_LIST`;
export const FETCH_INTEGRATION_LIST_SUCCESS = `${NAME}/FETCH_INTEGRATION_LIST_SUCCESS`;
export const FETCH_INTEGRATION_LIST_FAIL = `${NAME}/FETCH_INTEGRATION_LIST_FAIL`;

export const UPDATE_STATUS = `${NAME}/UPDATE_STATUS`;

export const FETCH_INTEGRATION_TRACKING_LIST = `${NAME}/FETCH_INTEGRATION_TRACKING_LIST`;
export const FETCH_INTEGRATION_TRACKING_LIST_SUCCESS = `${NAME}/FETCH_INTEGRATION_TRACKING_LIST_SUCCESS`;
export const FETCH_INTEGRATION_TRACKING_LIST_FAIL = `${NAME}/FETCH_INTEGRATION_TRACKING_LIST_FAIL`;
