import Immutable from 'seamless-immutable';
import * as types from './constants';
import _ from 'lodash';
import { DIMENSIONS } from 'modules/core/constants';

const setDimensionURL = id =>
  `/dimensions/values/?dimension=${id}&flat=True&writeable=True`;

const initialDisplaySettings = {
  id: null,
  field: null,
};

const initialRoleDimensions = {
  [DIMENSIONS.ACCOUNTS.id]: {
    label: DIMENSIONS.ACCOUNTS.label,
    url: setDimensionURL(DIMENSIONS.ACCOUNTS.id),
    values: [],
  },
  [DIMENSIONS.REPORTS.id]: {
    label: DIMENSIONS.REPORTS.label,
    url: DIMENSIONS.REPORTS.url,
    values: [],
  },
  [DIMENSIONS.REPORTS_CUSTOM.id]: {
    label: DIMENSIONS.REPORTS_CUSTOM.label,
    url: DIMENSIONS.REPORTS_CUSTOM.url,
    values: [],
  },
};

const initialState = Immutable({
  dimensionsList: {
    count: 0,
    dimensions: [],
  },
  dimensionDetail: {},
  dimensionValues: {
    count: 0,
    values: [],
  },
  valuesDimension: {
    count: 0,
    values: [],
  },
  dimensionValuesParents: {
    count: 0,
    valuesParents: [],
  },
  usersList: {
    count: 0,
    values: [],
  },
  userRoles: [],
  userDetail: {},
  variablesList: [],
  variableDetail: {},
  variablesKpis: {},
  behaviourList: [],
  formulasList: { count: 0, results: [] },
  formulaDetail: {},
  modifiersList: { results: [], count: 0 },
  factoresList: {},
  displaySettings: initialDisplaySettings,
  otherReportsList: {
    count: 0,
    data: [],
  },
  otherReportsStructure: {},
  rolesList: {
    count: 0,
    data: [],
  },
  roleOperationsKeys: [],
  roleDimensions: {},
  factoresBehaviours: [],
  currencyList: {
    count: 0,
    data: [],
  },
  onboardingSteps: {},
});

//NOTE: ARMO EL auxChildren POR EL LA NUEVA VISUALIZACION DE LA TABLA DE PLAN DE CUENTAS. ES PARA QUE NO TOME POR DEFECTO EL CHILDREN YA QUE ES UNA TABLA EXPANDIBLE
const renderTableValues = results => {
  return results.map(el => {
    return {
      ...el,
      children: null,
      auxChildren: el.children ? renderTableValues(el.children) : null,
    };
  });
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DIMENSIONS_LIST_SUCCESS:
      return state.merge({
        dimensionsList: {
          dimensions: action.payload.data.results,
          count: action.payload.data.count,
        },
      });

    case types.FETCH_DIMENSION_VALUES_SUCCESS:
      return state.merge({
        dimensionValues: {
          count: action.payload.data.count,
          values: action.payload.data.results.rows
            ? renderTableValues(action.payload.data.results.rows)
            : action.payload.data.results,
        },
      });

    case types.FETCH_VALUES_DIMENSION_SUCCESS:
      return state.merge({
        valuesDimension: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      });

    case types.FETCH_DIMENSION_VALUES_PARENTS_SUCCESS:
      return state.merge({
        dimensionValuesParents: {
          count: action.payload.data.count,
          valuesParents: action.payload.data.results,
        },
      });

    case types.FETCH_DIMENSION_DETAIL_SUCCESS:
      return state.merge({
        dimensionDetail: action.payload.data,
      });

    case types.FETCH_USERS_LIST_SUCCESS:
      return state.merge({
        usersList: {
          count: action.payload.data.count,
          values: action.payload.data.results,
        },
      });

    case types.FETCH_USER_DETAIL_SUCCESS:
      return state.merge({
        userDetail: action.payload.data,
      });

    case types.FETCH_USER_ROLES_SUCCESS:
      return state.merge({
        userRoles: action.payload.data,
      });

    case types.FETCH_DISPLAY_SETTINGS_SUCCESS:
      return state.merge({
        displaySettings: action.payload.data.results[0]
          ? action.payload.data.results[0]
          : initialDisplaySettings,
      });
    case types.FETCH_VARIABLES_LIST_SUCCESS:
      return state.merge({
        variablesList: action.payload.data,
      });
    case types.FETCH_VARIABLE_DETAIL_SUCCESS:
      return state.merge({
        variableDetail: action.payload.data,
      });
    case types.FETCH_VARIABLES_KPI_SUCCESS:
      return state.merge({
        variablesKpis: action.payload.data.kpi,
      });

    case types.SET_VARIABLES_VALUES:
      const { newValue } = action.payload;
      return state.merge({
        variableDetail: {
          ...state.variableDetail,
          values: [
            ...state.variableDetail.values.map(val => {
              if (newValue.date === val.date) {
                return {
                  ...val,
                  amount: newValue.amount,
                };
              }
              return val;
            }),
          ],
        },
      });

    case types.FETCH_BEHAVIOUR_LIST_SUCCESS:
      return state.merge({
        behaviourList: action.payload.data.results,
      });
    case types.FETCH_MODIFIERS_LIST_SUCCESS:
      return state.merge({
        modifiersList: action.payload.data,
      });
    case types.FETCH_FORMULAS_LIST_SUCCESS:
      return state.merge({
        formulasList: action.payload.data,
      });
    case types.FETCH_FORMULA_DETAIL_SUCCESS:
      return state.merge({
        formulaDetail: action.payload.data,
      });
    case types.FETCH_FACTORES_LIST_SUCCESS:
      return state.merge({
        factoresList: action.payload.data,
      });

    case types.FETCH_OTHER_REPORT_LIST_SUCCESS:
      return state.merge({
        otherReportsList: {
          count: action.payload.data.count,
          data: action.payload.data.results,
        },
      });

    case types.FETCH_OTHER_REPORT_STRUCTURE_SUCCESS:
      return state.merge({
        otherReportsStructure: action.payload.data,
      });

    case types.FETCH_ROLES_LIST_SUCCESS:
      return state.merge({
        rolesList: {
          count: action.payload.data.length,
          data: action.payload.data,
        },
      });

    case types.EDIT_ROLE_OPERATIONS:
      return state.merge({
        roleOperationsKeys: action.payload.checked
          ? _.union(state.roleOperationsKeys, action.payload.operationsKeys)
          : _.difference(
              state.roleOperationsKeys,
              action.payload.operationsKeys
            ),
      });

    case types.FETCH_ROLE_DIMENSIONS_LIST_SUCCESS:
      return state.merge({
        roleDimensions: action.payload.data.results.reduce(
          (allDimensions, el) => {
            return {
              ...allDimensions,
              [el.id]: {
                label: el.name,
                url: setDimensionURL(el.id),
                values: [],
              },
            };
          },
          initialRoleDimensions
        ),
      });

    case types.FETCH_ROLE_DIMENSIONS_VALUES_SUCCESS:
      return state.merge({
        roleDimensions: {
          ...state.roleDimensions,
          ...action.payload.data.reduce((roleDims, el) => {
            return {
              ...roleDims,
              [el.id]: {
                ...state.roleDimensions[el.id],
                values: el.values.map(v => v.id),
                isAll: el.isAll,
              },
            };
          }, {}),
        },
      });

    case types.SELECT_ROLE_DIMENSION:
      return state.merge({
        roleDimensions: {
          ...state.roleDimensions,
          [action.payload.data.dimensionId]: {
            ...state.roleDimensions[action.payload.data.dimensionId],
            values: action.payload.data.values,
            selected_all: action.payload.data.selected_all,
          },
        },
      });

    case types.CLEAN_ROLE_DIMENSION:
      return state.merge({
        roleDimensions: _.reduce(
          state.roleDimensions,
          (allDimensions, el, key) => {
            return {
              ...allDimensions,
              [key]: {
                ...el,
                values: [],
              },
            };
          },
          {}
        ),
      });

    case types.FETCH_FACTORES_BEHAVIOURS_SUCCESS:
      return state.merge({
        factoresBehaviours: action.payload.data.results,
      });

    case types.FETCH_CURRENCY_LIST_SUCCESS:
      return state.merge({
        currencyList: {
          count: action.payload.data.count,
          data: action.payload.data.results,
        },
      });

    case types.FETCH_ONBOARDING_STEPS_SUCCESS:
      return state.merge({
        onboardingSteps: {
          config: action.payload.data.config.onboarding.config,
          dash: action.payload.data.config.onboarding.dashboard,
        },
      });

    case types.SET_ONBOARDING_STEP: {
      const { paso, key } = action.payload.request.data;
      return state.merge({
        onboardingSteps: {
          ...state.onboardingSteps,
          [key]: {
            ...state.onboardingSteps.key,
            paso,
          },
        },
      });
    }

    default:
      return state;
  }
};
