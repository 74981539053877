import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumber, Tooltip } from 'antd';

const InputNumberPercentage = ({
  value = 0,
  disabled,
  size = 'small',
  onPressEnter,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('FEEDBACK_INPUT_PERCENTAGE')}>
      <InputNumber
        value={value}
        disabled={disabled}
        size={size}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        onPressEnter={e => {
          onPressEnter(e.target.value.replace('%', '') / 100);
        }}
      />
    </Tooltip>
  );
};

export default InputNumberPercentage;
