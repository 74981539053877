import React from 'react';
import { Button } from 'antd';
import { ButtonActionBar } from 'modules/core/components';
import { useTranslation } from 'react-i18next';

const ButtonOther = ({ dataOther }) => {
  const { t } = useTranslation();

  return (
    <ButtonActionBar>
      <Button type="primary" onClick={dataOther.onClickButtonUpdate}>
        {t('ACTION_UPDATE_DATA')}
      </Button>
    </ButtonActionBar>
  );
};

export default ButtonOther;
