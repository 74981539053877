import React, { useState } from 'react';
import {
  Table,
  Popconfirm,
  Space,
  Button,
  Tooltip,
  message,
  notification,
} from 'antd';
import {
  DropdownMenu,
  InputCell,
  //SelectCell,
  Typography,
} from 'modules/core/components';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';

const { SETTINGS__DIMENSIONS__EDIT, SETTINGS__DIMENSIONS__DELETE } = ROLES;

const DimensionsDetailTable = ({
  tableLoading,
  onTableChange,
  dataSource,
  pagination,
  count,
  onSetRowSelection,
  dataSelection,
  deleteDimensionValue,
  editDimensionValue,
  onFetchDimensionValues,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [deleteDimensionDetailId, setDeleteDimensionDetailId] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const { t } = useTranslation();

  const onCellEdit = (name, value) =>
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });

  const columns = [
    {
      title: t('FIELD_CODE'),
      dataIndex: 'code',
      editable: true,
      width: 200,
      render: (record, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={record}
              onCellUpdate={onCellEdit}
              name="code"
            />
          );
        } else {
          return record;
        }
      },
    },
    {
      title: t('FIELD_DESCRIPTION'),
      dataIndex: 'name',
      editable: true,
      width: 200,
      render: (record, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={record}
              onCellUpdate={onCellEdit}
              name="name"
            />
          );
        } else {
          return record;
        }
      },
    },
    //{
    // TODO: COMENTADO HASTA QUE ESTE LA DEFINICION
    // title: t('FIELD_STATUS'),
    // dataIndex: 'deleted',
    // editable: true,
    // width: 200,
    // render: (deleted, row) =>
    // if (editRow && row.id === editRow) {
    //   return (
    //     <SelectCell
    //       initialValue={
    //         !deleted ? t('STATE_TAG_ACTIVE') : t('STATE_TAG_INACTIVE')
    //       } //faltan los ids
    //       onCellUpdate={onCellEdit}
    //       name={'status'}
    //       options={[
    //         { id: 'active', name: t('STATE_TAG_ACTIVE') },
    //         { id: 'inactive', name: t('STATE_TAG_INACTIVE') },
    //       ]}
    //     />
    //   );
    // } else {
    //   if (!deleted) {
    //     return t('STATE_TAG_ACTIVE');
    //   } else {
    //     return t('STATE_TAG_INACTIVE');
    //   }
    // }
    //},
    {
      title: (
        <Space size="large">
          {t('FIELD_ACTIONS')}
          <Tooltip title={t('CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} type="primary" />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'id',
      align: 'right',
      width: 150,
      render: (record, row) => {
        return record !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_DIMENSIONS_VALUES_POPCONFIRM_DELETE_VALUE_TITLE')}
            okText={t('ACTION_DELETE')}
            onConfirm={() => onDeleteDimensionDetail(record)}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteDimensionDetailId(null)}
            visible={deleteDimensionDetailId === record}
          >
            <Space size="middle">
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={_.compact([
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => {
                      setEditRow(record);
                    },
                    disabled: !isUserAllowed(SETTINGS__DIMENSIONS__EDIT),
                  },
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteDimensionDetailId(record),
                    disabled: !isUserAllowed(SETTINGS__DIMENSIONS__DELETE),
                  },
                ])}
              />
            </Space>
          </Popconfirm>
        ) : (
          <Space direction="horizontal" size="middle">
            {!_.isEmpty(dataCellEdit) ? (
              <Popconfirm
                placement="bottomRight"
                title={t('POPCONFIRM_EDIT_CANCEL')}
                okText={t('YES')}
                onConfirm={() => {
                  setEditRow(null);
                  setDataCellEdit({});
                }}
                cancelText={t('NO')}
              >
                <Button type="link">{t('ACTION_CANCEL')}</Button>
              </Popconfirm>
            ) : (
              <Button type="link" onClick={() => setEditRow(null)}>
                {t('ACTION_CANCEL')}
              </Button>
            )}
            <Button
              type="link"
              onClick={onEditDimensionDetail}
              disabled={_.isEmpty(dataCellEdit)}
            >
              {t('ACTION_SAVE_CHANGES')}
            </Button>
          </Space>
        );
      },
    },
  ];

  const onEditDimensionDetail = () => {
    if (!_.isEmpty(dataCellEdit)) {
      setIsTableLoading(true);
      editDimensionValue(editRow, dataCellEdit)
        .then(() =>
          onFetchDimensionValues().then(() => {
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
            setEditRow(null);
            setDataCellEdit({});
            setIsTableLoading(false);
          })
        )
        .catch(() => {
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          });
          setEditRow(null);
          setDataCellEdit({});
          setIsTableLoading(false);
        });
    } else {
      setEditRow(null);
      setDataCellEdit({});
    }
  };

  const onDeleteDimensionDetail = id => {
    setIsTableLoading(true);
    deleteDimensionValue(id)
      .then(() =>
        onFetchDimensionValues().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          setIsTableLoading(false);
          setDeleteDimensionDetailId(null);
        })
      )
      .catch(() => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_FAIL'),
          duration: 0,
        });
        setIsTableLoading(false);
        setDeleteDimensionDetailId(null);
      });
  };

  return (
    <Table
      rowKey="id"
      bordered
      size="small"
      rowSelection={{
        selectedRowKeys: dataSelection,
        onChange: value => onSetRowSelection(value),
      }}
      loading={tableLoading || isTableLoading}
      onChange={onTableChange}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize: pagination.page_size,
        current: pagination.page,
        total: count,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  editDimensionValue: configuration.actions.editDimensionValue,
  deleteDimensionValue: configuration.actions.deleteDimensionValue,
};

export default connect(null, mapDispatchToProps)(DimensionsDetailTable);
