import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  EmptyScreen,
  ButtonActionBar,
  Typography,
} from 'modules/core/components';
import {
  Spin,
  Row,
  Col,
  List,
  message,
  Popover,
  Button,
  Typography as TypographyAnt,
  Tooltip,
} from 'antd';
import { ProjectionCard } from '../';
import { CreateBudgetModal } from 'modules/forecast/components';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import planning from 'modules/planning';
import login from 'modules/login';
import { useParams, useHistory } from 'react-router-dom';
import { CreateConsolidationModal } from './components';
import { useTranslation } from 'react-i18next';
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons';
import {
  isApproved,
  isUserAllowed,
  isPublished,
  renderPrefix,
} from 'modules/core/utils';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import { ROLES } from 'modules/core/constants';

const {
  FORECAST__CONSOLIDATED__CREATE,
  FORECAST__CONSOLIDATED__APPROVE,
  FORECAST__CONSOLIDATED__PUBLISH,
} = ROLES;

const ConsolidationList = ({
  consolidationList,
  fetchConsolidationList,
  setDefaultBudget,
  periodDetail,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isListLoading, setIsListLoading] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [duplicateData, setDuplicateDate] = useState(null);
  const functionalCurrencyData = useFunctionalCurrency();
  let { periodId, forecastId } = useParams();
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    fetchConsolidationList({ forecast: forecastId }).then(() =>
      setIsScreenLoading(false)
    );
  }, [fetchConsolidationList, forecastId]);

  const refreshList = () => {
    setIsListLoading(true);
    fetchConsolidationList({ forecast: forecastId }).then(() =>
      setIsListLoading(false)
    );
  };

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_CONSOLIDATION_EMPTY_SCREEN_TITLE')}
      footer={
        <CreateConsolidationModal
          periodId={periodId}
          forecastId={forecastId}
          onFetchConsolidationList={refreshList}
          text={t('LABEL_CONSOLIDATION').toLowerCase()}
        />
      }
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <CreateConsolidationModal
        consolidationApproved={consolidationList.filter(consolidation =>
          isApproved(consolidation.status)
        )}
        periodId={periodId}
        forecastId={forecastId}
        onFetchConsolidationList={refreshList}
        text={t('LABEL_NEW').toLowerCase()}
      />
    </ButtonActionBar>
  );

  const onSetDefaultBudget = id => {
    setDefaultBudget(id).then(() => {
      message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
    });
  };

  const renderButtonFix = (isFixed, setFixed) => {
    return (
      <Button
        type="text"
        shape="circle"
        style={isFixed ? { cursor: 'default' } : {}}
        icon={
          <Typography.Icon icon={isFixed ? PushpinFilled : PushpinOutlined} />
        }
        onClick={!isFixed && setFixed}
      />
    );
  };

  const renderFixConsolidated = ({ isFixed, setFixed, status }) => {
    return (
      ((setFixed && isPublished(status)) || (isFixed && isApproved(status))) &&
      isUserAllowed([
        FORECAST__CONSOLIDATED__CREATE,
        FORECAST__CONSOLIDATED__APPROVE,
        FORECAST__CONSOLIDATED__PUBLISH,
      ]) &&
      (isFixed ? (
        <Popover
          placement="bottom"
          title={t('FIELD_PREDETERMINED_BUDGET')}
          content={
            <TypographyAnt.Paragraph style={{ width: 300 }}>
              {t('PLANNING_CONSOLIDATION_BUDGET_FIXED_POPOVER_TEXT')}
            </TypographyAnt.Paragraph>
          }
        >
          {renderButtonFix(isFixed, setFixed)}
        </Popover>
      ) : (
        <Tooltip title={t('PLANNING_CONSOLIDATION_INFORMATIVE_TOOLTIP_TITLE')}>
          {renderButtonFix(isFixed, setFixed)}
        </Tooltip>
      ))
    );
  };

  const renderConsolidationtList = () => (
    <List
      style={{ paddingLeft: 6 }}
      loading={isListLoading}
      grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 3, xxl: 3 }}
      dataSource={consolidationList}
      renderItem={budget => {
        const { id, name, status } = budget;
        return (
          <List.Item>
            <ProjectionCard
              budgetId={id}
              title={name}
              status={status}
              extraFooter={renderFixConsolidated({
                isFixed: periodDetail.conso_forecast_predef
                  ? periodDetail.conso_forecast_predef.id === id
                  : null,
                setFixed:
                  consolidationList.filter(conso => isApproved(conso.status))
                    .length === 0
                    ? () => onSetDefaultBudget(id)
                    : null,
                status: status,
              })}
              onDuplicate={budgetName => {
                setDuplicateDate({
                  id: id,
                  name: t('COPY_NAME', { name: budgetName }),
                });
                setShowCopyModal(true);
              }}
              onOpen={() =>
                history.push(
                  `/forecast/${periodId}/${forecastId}/consolidacion/${id}`
                )
              }
              reloadList={refreshList}
              renderPrefix={defaultPrefix =>
                renderPrefix(functionalCurrencyData, defaultPrefix)
              }
            />
          </List.Item>
        );
      }}
    />
  );

  return (
    <Spin spinning={isScreenLoading} size="large">
      {!isScreenLoading && (
        <Row gutter={[24, 24]}>
          {consolidationList.length <= 0 && (
            <Col span={24}>{renderNoDataScreen()}</Col>
          )}
          {consolidationList.length > 0 && (
            <>
              <Col span={24}>{renderTopActions()}</Col>
              <Col span={24}>{renderConsolidationtList()}</Col>
            </>
          )}
        </Row>
      )}
      <CreateBudgetModal
        visible={showCopyModal}
        onCancel={() => {
          setShowCopyModal(false);
          setDuplicateDate(null);
        }}
        copyData={duplicateData}
        onSuccessCreate={refreshList}
        forecastId={forecastId}
      />
    </Spin>
  );
};

const mapStateToProps = state => ({
  consolidationList: planning.selectors.getConsolidationList(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchConsolidationList: planning.actions.fetchConsolidationList,
  setDefaultBudget: planning.actions.setDefaultBudget,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsolidationList);
