import { categoricalColorSchemes } from '@nivo/colors';
import { toJpeg, toPng } from 'html-to-image';

const PIE = 'pie';
const BAR = 'bar';
const LINE = 'line';

const TYPES = {
  PIE: PIE,
  BAR: BAR,
  LINE: LINE,
};

const VALUE = 'value';
const NAME_VALUE = 'name_value';
const NAME = 'name';
const LABEL_FORMAT = {
  NAME,
  VALUE,
  NAME_VALUE,
};
const LABEL_FORMAT_LIST = [VALUE, NAME_VALUE, NAME];

const POSITION_OUTER = 'outer';
const POSITION_INNER = 'inner';
const LABEL_POSITION = [POSITION_OUTER, POSITION_INNER];

const VALUE_PERCENTAGE = 'percentage';
const VALUE_AMOUNT = 'amount';
const VALUE_FORMAT = {
  PERCENTAGE: VALUE_PERCENTAGE,
  AMOUNT: VALUE_AMOUNT,
};
const VALUE_FORMAT_LIST = [VALUE_PERCENTAGE, VALUE_AMOUNT];

const MODE_GROUPED = 'grouped';
const MODE_STACKED = 'stacked';
const BAR_GROUP_MODE = [MODE_GROUPED, MODE_STACKED];

const SETTINGS = {
  LABEL_FORMAT_LIST: LABEL_FORMAT_LIST,
  POSITION_INNER: POSITION_INNER,
  POSITION_OUTER: POSITION_OUTER,
  LABEL_POSITION: LABEL_POSITION,
  VALUE_FORMAT_LIST: VALUE_FORMAT_LIST,
  BAR_GROUP_MODE: BAR_GROUP_MODE,
};

const I18N = {
  [VALUE]: 'FIELD_VALUE',
  [NAME_VALUE]: 'LABEL_NAME_VALUE',
  [NAME]: 'FIELD_NAME',
  [POSITION_INNER]: 'LABEL_INNER',
  [POSITION_OUTER]: 'LABEL_OUTER',
  [VALUE_PERCENTAGE]: 'FIELD_PERCENTAGE',
  [VALUE_AMOUNT]: 'FIELD_AMOUNT',
  [MODE_GROUPED]: 'LABEL_GROUPED',
  [MODE_STACKED]: 'LABEL_STACKED',
  [PIE]: 'CHART_TYPE_PIE',
  [BAR]: 'CHART_TYPE_BAR',
  [LINE]: 'CHART_TYPE_LINE',
};

//TODO: Definir con UX/UI paleta de colores para graficos.
const COLORS_PLIKA = ['#00AA63', '#98C4ED', '#2065C7', '#64D199'];
const COLORS = [
  ...categoricalColorSchemes.nivo,
  ...categoricalColorSchemes.pastel1,
  ...categoricalColorSchemes.set3,
  ...COLORS_PLIKA,
];

const EXPORT_FORMAT_TYPE_OPTIONS = [
  { id: 'png', name: 'PNG', onAction: toPng },
  {
    id: 'jpeg',
    name: 'JPG',
    onAction: toJpeg,
    props: { backgroundColor: 'white' },
  },
];

const CHARTS = {
  COLORS,
  I18N,
  TYPES,
  SETTINGS,
  LABEL_FORMAT,
  VALUE_FORMAT,
  EXPORT_FORMAT_TYPE_OPTIONS,
};

export default CHARTS;
