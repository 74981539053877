import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Space,
  Button,
  Select,
  Spin,
  Tabs,
  Tooltip,
  Popconfirm,
  message,
  notification,
} from 'antd';
import numeral from 'numeral';
import { SiderLayout } from 'modules/core/layouts';
import {
  ButtonActionBar,
  DropdownMenu,
  Typography,
  KPIValue,
  PeriodNavigator,
  ScreenWellDone,
  References,
  ScenarioDrawer,
} from 'modules/core/components';
import { ActionModal, ReportTable } from './components';
import { CreateBudgetModal } from 'modules/planning/components';
import {
  CopyOutlined,
  DownloadOutlined,
  ReloadOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import reports from 'modules/reports';
import planning from 'modules/planning';
import {
  generateSelectOptions,
  isApproved,
  isPublished,
  isUserAllowed,
  renderPrefix,
} from 'modules/core/utils';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import { ROLES, PROJECTION, CONCEPTS } from 'modules/core/constants';
import _ from 'lodash';

import './ConsolidationDetail.scss';

const {
  PLANNING__CONSOLIDATED__APPLY_VARS,
  PLANNING__CONSOLIDATED__DUPLICATE,
  PLANNING__CONSOLIDATED__APPROVE,
  PLANNING__CONSOLIDATED__PUBLISH,
} = ROLES;

const ConsolidationDetail = ({
  consolidationDetail,
  conceptsList,
  projections,
  reportList,
  consolidationKPIS,
  fetchProjections,
  fetchConceptsList,
  fetchProjectionDetail,
  fetchReportList,
  fetchConsolidationDetailKPI,
  changeBudget,
  downloadEvolutionXLS,
  approveProjection,
  updateConsolidated,
  setDefaultBudget,
  publishConsolidated,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isKpisLoading, setIsKpisLoading] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showSimulationDrawer, setShowSimulationDrawer] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableId, setTableId] = useState(undefined);
  const [showActionModal, setShowActionModal] = useState(null);
  const [showScreenSuccess, setShowScreenSuccess] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [valueSelectBudget, setValueSelectBudget] = useState({
    newId: null,
    conceptId: null,
  });
  const [reportsData, setReportsData] = useState([]);
  const functionalCurrencyData = useFunctionalCurrency();

  let { periodId, consolidationId } = useParams();
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    setIsKpisLoading(true);
    fetchConsolidationDetailKPI(consolidationId).then(() =>
      setIsKpisLoading(false)
    );
  }, [fetchConsolidationDetailKPI, consolidationId, consolidationDetail]);

  useEffect(() => {
    setIsScreenLoading(true);
    Promise.all([
      fetchProjectionDetail(consolidationId),
      fetchProjections({ period: periodId, type: PROJECTION.BUDGET }),
      fetchConceptsList(),
      fetchReportList({
        period_id: periodId,
        open: true,
        consolidated_id: consolidationId,
      }),
    ]).finally(() => setIsScreenLoading(false));
  }, [
    periodId,
    consolidationId,
    fetchProjections,
    fetchReportList,
    fetchProjectionDetail,
    fetchConceptsList,
  ]);

  useEffect(() => {
    const auxDataReport = _.compact(
      reportList.reportData.map(report => {
        return report.has_config && report.has_data ? { ...report } : null;
      })
    );
    if (auxDataReport.length > 0) setTableId(auxDataReport[0].id);
    setReportsData(auxDataReport);
  }, [reportList]);

  const isConsolidationApproved = isApproved(consolidationDetail.status);
  const isActionApprove = isApproved(showActionModal);
  const userCanPublish = isUserAllowed(PLANNING__CONSOLIDATED__PUBLISH);
  const userCanApprove = isUserAllowed(PLANNING__CONSOLIDATED__APPROVE);
  const userCanApply = isUserAllowed(PLANNING__CONSOLIDATED__APPLY_VARS);
  const userCanDuplicate = isUserAllowed(PLANNING__CONSOLIDATED__DUPLICATE);

  const handleBudgetChange = value => {
    setIsTableLoading(true);
    changeBudget(consolidationId, { budget_id: value })
      .then(() => {
        setIsTableLoading(true);
        fetchProjectionDetail(consolidationId).then(() => {
          setIsTableLoading(false);
          setValueSelectBudget({ newId: null, conceptId: null });
        });
      })
      .catch(() => {
        setIsTableLoading(false);
        setValueSelectBudget({ newId: null, conceptId: null });
      });
  };

  const renderSelectors = () => (
    <ButtonActionBar position="start">
      {/* TODO-NOTE: FILTRADO MOMENTANEO HASTA QUE SE DEFINA EL MODULO PLANIFICACION PARA MODULO FINANCIERO */}
      {conceptsList.values
        .filter(concept => concept.type === CONCEPTS.TYPE.ECONOMIC_KEY)
        .map((concept, index) => (
          <Popconfirm
            key={index}
            placement="bottomLeft"
            title={t('PLANNING_CONSOLIDATION_MODIFY_BUDGET_POPCONFIRM_TITLE')}
            okText={t('ACTION_MODIFY')}
            onConfirm={() => handleBudgetChange(valueSelectBudget.newId)}
            okButtonProps={{ loading: isTableLoading }}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() =>
              setValueSelectBudget({ newId: null, conceptId: null })
            }
            visible={valueSelectBudget.conceptId === concept.id}
          >
            <Select
              style={{ width: 200 }}
              placeholder={t('ACTION_SELECT')}
              key={concept.id}
              onChange={value =>
                setValueSelectBudget({ newId: value, conceptId: concept.id })
              }
              value={
                valueSelectBudget.newId &&
                valueSelectBudget.conceptId === concept.id
                  ? valueSelectBudget.newId
                  : consolidationDetail.budgets_ids?.length > 0 &&
                    consolidationDetail.budgets_ids.filter(
                      object => object[concept.id]
                    )[0][concept.id]
              }
              disabled={isConsolidationApproved}
              options={generateSelectOptions({
                options: projections.filter(
                  dataProjection =>
                    dataProjection.concept.id === concept.id &&
                    dataProjection.has_transactions
                ),
              })}
            />
          </Popconfirm>
        ))}
    </ButtonActionBar>
  );

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_PLANNING'),
          url: `/planificacion/${periodId}/?tab=consolidacion`,
        },
        {
          name: !_.isEmpty(consolidationDetail) ? consolidationDetail.name : '',
        },
      ]}
    />
  );

  const onUpdateConsolidated = id => {
    setIsKpisLoading(true);
    setIsUpdating(true);
    updateConsolidated(id)
      .then(() =>
        Promise.all([
          fetchConsolidationDetailKPI(consolidationId),
          fetchProjectionDetail(consolidationId),
          fetchReportList({
            period_id: periodId,
            open: true,
            consolidated_id: consolidationId,
          }),
        ]).then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
          setIsKpisLoading(false);
          setIsUpdating(false);
        })
      )
      .catch(() => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
        });
        setIsKpisLoading(false);
        setIsUpdating(false);
      });
  };

  const renderUserActions = () => (
    <ButtonActionBar position="space-between">
      <>
        <Tooltip
          title={
            consolidationDetail.has_new_data && !isConsolidationApproved
              ? t('PLANNING_CONSOLIDATION_UPDATE_DATA_TOOLTIP_TITLE')
              : t('PLANNING_CONSOLIDATION_NOT_UPDATE_DATA_TOOLTIP_TITLE')
          }
        >
          <Button
            type="text"
            shape="circle"
            title={t('ACTION_UPDATE_DATA')}
            icon={<Typography.Icon icon={ReloadOutlined} />}
            onClick={() => onUpdateConsolidated(consolidationId)}
            loading={isUpdating}
            disabled={
              isUpdating ||
              !consolidationDetail.has_new_data ||
              isConsolidationApproved
            }
          />
        </Tooltip>
        <Space direction="horizontal">
          <Button
            type="primary"
            ghost
            onClick={() => setShowActionModal(PROJECTION.STATUS.PUBLISHED)}
            disabled={
              isPublished(consolidationDetail.status) ||
              isConsolidationApproved ||
              !userCanPublish
            }
          >
            {isPublished(consolidationDetail.status)
              ? t('STATE_TAG_PUBLISHED')
              : t('ACTION_PUBLISH')}
          </Button>
          <Tooltip
            title={
              !isPublished(consolidationDetail.status) &&
              !isConsolidationApproved
                ? t('TOOLTIP_NOT_APPROVE_TITLE')
                : ''
            }
          >
            <Button
              type="primary"
              onClick={() => setShowActionModal(PROJECTION.STATUS.APPROVED)}
              disabled={
                !userCanApprove ||
                isConsolidationApproved ||
                !isPublished(consolidationDetail.status)
              }
            >
              {isConsolidationApproved
                ? t('STATE_TAG_APPROVED')
                : t('ACTION_APPROVE')}
            </Button>
          </Tooltip>
          <Button
            onClick={() => setShowSimulationDrawer(true)}
            disabled={!userCanApply || isConsolidationApproved}
          >
            {t('ACTION_STAGE')}
          </Button>
          <DropdownMenu
            title={t('ACTION_MORE')}
            menu={[
              {
                title: t('ACTION_COPY'),
                icon: <CopyOutlined />,
                onClick: () => setShowCopyModal(true),
                disabled: !userCanDuplicate,
              },
            ]}
          />
        </Space>
      </>
    </ButtonActionBar>
  );

  const renderKpis = () => (
    <Row gutter={24}>
      {isKpisLoading &&
        [1, 2, 3].map(index => (
          <Col span={8} key={index}>
            <Card loading={true} />
          </Col>
        ))}
      {!isKpisLoading &&
        consolidationKPIS.map((kpi, index) => (
          <Col span={8} key={index}>
            <Card className="consolidation-kpis" data-type={kpi.title}>
              <Row gutter={[24, 24]} align="middle">
                <Col span={9}>
                  <KPIValue
                    size="medium"
                    fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                    value={
                      kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'
                    }
                    prefix={renderPrefix(functionalCurrencyData)}
                  />
                  <Typography.Body level={3} type="secondary">
                    {kpi.title}
                  </Typography.Body>
                </Col>
                {kpi.variation && (
                  <Col style={{ paddingLeft: 0 }} span={4}>
                    <KPIValue
                      isVariation={true}
                      value={kpi.variation ? kpi.variation : '-'}
                      type="secondary"
                    />
                  </Col>
                )}
                <Col span={11}>
                  {kpi.children &&
                    kpi.children.map((dataChildren, index) => (
                      <Row gutter={24} key={index}>
                        <Col span={18}>
                          <Space direction="vertical" size="small">
                            <KPIValue
                              fullValue={
                                dataChildren.value &&
                                numeral(dataChildren.value).format('0,0.00')
                              }
                              value={
                                dataChildren.value
                                  ? numeral(dataChildren.value).format('0[.]0a')
                                  : '-'
                              }
                              prefix={renderPrefix(functionalCurrencyData)}
                            />
                            <Typography.Body level={3} type="secondary">
                              {dataChildren.title}
                            </Typography.Body>
                          </Space>
                        </Col>
                        {dataChildren.variation && (
                          <Col span={6}>
                            <KPIValue
                              isVariation={true}
                              value={
                                dataChildren.variation
                                  ? dataChildren.variation
                                  : '-'
                              }
                              type="secondary"
                            />
                          </Col>
                        )}
                      </Row>
                    ))}
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
    </Row>
  );

  const handleVariablesApply = id => {
    if (consolidationId !== id)
      history.push(`/planificacion/${periodId}/consolidacion/${id}`);
    setIsTableLoading(true);
    fetchProjectionDetail(id).then(() => setIsTableLoading(false));
  };

  const renderReportsTables = () => (
    <>
      <Tabs
        defaultActiveKey={
          reportsData.length > 0 ? reportsData[0].id : undefined
        }
        onChange={key => setTableId(parseInt(key))}
        size="small"
      >
        {reportsData.length > 0 &&
          reportsData
            .filter(report => report.has_data)
            .map(report => <Tabs.TabPane tab={report.name} key={report.id} />)}
      </Tabs>
      <Spin spinning={isTableLoading || isUpdating} />
      {tableId && !isTableLoading && (
        <>
          <ButtonActionBar position="space-between">
            <References
              referencesItems={[
                {
                  color: '#a73ba1',
                  type: 'Ab',
                  title: t('LABEL_RATIO'),
                  description: t('REPORT_VALUES_RATIO'),
                },
                ...(functionalCurrencyData
                  ? [
                      {
                        type: <DollarOutlined />,
                        title:
                          functionalCurrencyData && functionalCurrencyData.code,
                        description:
                          functionalCurrencyData &&
                          t('REFERENCE_DESCRIPTION', {
                            currencyName: t(
                              functionalCurrencyData.name
                            ).toLowerCase(),
                          }),
                      },
                    ]
                  : []),
              ]}
            />
            <Button
              type="text"
              shape="circle"
              icon={<Typography.Icon icon={DownloadOutlined} />}
              onClick={() =>
                downloadEvolutionXLS(
                  tableId,
                  {
                    period_id: periodId,
                    consolidated_id: consolidationId,
                  },
                  t('PLANNING_CONSOLIDATION_EVOLUTION_TABLE_FILE_NAME', {
                    consoName: consolidationDetail.name,
                    reportName: reportList.reportData.filter(
                      report => report.id === tableId
                    )[0].name,
                    periodName: consolidationDetail.period.name,
                  })
                )
              }
              title={t('ACTION_DOWNLOAD_TABLE')}
            />
          </ButtonActionBar>
          <ReportTable
            reportId={tableId}
            period={periodId}
            consolidated={consolidationId}
            tableLoading={isTableLoading || isUpdating}
          />
        </>
      )}
    </>
  );

  const onSetDefaultBudget = id => {
    setDefaultBudget(id).then(() => {
      setShowScreenSuccess(null);
      message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
    });
  };

  return (
    <SiderLayout className="container-consolidation-detail">
      {renderHeader()}
      <Spin spinning={isScreenLoading} />
      {consolidationDetail && !isScreenLoading && (
        <>
          {!showScreenSuccess && (
            <>
              <Row gutter={[8, 24]}>
                <Col span={24}>{renderUserActions()}</Col>
                <Col span={24}>{renderSelectors()}</Col>
                <Col span={24}>{renderKpis()}</Col>
                <Col span={24}>
                  {reportList.count > 0 && renderReportsTables()}
                </Col>
              </Row>
              <CreateBudgetModal
                visible={showCopyModal}
                onCancel={() => setShowCopyModal(false)}
                copyData={{
                  id: consolidationId,
                  name: t('COPY_NAME', {
                    name: consolidationDetail && consolidationDetail.name,
                  }),
                }}
              />
              <ActionModal
                consolidationId={consolidationId}
                isActionApprove={isActionApprove}
                visible={showActionModal}
                onAction={
                  isActionApprove ? approveProjection : publishConsolidated
                }
                onFetchDetail={() => fetchProjectionDetail(consolidationId)}
                onConfirm={() => setShowScreenSuccess(showActionModal)}
                onClose={() => setShowActionModal(null)}
              />
            </>
          )}
          {showScreenSuccess && (
            <ScreenWellDone
              title={t('FEEDBACK_WELLDONE')}
              description={t('PLANNING_SCREEN_SUCCESS_FEEDBACK', {
                period: consolidationDetail.period.name,
                action: isActionApprove
                  ? t('ACTION_APPROVE').toLowerCase()
                  : t('ACTION_PUBLISH').toLowerCase(),
              })}
              onConfirm={() => setShowScreenSuccess(null)}
              fixButton={
                !isConsolidationApproved && (
                  <Tooltip
                    title={t(
                      'PLANNING_CONSOLIDATION_INFORMATIVE_TOOLTIP_TITLE'
                    )}
                  >
                    <Button onClick={() => onSetDefaultBudget(consolidationId)}>
                      {t('ACTION_FIX')}
                    </Button>
                  </Tooltip>
                )
              }
            />
          )}
        </>
      )}
      {consolidationId && showSimulationDrawer && (
        <ScenarioDrawer
          visible={showSimulationDrawer}
          onClose={() => setShowSimulationDrawer(false)}
          selected={consolidationDetail.variables}
          budgetVarSelected={consolidationDetail.budgets_vars_ids}
          budgetId={consolidationId}
          type={consolidationDetail.type}
          onApplySuccess={handleVariablesApply}
        />
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  projections: planning.selectors.getProjections(state),
  conceptsList: planning.selectors.getConceptsList(state),
  consolidationDetail: planning.selectors.getProjectionDetail(state),
  reportList: reports.selectors.getReportList(state),
  consolidationKPIS: planning.selectors.getConsolidationKPIS(state),
});

const mapDispatchToProps = {
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
  fetchProjections: planning.actions.fetchProjections,
  fetchConceptsList: planning.actions.fetchConceptsList,
  fetchReportList: reports.actions.fetchReportList,
  fetchConsolidationDetailKPI: planning.actions.fetchConsolidationDetailKPI,
  changeBudget: planning.actions.changeBudget,
  downloadEvolutionXLS: reports.actions.downloadEvolutionXLS,
  approveProjection: planning.actions.approveProjection,
  updateConsolidated: planning.actions.updateConsolidated,
  setDefaultBudget: planning.actions.setDefaultBudget,
  publishConsolidated: planning.actions.publishConsolidated,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidationDetail);
