const distributions = {
  DISTRIBUTION_LABEL: 'Distribuciones',
  DISTRIBUTION_NO_DATA_ALERT_TITLE:
    'Todavía no tenemos todos los datos que necesitamos para que puedas crear tus distribuciones',
  DISTRIBUTION_NO_DATA_ALERT_TEXT:
    'Para que puedas crear distribuciones debemos tener datos reales aprobados. Una vez que lo hagas, podrás crear todas las distribuciones que consideres necesarias.',
  DISTRIBUTION_EMPTY_STATE_TITLE: '¡Creá tu primer distribución!',
  DISTRIBUTION_EMPTY_STATE_TEXT:
    'Realizá registros que te permitan distribuir y asignar correctamente tanto tus gastos como tus ventas',
  DISTRIBUTION_CREATE_ACTION: 'Crear distribución',
  DISTIBUTION_CREATE_MODAL_TITLE: 'Creación de distribución',
  DISTIBUTION_ORIGIN_FIELD: 'Datos origen',
  DISTRIBUTION_TO_FIELD: 'Datos destino',
  DISTIBUTION_DIMENSION_TO_FIELD: 'Dimensión destino',
  DISTRIBUTION_ACCOUNT_FIELD: 'Cuenta contable',
  DISTRIBUTION_SELECT_DATE_LABEL: 'Año/mes a distribuir',
  DISTRIBUTION_DATE_FIELD: 'Año/Mes distribuido',
  DISTIBUTION_UPLOAD_DATA_ALERT:
    'Para cargar los datos destino necesitamos que primero cargues los datos origen',
  DISTIBUTION_UPLOAD_ACTION: 'Cargar distribución',
  DISTRIBUTION_DOWNLOAD_ACTION: 'Descargar distribución',
  DISTRIBUTION_EMPTY_VALUES_TEXT:
    'Acá verás todos los valores de dimensión que elijas para hacer tu distribución',
  DISTRIBUTION_DETAIL_TITLE: 'Detalle de distribución',
  DISTRIBUTION_CANCELED_DETAIL_TITLE: 'Detalle de distribución anulada',
  DISTRIBUTION_CANCEL_MODAL_TITLE: 'Confirmación de anulación de distribución',
  DISTRIBUTION_CANCEL_MODAL_TEXT:
    '{{userName}}, estás por anular una distribución en la cuenta {{account}} en {{añoMes}}. Recordá que una vez que la hagas no podrás deshacerla.',
  DISTRIBUTION_CREATE_SUCCESS_FEEDBACK_TITLE: 'Distribución registrada',
  DISTRIBUTION_CANCEL_SUCCESS_FEEDBACK_TITLE:
    '¡Genial! La anulación quedó registrada.',
  DISTRIBUTION_SUCCESS_FEEDBACK_DESCRIPTION:
    'Ya podés ver el impacto de la misma en seguimiento o análisis.',

  DISTRIBUTION_EMPTY_STATE_APPROVE_DATA_TOOLTIP:
    'No hay datos reales aprobados',

  DISTRIBUTION_VIEW_ACTION_TITLE: 'Ver distribución',
  DISTRIBUTION_CANCEL_CONFIRM_FAIL_FEEDBACK_DESCRIPTION:
    '{{userName}}, no pudimos realizar la anulación.',
  DISTRIBUTION_CREATE_FAIL_FEEDBACK_DESRIPTION:
    'La distribución no se pudo crear.',
  DISTRIBUTION_NEW_CREATE_ACTION: 'Crear distribución nueva',
  DISTRIBUTION_LOAD_SUCCESS_FEEDBACK_TITLE:
    'La distribución se cargó correctamente',
  DISTRIBUTION_LOAD_SUCCESS_FEEDBACK_DISTRIBUTION: 'Ya podes trabajar con ella',
  DISTRIBUTION_NOT_AMOUNT_FAIL_FEEDBACK: 'No hay monto a distribuir',
  DISTRIBUTION_SELECT_DIMENSION_ACTION:
    'Debes seleccionar una dimensión destino',
  DISTRIBUTION_PERCENTAGE_INFO_TOOLTIP:
    'Recordá que la sumatoria de los porcentajes debe dar 100%',

  DISTRIBUTION_DATA_TABLE_FILE_NAME:
    'Plika - Distribuciones - {{periodName}} (listado)',
  DISTRIBUTION_DEFAULT_FILE_NAME: 'Plika - Distribuciones  (listado)',
  DISTRIBUTION_DATA_ROW_FILE_NAME: 'Plika - Distribución - {{periodName}}',
  DISTRIBUTION_TEMPLATE_NAME: 'Plantilla Plika - Distribución',
  DISTRIBUTION_SOURCE_DATA_TEMPLATE:
    'Plantilla Plika - Distribución Datos destino.xlsx',
  DISTRIBUTION_ADD_NEW_COMBINATION_ACTION: 'Añadir nueva combinación',
};

export default distributions;
