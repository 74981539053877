import React from 'react';
import { Button, Space } from 'antd';
import { ButtonActionBar, Typography } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';

const ButtonSica = ({ dataSica }) => {
  const { t } = useTranslation();

  return (
    <ButtonActionBar position="space-between">
      <Button
        type="text"
        shape="circle"
        title={t('ACTION_UPDATE_DATA')}
        icon={<Typography.Icon icon={ReloadOutlined} />}
        onClick={dataSica.onClickButtonUpdate}
      />
      <Space direction="horizontal">
        <Button onClick={dataSica.onClickButtonUpload} type="primary">
          {t('REPORT_SELF_SERVICE_LOAD_DATA_BUTTON_TITLE')}
        </Button>
        <Button onClick={dataSica.onClickButtonPrint}>
          {t('REPORT_SELF_SERVICE_REPORT_PRINT_BUTTON_TITLE')}
        </Button>
      </Space>
    </ButtonActionBar>
  );
};

export default ButtonSica;
