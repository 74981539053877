import React, { useState } from 'react';
import { Space, Button } from 'antd';
import './TopColapsableMenu.scss';
import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const TopColapsableMenu = ({ children, collText }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  return (
    <Space className={`top-collapsable-menu ${collapsed ? 'collapsed' : ''} `}>
      <Button
        size="small"
        type="text"
        icon={collapsed ? <DownSquareOutlined /> : <UpSquareOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        title={collapsed ? t('SHOW_FILTERS_MENU') : t('HIDE_FILTERS_MENU')}
      />
      {!collapsed && children}
    </Space>
  );
};

export default TopColapsableMenu;
