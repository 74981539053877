import React from 'react';
import { Card } from 'antd';
import { ProjectionListKPI } from 'modules/core/components';

import './ListCard.scss';

const ListCard = ({
  className = '',
  title,
  type,
  projectionId,
  periodId,
  image = null,
  onClick,
  renderPrefix = null,
  extra = null,
  actions = [],
  emptyState = { title: null, description: null },
  hasData = true,
  loading = false,
}) => {
  return (
    <Card
      className={`projection-list-card ${className}`}
      headStyle={{ height: 56 }}
      bodyStyle={{ height: 194 }}
      title={title}
      bordered={false}
      data-type={type}
      onClick={onClick}
      extra={extra}
      actions={actions}
      loading={loading}
    >
      <ProjectionListKPI
        type={type}
        projectionId={projectionId}
        periodId={periodId}
        image={image}
        hasData={hasData}
        emptyState={emptyState}
        renderPrefix={renderPrefix}
      />
    </Card>
  );
};

export default ListCard;
