import React from 'react';
import { Tabs, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'modules/core/customHooks';
import { SiderLayout } from 'modules/core/layouts';
import { Navigator } from 'modules/core/components';
import { INTEGRATIONS } from 'modules/core/constants';
import { RolesList, UsersList } from './components';

const SecurityHome = () => {
  const { t } = useTranslation();
  let query = useQuery();

  const ROLES = 'roles';
  const USERS = 'users';

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        { name: t('LABEL_SECURITY') },
      ]}
    />
  );

  const renderSecurityTabs = () => (
    <Tabs
      size="small"
      defaultActiveKey={query.get('tab') !== null ? query.get('tab') : USERS}
    >
      <Tabs.TabPane tab={t('LABEL_USERS')} key={USERS}>
        <UsersList />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('LABEL_ROLES')} key={ROLES}>
        <RolesList />
      </Tabs.TabPane>
    </Tabs>
  );

  return (
    <SiderLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{renderSecurityTabs()}</Col>
      </Row>
    </SiderLayout>
  );
};

export default SecurityHome;
