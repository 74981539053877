import imgEbook from 'assets/images/compositions/ebook.svg';
import imgArticle from 'assets/images/compositions/articulo.svg';
import imgMetrics from 'assets/images/compositions/metricas.svg';
import imgNewFunctions from 'assets/images/compositions/nuevas_funciones.svg';

const dataSource = [
  {
    id: 1,
    name: 'Ebook',
    description: 'DASHBOARD_EBOOK_DESCRIPTION',
    link: 'https://getplika.com/wp-content/uploads/2022/03/Principales-tendencias-FPA-que-impactan-en-los-equipos-financieros-1.pdf',
    img: imgEbook,
    textoBoton: 'DASHBOARD_GO_EBOOK_LINK',
  },
  {
    id: 2,
    name: 'DASHBOARD_LABEL_ARTICLES',
    description: 'DASHBOARD_ARTICLE_DESCRIPTION',
    link: 'https://getplika.com/blog/',
    img: imgArticle,
    textoBoton: 'DASHBOARD_GO_BLOG_LINK',
  },
  {
    id: 3,
    name: 'DASHBOARD_LABEL_DICTIONARY',
    description: 'DASHBOARD_METRICS_DESCRIPTION',
    link: 'https://getplika.com/diccionarios/',
    img: imgMetrics,
    textoBoton: 'DASHBOARD_GO_METRICS_LINK',
  },
  {
    id: 4,
    name: 'DASHBOARD_LABEL_NEW_FUNCTIONS',
    description: 'DASHBOARD_NEW_FUNCTIONS_DESCRIPTION',
    link: 'https://www.youtube.com/playlist?list=PLJo0bl0cWTANbbnIJAswNCWHOzZ08yGwm',
    img: imgNewFunctions,
    textoBoton: 'DASHBOARD_GO_NEW_FUNCTIONS_LINK',
  },
];

const contacts = [
  {
    id: 1,
    title: 'DASHBOARD_COMERCIAL_CONTACT',
    description: [
      {
        name: 'Julián Bevilacqua',
        mail: 'jbevilacqua@getplika.com',
        numero: '+5492364614162',
        linkWhatsapp: 'https://wa.me/5492364614162',
      },
    ],
  },
  {
    id: 2,
    title: 'DASHBOARD_SERVICES_CONTACT',
    description: [
      {
        name: 'Facundo De Andres Bao',
        mail: 'fdeandres@getplika.com',
        numero: '+5492215472126',
        linkWhatsapp: 'https://wa.me/5492215472126',
      },
    ],
  },
  {
    id: 3,
    title: 'WhatsApp Plika',
    description: [
      {
        link: 'https://api.whatsapp.com/send/?phone=5492216387240&text&type=phone_number&app_absent=0',
      },
    ],
  },
];

const DATA_DASHBOARD = {
  dataSource,
  contacts,
};

export default DATA_DASHBOARD;
