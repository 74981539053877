import { useEffect, useState } from 'react';
import { client } from 'httpclient';
import { PROJECTION } from '../constants';
import store from 'store';
import _ from 'lodash';

const useFunctionalCurrency = () => {
  const [currencyData, setCurrencyData] = useState(
    store.getState().configuration.currencyList.data
  );

  useEffect(() => {
    client.get(`/planning/currency`).then(response => {
      setCurrencyData(response.data.results);
    });
  }, []);

  const functionalCurrencyPredefined =
    currencyData.length > 0
      ? currencyData.find(
          currency =>
            currency.value_type === PROJECTION.FUNCTIONAL_KEY &&
            currency.predefined
        )
      : null;
  const functionalCurrencyData = !_.isEmpty(functionalCurrencyPredefined)
    ? PROJECTION.CURRENCIES_OPTIONS.find(
        currency => currency.code === functionalCurrencyPredefined.code
      )
    : null;

  return functionalCurrencyData;
};

export default useFunctionalCurrency;
