import * as types from './constants';
import { saveAs } from 'file-saver';
import queryString from 'query-string';
import i18n from 'i18next';
import { GA } from 'modules/core/utils';

// List KPIs Endpoints
// `/report/reports/${id}/card-kpis/?period_id=${periodId}`
// `report/financial/${id}/card-kpis/period_id=${periodId}`

export const fetchReportList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_LIST,
      payload: {
        request: {
          url: `/report/reports/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportDetail =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_DETAIL,
      payload: {
        request: {
          url: `/report/reports/${reportId}/info/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_COLUMNS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportData =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_DATA,
      payload: {
        request: {
          url: `/report/reports/${reportId}/`,
          method: 'GET',
          params: {
            ...params,
            open: 'True',
          },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    GA.event(GA.ACTIONS.REPORT_ENTRY);
    return response;
  };

export const fetchReportStructure =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_STRUCTURE,
      payload: {
        request: {
          url: `/report/reports/${reportId}/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

const fetchReportDataRow =
  (rowId, type = types.FETCH_REPORT_ROW, params = {}) =>
  async dispatch => {
    // NOTE: Me quedo con el primer elemento que representa al padre raiz.
    const rootRowId = `${rowId}`.split('_')[0];
    const response = await dispatch({
      type: type,
      payload: {
        request: {
          url: `/report/reports/rows/${rootRowId}/`,
          method: 'GET',
          params: {
            ...params,
            row_id_front: rowId,
          },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportRow =
  (rowId, params = {}) =>
  async dispatch =>
    dispatch(
      fetchReportDataRow(rowId, types.FETCH_REPORT_ROW, {
        ...params,
        table: 'report',
      })
    );

export const fetchReportOpenRow =
  (rowId, params = {}) =>
  async dispatch =>
    dispatch(
      fetchReportDataRow(rowId, types.FETCH_REPORT_OPEN_ROW, {
        ...params,
        table: 'report-open',
      })
    );

export const createReport = data => async dispatch => {
  const response = await dispatch({
    type: types.CREATE_REPORT,
    payload: {
      request: {
        url: '/report/reports/',
        method: 'POST',
        data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const editReport =
  (reportId, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.EDIT_REPORT,
      payload: {
        request: {
          url: `/report/reports/${reportId}/`,
          method: 'PATCH',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const deleteReport = reportId => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_REPORT,
    payload: {
      request: {
        url: `/report/reports/${reportId}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchEconomicReportTemplateXLS =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/report/reports/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, i18n.t('CONFIG_ECONOMIC_REPORT_TEMPLATE'));
    return Promise.resolve();
  };

export const fetchFinancialReportTemplateXLS =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/report/reports/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, i18n.t('CONFIG_FINANCIAL_REPORT_TEMPLATE'));
    return Promise.resolve();
  };

export const fetchReportConsolidatedXLS = reportId => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_REPORT_CONSOLIDATED_XLS,
    payload: {
      request: {
        url: `report/reports/${reportId}/consolidated/`,
        method: 'GET',
        responseType: 'blob',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }

  saveAs(response.payload.data, i18n.t('REPORT_STRUCTURE_FILE_NAME'));
  return Promise.resolve();
};

export const uploadReportValuesXLS =
  (data, reportId, params = {}) =>
  async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('file', file);
      formData.append('report_id', reportId);
    });
    const response = await dispatch({
      type: types.UPLOAD_REPORT_VALUES_XLS,
      payload: {
        request: {
          url: `/report/reports/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const downloadEvolutionXLS =
  (
    reportId,
    params = {},
    filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_EVOLUTION_XLS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/excel-download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const downloadComparationXLS =
  (
    reportId,
    params = {},
    filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_COMPARE_XLS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/compare-download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const fetchCustomReportRows =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CUSTOM_REPORT_ROW,
      payload: {
        request: {
          url: `/report/custom/${reportId}/custom-analysis-report/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchCustomReportColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CUSTOM_REPORT_COLUMNS,
      payload: {
        request: {
          url: `/report/custom/${reportId}/custom-analysis-report/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportSegmentedDataRow =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_SEGMENTED_ROW,
      payload: {
        request: {
          url: `/report/reports/${reportId}/segmented-analysis-report/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportSegmentedColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_SEGMENTED_COLUMNS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/segmented-analysis-report/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const downloadCusomReportXLS =
  (
    reportId,
    params = {},
    filename = i18n.t('REPORT_CUSTOM_FILE_NAME_DEFAULT')
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_CUSTOM_XLS,
      payload: {
        request: {
          url: `/report/custom/${reportId}/custom-analysis-report/excel-download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const downloadEvolutionSegmentedXLS =
  (
    reportId,
    params = {},
    filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_EVOLUTION_SEGMENTED_XLS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/segmented-analysis-report/excel-download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const fetchReportVerticalComparisonDataRow =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_VERTICAL_COMPARISON_ROW,
      payload: {
        request: {
          url: `/report/reports/${reportId}/vertical-compare-report/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportVerticalComparisonColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_VERTICAL_COMPARISON_COLUMNS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/vertical-compare-report/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportVerticalSegmentedDataRow =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_VERTICAL_SEGMENTED_ROW,
      payload: {
        request: {
          url: `/report/reports/${reportId}/vertical-segmented-report/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchReportVerticalSegmentedColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_VERTICAL_SEGMENTED_COLUMNS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/vertical-segmented-report/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const downloadEvolutionVerticalComparisonXLS =
  (
    reportId,
    params = {},
    filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_EVOLUTION_VERTICAL_COMPARISON_XLS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/vertical-compare-report/download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const downloadEvolutionVerticalSegmentedXLS =
  (
    reportId,
    params = {},
    filename = i18n.t('REPORT_EVOLUTION_FILE_NAME_DEFAULT')
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_EVOLUTION_VERTICAL_SEGMENTED_XLS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/vertical-segmented-report/excel-download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const fetchDimensionTransactionsList =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DIMENSION_TRANSACTIONS_LIST,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/rows/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchCustomReportList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CUSTOM_REPORT_LIST,
      payload: {
        request: {
          url: `/report/custom/cards/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchCustomReportDetail =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CUSTOM_REPORT_DETAIL,
      payload: {
        request: {
          url: `/report/custom/${id}/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    GA.event(GA.ACTIONS.CUSTOM_REPORT_ENTRY);
    return response;
  };

//traer lstado de dimensiones para P&L segmentado
export const fetchDimensionValuesByRow =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DIMENSION_VALUES_BY_ROW,
      payload: {
        request: {
          url: `/report/reports/${reportId}/dimension_values_by_row/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchCustomReportGraphData =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CUSTOM_REPORT_GRAPH_DATA,
      payload: {
        request: {
          url: `/report/custom/${id}/custom-analysis-report/graph-data/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editReportKpis =
  (kpiId, data = {}) =>
  dispatch =>
    dispatch({
      type: types.EDIT_REPORT_KPIS,
      payload: { kpiId, ...data },
    });

//financiero

export const fetchFinancialReportList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FINANCIAL_REPORT_LIST,
      payload: {
        request: {
          url: `/report/financial/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchFinancialReportColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FINANCIAL_REPORT_COLUMNS,
      payload: {
        request: {
          url: `/report/financial/${reportId}/analysis/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchFinancialReportRows =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FINANCIAL_REPORT_ROWS,
      payload: {
        request: {
          url: `/report/financial/${reportId}/analysis/rows/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchFinancialReportChartData =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FINANCIAL_REPORT_CHART_DATA,
      payload: {
        request: {
          url: `/report/financial/${reportId}/analysis/graph-data/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const setInitialSituation =
  (reportId, data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.SET_INITIAL_SITUATION,
      payload: {
        request: {
          url: `/report/financial/${reportId}/analysis/set_initial_situation/`,
          method: 'POST',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const setReportLineSelected = lineId => dispatch =>
  dispatch({
    type: types.SET_REPORT_LINE_SELECTED,
    payload: lineId,
  });

export const fetchReportRuleData =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_REPORT_RULE_DATA,
      payload: {
        request: {
          url: `/report/reports/${id}/analysis-PxQ-report/`,
          method: 'GET',
          params,
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const createExchangeRateReport =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_EXCHANGE_RATE_REPORT,
      payload: {
        request: {
          url: '/report/exchange-rates/',
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const duplicateReport =
  (id, data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DUPLICATE_REPORT,
      payload: {
        request: {
          url: `/report/reports/${id}/duplicate-report/`,
          method: 'POST',
          params,
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const editInitialSituation =
  (reportId, params = {}, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.EDIT_INITIAL_SITUATION,
      payload: {
        request: {
          url: `/report/financial/${reportId}/analysis/set_initial_situation/`,
          method: 'POST',
          params,
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchComparativeMonthsRows =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_COMPARATIVE_MONTHS_ROWS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/comparative-by-date-range/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchComparativeMonthsColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_COMPARATIVE_MONTHS_COLUMNS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/comparative-by-date-range/columns/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const downloadComparativeMonthsXLS =
  (reportId, params = {}, filename) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_COMPARATIVE_MONTHS_XLS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/comparative-by-date-range/excel-download/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, filename);
    return Promise.resolve();
  };

export const fetchSegregatedAnalysisRows =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_SEGREGATED_ANALYSIS_ROWS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/double-segmented-report/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchSegregatedAnalysisColumns =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_SEGREGATED_ANALYSIS_COLUMNS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/double-segmented-report/columns/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchAvailableDimensions =
  (reportId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_AVAILABLE_DIMENSIONS,
      payload: {
        request: {
          url: `/report/reports/${reportId}/configs-with-transaction-current-report/`,
          method: 'GET',
          params,
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const downloadFlatxls =
  (data = {}, filename = i18n.t('LABEL_FLAT_DOWNLOAD')) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_FLAT_XLS,
      payload: {
        request: {
          url: `/report/reports/despegar/`,
          method: 'POST',
          responseType: 'blob',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    saveAs(response.payload.data, `${filename}.zip`);
    return Promise.resolve();
  };
