import React, { useRef, useCallback } from 'react';
import numeral from 'numeral';
import { Card } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { CHARTS } from 'modules/core/constants';
import { DropdownMenu, LineChart } from 'modules/core/components';
import { executeExportAsImage } from 'modules/core/utils';
import { useTranslation } from 'react-i18next';

import './ReportLineChart.scss';

const ReportLineChart = ({ title, chartTitle = '', data, prefix = '' }) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();

  const onExportChart = useCallback(props => {
    executeExportAsImage({ ...props });
  }, []);

  const renderExtraActions = () => {
    return (
      <DropdownMenu
        title={t('ACTION_MORE')}
        placement="bottomRight"
        menu={[
          {
            title: t('ACTION_EXPORT'),
            icon: <ExportOutlined />,
            submenu: CHARTS.EXPORT_FORMAT_TYPE_OPTIONS.map(formatType => {
              return {
                title: formatType.name,
                key: formatType.id,
                onClick: () =>
                  onExportChart({
                    chartRef: chartRef.current,
                    format: formatType.id,
                    onExport: formatType.onAction,
                    props: formatType.props,
                    title: chartTitle,
                  }),
              };
            }),
          },
        ]}
      />
    );
  };

  return (
    <div ref={chartRef}>
      <Card
        className="report-line-chart-card"
        title={title}
        headStyle={{ height: 56 }}
        extra={renderExtraActions()}
      >
        <LineChart
          containerHeight={300}
          data={data}
          colors={CHARTS.COLORS}
          axisLeft={{
            format: value => `${prefix} ${numeral(value).format('0,0[.]0 a')}`,
          }}
          value={data.map(val => val)}
          prefix={prefix}
        />
      </Card>
    </div>
  );
};

export default ReportLineChart;
