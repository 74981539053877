import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Spin,
  Table,
  Space,
  Input,
  notification,
} from 'antd';
import {
  Navigator,
  Typography,
  ButtonActionBar,
  UploadDraggerScreen,
  projectionSuccessUploadNotice,
} from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { ConfigurationUploadModal } from 'modules/configuration/components';
import { CONCEPTS, ROLES, INTEGRATIONS } from 'modules/core/constants';
import { isUserAllowed } from 'modules/core/utils';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import reports from 'modules/reports';

const { Search } = Input;
const {
  SETTINGS__REPORTS__REPORTSPNL__CREATE,
  SETTINGS__REPORTS__REPORTSPNL__EDIT,
} = ROLES;

const ReportsDetail = ({
  reportStructure: { name, values },
  fetchFinancialReportTemplateXLS,
  fetchReportStructure,
  fetchReportConsolidatedXLS,
  uploadReportValuesXLS,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  let { reportId } = useParams();
  const { t } = useTranslation();

  const canUserUpload = isUserAllowed([
    SETTINGS__REPORTS__REPORTSPNL__CREATE,
    SETTINGS__REPORTS__REPORTSPNL__EDIT,
  ]);

  useEffect(() => {
    fetchReportStructure(reportId).then(() => setIsTableLoading(false));
  }, [fetchReportStructure, reportId]);

  const functionSearch = (values, searchValue) => {
    const resultado = [];
    values.forEach(value => {
      if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
        resultado.push(value);
      } else {
        if (value.children) {
          const childrenAux = value.children.filter(childValue =>
            childValue.name.toLowerCase().includes(searchValue.toLowerCase())
          );
          if (childrenAux.length !== 0) {
            let valueAux = { ...value, children: childrenAux };
            resultado.push(valueAux);
          }
        }
      }
    });
    return resultado;
  };

  const onSuccessUploadFile = response => {
    projectionSuccessUploadNotice({
      projectionType: 'reporte',
      errorFile: response.payload.data.error_file,
    });
    setIsTableLoading(true);
    fetchReportStructure(reportId).then(() => setIsTableLoading(false));
  };

  const onFailUploadFile = error => {
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: error?.response?.data[0],
      duration: 0,
    });
  };

  const columns = t => [
    {
      title: t('CONFIG_REPORT_STRUCTURE_FIELD'),
      dataIndex: 'name',
    },
  ];

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_REPORTS'),
          url: `/configuracion/misdatos/reportes?tab=${CONCEPTS.TYPE.FINANCIAL_KEY}`,
        },
        {
          name: t('LABEL_FINANCIAL'),
          url: `/configuracion/misdatos/reportes?tab=${CONCEPTS.TYPE.FINANCIAL_KEY}`,
        },
        { name: t('LABEL_REPORT') },
        { name: name ? name : '' },
      ]}
    />
  );

  const renderEmptyScreen = () => (
    <UploadDraggerScreen
      disabled={!canUserUpload}
      description={t('CONFIG_REPORT_DETAIL_EMPTY_STATE_TITLE')}
      downloadTemplate={() => fetchFinancialReportTemplateXLS()}
      uploadFile={file => uploadReportValuesXLS(file, reportId)}
      onSuccessUpload={onSuccessUploadFile}
      onFailUpload={onFailUploadFile}
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="configuration-reports-detail-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        <Button
          type="primary"
          onClick={() => setShowUploadModal(true)}
          disabled={!canUserUpload}
        >
          {t('ACTION_UPLOAD_FILE')}
        </Button>
        <Button onClick={() => fetchFinancialReportTemplateXLS()}>
          {t('ACTION_DOWNLOAD_TEMPLATE')}
        </Button>
      </Space>
    </ButtonActionBar>
  );

  const renderTable = () => (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={isTableLoading}
      dataSource={
        searchValue === null ? values : functionSearch(values, searchValue)
      }
      columns={columns(t)}
      pagination={false}
    />
  );

  return (
    <SiderLayout>
      <div className="configuration-reports-detail-container">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Spin spinning={isTableLoading} />
          {values.length <= 0 && !isTableLoading && renderEmptyScreen()}
          {values.length > 0 && (
            <>
              <Col span={24}> {renderUserActions()}</Col>
              <Col span={24}>
                <ButtonActionBar>
                  <Button
                    type="text"
                    shape="circle"
                    title={t('ACTION_DOWNLOAD_TABLE')}
                    icon={<Typography.Icon icon={DownloadOutlined} />}
                    onClick={() => fetchReportConsolidatedXLS(reportId)}
                  />
                </ButtonActionBar>
              </Col>
              <Col span={24}>{renderTable()}</Col>
            </>
          )}
        </Row>
        <ConfigurationUploadModal
          title={t('CONFIG_REPORT_UPLOAD_ACTION')}
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          onSuccessUpload={() => fetchReportStructure(reportId)}
          uploadValuesXLS={file => uploadReportValuesXLS(file, reportId)}
          setIsTableLoading={setIsTableLoading}
          nameConfig={t('LABEL_REPORT')}
        />
      </div>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  reportStructure: reports.selectors.getReportStructure(state),
});

const mapDispatchToProps = {
  fetchReportStructure: reports.actions.fetchReportStructure,
  fetchReportConsolidatedXLS: reports.actions.fetchReportConsolidatedXLS,
  fetchFinancialReportTemplateXLS:
    reports.actions.fetchFinancialReportTemplateXLS,
  uploadReportValuesXLS: reports.actions.uploadReportValuesXLS,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsDetail);
