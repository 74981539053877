import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Table,
  Button,
  Popconfirm,
  Space,
  message,
  notification,
  Modal,
  Tooltip,
} from 'antd';
import {
  Typography,
  DropdownMenu,
  InputCell,
  SelectCell,
} from 'modules/core/components';
import {
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { isUserAllowed, renderTableCellLabel } from 'modules/core/utils';
import { ROLES, DIMENSIONS, PROJECTION_ELEMENTS } from 'modules/core/constants';
import configuration from 'modules/configuration';

const {
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__DELETE,
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__EDIT,
} = ROLES;

const isAllSelection = (values, options) => {
  return values.length === options.length;
};

// TODO: DUPLICAR
// FIXME: Revisar los .toString
const FormulasTable = ({
  dataSource,
  handleTableChange,
  tablePagination,
  loading = false,
  deleteFormula,
  onFetchFormulasList,
  deleteCascade,
  desactivateFormula,
  count,
  dimensionsList,
  editFormula,
  accountsList: { values: optionsAccount },
}) => {
  const [deleteFormulaId, setDeleteFormulaId] = useState(null);
  const [isDeletingFormula, setIsDeletingFormula] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [dataJoin, setDataJoin] = useState({
    accounts: null,
    dimensions: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmSaveChanges, setShowConfirmSaveChanges] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  let history = useHistory();
  const { t } = useTranslation();

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });
  };

  const onPrepareDataDimensions = (name, value, dataAccount) => {
    const dataComplete = {};
    value.map(
      idDimension =>
        idDimension &&
        Object.assign(dataComplete, {
          [`${idDimension}`]: [],
        })
    );
    setDataJoin(prevState => {
      return { ...prevState, dimensions: dataComplete };
    });
    onCellEdit(name, { ...dataAccount, ...dataComplete });
  };

  const onSetAccountsValues = values => {
    return {
      [DIMENSIONS.ACCOUNTS.id]: isAllSelection(values, optionsAccount)
        ? []
        : values.map(id => parseInt(id)),
    };
  };

  const onPrepareDataAccounts = (name, values, dataDimensions) => {
    setDataJoin({
      ...dataJoin,
      accounts: onSetAccountsValues(values),
    });
    const dataComplete = dataJoin.dimensions
      ? {
          ...dataJoin.dimensions,
          ...onSetAccountsValues(values),
        }
      : {
          ...dataDimensions,
          ...onSetAccountsValues(values),
        };
    onCellEdit(name, dataComplete);
  };

  const renderOptionsRows = (id, idFormula) => {
    return (
      <Popconfirm
        placement="bottomRight"
        title={t('CONFIG_FORMULAS_POPCONFIRM_DELETE_TITLE')}
        okText={t('ACTION_DELETE')}
        okButtonProps={{ loading: isDeletingFormula }}
        onConfirm={() => onDeleteFormula(id)}
        cancelText={t('ACTION_CANCEL')}
        onCancel={() => setDeleteFormulaId(null)}
        visible={deleteFormulaId === id}
      >
        <Space size="middle">
          <Button
            type="text"
            shape="circle"
            onClick={() =>
              history.push(
                `/configuracion/misdatos/elementosdeproyeccion/regla/${idFormula}`
              )
            }
            icon={<Typography.Icon icon={EyeOutlined} />}
            title={t('LABEL_DETAIL')}
          />
          <DropdownMenu
            title={t('ACTION_MORE')}
            menu={[
              {
                title: t('ACTION_EDIT'),
                icon: <EditOutlined />,
                onClick: () => setEditRow(id),
                disabled: !isUserAllowed(
                  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__EDIT
                ),
              },
              {
                title: t('ACTION_DELETE'),
                icon: <DeleteOutlined />,
                onClick: () => setDeleteFormulaId(id),
                disabled: !isUserAllowed(
                  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__DELETE
                ),
              },
            ]}
          />
        </Space>
      </Popconfirm>
    );
  };

  const renderCancelButton = () => {
    return (
      <Popconfirm
        placement="bottomRight"
        title={t('POPCONFIRM_EDIT_CANCEL')}
        okText={t('YES')}
        onConfirm={() => {
          setEditRow(null);
          setDataCellEdit({});
          setShowCancelConfirm(false);
          setDataJoin({ accounts: null, dimensions: null });
        }}
        cancelText={t('NO')}
        onCancel={() => setShowCancelConfirm(false)}
        visible={showCancelConfirm && !_.isEmpty(dataCellEdit)}
      >
        <Button
          type="link"
          onClick={() => {
            _.isEmpty(dataCellEdit)
              ? setEditRow(null)
              : setShowCancelConfirm(true);
          }}
          disabled={showConfirmSaveChanges}
        >
          {t('ACTION_CANCEL')}
        </Button>
      </Popconfirm>
    );
  };

  const onShowWarningModal = () => {
    return Modal.confirm({
      title: t('FIELD_IMPORTANT'),
      centered: true,
      content: (
        <Space direction="vertical">
          <span>{t('CONFIG_FORMULAS_CONFIRM_ACTION_MODAL_DESCRIPTION')}</span>
          <span>{t('CONFIG_FORMULAS_CONFIRM_ACTION_POPCONFIRM_TITLE')}</span>
        </Space>
      ),
      okText: t('ACTION_CLEAR'),
      onOk: () => onEditFormula(false),
      cancelText: t('ACTION_KEEP'),
      onCancel: () => onEditFormula(true),
    });
  };

  const renderConfirmButton = () => {
    return (
      <Popconfirm
        placement="bottomRight"
        title={t('PLANNING_FACTOR_POPCONFIRM_SAVE_CHANGES')}
        okText={t('YES')}
        onConfirm={() => {
          setShowConfirmSaveChanges(false);
          onShowWarningModal();
        }}
        cancelText={t('NO')}
        onCancel={() => setShowConfirmSaveChanges(false)}
        visible={showConfirmSaveChanges}
      >
        <Button
          type="link"
          onClick={() =>
            !dataCellEdit.type && !dataCellEdit.dimensions
              ? onEditFormula()
              : setShowConfirmSaveChanges(true)
          }
          disabled={_.isEmpty(dataCellEdit) || showCancelConfirm}
        >
          {t('ACTION_SAVE_CHANGES')}
        </Button>
      </Popconfirm>
    );
  };

  const renderEditButtons = () => {
    return (
      <Space direction="horizontal" size="middle">
        {renderCancelButton()}
        {renderConfirmButton()}
      </Space>
    );
  };

  const columns = [
    {
      title: t('CONFIG_FORMULA_FIELD_NAME_TABLE'),
      dataIndex: 'name',
      width: 230,
      editable: true,
      render: (name, record) => {
        return record.id === editRow ? (
          <InputCell
            initialValue={name}
            onCellUpdate={onCellEdit}
            name="name"
            style={{ width: 180 }}
          />
        ) : (
          name
        );
      },
    },
    {
      title: t('FIELD_VALUE_TYPE'),
      dataIndex: 'type',
      width: 150,
      render: (type, record) => {
        return record.id === editRow ? (
          <SelectCell
            initialValue={type}
            onCellUpdate={onCellEdit}
            style={{ width: 130 }}
            name="type"
            options={[{ id: 'init', name: 'Inicial' }]}
          />
        ) : (
          t(type)
        );
      },
    },
    {
      title: t('LABEL_FACTORS'),
      dataIndex: 'factores',
      width: 150,
      render: row =>
        row.length <= 0 ? (
          <Space
            style={{ display: 'flex', justifyContent: 'space-between' }}
            size="large"
            direction="horizontal"
          >
            {row.length}
            <Tooltip
              title={
                row.length <= 0 ? t('CONFIG_FORMULAS_INFORMATIVE_TOOLTIP') : ''
              }
            >
              <Typography.Icon
                icon={ExclamationCircleOutlined}
                type="warning"
              />
            </Tooltip>
          </Space>
        ) : (
          row.length
        ),
    },
    {
      title: t('LABEL_DIMENSIONS'),
      dataIndex: 'dimensions',
      width: 150,
      render: (dimensions, record) => {
        const dimensionsKeys = Object.keys(dimensions).filter(
          id => parseInt(id) !== DIMENSIONS.ACCOUNTS.id
        );
        if (record.id === editRow) {
          return (
            <SelectCell
              initialValue={Object.keys(dimensions)
                .filter(id => parseInt(id) !== DIMENSIONS.ACCOUNTS.id)
                .map(id => parseInt(id))}
              style={{ width: 190 }}
              onCellUpdate={(name, value) =>
                onPrepareDataDimensions(name, value, {
                  [DIMENSIONS.ACCOUNTS.id]: dimensions[DIMENSIONS.ACCOUNTS.id],
                })
              }
              options={dimensionsList.dimensions.map(dimension => {
                return { id: dimension.id, name: dimension.name };
              })}
              placeholder={t('ACTION_SELECT')}
              name="dimensions"
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={11}
              showArrow
              allowClear
            />
          );
        } else {
          return renderTableCellLabel({
            values: dimensionsKeys,
            allValues: dimensionsList.dimensions,
          });
        }
      },
    },
    {
      title: t('FIELD_ACCOUNTS_IMPACTED'),
      width: 150,
      render: (row, record) => {
        if (row.id === editRow) {
          return (
            <SelectCell
              initialValue={
                record.dimensions[DIMENSIONS.ACCOUNTS.id].length > 0
                  ? record.dimensions[DIMENSIONS.ACCOUNTS.id]
                      .asMutable()
                      .map(id => parseInt(id))
                  : optionsAccount.asMutable().map(account => account.id)
              }
              style={{ width: 205 }}
              placeholder={t('ACTION_SELECT')}
              onCellUpdate={(name, values) =>
                onPrepareDataAccounts(name, values, record.dimensions)
              }
              options={optionsAccount.asMutable().map(account => {
                return { id: account.id, name: account.name };
              })}
              name="dimensions"
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={8}
              showArrow
              allowClear
            />
          );
        } else {
          return renderTableCellLabel({
            values: record.dimensions[DIMENSIONS.ACCOUNTS.id],
            allValues: [],
          });
        }
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      width: 100,
      render: (id, record) => {
        if (id !== editRow) {
          return renderOptionsRows(id, record.id);
        } else {
          return renderEditButtons();
        }
      },
    },
  ];

  const onEditFormula = (keepData = null) => {
    if (!_.isEmpty(dataCellEdit)) {
      //NOTE: keep_data se le envia para validar si el usuario eligió borrar o conservar datos. Borrar => keep_data: false, conservar => keep_data: true
      const endpointData = {
        ...dataCellEdit,
        ...(keepData !== null && {
          keep_data: keepData,
        }),
      };
      editFormula(editRow, endpointData)
        .then(() => {
          setIsEditing(true);
          onFetchFormulasList().then(() => {
            setIsEditing(false);
            setEditRow(null);
            setDataCellEdit({});
            setShowConfirmSaveChanges(false);
            setDataJoin({ accounts: null, dimensions: null });
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          });
        })
        .catch(() => {
          setIsEditing(false);
          setEditRow(null);
          setDataCellEdit({});
          setShowConfirmSaveChanges(false);
          setDataJoin({ accounts: null, dimensions: null });
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          });
        });
    }
  };

  const onResetData = () => {
    setIsDeleting(false);
    setIsDeletingFormula(false);
    setDeleteFormulaId(null);
  };

  const onDeleteCascade = id => {
    setIsDeleting(true);
    deleteCascade(id)
      .then(() => {
        onFetchFormulasList().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          onResetData();
        });
      })
      .catch(() => {
        onResetData();
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        });
      });
  };

  const onDesactivateFormula = id => {
    setIsDeleting(true);
    desactivateFormula(id)
      .then(() => {
        onFetchFormulasList().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          onResetData();
        });
      })
      .catch(() => {
        onResetData();
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        });
      });
  };

  const onDeleteFormula = id => {
    setIsDeletingFormula(true);
    deleteFormula(id)
      .then(() =>
        onFetchFormulasList().then(() => {
          message.success(t('CONFIG_FORMULAS_DELETE_FEEDBACK_SUCCESS'));
          onResetData();
        })
      )
      .catch(error => {
        if (
          error.response.data.code ===
            PROJECTION_ELEMENTS.RULE.CODES_DELETE.DESACTIVATED ||
          error.response.data.code ===
            PROJECTION_ELEMENTS.RULE.CODES_DELETE.CASCADE
        ) {
          setDeleteFormulaId(null);
          Modal.confirm({
            title: t('FIELD_ATENTION'),
            centered: true,
            content: (
              <Space direction="vertical">
                <span>{error.response.data.message}</span>
                <span>
                  {error.response.data.code ===
                  PROJECTION_ELEMENTS.RULE.CODES_DELETE.DESACTIVATED
                    ? t('CONFIG_FORMULAS_MODAL_INACTIVE_CONFIRM_TEXT')
                    : t('MODAL_DELETE_CONFIRM_TEXT')}
                </span>
              </Space>
            ),
            okText: t('YES'),
            onOk: () =>
              error.response.data.code ===
              PROJECTION_ELEMENTS.RULE.CODES_DELETE.DESACTIVATED
                ? onDesactivateFormula(id)
                : onDeleteCascade(id),
            cancelText: t('NO'),
            onCancel: () => onResetData(),
          });
        } else {
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: error.response.data.message,
            duration: 0,
          });
        }
      });
  };

  return (
    <Table
      rowKey="id"
      size="small"
      bordered
      columns={columns}
      onChange={handleTableChange}
      loading={loading || isDeleting || isDeletingFormula || isEditing}
      dataSource={dataSource}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: count,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  deleteFormula: configuration.actions.deleteFormula,
  desactivateFormula: configuration.actions.desactivateFormula,
  deleteCascade: configuration.actions.deleteCascade,
  editFormula: configuration.actions.editFormula,
};

export default connect(null, mapDispatchToProps)(FormulasTable);
