import React from 'react';
import i18n from 'i18next';
import { FilterFilled, MessageOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import {
  TransactionCell,
  TableFilterDropdown,
  Typography,
  CellStyle,
} from 'modules/core/components';
import { dateMMYYYY, columnPadding } from 'modules/core/utils';
import { COLUMNS, ROWS, CONCEPTS } from 'modules/core/constants';
import { Resizable } from 'react-resizable';

const HEADER_TITLE_SIZE = 22;

//NOTE: SE AGREGA PARA CASOS DONDE EL NUMERO QUE DEVUELVE BACK TIENE UNA e. COMO SE LES COMPLICABA A ELLOS RESOLVERLO DESDE LA BASE, LOA GREGAMOS DESDE FRONT.
const renderAuxValueReview = value =>
  value && value.toString().includes('e') ? 0 : value;

const renderResizable = ({ onResize, width, ...restProps }) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const tableComponents = {
  header: {
    cell: renderResizable,
  },
};

const renderSorterLocale = () => {
  return {
    triggerAsc: i18n.t('ACTION_SORT_ASC'),
    triggerDesc: i18n.t('ACTION_SORT_DESC'),
  };
};

const shouldBeFixed = ({ isCollapsed = true, columns = [] } = {}) =>
  isCollapsed ||
  columns.filter(column => !column.is_transaction).length <=
    COLUMNS.MAX_COLUMNS_TO_FIX;

const renderHeaderTooltip = ({ title, size = HEADER_TITLE_SIZE }) => {
  return {
    title: () => (
      <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        {title.length > size ? (
          <Tooltip title={title}>{`${title.slice(0, size)}.`}</Tooltip>
        ) : (
          title
        )}
      </div>
    ),
  };
};

// NOTE: Recordar importar las clases css correspondientes
const renderCollapser = ({
  width = 15,
  fixedColumns,
  isCollapsed,
  onCollapse,
}) => {
  return {
    width: width,
    title: `${isCollapsed ? '>' : '<'}`,
    className: COLUMNS.TYPES.COLLAPSER,
    ...(fixedColumns && { fixed: 'left' }),
    onHeaderCell: column => {
      return {
        onClick: onCollapse,
      };
    },
  };
};

// NOTE: Recordar importar las clases css correspondientes
const renderTotal = ({
  width = COLUMNS.WIDTH.TRANSACTION,
  format,
  showNegativeInRed,
  has_restriction = false,
} = {}) => {
  return {
    className: COLUMNS.TYPES.TOTALIZER,
    align: 'right',
    width: width,
    // FIXME: Quitar cuando este el sort para transacciones
    sorter: false,
    render: (value, record) => {
      return (
        <CellStyle.Number
          value={renderAuxValueReview(value)}
          format={renderFormatValue({
            isRatio: record.type === ROWS.RATIO,
            concept: record.concept,
            has_restriction,
            format,
          })}
          showNegativeInRed={showNegativeInRed}
          type={record.type}
          has_restriction={has_restriction}
        />
      );
    },
  };
};

const renderFilters = ({ dataIndex, filterOptions, filters, onFilter }) => {
  if (!filterOptions || !filterOptions[dataIndex]) return null;
  const filteredValues = filters[filterOptions[dataIndex].id];
  const { id: dimensionId, options } = filterOptions[dataIndex];
  const isFiltered =
    filters[dimensionId] && filters[dimensionId].length > 0 ? true : false;

  return {
    filters: options,
    filterIcon: () => (
      <FilterFilled
        style={{
          ...(isFiltered && {
            padding: '8px',
            backgroundColor: '#D9D9D9',
            color: '#797979',
          }),
        }}
      />
    ),
    filterDropdown: filterDropdownProps => (
      <TableFilterDropdown
        {...filterDropdownProps}
        filteredValues={filteredValues}
        onFilter={newFilters =>
          onFilter({ ...filters, [dimensionId]: newFilters })
        }
      />
    ),
  };
};

const renderComment = (width = COLUMNS.WIDTH.SMALL) => {
  return {
    width: width,
    fixed: 'right',
    align: 'center',
    dataIndex: 'comments',
    render: comment =>
      comment && comment.trim().length > 0 ? (
        <Popover
          placement="top"
          title={i18n.t('FIELD_COMMENT')}
          content={comment}
          trigger="click"
        >
          <Typography.Icon icon={MessageOutlined} level={2} />
        </Popover>
      ) : (
        ''
      ),
  };
};

const renderDimension = ({
  dataIndex,
  title,
  width = COLUMNS.WIDTH.DIMENSION,
  fixedColumns,
  totalProps: { isTotal, ...restTotalProps },
  filterProps = {},
  collapserProps: { isCollapser, ...otherCollapserProps },
}) => {
  return {
    title: title,
    width: width,
    ...renderHeaderTooltip({ title }),
    ...(isTotal && renderTotal({ ...restTotalProps })),
    ...(!isTotal && renderFilters({ dataIndex, ...filterProps })),
    ...(fixedColumns && { fixed: 'left' }),
  };
};

const renderDataRecord = record => {
  return {
    isTotalizer:
      record.id === ROWS.TOTALIZER_ID || record.type === ROWS.TOTALIZER,
    isRatio: record.type === ROWS.RATIO,
    hasChildren: record.children,
    isOriginCurrencyRow: record.has_origin_rows,
    isFinancial: record.type === CONCEPTS.TYPE.FINANCIAL_KEY,
  };
};

//NOTE: hardcodeado por cliente especifico
export const renderFormatValue = ({
  isRatio,
  concept,
  has_restriction,
  format,
}) => {
  const nameRowsRatiowirhoutPercentage = [
    'ASP',
    'ASP Turismo',
    'ASP Financiero',
    'Rev/Transaction (USD/tx.)',
  ];
  if (isRatio) {
    if (nameRowsRatiowirhoutPercentage.includes(concept) && has_restriction) {
      return format;
    }
    return '%';
  }

  return format;
};

const renderTransactionCell = ({
  hasPropAmount,
  initialValue,
  transactionId,
  record,
  transaction,
  date,
  format,
  disabled,
  showNegativeInRed,
  onCalculateValue,
  onCellEdit,
  onCellEditForward,
  showAdjustment,
  showCalculateValue,
  onComment,
  onDeleteComment,
  has_restriction,
  data_index = '',
  isTotalizer,
  isRatio,
  hasChildren,
  isOriginCurrencyRow,
  isFinancial,
}) => {
  const commentList = transaction.comments_thread
    ? transaction.comments_thread
    : [];

  const transactionInfo = {
    columnId: date.slice(0, -3), // Only need YYYY-MM
    rowId: record.id,
    transactionId: transaction.id,
  };

  const adjustmentProps = {
    adjustment: {
      disabled: !transaction.adjustable,
      date: date,
      // TODO: adjustment_info deberia solo tener id y en vez de info > label
      row: record.adjustment_info,
    },
  };

  const renderDataCellEdit = value => {
    if (data_index && data_index !== 'amount') {
      return {
        [data_index.toUpperCase()]: value,
        transaction_id: transactionId,
      };
    }
    return {
      transactionId,
      amount: value,
      date,
      parentId: record.parent_id,
      origin_row: record.id,
    };
  };

  return (
    <TransactionCell
      initialValue={initialValue}
      hasVarsOrRule={{
        hasVars: record.has_variable_applied,
        hasRule: record.has_rule_applied,
      }}
      format={renderFormatValue({
        isRatio,
        concept: record.concept,
        has_restriction,
        format,
      })}
      has_restriction={has_restriction}
      type={record.type}
      disabledInput={disabled || (isTotalizer && !isFinancial) || hasChildren}
      showContextMenu={!(isTotalizer || !hasPropAmount) || isFinancial}
      showNegativeInRed={showNegativeInRed}
      {...(onCalculateValue && {
        onCalculateValue: value =>
          onCalculateValue(
            { transactionId, amount: value },
            isOriginCurrencyRow
          ),
      })}
      onCellEdit={value =>
        onCellEdit(renderDataCellEdit(value), isOriginCurrencyRow, data_index)
      }
      {...(onCellEditForward && {
        onCellEditForward: () =>
          onCellEditForward(
            {
              rowId: record.id,
              startDate: date,
              amount: transaction.amount,
              parentId: record.parent_id,
              ...(record.endDate && {
                endDate: record.endDate,
              }),
            },
            isOriginCurrencyRow
          ),
      })}
      {...(showAdjustment ? adjustmentProps : {})}
      showCalculateValue={showCalculateValue}
      comments={{
        hasComment: commentList.length > 0 && !isTotalizer,
        commentList: commentList,
        ...(onComment && {
          onComment: newComment =>
            onComment({
              ...transactionInfo,
              text: newComment,
            }),
        }),
        ...(onDeleteComment && {
          onDeleteComment: commentId => {
            onDeleteComment({
              ...transactionInfo,
              commentId,
            });
          },
        }),
      }}
    />
  );
};

// TODO: Quizas se podria hacer generica, ir viendo en cada caso.
// NOTE: Recordar importar las clases css correspondientes
const renderTransactionPlanning = ({
  title = null,
  date,
  cellFormat: { format, showNegativeInRed } = {},
  className = COLUMNS.TYPES.TRANSACTION,
  width = COLUMNS.WIDTH.TRANSACTION,
  disabled,
  onCellEdit,
  onCalculateValue = null,
  onCellEditForward = null,
  comments: { onComment = null, onDeleteComment = null } = {},
  showAdjustment = false,
  showCalculateValue = true,
  has_restriction,
}) => {
  return {
    title: title !== null ? title : dateMMYYYY(date, 'YYYY-MM'),
    align: 'right',
    width: width,
    // FIXME: Quitar cuando este el sort para transacciones
    sorter: false,
    className,
    render: (transaction, record) => {
      if (transaction) {
        const hasPropAmount = transaction.hasOwnProperty('amount');

        const renderInitialValue = () =>
          hasPropAmount
            ? transaction.amount
            : renderAuxValueReview(transaction);

        return renderTransactionCell({
          hasPropAmount,
          initialValue: renderInitialValue(),
          transactionId: transaction.id,
          record,
          transaction,
          date,
          format,
          disabled,
          showNegativeInRed,
          onCalculateValue,
          onCellEdit,
          onCellEditForward,
          showAdjustment,
          showCalculateValue,
          onComment,
          onDeleteComment,
          has_restriction,
          ...renderDataRecord(record),
        });
      }
      return '-';
    },
  };
};

const renderTransactionPlanningPXQ = ({
  title = null,
  column,
  width = COLUMNS.WIDTH.TRANSACTION,
  disabled,
  className = COLUMNS.TYPES.TRANSACTION,
  cellFormat: { format, showNegativeInRed } = {},
  onCellEdit = null,
  showAdjustment = false,
  showCalculateValue = true,
  has_restriction = false,
}) => {
  return {
    ...column,
    title: title ? title : dateMMYYYY(column.title, 'YYYY-MM'),
    align: 'right',
    width,
    // FIXME: Quitar cuando este el sort para transacciones
    sorter: false,
    className,
    children: column.childrens.map(chil => {
      return {
        ...chil,
        align: 'right',
        width: COLUMNS.WIDTH.TRANSACTION,
        render: (transaction, record) => {
          const dataTransaction = transaction[column.title.slice(0, -3)];

          if (dataTransaction) {
            return renderTransactionCell({
              hasPropAmount: dataTransaction.hasOwnProperty('amount'),
              initialValue: dataTransaction[chil.data_index],
              transactionId: dataTransaction.id,
              record,
              transaction,
              date: column.title,
              format,
              disabled,
              showNegativeInRed,
              onCellEdit,
              showAdjustment,
              showCalculateValue,
              data_index: chil.data_index,
              has_restriction,
              ...renderDataRecord(record),
            });
          }
          return '-';
        },
      };
    }),
  };
};

const renderOnHeaderResize = onResize => {
  return {
    onHeaderCell: column => ({
      width: column.width,
      onResize: (e, { size }) => {
        onResize(prevState => {
          return [...prevState].map(col => {
            return col.data_index === column.dataIndex ||
              col.dataIndex === column.dataIndex
              ? { ...col, width: size.width }
              : col;
          });
        });
      },
    }),
  };
};

const generator = ({
  columns = [],
  filteredColumns,
  fixedColumns = null,
  cellFormat,
  filterProps,
  collapserProps: { isCollapsed = true, ...otherCollapserProps },
  renderTransaction,
  showRowsComment = true,
  sorter = false,
  render = null,
  onResize = null,
  has_restriction = false,
}) => {
  const collapserProps = { isCollapsed: isCollapsed, ...otherCollapserProps };
  let filterCollapsedColumns = columns;
  let auxFixedColumns =
    fixedColumns !== null
      ? fixedColumns
      : shouldBeFixed({ isCollapsed, columns });
  // Es necesario filtrar
  if (isCollapsed || filteredColumns) {
    filterCollapsedColumns = columns.filter((column, index) => {
      // Columnas fijas: Primera, Total y Transacciones.
      const fixedColumnsCondition =
        column.is_transaction ||
        index === COLUMNS.FIRST_INDEX ||
        column.data_index === COLUMNS.TOTAL_DATA_INDEX;

      // Sin colapsar y reduce dimensiones visibles
      if (!isCollapsed && filteredColumns)
        return (
          fixedColumnsCondition || filteredColumns.includes(column.data_index)
        );
      // Sino devuelvo siempre las columnas fijas
      return fixedColumnsCondition;
    });
  }

  const collapserColumn = renderCollapser({
    fixedColumns: auxFixedColumns,
    ...collapserProps,
  });

  const allDataColumns = filterCollapsedColumns.map((column, index) => {
    return {
      key: index,
      ellipsis: true,
      sorter,
      dataIndex: column.data_index,
      ...(!column.is_transaction &&
        renderDimension({
          dataIndex: column.data_index,
          title: column.title,
          width: column.width,
          fixedColumns: auxFixedColumns,
          totalProps: {
            isTotal: column.data_index === COLUMNS.TOTAL_DATA_INDEX,
            width:
              column.data_index === COLUMNS.TOTAL_DATA_INDEX && column.width,
            ...cellFormat,
          },
          filterProps,
          collapserProps: {
            isCollapser: index === COLUMNS.FIRST_INDEX,
            ...collapserProps,
          },
        })),
      ...(onResize && renderOnHeaderResize(onResize)),
      ...(column.is_transaction && renderTransaction(column)),
      ...(render && render(column)),
    };
  });

  const allColumns = [collapserColumn, ...allDataColumns];

  if (!showRowsComment) return allColumns;

  return [...allColumns, renderComment()];
};

const generatorReportColumns = ({
  columns = [],
  onResize = null,
  cellFormat = {},
  editable = false,
  onCellEdit = null,
  has_restriction = false,
}) => {
  return columns.map((column, index) => {
    const isTotalizer = column.data_index === COLUMNS.TOTAL_DATA_INDEX;
    const isTransaction = column.is_transaction;
    const firstMonth = columns.filter(col => col.is_transaction)[0].title;

    const COLUMNS_BY_PERIOD = {
      concept: {
        title: i18n.t('FIELD_CONCEPT'),
        width: column.width,
      },
      TOTAL: {
        title: i18n.t('FIELD_TOTAL').toUpperCase(),
      },
    };

    return {
      key: index,
      ellipsis: true,
      dataIndex: column.data_index,
      ...(onResize && renderOnHeaderResize(onResize)),
      ...(!isTransaction && {
        ...COLUMNS_BY_PERIOD[column.data_index],
        fixed: 'left',
        render: (value, row) => columnPadding(value, row.level, row.type),
        ...(isTotalizer &&
          columnsHelper.renderTotal({
            width: isTotalizer && column.width,
            format: cellFormat.format,
            has_restriction,
          })),
      }),
      ...(isTransaction &&
        columnsHelper.renderTransactionPlanning({
          date: column.title,
          cellFormat: cellFormat,
          width: column.width,
          showCalculateValue: false,
          has_restriction,
          ...(!editable
            ? { disabled: true }
            : { disabled: column.title !== firstMonth }),
          ...(onCellEdit && { onCellEdit }),
        })),
    };
  });
};

const generatorComparisonColumns = ({
  columns = [],
  onResize = null,
  renderValue,
}) => {
  return columns.map(col => {
    return {
      ...col,
      title: col.title,
      ...(onResize && renderOnHeaderResize(onResize)),
      ...(col.dataIndex !== 'concept' && renderValue(col)),
    };
  });
};

export const columnsHelper = {
  renderSorterLocale,
  shouldBeFixed,
  renderCollapser,
  renderTotal,
  renderFilters,
  renderComment,
  renderDimension,
  renderTransactionPlanning,
  generator,
  generatorReportColumns,
  generatorComparisonColumns,
  tableComponents,
  renderTransactionPlanningPXQ,
  renderFormatValue,
};
