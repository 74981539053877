import React from 'react';
import { connect } from 'react-redux';
import { DropdownMenu, Typography } from 'modules/core/components';
import { RiseOutlined } from '@ant-design/icons';
import login from 'modules/login';
import { useTranslation } from 'react-i18next';

// TODO: Deshacer el uso de este parche luego de implementar lo de los accesos.

const PATCH_ACCES_LINKS_URL = {
  sight:
    'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/2ea73974-a50a-4984-b49b-88e021236479',
  pnl: 'http://190.112.43.59:4848/sense/app/d72b1644-4e34-4f16-aa07-0365099c3190/sheet/85f170c3-f30c-4841-b3df-9b907003eb5a/state/analysis',
  sales:
    'http://190.112.43.59:4848/sense/app/6b2c8b06-ed90-4957-ad22-9da1bcae3d38/sheet/4ac6f7f2-efc7-4a72-b3cc-cb58b4fd3a1a/state/analysis',
  expenses:
    'http://190.112.43.59:4848/sense/app/1da01ff0-8b28-4904-8233-e120b7b4a1fd/sheet/8dca40ed-256e-4aaf-ba96-57b1c74848e8/state/analysis',
  Ventas:
    'http://190.112.43.59:4848/sense/app/6b2c8b06-ed90-4957-ad22-9da1bcae3d38/sheet/4ac6f7f2-efc7-4a72-b3cc-cb58b4fd3a1a/state/analysis',
  Gastos:
    'http://190.112.43.59:4848/sense/app/1da01ff0-8b28-4904-8233-e120b7b4a1fd/sheet/8dca40ed-256e-4aaf-ba96-57b1c74848e8/state/analysis',
};

const PATCH_ACCES_LINKS_USERS = [
  'jbevilacqua@comerciales.com',
  'mguillen@patagonia.com.ar',
  'mguillen@bluedraft.com.ar',
  'mguillen@test.com.ar',
];

const AccessLink = ({ loggedUser, linkName }) => {
  const { t } = useTranslation();

  // Sólo visible para usuarios predefinidos.
  if (!PATCH_ACCES_LINKS_USERS.includes(loggedUser.email)) return null;

  let auxUrl = PATCH_ACCES_LINKS_URL[linkName];

  return (
    <DropdownMenu
      // icon={<Typography.Icon icon={BarChartOutlined} />}
      title={t('ACTION_ANALYTICS_PATCH')}
      menu={[
        {
          title: (
            <a target="_blank" rel="noopener noreferrer" href={auxUrl}>
              {`Quick Sense - ${t(linkName)}`}
            </a>
          ),
        },
        {
          title: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={PATCH_ACCES_LINKS_URL['sight']}
            >
              Quick Sight
            </a>
          ),
        },
      ]}
    >
      <Typography.Icon icon={RiseOutlined} /> {t('ACTION_ANALYTICS_PATCH')}
    </DropdownMenu>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

export default connect(mapStateToProps, null)(AccessLink);
