import reducer from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';
import { NAME } from './constants';

const index = {
  NAME,
  reducer,
  actions,
  selectors,
};

export default index;
