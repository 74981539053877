import React, { useEffect, useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Space, Button, Popover } from 'antd';
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils';
import periodsMenu from './periodsMenu';
import { getDataButtonsContainerHelp } from './MockDataButtonHelp';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import planning from 'modules/planning';
import configuration from 'modules/configuration';

import './SiderLayout.scss';
import { DropdownMenu, Typography } from 'modules/core/components';

const { Content, Sider } = Layout;

const SiderLayout = ({
  match: {
    params: { periodId },
    path,
  },
  header = null,
  children,
  className,
  fetchPeriods,
  periods,
  setOnboardingStep,
  fetchOnboardingSteps,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!periodId) {
      fetchPeriods();
    }
  }, [fetchPeriods, periodId]);

  useEffect(() => {
    fetchOnboardingSteps();
  }, [fetchOnboardingSteps]);

  const EXCEPTION_PATHS = ['/configuracion', '/analisis', '/periodos'];
  const PLIKA_URL = 'https://getplika.com/manual-de-usuario-plika/';

  const exception_path = EXCEPTION_PATHS.find(el =>
    path.includes(el) ? el : null
  );

  const renderButton = ({ ...props }) => (
    <Button {...props} block style={{ marginBottom: 10 }} type="primary">
      {props.title}
    </Button>
  );

  const renderExceptionButtons = () =>
    getDataButtonsContainerHelp(setOnboardingStep, fetchOnboardingSteps)[
      exception_path
    ].buttons.map((button, index) =>
      button.subButtons ? (
        <DropdownMenu
          title={t('LABEL_HELP')}
          key={index}
          menu={[
            ...button.subButtons.map(subButton => {
              return {
                title: t(subButton.name),
                icon: button.icon,
                key: subButton.id,
                onClick: () =>
                  subButton.link && window.open(subButton.link, '_blank'),
                ...(subButton.subSubButtons
                  ? {
                      submenu: [
                        ...subButton.subSubButtons.map(subSubButton => {
                          return {
                            title: t(subSubButton.name),
                            key: subSubButton.id,
                            onClick: () =>
                              window.open(subSubButton.link, '_blank'),
                          };
                        }),
                      ],
                    }
                  : {}),
              };
            }),
          ]}
        >
          {renderButton({
            title: t(button.name),
          })}
        </DropdownMenu>
      ) : (
        renderButton({
          title: t(button.name),
          onClick: () =>
            button.link ? window.open(button.link, '_blank') : button.onClick(),
          key: index,
        })
      )
    );

  const renderContentHelp = () => (
    <div
      style={{
        width: 200,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: 10 }}>
        <Typography.Body level={2}>{t('NEED_HELP')}</Typography.Body>
      </div>
      {renderButton({
        title: t('LABEL_USER_MANUAL'),
        onClick: () => window.open(PLIKA_URL, '_blank'),
      })}
      {exception_path && renderExceptionButtons()}
    </div>
  );

  const renderButtonHelp = () => (
    <Popover
      placement="rightBottom"
      trigger="click"
      open={open}
      onOpenChange={() => setOpen(!open)}
      content={renderContentHelp()}
    >
      <Button type="text" shape="circle" title={t('LABEL_HELP')}>
        <Typography.Icon icon={QuestionCircleOutlined} />
      </Button>
    </Popover>
  );

  return (
    <Layout className={`sider-layout ${className ? className : ''}`}>
      <Sider
        className="sider-layout-menu"
        collapsible
        collapsed={true}
        trigger={null}
        collapsedWidth={56}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            paddingBottom: 10,
          }}
        >
          <Space
            className="sub-layout-actions"
            align="center"
            direction="vertical"
            size="large"
          >
            {periodsMenu(t)
              .filter(navItem =>
                isUserAllowed(getOperationsByKeys(navItem.allowedFor))
              )
              .map((item, index) => {
                const { title, url, exact, icon } = item;
                return (
                  <NavLink
                    key={index}
                    className="menu-icon"
                    to={url.replace(
                      ':periodId',
                      periodId ? periodId : periods.length > 0 && periods[0].id
                    )}
                    exact={exact}
                    activeClassName="selected"
                    title={title}
                  >
                    {icon}
                  </NavLink>
                );
              })}
          </Space>
          <div>{renderButtonHelp()}</div>
        </div>
      </Sider>
      <Layout>
        {header}
        <Content
          className={`sider-layout-content ${className ? className : ''}`}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = state => ({
  periods: planning.selectors.getPeriods(state),
});

const mapDispatchToProps = {
  fetchPeriods: planning.actions.fetchPeriods,
  setOnboardingStep: configuration.actions.setOnboardingStep,
  fetchOnboardingSteps: configuration.actions.fetchOnboardingSteps,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiderLayout)
);
