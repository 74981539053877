import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ForecastHome from './ForecastHome/ForecastHome';
import ForecastList from './ForecastList/ForecastList';
import ForecastDetail from './ForecastDetail/ForecastDetail';
import ConsolidationDetail from './ConsolidationDetail/ConsolidationDetail';
import {
  ProjectionTrackingScreen,
  ExchangeRateScreen,
} from 'modules/core/components';

const Home = () => (
  <Switch>
    <Route exact path="/forecast/:periodId/" component={ForecastHome} />
    <Route
      exact
      path="/forecast/:periodId/:forecastId"
      component={ForecastList}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/consolidacion/:consolidationId"
      component={ConsolidationDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:conceptName(ventas|gastos)/actividad/:projectionId"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/:conceptName(ventas|gastos)/:projectionId"
      component={ForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/tipodecambio/:budgetId"
      component={ExchangeRateScreen}
    />
  </Switch>
);

export default Home;
