import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Popconfirm, message, Tooltip } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  DropdownMenu,
  SelectCell,
  InputCell,
  DeleteAndUpdate,
} from 'modules/core/components';
import _ from 'lodash';
import { EditDimensionModal } from './components';
import {
  orderBySelection,
  isUserAllowed,
  renderTableCellLabel,
} from 'modules/core/utils';
import { DIMENSIONS, PROJECTION, ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';

import './VariablesTable.scss';

const {
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES__EDIT,
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES__DELETE,
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES__DUPLICATE,
} = ROLES;

// TODO: Revisar el isTableLoading y su comportamiento ya que quizas podria divirse en isEditing, isDeleting, etc. ya que el componente tiene un loading como prop.
const VariablesTable = ({
  dataSource,
  onDuplicateRow,
  accountsList: { values: optionsAccount },
  dimensionsList,
  optionsBehaviour,
  editVariable,
  deleteVariable,
  onRefreshData,
  handleTableChange,
  tablePagination,
  tableLoading = false,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isDeletingVariable, setIsDeletingVariable] = useState(false);
  const [deleteVariableId, setDeleteVariableId] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [dimensionEditData, setDimensionEditData] = useState(null);
  const [dataJoin, setDataJoin] = useState({
    accounts: null,
    dimensions: null,
  });

  const { t } = useTranslation();

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });
  };

  const onPrepareData = (name, value, data) => {
    setDataJoin({
      ...dataJoin,
      accounts: { [DIMENSIONS.ACCOUNTS.id]: value.map(id => parseInt(id)) },
    });
    const dataComplete = dataJoin.dimensions
      ? {
          ...dataJoin.dimensions,
          [DIMENSIONS.ACCOUNTS.id]: value.map(id => parseInt(id)),
        }
      : { ...data, [DIMENSIONS.ACCOUNTS.id]: value.map(id => parseInt(id)) };
    onCellEdit(name, dataComplete);
  };

  const renderDimensionLabel = dimensions => {
    if (dataJoin.dimensions)
      return renderTableCellLabel({
        values: _.keys(dataJoin.dimensions),
        allValues: dimensionsList.dimensions,
      });
    return renderTableCellLabel({
      values: dimensions,
      allValues: dimensionsList.dimensions,
    });
  };
  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      width: 200,
      editable: true,
      render: (row, record) =>
        editRow && record.id === editRow ? (
          <InputCell initialValue={row} onCellUpdate={onCellEdit} name="name" />
        ) : (
          row
        ),
    },
    {
      title: t('FIELD_VALUE_TYPE'),
      dataIndex: 'value_type',
      render: (valueType, record) => {
        if (editRow && record.id === editRow) {
          return (
            <SelectCell
              initialValue={valueType}
              onCellUpdate={onCellEdit}
              style={{ width: 130 }}
              name="value_type"
              allowClear
              options={PROJECTION.VARIABLES_TYPE_OPTIONS.map(variable => {
                return { ...variable, name: t(variable.name) };
              })}
            />
          );
        } else {
          if (valueType)
            return valueType === 'percentage'
              ? t('FIELD_PERCENTAGE')
              : t('FIELD_NUMBER');
          return '-';
        }
      },
    },
    {
      title: t('FIELD_BEHAVIOR'),
      dataIndex: 'behaviour',
      render: (row, record) =>
        editRow && record.id === editRow ? (
          <SelectCell
            initialValue={row.id}
            onCellUpdate={onCellEdit}
            style={{ width: 150 }}
            name="behaviour"
            allowClear
            options={optionsBehaviour}
          />
        ) : (
          <Tooltip title={row.description ? row.description : ''}>
            {row.name}
          </Tooltip>
        ),
    },
    {
      title: t('FIELD_ACCOUNTS'),
      render: (row, record) => {
        const auxAccountData =
          record.accounts && record.accounts[DIMENSIONS.ACCOUNTS.id];
        if (editRow && record.id === editRow) {
          return (
            <SelectCell
              initialValue={auxAccountData
                .asMutable()
                .map(accountId => accountId)}
              onCellUpdate={(name, value) =>
                onPrepareData(name, value, record.accounts)
              }
              style={{ width: 225 }}
              name="accounts"
              options={orderBySelection(
                auxAccountData.asMutable().map(accountId => String(accountId)),
                optionsAccount
              )}
              mode="multiple"
              allowClear
              maxTagCount={1}
              maxTagTextLength={11}
            />
          );
        } else {
          return renderTableCellLabel({
            values: auxAccountData,
            allValues: optionsAccount,
          });
        }
      },
    },
    {
      //NOTE: Refinar ui edición dimensión
      title: t('LABEL_DIMENSIONS'),
      render: (row, record) => {
        const dimensionsKeys = _.keys(record.accounts).filter(
          key => parseInt(key) !== DIMENSIONS.ACCOUNTS.id
        );
        const initialValue = [];
        for (const dimension in dataJoin.dimensions
          ? dataJoin.dimensions
          : record.accounts) {
          parseInt(dimension) !== DIMENSIONS.ACCOUNTS.id &&
            initialValue.push({
              dimension: parseInt(dimension),
              dimensionValue: dataJoin.dimensions
                ? [...dataJoin.dimensions[dimension]].map(id => id)
                : [...record.accounts[dimension]].map(id => id),
            });
        }
        if (editRow && record.id === editRow) {
          return (
            <div
              className="cell-edit"
              onClick={() => {
                setDimensionEditData({
                  selected: initialValue,
                  values: !dataJoin.accounts
                    ? {
                        [DIMENSIONS.ACCOUNTS.id]:
                          record.accounts[DIMENSIONS.ACCOUNTS.id],
                      }
                    : dataJoin.accounts,
                });
              }}
            >
              {renderDimensionLabel(dimensionsKeys)}
            </div>
          );
        } else {
          return renderTableCellLabel({
            values: dimensionsKeys,
            allValues: dimensionsList.dimensions,
          });
        }
      },
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'status',
      render: (row, record) => {
        if (editRow && record.id === editRow) {
          return (
            <SelectCell
              initialValue={row}
              onCellUpdate={onCellEdit}
              style={{ width: 90 }}
              name={'status'}
              allowClear
              options={[
                { id: 'active', name: t('STATE_TAG_ACTIVE') },
                { id: 'inactive', name: t('STATE_TAG_INACTIVE') },
              ]}
            />
          );
        } else {
          return row === 'active'
            ? t('STATE_TAG_ACTIVE')
            : t('STATE_TAG_INACTIVE');
        }
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (record, row) => {
        return record !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_VAR_DELETE_CONFIRM_TOOLTIP', { name: row.name })}
            okText={t('ACTION_DELETE')}
            okButtonProps={{ loading: isDeletingVariable }}
            onConfirm={() => onDeleteVariable(record)}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteVariableId(null)}
            visible={deleteVariableId === record}
          >
            <DropdownMenu
              title={t('ACTION_MORE')}
              menu={_.compact([
                {
                  title: t('ACTION_EDIT'),
                  icon: <EditOutlined />,
                  onClick: () => setEditRow(record),
                  disabled: !isUserAllowed(
                    SETTINGS__PROJECTION_ELEMENTS__VARIABLES__EDIT
                  ),
                },
                {
                  title: t('ACTION_DUPLICATE'),
                  icon: <CopyOutlined />,
                  onClick: () =>
                    onDuplicateRow({
                      name: t('DUPLICATE_NAME', { name: row.name }),
                      type_value: row.value_type,
                      behaviour: String(row.behaviour.id),
                      accounts: [...row.accounts],
                    }),
                  disabled: !isUserAllowed(
                    SETTINGS__PROJECTION_ELEMENTS__VARIABLES__DUPLICATE
                  ),
                },
                {
                  title: t('ACTION_DELETE'),
                  icon: <DeleteOutlined />,
                  onClick: () => setDeleteVariableId(record),
                  disabled: !isUserAllowed(
                    SETTINGS__PROJECTION_ELEMENTS__VARIABLES__DELETE
                  ),
                },
              ])}
            />
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={onEditVariable}
            dataCellEdit={dataCellEdit}
            loading={isTableLoading}
          />
        );
      },
    },
  ];

  const onCancel = () => {
    setEditRow(null);
    setDataCellEdit({});
    setDataJoin({ accounts: null, dimensions: null });
  };

  const onConfirm = () => {
    setEditRow(null);
    setDataCellEdit({});
  };

  const onEditVariable = () => {
    message.loading({
      key: 'loading_edit',
      content: t('FEEDBACK_LOADING_CHANGES'),
    });
    if (!_.isEmpty(dataCellEdit)) {
      editVariable(editRow, dataCellEdit)
        .then(() => {
          setIsTableLoading(true);
          message.destroy('loading_edit');
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          onRefreshData().then(() => setIsTableLoading(false));
          setEditRow(null);
          setDataCellEdit({});
          setDataJoin({ accounts: null, dimensions: null });
        })
        .catch(() => {
          message.destroy('loading_edit');
          message.error(t('FEEDBACK_SAVE_CHANGES_FAIL'));
          setEditRow(null);
          setDataCellEdit({});
          setDataJoin({ accounts: null, dimensions: null });
        });
    }
  };

  const onDeleteVariable = id => {
    setIsDeletingVariable(true);
    message.loading({
      key: 'loading_delete',
      content: t('FEEDBACK_LOADING_CHANGES'),
    });
    deleteVariable(id)
      .then(() => {
        setIsTableLoading(true);
        message.destroy('loading_delete');
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
        onRefreshData().then(() => setIsTableLoading(false));
        setDeleteVariableId(null);
        setIsDeletingVariable(false);
      })
      .catch(error => {
        message.destroy('loading_delete');
        message.error(t('FEEDBACK_DELETE_FAIL'));
        setDeleteVariableId(null);
        setIsDeletingVariable(false);
      });
  };

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        bordered
        columns={columns}
        onChange={handleTableChange}
        loading={isTableLoading || tableLoading}
        dataSource={dataSource}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total: dataSource.length,
          pageSizeOptions: ['10', '20', '30'],
          size: 'small',
          showSizeChanger: true,
        }}
      />
      {dimensionEditData && (
        <EditDimensionModal
          visible={dimensionEditData}
          onCancel={() => setDimensionEditData(null)}
          onConfirm={data => onCellEdit('accounts', data)}
          dimensionsSelected={dimensionEditData.selected}
          dataAccount={dimensionEditData.values}
          setDataJoin={setDataJoin}
          dataJoin={dataJoin}
          dimensionsList={dimensionsList}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  valuesDimension: configuration.selectors.getValuesDimension(state),
});

const mapDispatchToProps = {
  editVariable: configuration.actions.editVariable,
  deleteVariable: configuration.actions.deleteVariable,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariablesTable);
