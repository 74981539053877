import React from 'react';
import { Button, Modal, Space } from 'antd';
import { Typography } from 'modules/core/components';
import { useTranslation } from 'react-i18next';

const InformativeModal = ({ visible, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('CONFIG_ACCOUNTS_CREATE_LEVEL_ACTION')}
      visible={visible}
      centered
      closable={true}
      onCancel={onCancel}
      destroyOnClose
      width={568}
      footer={
        <Button type="primary" onClick={onConfirm}>
          {t('ACTION_UNDERSTAND')}
        </Button>
      }
    >
      <Space direction="vertical" size="middle">
        <Typography.Subtitle level={1}>
          {t('CONFIG_ACCOUNTS_INFORMATIVE_MODAL_TITLE')}
        </Typography.Subtitle>
        <Typography.Body level={2}>
          {t('CONFIG_ACCOUNTS_INFORMATIVE_MODAL_ITEM_ONE')}
        </Typography.Body>
        <Typography.Body level={2}>
          {t('CONFIG_ACCOUNTS_INFORMATIVE_MODAL_ITEM_TWO')}
        </Typography.Body>
      </Space>
    </Modal>
  );
};

export default InformativeModal;
