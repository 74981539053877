import i18n from 'i18next';
import { Select, Space, Popover, Spin } from 'antd';
import {
  renderInformativeIcon,
  renderBudgetSelect,
  renderForecastSelect,
} from '../../util';
import _ from 'lodash';

const ComparisonSelect = ({
  selections,
  onSelectId,
  consolidationsPredef,
  consolidationLists,
  loading = false,
}) => {
  return (
    <Space>
      {_.filter(selections, null).length === 1 &&
        renderInformativeIcon(selections.budget)}
      <Popover
        title={i18n.t('FIELD_PREDETERMINED_BUDGET')}
        placement="top"
        content={
          <div style={{ width: 300 }}>
            {_.filter(selections, null).length === 0
              ? i18n.t('POPOVER_NOT_DEFAULT_CONSOLIDATION_DESCRIPTION')
              : i18n.t('POPOVER_DEFAULT_CONSOLIDATION_DESCRIPTION')}
          </div>
        }
      >
        <Spin spinning={loading} size="small">
          <Select
            style={{ width: 210 }}
            value={
              !loading
                ? _.compact([selections.budget, selections.last_forecast])
                : undefined
            }
            showArrow
            showSearch
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children &&
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            maxTagCount={1}
            maxTagTextLength={6}
            onSelect={id => onSelectId(id)}
            disabled={_.filter(selections, null).length === 0}
          >
            {consolidationsPredef.budget &&
              renderBudgetSelect(consolidationLists.budget)}
            {consolidationsPredef.forecast &&
              renderForecastSelect({
                consoForecastList: consolidationLists.forecast,
              })}
          </Select>
        </Spin>
      </Popover>
    </Space>
  );
};

export default ComparisonSelect;
