import React from 'react';
import { Badge, Space } from 'antd';

const ChartCustomTooltip = ({ title, value, color }) => (
  <div
    style={{
      backgroundColor: 'white',
      padding: '0px 5px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    }}
  >
    <Space>
      <Badge color={color} />
      {title}
      <strong>{value}</strong>
    </Space>
  </div>
);

export default ChartCustomTooltip;
