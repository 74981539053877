import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { UploadModal } from 'modules/core/components';

const ConfigurationUploadModal = ({
  title,
  visible,
  onCancel,
  onSuccessUpload,
  uploadValuesXLS,
  setIsTableLoading,
  nameConfig = '',
  extraBody = null,
  afterOnClose = null,
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();

  const onCloseUploadModal = () => {
    onCancel();
    setFileList([]);
  };

  const onConfirmUpload = () => {
    setIsUploadingFile(true);
    setIsTableLoading(true);
    uploadValuesXLS(fileList)
      .then(() => {
        notification.success({
          message: t('FEEDBACK_VERY_GOOD'),
          description: t('FEEDBACK_UPLOAD_FILE_SUCCESS', {
            configModule: nameConfig,
          }),
          duration: 0,
        });
        onSuccessUpload().then(() => {
          setIsUploadingFile(false);
          setIsTableLoading(false);
        });
        onCloseUploadModal();
      })
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: !error.response ? error : error.response.data[0],
          duration: 0,
        });
        setIsUploadingFile(false);
        setIsTableLoading(false);
        onCloseUploadModal();
      });
  };

  const onAddUploadFile = file => {
    setFileList([file]);
    return false;
  };

  return (
    <UploadModal
      title={title}
      visible={visible}
      onCancel={onCloseUploadModal}
      onConfirm={onConfirmUpload}
      uploadProps={{
        name: 'file',
        accept: '.xls, .xlsx',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: file => setFileList([]),
        fileList: fileList,
      }}
      extraBody={extraBody}
      isUploadingFile={isUploadingFile}
      afterOnClose={afterOnClose}
    />
  );
};

export default ConfigurationUploadModal;
