const forecast = {
  // Actions
  FORECAST_CREATE_ACTION: 'Crear forecast',
  FORECAST_APPROVE_MODAL_TITLE: '{{action}} forecast',

  // Fields
  FORECAST_CUT_DATE_FIELD: 'Mes de corte:',

  // Messages
  FORECAST_NO_DATA_ALERT_TITLE:
    'Todavía no tenemos todos los datos que necesitamos para crear tu forecast',
  FORECAST_NO_DATA_ALERT_TEXT:
    'Para que puedas crear forecast debemos tener los presupuestos y datos reales publicados. Una vez que lo hagas, podrás crear tus re-presupuestaciones.',
  FORECAST_EMPTY_STATE_TEXT:
    'Identificá aquello que no evoluciona según lo planificado y re-definí tus objetivos. Modificá el futuro a corto y mediano plazo para disminuir riesgos',
  FORECAST_CREATE_TOOLTIP:
    'Para poder crear un nuevo forecast el anterior debe estar aprobado.',
  FORECAST_APPROVE_MODAL_TEXT:
    '¿Estás listo para aprobar el forecast? Recordá que una vez que lo hagas ya no podrás modificar ningún dato ni subir más archivos.',
  FORECAST_PUBLISH_MODAL_TEXT:
    'Estás por publicar tu forecast. En esta instancia podrás editar, seguir cargando datos, comparar forecast entre sí y visualizar tus datos en análisis y seguimiento.',

  FORECAST_SCREEN_SUCCESS_FEEDBACK:
    'Acabás de {{action}} el forecast del período {{period}} :) ',
  // ¿Querés darle la noticia a todos los que participaron de esta planificación?',

  FORECAST_CREATE_ERROR_FEEDBACK: 'No se pudo crear el forecast',
  FORECAST_DELETE_ERROR_FEEDBACK: 'No se pudo eliminar el forecast',
  FORECAST_EDIT_NAME_SUCCESS_FEEDBACK:
    'El nombre del forecast se editó correctamente.',
  FORECAST_EDIT_NAME_ERROR_FEEDBACK:
    'No pudimos editar el nombre del forecast. Por favor, volvé a intentarlo.',
  FORECAST_DELETE_POPCONFIRM:
    '¿Estás seguro que deseas eliminar este forecast?',

  FORECAST_EMPTY_CARD_DESCRIPTION: 'Ingresá y comenzá a re-presupuestar',

  FORECAST_ALERT_INFO_TITLE: 'Los meses con datos reales no son editables',
  FORECAST_ALERT_INFO_DESCRIPTION:
    'Desde acá vas a poder trabajar en la re-definición de tu presupuesto, por lo que verás editables los meses en los que aún no hay datos reales cargados y publicados.',
  FORECAST_TOOLTIP_NOT_UPLOAD_FILE:
    'No se puede cargar archivo porque el forecast ya está aprobado',
  FORECAST_UPLOAD_SCREEN_DESCRIPTION: 'Comenzá la carga de tu proyección',

  // XLS names
  FORECAST_TEMPLATE_NAME:
    'Plantilla Plika - Forecast {{concept}} - {{periodName}}',
  FORECAST_EVOLUTION_TABLE_FILE_NAME:
    'Plika - Forecast {{concept}} - {{periodName}} (evolución mensual)',
  FORECAST_CONSOLIDATION_EVOLUTION_TABLE_FILE_NAME: `Plika - Forecast {{consoName}}({{reportName}}) - {{periodName}} (Consolidación)`,

  //Empty screen
  FORECAST_EMPTY_SCREEN_TITLE:
    '¡Aún no tenés ningún forecast de {{concept}} creado!',
  FORECAST_EMPTY_SCREEN_BODY:
    'Creá el forecast, establecé prioridades y construí la cantidad de proyecciones así cómo diferentes escenarios tengas',
};

export default forecast;
