import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePagination } from 'modules/core/customHooks';
import _ from 'lodash';
import { Table, Row, Col, Button, Tag, Alert, Tooltip } from 'antd';
import {
  CloseCircleOutlined,
  CopyOutlined,
  // DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  RangePicker,
  DropdownMenu,
  EmptyScreen,
  Typography,
} from 'modules/core/components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES, CONCEPTS } from 'modules/core/constants';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import initialColumns from './columns';
import login from 'modules/login';
import distributions from 'modules/distributions';
import planning from 'modules/planning';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import control from 'modules/control';

const {
  CONTROL__DISTRIBUTIONS__CREATE,
  CONTROL__DISTRIBUTIONS__DUPLICATE,
  CONTROL__DISTRIBUTIONS__DELETE,
} = ROLES;

const DistributionsList = ({
  loggedUser: { groups },
  period: { periodId, periodName },
  distributionList: { count, values },
  fetchDistributions,
  downloadDistributionsXLS,
  downloadSingleDistributionsXLS,
  duplicateDistribution,
  approveDates,
  fetchApproveDates,
  cleanPublishedDates,
  periodDetail,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [selectedDates, setSelectedDates] = useState({});
  const [tablePagination, setTablePagination] = usePagination();
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    fetchApproveDates(periodId, { concept_type: CONCEPTS.TYPE.ECONOMIC_KEY })
      .then(() => null)
      .catch(() => null);
    return () => {
      cleanPublishedDates();
    };
  }, [fetchApproveDates, cleanPublishedDates, periodId]);

  useEffect(() => {
    setIsTableLoading(true);
    fetchDistributions(periodId, {
      ...tablePagination,
      ...selectedDates,
    }).then(() => setIsTableLoading(false));
  }, [fetchDistributions, tablePagination, selectedDates, periodId]);

  const userCanCreate = isUserAllowed(CONTROL__DISTRIBUTIONS__CREATE);

  const renderTopAlert = () => (
    <Alert
      showIcon
      closable
      type="warning"
      message={t('DISTRIBUTION_NO_DATA_ALERT_TITLE')}
      description={t('DISTRIBUTION_NO_DATA_ALERT_TEXT')}
    />
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('DISTRIBUTION_EMPTY_STATE_TITLE')}
      description={t('DISTRIBUTION_EMPTY_STATE_TEXT')}
      footer={
        userCanCreate && (
          <Tooltip
            title={
              approveDates.length === 0
                ? t('DISTRIBUTION_EMPTY_STATE_APPROVE_DATA_TOOLTIP')
                : ''
            }
          >
            <Button
              type="primary"
              onClick={() =>
                history.push(
                  `/seguimiento/${periodId}/economico/distribuciones/`
                )
              }
              disabled={approveDates.length === 0}
            >
              {t('DISTRIBUTION_CREATE_ACTION')}
            </Button>
          </Tooltip>
        )
      }
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      {userCanCreate && (
        <Button
          type="primary"
          onClick={() =>
            history.push(`/seguimiento/${periodId}/economico/distribuciones/`)
          }
        >
          {t('DISTRIBUTION_CREATE_ACTION')}
        </Button>
      )}
    </ButtonActionBar>
  );

  const renderTableActions = () => (
    <ButtonActionBar>
      <Button
        type="text"
        shape="circle"
        title={t('ACTION_DOWNLOAD_TABLE')}
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={() =>
          downloadDistributionsXLS(
            t('DISTRIBUTION_DATA_TABLE_FILE_NAME', { periodName: periodName })
          )
        }
      />
    </ButtonActionBar>
  );

  const renderAdjustmentTable = () => {
    const columns = [
      ...initialColumns(t),
      {
        title: t('FIELD_ACTIONS'),
        dataIndex: 'id',
        align: 'right',
        width: 200,
        render: (record, row) => (
          <ButtonActionBar>
            {row.canceled ? <Tag>{t('STATE_TAG_CANCELED')}</Tag> : ''}
            <Button
              type="text"
              shape="circle"
              onClick={() =>
                history.push(
                  `/seguimiento/${periodId}/economico/distribuciones/${record}`
                )
              }
              icon={<Typography.Icon icon={EyeOutlined} />}
              title={t('DISTRIBUTION_VIEW_ACTION_TITLE')}
            />
            <DropdownMenu
              title={t('ACTION_MORE')}
              menu={_.compact([
                {
                  title: t('ACTION_DUPLICATE'),
                  icon: <CopyOutlined />,
                  onClick: () => {
                    duplicateDistribution(record).then(
                      history.push(
                        `/seguimiento/${periodId}/economico/distribuciones?duplicatedId=${record}`
                      )
                    );
                  },
                  disabled: !isUserAllowed(CONTROL__DISTRIBUTIONS__DUPLICATE),
                },
                ...[
                  !row.canceled && {
                    title: t('ACTION_ANULATE'),
                    icon: <CloseCircleOutlined />,
                    onClick: () =>
                      history.push(
                        `/seguimiento/${periodId}/economico/distribuciones/${record}`
                      ),
                    disabled: !isUserAllowed(CONTROL__DISTRIBUTIONS__DELETE),
                  },
                ],
                {
                  title: t('ACTION_DOWNLOAD'),
                  icon: <DownloadOutlined />,
                  onClick: () =>
                    downloadSingleDistributionsXLS(
                      record,
                      t('DISTRIBUTION_DATA_ROW_FILE_NAME', {
                        periodName: periodName,
                      })
                    ),
                },
              ])}
            />
          </ButtonActionBar>
        ),
      },
    ];

    return (
      <Table
        rowKey="id"
        size="small"
        bordered
        loading={isTableLoading}
        onChange={setTablePagination}
        columns={columns}
        dataSource={values}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total: count,
          size: 'small',
          showSizeChanger: false,
        }}
      />
    );
  };

  return (
    <>
      {!isTableLoading && count <= 0 && _.isEmpty(selectedDates) && (
        <Row gutter={[24, 24]}>
          {approveDates.length === 0 && (
            <Col span={24}> {renderTopAlert()}</Col>
          )}
          <Col span={24}>{renderNoDataScreen()}</Col>
        </Row>
      )}

      {(count > 0 || (count === 0 && !_.isEmpty(selectedDates))) && (
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>
            <RangePicker
              onSetRange={setSelectedDates}
              periodDate={{
                startDate: periodDetail?.start_date,
                endDate: periodDetail?.end_date,
              }}
              defaultPickerValue={
                !_.isEmpty(periodDetail)
                  ? [
                      moment(periodDetail?.start_date),
                      moment(periodDetail?.end_date),
                    ]
                  : null
              }
              defaultValue={
                !_.isEmpty(selectedDates) && [
                  moment(selectedDates['start-date'], 'YYYY-MM-01'),
                  moment(selectedDates['end-date'], 'YYYY-MM-01'),
                ]
              }
            />
          </Col>
          <Col span={24}>{renderTableActions()}</Col>
          <Col span={24}>{renderAdjustmentTable()}</Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  distributionList: distributions.selectors.getDistributionList(state),
  approveDates: control.selectors.getApproveDates(state),
});

const mapDispatchToProps = {
  fetchDistributions: distributions.actions.fetchDistributions,
  downloadDistributionsXLS: distributions.actions.downloadDistributionsXLS,
  downloadSingleDistributionsXLS:
    distributions.actions.downloadSingleDistributionsXLS,
  duplicateDistribution: distributions.actions.duplicateDistribution,
  fetchDistributionDetail: distributions.actions.fetchDistributionDetail,
  cancelDistribution: distributions.actions.cancelDistribution,
  fetchApproveDates: control.actions.fetchApproveDates,
  cleanPublishedDates: planning.actions.cleanPublishedDates, //TODO: chsumear esta funcion ya que se esta basando en datos publicados y con esto nuevo de flujo tendría que ser con los aprobados
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributionsList);
