export const NAME = 'login';

// ++ LOGIN ++
export const USER_LOGIN = `${NAME}/USER_LOGIN`;
export const USER_LOGIN_SUCCESS = `${NAME}/USER_LOGIN_SUCCESS`;
export const USER_LOGIN_FAIL = `${NAME}/USER_LOGIN_FAILED`;

export const USER_LOGOUT = `${NAME}/USER_LOGOUT`;

export const USER_LOGIN_CONFIRM_PASSWORD = `${NAME}/USER_LOGIN_CONFIRM_PASSWORD`;

// ++ CHANGE PASSWORD
export const USER_CHANGE_PASSWORD = `${NAME}/USER_CHANGE_PASSWORD`;
export const USER_CHANGE_PASSWORD_SUCCESS = `${NAME}/USER_CHANGE_PASSWORD_SUCCESS`;
export const USER_CHANGE_PASSWORD_FAIL = `${NAME}/USER_CHANGE_PASSWORD_FAILED`;

// ++ WHO AM I ++
export const USER_WHO_AM_I = `${NAME}/USER_WHO_AM_I`;
export const USER_WHO_AM_I_SUCCESS = `${NAME}/USER_WHO_AM_I_SUCCESS`;
export const USER_WHO_AM_I_FAIL = `${NAME}/USER_WHO_AM_I_FAIL`;

// ++ SETTING LANG
export const USER_SETTING_LANG = `${NAME}/USER_SETTING_LANG`;
export const USER_SETTING_LANG_SUCCESS = `${NAME}/USER_SETTING_LANG_SUCCESS`;
export const USER_SETTING_LANG_FAIL = `${NAME}/USER_SETTING_LANG_FAILED`;

// RESET PASSWORD

export const EMAIL_CONFIRM = `${NAME}/EMAIL_CONFIRM`;
export const RESET_PASSWORD = `${NAME}/RESET_PASSWORD`;
