import React, { useState } from 'react';
import { notification } from 'antd';
import { UploadModal } from 'modules/core/components';

const ProjectionUploadModal = ({
  title,
  visible,
  onCancel,
  downloadTemplateFile = null,
  uploadFile,
  onSuccessUpload = null,
  onFailUpload = null,
  extraBody = null,
  disabled = false,
  disabledTooltip = '',
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileList, setFileList] = useState([]);

  const onCloseUploadModal = () => {
    onCancel();
    setFileList([]);
  };

  const onConfirmUpload = () => {
    setIsUploadingFile(true);
    notification.close('error_file');
    notification.close('warning_file');
    uploadFile(fileList)
      .then(response => {
        onSuccessUpload && onSuccessUpload(response);
        setFileList([]);
        setIsUploadingFile(false);
        onCloseUploadModal();
      })
      .catch(error => {
        onFailUpload && onFailUpload(error);
        setFileList([]);
        setIsUploadingFile(false);
        onCloseUploadModal();
      });
  };

  const onAddUploadFile = file => {
    setFileList([file]);
    return false;
  };

  return (
    <UploadModal
      title={title}
      visible={visible}
      onCancel={onCloseUploadModal}
      onConfirm={onConfirmUpload}
      downloadTemplateFile={downloadTemplateFile}
      uploadProps={{
        name: 'file',
        accept: '.xls, .xlsx',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: file => setFileList([]),
        fileList: fileList,
      }}
      isUploadingFile={isUploadingFile}
      extraBody={extraBody}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
    />
  );
};

export default ProjectionUploadModal;
