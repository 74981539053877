import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Space, Popover } from 'antd';
import { Typography } from 'modules/core/components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const RuleAlert = ({ ruleId, name }) => {
  let { t } = useTranslation();
  let history = useHistory();

  return (
    <Space size="middle" direction="horizontal">
      {name}
      <Popover
        title={`${t('LABEL_BUSINESS_RULE')} ${name}`}
        content={
          <>
            <Typography.Body type="primary" level={2}>
              {`¡${t('CONFIG_FORMULAS_INFORMATIVE_TOOLTIP')}!`}
            </Typography.Body>
            <div>
              <span
                className="rule-table-alert"
                onClick={() =>
                  history.push(
                    `/configuracion/elementosdeproyeccion/regla/${ruleId}`
                  )
                }
              >
                {t('ACTION_GO_TO_DETAIL')}
              </span>
              {` ${t('PLANNING_FORMULA_GO_TO_LOAD_FACTOR_TEXT')}`}
            </div>
          </>
        }
      >
        <Typography.Icon icon={ExclamationCircleOutlined} type="warning" />
      </Popover>
    </Space>
  );
};

export default RuleAlert;
