import i18n from 'i18next';
import { Tooltip, Select, Space, Popover, Spin } from 'antd';
import {
  renderInformativeIcon,
  renderBudgetSelect,
  renderForecastSelect,
} from '../../util';
import { PROJECTION } from 'modules/core/constants';
import _ from 'lodash';

const { Option } = Select;

const EvolutionSelect = ({
  activeOptionTable,
  setActiveByPeriodTable,
  dataConsolidatedDefault,
  consolidationsPredef,
  consolidationLists: { budget = [], forecast = [] },
  loading = false,
  initialSituation = null,
}) => {
  return (
    <Space>
      {_.filter(dataConsolidatedDefault, null).length === 1 &&
        renderInformativeIcon(consolidationsPredef.budget)}
      <Popover
        title={i18n.t('FIELD_PREDETERMINED_BUDGET')}
        placement="top"
        content={
          <div style={{ width: 300 }}>
            {_.filter(dataConsolidatedDefault, null).length === 0
              ? i18n.t('POPOVER_NOT_DEFAULT_CONSOLIDATION_DESCRIPTION')
              : i18n.t('POPOVER_DEFAULT_CONSOLIDATION_DESCRIPTION')}
          </div>
        }
      >
        <Spin spinning={loading} size="small">
          <Select
            className="report-detail-table-actions-select"
            value={
              !loading &&
              `${activeOptionTable.value}${
                activeOptionTable.id ? `-${activeOptionTable.id}` : ''
              }`
            }
            showArrow
            showSearch
            onChange={value =>
              setActiveByPeriodTable(
                value.includes('-')
                  ? {
                      value: _.split(value, '-', 1)[0],
                      id: _.split(value, '-', 2)[1],
                    }
                  : { value: value, id: null }
              )
            }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children &&
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {budget.length !== 0 &&
              consolidationsPredef.budget &&
              renderBudgetSelect(budget)}
            <Option value={PROJECTION.REAL} title={i18n.t('REAL')}>
              {i18n.t('REAL')}
            </Option>
            <Option
              value={PROJECTION.REAL_LAST_PERIOD}
              disabled={
                initialSituation &&
                !initialSituation[PROJECTION.REAL_LAST_PERIOD]
              }
              title={i18n.t('REAL_LAST_PERIOD')}
            >
              <Tooltip
                placement="right"
                title={
                  initialSituation &&
                  !initialSituation[PROJECTION.REAL_LAST_PERIOD]
                    ? i18n.t(
                        'REPORT_FINANCIAL_NO_DATA_INFORMATIVE_TOOLTIP_TITLE'
                      )
                    : ''
                }
              >
                {i18n.t('REAL_LAST_PERIOD')}
              </Tooltip>
            </Option>
            {consolidationsPredef.forecast &&
              renderForecastSelect({
                consoForecastList: forecast,
                disabled:
                  initialSituation &&
                  !initialSituation[PROJECTION.LAST_FORECAST],
              })}
          </Select>
        </Spin>
      </Popover>
    </Space>
  );
};

export default EvolutionSelect;
