import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePagination, useSorter, useToggle } from 'modules/core/customHooks';
import {
  DownloadOutlined,
  FileExcelOutlined,
  LeftCircleOutlined,
  DeleteOutlined,
  CopyOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import {
  Tabs,
  Button,
  Row,
  Col,
  notification,
  message,
  Popconfirm,
  Switch,
  Tooltip,
} from 'antd';
import {
  UploadDraggerScreen,
  Typography,
  TableSettingsModal,
  ButtonActionBar,
  DropdownMenu,
  ProjectionUploadModal,
  projectionSuccessUploadNotice,
  ShowCommentsButton,
  DeleteSelectionButton,
  References,
} from 'modules/core/components';
import {
  isInElaboration,
  isPublished,
  isApplied,
  isUserAllowed,
  formItemsHelper,
} from 'modules/core/utils';
import {
  ROLES,
  CONCEPTS,
  TABLES,
  COLUMNS,
  PROJECTION,
} from 'modules/core/constants';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import FactorTable from './FactorTable';
import ReplicateFactorModal from './ReplicateFactorModal';
import planning from 'modules/planning';
import login from 'modules/login';

const FACTOR_CELL_FORMAT = '0,0.00';
const INITIAL_STATE_SWITCH = { apply: false, visiblePopConfirm: false };

//TODO: AGREGAR ROLES FORECAST CUANDO ESTEN
const {
  PLANNING__EXPENSES__CREATE,
  PLANNING__SALES__CREATE,
  PLANNING__EXPENSES__EDIT,
  PLANNING__SALES__EDIT,
  PLANNING__EXPENSES__DELETE,
  PLANNING__SALES__DELETE,
  PLANNING__EXPENSES__UPLOAD,
  PLANNING__SALES__UPLOAD,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PROJECTION__CONCEPT = {
  [PROJECTION.BUDGET]: {
    [CONCEPTS.KEYS.SALES]: {
      EDIT: [PLANNING__SALES__CREATE, PLANNING__SALES__EDIT],
      DELETE: PLANNING__SALES__DELETE,
      UPLOAD: PLANNING__SALES__UPLOAD,
    },
    [CONCEPTS.KEYS.EXPENSES]: {
      EDIT: [PLANNING__EXPENSES__CREATE, PLANNING__EXPENSES__EDIT],
      DELETE: PLANNING__EXPENSES__DELETE,
      UPLOAD: PLANNING__EXPENSES__UPLOAD,
    },
  },
  [PROJECTION.FORECAST]: {
    [CONCEPTS.KEYS.SALES]: {
      EDIT: [PLANNING__SALES__CREATE, PLANNING__SALES__EDIT],
      DELETE: PLANNING__SALES__DELETE,
      UPLOAD: PLANNING__SALES__UPLOAD,
    },
    [CONCEPTS.KEYS.EXPENSES]: {
      EDIT: [PLANNING__EXPENSES__CREATE, PLANNING__EXPENSES__EDIT],
      DELETE: PLANNING__EXPENSES__DELETE,
      UPLOAD: PLANNING__EXPENSES__UPLOAD,
    },
  },
};

const ProjectionFactorsDetail = ({
  factoresList,
  factorColumns,
  filterTableOptions,
  factorRows,
  fetchFactorColumns,
  fetchProjectionFilters,
  fetchFactorRows,
  formulaId,
  formulaData,
  factorId,
  setFactorId,
  projectionId,
  projection,
  downloadTemplateXLS,
  uploadProjectionXLS,
  setShowFactorsDetail,
  updateTransactionFactor,
  deleteFactorRow,
  formulaApplyData,
  downloadProjectionXLS,
  onFetchAmountList,
  unApplyFormula,
  applyFormula,
  deleteAllFactorRows,
  syncProjectionComment,
  createTransactionComment,
  deleteTransactionComment,
  loggedUser: { email },
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isColumnsLoading, setIsColumnsLoading] = useState(false);
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS(email),
    format: FACTOR_CELL_FORMAT,
    showNegativeInRed: false,
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
  });
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [isDeletingSelection, setIsDeletingSelection] = useState(false);
  const [dataSelection, setDataSelection] = useState([]);
  const [showRowsComment, toggleShowRowsComment] = useToggle(false);
  const [filters, setFilters] = useState({});
  const [tableSorter, setTableSorter] = useSorter();
  const [refetchFilters, setRefetchFilters] = useState(true);
  const [formulaSwitch, setFormulaSwitch] = useState(INITIAL_STATE_SWITCH);
  const [isActionApplying, setIsActionAppying] = useState(false);
  const [tablePagination, setTablePagination] = usePagination();
  const [showReplicateFactor, setShowReplicateFactor] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  const { t } = useTranslation();

  useEffect(() => {
    setIsColumnsLoading(true);
    fetchFactorColumns(formulaId, { projection_id: projectionId })
      .then(() => setIsColumnsLoading(false))
      .catch(() => {});
  }, [fetchFactorColumns, formulaId, projectionId]);

  useEffect(() => {
    if (projection.concept.id && refetchFilters) {
      fetchProjectionFilters(projection.concept.id, {
        factor_id: factorId,
        projection: projectionId,
        ...filters,
      })
        .then(() => setRefetchFilters(false))
        .catch(() => setRefetchFilters(false));
    }
  }, [
    fetchProjectionFilters,
    factorId,
    projectionId,
    projection,
    refetchFilters,
    filters,
  ]);

  useEffect(() => {
    setIsTableLoading(true);
    fetchFactorRows({
      factorId,
      projectionId,
      ...tablePagination,
      ...filters,
      ...tableSorter,
    })
      .then(() => setIsTableLoading(false))
      .catch(() => {});
  }, [
    fetchFactorRows,
    factorId,
    projectionId,
    tablePagination,
    filters,
    tableSorter,
  ]);

  const canUserEdit = isUserAllowed(
    PROJECTION__CONCEPT[projection.type][projection.concept.name].EDIT
  );

  const canUserUpload = isUserAllowed(
    PROJECTION__CONCEPT[projection.type][projection.concept.name].UPLOAD
  );

  const canUserDelete = isUserAllowed(
    PROJECTION__CONCEPT[projection.type][projection.concept.name].DELETE
  );

  const factorName = factoresList.find(factor => factor.id === factorId).name;

  const handleOnChangeTab = factorId => {
    setFactorId(parseInt(factorId));
    setTablePagination({ current: 1 });
    setRefetchFilters(true);
  };

  const onSuccessUploadFile = response => {
    projectionSuccessUploadNotice({
      projectionType: 'factor',
      errorFile: response.payload.data.error_file,
    });
    setTablePagination({ current: 1 });
    setIsTableLoading(true);
    setRefetchFilters(true);
    Promise.all([
      fetchFactorRows({
        factorId,
        projectionId,
        ...tablePagination,
        ...filters,
        ...tableSorter,
      }),
      onFetchAmountList(),
      fetchFactorColumns(formulaId, { projection_id: projectionId }),
    ])
      .then(() => setIsTableLoading(false))
      .catch(() => {});
  };

  const onFailUploadFile = error =>
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: error.response.data,
      duration: 0,
    });

  const onDeleteAll = () => {
    setIsTableLoading(true);
    setRefetchFilters(true);
    deleteAllFactorRows(factorId, { projection_id: projectionId })
      .then(() => {
        setShowDeleteAll(false);
        fetchFactorRows({
          factorId,
          projectionId,
          ...tablePagination,
          ...filters,
          ...tableSorter,
        }).then(() => {
          setIsTableLoading(false);
          message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
        });
      })
      .catch(error => {
        setIsTableLoading(false);
        setShowDeleteAll(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error.response.data[0],
          duration: 0,
        });
      });
  };

  const handleDeleteSelection = () => {
    setIsDeletingSelection(true);
    Promise.all(
      dataSelection.map(id =>
        deleteFactorRow(factorId, { row_id: id }).catch(() =>
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_DELETE_SELECTION_ERROR'),
            duration: 0,
          })
        )
      )
    ).then(() => {
      setRefetchFilters(true);
      Promise.all([
        fetchFactorRows({
          factorId,
          projectionId,
          ...tablePagination,
          ...filters,
          ...tableSorter,
        }),
        onFetchAmountList(),
      ]).then(() => {
        setIsDeletingSelection(false);
        message.success(t('FEEDBACK_DELETE_SELECTION_SUCCESS'));
      });
      setDataSelection([]);
    });
  };

  const handleCellEdit = data => {
    message.loading({
      key: 'loading_edit',
      content: t('FEEDBACK_LOADING_CHANGES'),
    });
    updateTransactionFactor(data)
      .then(() => {
        Promise.all([
          fetchFactorRows({
            factorId,
            projectionId,
            ...tablePagination,
            ...filters,
            ...tableSorter,
          }),
          onFetchAmountList(),
        ]).then(() => {
          message.destroy('loading_edit');
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES', 0.5));
        });
      })
      .catch(() => {
        message.error(t('FEEDBACK_DEFAULT_ERROR'));
        setIsTableLoading(false);
      });
  };

  const handleOnChangeTable = (pagination, filters, sorter) => {
    setTablePagination(pagination);
    setTableSorter(sorter);
  };

  const handleApplyFormula = () => {
    const applyFn = formulaSwitch.apply ? applyFormula : unApplyFormula;
    setIsActionAppying(true);
    applyFn(formulaId, { projection_id: projectionId })
      .then(response => {
        Promise.all([
          onFetchAmountList(),
          fetchFactorRows({
            factorId,
            projectionId,
            ...tablePagination,
            ...filters,
            ...tableSorter,
          }),
        ]).then(() => {
          setIsActionAppying(false);
          setFormulaSwitch(INITIAL_STATE_SWITCH);
          notification.success({
            message: t('LABEL_BUSINESS_RULE'),
            description: response.payload.data[0],
          });
        });
      })
      .catch(error => {
        setIsActionAppying(false);
        setFormulaSwitch(INITIAL_STATE_SWITCH);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error.response.data[0],
          duration: 0,
        });
      });
  };

  const onCommentCell = ({ columnId, rowId, transactionId, text }) => {
    createTransactionComment({ transactionId, text, factorId })
      .then(response => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'factorRows',
          newComment: response.payload.data,
        });
      })
      .catch(() => message.error(t('FEEDBACK_DEFAULT_ERROR')));
  };

  const onDeleteCommentCell = ({ columnId, rowId, commentId }) => {
    deleteTransactionComment({ commentId })
      .then(() => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'factorRows',
          commentId,
        });
      })
      .catch(() => message.error(t('FEEDBACK_DEFAULT_ERROR')));
  };

  const renderEmptyScreen = () => (
    <UploadDraggerScreen
      disabled={!canUserUpload}
      description={t('PLANNING_FACTOR_EMPTY_STATE_TITLE')}
      downloadTemplate={() =>
        downloadTemplateXLS(
          projectionId,
          `${t('PLANNING_TEMPLATE_NAME', {
            concept: projection.name,
            periodName: projection.period.name,
          })}  (${
            factoresList.filter(factor => factor.id === factorId)[0].name
          })`,
          { factor_id: factorId }
        )
      }
      uploadFile={fileList =>
        uploadProjectionXLS(projectionId, fileList, { factor_id: factorId })
      }
      onSuccessUpload={onSuccessUploadFile}
      onFailUpload={onFailUploadFile}
    />
  );

  const renderGoBackButton = () => (
    <Button
      style={{ padding: 0 }}
      type="link"
      onClick={() => setShowFactorsDetail(false)}
    >
      <LeftCircleOutlined title={t('PLANNING_FACTOR_BACK_TO_RULES_ACTION')} />
      <Typography.Body>
        {formulaData.find(formula => formula.id === formulaId).name}
      </Typography.Body>
    </Button>
  );

  const renderApplySwitch = () => (
    <ButtonActionBar>
      <Popconfirm
        placement="bottomRight"
        title={
          t('PLANING_FORMULA_UNAPPLY_POPCONFIRM_TEXT', {
            action: formulaSwitch.apply
              ? t('ACTION_APPLY').toLowerCase()
              : t('ACTION_UNAPPLY').toLowerCase(),
          })
          // NOTE: comentado hasta que se pueda aplicar aunque no esten todos los valores
          // dataAction.actionName === 'unApply'
          //   ? t('PLANING_FORMULA_UNAPPLY_POPCONFIRM_TEXT', {
          //       action: t('ACTION_UNAPPLY').toLowerCase(),
          //     })
          //   : formulaApplyData && !formulaApplyData.all_factors_loaded
          //   ? t('PLANNING_FORMULA_APPLY_POPCONFIRM_PENDING_TEXT')
          //   : t('PLANING_FORMULA_UNAPPLY_POPCONFIRM_TEXT', {
          //       action: t('ACTION_APPLY').toLowerCase(),
          //     })
        }
        okText={formulaSwitch.apply ? t('ACTION_APPLY') : t('ACTION_UNAPPLY')}
        okButtonProps={{ loading: isActionApplying }}
        onConfirm={handleApplyFormula}
        cancelText={t('ACTION_CANCEL')}
        onCancel={() => setFormulaSwitch(INITIAL_STATE_SWITCH)}
        visible={formulaSwitch.visiblePopConfirm}
      >
        <Tooltip
          placement="left"
          title={
            formulaApplyData && isApplied(formulaApplyData.status)
              ? t('PLANNING_FORMULA_UNAPPLY_TOOLTIP_TITLE')
              : !formulaApplyData
              ? t('PLANNING_FORMULA_LOAD_DATA_TOOLTIP_TITLE')
              : t('PLANNING_FORMULA_APPLY_TOOLTIP_TITLE')
          }
        >
          <Switch
            checkedChildren={t('FIELD_APPLIED')}
            unCheckedChildren={t('FIELD_UNAPPLIED')}
            disabled={!formulaApplyData}
            checked={formulaApplyData && isApplied(formulaApplyData.status)}
            onChange={state =>
              setFormulaSwitch({ visiblePopConfirm: true, apply: state })
            }
          />
        </Tooltip>
      </Popconfirm>
    </ButtonActionBar>
  );

  const renderActionsTable = () => {
    return (
      <Row>
        <Col span={12}>
          <References
            referencesItems={[
              ...(functionalCurrencyData
                ? [
                    {
                      type: <DollarOutlined />,
                      title:
                        functionalCurrencyData && functionalCurrencyData.code,
                      description:
                        functionalCurrencyData &&
                        t('REFERENCE_DESCRIPTION', {
                          currencyName: t(
                            functionalCurrencyData.name
                          ).toLowerCase(),
                        }),
                    },
                  ]
                : []),
            ]}
          />
          <ShowCommentsButton onClick={toggleShowRowsComment} />
          {dataSelection.length > 0 && (
            <DeleteSelectionButton
              onConfirm={handleDeleteSelection}
              disabled={!canUserDelete}
              isDeletingAll={
                dataSelection.length === tablePagination.page_size ||
                dataSelection.length === factorRows.count
              }
            />
          )}
        </Col>
        <Col span={12}>
          <ButtonActionBar>
            <Button
              type="text"
              shape="circle"
              icon={<Typography.Icon icon={DownloadOutlined} />}
              onClick={() =>
                downloadProjectionXLS(
                  projectionId,
                  t('PLANNING_EVOLUTION_TABLE_FILE_NAME', {
                    concept: projection.name,
                    periodName: projection.period.name,
                    loadTypeName: factorName,
                  }),
                  { factor_id: factorId, ...filters }
                )
              }
              title={t('ACTION_DOWNLOAD_TABLE')}
            />
            <TableSettingsModal
              settings={tableSettings}
              onConfirm={setTableSettings}
              options={factorColumns.filter(
                (column, index) =>
                  !column.is_transaction &&
                  index !== COLUMNS.FIRST_INDEX &&
                  column.data_index !== COLUMNS.TOTAL_DATA_INDEX
              )}
              formItems={formItemsHelper.itemsDataView(
                tableSettings.negativeValuesType,
                tableSettings.showNegativeInRed
              )}
            />
          </ButtonActionBar>
        </Col>
      </Row>
    );
  };

  const handleOnFilter = newFilters => {
    setFilters(newFilters);
    setTablePagination({ current: 1 });
    setRefetchFilters(true);
  };

  const renderFactorsTable = () => (
    <FactorTable
      dataSource={factorRows.transactions}
      columns={factorColumns.filter(
        column => column.data_index !== COLUMNS.TOTAL_DATA_INDEX
      )}
      onChange={handleOnChangeTable}
      loading={isTableLoading || isActionApplying || isDeletingSelection}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: factorRows.count,
      }}
      tableSettings={tableSettings}
      canDelete={
        canUserDelete &&
        (isInElaboration(projection.status) ||
          isPublished(projection.status)) &&
        formulaApplyData &&
        !isApplied(formulaApplyData.status)
      }
      dataSelection={{
        selection: dataSelection,
        setSelection: data => setDataSelection(data),
      }}
      onCellEdit={handleCellEdit}
      editable={
        canUserEdit &&
        (isInElaboration(projection.status) ||
          isPublished(projection.status)) &&
        (!formulaApplyData || !isApplied(formulaApplyData.status))
      }
      filters={filters}
      filterOptions={filterTableOptions}
      onFilter={handleOnFilter}
      comments={{
        showRowsComment,
        onComment: onCommentCell,
        onDeleteComment: onDeleteCommentCell,
      }}
      projectionType={projection.type}
    />
  );

  const renderActionsUser = () => (
    <ButtonActionBar>
      <Tooltip
        title={
          formulaApplyData && isApplied(formulaApplyData.status)
            ? t('PLANNING_FORMULA_BUTTON_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE')
            : ''
        }
      >
        <Button
          type="primary"
          onClick={() => setShowUploadModal(true)}
          disabled={
            !canUserEdit ||
            (formulaApplyData && isApplied(formulaApplyData.status))
          }
        >
          {t('ACTION_UPLOAD_FILE')}
        </Button>
      </Tooltip>
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_DOWNLOAD_TEMPLATE'),
            icon: <FileExcelOutlined />,
            onClick: () =>
              downloadTemplateXLS(
                projectionId,
                `${t('PLANNING_TEMPLATE_NAME', {
                  concept: projection.name,
                  periodName: projection.period.name,
                })}  (${
                  factoresList.filter(factor => factor.id === factorId)[0].name
                })`,
                { factor_id: factorId }
              ),
          },
          {
            title: t('CONFIG_REPLICATE_FACTOR_TITLE'),
            icon: <CopyOutlined />,
            disabled: !canUserEdit,
            onClick: () => setShowReplicateFactor(true),
          },
          {
            title: t('ACTION_DELETE_ALL'),
            icon: <DeleteOutlined />,
            onClick: () => setShowDeleteAll(true),
            disabled:
              !canUserDelete ||
              !(
                isInElaboration(projection.status) ||
                isPublished(projection.status)
              ),
          },
        ]}
      />
      <Popconfirm
        placement="left"
        title={t('POPCONFIRM_DELETE_ALL_PAGES', {
          projectionName: factorName,
        })}
        okText={t('ACTION_DELETE')}
        onConfirm={onDeleteAll}
        cancelText={t('ACTION_CANCEL')}
        onCancel={() => setShowDeleteAll(false)}
        visible={showDeleteAll}
      />
    </ButtonActionBar>
  );

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>{renderGoBackButton()}</Col>
      <Col span={12}>{renderApplySwitch()}</Col>
      <Col span={24}>
        <Tabs
          size="small"
          defaultActiveKey={factorId}
          onChange={handleOnChangeTab}
        >
          {factoresList.map(factor => (
            <Tabs.TabPane key={factor.id} tab={factor.name}>
              {!isColumnsLoading && (
                <>
                  {factorRows.count > 0 && (
                    <Row gutter={[8, 8]}>
                      <Col span={24}>{renderActionsUser()}</Col>
                      <Col span={24}>{renderActionsTable()}</Col>
                      <Col span={24}>{renderFactorsTable()}</Col>
                      <Col span={24}>
                        <ReplicateFactorModal
                          visible={showReplicateFactor}
                          factorId={factorId}
                          projectionId={projectionId}
                          onCancel={() => setShowReplicateFactor(false)}
                        />
                      </Col>
                    </Row>
                  )}
                  {!isTableLoading &&
                    factorRows.count <= 0 &&
                    renderEmptyScreen()}
                </>
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Col>
      {showUploadModal && (
        <ProjectionUploadModal
          title={t('PLANNING_UPLOAD_MODAL_TITLE')}
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          uploadFile={fileList =>
            uploadProjectionXLS(
              projectionId,
              fileList,
              factorId ? { factor_id: factorId } : {}
            )
          }
          onSuccessUpload={onSuccessUploadFile}
          onFailUpload={onFailUploadFile}
        />
      )}
    </Row>
  );
};

const mapStateToProps = state => ({
  factorRows: planning.selectors.getFactorRows(state),
  factorColumns: planning.selectors.getFactorColumns(state),
  filterTableOptions: planning.selectors.getFilterTableOptions(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchFactorRows: planning.actions.fetchFactorRows,
  fetchFactorColumns: planning.actions.fetchFactorColumns,
  fetchProjectionFilters: planning.actions.fetchProjectionFilters,
  downloadTemplateXLS: planning.actions.downloadTemplateXLS,
  uploadProjectionXLS: planning.actions.uploadProjectionXLS,
  downloadProjectionXLS: planning.actions.downloadProjectionXLS,
  deleteFactorRow: planning.actions.deleteFactorRow,
  updateTransactionFactor: planning.actions.updateTransactionFactor,
  unApplyFormula: planning.actions.unApplyFormula,
  applyFormula: planning.actions.applyFormula,
  deleteAllFactorRows: planning.actions.deleteAllFactorRows,
  syncProjectionComment: planning.actions.syncProjectionComment,
  createTransactionComment: planning.actions.createTransactionComment,
  deleteTransactionComment: planning.actions.deleteTransactionComment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectionFactorsDetail);
