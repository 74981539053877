const control = {
  CONTOL_TEMPLATE_FILE_NAME:
    'Plantilla Plika - Seguimiento {{name}} - {{period}}',
  CONTROL_MENSUAL_EVOLUTION_FILE_NAME:
    'Plika - Seguimiento {{name}} - {{period}} (evolución mensual)',
  CONTROL_COMPARISON_FILE_NAME:
    'Plika - Seguimiento {{name}} - {{period}} (Comparación)',
  CONTROL_DEFAULT_FILE_NAME: 'Plika - Seguimiento',

  CONTROL_ORIGINAL_OBS_FILED: 'Observaciones original',
  CONTROL_CANCELED_OBS_FILED: 'Obsevaciones anulado',

  ECONOMIC_CONTROL_EMPTY_SCREEN_DRAG_TITLE:
    'Comenzá la carga de tus datos contables',
  FINANCIAL_CONTROL_EMPTY_SCREEN_DRAG_TITLE: 'Cargar seguimiento financiero',
  CONTROL_CARD_EMPTY_STATE_DESCRIPTION:
    'Ingresá y comenzá a cargar tus {{conceptName}}.  Acá presentamos un resumen de los indicadores más importantes del seguimiento de tu presupuesto.',
  CONTROL_EMPTY_ECONOMIC_CARD_TITLE:
    '¡Controlá la ejecución de tus gastos y tus ventas!',
  CONTROL_EMPTY_FINANCIAL_CARD_TITLE: '¡Controlá tus cobros y tus pagos!',
  CONTROL_EMPTY_FINANCIAL_CARD_DESCRIPTION:
    'Cargá el real, compará el presupuesto y analizá los datos para poder tomar las mejores desiciones',
  ECONOMIC_CONTROL_UPLOAD_MODAL_TITLE: 'Cargar datos contables',
  FINANCIAL_CONTROL_UPLOAD_MODAL_TITLE: 'Cargar seguimiento financiero',
  CONTROL_ACCOUNTING_TYPE: 'contables',
  CONTROL_FINANCIAL_TYPE: 'financieros',

  CONTROL_CONCEPT_PUBLISH_MODAL_TEXT_SALES_EXPENSES:
    'Al publicar tus datos reales podras visualizar y utilizar las dintintas herramientas de Plika. Aún podras editar los datos cargados. Para continuar, seleccioná hasta el mes que deseas publicar (inclusive).',
  CONTROL_CONCEPT_APPROVE_MODAL_TEXT_SALES_EXPENSES:
    '¿Estás listo para aprobar las ventas y los gastos ejecutados? Recordá que una vez que lo hagas ya no podrás modificar ningún dato de los meses aprobados. Para proseguir, seleccioná hasta el mes que deseas aprobar (inclusive).',
  CONTROL_CONCEPT_ACTION_SUCCESS_FEEDBACK_DESCRIPTION:
    'Acabás de {{action}} {{concept1}} y {{concept2}} ejecutados hasta el mes de {{endDate}} :)', //NOTE: COMENTADO HASTA QUE SE PUEDA NOTIFICAR.¿Querés darle la noticia a todos los que participaron de esta planificación?',

  ADJUSTMENT_DISTRIBUTION_MODAL_CANCEL_COMMENT_TEXT:
    'Ingresá un comentario con el motivo de la anulación',

  CONTROL_UPLOAD_FILE_TOOLTIP_TITLE:
    'No se puede cargar un archivo cuando todos los meses ya están publicados.',
  CONTROL_PUBLISHED_DATA_TOOLTIP_TITLE: 'Datos publicados',
  CONTROL_UPLOADED_DATA_TOOLTIP_TITLE: 'Datos cargados',
  CONTROL_APPROVED_DATA_TOOLTIP_TITLE: 'Datos aprobados',

  CONTROL_MODAL_ACTION_DATA_TITLE: '{{action}} datos reales',
  CONTROL_MODAL_NOT_LOADED_DATA_TOOLTIP_TITLE:
    'Debes cargar datos en todos los conceptos para podes publicar',
  CONTROL_MODAL_NOT_PUBLISHED_DATA_TOOLTIP_TITLE:
    'No hay datos publicados disponibles para aprobar.',
  CONTROL_PUBLISH_MONTHS_FAIL_FEEDBACK_DESCRIPTION:
    'No pudimos realizar la acción. Por favor, volve a intentarlo.',
  CONTROL_ALL_PUBLISH_DATES_TOOLTIP_TITLE:
    'Ya se encuentran todas las fechas publicadas',
  CONTROL_ALL_APPROVE_DATES_TOOLTIP_TITLE:
    'Ya se encuentran todas las fechas aprobadas',
  CONTROL_LAST_DATE_STATUS_TOOLTIP_TITLE: 'Última fecha {{status}} {{date}}',

  CONTROL_REAL_DATA: 'Datos reales',
};

export default control;
