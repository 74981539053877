const report = {
  REPORT_CARD_TITLE_NO_CONFIG:
    'Necesitamos que configures la estructura de tu reporte para poder mostrarte los datos.',
  REPORT_CARD_TITLE_NO_DATA:
    'Cuando el presupuesto económico esté en revisión o aprobado, acá presentaremos un resumen de los indicadores más importantes de tu reporte.',
  REPORT_GRAPHIC_TITLE: 'Evolución y comparación mensual',
  REPORT_TOOLTIP_CONFIGURATION_TITLE: 'El reporte aún no ha sido configurado',
  REPORT_ALERT_INFO_MESSAGE:
    'No se visualizarán los presupuestos y forecasts consolidados hasta que estos sean publicados.',

  REPORT_STRUCTURE_FILE_NAME: 'Plika - Configuración reporte (estructura)',
  REPORT_EVOLUTION_FILE_NAME_DEFAULT: 'Plika - Análisis reportes',
  REPORT_EVOLUTION_FILE_NAME:
    'Plika - Análisis reportes - {{periodName}} (evolución mensual)',
  REPORT_COMPARISON_FILE_NAME:
    'Plika - Análisis reportes - {{periodName}} (Comparaciones)',
  REPORT_CUSTOM_FILE_NAME_DEFAULT: 'Plika - Reporte',
  REPORT_FILE_NAME_DEFAULT: 'Plika - {{reportName}} - {{periodName}} (reporte)',
  REPORT_EVOLUTION_SEGMENTED_ANALYSIS_FILE_NAME:
    'Plika - Análisis reportes - {{periodName}} (Análisis Segmentado)',
  REPORT_EVOLUTION_VERTICAL_ANALYSIS_COMPARISON_FILE_NAME:
    'Plika - Análisis reportes - {{periodName}} (Análisis Vertical comparativo)',
  REPORT_EVOLUTION_VERTICAL_ANALYSIS_SEGMENTED_FILE_NAME:
    'Plika - Análisis reportes - {{periodName}} (Análisis Vertical Segmentado)',
  REPORT_QUICKSIGHT_PRINT_REPORT_FILE_NAME: 'Plika - Reporte impreso',

  REPORT_OTHER_CARD_BODY_TEXT: 'Ingresá para ver el detalle del reporte',
  REPORT_OTHER_CHECKBOX_TEXT:
    'Convertir signo de linea de reporte seleccionada',
  REPORT_OTHER_CHECKBOX_TEXT_TOOTIP:
    'Al convertir el signo verá los valores negativos en positivos reflejados en su tabla.',
  REPORT_OTHER_VIEW_DETAIL_ACCOUNTS_TOOLTIP: 'Ver detalle cuentas',
  REPORT_OTHER_CHART_CARD_TITLE:
    'Comparación de {{lineReportAccount}} por {{dimensionName}}',

  REPORT_TAB_SEGMENTED_ANALYSIS: 'Análisis segmentado',
  REPORT_TAB_VERTICAL_ANALYSIS: 'Análisis vertical',
  REPORT_TAB_SEGMENTED_PL: `Reporte segmentado`,
  REPORT_TAB_RDN_DETAIL: 'Detalle RDN',
  REPORT_TAB_SEGREGATED_ANALYSIS: 'Análisis segregado',
  REPORT_VERTICAL_COMPARISON_TOOLTIP_TITLE:
    'Primero debes seleccionar una dimensión para poder elegir un valor',
  REPORT_VERTICAL_ANALYSIS_STRUCTURE_TOOLTIP_TITLE: `Primero debes elegir una línea de reporte para poder visualizar los datos`,
  REPORT_TAB_SEGMENTED_ANALYSIS_TOOLTIP_TITLE:
    'Aún no tenemos dimensiones con importes cargados para visualizar los datos',
  REPORT_DIMENSION_SHOW_CANT_LABEL: 'Cantidad de dimensiones visibles',
  REPORT_EDITION_KPIS_INFORMATIVE_ALERT_DESCRIPTION:
    'Los cambios realizados en el orden y visualización de los KPIs no se verán reflajados de manera permanente.',
  REPORT_RDN_DETAIL_INFORMATIVE_TOOLTIP_TITLE:
    'Para poder ver este reporte tenes que fijar un presupuesto consolidado',
  REPORT_RDN_DETAIL_EMPTY_SCREEN_DESCRIPTION:
    'No hay datos de reglas de negocio para mostrar',

  REPORT_VALUES_RATIO: 'Valores con ratio',

  REPORT_PLIKA_CARD_TITLE: 'Reportes Plika',
  REPORT_PLIKA_CARD_BODY_TITLE: 'Analiza tus métricas más relevantes',
  REPORT_PLIKA_CARD_BODY_DESCRIPTION:
    'Accede a tus reportes, para visualizar las métricas más relevantes de tus datos, gráficos de evolución y comparaciones.',

  //reporte self-service
  REPORT_SELF_SERVICE_FIELD: 'Reportes self-services Quicksight',
  REPORT_SELF_SERVICE_CARD_BODY_TITLE:
    'Crea reportes con la información que desees',
  REPORT_SELF_SERVICE_ENTER_PERIOD_DESCRIPTION:
    'Visualiza y organiza la información que desees acompañada por gráficos para analizar de manera sencilla y rápida tus datos.',
  REPORT_SELF_SERVICE_LOAD_DATA_BUTTON_TITLE: 'Cargar datos xls',
  REPORT_SELF_SERVICE_REPORT_PRINT_BUTTON_TITLE: 'Imprimir reportes',
  REPORT_SELF_SERVICE_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'Si clickeas este botón, vas a poder utilizar los datos que tengas cargados en Plika para armar un reporte self-service en Quicksight.',

  //multimoneda
  REPORT_DUPLICATE_MODAL: 'Duplicar reporte',
  REPORT_DUPLICATE_NAME: 'Nombre del reporte',
  REPORT_DUPLICATE_NAME_PLACEHOLDER: 'Ej: Estado de resultados en Dólares',
  REPORT_DUPLICATE_CHOOSE_CURRENCY:
    'Elegí la moneda funcional en la cual deseás visualizar tu reporte',
  REPORT_DUPLICATE_LOAD_EXCHANGE_RATE: 'Cargá el tipo de cambio',
  REPORT_DUPLICATE_DROPDOWN_ITEM: 'Duplicar y convertir',

  //financiero

  REPORT_FINANCIAL_NO_INITIAL_SITUATION_ALERT_DESCRIPTION:
    'No tenemos situación inicial para que puedas ver el detalle de tu reporte financiero. Por favor ingresá el valor en al menos uno de los siguientes campos para poder visualizarlo.',
  REPORT_FINANCIAL_INITIAL_SITUATION_MODAL_TITLE: 'Situación inicial',
  REPORT_FINANCIAL_ENTER_VALUE_INFORMATIVE_TOOLTIP_TITLE:
    'Debes ingresar al menos un valor',
  REPORT_FINANCIAL_NO_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'No hay datos cargados del período anterior para visualizar esta sección',
  REPOR_FINANCIAL_NO_INITIAL_SITUATION_PREV_REAL_ALERT_DESCRIPTION:
    'No tenemos informacion del periodo anterior para poder mostrar esta seccion.Necesitamos que ingreses un valor para establecer como situacion inicial y asi, poder mostrarte información.',
  REPORT_FINANCIAL_CARD_TITLE_NO_DATA:
    'Acá presentaremos un resumen de los indicadores más importantes de tu reporte. Para poder verlos, es necesario que estén publicados y aprobados los datos hasta el último mes que hayas cargado',

  REPORT_COMPARISON_MONTH_INFORMATIVE_MESSAGE_ALERT:
    'Primero debes elegir la/s fechas a comparar para poder visualizar la tabla.',
  REPORT_COMPARISON_MONTH_FILE_NAME:
    'Plika - Analisis Estado de Resultados - {{periodName}} (Comparativa mensual).xlsx',

  REPORT_SEGREGATED_ANALYSIS_CHOOSE_PRINCIPAL_DIMENSION_VALUES_TYPOGRAPHY:
    'Elige los valores de la dimensión que quiera visualizar como pincipal',
  REPORT_SEGREGATED_ANALYSIS_CHOOSE_SECONDARY_DIMENSION_VALUES_TYPOGRAPHY:
    'Elige los valores de la dimensión que quiera visualizar como secundaria',
  REPORT_SEGREGATED_ANALYSIS_OPTION_DEFAULT_COLUMNS_VIEW_RADIO_GROUP:
    'Default (configuración del reporte)',
  REPORT_SEGREGATED_ANALYSIS_NOT_DATA_EMPTY_DESCRIPTION:
    'Según la configuración de este reporte no tenemos información para mostrar.',
  REPORT_SEGREGATED_ANALYSIS_HAS_ONE_DIMENSION_EMPTY_DESCRIPTION:
    'Solo encontramos datos de una dimensión para este reporte, por lo que no podras realizar tu análisis',
};

export default report;
