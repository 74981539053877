import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';
import { ButtonActionBar, Typography } from 'modules/core/components';
import { Button } from 'antd';

const ButtonZarcam = ({ dataZarcam }) => {
  const { t } = useTranslation();
  return (
    <ButtonActionBar>
      <Button
        type="text"
        shape="circle"
        title={t('ACTION_UPDATE_DATA')}
        icon={<Typography.Icon icon={ReloadOutlined} />}
        onClick={dataZarcam.onClickButtonUpdate}
      />
    </ButtonActionBar>
  );
};

export default ButtonZarcam;
