const control = {
  CONTOL_TEMPLATE_FILE_NAME:
    'Plika Template - Controlling {{name}} - {{period}}',
  CONTROL_MENSUAL_EVOLUTION_FILE_NAME:
    'Plika - Controlling {{name}} - {{period}} (Monthly evolution)',

  CONTROL_COMPARISON_FILE_NAME:
    'Plika - Controlling {{name}} - {{period}} (Comparison)',
  CONTROL_DEFAULT_FILE_NAME: 'Plika - Controlling ',

  CONTROL_ORIGINAL_OBS_FILED: 'Original remarks',
  CONTROL_CANCELED_OBS_FILED: 'Override feedback',

  ECONOMIC_CONTROL_EMPTY_SCREEN_DRAG_TITLE:
    'Start loading your accounting data',
  FINANCIAL_CONTROL_EMPTY_SCREEN_DRAG_TITLE: 'Upload financial control',
  CONTROL_CARD_EMPTY_STATE_DESCRIPTION:
    'Login and start uploading your executed {{conceptName}}. Here we will present a summary of the most important indicators of monitoring your budget.',
  CONTROL_EMPTY_ECONOMIC_CARD_TITLE:
    'Control your expense and sales performance!',
  CONTROL_EMPTY_FINANCIAL_CARD_TITLE: 'Control your collections and payments!',
  CONTROL_EMPTY_FINANCIAL_CARD_DESCRIPTION:
    'Upload the real, compare the budget and analyze the data in order to make the best decisions',
  ECONOMIC_CONTROL_UPLOAD_MODAL_TITLE: 'Load accounting data',
  FINANCIAL_CONTROL_UPLOAD_MODAL_TITLE: 'Load financial control',

  CONTROL_CONCEPT_PUBLISH_MODAL_TEXT_SALES_EXPENSES:
    'A real publishing your data can view and use the tools dintintas of plika. If you edit the loaded data. To continue, select the month you want to publish (inclusive).',
  CONTROL_CONCEPT_APPROVE_MODAL_TEXT_SALES_EXPENSES:
    'Are you ready to approve sales and expenses executed? Remember that once you do it will not modify any data of approved months. To continue, select the month you want to approve (inclusive).',
  CONTROL_CONCEPT_ACTION_SUCCESS_FEEDBACK_DESCRIPTION:
    'You just {{action}} {{concept1}} and {{concept2}} excecuted until the month of {{endDate}} :), //NOTE: COMENTADO HASTA QUE SE PUEDA NOTIFICAR. Want to break the news to all who participated in this planning?',
  ADJUSTMENT_DISTRIBUTION_MODAL_CANCEL_COMMENT_TEXT:
    'Enter a comment with the reason for annulment',

  CONTROL_UPLOAD_FILE_TOOLTIP_TITLE:
    'Unable to load a file when every month are already published.',
  CONTROL_PUBLISHED_DATA_TOOLTIP_TITLE: 'Published data',
  CONTROL_UPLOADED_DATA_TOOLTIP_TITLE: 'Upload data',
  CONTROL_APPROVED_DATA_TOOLTIP_TITLE: 'Approved data',

  CONTROL_MODAL_ACTION_DATA_TITLE: '{{action}} actual data',
  CONTROL_MODAL_NOT_PUBLISHED_DATA_TOOLTIP_TITLE:
    'There are no published data available to approve',
  CONTROL_MODAL_NOT_LOADED_DATA_TOOLTIP_TITLE:
    'You have to load data into all concepts can post',
  CONTROL_PUBLISH_MONTHS_FAIL_FEEDBACK_DESCRIPTION:
    'We could not make the action. Please try again',
  CONTROL_ALL_PUBLISH_DATES_TOOLTIP_TITLE: 'All dates are pubished.',
  CONTROL_ALL_APPROVE_DATES_TOOLTIP_TITLE: 'All dates are approved',
  CONTROL_LAST_DATE_STATUS_TOOLTIP_TITLE: 'Last date {{status}} {{date}}',

  CONTROL_REAL_DATA: 'Real data',
};

export default control;
