import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { Table } from 'antd';
import { CellStyle } from 'modules/core/components';
import { calculateScrollX } from 'modules/core/utils';
import { ROWS } from 'modules/core/constants';
import reports from 'modules/reports';

import './OtherReportTable.scss';

const NUMBER_PERCENTAGE = ['budget_%', 'prev_real_%', 'last_forecast_%'];
const NUMBER_DECIMAL = [
  'real',
  'budget',
  'budget_$',
  'last_forecast',
  'last_forecast_$',
  'prev_real',
  'prev_real_$',
];

const OtherReportTable = ({
  reportId,
  periodId,
  selectedDates,
  reportTableData,
  reportColumns,
  tableSettings: { visibleData, format },
  fetchCustomReportRows,
}) => {
  const [isLoading, setIsloading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsloading(true);
    fetchCustomReportRows(reportId, {
      period_id: periodId,
      ...selectedDates,
    })
      .then(() => setIsloading(false))
      .catch(e => console.log(e));
  }, [reportId, periodId, selectedDates, fetchCustomReportRows]);

  const columns = [
    ...reportColumns
      .filter(column =>
        visibleData
          ? !column.is_transaction || visibleData.includes(column.data_index)
          : true
      )
      .map((column, index) => {
        return {
          key: index,
          ellipsis: true,
          dataIndex: column.data_index,
          ...(!column.is_transaction && {
            fixed: 'left',
            title: column.title,
            width: 250,
          }),
          ...(column.is_transaction && {
            title: t(column.title),
            width: 100,
            align: 'right',
            render: (value, row) => {
              if (NUMBER_DECIMAL.includes(column.data_index)) {
                return value ? numeral(value).format(format) : '-';
              } else if (NUMBER_PERCENTAGE.includes(column.data_index)) {
                return value ? (
                  column.data_index === 'real_%' ? (
                    <CellStyle.VariationTable
                      value={value}
                      amount={row.budget}
                    />
                  ) : (
                    <CellStyle.VariationTable value={value} amount={row.real} />
                  )
                ) : (
                  '-'
                );
              }
              return value;
            },
          }),
        };
      }),
  ];

  return (
    <Table
      bordered
      size="small"
      rowClassName={record => record.id === 0 && ROWS.TYPES[ROWS.TOTALIZER]}
      columns={columns}
      dataSource={reportTableData}
      loading={isLoading}
      scroll={{ x: calculateScrollX(columns), y: 420 }}
      pagination={false}
    />
  );
};

const mapStateToProps = state => ({
  reportTableData: reports.selectors.getCustomReportRows(state),
});

const mapDispatchToProps = {
  fetchCustomReportRows: reports.actions.fetchCustomReportRows,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherReportTable);
