import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  usePagination,
  useToggle,
  useCollapser,
} from 'modules/core/customHooks';
import { Button, Space, Table } from 'antd';
import { DownloadOutlined, DollarOutlined } from '@ant-design/icons';
import {
  calculateScrollX,
  formItemsHelper,
  columnsHelper,
  isApproved,
  isPublished,
  renderPrefix,
} from 'modules/core/utils';
import {
  PROJECTION,
  COLUMNS,
  TABLES,
  ACCES_USERS,
} from 'modules/core/constants';
import {
  ButtonActionBar,
  TableSettingsModal,
  Typography,
  CellStyle,
  SelectType,
  ShowCommentsButton,
  References,
} from 'modules/core/components';
import control from 'modules/control';
import planning from 'modules/planning';
import login from 'modules/login';
import _ from 'lodash';

import './ComparationTable.scss';

const ComparationTable = ({
  periodId,
  conceptName,
  projectionName,
  periodName,
  concept,
  selectedDates,
  realColumns,
  realRows: { transactions, count },
  refetch: { needRefetch = false, setRefetch = null },
  fetchRealRows,
  fetchRealColumns,
  downloadComparationXLS,
  periodDetail,
  fetchConsolidationList,
  consolidationList,
  filterOptions,
  filters,
  onFilter,
  consolidatedPredefined,
  isComparison,
  functionalCurrencyData,
  loggedUser: { email },
}) => {
  const [isConsoLoading, setIsConsoLoading] = useState(false);
  const [isCollapsed, toogleIsCollapsed, isCollapsing] = useCollapser();
  const [showRowsComment, toggleShowRowsComment] = useToggle(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS(email),
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
    showNegativeInRed: false,
  });
  const [tablePagination, setTablePagination] = usePagination();
  const [comparisonSelections, setComparisonSelections] = useState(
    consolidatedPredefined
  );
  const [columnsComparison, setColumnsComparison] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchRealColumns({ concept });
  }, [fetchRealColumns, concept]);

  useEffect(() => {
    setColumnsComparison(realColumns);
  }, [realColumns]);

  useEffect(() => {
    const endpointParams = {
      concept,
      ...tablePagination,
      ...selectedDates,
      ...filters,
      ...(isComparison && {
        consolidado1: _.split(comparisonSelections.budget, '-', 2)[1],
        consolidado2: _.split(comparisonSelections.last_forecast, '-', 2)[1],
      }),
    };
    setIsTableLoading(true);
    fetchRealRows(periodId, endpointParams).then(() => {
      setIsTableLoading(false);
      if (needRefetch) {
        setRefetch(prevRefetch => {
          return { ...prevRefetch, comparation: false };
        });
      }
    });
  }, [
    fetchRealRows,
    periodId,
    filters,
    concept,
    selectedDates,
    tablePagination,
    needRefetch,
    setRefetch,
    isComparison,
    comparisonSelections.budget,
    comparisonSelections.last_forecast,
  ]);

  useEffect(() => {
    setIsConsoLoading(true);
    fetchConsolidationList().then(() => setIsConsoLoading(false));
  }, [fetchConsolidationList]);

  const consolidationListBudget = consolidationList.filter(
    conso =>
      conso.period.id === parseInt(periodId) &&
      conso.type === PROJECTION.CONSOLIDATED &&
      (isApproved(conso.status) || isPublished(conso.status))
  );
  const consolidationListForecast = consolidationList.filter(
    conso =>
      conso.period.id === parseInt(periodId) &&
      conso.type === PROJECTION.CONSOLIDATED_FORECAST &&
      (isApproved(conso.status) || isPublished(conso.status))
  );

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const handleOnFilter = newFilters => {
    setTablePagination({ current: 1 });
    onFilter(newFilters);
  };

  const renderTransactionColumn = ({ dataIndex, isVariation, width }) => {
    return {
      width: width,
      align: 'right',
      title: t(PROJECTION.I18N[dataIndex], {
        prefix: renderPrefix(functionalCurrencyData),
      }),
      render: (value, row) => {
        if (value) {
          if (isVariation) {
            return (
              <CellStyle.VariationTable
                value={value}
                amount={
                  dataIndex === PROJECTION.BUDGET_VAR_MONEY
                    ? row[PROJECTION.BUDGET]
                    : row[PROJECTION.REAL]
                }
              />
            );
          }
          return (
            <CellStyle.Number
              value={value}
              format={tableSettings.format}
              showNegativeInRed={tableSettings.showNegativeInRed}
              has_restriction={has_restriction}
            />
          );
        }
        return '-';
      },
    };
  };

  const columnsGenerator = () =>
    columnsHelper.generator({
      columns: columnsComparison,
      filteredColumns: tableSettings.visibleData,
      cellFormat: {
        format: tableSettings.format,
        showNegativeInRed: tableSettings.showNegativeInRed,
      },
      filterProps: {
        filterOptions,
        filters,
        onFilter: handleOnFilter,
      },
      collapserProps: {
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      renderTransaction: column =>
        renderTransactionColumn({
          dataIndex: column.data_index,
          isVariation: COLUMNS.PERCENTAGE.includes(column.data_index),
          width: column.width,
        }),
      onResize: setColumnsComparison,
      has_restriction,
    });

  const handleSelect = id => {
    id.includes(PROJECTION.BUDGET)
      ? setComparisonSelections(prevState => {
          return { ...prevState, budget: id };
        })
      : setComparisonSelections(prevState => {
          return { ...prevState, last_forecast: id };
        });
  };

  const renderTableActions = () => {
    return (
      <ButtonActionBar position="space-between">
        <Space direction="horizontal">
          <SelectType.Comparison
            selections={comparisonSelections}
            onSelectId={handleSelect}
            consolidationsPredef={{
              budget: periodDetail.conso_budget_predef,
              forecast: periodDetail.conso_forecast_predef,
            }}
            loading={isConsoLoading}
            consolidationLists={{
              budget: consolidationListBudget,
              forecast: consolidationListForecast,
            }}
          />
          <References
            referencesItems={[
              ...(functionalCurrencyData
                ? [
                    {
                      type: <DollarOutlined />,
                      title:
                        functionalCurrencyData && functionalCurrencyData.code,
                      description:
                        functionalCurrencyData &&
                        t('REFERENCE_DESCRIPTION', {
                          currencyName: t(
                            functionalCurrencyData.name
                          ).toLowerCase(),
                        }),
                    },
                  ]
                : []),
            ]}
          />
          <ShowCommentsButton onClick={toggleShowRowsComment} />
        </Space>
        <Space>
          <Button
            type="text"
            shape="circle"
            icon={<Typography.Icon icon={DownloadOutlined} />}
            onClick={() =>
              downloadComparationXLS(
                periodId,
                t('CONTROL_COMPARISON_FILE_NAME', {
                  name: projectionName,
                  period: periodName,
                }),
                {
                  concept: conceptName,
                  ...selectedDates,
                  ...filters,
                  ...(isComparison && {
                    consolidado1: _.split(
                      comparisonSelections.budget,
                      '-',
                      2
                    )[1],
                    consolidado2: _.split(
                      comparisonSelections.last_forecast,
                      '-',
                      2
                    )[1],
                  }),
                }
              )
            }
            title={t('ACTION_DOWNLOAD_TABLE')}
          />
          {!isTableLoading && (
            <TableSettingsModal
              onConfirm={setTableSettings}
              options={realColumns.filter(
                (column, index) => index !== COLUMNS.FIRST_INDEX
              )}
              settings={tableSettings}
              formItems={formItemsHelper.itemsDataView(
                tableSettings.negativeValuesType,
                tableSettings.showNegativeInRed
              )}
            />
          )}
        </Space>
      </ButtonActionBar>
    );
  };

  return (
    <Space direction="vertical">
      {renderTableActions()}
      <Table
        rowKey="id"
        className="comparation-table"
        size="small"
        loading={isTableLoading}
        onChange={pagination =>
          needRefetch
            ? setTablePagination({ current: 1 })
            : setTablePagination(pagination)
        }
        columns={columnsGenerator()}
        dataSource={transactions}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total: count,
          pageSizeOptions: ['10', '20', '30'],
          size: 'small',
          showSizeChanger: true,
        }}
        components={columnsHelper.tableComponents}
        {...(!isCollapsing && {
          scroll: {
            x: calculateScrollX(columnsGenerator()),
            y: 445,
          },
        })}
      />
    </Space>
  );
};

const mapStateToProps = state => ({
  realColumns: control.selectors.getRealColumns(state),
  realRows: control.selectors.getRealRows(state),
  consolidationList: planning.selectors.getConsolidationList(state),
  consolidatedPredefined: planning.selectors.getConsolidatedPredefined(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchRealColumns: control.actions.fetchRealColumns,
  fetchRealRows: control.actions.fetchRealRows,
  downloadComparationXLS: control.actions.downloadComparationXLS,
  fetchConsolidationList: planning.actions.fetchConsolidationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparationTable);
