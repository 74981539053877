import React from 'react';
import {
  PartitionOutlined,
  ClockCircleOutlined,
  EyeOutlined,
  BarChartOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { ROLES } from 'modules/core/constants';

const { PLANNING, CONTROL, FORECAST, ANALYSIS } = ROLES;

const periodsMenu = t => [
  {
    key: 'home',
    title: 'Home',
    url: `/`,
    exact: true,
    icon: <HomeOutlined />,
    allowedFor: [PLANNING, CONTROL, FORECAST, ANALYSIS],
  },
  {
    key: 'planning',
    title: t('LABEL_PLANNING'),
    url: `/planificacion/:periodId`,
    exact: false,
    icon: <PartitionOutlined />,
    allowedFor: [PLANNING],
  },
  {
    key: 'seguimiento',
    title: t('LABEL_CONTROL'),
    url: `/seguimiento/:periodId`,
    exact: false,
    icon: <EyeOutlined />,
    allowedFor: [CONTROL],
  },
  {
    key: 'forecast',
    title: t('LABEL_FORECAST'),
    url: `/forecast/:periodId`,
    exact: false,
    icon: <ClockCircleOutlined />,
    allowedFor: [FORECAST],
  },
  {
    key: 'reportes',
    title: t('LABEL_ANALYSIS'),
    url: `/analisis/:periodId`,
    exact: false,
    icon: <BarChartOutlined />,
    allowedFor: [ANALYSIS],
  },
];

export default periodsMenu;
