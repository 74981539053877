import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Row, Col, Space, message, Spin } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { useTranslation } from 'react-i18next';
import { usePagination } from 'modules/core/customHooks';
import {
  ButtonActionBar,
  DropdownMenu,
  EmptyScreen,
  Typography,
  DeleteSelectionButton,
} from 'modules/core/components';
import {
  ConfigurationUploadModal,
  ConfigurationEmptyAlert,
} from 'modules/configuration/components';
import { UsersTable, CreateUserModal } from './components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';

const { Search } = Input;
const { SETTINGS__SECURITY__USERS__CREATE, SETTINGS__SECURITY__USERS__DELETE } =
  ROLES;

const UsersList = ({
  usersList: { count, values },
  fetchUsersList,
  fetchUserTemplateXLS,
  fetchUserConsolidatedXLS,
  uploadUsersXLS,
  deleteUser,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [tablePagination, setTablePagination] = usePagination();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [dataSelection, setDataSelection] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    fetchUsersList({
      writeable: 'True',
      search: searchValue,
      ...tablePagination,
    }).then(() => {
      setIsTableLoading(false);
      setIsScreenLoading(false);
    });
  }, [searchValue, tablePagination, fetchUsersList]);

  const userCanCreate = isUserAllowed(SETTINGS__SECURITY__USERS__CREATE);

  const onDeleteSelections = () => {
    setIsDeleting(true);
    Promise.all(
      dataSelection.map(id =>
        deleteUser(id).catch(error => {
          setIsDeleting(false);
          message.error(t('FEEDBACK_DELETE_SELECTION_ERROR'));
          setDataSelection([]);
        })
      )
    ).then(() =>
      fetchUsersList({
        writeable: 'True',
        search: searchValue,
        ...tablePagination,
      }).then(() => {
        setIsDeleting(false);
        message.success(t('FEEDBACK_DELETE_SELECTION_SUCCESS'));
        setDataSelection([]);
      })
    );
  };

  const renderNoDataScreen = () => (
    <>
      <Col span={24}>
        <ConfigurationEmptyAlert
          onDownloadTemplate={() => fetchUserTemplateXLS()}
        />
      </Col>
      <Col span={24}>
        <EmptyScreen
          image={emptyStateImage}
          title={t('CONFIG_USERS_EMPTY_STATE_TITLE')}
          footer={
            <Space direction="horizontal" size="large">
              <Button type="secondary" disabled={!userCanCreate}>
                {t('ACTION_UPLOAD_FILE')}
              </Button>
              <Button
                type="primary"
                onClick={() => setShowCreateModal(true)}
                disabled={!userCanCreate}
              >
                {t('ACTION_CREATE_NEW')}
              </Button>
            </Space>
          }
        />
      </Col>
    </>
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="user-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('ACTION_CREATE_NEW')}
        </Button>
        <Button
          onClick={() => setShowUploadModal(true)}
          disabled={!userCanCreate}
        >
          {t('ACTION_UPLOAD_FILE')}
        </Button>
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_DOWNLOAD_TEMPLATE'),
              icon: <FileExcelOutlined />,
              onClick: () => fetchUserTemplateXLS(),
            },
          ]}
        />
      </Space>
    </ButtonActionBar>
  );

  return (
    <>
      <Spin spinning={isScreenLoading} />
      <Row gutter={[24, 24]}>
        {count <= 0 && !searchValue && !isTableLoading && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {(count > 0 || (searchValue && count === 0)) && (
          <>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={12}>
              {dataSelection.length > 0 && (
                <DeleteSelectionButton
                  onConfirm={onDeleteSelections}
                  disabled={!isUserAllowed(SETTINGS__SECURITY__USERS__DELETE)}
                  isDeletingAll={
                    dataSelection.length === tablePagination.page_size ||
                    dataSelection.length === count
                  }
                />
              )}
            </Col>
            <Col span={12}>
              <ButtonActionBar>
                <Button
                  type="text"
                  shape="circle"
                  title={t('ACTION_DOWNLOAD_TABLE')}
                  icon={<Typography.Icon icon={DownloadOutlined} />}
                  onClick={fetchUserConsolidatedXLS}
                />
              </ButtonActionBar>
            </Col>
            <Col span={24}>
              <UsersTable
                tableLoading={isTableLoading || isDeleting}
                onSetTableLoading={setIsTableLoading}
                values={values}
                count={count}
                onTableChange={setTablePagination}
                pagination={{
                  pageSize: tablePagination.page_size,
                  current: tablePagination.page,
                  total: count,
                }}
                dataSelection={id => setDataSelection(id)}
                onRefetchUsersList={() =>
                  fetchUsersList({
                    writeable: 'True',
                    search: searchValue,
                    ...tablePagination,
                  })
                }
              />
            </Col>
          </>
        )}

        <ConfigurationUploadModal
          title={t('CONFIG_USERS_UPLOAD_MODAL_TITLE')}
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
          onSuccessUpload={() =>
            //setTablePagination({ current: 1 })
            fetchUsersList({
              writeable: 'True',
              search: searchValue,
              ...tablePagination,
            })
          }
          uploadValuesXLS={file => uploadUsersXLS(file)}
          setIsTableLoading={setIsTableLoading}
          nameConfig={t('LABEL_USERS')}
        />
        <CreateUserModal
          visible={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          onRefetchUsersList={() =>
            fetchUsersList({
              writeable: 'True',
              search: searchValue,
              ...tablePagination,
            })
          }
        />
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  usersList: configuration.selectors.getUsersList(state),
});

const mapDispatchToProps = {
  fetchUsersList: configuration.actions.fetchUsersList,
  fetchUserTemplateXLS: configuration.actions.fetchUserTemplateXLS,
  fetchUserConsolidatedXLS: configuration.actions.fetchUserConsolidatedXLS,
  uploadUsersXLS: configuration.actions.uploadUsersXLS,
  deleteUser: configuration.actions.deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
