import axios from 'axios';

export const API_ROOT =
  process.env.REACT_APP_API_ROOT || 'https://we.getplika.com/api';

const client = axios.create({
  baseURL: API_ROOT,
});

client.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = localStorage.getItem('access_token') || null;
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
      // NOTE: Pedido de back por roles
      config.headers.common['x-request-path'] = window.location.pathname;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// TODO:
// * Improve this interceptor for logout token expired.
// * Use redux flow and clean store not only localStorage.
// * Use react-router for redirect, maybe implement HOC.
client.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response.status === 401 &&
      error.response.data.code === 'token_not_valid'
    ) {
      window.location.href = '/login';
      localStorage.clear();
    }
    if (error.response.status === 403) {
      return Promise.reject({
        ...error,
        response: {
          ...error.response,
          data: 'No tiene los permisos para realizar la acción.',
        },
      });
    }
    return Promise.reject(error);
  }
);

const responseBody = res => res.data.results;
const requests = {
  delete: url => client.delete(url).then(responseBody),
  get: url => client.get(url).then(responseBody),
  put: (url, body) => client.put(url, body).then(responseBody),
  patch: (url, body) => client.patch(url, body).then(responseBody),
  post: (url, body) => client.post(url, body).then(responseBody),
};

export { client };
export default requests;
