import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Space, Button } from 'antd';
import {
  SelectType,
  ButtonActionBar,
  References,
  Typography,
  TableSettingsModal,
  CellStyle,
} from 'modules/core/components';
import { DollarOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  PROJECTION,
  ROWS,
  COLUMNS,
  TABLES,
  ACCES_USERS,
} from 'modules/core/constants';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import reports from 'modules/reports';
import login from 'modules/login';
import {
  renderPrefix,
  columnsHelper,
  calculateScrollX,
  getOnExpandParams,
} from 'modules/core/utils';
import columnsDetail from './columnsDetail';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

const FORMATS_TYPES = {
  '0,0': 'integer',
  '0,0.00': 'decimals',
  '0[.]0a': 'millions',
};

const ComparisionProjections = ({
  comparisonSelections,
  setComparisonSelections,
  isDataLoading,
  reportDetailTable,
  reportFunctionalCurrencyData,
  periodDetail,
  selectedDates,
  filters,
  downloadComparationXLS,
  consolidationListBudget,
  consolidationListForecast,
  isChangingConso,
  fetchReportRow,
  loggedUser: { email },
}) => {
  const [comparationTableSettings, setComparationTableSettings] = useState(
    TABLES.DEFAULT_SETTINGS(email)
  );
  const [isExpandingDetail, setIsExpandingDetail] = useState(false);
  const [expandedDetailRow, setExpandedDetailRow] = useState([]);
  const [detailTableColumns, setDetailTableColumns] = useState([]);
  let { periodId, reportId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setDetailTableColumns(
      columnsDetail(renderPrefix(reportFunctionalCurrencyData))
    );
  }, [reportFunctionalCurrencyData]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const renderOptions = () => {
    return (
      detailTableColumns.length > 0 &&
      detailTableColumns.map(col => {
        const deletePropieties = ({ fixed, width, ellipsis, align, ...rest }) =>
          rest;
        return {
          ...deletePropieties(col),
          data_index: col.dataIndex,
          title: col.dataIndex.includes('var$')
            ? t('VARIATION_MONEY', {
                prefix: renderPrefix(reportFunctionalCurrencyData),
              })
            : col.title,
        };
      })
    );
  };

  const renderTableActions = () => (
    <ButtonActionBar position="space-between">
      <References
        referencesItems={[
          {
            color: '#a73ba1',
            type: 'Ab',
            title: t('LABEL_RATIO'),
            description: t('REPORT_VALUES_RATIO'),
          },
          ...(reportFunctionalCurrencyData
            ? [
                {
                  type: <DollarOutlined />,
                  title:
                    reportFunctionalCurrencyData &&
                    reportFunctionalCurrencyData.code,
                  description:
                    reportFunctionalCurrencyData &&
                    t('REFERENCE_DESCRIPTION', {
                      currencyName: t(
                        reportFunctionalCurrencyData.name
                      ).toLowerCase(),
                    }),
                },
              ]
            : []),
        ]}
      />
      <Space direction="horizontal">
        <Button
          type="text"
          shape="circle"
          title={t('ACTION_DOWNLOAD_TABLE')}
          icon={<Typography.Icon icon={DownloadOutlined} />}
          onClick={() =>
            downloadComparationXLS(
              reportId,
              {
                period_id: periodId,
                format_number: FORMATS_TYPES[comparationTableSettings.format],
                ...selectedDates,
                ...filters,
                ...endpointParamsComparison,
              },
              t('REPORT_COMPARISON_FILE_NAME', {
                periodName: periodDetail.name,
              })
            )
          }
        />
        <TableSettingsModal
          onConfirm={setComparationTableSettings}
          settings={comparationTableSettings}
          options={renderOptions()}
        />
      </Space>
    </ButtonActionBar>
  );

  const endpointParamsComparison = {
    consolidado1: _.split(comparisonSelections.budget, '-', 2)[1],
    consolidado2: _.split(comparisonSelections.last_forecast, '-', 2)[1],
  };

  const handleSelect = id => {
    id.includes(PROJECTION.BUDGET)
      ? setComparisonSelections(prevState => {
          return { ...prevState, budget: id };
        })
      : setComparisonSelections(prevState => {
          return { ...prevState, last_forecast: id };
        });
  };

  const onGenerateDetailColumns = () => {
    return columnsHelper
      .generatorComparisonColumns({
        columns: detailTableColumns,
        onResize: setDetailTableColumns,
        renderValue: column =>
          renderValueColumn({
            dataIndex: column.dataIndex,
            isVariation: COLUMNS.PERCENTAGE.includes(column.dataIndex),
            width: column.width,
          }),
      })
      .filter((column, index) =>
        comparationTableSettings.visibleData
          ? index === 0 ||
            comparationTableSettings.visibleData.includes(column.dataIndex)
          : true
      );
  };

  const renderValueColumn = ({ dataIndex, isVariation, width }) => {
    return {
      width: width,
      align: 'right',
      render: (value, row) => {
        if (isVariation) {
          return (
            <CellStyle.VariationTable
              value={value}
              amount={
                dataIndex === PROJECTION.BUDGET_VAR_MONEY
                  ? row[PROJECTION.BUDGET]
                  : row[PROJECTION.REAL]
              }
            />
          );
        }
        return (
          <CellStyle.Number
            value={value}
            format={
              columnsHelper.renderFormatValue({
                isRatio: row.type === ROWS.RATIO,
                concept: row.concept,
                has_restriction,
                format: comparationTableSettings.format,
              })
              //row.type === ROWS.RATIO ? '%' : comparationTableSettings.format
            }
            has_restriction={has_restriction}
          />
        );
      },
    };
  };

  const onExpandDetail = (expanded, record) => {
    setIsExpandingDetail(true);
    if (expanded) {
      fetchReportRow(record.id, {
        ...endpointParamsComparison,
        ...getOnExpandParams(record, periodId, selectedDates, filters),
      }).then(() => {
        setIsExpandingDetail(false);
        setExpandedDetailRow(prevExpanded => [...prevExpanded, record.id]);
      });
    } else {
      setExpandedDetailRow(prevExpanded =>
        prevExpanded.filter(item => item !== record.id)
      );
      setIsExpandingDetail(false);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SelectType.Comparison
            selections={comparisonSelections}
            onSelectId={handleSelect}
            consolidationsPredef={{
              budget: periodDetail.conso_budget_predef,
              forecast: periodDetail.conso_forecast_predef,
            }}
            loading={isDataLoading}
            consolidationLists={{
              budget: consolidationListBudget,
              forecast: consolidationListForecast,
            }}
          />
        </Col>
        <Col span={24}>{renderTableActions()}</Col>
      </Row>
      <Table
        rowKey="id"
        size="small"
        columns={onGenerateDetailColumns()}
        dataSource={reportDetailTable}
        rowClassName={record => ROWS.TYPES[record.type]}
        loading={isExpandingDetail || isDataLoading || isChangingConso}
        onExpand={onExpandDetail}
        expandedRowKeys={expandedDetailRow}
        defaultExpandAllRows={false}
        scroll={{
          x: calculateScrollX(onGenerateDetailColumns(), 10),
          y: 500,
        }}
        pagination={false}
        components={columnsHelper.tableComponents}
      />
    </>
  );
};

const mapStateToProps = state => ({
  reportDetailTable: reports.selectors.getReportDetailTable(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  downloadComparationXLS: reports.actions.downloadComparationXLS,
  fetchReportRow: reports.actions.fetchReportRow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComparisionProjections);
