import {
  PROJECTION,
  ROWS,
  DIMENSIONS,
  CONCEPTS,
  REPORT,
} from 'modules/core/constants';
import i18n from 'i18next';
import { saveAs } from 'file-saver';
import { notification } from 'antd';
import _ from 'lodash';

export const isInElaboration = status => {
  return PROJECTION.STATUS.IN_ELABORATION === status;
};

export const isPublished = status => {
  return PROJECTION.STATUS.PUBLISHED === status;
};

export const isApproved = status => {
  return PROJECTION.STATUS.APPROVED === status;
};

export const isInitiated = status => {
  return PROJECTION.STATUS.INITIATED === status;
};

export const isApplied = status => {
  return PROJECTION.STATUS.APPLIED === status;
};

export const isAmount = key => REPORT.REPORTS_KEYS.AMOUNT === key;

export const calculateScrollX = (columns = [], initScrollX = 100) => {
  let scrollX = initScrollX;
  columns.forEach(column => {
    scrollX += column.width ? column.width : 0;
  });
  return scrollX;
};

export const orderBySelection = (ids, options) => {
  if (options) {
    const parsedIds = ids && ids.map(id => parseInt(id));
    const restItems = ids
      ? options.filter(option => !parsedIds.includes(option.id))
      : options;
    const itemsSelection = ids
      ? options.filter(option => parsedIds.includes(option.id))
      : [];
    return [...itemsSelection, ...restItems];
  } else return [];
};

export const columnPadding = (value, level, type) => {
  return (
    <span
      style={{
        paddingLeft: level && level * 7,
        ...(type === ROWS.RATIO && {
          fontWeight: 600,
        }),
      }}
    >
      {value}
    </span>
  );
};

export const updateCommentInReducer = ({ state, reducerKey, data }) => {
  const {
    rowId,
    columnId,
    newComment: { id, text, created_on, author } = {},
    commentId = null,
  } = data;

  const updateCreatedComment = row => {
    if (row.id === rowId) {
      const updateCommentsThread = [
        ...row[columnId].comments_thread,
        { id, text, created_on, author },
      ];

      return {
        ...row,
        [columnId]: {
          ...row[columnId],
          comments_thread: updateCommentsThread,
        },
      };
    }
    return row;
  };

  const updateDeletedComment = row => {
    if (row.id === rowId) {
      const updateCommentsThread = row[columnId].comments_thread.filter(
        el => el.id !== commentId
      );

      return {
        ...row,
        [columnId]: {
          ...row[columnId],
          comments_thread: updateCommentsThread,
        },
      };
    }
    return row;
  };

  const updateComment = row => {
    if (commentId) {
      return updateDeletedComment(row);
    }
    return updateCreatedComment(row);
  };

  return {
    [reducerKey]: {
      ...state[reducerKey],
      transactions: state[reducerKey].transactions.map(row =>
        updateComment(row)
      ),
    },
  };
};

export const handleSelectAll = ({
  values = [],
  allValues = [],
  areAllSelected = false,
  onSetValues,
  onSetAreAllSelected = null,
}) => {
  //NOTE: Los true o fase agregados en la funcion onSetValues es por tema de roles. Hacen referencia al campo selected_all que le enviamos a back
  if (values.map(val => parseInt(val)).includes(DIMENSIONS.ALL_FIELDS_ID)) {
    if (!areAllSelected) {
      onSetValues(allValues, true);
      onSetAreAllSelected && onSetAreAllSelected(true);
    } else {
      onSetValues([], false);
      onSetAreAllSelected && onSetAreAllSelected(false);
    }
  } else {
    onSetValues(values, false);
    onSetAreAllSelected && onSetAreAllSelected(false);
  }
};

export const generateSelectOptions = ({
  options = [],
  includeOptionAll = false,
  selection = null,
}) => {
  const auxOptions = _.compact([
    ...[
      includeOptionAll && {
        id: DIMENSIONS.ALL_FIELDS_ID,
        name: i18n.t('FIELD_ALL'),
      },
    ],
    ...options,
  ]);
  return auxOptions.map(option => {
    const label =
      option.code !== undefined
        ? `${option.code} - ${option.name}`
        : option.name;
    return {
      label: label,
      value: option.id,
      title: option.description ? option.description : label,
      ...(selection && { disabled: selection.includes(option.id.toString()) }),
    };
  });
};

export const renderTableCellLabel = ({
  values = [],
  allValues = [],
  isDataJoin = false,
}) => {
  if (values.length === allValues.length) return i18n.t('FIELD_ALL');
  return isDataJoin ? values.join(', ') : values.length;
};

export const executeExportAsImage = ({
  chartRef,
  format,
  onExport,
  props,
  title = '',
}) => {
  if (chartRef === null) {
    return;
  }

  onExport(chartRef, { ...props, quality: 1 })
    .then(dataUrl => {
      const fileName = i18n.t('CHART_EXPORT_AS_IMAGE_FILE_NAME', {
        chartTitle: title.toLowerCase(),
        format: format,
      });
      saveAs(dataUrl, fileName);
    })
    .catch(() => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: i18n.t('FEEDBACK_EXPORT_CHARTS_AS_IMAGE_ERROR'),
        duration: 0,
      });
    });
};

export const userCanAcces = ({ patchAcces, email }) => {
  if (!patchAcces.includes(email)) return null;

  return true;
};

export const renderPrefix = (functionalCurrencyData, defaultPrefix = '$') =>
  functionalCurrencyData ? functionalCurrencyData.prefix : defaultPrefix;

export const generateSelectConceptOptions = ({ values }) => [
  {
    label: i18n.t('CONFIG_FIELD_ECONOMIC_CONCEPTS'),
    value: CONCEPTS.TYPE.ECONOMIC_KEY,
    children: values
      .filter(concept => concept.type === CONCEPTS.TYPE.ECONOMIC_KEY)
      .map(concept => {
        return { label: concept.name, value: concept.id };
      }),
  },
  {
    label: i18n.t('CONFIG_FIELD_FINANCIAL_CONCEPTS'),
    value: CONCEPTS.TYPE.FINANCIAL_KEY,
    children: values
      .filter(concept => concept.type === CONCEPTS.TYPE.FINANCIAL_KEY)
      .map(concept => {
        return { label: concept.name, value: concept.id };
      }),
  },
];

export const getOnExpandParams = (row, periodId, selectedDates, filters) => {
  const getRowAdjustmentInfo = adjustmentInfo => {
    return _.reduce(
      adjustmentInfo,
      (accum, value, key) => {
        return { ...accum, [key]: value.id };
      },
      {}
    );
  };

  return {
    period_id: periodId,
    ...selectedDates,
    ...filters,
    ...(row.adjustment_info &&
      row.type === 'dimension' && {
        open_level: 'True',
        ...getRowAdjustmentInfo(row.adjustment_info),
      }),
  };
};
