import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  List,
  Typography as TypographyAntd,
  Tooltip,
  Switch,
  Button,
  Space,
  message,
  notification,
  Popconfirm,
} from 'antd';
import {
  SolutionOutlined,
  RightCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Typography,
  IntegrationCard,
  ButtonActionBar,
} from 'modules/core/components';
import { INTEGRATIONS } from 'modules/core/constants';
import moment from 'moment';
import home from 'modules/home';

import './IntegrationsList.scss';

const { Link } = TypographyAntd;

const IntegrationsList = ({
  title,
  dataSource,
  setIntegrationData,
  setShowConnectModal,
  size = 4,
  onRefetchIntegrationList,
  updateStatus,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showPopconfirm, setShowPopconfirm] = useState(false);
  let history = useHistory();
  const { t } = useTranslation();

  const setActionName = (status, is_configured = true) => {
    if (is_configured) {
      return !status ? t('ACTION_ACTIVATE') : t('ACTION_DEACTIVATE');
    } else {
      return t('ACTION_CONFIGURATE');
    }
  };

  const renderIntegrationBody = ({ name, image, last_update, status }) => (
    <div title={name} className="integration-container-img">
      <Space direction="vertical" size="small">
        <img width={250} src={image} alt={t('INTEGRATION_LOGO_IMG_ALT')} />
        {last_update && status && (
          <Typography.Body level={3}>{`${t('FIELD_LAST_UPDATED')} ${moment(
            last_update
          ).format('DD/MM/YYYY')} ${t('DATE_AT_TIME')} ${moment(
            last_update
          ).format('HH:mm')} `}</Typography.Body>
        )}
      </Space>
    </div>
  );

  const renderManualBody = ({ name, image }) => (
    <Row align="middle" title={name}>
      <Col span={12}>
        <img src={image} alt={t('INTEGRATION_PLIKA_LOGO_IMG_ALT')} />
      </Col>
      <Col span={12}>
        <div style={{ textAlign: 'center' }}>
          <Typography.Body>{name}</Typography.Body>
        </div>
      </Col>
    </Row>
  );

  const handleOnClickIntegration = ({ name, id }) => {
    setShowConnectModal(true);
    setIntegrationData({ name, id });
  };

  const onClose = () => {
    setIsUpdating(false);
    setShowPopconfirm(false);
  };

  const onUpdateStatus = (id, status) => {
    setIsUpdating(true);
    updateStatus({ status: !status, integration: id })
      .then(() =>
        onRefetchIntegrationList().then(() => {
          message.success(
            t('INTEGRATION_FEEDBACK_SUCCESS_MESSAGE', {
              action: status
                ? t('INTEGRATIONS_FEEDBACK_DEACTIVATE_SUCCESS_TEXT')
                : t('INTEGRATIONS_FEEDBACK_ACTIVATE_SUCCESS_TEXT'),
            })
          );
          onClose();
        })
      )
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error.response
            ? error.response.data
            : t('INTEGRATIONS_FEEDBACK_ACTIVATE_DEACTIVATE_ERROR_DESCRIPTION', {
                action: setActionName(status).toLowerCase(),
              }),
          duration: 0,
        });
        onClose();
      });
  };

  const renderExtra = ({ id, status, name, url, type, is_configured }) => (
    <ButtonActionBar>
      {type === INTEGRATIONS.KEYS.INTEGRATION_KEY && (
        <Tooltip title={setActionName(status, is_configured)}>
          <Link href={url && !status && url} target="_blank">
            <Popconfirm
              placement="bottomRight"
              title={t('INTEGRATIONS_ACTIVATE_DESACTIVATE_POPCONFIRM_TITLE', {
                action: setActionName(status).toLowerCase(),
                name,
              })}
              okText={setActionName(status)}
              onConfirm={() => onUpdateStatus(id, status)}
              okButtonProps={{ loading: isUpdating }}
              cancelText={t('ACTION_CANCEL')}
              onCancel={() => setShowPopconfirm(false)}
              cancelButtonProps={{ disabled: isUpdating }}
              visible={!url && is_configured && showPopconfirm}
            >
              <Switch
                checked={status}
                loading={isUpdating}
                onClick={() => {
                  !url && !is_configured
                    ? handleOnClickIntegration({ name, id })
                    : !url && is_configured && setShowPopconfirm(true);
                }}
              />
            </Popconfirm>
          </Link>
        </Tooltip>
      )}
      {type === INTEGRATIONS.KEYS.MANUAL_KEY && (
        <Button
          type="text"
          title={t('ACTION_GO_TO_CONFIGURE')}
          onClick={() => history.push('/configuracion')}
          icon={<Typography.Icon icon={RightCircleOutlined} />}
          shape="circle"
        />
      )}
      {status && type === INTEGRATIONS.KEYS.INTEGRATION_KEY && (
        <Button
          type="text"
          title={t('ACTION_ACTIVITIES')}
          onClick={() =>
            history.push(`/configuracion/integraciones/${name}/actividad`)
          }
          icon={<Typography.Icon icon={SolutionOutlined} />}
          shape="circle"
        />
      )}
    </ButtonActionBar>
  );

  const renderTitle = () => (
    <Button
      type="text"
      shape="circle"
      icon={<Typography.Icon icon={SyncOutlined} level={2} />}
      title={t('ACTION_UPDATE_DATA')}
      disabled={true}
    />
  );

  return (
    <>
      <Typography.Headline level={6}>{t(title)}</Typography.Headline>
      <List
        style={{ paddingTop: 20 }}
        grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: size, xxl: size }}
        dataSource={dataSource}
        renderItem={integration => {
          const {
            id,
            name,
            image,
            url,
            type,
            status,
            last_update,
            is_configured,
          } = integration;
          return (
            <List.Item>
              <IntegrationCard
                cardTitle={status ? renderTitle() : ''}
                body={
                  type === INTEGRATIONS.KEYS.INTEGRATION_KEY
                    ? renderIntegrationBody({
                        name,
                        image,
                        last_update,
                        status,
                      })
                    : renderManualBody({ name, image })
                }
                extraCard={renderExtra({
                  id,
                  status,
                  name,
                  url,
                  type,
                  is_configured,
                })}
                headStyle={{ height: 56 }}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateStatus: home.actions.updateStatus,
};

export default connect(null, mapDispatchToProps)(IntegrationsList);
