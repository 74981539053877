import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Space, Select, Typography, Input, DatePicker } from 'antd';
import planning from 'modules/planning';
import distributions from 'modules/distributions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import './DistributionCreationInfo.scss';
import { generateSelectOptions } from 'modules/core/utils';

const { TextArea } = Input;
const { Text } = Typography;

const DistributionCreationInfo = ({
  valueDate,
  dateOptions = [],
  valueAccount,
  publishedAccounts,
  comment,
  setComment,
  fetchPublishedAccounts,
  editDistributionValues,
  successCreation,
  dataDuplicated: { duplicatedComment, duplicatedDate },
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchPublishedAccounts({ date: valueDate });
  }, [fetchPublishedAccounts, valueDate]);

  const disabledDate = date => {
    const pickerDates = moment(date._d).format('YYYY-MM-01');
    if (dateOptions.length === 0) {
      return true;
    }
    return !dateOptions.includes(pickerDates);
  };

  const renderValueDate = () => {
    if (valueDate) {
      return moment(valueDate);
    } else if (duplicatedDate) {
      return moment(duplicatedDate);
    }

    return undefined;
  };
  const auxComment = comment ? comment : duplicatedComment;

  //NOTE: LO AGREGADO EN KEY NO SE SI ES CORRECTO PERO FUE LA UNICA FORMA QUE ENCONTRE PARA PODER HACER QUE EL VALOR SE PUEDA DESELECCIONAR.
  //CON LA PROPIEDAD VALUE NO ME DEJABA DESELECCIONARLO Y SI DEJABA SOLO DEFAULTVALUE NO ME LO MOSTRABA.

  return (
    <Space align="top" size="large">
      <Space direction="vertical">
        <Text>
          <Text type="danger">*</Text>
          {t('DISTRIBUTION_SELECT_DATE_LABEL')}
        </Text>
        <DatePicker
          className="distribution-creation-info-select"
          placeholder={t('MONTH_PLACEHOLDER')}
          defaultPickerValue={moment(dateOptions[0])}
          key={renderValueDate()}
          defaultValue={renderValueDate()}
          format="MMMM YYYY"
          picker="month"
          disabledDate={disabledDate}
          disabled={successCreation}
          onChange={value =>
            editDistributionValues(
              value ? { date: moment(value._d).format('YYYY-MM-01') } : null
            )
          }
        />
      </Space>
      <Space direction="vertical">
        <Text>
          <Text type="danger">*</Text> {t('DISTRIBUTION_ACCOUNT_FIELD')}
        </Text>
        <Select
          className="distribution-creation-info-select"
          showSearch
          disabled={!valueDate || successCreation}
          value={valueAccount ? valueAccount : undefined}
          placeholder={t('ACCOUNT_PLACEHOLDER')}
          notFoundContent={t('FEEDBACK_DEFAULT_NOT_FOUND')}
          onChange={value =>
            editDistributionValues(value ? { account: value } : null)
          }
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={generateSelectOptions({ options: publishedAccounts })}
        />
      </Space>

      <Space direction="vertical">
        <Text>{t('FIELD_OBSERVATIONS')}</Text>
        <TextArea
          value={auxComment}
          onChange={event => setComment(event.target.value)}
          autoComplete="off"
          autoSize={{ maxRows: 1 }}
          style={{ width: 400 }}
          disabled={successCreation}
        />
      </Space>
    </Space>
  );
};

const mapStateToProps = state => ({
  publishedAccounts: planning.selectors.getPublishedAccounts(state),
});

const mapDispatchToProps = {
  editDistributionValues: distributions.actions.editDistributionValues,
  fetchPublishedAccounts: planning.actions.fetchPublishedAccounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionCreationInfo);
