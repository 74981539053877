import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin, Space, Row, Col, List, Button, Tooltip } from 'antd';
import { DeadlineModal } from './components';
import { BudgetCard, CreateBudgetModal } from 'modules/planning/components';
import { ButtonActionBar, EmptyScreen } from 'modules/core/components';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import { DollarOutlined } from '@ant-design/icons';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { useTranslation } from 'react-i18next';
import { isApproved, isUserAllowed, renderPrefix } from 'modules/core/utils';
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants';
import planning from 'modules/planning';

const {
  PLANNING__EXPENSES__VIEW,
  PLANNING__SALES__VIEW,
  PLANNING__EXPENSES__CREATE,
  PLANNING__SALES__CREATE,
  PLANNING__EXPENSES__EXCHANGE_RATE,
  PLANNING__SALES__EXCHANGE_RATE,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PLANNING__CONCEPT = {
  [CONCEPTS.KEYS.SALES]: {
    VIEW: PLANNING__SALES__VIEW,
    CREATE: PLANNING__SALES__CREATE,
    EXCHANGE_RATE: PLANNING__SALES__EXCHANGE_RATE,
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    VIEW: PLANNING__EXPENSES__VIEW,
    CREATE: PLANNING__EXPENSES__CREATE,
    EXCHANGE_RATE: PLANNING__EXPENSES__EXCHANGE_RATE,
  },
};

const PlanningList = ({ periodId, concept, fetchProjections }) => {
  const [projections, setProjections] = useState([]);
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isListLoading, setIsListLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [duplicateData, setDuplicateDate] = useState(null);
  const functionalCurrencyData = useFunctionalCurrency();
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    setIsScreenLoading(true);
    fetchProjections({
      type: PROJECTION.BUDGET,
      period: periodId,
      concept: concept.id,
    }).then(data => {
      setProjections(data);
      setIsScreenLoading(false);
    });
  }, [fetchProjections, periodId, concept]);

  const userCanCreate = isUserAllowed(PLANNING__CONCEPT[concept.name].CREATE);
  const areApprovedProjections =
    projections.filter(projection => isApproved(projection.status)).length > 0;
  const canUserExchangeRateView = isUserAllowed(
    PLANNING__CONCEPT[concept.name].EXCHANGE_RATE
  );

  const refreshList = () => {
    setIsListLoading(true);
    fetchProjections({
      type: PROJECTION.BUDGET,
      period: periodId,
      concept: concept.id,
    }).then(data => {
      setProjections(data);
      setIsListLoading(false);
    });
  };

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_EMPTY_SCREEN_TITLE')}
      description={t('PLANNING_EMPTY_SCREEN_BODY')}
      footer={
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('PLANNING_BUDGET_CREATE_ACTION')}
        </Button>
      }
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Tooltip
          title={
            areApprovedProjections
              ? t('PLANNING_DISABLED_CREATION_TOOLTIP')
              : ''
          }
        >
          <Button
            type="primary"
            onClick={() => setShowCreateModal(true)}
            disabled={!userCanCreate || areApprovedProjections}
          >
            {t('ACTION_CREATE_NEW')}
          </Button>
        </Tooltip>
        <DeadlineModal
          period={periodId}
          concept={concept.id}
          editDisabled={!userCanCreate || areApprovedProjections}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderBudgetList = () => (
    <List
      style={{ paddingLeft: 6 }}
      grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 3, xxl: 3 }}
      dataSource={!isListLoading ? projections : []}
      renderItem={budget => {
        const {
          id: projectionId,
          name,
          status,
          has_updated_exchangerates,
        } = budget;
        return (
          <List.Item>
            <BudgetCard
              budgetId={projectionId}
              periodId={periodId}
              title={t(name)}
              type={concept.name}
              status={status}
              extraOptionExchangeRate={[
                {
                  title: t('ACTION_LOAD_EXCHANGE_RATE'),
                  icon: <DollarOutlined />,
                  onClick: () =>
                    history.push(
                      `/planificacion/${periodId}/tipodecambio/${projectionId}`
                    ),
                  disabled: !canUserExchangeRateView,
                },
              ]}
              has_updated_exchangerates={has_updated_exchangerates}
              onOpenActivities={() =>
                history.push(
                  `/planificacion/${periodId}/${concept.name.toLowerCase()}/actividad/${projectionId}`
                )
              }
              onOpen={() =>
                history.push(
                  `/planificacion/${periodId}/${concept.name.toLowerCase()}/${projectionId}`
                )
              }
              onDuplicate={budgetName => {
                setDuplicateDate({
                  id: projectionId,
                  name: t('COPY_NAME', {
                    name: t(budgetName),
                  }),
                });
                setShowCreateModal(true);
              }}
              reloadList={refreshList}
              renderPrefix={defaultPrefix =>
                renderPrefix(functionalCurrencyData, defaultPrefix)
              }
            />
          </List.Item>
        );
      }}
    />
  );

  return (
    <Spin spinning={isScreenLoading} size="large">
      <Row gutter={[24, 24]}>
        {!isScreenLoading && projections.length <= 0 && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {projections.length > 0 && <Col span={24}>{renderTopActions()}</Col>}
        {!isScreenLoading && projections.length > 0 && !isListLoading && (
          <Col span={24}>{renderBudgetList()}</Col>
        )}
      </Row>
      <CreateBudgetModal
        period={periodId}
        concept={concept}
        visible={showCreateModal}
        onCancel={() => {
          setShowCreateModal(false);
          setDuplicateDate(null);
        }}
        copyData={duplicateData}
        onSuccessCreate={refreshList}
      />
    </Spin>
  );
};

const mapDispatchToProps = {
  fetchProjections: planning.actions.fetchProjections,
};

export default connect(null, mapDispatchToProps)(PlanningList);
