import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Col, Row, Space, Button, Spin, Alert, Radio } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import {
  ButtonActionBar,
  EmptyScreen,
  Navigator,
  Typography,
  DropdownMenu,
} from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { DisplaySettingsModal } from '../../components';
import {
  ConfigurationEmptyAlert,
  ConfigurationUploadModal,
} from 'modules/configuration/components';
import {
  CreateAccountModal,
  CreateLevelModal,
  CreatePlanAccountModal,
  PlanAccountsTable,
  InformativeModal,
} from './components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES, DIMENSIONS, INTEGRATIONS } from 'modules/core/constants';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import configuration from 'modules/configuration';
import planning from 'modules/planning';

import './PlanAccounts.scss';

const { Search } = Input;
const { SETTINGS__PLAN_ACCOUNTS__CREATE } = ROLES;

const CONFIG_LOAD_TYPES_LIST = [
  { id: 'loadData', name: 'LABEL_LOAD_DATA' },
  { id: 'association', name: 'LABEL_LOAD_ASSOCIATION_DATA' },
];

const LOAD_DATA_KEY = 'loadData';
const LEVEL_KEY = 'level';

//TODO: Seleccion multiple comentado hasta que se sepa la validacion para eliminar cuando es informacion tipo árbol
const PlanAccounts = ({
  dimensionValues: { values, count },
  fetchDimensionValues,
  uploadDimensionValuesXLS,
  fetchDimensionTemplateXLS,
  fetchPlanAccountsConsolidatedXLS,
  fetchConceptsList,
  fetchDimensionValuesParents,
  dimensionValuesParents,
  conceptsList,
  //deletePlanAccount,
  fetchConceptAccountAssociationTemplateXLS,
  uploadConceptAccountAssociationXLS,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreateFirstTime, setShowCreateFirstTime] = useState(false);
  //const [dataRowSelection, setDataRowSelection] = useState([]);
  //const [isSelection, setIsSelection] = useState(false);
  //const [deleting, setDeleting] = useState(false);
  const [showInformativeModal, setShowInformativeModal] = useState(false);
  const [createModalSettings, setCreateModalSettings] = useState({
    visible: false,
    type: LEVEL_KEY, //Referenciado a createTypesOptions
  });
  const [optionLoadFile, setOptionLoadFile] = useState(LOAD_DATA_KEY);
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    Promise.all([
      fetchConceptsList(),
      fetchDimensionValues({
        dimension_name: DIMENSIONS.LEVEL_KEY,
        writeable: 'True',
      }),
    ]).then(() => {
      setIsTableLoading(false);
      setIsScreenLoading(false);
    });
  }, [fetchDimensionValues, fetchConceptsList]);

  useEffect(() => {
    fetchDimensionValuesParents({
      dimension__name: DIMENSIONS.LEVEL_KEY,
      writeable: 'True',
    });
  }, [fetchDimensionValuesParents]);

  const dimensionId = 2;
  const createTypesOptions = [
    { id: 'level', name: t('CONFIG_ACCOUNTS_CHOOSE_OPTION_MODAL_NAME') },
    { id: 'account', name: t('FIELD_ACCOUNT') },
  ];

  const userCanCreate = isUserAllowed(SETTINGS__PLAN_ACCOUNTS__CREATE);
  const isLevel = createModalSettings.type === LEVEL_KEY;

  // const onSetDataRowSelection = data => setDataRowSelection(data);

  // const onDeleteSelections = () => {
  //   //iterar el endpont deletePlanAccount por cada id seleccionado
  //   console.log('eliminar ids ', dataRowSelection);
  //   //deletePlanAccount(dataRowSelection)
  //   //.then(()=>
  //   //message.success('La selección se eliminó correctamente.')
  //   //)
  //   //.catch(()=>
  //   //message.error('No pudimos eliminar la selección. Por favor, volvé a intentarlo.')
  //   //)
  //   setDataRowSelection([]);
  //   setIsSelection(false);
  //   setDeleting(false);
  // };

  const functionSearch = (valueRows, searchValue) => {
    const resultado = [];
    valueRows.forEach(value => {
      if (
        value.code.includes(searchValue) ||
        value.name.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        resultado.push(value);
      } else {
        if (value.auxChildren) {
          let childrenAux = functionSearch(value.auxChildren, searchValue);
          if (childrenAux.length !== 0) {
            let valueAux = { ...value, auxChildren: childrenAux };
            resultado.push(valueAux);
          }
        }
      }
    });
    return resultado;
  };

  const renderEmptyScreen = () => (
    <>
      <Col span={24}>
        <ConfigurationEmptyAlert
          onDownloadTemplate={() =>
            fetchDimensionTemplateXLS(t('CONFIG_ACCOUNTS_TEMPLATE_NAME'), {
              dimension: DIMENSIONS.LEVEL_KEY,
            })
          }
        />
      </Col>
      <Col span={24}>
        <EmptyScreen
          title={t('LABEL_ACCOUNTS')}
          description={t('CONFIG_ACCOUNTS_EMPTY_STATE_DESCRIPTION')}
          image={emptyStateImage}
          footer={
            <Space direction="horizontal" size="large">
              <Button
                type="secondary"
                onClick={() => setShowUploadModal(true)}
                disabled={!userCanCreate}
              >
                {t('ACTION_UPLOAD_FILE')}
              </Button>
              <Button
                type="primary"
                onClick={() => setShowInformativeModal(true)}
                disabled={!userCanCreate}
              >
                {t('CONFIG_ACCOUNTS_CREATE_LEVEL_ACTION')}
              </Button>
            </Space>
          }
        />
      </Col>
    </>
  );

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        { name: t('LABEL_ACCOUNTS') },
      ]}
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="account-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('ACTION_CREATE_NEW')}
        </Button>
        <Button
          onClick={() => setShowUploadModal(true)}
          disabled={!userCanCreate}
        >
          {t('ACTION_UPLOAD_FILE')}
        </Button>
        <DisplaySettingsModal
          dimensionName={DIMENSIONS.ACCOUNTS.label}
          dimensionId={DIMENSIONS.ACCOUNTS.id}
        />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_DOWNLOAD_TEMPLATE'),
              icon: <FileExcelOutlined />,
              onClick: () =>
                fetchDimensionTemplateXLS(t('CONFIG_ACCOUNTS_TEMPLATE_NAME'), {
                  dimension: DIMENSIONS.LEVEL_KEY,
                }),
            },
          ]}
        />
      </Space>
    </ButtonActionBar>
  );

  const optionsLoadTypes = () => (
    <Space direction="vertical" style={{ textAlign: 'start' }}>
      <span>{`${t('FIELD_LOAD_TYPE')}:`}</span>
      <Radio.Group
        value={optionLoadFile}
        onChange={value => setOptionLoadFile(value.target.value)}
      >
        <Space direction="vertical">
          {CONFIG_LOAD_TYPES_LIST.map(loadType => (
            <Radio value={loadType.id} key={loadType.id}>
              {t(loadType.name)}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Space>
  );

  const renderTableActions = () => (
    <ButtonActionBar>
      {/* {isSelection && (
        <Popconfirm
          placement="bottomRight"
          title={`¿Estás seguro que deseas eliminar esta selección?`}
          okText="Eliminar"
          onConfirm={() => onDeleteSelections()}
          cancelText="Cancelar"
          onCancel={() => setDeleting(false)}
          visible={deleting}
        >
          <Button type="link" onClick={() => setDeleting(true)}>
            Eliminar selección
          </Button>
        </Popconfirm>
      )} */}

      <Button
        type="text"
        shape="circle"
        title={t('ACTION_DOWNLOAD_TABLE')}
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={() => fetchPlanAccountsConsolidatedXLS()}
      />
    </ButtonActionBar>
  );

  const renderAlertInfo = () => (
    <Alert
      showIcon
      closable
      type="info"
      description={
        <div>
          <span>{t('CONFIG_ACCOUNTS_ASSOCIATION_ALERT_DESCRIPTION')}</span>
          <span
            className="plan-account-alert-download-tempate"
            onClick={() => fetchConceptAccountAssociationTemplateXLS()}
          >
            {t('ACTION_DOWNLOAD_TEMPLATE')}
          </span>
        </div>
      }
    />
  );

  const renderTable = () => (
    <PlanAccountsTable
      tableLoading={isTableLoading}
      dataSource={values}
      searchValue={searchValue}
      onSearchValue={functionSearch}
      //dataSelection={dataRowSelection}
      // onSetRowSelection={id => {
      //   setIsSelection(true);
      //   onSetDataRowSelection(id);
      // }}
      onRefetchDimensioValues={() =>
        fetchDimensionValues({
          dimension_name: DIMENSIONS.LEVEL_KEY,
          writeable: 'True',
        })
      }
      conceptsList={conceptsList}
    />
  );

  return (
    <SiderLayout>
      <div className="plan-accounts-container">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Spin spinning={isScreenLoading} />
          {count <= 0 && !isScreenLoading && renderEmptyScreen()}
          {count > 0 && (
            <>
              <Col span={24}>{renderAlertInfo()}</Col>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderTableActions()}</Col>
              <Col span={24}>{renderTable()}</Col>
            </>
          )}
        </Row>
      </div>
      <CreatePlanAccountModal
        defaultValue={createModalSettings.type}
        options={createTypesOptions}
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onConfirm={type => {
          setCreateModalSettings({ visible: true, type });
          setShowCreateModal(false);
        }}
      />
      <InformativeModal
        visible={showInformativeModal}
        onCancel={() => setShowInformativeModal(false)}
        onConfirm={() => {
          setShowInformativeModal(false);
          setShowCreateFirstTime(true);
        }}
      />
      <CreateAccountModal
        visible={createModalSettings.visible && !isLevel}
        onRefetchDimensioValues={() =>
          fetchDimensionValues({
            dimension_name: DIMENSIONS.LEVEL_KEY,
            writeable: 'True',
          })
        }
        onCancel={() =>
          setCreateModalSettings({ visible: false, type: LEVEL_KEY })
        }
        onShowPlanAccountModal={setShowCreateModal}
        conceptsList={conceptsList}
        dimensionValuesParents={dimensionValuesParents}
      />
      <CreateLevelModal
        visible={
          (createModalSettings.visible && isLevel) || showCreateFirstTime
        }
        onRefetchDimensioValues={() =>
          fetchDimensionValues({
            dimension_name: DIMENSIONS.LEVEL_KEY,
            writeable: 'True',
          })
        }
        onRefetchDimensioValuesParents={() =>
          fetchDimensionValuesParents({
            dimension__name: DIMENSIONS.LEVEL_KEY,
            writeable: 'True',
          })
        }
        onCancel={() =>
          showCreateFirstTime
            ? setShowCreateFirstTime(false)
            : setCreateModalSettings({ visible: false, type: LEVEL_KEY })
        }
        createFirstTime={showCreateFirstTime}
        onShowPlanAccountModal={setShowCreateModal}
        dimensionValuesParents={dimensionValuesParents}
      />

      <ConfigurationUploadModal
        title={t('CONFIG_ACCOUNTS_UPLOAD_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        onSuccessUpload={() =>
          fetchDimensionValues({
            dimension_name: DIMENSIONS.LEVEL_KEY,
            writeable: 'True',
          })
        }
        uploadValuesXLS={file =>
          optionLoadFile === LOAD_DATA_KEY
            ? uploadDimensionValuesXLS(file, { dimension: dimensionId })
            : uploadConceptAccountAssociationXLS(file)
        }
        extraBody={optionsLoadTypes()}
        setIsTableLoading={setIsTableLoading}
        nameConfig={t('LABEL_ACCOUNTS')}
        afterOnClose={() => setOptionLoadFile(LOAD_DATA_KEY)}
      />
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  dimensionValues: configuration.selectors.getDimensionValues(state),
  conceptsList: planning.selectors.getConceptsList(state),
  dimensionValuesParents:
    configuration.selectors.getDimensionValuesParents(state),
});

const mapDispatchToProps = {
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionTemplateXLS: configuration.actions.fetchDimensionTemplateXLS,
  fetchPlanAccountsConsolidatedXLS:
    configuration.actions.fetchPlanAccountsConsolidatedXLS,
  uploadDimensionValuesXLS: configuration.actions.uploadDimensionValuesXLS,
  deletePlanAccount: configuration.actions.deletePlanAccount,
  fetchDimensionValuesParents:
    configuration.actions.fetchDimensionValuesParents,
  fetchConceptsList: planning.actions.fetchConceptsList,
  fetchConceptAccountAssociationTemplateXLS:
    configuration.actions.fetchConceptAccountAssociationTemplateXLS,
  uploadConceptAccountAssociationXLS:
    configuration.actions.uploadConceptAccountAssociationXLS,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanAccounts);
