import React, { useEffect, useState } from 'react';
import { List, Tooltip, Button, Row, Col, Space, Input } from 'antd';
import {
  ListCard,
  ButtonActionBar,
  CreateReportModal,
} from 'modules/core/components';
import { isUserAllowed } from 'modules/core/utils';
import reports from 'modules/reports';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import emptyImage from 'assets/images/compositions/empty-card.svg';
import { useTranslation } from 'react-i18next';
import { PROJECTION, REPORT, ROLES } from 'modules/core/constants';
import { RightCircleOutlined } from '@ant-design/icons';
import { InitialSituationModal } from '../../../components';

const { ANALYSIS__REPORTSPNL__CREATE } = ROLES;
const { Search } = Input;

//TODO: AGREGAR PERMISOS REFERIDOS A FINANCIERON CUANDO ESTEN. HOY RESTRINJO CON LOS QUE TENEMOS DE REPROTE NORMAL
const ReportsList = ({ fetchFinancialReportList, financialReportsList }) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showInitialSituationModal, setShowInitialSituationModal] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  let history = useHistory();
  let { periodId } = useParams();

  useEffect(() => {
    setIsDataLoading(true);
    fetchFinancialReportList({
      period_id: periodId,
      type: REPORT.REPORTS_KEYS.FNL_KEY,
    }).then(() => setIsDataLoading(false));
  }, [fetchFinancialReportList, periodId]);

  const canUserCreate = isUserAllowed(ANALYSIS__REPORTSPNL__CREATE);

  //recibe los reportes y al filtrar por el valor agrega los kpis que coinciden al array 'Results'
  const functionSearch = (values, searchValue) => {
    const results = [];
    values.forEach(value => {
      if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
        results.push(value);
      }
    });
    return results;
  };

  const dataSource = financialReportsList.filter(
    report => report.type === REPORT.REPORTS_KEYS.FNL_KEY
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        <CreateReportModal
          rolesAvailables={[ANALYSIS__REPORTSPNL__CREATE]}
          onFetchReportList={() =>
            fetchFinancialReportList({
              period_id: periodId,
              type: REPORT.REPORTS_KEYS.FNL_KEY,
            })
          }
          reportCreationType={REPORT.REPORTS_KEYS.FNL_KEY}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderReportList = () => (
    <List
      style={{ paddingLeft: 6 }}
      grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
      loading={isDataLoading}
      dataSource={
        searchValue === null
          ? dataSource
          : functionSearch(dataSource, searchValue)
      }
      renderItem={fnl => {
        const {
          id,
          name,
          has_config,
          has_data,
          has_initial_situation,
          manual_initial_situation,
        } = fnl;
        return (
          <List.Item>
            {!isDataLoading && (
              <ListCard
                title={name}
                type={REPORT.REPORTS_KEYS.FNL_KEY}
                projectionId={id}
                periodId={periodId}
                image={emptyImage}
                description={t('REPORT_FINANCIAL_CARD_TITLE_NO_DATA')}
                hasData={has_data}
                actions={[
                  <ButtonActionBar position="start">
                    <Tooltip
                      title={
                        !has_config && !canUserCreate
                          ? t('ALERT_NOT_ALLOWED')
                          : ''
                      }
                    >
                      <Button
                        type="link"
                        disabled={
                          (!has_config && !canUserCreate) ||
                          (has_config && !has_data)
                        }
                        onClick={() => {
                          if (has_config) {
                            if (
                              !has_initial_situation[PROJECTION.REAL] &&
                              !manual_initial_situation[PROJECTION.REAL]
                            ) {
                              setReportId(id);
                              setShowInitialSituationModal(true);
                            } else {
                              history.push(
                                `/analisis/${periodId}/reportesplika/financieros/reportes/${id}`
                              );
                            }
                          } else {
                            history.push(
                              `/configuracion/misdatos/reportes/financieros/reporte/${id}`
                            );
                          }
                        }}
                      >
                        {has_config
                          ? t('ACTION_ENTER')
                          : t('ACTION_CONFIGURATE')}
                        <RightCircleOutlined />
                      </Button>
                    </Tooltip>
                  </ButtonActionBar>,
                ]}
                emptyState={{
                  description: t('REPORT_FINANCIAL_CARD_TITLE_NO_DATA'),
                  ...(!has_config && {
                    title: t('REPORT_TOOLTIP_CONFIGURATION_TITLE'),
                    description: t('REPORT_CARD_TITLE_NO_CONFIG'),
                  }),
                }}
              />
            )}
          </List.Item>
        );
      }}
    />
  );

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>{renderTopActions()}</Col>
      <Col span={24}>{renderReportList()}</Col>
      <InitialSituationModal
        showInitialSituationModal={showInitialSituationModal}
        setShowInitialSituationModal={setShowInitialSituationModal}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        periodId={periodId}
        reportId={reportId}
        setReportId={setReportId}
      />
    </Row>
  );
};

const mapStateToProps = state => ({
  financialReportsList: reports.selectors.getFinancialReportsList(state),
});

const mapDispatchToProps = {
  fetchFinancialReportList: reports.actions.fetchFinancialReportList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);
