import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, message } from 'antd';
import { calculateScrollX, columnsHelper } from 'modules/core/utils';
import { ACCES_USERS, PROJECTION, ROWS } from 'modules/core/constants';
import reports from 'modules/reports';
import login from 'modules/login';
import _ from 'lodash';

import './ReportTable.scss';

const ReportTable = ({
  reportId,
  period,
  consolidated,
  reportColumns: { columns },
  fetchReportColumns,
  fetchReportData,
  tableLoading,
  fetchReportOpenRow,
  reportTableByPeriods,
  type = PROJECTION.BUDGET,
  loggedUser: { email },
}) => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [periodTableColumns, setPeriodTableColumns] = useState([]);
  const [isExpandingByPeriod, setIsExpandingByPeriod] = useState(false);
  const [expandedByPeriodRow, setExpandedByPeriodRow] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setPeriodTableColumns(
      columnsHelper.generatorReportColumns({
        columns,
        has_restriction: [
          ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
        ].some(el => email.includes(el)),
      })
    );
  }, [columns, email]);

  useEffect(() => {
    setIsDataLoading(true);
    Promise.all([
      fetchReportData(reportId, {
        period_id: period,
        consolidated_id: consolidated,
      }),
      fetchReportColumns(reportId, { period_id: period }),
    ]).then(() => setIsDataLoading(false));
  }, [
    period,
    reportId,
    consolidated,
    fetchReportData,
    fetchReportColumns,
    tableLoading,
  ]);

  const onExpandByPeriod = (expanded, record) => {
    setIsExpandingByPeriod(true);
    if (expanded) {
      fetchReportOpenRow(record.id, {
        consolidated_id: consolidated,
        period_id: period,
      })
        .then(() => {
          setIsExpandingByPeriod(false);
          setExpandedByPeriodRow(prevExpanded => [...prevExpanded, record.id]);
        })
        .catch(() => {
          setIsExpandingByPeriod(false);
          message.error(t('FEEDBACK_NOT_DATA_REQUEST'));
        });
    } else {
      setExpandedByPeriodRow(prevExpanded =>
        prevExpanded.filter(item => item !== record.id)
      );
      setIsExpandingByPeriod(false);
    }
  };

  const renderRowClassName = record => {
    if (record.has_variable_applied) {
      return `${record.has_variable_applied ? ROWS.TYPES.APPLIED_VAR_ROW : ''}`;
    }

    return ROWS.TYPES[record.type];
  };

  return (
    <Table
      rowKey="id"
      size="small"
      className="report-table"
      loading={isExpandingByPeriod || isDataLoading || tableLoading}
      columns={periodTableColumns}
      dataSource={
        !_.isEmpty(reportTableByPeriods) && reportTableByPeriods.rows[type]
      }
      rowClassName={renderRowClassName}
      onExpand={onExpandByPeriod}
      expandedRowKeys={expandedByPeriodRow}
      defaultExpandAllRows={false}
      scroll={{ x: calculateScrollX(periodTableColumns), y: 450 }}
      pagination={false}
    />
  );
};

const mapStateToProps = state => ({
  reportColumns: reports.selectors.getReportColumns(state),
  reportTableByPeriods: reports.selectors.getReportTableByPeriods(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchReportColumns: reports.actions.fetchReportColumns,
  fetchReportData: reports.actions.fetchReportData,
  fetchReportRow: reports.actions.fetchReportRow,
  fetchReportOpenRow: reports.actions.fetchReportOpenRow,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
