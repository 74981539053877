import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  notification,
} from 'antd';
import { InputMonth } from 'modules/core/components';
import { generateSelectOptions } from 'modules/core/utils';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import planning from 'modules/planning';
import reports from 'modules/reports';
import configuration from 'modules/configuration';
import { PROJECTION, REPORT } from 'modules/core/constants';

const DuplicateReportModal = ({
  monthsList,
  fetchMonthsList,
  periodId,
  showDuplicateModal,
  setShowDuplicateModal,
  createExchangeRateReport,
  duplicateReport,
  fetchCurrencyList,
  currencyList,
  type = REPORT.REPORTS_KEYS.PNL_KEY,
  reportId,
  onRefetchReportList,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [dataExchangeRate, setDataExchangeRate] = useState({});
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    Promise.all([fetchMonthsList(periodId), fetchCurrencyList()]);
  }, [fetchMonthsList, periodId, fetchCurrencyList]);

  const onClose = () => {
    form.resetFields();
    setIsSaving(false);
    setShowDuplicateModal(false);
  };

  const handleNotificationError = error =>
    notification.error({
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: error.response
        ? error.response.data
        : t('FEEDBACK_SAVE_CHANGES_FAIL'),
      duration: 0,
    });

  const onConfirm = () => {
    setIsSaving(true);
    message.loading({
      key: 'loading_edit',
      content: t('FEEDBACK_LOADING_CHANGES'),
    });
    form.validateFields().then(values => {
      setIsSaving(true);

      const dataCreateExchangeRate = {
        to_currency_code: currencyList.data.find(
          currency => currency.id === values.currency_id
        ).code,
        exr_by_month: dataExchangeRate,
        period_id: periodId,
      };

      const dataDuplicateReport = {
        type,
        name: values.name,
      };
      createExchangeRateReport(dataCreateExchangeRate)
        .then(response => {
          duplicateReport(reportId, dataDuplicateReport, {
            exchangerate_id: response.payload.data.id,
          })
            .then(() => {
              Promise.all([onRefetchReportList()]).then(() => {
                message.destroy('loading_edit');
                message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
                onClose();
              });
            })
            .catch(error => {
              handleNotificationError(error);
              onClose();
            });
        })
        .catch(error => {
          handleNotificationError(error);
          onClose();
        });
    });
  };

  return (
    <Modal
      title={t('REPORT_DUPLICATE_MODAL')}
      visible={showDuplicateModal}
      onOk={onConfirm}
      okText={t('ACTION_SAVE')}
      onCancel={onClose}
      centered
      okButtonProps={{ loading: isSaving, disabled: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      cancelText={t('ACTION_CANCEL')}
    >
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t('REPORT_DUPLICATE_NAME')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('REPORT_DUPLICATE_NAME_PLACEHOLDER')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="currency_id"
              label={t('REPORT_DUPLICATE_CHOOSE_CURRENCY')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({
                  options: currencyList.data
                    .filter(
                      currency =>
                        currency.value_type === PROJECTION.FUNCTIONAL_KEY &&
                        !currency.predefined
                    )
                    .map(option => {
                      return {
                        id: option.id,
                        name: option.code,
                      };
                    }),
                })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="report-exchange-rate"
              label={t('REPORT_DUPLICATE_LOAD_EXCHANGE_RATE')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <div style={{ marginLeft: 47 }}>
                {monthsList.map((month, index) => (
                  <InputMonth
                    index={index}
                    month={month}
                    defaultValue={0}
                    onChange={value =>
                      setDataExchangeRate(prevState => {
                        return { ...prevState, [month]: value };
                      })
                    }
                    style={index < monthsList.length - 1 && { paddingRight: 5 }}
                  />
                ))}
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  monthsList: planning.selectors.getMonthsList(state),
  currencyList: configuration.selectors.getCurrencyList(state),
});

const mapDispatchToProps = {
  fetchMonthsList: planning.actions.fetchMonthsList,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  createExchangeRateReport: reports.actions.createExchangeRateReport,
  duplicateReport: reports.actions.duplicateReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateReportModal);
