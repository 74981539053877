import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SiderLayout } from 'modules/core/layouts';
import { Spin, Button, Row, Col, notification, Alert, Tooltip } from 'antd';
import {
  ButtonActionBar,
  PeriodNavigator,
  EmptyScreen,
} from 'modules/core/components';
import { ForecastPeriodList } from './components';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import forecast from 'modules/forecast';
import planning from 'modules/planning';

const {
  FORECAST__EXPENSES__CREATE,
  FORECAST__SALES__CREATE,
  FORECAST__CONSOLIDATED__CREATE,
} = ROLES;

const ForecastHome = ({
  forecasts,
  forecastCreatable: { creatable, message },
  fetchPeriodDetail,
  fetchForecasts,
  createForecast,
  fetchForecastCreatable,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isCreatingForecast, setIsCreatingForecast] = useState(false);
  let { periodId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    Promise.all([
      fetchForecasts({ period: periodId }),
      fetchPeriodDetail(periodId),
      fetchForecastCreatable({ period: periodId }),
    ]).then(() => setIsScreenLoading(false));
  }, [fetchForecasts, fetchPeriodDetail, fetchForecastCreatable, periodId]);

  const userCanCreate = isUserAllowed([
    FORECAST__SALES__CREATE,
    FORECAST__EXPENSES__CREATE,
    FORECAST__CONSOLIDATED__CREATE,
  ]);

  const onCreateForecast = () => {
    setIsCreatingForecast(true);
    createForecast({ period: periodId })
      .then(() => {
        fetchForecastCreatable({ period: periodId });
        fetchForecasts({ period: periodId });
        setIsCreatingForecast(false);
      })
      .catch(error => {
        notification.error({
          message: t('FORECAST_CREATE_ERROR_FEEDBACK'),
          description: error.response.data[0],
          duration: 0,
        });
        setIsCreatingForecast(false);
      });
  };

  const renderTopAlert = () => (
    <Alert
      showIcon
      closable
      type="warning"
      message={t('FORECAST_NO_DATA_ALERT_TITLE')}
      description={t('FORECAST_NO_DATA_ALERT_TEXT')}
    />
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_FORECAST')}
      description={t('FORECAST_EMPTY_STATE_TEXT')}
      footer={
        userCanCreate && (
          <Tooltip title={message ? message : ''}>
            <Button
              type="primary"
              onClick={onCreateForecast}
              loading={isCreatingForecast}
              disabled={!creatable}
            >
              {t('FORECAST_CREATE_ACTION')}
            </Button>
          </Tooltip>
        )
      }
    />
  );

  const renderHeader = () => (
    <PeriodNavigator items={[{ name: t('LABEL_FORECAST') }]} />
  );

  //TODO: pedir a back cambiar texto de message
  const renderCreateButton = () =>
    userCanCreate && (
      <ButtonActionBar>
        <Tooltip title={message ? message : ''}>
          <Button
            type="primary"
            onClick={onCreateForecast}
            loading={isCreatingForecast}
            disabled={!creatable}
          >
            {t('FORECAST_CREATE_ACTION')}
          </Button>
        </Tooltip>
      </ButtonActionBar>
    );

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      {!isScreenLoading && forecasts.length <= 0 && (
        <Row gutter={[24, 24]}>
          {!creatable && <Col span={24}> {renderTopAlert()}</Col>}
          <Col span={24}>{renderNoDataScreen()}</Col>
        </Row>
      )}
      {!isScreenLoading && forecasts.length > 0 && (
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderCreateButton()}</Col>
          <Col span={24}>
            <ForecastPeriodList
              dataSource={forecasts}
              fetchForecasts={() => {
                fetchForecastCreatable({ period: periodId });
                fetchForecasts({ period: periodId });
              }}
            />
          </Col>
        </Row>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  forecasts: forecast.selectors.getForecasts(state),
  forecastCreatable: forecast.selectors.getForecastCreatable(state),
});

const mapDispatchToProps = {
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  fetchForecasts: forecast.actions.fetchForecasts,
  createForecast: forecast.actions.createForecast,
  deleteForecast: forecast.actions.deleteForecast,
  fetchForecastCreatable: forecast.actions.fetchForecastCreatable,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastHome);
