import Immutable from 'seamless-immutable';
import * as types from './constants';

const initialState = Immutable({
  integrationList: [],
  integrationTrackingList: [],
});

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INTEGRATION_LIST_SUCCESS:
      return state.merge({
        integrationList: action.payload.data,
      });

    case types.FETCH_INTEGRATION_TRACKING_LIST_SUCCESS:
      return state.merge({
        integrationTrackingList: action.payload.data,
      });

    default:
      return state;
  }
};
