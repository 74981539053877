import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { PROJECTION } from 'modules/core/constants';

const StatusTag = ({ status, ...otherProps }) => {
  const { t } = useTranslation();
  return (
    <Tag {...otherProps} color={PROJECTION.STATUS_COLORS[status]}>
      {t(status)}
    </Tag>
  );
};

export default StatusTag;
