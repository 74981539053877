import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Modal,
  Form,
  Col,
  Row,
  DatePicker,
  TimePicker,
  Button,
  message,
  Tooltip,
} from 'antd';
import { ScheduleOutlined } from '@ant-design/icons';
import planning from 'modules/planning';
import { useTranslation } from 'react-i18next';

const FormItem = Form.Item;

// ToDo: agregar permisos, quienes pueden editar la fecha límite?
const DeadlineModal = ({
  period,
  concept,
  setUploadLimitDate,
  updateUploadLimitDate,
  getUploadLimitDate,
  editDisabled,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    getUploadLimitDate({ concept: concept, period: period }).then(deadline => {
      if (deadline[0]) {
        setInitialValues(deadline[0]);
      }
    });
  }, [getUploadLimitDate, concept, period]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...(initialValues.date && {
          date: moment(initialValues.date, 'YYYY-MM-DD'),
        }),
        ...(initialValues.time && {
          time: moment(initialValues.time, 'HH:mm'),
        }),
      });
    }
  }, [visible, form, initialValues]);

  const disabledDate = current => {
    return current && current < moment().startOf('day');
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const createDeadline = () => {
    form
      .validateFields()
      .then(({ date, time }) => {
        setIsLoading(true);
        const data = {
          period: parseInt(period),
          concept: concept,
          date: date.format('YYYY-MM-DD'),
          time: time ? time.format('HH:mm') : null,
        };
        setUploadLimitDate(data)
          .then(newValue => {
            setInitialValues(newValue);
            setIsLoading(false);
            message.success(t('PLANNING_CREATE_DEADLINE_SUCCESS_FEEDBACK'));
            onClose();
          })
          .catch(() => {
            setIsLoading(false);
            message.error(t('PLANNING_CREATE_DEADLINE_ERROR_FEEDBACK'));
            onClose();
          });
      })
      .catch(e => {});
  };

  const editDeadline = () => {
    form
      .validateFields()
      .then(({ date, time }) => {
        setIsLoading(true);
        const data = {
          date: date.format('YYYY-MM-DD'),
          time: time ? time.format('HH:mm') : null,
        };
        updateUploadLimitDate(initialValues.id, data)
          .then(newValue => {
            setInitialValues(newValue);
            setIsLoading(false);
            message.success(t('PLANNING_UPDATE_DEADLINE_SUCCESS_FEEDBACK'));
            onClose();
          })
          .catch(() => {
            setIsLoading(false);
            message.error(t('PLANNING_UPDATE_DEADLINE_ERROR_FEEDBACK'));
            onClose();
          });
      })
      .catch(e => {});
  };

  const getDatetimeFormated = () => {
    if (initialValues.date) {
      return initialValues.time
        ? `${moment(initialValues.date).format('DD/MM/YYYY')} - ${moment(
            initialValues.time,
            'HH:mm:ss'
          ).format('HH:mm')} `
        : ` ${moment(initialValues.date).format('DD/MM/YYYY')} `;
    } else return t('STATE_TAG_UNDEFINED');
  };
  return (
    <>
      <Tooltip title={getDatetimeFormated}>
        <Button
          onClick={() => setVisible(true)}
          icon={<ScheduleOutlined />}
          disabled={editDisabled}
        >
          {t('FIELD_DEADLINE')}
        </Button>
      </Tooltip>

      <Modal
        title={t('PLANNING_DEADLINE_MODAL_TITLE', {
          field: initialValues.id ? t('ACTION_EDIT') : t('ACTION_DEFINE'),
        })}
        visible={visible}
        centered
        onOk={initialValues.id ? editDeadline : createDeadline}
        okText={t('ACTION_SAVE')}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
        onCancel={onClose}
        cancelText={t('ACTION_CANCEL')}
      >
        <Form form={form} layout="vertical" hideRequiredMark={false}>
          <Row gutter={24} type="flex" justify="center">
            <Col span={12}>
              <FormItem
                className="form"
                name="date"
                label={t('FIELD_DATE')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <DatePicker
                  placeholder={t(
                    'PLANNING_CREATE_FORM_DEADLINE_DATE_PLACEHOLDER'
                  )}
                  format="DD/MM/YYYY"
                  disabledDate={disabledDate}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="time" label={t('FIELD_TIME')}>
                <TimePicker
                  placeholder={t(
                    'PLANNING_CREATE_FORM_DEADLINE_TIME_PLACEHOLDER'
                  )}
                  format="HH:mm"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = {
  setUploadLimitDate: planning.actions.setUploadLimitDate,
  updateUploadLimitDate: planning.actions.updateUploadLimitDate,
  getUploadLimitDate: planning.actions.getUploadLimitDate,
};

export default connect(null, mapDispatchToProps)(DeadlineModal);
