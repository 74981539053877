import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { message, notification } from 'antd';
import i18n from 'i18next';

export const getDashboard = (id, url) => {
  var dashboard;
  const onDashboardLoad = payload => {
    console.log('Do something when the dashboard is fully loaded.');
  };

  const onError = payload => {
    console.log('Do something when the dashboard fails loading');
  };
  const embedDashboard = () => {
    var containerDiv = document.getElementById(id);
    var options = {
      url,
      container: containerDiv,
      parameters: {
        country: 'United States',
      },
      scrolling: 'no',
      iframeResizeOnSheetChange: true,
      height: 'AutoFit',
      loadingHeight: '700px',
      width: '100%',
    };
    dashboard = QuickSightEmbedding.embedDashboard(options);
    dashboard.on('error', onError);
    dashboard.on('load', onDashboardLoad);
    return dashboard;
  };
  return embedDashboard;
};

export const handleUpdateDataSICA = ({
  setIsLoading,
  updateDataSICA,
  onRefetch,
}) => {
  setIsLoading(true);
  updateDataSICA()
    .then(() =>
      onRefetch().then(() => {
        setIsLoading(false);
        message.success(i18n.t('FEEDBACK_UPDATE_DATA_SUCCESS'));
      })
    )
    .catch(() => {
      setIsLoading(false);
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: i18n.t('FEEDBACK_UPDATE_DATA_ERROR'),
        duration: 0,
      });
    });
};

export const handleUpdateDataZarcam = ({
  setIsLoading,
  updateDataZarcam,
  onRefetch,
}) => {
  setIsLoading(true);
  updateDataZarcam()
    .then(() =>
      onRefetch().then(() => {
        setIsLoading(false);
        message.success(i18n.t('FEEDBACK_UPDATE_DATA_SUCCESS'));
      })
    )
    .catch(() => {
      setIsLoading(false);
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: i18n.t('FEEDBACK_UPDATE_DATA_ERROR'),
        duration: 0,
      });
    });
};

export const handleUpdateDataOther = ({
  setIsLoading,
  updateDataOther,
  onRefetch,
}) => {
  setIsLoading(true);
  updateDataOther()
    .then(() =>
      onRefetch().then(() => {
        setIsLoading(false);
        message.success(i18n.t('FEEDBACK_UPDATE_DATA_SUCCESS'));
      })
    )
    .catch(() => {
      setIsLoading(false);
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: i18n.t('FEEDBACK_UPDATE_DATA_ERROR'),
        duration: 0,
      });
    });
};
