import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Space, Popconfirm, message, notification } from 'antd';
import {
  DropdownMenu,
  Typography,
  InputCell,
  SelectCell,
  DeleteAndUpdate,
  SelectType,
} from 'modules/core/components';
import {
  isUserAllowed,
  generateSelectConceptOptions,
  userCanAcces,
} from 'modules/core/utils';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  DIMENSIONS,
  ROLES,
  CONCEPTS,
  ACCES_USERS,
} from 'modules/core/constants';
import configuration from 'modules/configuration';
import login from 'modules/login';

const { SETTINGS__PLAN_ACCOUNTS__EDIT, SETTINGS__PLAN_ACCOUNTS__DELETE } =
  ROLES;

const isLevel = value => value === DIMENSIONS.LEVEL_KEY;

//TODO: Comentado lo de seleccion multiple hasta que se sepa las validaciones del eliminar cuando es info tipo arbol
//NOTE: COMENTADO LO DE EDITAR CONCEPTO POR INTERFAZ HASTA QUE FUNCIONE BIEN
const PlanAccountsTable = ({
  tableLoading,
  dataSource,
  searchValue,
  onSearchValue,
  //onSetRowSelection,
  //dataSelection,
  deletePlanAccount,
  onRefetchDimensioValues,
  editPlanAccount,
  conceptsList: { values },
  loggedUser: { email },
  // editConceptCascader,
}) => {
  const [deleteLevelId, setDeleteLevelId] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  const onCellEdit = (name, value) =>
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });

  const ECONOMIC_CONCEPTS = values
    .filter(concept => concept.type === CONCEPTS.TYPE.ECONOMIC_KEY)
    .map(concept => concept.id);

  const onPrepareConceptData = selectedConcepts => {
    let selectedAllIds = [];
    const selectedIds = _.compact([
      ...selectedConcepts.map(conceptData => {
        if (!(conceptData.length > 1)) {
          selectedAllIds = [
            ...selectedAllIds,
            ...values
              .filter(concept => concept.type === conceptData[0])
              .map(concept => concept.id),
          ];
        }
        return conceptData[1];
      }),
    ]);
    onCellEdit('concept_id', [...selectedAllIds, ...selectedIds]);
  };

  const renderCascaderSelect = (record, defaultValue) => (
    <SelectType.Cascader
      defaultValue={record ? defaultValue : undefined}
      options={generateSelectConceptOptions({ values })}
      onChange={onPrepareConceptData}
    />
  );

  const renderNormalSelect = record => (
    <SelectCell
      initialValue={record ? record.map(concept => concept.id) : undefined}
      onCellUpdate={onCellEdit}
      name={'concept_id'}
      options={values
        .filter(val => val.type === CONCEPTS.TYPE.ECONOMIC_KEY)
        .map(concept => {
          return { id: concept.id, name: t(concept.name) };
        })}
      placeholder={t('ACTION_SELECT')}
      mode="multiple"
      allowClear
      maxTagCount={1}
      maxTagTextLength={11}
    />
  );

  const renderConceptsName = ({ dataConcept }) =>
    dataConcept.length > 0
      ? dataConcept.map(concept => concept.name).join(', ')
      : '-';

  const columns = [
    {
      title: t('FIELD_CODE'),
      dataIndex: 'code',
      width: 200,
      editable: true,
      render: (record, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={row.code}
              onCellUpdate={onCellEdit}
              name="code"
            />
          );
        } else {
          return record;
        }
      },
    },
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      width: 200,
      editable: true,
      render: (record, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={row.name}
              onCellUpdate={onCellEdit}
              name="name"
            />
          );
        } else {
          return record;
        }
      },
    },
    {
      title: (
        <Space>
          {t('FIELD_ASSOCIATED_CONCEPT')}
          <Tooltip title={t('CONFIG_ACCOUNTS_EDIT_INFORMATIVE_TOOLTIP_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} level={2} />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'concept',
      width: 200,
      render: (record, row) => {
        //NOTE: PROVISORIO auxDataConcepts HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS
        const auxDataConcepts =
          record &&
          record.filter(concept => ECONOMIC_CONCEPTS.includes(concept.id));

        if (editRow && row.id === editRow) {
          const defaultValue =
            record &&
            record.map(concept => {
              if (ECONOMIC_CONCEPTS.includes(concept.id)) {
                return [CONCEPTS.TYPE.ECONOMIC_KEY, concept.id];
              } else {
                return [CONCEPTS.TYPE.FINANCIAL_KEY, concept.id];
              }
            });
          if (!isLevel(row.dimension)) {
            return (
              // NOTE: PROVISORIO HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS
              userCanAcces({
                patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
                email,
              })
                ? renderCascaderSelect(record, defaultValue)
                : renderNormalSelect(auxDataConcepts)
            );
          } else {
            return '-';
          }
        } else {
          if (record) {
            // NOTE: PROVISORIO HASTA QUE SE PUEDA VER POR TODOS LOS USUARIOS.DEJAR LO QUE ESTA DENTRO DE LA FUNCION CUANDO PUEDAN VERLOS TODOS
            const dataConcept = userCanAcces({
              patchAcces: ACCES_USERS.PATCH_ACCES_FINANCIAL_USERS,
              email,
            })
              ? record
              : auxDataConcepts;
            return renderConceptsName({ dataConcept });
          } else {
            return '-';
          }
        }
      },
    },
    // {
    //   title: t('FIELD_STATUS'),
    //   dataIndex: 'deleted',
    //   width: 200,
    //   editable: true,
    //   render: (deleted, row) =>
    //     !deleted ? t('STATE_TAG_ACTIVE') : t('STATE_TAG_INACTIVE'),
    //POR AHORA COMENTADO HASTA TENER DEFINICION SI SE EDITA O NO
    // if (editRow && row.code === editRow) {
    //   return (
    //     <SelectCell
    //       initialValue={!deleted ? 'active' : 'inactive'}
    //       onCellUpdate={onCellEdit}
    //       name={'status'}
    //       options={[
    //         { id: 'active', name: 'Activo' },
    //         { id: 'inactive', name: 'Inactivo' },
    //       ]}
    //     />
    //   );
    // } else {
    //   if (!deleted) {
    //     return 'Activo';
    //   } else {
    //     return 'Inactivo';
    //   }
    // },
    {
      title: (
        <Space size="large">
          {t('FIELD_ACTIONS')}
          <Tooltip title={t('CONFIG_ACCOUNTS_INFO_TOOLTIP_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} level={2} />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'id',
      align: 'right',
      width: 150,
      render: (record, row) => {
        return row.id !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_ACCOUNTS_CONFIRM_DELETE_LEVEL_TITLE')}
            okText={t('ACTION_DELETE')}
            onConfirm={() => onDeleteLevel(row.id)}
            okButtonProps={{ loading: isDeleting }}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteLevelId(null)}
            visible={deleteLevelId === row.id}
          >
            <DropdownMenu
              title={t('ACTION_MORE')}
              menu={_.compact([
                {
                  title: t('ACTION_EDIT'),
                  icon: <EditOutlined />,
                  disabled: !isUserAllowed(SETTINGS__PLAN_ACCOUNTS__EDIT),
                  onClick: () => {
                    setEditRow(row.id);
                  },
                },
                {
                  title: t('ACTION_DELETE'),
                  icon: <DeleteOutlined />,
                  disabled: !isUserAllowed(SETTINGS__PLAN_ACCOUNTS__DELETE),
                  onClick: () => setDeleteLevelId(row.id),
                },
              ])}
            />
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onConfirm={onConfirm}
            onCancel={onCancel}
            // onSave={() => onEditLevel(row.dimension, row.id)}
            onSave={() => onEditLevel()}
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        );
      },
    },
  ];

  const onConfirm = () => {
    setEditRow(null);
    setDataCellEdit({});
  };

  const onCancel = () => setEditRow(null);

  const onEditLevel = () => {
    if (!_.isEmpty(dataCellEdit)) {
      setIsEditing(true);
      // const data = {
      //   ...(dataCellEdit.code && {
      //     code: dataCellEdit.code,
      //   }),
      //   ...(dataCellEdit.name && {
      //     name: dataCellEdit.name,
      //   }),
      //   ...(!isLevel(dataType) &&
      //     dataCellEdit.concept_id && {
      //       concept_id: dataCellEdit.concept_id,
      //     }),
      // };
      // Promise.all(
      //   _.compact([
      //     !_.isEmpty(data) ? editPlanAccount(editRow, data) : null,
      //     isLevel(dataType)
      //       ? editConceptCascader({
      //           concept_id: dataCellEdit.concept_id,
      //           cuenta_id: id,
      //         })
      //       : null,
      //   ])
      // )
      editPlanAccount(editRow, dataCellEdit)
        .then(() => {
          onRefetchDimensioValues().then(() => {
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
            setEditRow(null);
            setDataCellEdit({});
            setIsEditing(false);
          });
        })
        .catch(error => {
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: error.response.data[0],
            duration: 0,
          });
          setEditRow(null);
          setDataCellEdit({});
          setIsEditing(false);
        });
    } else {
      setEditRow(null);
      setDataCellEdit({});
    }
  };

  const onDeleteLevel = id => {
    setIsDeleting(true);
    deletePlanAccount(id)
      .then(() => {
        onRefetchDimensioValues().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          setIsDeleting(false);
          setDeleteLevelId(null);
        });
      })
      .catch(error => {
        error
          ? notification.error({
              message: t('FEEDBACK_SAVE_CHANGES_FAIL'),
              description: error,
              duration: 0,
            })
          : message.error(t('FEEDBACK_DELETE_FAIL'));
        setIsDeleting(false);
        setDeleteLevelId(null);
      });
  };

  const renderAccountsList = record => {
    return (
      <Table
        rowKey="code"
        size="small"
        columns={columns}
        dataSource={
          searchValue === null
            ? record.auxChildren
            : onSearchValue(record.auxChildren, searchValue)
        }
        showHeader={false}
        pagination={{
          size: 'small',
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        expandable={{
          expandedRowRender: renderAccountsList,
          rowExpandable: record =>
            record.auxChildren && record.auxChildren.length !== 0,
        }}
      />
    );
  };

  return (
    <Table
      rowKey="code"
      // rowSelection={{
      //   selectedRowKeys: dataSelection,
      //   onChange: value => onSetRowSelection(value),
      //   //checkStrictly: false,
      // }}
      size="small"
      loading={tableLoading || isEditing || isDeleting}
      columns={columns}
      dataSource={
        searchValue === null
          ? dataSource
          : onSearchValue(dataSource, searchValue)
      }
      scroll={{ y: 450 }}
      expandable={{
        expandedRowRender: renderAccountsList,
        rowExpandable: record =>
          record.auxChildren && record.auxChildren.length !== 0,
      }}
      pagination={false}
    />
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  deletePlanAccount: configuration.actions.deletePlanAccount,
  editPlanAccount: configuration.actions.editPlanAccount,
  editConceptCascader: configuration.actions.editConceptCascader,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanAccountsTable);
