import React, { useState } from 'react';
import { Select, Space, Spin, Row, Col } from 'antd';
import { SegmentedAnalysisTable } from './components';
import { SelectType } from 'modules/core/components';
import { generateSelectOptions } from 'modules/core/utils';
import { ACCES_USERS, PROJECTION } from 'modules/core/constants';
import { connect } from 'react-redux';
import login from 'modules/login';

import './SegmentedAnalysis.scss';

const SegmentedAnalysis = ({
  filters,
  selectedDates,
  periodDetail,
  loadingDimensions,
  optionsSelectDimension,
  periodId,
  reportId,
  consolidationListForecast,
  consolidationListBudget,
  activeOptionPeriodTable,
  functionalCurrencyData,
  loggedUser: { email },
}) => {
  const [activeBySegmentedTable, setActiveBySegmentedTable] = useState(
    [...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS].some(el =>
      email.includes(el)
    )
      ? { value: PROJECTION.REAL, id: null }
      : activeOptionPeriodTable
  );
  const [dimensionId, setDimensionId] = useState(null);

  const dataConsolidatedDefault = {
    conso_forecast_predef: periodDetail.conso_forecast_predef,
    conso_budget_predef: periodDetail.conso_budget_predef,
  };

  return (
    <>
      <Spin spinning={loadingDimensions} />
      {!loadingDimensions && optionsSelectDimension.length !== 0 && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Space direction="horizontal">
                <SelectType.Evolution
                  activeOptionTable={activeBySegmentedTable}
                  setActiveByPeriodTable={setActiveBySegmentedTable}
                  dataConsolidatedDefault={dataConsolidatedDefault}
                  consolidationsPredef={{
                    budget: periodDetail.conso_budget_predef,
                    forecast: periodDetail.conso_forecast_predef,
                  }}
                  consolidationLists={{
                    budget: consolidationListBudget,
                    forecast: consolidationListForecast,
                  }}
                />
                <Select
                  className="report-selectors"
                  defaultValue={optionsSelectDimension[0].id}
                  onChange={id => setDimensionId(id)}
                  options={generateSelectOptions({
                    options: optionsSelectDimension,
                  })}
                />
              </Space>
            </Col>
            <Col span={24}>
              <SegmentedAnalysisTable
                activeBySegmentedTable={activeBySegmentedTable}
                reportId={reportId}
                periodId={periodId}
                filters={filters}
                selectedDates={selectedDates}
                periodDetail={periodDetail}
                dimensionId={
                  dimensionId
                    ? dimensionId
                    : optionsSelectDimension[0].id.toString()
                }
                functionalCurrencyData={functionalCurrencyData}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

export default connect(mapStateToProps, null)(SegmentedAnalysis);
