import * as types from './constants';
import { saveAs } from 'file-saver';
import i18n from 'i18next';

// ++ DIMENSIONS ++
export const fetchDimensionsList =
  (params = {}) =>
  async dispatch => {
    const { roleDimensions, ...restParams } = params;
    const response = await dispatch({
      type: !roleDimensions
        ? types.FETCH_DIMENSIONS_LIST
        : types.FETCH_ROLE_DIMENSIONS_LIST,
      payload: {
        request: {
          url: `/dimensions/dimensions/`,
          method: 'GET',
          params: restParams,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchDimensionValues = params => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_DIMENSION_VALUES,
    payload: {
      request: {
        url: `/dimensions/values/`,
        method: 'GET',
        params,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

//dimension value especifico de dimensiones
export const fetchValuesDimension = params => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_VALUES_DIMENSION,
    payload: {
      request: {
        url: `/dimensions/values/`,
        method: 'GET',
        params,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchDimensionValuesParents = params => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_DIMENSION_VALUES_PARENTS,
    payload: {
      request: {
        url: `/dimensions/values/`,
        method: 'GET',
        params,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const createDimension =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_DIMENSION,
      payload: {
        request: {
          url: `/dimensions/dimensions/`,
          method: 'POST',
          data: data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data[0]);
    }
    return response;
  };

export const deleteDimension = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_DIMENSION,
    payload: {
      request: {
        url: `/dimensions/dimensions/${id}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error.response.data);
  }
  return response;
};

export const deleteAllDimensionValues =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DELETE_ALL_DIMENSION_VALUES,
      payload: {
        request: {
          url: `/dimensions/values/delete-all-dimension-values/`,
          method: 'DELETE',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editDimensionName = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_DIMENSION_NAME,
    payload: {
      request: {
        url: `/dimensions/dimensions/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

//editar concepto asociado a dimension
export const editConceptDimension = data => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_CONCEPT_DIMENSION,
    payload: {
      request: {
        url: `/planning/concept-configs/`,
        method: 'POST',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

//eliminar concepto asociado a dimension
export const deleteConceptDimension = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_CONCEPT_DIMENSION,
    payload: {
      request: {
        url: `planning/concept-configs/${id}`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error.response.data);
  }
  return response;
};

//DIMENSION VALUE
export const createDimensionValue =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_DIMENSION_VALUE,
      payload: {
        request: {
          url: `/dimensions/values/`,
          method: 'POST',
          data: data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data[0]);
    }
    return response;
  };

export const editDimensionValue = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_DIMENSION_VALUE,
    payload: {
      request: {
        url: `/dimensions/values/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const deleteDimensionValue = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_DIMENSION_VALUE,
    payload: {
      request: {
        url: `/dimensions/values/${id}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error.response.data);
  }
  return response;
};

//++CREAR PLAN DE CUENTAS++
export const createPlanAccount =
  (data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_PLAN_ACCOUNT,
      payload: {
        request: {
          url: `/dimensions/values/`,
          method: 'POST',
          data: data,
          params: params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data[0]);
    }
    return response;
  };

//++ELIMINAR PLAN DE CUENTA++
export const deletePlanAccount = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_PLAN_ACCOUNT,
    payload: {
      request: {
        url: `/dimensions/values/${id}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error.response.data);
  }
  return response;
};

//++EDITAR PLAN DE CUENTA++
export const editPlanAccount =
  (id, data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.EDIT_PLAN_ACCOUNT,
      payload: {
        request: {
          url: `/dimensions/values/${id}/`,
          method: 'PATCH',
          data: data,
          params: params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchDimensionDetail = dimensionId => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_DIMENSION_DETAIL,
    payload: {
      request: {
        url: `/dimensions/dimensions/${dimensionId}`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchDisplaySettings =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DISPLAY_SETTINGS,
      payload: {
        request: {
          url: `/dimensions/user-configs/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createDisplaySettings =
  (data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_DISPLAY_SETTINGS,
      payload: {
        request: {
          url: `/dimensions/user-configs/create-user-config/`,
          method: 'POST',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const updateDisplaySettings =
  (settingId, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UPDATE_DISPLAY_SETTINGS,
      payload: {
        request: {
          url: `/dimensions/user-configs/${settingId}/`,
          method: 'patch',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const uploadDimensionValuesXLS =
  (data, params = {}) =>
  async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('file', file);
    });
    const response = await dispatch({
      type: types.UPLOAD_DIMENSION_VALUES_XLS,
      payload: {
        request: {
          url: `/dimensions/values/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data[0]);
    }
    return dispatch(fetchDimensionValues(params));
  };

export const fetchDimensionTemplateXLS =
  (
    fileName = i18n.t('CONFIG_DIMENSIONS_VALUES_TEMPLATE_NAME_DEFAULT'),
    params = {}
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DIMENSION_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/dimensions/values/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, fileName);
    return Promise.resolve();
  };

export const fetchDimensionValuesConsolidatedXLS =
  (fileName = i18n.t('CONFIG_DIMENSIONS_VALUES_FILE_NAME'), params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_DIMENSION_VALUES_CONSOLIDATED_XLS,
      payload: {
        request: {
          url: `/dimensions/values/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, fileName);
    return Promise.resolve();
  };

// ++ USERS ++
export const fetchUsersList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_USERS_LIST,
      payload: {
        request: {
          url: `/auth/users/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchUserDetail =
  (userId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_USER_DETAIL,
      payload: {
        request: {
          url: `/auth/users/${userId}`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchPlanAccountsConsolidatedXLS =
  (
    fileName = i18n.t('CONFIG_ACCOUNTS_STRUCTURE_FILE_NAME'),
    params = {
      dimension_name: 'Nivel',
    }
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PLAN_ACCOUNTS_CONSOLIDATED_XLS,
      payload: {
        request: {
          url: `/dimensions/values/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, fileName);
    return Promise.resolve();
  };

export const fetchUserTemplateXLS =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_USER_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/auth/users/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, i18n.t('CONFIG_USERS_TEMPLATE'));
    return Promise.resolve();
  };

export const fetchUserConsolidatedXLS = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_USER_CONSOLIDATED_XLS,
    payload: {
      request: {
        url: `/auth/users/consolidated/`,
        method: 'GET',
        responseType: 'blob',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }

  saveAs(response.payload.data, i18n.t('CONFIG_USERS_FILE_NAME'));
  return Promise.resolve();
};

export const uploadUsersXLS =
  (data, params = {}) =>
  async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('file', file);
    });
    const response = await dispatch({
      type: types.UPLOAD_USERS_XLS,
      payload: {
        request: {
          url: `/auth/users/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data[0]);
    }
    return response;
  };

export const fetchUserRoles =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_USER_ROLES,
      payload: {
        request: {
          url: `/auth/users/roles/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createUser = data => async dispatch => {
  const response = await dispatch({
    type: types.CREATE_USER,
    payload: {
      request: {
        url: `/auth/users/register/`,
        method: 'POST',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const editUser = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_USER,
    payload: {
      request: {
        url: `/auth/users/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return Promise.resolve();
};

export const deleteUser = userId => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_USER,
    payload: {
      request: {
        url: `/auth/users/${userId}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

// ++ MODIFICADORES ++
export const fetchModifiersCardList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_MODIFIERS_LIST,
      payload: {
        request: {
          url: '/planning/modifiers/info/',
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) return Promise.reject(response.error);
    return response;
  };

//  ++ VARIABLES +++

export const fetchVariablesList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_VARIABLES_LIST,
      payload: {
        request: {
          url: `/planning/variables/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchVariableDetail =
  (variableId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_VARIABLE_DETAIL,
      payload: {
        request: {
          url: `/planning/variables/${variableId}/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchVariablesKpis =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_VARIABLES_KPI,
      payload: {
        request: {
          url: `/planning/variables/kpi/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createVariable =
  (data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_VARIABLE,
      payload: {
        request: {
          url: `/planning/variables/`,
          method: 'POST',
          data: {
            name: data.name,
            value_type: data.value_type,
            behaviour: data.behaviour,
            accounts: data.accounts,
            status: 'active',
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    // return response;
    return dispatch(fetchVariablesList(params));
  };

export const deleteVariable = variableId => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_VARIABLE,
    payload: {
      request: {
        url: `/planning/variables/${variableId}/`,
        method: 'DELETE',
        params: { writeable: 'False' },
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const editVariable = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.UPDATE_VARIABLE,
    payload: {
      request: {
        url: `/planning/variables/${id}/`,
        method: 'PATCH',
        data: data,
        params: { writeable: 'False' },
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return Promise.resolve();
};

export const editVariableValues =
  (id, data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UPDATE_VARIABLE_VALUES,
      payload: {
        request: {
          url: `/planning/variables/${id}/drawer_update/`,
          method: 'PATCH',
          data: data,
          params: params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return Promise.resolve();
  };

export const setVariablesValues =
  (data = {}) =>
  dispatch =>
    dispatch({
      type: types.SET_VARIABLES_VALUES,
      payload: { ...data },
    });

export const fetchBehaviourList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_BEHAVIOUR_LIST,
      payload: {
        request: {
          url: `/planning/behaviours/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

// Fórmulas

export const fetchFormulasList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FORMULAS_LIST,
      payload: {
        request: {
          url: `/planning/formulas/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchFactoresList =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FACTORES_LIST,
      payload: {
        request: {
          url: `/planning/factores/${id}`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createFormulaFactor =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_FORMULA_FACTOR,
      payload: {
        request: {
          url: `/planning/formulas/`,
          method: 'POST',
          data: data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data[0]);
    }
    return response;
  };

export const fetchFormulaDetail =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FORMULA_DETAIL,
      payload: {
        request: {
          url: `/planning/formulas/${id}`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createFactor =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/`,
          method: 'POST',
          data: data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editFactor =
  (id, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UPDATE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/${id}/`,
          method: 'PATCH',
          data: data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return Promise.resolve();
  };

export const deleteFactor =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DELETE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/${id}/`,
          method: 'DELETE',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data);
    }
    return response;
  };

export const fetchFactoresBehaviours = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_FACTORES_BEHAVIOURS,
    payload: {
      request: {
        url: `/planning/factor-behaviours/`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

// Conceptos
export const deleteConceptAssociation = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_CONCEPT_ASSOCIATION,
    payload: {
      request: {
        url: `/planning/concept-configs/${id}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const createConceptAssociation =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_CONCEPT_ASSOCIATION,
      payload: {
        request: {
          url: `/planning/concept-configs/`,
          method: 'POST',
          data: {
            dimension_id: data.dimension,
            concept: data.concept,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

//ACCIONES FORMULA
export const editFormula = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.UPDATE_FORMULA,
    payload: {
      request: {
        url: `/planning/formulas/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return Promise.resolve();
};

export const deleteFormula = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_FORMULA,
    payload: {
      request: {
        url: `/planning/formulas/${id}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const deleteCascade = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_CASCADE,
    payload: {
      request: {
        url: `/planning/formulas/${id}/delete_cascade/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const desactivateFormula = id => async dispatch => {
  const response = await dispatch({
    type: types.DESACTIVATE_FORMULA,
    payload: {
      request: {
        url: `/planning/formulas/${id}/deactivate/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

//creacion nuevo reporte
export const createOtherReport =
  (data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_OTHER_REPORT,
      payload: {
        request: {
          url: '/report/custom/',
          method: 'POST',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchOtherReportsList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_OTHER_REPORT_LIST,
      payload: {
        request: {
          url: `/report/custom/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const updateOtherReportName =
  (id, data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UPDATE_REPORT_NAME,
      payload: {
        request: {
          url: `/report/custom/${id}/`,
          method: 'PATCH',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const deleteOtherReport =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DELETE_OTHER_REPORT,
      payload: {
        request: {
          url: `/report/custom/${id}/`,
          method: 'DELETE',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data);
    }
    return response;
  };

export const fetchOtherReportStructure =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_OTHER_REPORT_STRUCTURE,
      payload: {
        request: {
          url: `/report/custom/${id}/info/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

// ++ ROLES ++
export const fetchRolesList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_ROLES_LIST,
      payload: {
        request: {
          url: `/auth/users/roles/get_roles/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createRole =
  (data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_ROLE,
      payload: {
        request: {
          url: `/auth/users/roles/create_new_rol/`,
          method: 'POST',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const duplicateRole =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DUPLICATE_ROLE,
      payload: {
        request: {
          url: `auth/users/roles/duplicate_rol/`,
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editRole =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.EDIT_ROLE,
      payload: {
        request: {
          url: `/auth/users/roles/update_rol/`,
          method: 'PUT',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const deleteRole = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_ROLE,
    payload: {
      request: {
        url: `/auth/users/roles/delete_rol/`,
        data: { id: id },
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const editRoleOperations =
  ({ checked = false, operationsKeys = [] }) =>
  dispatch =>
    dispatch({
      type: types.EDIT_ROLE_OPERATIONS,
      payload: { checked, operationsKeys },
    });

export const saveRoleOperations =
  (data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.SAVE_ROLE_OPERATIONS,
      payload: {
        request: {
          url: `/auth/users/roles/update_operation_rol/`,
          method: 'PUT',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchRoleDimensionsValues =
  (data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_ROLE_DIMENSIONS_VALUES,
      payload: {
        request: {
          url: `/auth/users/dimensions/get_dimension/`,
          method: 'POST',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const selectRoleDimension =
  (data = {}) =>
  dispatch =>
    dispatch({
      type: types.SELECT_ROLE_DIMENSION,
      payload: { data },
    });

export const cleanAllRoleDimension = () => dispatch =>
  dispatch({
    type: types.CLEAN_ROLE_DIMENSION,
  });

export const asignRoleDimensions =
  (data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.ASIGN_ROLE_OPERATIONS,
      payload: {
        request: {
          url: `/auth/users/dimensions/asign_dimension/`,
          method: 'PUT',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchConceptAccountAssociationTemplateXLS =
  () => async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CONCEPT_ACCOUNT_ASSOCIATION_TEMPLATE_XLS,
      payload: {
        request: {
          url: '/dimensions/cuenta-concept/template/',
          method: 'GET',
          responseType: 'blob',
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(
      response.payload.data,
      i18n.t('CONFIG_ACCOUNT_CONCEPT_ASSOCIATION_TEMPLATE_NAME')
    );
    return Promise.resolve();
  };

export const uploadConceptAccountAssociationXLS = data => async dispatch => {
  const formData = new FormData();
  data.forEach(file => {
    formData.append('file', file);
  });
  const response = await dispatch({
    type: types.UPLOAD_CONCEPT_ACCOUNT_ASSOCIATION_XLS,
    payload: {
      request: {
        url: '/dimensions/cuenta-concept/excel-upload/',
        method: 'POST',
        data: formData,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const editConceptCascader = data => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_CONCEPT_CASCADER,
    payload: {
      request: {
        url: '/dimensions/cuenta-concept/',
        method: 'POST',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

/*-------MULTIMONEDA--------*/
export const fetchCurrencyList = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_CURRENCY_LIST,
    payload: {
      request: {
        url: `/planning/currency`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const createCurrencies = data => async dispatch => {
  const response = await dispatch({
    type: types.CREATE_CURRENCIES,
    payload: {
      request: {
        url: `/planning/currency/`,
        method: 'POST',
        data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const setCurrencyAction =
  (id, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.SET_CURRENCY_ACTION,
      payload: {
        request: {
          url: `/planning/currency/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const predefineCurrency = id => async dispatch => {
  const response = await dispatch({
    type: types.PREDEFINE_CURRENCY,
    payload: {
      request: {
        url: `/planning/currency/${id}/predefine/`,
        method: 'POST',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

/*-------ONBOARDING--------*/
export const fetchOnboardingSteps = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_ONBOARDING_STEPS,
    payload: {
      request: {
        url: '/auth/users/current/',
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const setOnboardingStep =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.SET_ONBOARDING_STEP,
      payload: {
        request: {
          url: '/auth/users/update_onboarding/',
          method: 'PUT',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };
