import React, { useState } from 'react';
import { Space, Popover } from 'antd';
import { Typography, CellStyle } from 'modules/core/components';
import i18n from 'i18next';

const renderContent = (fullValue, prefix, variationValue) => {
  if (fullValue || variationValue) {
    return (
      <Space direction="vertical">
        <Space direction="horizontal">
          <Typography.Subtitle>{`${i18n.t(
            'FIELD_FULL_VALUE'
          )}: `}</Typography.Subtitle>
          <Typography.Body level={3}>{`${
            fullValue ? `${prefix}${fullValue}` : ''
          }`}</Typography.Body>
        </Space>
        <Space direction="horizontal">
          <Typography.Subtitle>{`${i18n.t(
            'FIELD_VARIATION'
          )}: `}</Typography.Subtitle>
          <Typography.Body level={3}>
            <CellStyle.VariationKPI value={variationValue} />
          </Typography.Body>
        </Space>
      </Space>
    );
  }
};

export const KPIValue = ({
  value,
  fullValue,
  prefix = '',
  suffix = '',
  size = 'small',
  type = 'primary',
  variationValue = null,
  isVariation = false,
}) => {
  const [hovered, setHovered] = useState(false);

  if (size === 'large') {
    return (
      <Typography.Headline
        style={{ cursor: 'default' }}
        level={3}
        type={type}
        title={fullValue ? `${prefix}${fullValue}` : ''}
      >
        {`${prefix} ${value} ${suffix}`}
      </Typography.Headline>
    );
  }
  if (size === 'medium') {
    return (
      <Typography.Headline
        style={{ cursor: 'default' }}
        level={4}
        type={type}
        title={fullValue ? `${prefix}${fullValue}` : ''}
      >
        {`${prefix} ${value} ${suffix}`}
      </Typography.Headline>
    );
  }
  if (size === 'extra-small') {
    return (
      <Popover
        content={renderContent(fullValue, prefix, variationValue)}
        trigger="hover"
        visible={hovered && (fullValue || variationValue)}
        onVisibleChange={visible => setHovered(visible)}
      >
        <Typography.Body className="kpi-extra-small" level={3} type={type}>
          {`${prefix} ${value} ${suffix}`}
        </Typography.Body>
      </Popover>
    );
  }

  return (
    <Typography.Subtitle
      style={{ cursor: 'default' }}
      type={type}
      title={fullValue ? `${prefix}${fullValue}` : ''}
    >
      {isVariation ? (
        <CellStyle.VariationKPI value={value} />
      ) : (
        `${prefix} ${value} ${suffix}`
      )}
    </Typography.Subtitle>
  );
};
