import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Button, Popconfirm } from 'antd';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Typography, DropdownMenu } from 'modules/core/components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';

const {
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__DELETE,
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__EDIT,
} = ROLES;

const RuleTableActions = ({
  id,
  loading,
  onEdit,
  popconfirmTitle,
  onDelete,
  deleteConfirmationVisible = false,
  onDeleteConfirm,
  disabledDetail = false,
  onClickDetail,
  isMoreActionsVisible = true,
  moreActions = [],
}) => {
  let { t } = useTranslation();

  return (
    <Space direction="horizontal">
      <Popconfirm
        placement="bottomRight"
        title={t(popconfirmTitle)}
        okText={t('ACTION_DELETE')}
        okButtonProps={{ loading: loading }}
        onConfirm={() => onDeleteConfirm(id)}
        cancelText={t('ACTION_CANCEL')}
        onCancel={() => onDelete(null)}
        visible={deleteConfirmationVisible}
      >
        <Button
          type="text"
          shape="circle"
          icon={<Typography.Icon icon={EyeOutlined} />}
          title={t('ACTION_OPEN_DETAIL')}
          onClick={onClickDetail}
          disabled={disabledDetail}
        />
        {isMoreActionsVisible && (
          <DropdownMenu
            title={t('ACTION_MORE')}
            menu={[
              ...[
                {
                  title: t('ACTION_EDIT'),
                  icon: <EditOutlined />,
                  onClick: () => onEdit(id),
                  disabled: !isUserAllowed(
                    SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__EDIT
                  ),
                },
                {
                  title: t('ACTION_DELETE'),
                  icon: <DeleteOutlined />,
                  onClick: () => onDelete(id),
                  disabled: !isUserAllowed(
                    SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__DELETE
                  ),
                },
              ],
              ...moreActions,
            ]}
          />
        )}
      </Popconfirm>
    </Space>
  );
};

export default RuleTableActions;
