import {
  Evolution,
  Comparison,
  Report,
  Cascader,
  Multiple,
  Simple,
} from './variants';

const SelectType = {
  Evolution,
  Comparison,
  Report,
  Cascader,
  Multiple,
  Simple,
};

export default SelectType;
