import React, { useState } from 'react';
import {
  Col,
  Row,
  Table,
  Button,
  Input,
  Space,
  Spin,
  Popconfirm,
  message,
  notification,
} from 'antd';
import {
  ButtonActionBar,
  CreateReportModal,
  Typography,
  EmptyScreen,
  DropdownMenu,
  InputCell,
  DeleteAndUpdate,
} from 'modules/core/components';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { REPORT, ROLES } from 'modules/core/constants';
import { isUserAllowed } from 'modules/core/utils';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import reports from 'modules/reports';
import _ from 'lodash';

const { Search } = Input;
const {
  SETTINGS__REPORTS__REPORTSPNL__CREATE,
  SETTINGS__REPORTS__REPORTSPNL__EDIT,
  SETTINGS__REPORTS__REPORTSPNL__DELETE,
} = ROLES; //TODO: PROVISORIO ROLES DE REPORTES GENERAL, VER CUANDO ESTE LO FINANCIERO

const ReportList = ({
  fetchReportList,
  deleteReport,
  editReport,
  fnlReportsData,
  reportsCount,
  isScreenLoading,
}) => {
  const [editRow, setEditRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const [deleteReportId, setDeleteReportId] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  let history = useHistory();
  const { t } = useTranslation();

  const onFetchReportList = () => fetchReportList({ flat: 'True' });

  const onCellEdit = (name, value) =>
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });

  const onCancel = () => setEditRow(null);

  const onConfirm = () => {
    setEditRow(null);
    setDataCellEdit({});
  };

  const onEditReportName = () => {
    if (!_.isEmpty(dataCellEdit)) {
      setIsEditing(true);
      editReport(editRow, dataCellEdit)
        .then(() => {
          onFetchReportList().then(() => {
            setIsEditing(false);
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
            setEditRow(null);
            setDataCellEdit({});
          });
        })
        .catch(() => {
          setIsEditing(false);
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          });
          setEditRow(null);
          setDataCellEdit({});
        });
    } else {
      setEditRow(null);
      setDataCellEdit({});
    }
  };

  const onDeleteReport = () => {
    setIsDeletingReport(true);
    deleteReport(deleteReportId)
      .then(() =>
        onFetchReportList().then(() => {
          message.success(t('FEEDBACK_DELETE_DATA_SUCCESS'));
          setDeleteReportId(null);
          setIsDeletingReport(false);
        })
      )
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_FAIL'),
          duration: 0,
        });
        setDeleteReportId(null);
        setIsDeletingReport(false);
      });
  };

  const functionSearch = (values, searchValue) => {
    const resultado = [];
    values.forEach(value => {
      if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
        resultado.push(value);
      } else {
        if (value.children) {
          const childrenAux = value.children.filter(childValue =>
            childValue.name.toLowerCase().includes(searchValue.toLowerCase())
          );
          if (childrenAux.length !== 0) {
            let valueAux = { ...value, children: childrenAux };
            resultado.push(valueAux);
          }
        }
      }
    });
    return resultado;
  };

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      render: (name, record) =>
        editRow && record.id === editRow ? (
          <InputCell
            initialValue={name}
            onCellUpdate={onCellEdit}
            name="name"
          />
        ) : (
          name
        ),
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id, record) => {
        return id !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_REPORT_DELETE_TITLE')}
            okText={t('ACTION_DELETE')}
            okButtonProps={{ loading: isDeletingReport }}
            onConfirm={() => onDeleteReport()}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteReportId(null)}
            visible={deleteReportId === id}
          >
            <Space size="middle">
              <Button
                type="text"
                shape="circle"
                icon={<Typography.Icon icon={EyeOutlined} />}
                title={t('ACTION_OPEN_DETAIL')}
                onClick={() =>
                  history.push(
                    `/configuracion/misdatos/reportes/financieros/reporte/${id}`
                  )
                }
              />
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => setEditRow(id),
                    disabled: !isUserAllowed(
                      SETTINGS__REPORTS__REPORTSPNL__EDIT
                    ),
                  },
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteReportId(id),
                    disabled: !isUserAllowed(
                      SETTINGS__REPORTS__REPORTSPNL__DELETE
                    ),
                  },
                ]}
              />
            </Space>
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={onEditReportName}
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        );
      },
    },
  ];

  const renderCreateReportModal = () => (
    <CreateReportModal
      rolesAvailables={[SETTINGS__REPORTS__REPORTSPNL__CREATE]}
      onFetchReportList={() => fetchReportList({ flat: 'True' })}
      reportCreationType={REPORT.REPORTS_KEYS.FNL_KEY}
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="configuration-reports-list-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        {renderCreateReportModal()}
      </Space>
    </ButtonActionBar>
  );

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_REPORTS_EMPTY_STATE_TITLE')}
      footer={renderCreateReportModal()}
    />
  );

  return (
    <>
      <Spin spinning={isScreenLoading} />
      <Row gutter={[24, 24]}>
        {reportsCount <= 0 && !isScreenLoading && renderEmptyScreen()}
        {reportsCount > 0 && !isScreenLoading && (
          <>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>
              <Table
                bordered
                size="small"
                rowKey="id"
                dataSource={
                  searchValue === null
                    ? fnlReportsData
                    : functionSearch(fnlReportsData, searchValue)
                }
                columns={columns}
                loading={isScreenLoading || isEditing}
                pagination={{
                  pageSize: 10,
                  size: 'small',
                  hideOnSinglePage: true,
                }}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  reportList: reports.selectors.getReportList(state),
});

const mapDispatchToProps = {
  fetchReportList: reports.actions.fetchReportList,
  editReport: reports.actions.editReport,
  deleteReport: reports.actions.deleteReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportList);
