import _ from 'lodash';

export const onSaveIdsSelected = (setIds, key, idsSelected) => {
  setIds(prevState => {
    return { ...prevState, [key]: idsSelected };
  });
};

export const getIdsToDisabled = (limitNumber, values, idsSelected) => {
  const idsValues = values.map(val => val.id);
  if (idsSelected.length === limitNumber) {
    return _.difference(idsValues, idsSelected).map(id => id.toString());
  }
  return [];
};
