import * as types from './constants';
import { PROJECTION } from 'modules/core/constants';
import { saveAs } from 'file-saver';
import queryString from 'query-string';
import i18n from 'i18next';
import { GA } from 'modules/core/utils';

// List KPIs Endpoints
// `/planning/periods/${id}/card-kpis/`
// `/planning/periods/${id}/controlling-card-kpis/?concept=${concept}`
// `/planning/projections/${id}/card-kpis/?concept=${concept}`
// `/planning/projections/${id}/consolidation-card-kpis/?concept=${concept}`,

export const fetchPeriods = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_PERIODS,
    payload: {
      request: {
        url: `/planning/periods/`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchPeriodDetail = id => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_PERIOD_DETAIL,
    payload: {
      request: {
        url: `/planning/periods/${id}/`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const createPeriod = data => async dispatch => {
  const response = await dispatch({
    type: types.CREATE_PERIOD,
    payload: {
      request: {
        url: `/planning/periods/`,
        method: 'POST',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return dispatch(fetchPeriods());
};

export const editPeriod = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_PERIOD,
    payload: {
      request: {
        url: `/planning/periods/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const deletePeriod =
  (periodId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DELETE_PERIOD,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/`,
          method: 'DELETE',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchConceptsList = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_CONCEPTS_LIST,
    payload: {
      request: {
        url: `/planning/concepts/`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchConceptsDetail =
  (conceptId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CONCEPTS_DETAIL,
      payload: {
        request: {
          url: `/planning/concepts/${conceptId}`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchConceptTemplateXLS =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CONCEPT_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/planning/concepts/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, i18n.t('CONFIG_CONCEPTS_TEMPLATE'));
    return Promise.resolve();
  };

export const fetchConceptConsolidatedXLS =
  (conceptType, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CONCEPT_CONSOLIDATED_XLS,
      payload: {
        request: {
          url: `/planning/concepts/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(
      response.payload.data,
      i18n.t('CONFIG_CONCEPTS_FILE_NAME', {
        conceptType,
        conceptName: i18n.t(params.concept_name),
      })
    );
    return Promise.resolve();
  };

/////////////////////////////////////

export const fetchProjections =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PROJECTIONS,
      payload: {
        request: {
          url: `/planning/projections/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response.payload.data.results;
  };

export const fetchProjectionDetail = id => async dispatch => {
  return dispatch({
    type: types.FETCH_PROJECTION_DETAIL,
    payload: {
      request: {
        url: `/planning/projections/${id}/`,
        method: 'GET',
      },
    },
  });
};

export const updateTransaction =
  (data = {}, params = {}) =>
  async dispatch => {
    const { transactionId, amount } = data;
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION,
      payload: {
        request: {
          url: `/planning/transactions/${transactionId}/`,
          method: 'PATCH',
          data: { amount },
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const updateTransactionForward =
  (data = {}) =>
  async dispatch => {
    const { startDate, endDate, rowId, ...restData } = data;
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_FORWARD,
      payload: {
        request: {
          url: `/planning/rows/${rowId}/`,
          method: 'PATCH',
          data: {
            start_date: startDate,
            end_date: endDate,
            ...restData,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const updateTransactionAll =
  ({ projectionId, value }, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_ALL,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/rows/${projectionId}/`,
          method: 'PATCH',
          params: {
            ...params,
            percentage_to_apply: value,
            filter: 'conventional',
          },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

//++ USERS TRACKS
export const fetchUserTracks =
  (projectionId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_USER_TRACKS,
      payload: {
        request: {
          url: `/planning/tracks/`,
          method: 'GET',
          params: {
            ...params,
            projection: projectionId,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

//++ FILE TRACKS
export const fetchFileTracks = params => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_FILE_TRACKS,
    payload: {
      request: {
        url: '/auth/users/tracker/get_activities/',
        method: 'GET',
        params,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

// ++ BUDGET TRANSACTIONS ++
export const fetchProjectionColumns =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_COLUMNS,
      payload: {
        request: {
          url: `/planning/projections/${id}/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchProjectionRows = (id, params) => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_PROJECTION_ROWS,
    payload: {
      request: {
        url: `/planning/projections/${id}/rows/`,
        method: 'GET',
        params: { page_size: 12, page: 1, ...params },
        paramsSerializer: params => queryString.stringify(params),
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchProjectionAmountsRows = (id, params) => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_PROJECTION_AMOUNTS_ROWS,
    payload: {
      request: {
        url: `/planning/projections/${id}/rows/`,
        method: 'GET',
        params: {
          page_size: 12,
          page: 1,
          filter: PROJECTION.LOAD_TYPES.CONVENTIONAL.id,
          ...params,
        },
        paramsSerializer: params => queryString.stringify(params),
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

// ++ XLS ++
export const downloadTemplateXLS =
  (
    projectionId,
    filename = i18n.t('PLANNING_TEMPLATE_NAME_DEFAULT'),
    params = {}
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/template/`,
          method: 'GET',
          params,
          responseType: 'blob',
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

export const uploadProjectionXLS =
  (projectionId, data, params = {}) =>
  async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('input_file', file);
    });
    const response = await dispatch({
      type: types.UPLOAD_PROJEXTION_XLS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const uploadConceptValuesXLS =
  (data, params = {}) =>
  async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('file', file);
    });
    const response = await dispatch({
      type: types.UPLOAD_CONCEPT_VALUES_XLS,
      payload: {
        request: {
          url: `/planning/concepts/concept-configs/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data[0]);
    }
    return response;
  };

export const downloadProjectionXLS =
  (
    projectionId,
    filename = i18n.t('PLANNING_EVOLTION_FILE_NAME'),
    params = {}
  ) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DOWNLOAD_PROJECTION_XLS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/download-consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params: { ...params },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    saveAs(response.payload.data, `${filename}.xlsx`);
    return Promise.resolve();
  };

// PROJECTION DETAIL KPIS
export const fetchProjectionKpis =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_KPIS,
      payload: {
        request: {
          url: `/planning/projections/${id}/kpis/`,
          method: 'GET',
          params: { ...params },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };
export const fetchProjectionAmountsKpis =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_AMOUNTS_KPIS,
      payload: {
        request: {
          url: `/planning/projections/${id}/kpis/`,
          method: 'GET',
          params: {
            table_type: PROJECTION.LOAD_TYPES.CONVENTIONAL.id,
            ...params,
          },
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

// ++APPROVE BUTTON ON PROJECTION DETAIL++
export const approveProjection = id => async dispatch => {
  const response = await dispatch({
    type: types.APPROVE_PROJECTION,
    payload: {
      request: {
        url: `/planning/projections/${id}/approve/`,
        method: 'PATCH',
        data: {
          status: PROJECTION.STATUS.APPROVED,
        },
      },
    },
  });
  if (response.error) {
    //GA.event(GA.ACTIONS.APPROVE_DATA_BUDGET_ERROR);
    return Promise.reject(response.error);
  }
  GA.event({ ...GA.ACTIONS.APPROVE_DATA_BUDGET, label: id });
  return Promise.resolve();
};

// ++FILTERS++
export const fetchProjectionFilters =
  (idConcept = null, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PROJECTION_FILTERS,
      payload: {
        request: {
          url: idConcept
            ? `/planning/concepts/${idConcept}/filters/`
            : `/planning/concepts/filters/`,
          method: 'GET',
          params,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchPublishedAccounts =
  (data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_PUBLISHED_ACCOUNTS,
      payload: {
        request: {
          url: '/planning/rows/dimensions/',
          method: 'POST',
          data: { ...data, desired_dimension_id: 1 },
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const cleanPublishedDates = () => dispatch =>
  dispatch({
    type: types.CLEAN_PUBLISHED_DATES,
  });

export const applyVariables = (budgetId, varList) => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_PUBLISHED_ACCOUNTS,
    payload: {
      request: {
        url: '/planning/variables/apply/',
        method: 'POST',
        data: {
          projection_id: budgetId,
          variable_ids: varList,
        },
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response.payload.data;
};
// ++ Upload limit date  ++
export const setUploadLimitDate =
  (data, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.SET_UPLOAD_LIMIT_DATE,
      payload: {
        request: {
          url: '/planning/upload-limit-date/',
          method: 'POST',
          data,
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response.payload.data;
  };

export const updateUploadLimitDate =
  (id, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.SET_UPLOAD_LIMIT_DATE,
      payload: {
        request: {
          url: `/planning/upload-limit-date/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response.payload.data;
  };

export const getUploadLimitDate =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_UPLOAD_LIMIT_DATE,
      payload: {
        request: {
          url: '/planning/upload-limit-date/',
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response.payload.data.results;
  };
// ++ CREAR PPTO ++
export const createProjection =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.EDIT_BUDGET_NAME,
      payload: {
        request: {
          url: `/planning/projections/`,
          method: 'POST',
          data: data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

// ++ EDITAR NOMBRE PPTO ++
export const editBudgetName = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.EDIT_BUDGET_NAME,
    payload: {
      request: {
        url: `/planning/projections/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

//++ ELIMINAR PPTO ++

export const deleteBudget = projectionId => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_BUDGET,
    payload: {
      request: {
        url: `/planning/projections/${projectionId}/`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error.response.data);
  }
  return response;
};

//++ DUPLICAR PPTO ++

export const duplicateBudget =
  (projectionId, data, params) => async dispatch => {
    const response = await dispatch({
      type: types.DUPLICATE_BUDGET,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/duplicate/`,
          method: 'POST',
          data: data,
          params: params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error.response.data);
    }
    return response;
  };

// ++ CONSOLIDADO ++

export const fetchConsolidationList = params => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_CONSOLIDATION_LIST,
    payload: {
      request: {
        url: `/planning/projections/consolidations/`,
        method: 'GET',
        params,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const createConsolidation =
  (data = {}, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.CREATE_CONSOLIDATION,
      payload: {
        request: {
          url: '/planning/projections/consolidate/',
          method: 'POST',
          data: data,
          params: params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchConsolidationDetailKPI = id => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_CONSOLIDATION_DETAIL_KPI,
    payload: {
      request: {
        url: `/planning/projections/${id}/consolidation-kpis/`,
        method: 'GET',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const changeBudget = (id, data) => async dispatch => {
  const response = await dispatch({
    type: types.CHANGE_BUDGET,
    payload: {
      request: {
        url: `/planning/projections/${id}/set-budget/`,
        method: 'POST',
        data: data,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

//eliminar linea de ppto
export const deleteBudgetRow = id => async dispatch => {
  const response = await dispatch({
    type: types.DELETE_BUDGET_ROW,
    payload: {
      request: {
        url: `/planning/rows/${id}`,
        method: 'DELETE',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error.response.data);
  }
  return response;
};

//Eliminar toda la data de ppto
export const deleteAllProjectionRows =
  (projectionId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DELETE_ALL_PROJECTION_ROWS,
      payload: {
        request: {
          url: `/planning/projections/${projectionId}/delete-all-rows/`,
          method: 'DELETE',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchFormulasAmountList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FORMULAS_AMOUNT_LIST,
      payload: {
        request: {
          url: `/planning/formulas/count/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchFactorRows =
  (params = {}) =>
  async dispatch => {
    const { factorId, projectionId, ...restParams } = params;
    const response = await dispatch({
      type: types.FETCH_FACTOR_ROWS,
      payload: {
        request: {
          url: `/planning/factor/${factorId}/projection/${projectionId}/rows/`,
          method: 'GET',
          params: restParams,
          paramsSerializer: params => queryString.stringify(params),
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const deleteAllFactorRows =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DELETE_ALL_FACTOR_ROWS,
      payload: {
        request: {
          url: `/planning/factores/${id}/delete-all-rows/`,
          method: 'DELETE',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const deleteFactorRow =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.DELETE_FACTOR_ROW,
      payload: {
        request: {
          url: `/planning/factores/${id}/delete-row/`,
          method: 'DELETE',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const replicateFactor =
  (data = {}) =>
  async dispatch => {
    const { factorId, projectionId, destinationFactor } = data;
    const response = await dispatch({
      type: types.REPLICATE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/${factorId}/retort/`,
          method: 'POST',
          data: {},
          params: {
            projection_id: projectionId,
            factor_to_replicate_id: destinationFactor,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchFactorColumns =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_FACTOR_COLUMNS,
      payload: {
        request: {
          url: `/planning/formulas/${id}/columns/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const updateTransactionFactor =
  (data = {}) =>
  async dispatch => {
    const { transactionId, amount } = data;
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_FACTOR,
      payload: {
        request: {
          url: `/planning/relacion-factores-transaccion/${transactionId}/`,
          method: 'PATCH',
          data: { factor_value: amount },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const unApplyFormula =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UNAPPLY_FORMULA,
      payload: {
        request: {
          url: `/planning/formulas/${id}/un-apply/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const applyFormula =
  (id, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.APPLY_FORMULA,
      payload: {
        request: {
          url: `/planning/formulas/${id}/apply/`,
          method: 'GET',
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchDimensionsValuesList = id => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_DIMENSIONS_VALUES_LIST,
    payload: {
      request: {
        url: `/planning/periods/${id}/dimension_values_list/`,
        method: 'GET',
      },
    },
  });

  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

// NOTA: Fijar consolidado por defecto en ppto
export const setDefaultBudget = consoId => async dispatch => {
  const response = await dispatch({
    type: types.SET_DEFAULT_BUDGET,
    payload: {
      request: {
        url: `/planning/projections/${consoId}/set-predefined/`,
        method: 'POST',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const updateConsolidated = consoId => async dispatch => {
  const response = await dispatch({
    type: types.UPDATE_CONSOLIDATED,
    payload: {
      request: {
        url: `/planning/projections/${consoId}/update-consolidated/`,
        method: 'POST',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const publishConsolidated = id => async dispatch => {
  const response = await dispatch({
    type: types.PUBLISH_CONSOLIDATED,
    payload: {
      request: {
        url: `/planning/projections/${id}/publish/`,
        method: 'PATCH',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

// ++ COMMENTS ++
export const fetchTransactionComments = transactionId => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_TRANSACTION_COMMENTS,
    payload: {
      request: {
        url: `/planning/comments/get_comments/?transaction_id=${transactionId}`,
        method: 'GET',
      },
    },
  });

  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const syncProjectionComment =
  (data = {}) =>
  dispatch => {
    const { commentId = null } = data;

    // Reviso que venga la prop newComment, sino asumo que esta borrando comentario.
    return dispatch({
      type:
        commentId !== null
          ? types.SYNC_CREATE_PROJECTION_COMMENT
          : types.SYNC_DELETE_PROJECTION_COMMENT,
      payload: { data },
    });
  };

export const createTransactionComment =
  (data = {}) =>
  async dispatch => {
    const { transactionId, text, factorId } = data;
    const response = await dispatch({
      type: types.CREATE_TRANSACTION_COMMENT,
      payload: {
        request: {
          url: `/planning/comments/add_comment/`,
          method: 'POST',
          data: {
            transaction_id: transactionId,
            text,
            ...(factorId && { factor_id: factorId }),
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const deleteTransactionComment =
  (data = {}) =>
  async dispatch => {
    const { commentId } = data;
    const response = await dispatch({
      type: types.DELETE_TRANSACTION_COMMENT,
      payload: {
        request: {
          url: `/planning/comments/delete_comment/?comment_id=${commentId}`,
          method: 'DELETE',
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };
// ++ END COMMENTS ++

//multimoneda

export const fetchMonthsList = periodId => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_MONTHS_LIST,
    payload: {
      request: {
        url: `/planning/periods/${periodId}/dates/`,
        method: 'GET',
      },
    },
  });

  if (response.error) {
    return Promise.reject(response.error);
  }
  return response;
};

export const fetchCurrenciesTracks =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_CURRENCIES_TRACKS,
      payload: {
        request: {
          url: `/planning/exchange-rate-tracking/`,
          method: 'GET',
          params,
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchExchangeRateList =
  (params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_EXCHANGE_RATE_LIST,
      payload: {
        request: {
          url: `/planning/exchange-rate/`,
          method: 'GET',
          params,
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const saveExchangeRate =
  (id, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.SAVE_EXCHANGE_RATE,
      payload: {
        request: {
          url: `/planning/exchange-rate/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const setExchangeRateValues =
  (data = {}) =>
  dispatch =>
    dispatch({
      type: types.SET_EXCHANGE_RATE_VALUES,
      payload: data,
    });

//INTEGRATIONS
export const integrationFilesUpload =
  (data, presupuesto_ventas_id, presupuesto_gastos_id) => async dispatch => {
    const formData = new FormData();
    data.forEach(file => {
      formData.append('files_field', file['originFileObj']);
    });
    presupuesto_ventas_id.forEach(v => {
      formData.append('presupuesto_ventas_id', v);
    });
    presupuesto_gastos_id.forEach(g => {
      formData.append('presupuesto_gastos_id', g);
    });
    const response = await dispatch({
      type: types.INTEGRATIONS_UPLOAD_FILE,
      payload: {
        request: {
          url: `planning/projections/upload_files/`,
          method: 'POST',
          data: formData,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const fetchOriginRows =
  (rowId, params = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.FETCH_ORIGIN_ROWS,
      payload: {
        request: {
          url: `/planning/rows/${rowId}/get_origin_rows/`,
          method: 'GET',
          params,
          data: rowId,
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const editTransactionOriginCurrency =
  (data = {}, params = {}) =>
  async dispatch => {
    const { transactionId, amount } = data;
    const response = await dispatch({
      type: types.EDIT_TRANSACTION_ORIGIN_CURRENCY,
      payload: {
        request: {
          url: `/planning/origin-transactions/${transactionId}/`,
          method: 'PATCH',
          data: { amount },
          params,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const createTransactionOriginCurrency =
  (data = {}) =>
  async dispatch => {
    const { amount, date, origin_row } = data;
    const response = await dispatch({
      type: types.CREATE_TRANSACTION_ORIGIN_CURRENCY,
      payload: {
        request: {
          url: `/planning/origin-transactions/`,
          method: 'POST',
          data: {
            amount,
            date,
            origin_row,
          },
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  };

export const updateTransactionForwardOrigin =
  (data = {}) =>
  async dispatch => {
    const { startDate, endDate, rowId, amount } = data;
    const response = await dispatch({
      type: types.UPDARE_TRANSACTION_FORWARD_ORIGIN,
      payload: {
        request: {
          url: `/planning/origin-rows/${rowId}/auto_fill_origin_transactions/`,
          method: 'PATCH',
          data: {
            start_date: startDate,
            end_date: endDate,
            amount,
          },
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const fetchDashboardUrl = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_DASHBOARD_URL,
    payload: {
      request: {
        url: `/planning/quicksight/`,
        method: 'GET',
      },
    },
  });

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

export const fetchDashboardDatasources = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_DASHBOARD_DATASOURCES,
    payload: {
      request: {
        url: `/planning/update_s3_datasources/`,
        method: 'GET',
      },
    },
  });

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

export const fetchPXQ = () => async dispatch => {
  const response = await dispatch({
    type: types.FETCH_PXQ,
    payload: {
      request: {
        url: `/planning/PxQ/`,
        method: 'GET',
      },
    },
  });

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

export const createPXQ = () => async dispatch => {
  const response = await dispatch({
    type: types.CREATE_PXQ,
    payload: {
      request: {
        url: `/planning/PxQ/`,
        method: 'POST',
      },
    },
  });

  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

export const enablePXQ =
  (formulaId, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.ENABLE_PXQ,
      payload: {
        request: {
          url: `/planning/PxQ/${formulaId}/`,
          method: 'PATCH',
          data,
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const updateCellPXQ =
  (formulaId, params = {}, data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.UPDATE_CELL_PXQ,
      payload: {
        request: {
          url: `/planning/PxQ/${formulaId}/`,
          method: 'PUT',
          params,
          data,
        },
      },
    });

    if (response.error) {
      return Promise.reject(response.error);
    }

    return response;
  };

export const setDataUpdateCellPXQ = data => dispatch =>
  dispatch({
    type: types.SET_DATA_UPDATE_CELL_PXQ,
    payload: data,
  });

export const updateDataOther = () => async dispatch => {
  const response = await dispatch({
    type: types.UPDATE_DATA_OTHER,
    payload: {
      request: {
        url: `/planning/acurio/update_data/`,
        method: 'POST',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

export const updateDataSICA = () => async dispatch => {
  const response = await dispatch({
    type: types.UPDATE_DATA_SICA,
    payload: {
      request: {
        url: `/planning/sica/update_data/`,
        method: 'POST',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

export const updateDataZarcam = () => async dispatch => {
  const response = await dispatch({
    type: types.UPDATE_DATA_ZARCAM,
    payload: {
      request: {
        url: `/planning/zarcam/update_data/`,
        method: 'POST',
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};

export const printReportsSICA =
  (
    fileList,
    option,
    fileName = i18n.t('REPORT_QUICKSIGHT_PRINT_REPORT_FILE_NAME')
  ) =>
  async dispatch => {
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('option', option);
      formData.append('file', file);
    });
    const response = await dispatch({
      type: types.PRINT_REPORTS_SICA,
      payload: {
        request: {
          url: `/planning/sica/print_reports/`,
          method: 'POST',
          responseType: 'blob',
          data: formData,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }

    saveAs(response.payload.data, fileName ? fileName : `${fileName}.xlsx`);
    return Promise.resolve();
  };

export const uploadDataSICA = (fileList, option) => async dispatch => {
  const formData = new FormData();
  fileList.forEach(file => {
    formData.append('option', option);
    formData.append('file', file);
  });
  const response = await dispatch({
    type: types.UPLOAD_DATA_SICA,
    payload: {
      request: {
        url: `/planning/sica/upload_to_s3/`,
        method: 'POST',
        data: formData,
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }

  return response;
};
