import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Input, Space, Spin } from 'antd';
import { ButtonActionBar, EmptyScreen } from 'modules/core/components';
import {
  RoleOperationsDrawer,
  ActionRoleModal,
  RolesTable,
} from './components';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration';

const { SETTINGS__SECURITY__ROLES__CREATE } = ROLES;
const { Search } = Input;

const KEY_CREATE = 'create';
const KEY_DUPLICATE = 'duplicate';

// TODO: Implementar paginación cuando sea necesario (falta backend)
const RolesList = ({ rolesList: { count, data }, fetchRolesList }) => {
  const [showCreatModal, setShowCreatModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [duplicateRole, setDuplicateRole] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [showRoleOperationsDrawer, setShowRoleOperationsDrawer] = useState({
    roleId: null,
    name: '',
    visible: false,
  });
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setIsTableLoading(true);
    fetchRolesList({ search: searchValue }).then(() => {
      setIsTableLoading(false);
      setIsScreenLoading(false);
    });
  }, [fetchRolesList, searchValue]);

  const reFetchTable = () => {
    setIsTableLoading(true);
    fetchRolesList({ search: searchValue }).then(() =>
      setIsTableLoading(false)
    );
  };

  const renderCreateButton = () => (
    <Button
      type="primary"
      onClick={() => {
        setActionType(KEY_CREATE);
        setShowCreatModal(true);
      }}
      disabled={!isUserAllowed(SETTINGS__SECURITY__ROLES__CREATE)}
    >
      {t('CONFIG_ROLES_CREATE_ACTION')}
    </Button>
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_ROLES')}
      description={t('CONFIG_ROLES_EMPTY_STATE_DESCRIPTION')}
      footer={renderCreateButton()}
    />
  );

  const renderMainActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        {renderCreateButton()}
      </Space>
    </ButtonActionBar>
  );

  return (
    <>
      <Spin spinning={isScreenLoading} />
      <Row gutter={[24, 24]}>
        {count <= 0 && !searchValue && !isTableLoading && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {(count > 0 || (searchValue && count === 0)) && (
          <>
            <Col span={24}>{renderMainActions()}</Col>
            <Col span={24}>
              <RolesTable
                dataSource={data}
                reFetchTable={reFetchTable}
                onDuplicateRole={data => {
                  setDuplicateRole(data);
                  setActionType(KEY_DUPLICATE);
                  setShowCreatModal(true);
                }}
                setShowRoleOperationsDrawer={setShowRoleOperationsDrawer}
                tableLoading={isTableLoading}
                setIsTableLoading={setIsTableLoading}
                count={count}
              />
            </Col>
          </>
        )}
        <ActionRoleModal
          visible={showCreatModal}
          actionType={actionType}
          initialValues={duplicateRole}
          onCancel={() => {
            setShowCreatModal(false);
            setDuplicateRole({});
          }}
          onSuccess={reFetchTable}
        />
        <RoleOperationsDrawer
          roleId={showRoleOperationsDrawer.roleId}
          visible={showRoleOperationsDrawer.visible}
          roleName={showRoleOperationsDrawer.name}
          onSuccess={reFetchTable}
          onClose={() => {
            document.body.style.removeProperty('overflow');
            setShowRoleOperationsDrawer({
              roleId: null,
              name: '',
              visible: false,
            });
          }}
        />
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  rolesList: configuration.selectors.getRolesList(state),
});

const mapDispatchToProps = {
  fetchRolesList: configuration.actions.fetchRolesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);
