import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { List, Button, Row, Col, Tooltip, Input, Space } from 'antd';
import { isUserAllowed, renderPrefix } from 'modules/core/utils';
import {
  ListCard,
  ButtonActionBar,
  CreateReportModal,
  DropdownMenu,
} from 'modules/core/components';
import { DuplicateReportModal } from './components';
import emptyImage from 'assets/images/compositions/empty-card.svg';
import { useTranslation } from 'react-i18next';
import { RightCircleOutlined, EditOutlined } from '@ant-design/icons';
import { ROLES, REPORT, PROJECTION } from 'modules/core/constants';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import reports from 'modules/reports';

const { Search } = Input;

const { ANALYSIS__REPORTSPNL__CREATE } = ROLES;

// TODO: Pantalla vacía
const ListPNL = ({ reportList: { reportData }, fetchReportList }) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [reportId, setReportId] = useState(null);
  const functionalCurrencyData = useFunctionalCurrency();
  let history = useHistory();
  let { periodId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    fetchReportList({ period_id: periodId }).then(() =>
      setIsDataLoading(false)
    );
  }, [fetchReportList, periodId]);

  const canUserCreate = isUserAllowed(ANALYSIS__REPORTSPNL__CREATE);
  const dataSource = reportData.filter(
    report => report.type === REPORT.REPORTS_KEYS.PNL_KEY
  );

  //recibe los reportes y al filtrar por el valor agrega los kpis que coinciden al array 'Results'
  const functionSearch = (values, searchValue) => {
    const results = [];
    values.forEach(value => {
      if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
        results.push(value);
      }
    });
    return results;
  };

  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        <CreateReportModal
          rolesAvailables={[ANALYSIS__REPORTSPNL__CREATE]}
          onFetchReportList={() => fetchReportList({ period_id: periodId })}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderCardMoreActions = reportId => (
    <DropdownMenu
      title={t('ACTION_MORE')}
      menu={[
        {
          title: t('REPORT_DUPLICATE_DROPDOWN_ITEM'),
          icon: <EditOutlined />,
          onClick: () => {
            setShowDuplicateModal(true);
            setReportId(reportId);
          },
        },
      ]}
    />
  );

  const renderReportList = () => (
    <List
      style={{ paddingLeft: 6 }}
      loading={isDataLoading}
      grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
      dataSource={
        searchValue === null
          ? dataSource
          : functionSearch(dataSource, searchValue)
      }
      renderItem={pnl => {
        const { id, name, has_config, has_data, has_exr } = pnl;
        const reportFunctionalCurrencyData = has_exr
          ? PROJECTION.CURRENCIES_OPTIONS.find(
              currency => currency.code === has_exr.to_currency_code
            )
          : functionalCurrencyData;
        return (
          <List.Item>
            {!isDataLoading && (
              <ListCard
                title={name}
                type={REPORT.REPORTS_KEYS.PNL_KEY}
                projectionId={id}
                periodId={periodId}
                image={emptyImage}
                description={t('REPORT_CARD_TITLE_NO_DATA')}
                hasData={has_data}
                renderPrefix={defaultPrefix =>
                  renderPrefix(reportFunctionalCurrencyData, defaultPrefix)
                }
                actions={[
                  <ButtonActionBar position="start">
                    <Tooltip
                      title={
                        !has_config && !canUserCreate
                          ? t('ALERT_NOT_ALLOWED')
                          : ''
                      }
                    >
                      <Button
                        type="link"
                        disabled={
                          (!has_config && !canUserCreate) ||
                          (has_config && !has_data)
                        }
                        onClick={() =>
                          history.push(
                            has_config
                              ? `/analisis/${periodId}/reportesplika/economicos/reportes/${id}`
                              : `/configuracion/misdatos/reportes/economicos/reporte/${id}`
                          )
                        }
                      >
                        {has_config
                          ? t('ACTION_ENTER')
                          : t('ACTION_CONFIGURATE')}
                        <RightCircleOutlined />
                      </Button>
                    </Tooltip>
                  </ButtonActionBar>,
                ]}
                extra={has_config && has_data && renderCardMoreActions(id)}
                emptyState={{
                  description: t('REPORT_CARD_TITLE_NO_DATA'),
                  ...(!has_config && {
                    title: t('REPORT_TOOLTIP_CONFIGURATION_TITLE'),
                    description: t('REPORT_CARD_TITLE_NO_CONFIG'),
                  }),
                }}
              />
            )}
          </List.Item>
        );
      }}
    />
  );

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderTopActions()}</Col>
        <Col span={24}>{renderReportList()}</Col>
      </Row>
      <DuplicateReportModal
        periodId={periodId}
        showDuplicateModal={showDuplicateModal}
        setShowDuplicateModal={setShowDuplicateModal}
        reportId={reportId}
        onRefetchReportList={() => fetchReportList({ period_id: periodId })}
      />
    </>
  );
};

const mapStateToProps = state => ({
  reportList: reports.selectors.getReportList(state),
});

const mapDispatchToProps = {
  fetchReportList: reports.actions.fetchReportList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPNL);
