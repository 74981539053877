import React from 'react';
import { Card } from 'antd';

import './IntegrationCard.scss';

const IntegrationCard = ({
  extraCard = null,
  cardTitle = '',
  extraClassName = '',
  onClick,
  body,
  headStyle = null,
}) => {
  return (
    <Card
      className={`integration-card ${extraClassName}`}
      title={cardTitle && cardTitle}
      headStyle={headStyle && headStyle}
      extra={extraCard && extraCard}
      onClick={onClick}
    >
      {body}
    </Card>
  );
};

export default IntegrationCard;
