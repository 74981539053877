import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import {
  ButtonActionBar,
  Navigator,
  Typography,
} from 'modules/core/components';
import { INTEGRATIONS, CONCEPTS } from 'modules/core/constants';
import { SiderLayout } from 'modules/core/layouts';
import planning from 'modules/planning';
import { AssociateDimensionModal } from '../../../components';
import { ConceptsDetailTable } from './components';

const ConceptsDetail = ({
  conceptsDetail: { values, name },
  fetchConceptsDetail,
  fetchConceptConsolidatedXLS,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { t } = useTranslation();
  let { conceptId } = useParams();

  useEffect(() => {
    fetchConceptsDetail(conceptId).then(() => setIsScreenLoading(false));
  }, [fetchConceptsDetail, conceptId]);

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_CONCEPTS'),
          url: `/configuracion/misdatos/conceptos/?tab=${CONCEPTS.TYPE.ECONOMIC_KEY}`,
        },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/configuracion/misdatos/conceptos/?tab=${CONCEPTS.TYPE.ECONOMIC_KEY}`,
        },
        { name: name ? t(name) : '' },
      ]}
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <AssociateDimensionModal
        conceptId={conceptId}
        refreshData={refreshData}
        selectedDimensions={values.map(conf => conf.dimension_id)}
      />
    </ButtonActionBar>
  );

  const refreshData = () => {
    setIsTableLoading(true);
    fetchConceptsDetail(conceptId).then(() => setIsTableLoading(false));
  };

  const renderTableActions = () => (
    <ButtonActionBar>
      <Button
        type="text"
        shape="circle"
        title={t('ACTION_DOWNLOAD_TABLE')}
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={() =>
          fetchConceptConsolidatedXLS(t('LABEL_ECONOMIC').toLowerCase(), {
            concept_name: name,
          })
        }
      />
    </ButtonActionBar>
  );

  return (
    <SiderLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{!isScreenLoading && renderUserActions()}</Col>
        <Col span={24}>{!isScreenLoading && renderTableActions()}</Col>
        <Col span={24}>
          {!isScreenLoading && (
            <ConceptsDetailTable
              dataSource={values}
              tableLoading={isTableLoading}
              refreshData={refreshData}
            />
          )}
        </Col>
      </Row>
      <Spin spinning={isScreenLoading} />
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  conceptsDetail: planning.selectors.getConceptsDetail(state),
});

const mapDispatchToProps = {
  fetchConceptsDetail: planning.actions.fetchConceptsDetail,
  fetchConceptConsolidatedXLS: planning.actions.fetchConceptConsolidatedXLS,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConceptsDetail);
