import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'modules/core/customHooks';
import { Spin, Tabs, Space } from 'antd';
import { PeriodNavigator } from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { PROJECTION } from 'modules/core/constants';
import FileUploadDetails from './FileUploadDetails';
import CurrencyTrackingDetail from './CurrencyTrackingDetail';
import ActivityTrackingDetail from './ActivityTrackingDetail';
import planning from 'modules/planning';

import './ProjectionTrackingScreen.scss';

const { TabPane } = Tabs;

const ProjectionTrackingScreen = ({
  projectionDetail,
  fetchProjectionDetail,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const { t } = useTranslation();

  let { periodId, projectionId, conceptName } = useParams();
  let query = useQuery();

  const ACTIVITY = 'activity';
  const ACTIVITIES_DETAILS = 'activity-details';
  const ACTIVITY_CURRENCY = 'activity-currency';

  useEffect(() => {
    Promise.all([fetchProjectionDetail(projectionId)]).then(() =>
      setIsScreenLoading(false)
    );
  }, [fetchProjectionDetail, projectionId]);

  const renderHeader = () => {
    const { id, name, forecast } = projectionDetail;

    const customItem = () => {
      switch (projectionDetail.type) {
        case PROJECTION.BUDGET:
          return [
            { name: t('LABEL_PLANNING'), url: `/planificacion/${periodId}` },
            {
              name: name,
              url: `/planificacion/${periodId}/${conceptName}/${id}`,
            },
          ];
        case PROJECTION.REAL:
          if (projectionDetail.concept.type === 'economic') {
            return [
              {
                name: t('LABEL_CONTROL'),
                url: `/seguimiento/${periodId}`,
              },
              {
                name: t('LABEL_ECONOMIC'),
                url: `/seguimiento/${periodId}/economico`,
              },
              {
                name: name,
                url: `/seguimiento/${periodId}/economico/${conceptName}/${id}`,
              },
            ];
          } else {
            return [
              {
                name: t('LABEL_CONTROL'),
                url: `/seguimiento/${periodId}`,
              },
              {
                name: t('LABEL_FINANCIAL'),
                url: `/seguimiento/${periodId}/financiero`,
              },
              {
                name: name,
                url: `/seguimiento/${periodId}/financiero/${conceptName}/${id}`,
              },
            ];
          }
        case PROJECTION.FORECAST:
          return [
            { name: t('LABEL_FORECAST'), url: `/forecast/${periodId}` },
            {
              name: forecast.name,
              url: `/forecast/${periodId}/${forecast.id}`,
            },
            {
              name: name,
              url: `/forecast/${periodId}/${forecast.id}/${conceptName}/${id}`,
            },
          ];
        default:
          return [];
      }
    };

    return (
      <PeriodNavigator
        items={[...customItem(), ...[{ name: t('LABEL_ACTIVITIES') }]]}
      />
    );
  };

  const renderTrackingTabs = () => (
    <Tabs
      defaultActiveKey={query.get('tab') !== null ? query.get('tab') : ACTIVITY}
    >
      <TabPane tab={t('LABEL_ACTIVITY')} key={ACTIVITY}>
        <Space
          className="projection-tracking-screen-space"
          direction="vertical"
          size="large"
        >
          <ActivityTrackingDetail projectionId={projectionId} />
        </Space>
      </TabPane>
      <TabPane tab={t('LABEL_LOAD_DETAILS')} key={ACTIVITIES_DETAILS}>
        <Space
          className="projection-tracking-screen-space"
          direction="vertical"
          size="large"
        >
          <FileUploadDetails />
        </Space>
      </TabPane>
      <TabPane tab={t('LABEL_CURRENCY')} key={ACTIVITY_CURRENCY}>
        <Space
          className="projection-tracking-screen-space"
          direction="vertical"
          size="large"
        >
          <CurrencyTrackingDetail projectionDetail={projectionDetail} />
        </Space>
      </TabPane>
    </Tabs>
  );

  return (
    <SiderLayout className="projection-tracking-screen">
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          {renderTrackingTabs()}
        </>
      )}
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  projectionDetail: planning.selectors.getProjectionDetail(state),
});

const mapDispatchToProps = {
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectionTrackingScreen);
