import { Row, Radio, Col } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComparisionProjections, ComparisionMonth } from './components';

const OPTIONS_COMPARISON_TYPE = [
  {
    label: 'LABEL_PROJECTION',
    value: 'projection',
    key: 'projection',
  },
  {
    label: 'LABEL_MONTHLY',
    value: 'month',
    key: 'month',
  },
];

const ComparisonHome = ({
  comparisonSelections,
  setComparisonSelections,
  isDataLoading,
  reportFunctionalCurrencyData,
  periodDetail,
  selectedDates,
  filters,
  consolidationListBudget,
  consolidationListForecast,
  isChangingConso,
}) => {
  const [tableKey, setTableKey] = useState('projection');
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Radio.Group
          size="small"
          options={OPTIONS_COMPARISON_TYPE.map(type => {
            return { ...type, label: t(type.label) };
          })}
          onChange={val => setTableKey(val.target.value)}
          value={tableKey}
          optionType="button"
          buttonStyle="solid"
        />
      </Col>
      {tableKey === 'projection' && (
        <Col span={24}>
          <ComparisionProjections
            comparisonSelections={comparisonSelections}
            setComparisonSelections={setComparisonSelections}
            isDataLoading={isDataLoading}
            reportFunctionalCurrencyData={reportFunctionalCurrencyData}
            periodDetail={periodDetail}
            selectedDates={selectedDates}
            filters={filters}
            consolidationListBudget={consolidationListBudget}
            consolidationListForecast={consolidationListForecast}
            isChangingConso={isChangingConso}
          />
        </Col>
      )}
      {tableKey === 'month' && (
        <Col span={24}>
          <ComparisionMonth
            periodDetail={periodDetail}
            reportFunctionalCurrencyData={reportFunctionalCurrencyData}
            isDataLoading={isDataLoading}
          />
        </Col>
      )}
    </Row>
  );
};

export default ComparisonHome;
