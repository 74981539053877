import React, { useState } from 'react';
import { Table } from 'antd';
import { calculateScrollX, columnsHelper, isAmount } from 'modules/core/utils';
import {
  ROWS,
  PROJECTION,
  DIMENSIONS,
  ACCES_USERS,
} from 'modules/core/constants';
import { useCollapser } from 'modules/core/customHooks';
import './PlanningTable.scss';
import { connect } from 'react-redux';
import planning from 'modules/planning';
import login from 'modules/login';

const PlanningTable = ({
  loading,
  onChange,
  columns,
  columnsImporte,
  dataSource,
  pagination,
  editable,
  canDelete = false,
  onCalculateValue,
  onCellEdit,
  onCellEditForward,
  dataSelection: { selection, setSelection },
  tableSettings: { visibleData, format, showNegativeInRed },
  filters,
  filterOptions,
  onFilter,
  comments: { showRowsComment, onComment, onDeleteComment },
  fetchOriginRows,
  isPublishedOrElaboration,
  tableTypeKey,
  loggedUser: { email },
}) => {
  const [isCollapsed, toogleIsCollapsed, isCollapsing] = useCollapser();
  const [isExpandingRow, setIsExpandingRow] = useState(false);
  const [expandedRow, setExpandedRow] = useState([]);

  const has_restriction = [
    ...ACCES_USERS.PATCH_ACCES_FLAT_DOWNLOAD_BUTTON_USERS,
  ].some(el => email.includes(el));

  const renderTransactionColumn = column =>
    columnsHelper.renderTransactionPlanning({
      date: column.title,
      cellFormat: { format, showNegativeInRed },
      width: column.width,
      disabled: !editable,
      onCalculateValue,
      onCellEdit,
      onCellEditForward,
      comments: {
        onComment: onComment,
        onDeleteComment: onDeleteComment,
      },
      has_restriction,
    });

  const renderTransactionColumnPXQ = column =>
    //  NOTE: POR AHORA SIN COMENTARIOS QUE FUNCIONE PARA VISTA PXQ
    columnsHelper.renderTransactionPlanningPXQ({
      column,
      cellFormat: { format, showNegativeInRed },
      width: column.width,
      disabled: !editable,
      showCalculateValue: false,
      onCellEdit,
      has_restriction,
    });

  const columnsGeneratorImporte = () =>
    columnsHelper.generator({
      columns: columnsImporte,
      filteredColumns: visibleData,
      cellFormat: { format, showNegativeInRed },
      filterProps: { filterOptions, filters, onFilter },
      collapserProps: {
        ...(isPublishedOrElaboration && { width: 25 }),
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      sorter: true,
      renderTransaction: renderTransactionColumn,
      has_restriction,
    });

  const columnsGeneratorPXQ = () =>
    columnsHelper.generator({
      columns: columns,
      filteredColumns: visibleData,
      cellFormat: { format, showNegativeInRed },
      filterProps: { filterOptions, filters, onFilter },
      collapserProps: {
        ...(isPublishedOrElaboration && { width: 25 }),
        isCollapsed: isCollapsed,
        onCollapse: toogleIsCollapsed,
      },
      showRowsComment,
      sorter: true,
      renderTransaction: renderTransactionColumnPXQ,
      has_restriction,
    });

  const renderRowSelection = () => {
    return {
      getCheckboxProps: record => {
        const isOriginRow = PROJECTION.CURRENCIES_OPTIONS.find(
          currency => currency.code === record[DIMENSIONS.ACCOUNT_LABEL]
        );
        return {
          ...((record.id === ROWS.TOTALIZER_ID || isOriginRow) && {
            className: 'hide-check',
            disabled: true,
          }),
        };
      },
      columnWidth: 24,
      selectedRowKeys: selection,
      onChange: idsRows => setSelection(idsRows),
    };
  };

  const renderRowClassName = record => {
    if (record.id === ROWS.TOTALIZER_ID) {
      return ROWS.TYPES[ROWS.TOTALIZER];
    }
    if (record.has_variable_applied) {
      return ROWS.TYPES.APPLIED_VAR_ROW;
    }
  };

  const onExpandRow = (expanded, record) => {
    setIsExpandingRow(true);
    if (expanded) {
      fetchOriginRows(record.id, { expand_row: true }).then(() => {
        setIsExpandingRow(false);
        setExpandedRow(prevExpanded => [...prevExpanded, record.id]);
      });
    } else {
      setExpandedRow(prevExpanded =>
        prevExpanded.filter(item => item !== record.id)
      );
      setIsExpandingRow(false);
    }
  };

  return (
    <Table
      rowKey="id"
      className="planning-table"
      rowSelection={canDelete ? renderRowSelection() : undefined}
      rowClassName={renderRowClassName}
      size="small"
      loading={loading || isExpandingRow}
      onChange={onChange}
      columns={
        isAmount(tableTypeKey)
          ? columnsGeneratorImporte()
          : columnsGeneratorPXQ()
      }
      dataSource={dataSource}
      pagination={{
        ...pagination,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
      onExpand={onExpandRow}
      expandedRowKeys={expandedRow}
      locale={columnsHelper.renderSorterLocale()}
      {...(!isCollapsing && {
        scroll: {
          x: calculateScrollX(
            isAmount(tableTypeKey)
              ? columnsGeneratorImporte()
              : columnsGeneratorPXQ()
          ),
          y: 445,
        },
      })}
    />
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchOriginRows: planning.actions.fetchOriginRows,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanningTable);
