import React from 'react';
import { Button, Divider } from 'antd';
import { ButtonActionBar } from 'modules/core/components';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const AddAndDeleteFormItem = ({
  fieldsLength,
  index,
  disabled = false,
  addData,
  deleteData,
  otherCondition = null,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ButtonActionBar>
        {(otherCondition ? otherCondition : fieldsLength > 1) && (
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={deleteData.onClick}
            disabled={disabled}
          >
            {t(deleteData.buttonName)}
          </Button>
        )}
        {fieldsLength - 1 === parseInt(index) && (
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={addData.onClick}
            disabled={disabled}
          >
            {t(addData.buttonName)}
          </Button>
        )}
      </ButtonActionBar>
      {fieldsLength - 1 !== parseInt(index) && <Divider />}
    </>
  );
};
export default AddAndDeleteFormItem;
