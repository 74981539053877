const adjustments = {
  ADJUSTMENT_MANAGMENT_LABEL: 'Ajustes de gestión',
  ADJUSTMENT_EMPTY_STATE_ALERT_TITLE:
    'Todavía no tenemos todos los datos que necesitamos para que puedas crear tus ajustes de gestión',
  ADJUSTMENT_EMPTY_STATE_ALERT_TEXT:
    'Para que puedas crear ajustes debemos tener datos reales aprobados. Una vez que lo hagas, podrás crear todos los ajustes que consideres necesarios.',
  ADJUSTMENT_EMPTY_STATE_TITLE: '¡Creá tu primer ajuste!',
  ADJUSTMENT_EMPTY_STATE_TEXT:
    'Realizá registros que te permitan ajustar tus datos e imputar correctamente todas tus ventas y tus gastos',
  ADJUSTMENT_CREATE_ACTION: 'Crear ajuste',
  ADJUSTMENT_VALID_LABEL: 'Ajustes vigentes',
  ADJUSTMENT_DATE_FIELD: 'Año/mes ajustado',
  ADJUSTMENT_TYPE_FIELD: 'Tipo de ajuste',
  ADJUSTMENT_CREATE_FORM_TITLE: 'Creación de ajuste',
  ADJUSTMENT_SELECT_DATE_LABEL: 'Año/mes a ajustar',
  ADJUSTMENT_TYPE_EXTENSION: 'Ampliación',
  ADJUSTMENT_TYPE_RECLASSIFICATION: 'Reclasificación',
  ADJUSTMENT_TYPE_REDUCTION: 'Reducción',
  ADJUSTMENT_ADD_MOVEMENT_ACTION: 'Añadir movimiento',
  ADJUSTMENT_FORM_CANCEL_ALERT:
    'Al salir, perdés toda la información que cargaste hasta el momento. ¿Salimos de todos modos?',
  ADJUSTMENT_CREATE_CONFIRM_TITLE: 'Confirmación de creación de ajuste',
  ADJUSTMENT_CREATE_CONFIRM_DESCRIPTION:
    'Estás por crear una {{type}} en {{añoMes}}. Recordá que una vez que la hagas, quedará registrada, podrás anularla pero no eliminarla.',
  ADJUSTMENT_CREATE_SUCCESS_FEEDBACK_TITLE: 'Ajuste registrado',
  ADJUSTMENT_SUCCESS_FEEDBACK_DESCRIPTION:
    'Ya podés ver el impacto de la misma en seguimiento o análisis.',
  ADJUSTMENT_DETAIL_TITLE: 'Detalle de ajuste',
  ADJUSTMENT_CANCELED_DETAIL_TITLE: 'Detalle de ajuste anulado',
  ADJUSTMENT_DOWNLOAD_ACTION: 'Descarga ajuste',
  ADJUSTMENT_DOWNLOAD_MOVEMENT_ACTION_TITLE: 'Descargar movimientos',
  ADJUSTMENT_CANCEL_CONFIRM_TITLE: 'Confirmación de anulación de ajuste',
  ADJUSTMENT_CANCEL_CONFIRM_TEXT:
    '{{userName}}, estás por anular un ajuste en {{añoMes}}. Recordá que una vez que la hagas no podrás deshacerla.',
  ADJUSTMENT_CANCEL_CONFIRM_SUCCESS_FEEDBACK_TITLE:
    '¡Genial! La anulación quedó registrada.',

  ADJUSTMENT_CANCEL_CONFIRM_FAIL_FEEDBACK_DESCRIPTION:
    '{{userName}}, no pudimos realizar la anulación.',
  ADJUSTMENT_EMPTY_STATE_APPROVE_DATA_TOOLTIP: 'No hay datos reales aprobados',
  ADJUSTMENT_VIEW_ACTION_TITLE: 'Ver ajuste',
  ADJUSTMENT_NEW_CREATE_ACTION: 'Crear nuevo ajuste',
  ADJUSTMENT_CREATE_FAIL_FEEDBACK_DESCRIPTION: 'El ajuste no se pudo crear',
  ADJUSTMENT_LOAD_MOVEMENT_ACTION_TITLE: 'Cargar movimientos',
  ADJUSTMENT_DELETE_MOVEMENT_ACTION_TITLE: 'Eliminar movimiento',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_TITLE:
    'Los movimientos se cargaron correctamente',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_DESCRIPTION:
    'Ya podes trabajar con ellos',

  ADJUSTMENT_OBSERVATION_DETAIL_INFO: '(Original)\n{{canceled}} (Anulación)',

  ADJUSTMENT_DATA_TABLE_FILE_NAME:
    'Plika - Ajustes de gestión - {{periodName}} (listado)',
  ADJUSTMENT_LIST_DEFAULT_FILE_NAME: 'Plika - Ajustes de gestión (listado)',
  ADJUSTMENT_DEFAULT_FILE_NAME: 'Plika - Ajustes de gestión',
  ADJUSTMENT_DATA_TYPE_FILE_NAME: `Plika - Ajuste de gestión {{type}} - {{periodName}}`,
  ADJUSTMENT_MOVEMENT_FILE_NAME:
    'Plantilla Plika - Ajuste de gestión Movimientos.xlsx',
};

export default adjustments;
