import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Spin, Row, Col, List, Button } from 'antd';
import { ProjectionCard } from '../';
import { EmptyScreen } from 'modules/core/components';
import { isUserAllowed, userCanAcces } from 'modules/core/utils';
import {
  ROLES,
  CONCEPTS,
  ACCES_USERS,
  PROJECTION,
} from 'modules/core/constants';
import { CreateBudgetModal } from 'modules/planning/components';
import { DollarOutlined } from '@ant-design/icons';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { useTranslation } from 'react-i18next';
import { renderPrefix } from 'modules/core/utils';
import { useFunctionalCurrency } from 'modules/core/customHooks';

import forecast from 'modules/forecast';
import login from 'modules/login';

const {
  FORECAST__EXPENSES__VIEW,
  FORECAST__SALES__VIEW,
  FORECAST__EXPENSES__CREATE,
  FORECAST__SALES__CREATE,
  FORECAST__EXPENSES__EXCHANGE_RATE,
  FORECAST__SALES__EXCHANGE_RATE,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const FORECAST__CONCEPT = {
  [CONCEPTS.KEYS.SALES]: {
    VIEW: FORECAST__SALES__VIEW,
    CREATE: FORECAST__SALES__CREATE,
    EXCHANGE_RATE: FORECAST__SALES__EXCHANGE_RATE,
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    VIEW: FORECAST__EXPENSES__VIEW,
    CREATE: FORECAST__EXPENSES__CREATE,
    EXCHANGE_RATE: FORECAST__EXPENSES__EXCHANGE_RATE,
  },
};

// TODO: Creación, edición de nombre, duplicar, eliminar.
const ProjectionsList = ({
  concept,
  fetchForecastProjections,
  loggedUser: { email },
}) => {
  const [projections, setProjections] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [duplicateData, setDuplicateData] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  let { periodId, forecastId } = useParams();
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    setIsListLoading(true);
    fetchForecastProjections(forecastId, { concept: concept.id }).then(data => {
      setProjections(data.payload.data);
      setIsListLoading(false);
    });
  }, [fetchForecastProjections, forecastId, concept]);

  const userCanCreate = isUserAllowed(FORECAST__CONCEPT[concept.name].CREATE);

  const canUserExchangeRateView = isUserAllowed(
    FORECAST__CONCEPT[concept.name].EXCHANGE_RATE
  );

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('FORECAST_EMPTY_SCREEN_TITLE', { concept: t(concept.name) })}
      description={t('FORECAST_EMPTY_SCREEN_BODY')}
      footer={
        <Button
          type="primary"
          onClick={() => setShowCreateModal(true)}
          disabled={!userCanCreate}
        >
          {t('PLANNING_BUDGET_CREATE_ACTION')}
        </Button>
      }
    />
  );

  const renderBudgetList = () => (
    <List
      style={{ paddingLeft: 6 }}
      grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 3, xxl: 3 }}
      dataSource={!isListLoading ? projections : []}
      renderItem={budget => {
        const { id: projectionId, name, status } = budget;
        return (
          <List.Item>
            <ProjectionCard
              budgetId={projectionId}
              periodId={periodId}
              title={name}
              type={concept.name}
              status={status}
              extraOptionExchangeRate={
                userCanAcces({
                  patchAcces: ACCES_USERS.PATCH_ACCES_CURRENCY_USERS,
                  email,
                })
                  ? [
                      {
                        title: t('ACTION_LOAD_EXCHANGE_RATE'),
                        icon: <DollarOutlined />,
                        onClick: () =>
                          history.push(
                            `/forecast/${periodId}/tipodecambio/${projectionId}`
                          ),
                        disabled: !canUserExchangeRateView,
                      },
                    ]
                  : null
              }
              onOpenActivities={() =>
                history.push(
                  `/forecast/${periodId}/${concept.name.toLowerCase()}/actividad/${projectionId}`
                )
              }
              onOpen={() =>
                history.push(
                  `/forecast/${periodId}/${forecastId}/${concept.name.toLowerCase()}/${projectionId}`
                )
              }
              onDuplicate={forecastName => {
                setDuplicateData({
                  id: projectionId,
                  name: t('COPY_NAME', {
                    name: t(forecastName),
                  }),
                });
                setShowCreateModal(true);
              }}
              reloadList={refreshList}
              renderPrefix={defaultPrefix =>
                renderPrefix(functionalCurrencyData, defaultPrefix)
              }
            />
          </List.Item>
        );
      }}
    />
  );

  const refreshList = () => {
    setIsListLoading(true);
    fetchForecastProjections(forecastId, { concept: concept.id }).then(data => {
      setProjections(data.payload.data);
      setIsListLoading(false);
    });
  };

  return (
    <Spin spinning={isListLoading} size="large">
      <Row gutter={[24, 24]}>
        {!isListLoading && projections.length <= 0 && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {!isListLoading && projections.length > 0 && (
          <Col span={24}>{renderBudgetList()}</Col>
        )}
      </Row>
      <CreateBudgetModal
        period={periodId}
        concept={concept}
        visible={showCreateModal}
        onCancel={() => {
          setShowCreateModal(false);
          setDuplicateData(null);
        }}
        copyData={duplicateData}
        onSuccessCreate={refreshList}
        type={PROJECTION.FORECAST}
        forecastId={forecastId}
      />
    </Spin>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchForecastProjections: forecast.actions.fetchForecastProjections,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionsList);
