import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Collapse, Drawer, Row, Col, message } from 'antd';
import { ButtonActionBar } from 'modules/core/components';
import { RoleDimensionsDrawer } from '../';
import { useTranslation } from 'react-i18next';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import login from 'modules/login';
import configuration from 'modules/configuration';

const { Panel } = Collapse;
const {
  SETTINGS__SECURITY__ROLES__CREATE,
  SETTINGS__SECURITY__ROLES__EDIT,
  MODULES_OPTIONS,
} = ROLES;

const RoleOperationsDrawer = ({
  roleId,
  visible,
  roleName,
  onSuccess,
  onClose,
  whoAmI,
  roleOperationKeys,
  editRoleOperations,
  saveRoleOperations,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [dimensionDrawer, setDimensionDrawer] = useState({
    operationKeys: [],
    visible: false,
    operationName: '',
    isLastChild: false,
  });
  const { t } = useTranslation();
  const canUserEdit = isUserAllowed([
    SETTINGS__SECURITY__ROLES__CREATE,
    SETTINGS__SECURITY__ROLES__EDIT,
  ]);

  // NOTE: Limpio las opciones tildadas
  const onCancel = () => {
    onClose();
    editRoleOperations({
      checked: false,
      operationsKeys: roleOperationKeys,
    });
  };

  const onSave = () => {
    setIsSaving(true);
    saveRoleOperations({
      role_id: roleId,
      operations_asign: roleOperationKeys,
    })
      .then(() => {
        // NOTE: Refetch del usuario logeado por si cambio su rol. Falta contemplar la condicion del rol.
        whoAmI();
        onSuccess();
        setIsSaving(false);
        message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
      })
      .catch(() => {
        setIsSaving(false);
        message.error(t('FEEDBACK_SAVE_CHANGES_FAIL'));
      });
  };

  const renderDimensionDrawerButton = ({
    operationName,
    panelArrayKeys,
    isLastChild,
  }) => (
    <Button
      type="link"
      style={{ marginRight: 5 }}
      onClick={event => {
        event.stopPropagation();
        setDimensionDrawer({
          visible: true,
          operationKeys: panelArrayKeys,
          operationName: operationName,
          isLastChild: isLastChild,
        });
      }}
    >
      {t('LABEL_DETAIL')}
    </Button>
  );

  const renderOperationCheckbox = panelArrayKeys => {
    const allChecked = panelArrayKeys.every(r => roleOperationKeys.includes(r));
    const partialChecked =
      !allChecked &&
      [...panelArrayKeys].some(r => roleOperationKeys.includes(r));

    return (
      <Checkbox
        onClick={event => event.stopPropagation()}
        checked={allChecked}
        indeterminate={partialChecked}
        disabled={!canUserEdit}
        onChange={event =>
          editRoleOperations({
            checked: event.target.checked,
            operationsKeys: panelArrayKeys,
          })
        }
      />
    );
  };

  const renderOperationsPanelContent = operations =>
    operations.map((op, index) => (
      <Row key={index} justify="space-between" align="middle">
        <Col span={22}>{t(`${op.label}`)}</Col>
        <Col span={2} style={{ display: 'flex', justifyContent: 'end' }}>
          {renderOperationCheckbox([op.key])}
        </Col>
      </Row>
    ));

  const renderScreenCollapse = options => {
    return (
      <Collapse>
        {options.map(collapse => (
          <Panel
            header={t(`${collapse.label}`)}
            key={collapse.key}
            extra={
              <>
                {renderDimensionDrawerButton({
                  operationName: t(`${collapse.label}`),
                  panelArrayKeys: collapse.operationsKeys,
                  isLastChild: !collapse.children,
                })}
                {renderOperationCheckbox(collapse.operationsKeys)}
              </>
            }
          >
            {/* NOTE: Se diferencian las opciones que tienen submodulos y las que no */}
            {collapse.children
              ? renderScreenCollapse(collapse.children)
              : renderOperationsPanelContent(collapse.operations)}
          </Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <Drawer
      title={t('CONFIG_ROLES_DRAWER_ROLE_OPERATIONS_TITLE', { name: roleName })}
      placement="right"
      maskClosable={false}
      onClose={onCancel}
      visible={visible}
      width={500}
      footer={
        <ButtonActionBar>
          <Button onClick={onCancel}>{t('ACTION_CANCEL')}</Button>
          <Button
            type="primary"
            onClick={onSave}
            loading={isSaving}
            disabled={!canUserEdit}
          >
            {t('ACTION_SAVE_CHANGES')}
          </Button>
        </ButtonActionBar>
      }
    >
      {renderScreenCollapse(MODULES_OPTIONS)}
      <RoleDimensionsDrawer
        roleId={roleId}
        operationName={dimensionDrawer.operationName}
        visible={dimensionDrawer.visible}
        operationKeys={dimensionDrawer.operationKeys}
        isLastChild={dimensionDrawer.isLastChild}
        onClose={() =>
          setDimensionDrawer({
            visible: false,
            operationKeys: [],
            operationName: '',
            isLastChild: false,
          })
        }
      />
    </Drawer>
  );
};

const mapStateToProps = state => ({
  roleOperationKeys: configuration.selectors.getRoleOperationKeys(state),
});

const mapDispatchToProps = {
  whoAmI: login.actions.whoAmI,
  editRoleOperations: configuration.actions.editRoleOperations,
  saveRoleOperations: configuration.actions.saveRoleOperations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleOperationsDrawer);
