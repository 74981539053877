import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Navigator, Typography } from 'modules/core/components';
import { useParams } from 'react-router-dom';
import { usePagination } from 'modules/core/customHooks';
import { INTEGRATIONS, PROJECTION } from 'modules/core/constants';
import moment from 'moment';
import home from 'modules/home';
import planning from 'modules/planning';

import './IntegrationTrackingScreen.scss';

const IntegrationTrackingScreen = ({
  fetchIntegrationTrackingList,
  integrationTrackingList,
  fetchProjections,
  projections,
}) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tablePagination, setTablePagination] = usePagination();
  let { integrationName } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    Promise.all([
      fetchProjections({ type: PROJECTION.REAL }),
      fetchIntegrationTrackingList(),
    ]).then(() => setIsDataLoading(false));
  }, [fetchIntegrationTrackingList, fetchProjections]);

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        { name: t('LABEL_CONFIG'), url: '/configuracion' },
        { name: t('LABEL_INTEGRATION'), url: '/configuracion?tab=integration' },
        { name: integrationName },
        { name: t('LABEL_ACTIVITY') },
      ]}
    />
  );

  //todo: ver si los data index estan bien
  const columns = [
    {
      title: t('FIELD_LAST_UPDATED'),
      dataIndex: 'last_update',
      render: last_update => (
        <Typography.Body level={3}>{`${moment(last_update).format(
          'DD/MM/YYYY'
        )} ${t('DATE_AT_TIME')} ${moment(last_update).format(
          'HH:mm'
        )} `}</Typography.Body>
      ),
    },
    {
      title: t('LABEL_USER'),
      dataIndex: 'user',
    },
    {
      title: t('LABEL_PERIOD'),
      dataIndex: 'period',
    },
    {
      title: t('LABEL_DESTINATION_PROJECTION'),
      dataIndex: 'proyection',
      render: id => {
        if (id && projections.length > 0) {
          // NOTE: PROJECTION-REAL PROVISORIO YA QUE POR AHORA SOLO ES REAL LO QUE TRAE
          return ` ${PROJECTION.REAL} -  ${
            projections.find(projection => projection.id === parseInt(id)).name
          } `;
        } else {
          return '';
        }
      },
    },
    {
      title: t('LOADING_STATUS'),
      dataIndex: 'status',
      render: status => (
        <Tag
          color={INTEGRATIONS.STATUS[status].color}
          icon={INTEGRATIONS.STATUS[status].icon}
        >
          {t(INTEGRATIONS.STATUS[status].title)}
        </Tag>
      ),
    },
    {
      title: t('LABEL_LOAD_DETAIL'),
      dataIndex: 'message',
      render: (message, record) =>
        message ? message : t(INTEGRATIONS.STATUS[record.status].message),
    },
  ];

  const renderTable = () => (
    <Table
      rowKey="id"
      bordered
      size="small"
      loading={isDataLoading}
      onChange={setTablePagination}
      columns={columns}
      dataSource={integrationTrackingList}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: integrationTrackingList.length,
        size: 'small',
      }}
    />
  );

  return (
    <div className="integration-activity-container">
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{renderTable()}</Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  integrationTrackingList: home.selectors.getIntegrationTrackingList(state),
  projections: planning.selectors.getProjections(state),
});

const mapDispatchToProps = {
  fetchIntegrationTrackingList: home.actions.fetchIntegrationTrackingList,
  fetchProjections: planning.actions.fetchProjections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationTrackingScreen);
