import React from 'react';
import { Tabs } from 'antd';
import { useQuery } from 'modules/core/customHooks';
import { useTranslation } from 'react-i18next';
import { Navigator } from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { VariablesList, FormulasList, CurrencyList } from '../';
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils';
import { ROLES, INTEGRATIONS } from 'modules/core/constants';

const {
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES,
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES,
  SETTINGS__PROJECTION_ELEMENTS__CURRENCY,
} = ROLES;

const ModificadoresHome = () => {
  const { t } = useTranslation();
  let query = useQuery();

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        { name: t('LABEL_PROJECTION_ELEMENTS') },
      ]}
    />
  );

  return (
    <SiderLayout>
      <>
        {renderHeader()}
        <Tabs
          size="small"
          defaultActiveKey={
            query.get('tab') !== null ? query.get('tab') : 'vars'
          }
          style={{ paddingTop: 24 }}
        >
          {isUserAllowed(
            getOperationsByKeys([SETTINGS__PROJECTION_ELEMENTS__VARIABLES])
          ) && (
            <Tabs.TabPane tab={t('LABEL_VARIABLES')} key="vars">
              <VariablesList />
            </Tabs.TabPane>
          )}
          {isUserAllowed(
            getOperationsByKeys([SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES])
          ) && (
            <Tabs.TabPane tab={t('LABEL_BUSINESS_RULES')} key="rules">
              <FormulasList />
            </Tabs.TabPane>
          )}
          {isUserAllowed(
            getOperationsByKeys([SETTINGS__PROJECTION_ELEMENTS__CURRENCY])
          ) && (
            <Tabs.TabPane tab={t('LABEL_CURRENCY')} key="currency">
              <CurrencyList />
            </Tabs.TabPane>
          )}
        </Tabs>
      </>
    </SiderLayout>
  );
};

export default ModificadoresHome;
