import React /*, { useState }*/ from 'react';
import { Row, Col, Table, Button, Space, InputNumber } from 'antd';
import {
  Typography,
  // ButtonActionBar,
  // DropdownMenu,
} from 'modules/core/components';
import {
  DeleteOutlined,
  // FileExcelOutlined,
  // UploadOutlined,
  PlusCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { DistributionTableHeader } from 'modules/distributions/components';
// import { DistributionUploadModal } from '..';
import { CellSelect } from '../DistributionOriginTable/Cells';
import { useTranslation } from 'react-i18next';
import distributions from 'modules/distributions';
import { connect } from 'react-redux';

const DistributionDestinationTable2 = ({
  successCreation,
  originColumns,
  valueDate,
  valueAccount,
  dataSource,
  disabled,
  // downloadDistributionTemplateXLS,
  editDistributionDestinationRow,
  addDistributionDestinationRow,
  removeDistributionDestinationRow,
  copyDistributionRow,
}) => {
  // const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();

  // const renderTopActions = () => (
  //   <ButtonActionBar>
  //     <DropdownMenu
  //       title={t('ACTION_MORE')}
  //       disabled={successCreation}
  //       menu={[
  //         {
  //           title: t('DISTIBUTION_UPLOAD_ACTION'),
  //           icon: <UploadOutlined />,
  //           onClick: () => setShowUploadModal(true),
  //         },
  //         {
  //           title: t('ACTION_DOWNLOAD_TEMPLATE'),
  //           icon: <FileExcelOutlined />,
  //           onClick: () =>
  //             downloadDistributionTemplateXLS({
  //               destination: '', //destinationDimension,
  //             }),
  //         },
  //       ]}
  //     />
  //   </ButtonActionBar>
  // );

  const renderValue = (record, row) => {
    if (typeof record === 'object') {
      return record;
    }
    if (typeof record === 'number') {
      return { id: record, name: row.name };
    }

    return undefined;
  };

  const dimensionColumns = originColumns
    .filter(item => item.data_index !== 'amount')
    .map(item => {
      return {
        title: item.title,
        dataIndex: item.data_index,
        width: 200,
        render: (record, row) => {
          return (
            <CellSelect
              key={item.dimension}
              label={item.title}
              disabled={disabled || successCreation}
              fetchData={{
                date: valueDate,
                desired_dimension_id: item.data_index,
                value_id: valueAccount,
                dimensionId: 1,
              }}
              value={record ? renderValue(record, row) : undefined}
              onChange={newValue =>
                editDistributionDestinationRow(row.id, {
                  [item.data_index]: newValue,
                })
              }
            />
          );
        },
      };
    });

  const columns = [
    { title: '', dataIndex: 'id', render: id => '' },
    {
      dataIndex: 'amount',
      title: '',
      align: 'right',
      width: 200,
      render: (record, row) => {
        return (
          <Space align="end">
            <InputNumber
              min={0}
              max={100}
              value={record}
              disabled={disabled || successCreation}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              onChange={value =>
                editDistributionDestinationRow(row.id, { amount: value })
              }
            />
            <Button
              type="text"
              shape="circle"
              title={t('ACTION_DUPLICATE')}
              disabled={disabled}
              icon={
                <Typography.Icon
                  icon={CopyOutlined}
                  type={disabled ? 'disable' : 'primary'}
                />
              }
              onClick={() => copyDistributionRow(row.id)}
            />
            {dataSource.length > 1 && (
              <Button
                type="text"
                shape="circle"
                title={t('ACTION_DELETE_ROW')}
                disabled={disabled || successCreation}
                icon={<Typography.Icon icon={DeleteOutlined} />}
                onClick={() => removeDistributionDestinationRow(row.id)}
              />
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Row gutter={[8, 8]} align="bottom">
      <Col span={20}>
        <Typography.Body level={2}>
          <Typography.Body level={2} type="danger">
            *
          </Typography.Body>{' '}
          {t('DISTIBUTION_DIMENSION_TO_FIELD')}
        </Typography.Body>
      </Col>
      {/* NOTE: Se deshabilita la opcion de carga y descarga de archivo hasta que se defina como debe funcionar con la nueva ui */}
      {/* <Col span={4}>
        {renderTopActions()}
        <DistributionUploadModal
          // dimensionId={destinationDimension}
          visible={showUploadModal}
          onCancel={() => setShowUploadModal(false)}
        />
      </Col> */}
      <Col span={24}>
        <DistributionTableHeader
          title={t('DISTRIBUTION_TO_FIELD')}
          disabled={successCreation ? successCreation : disabled}
        />
        <Table
          rowKey="id"
          size="small"
          showHeader={false}
          dataSource={dataSource}
          columns={[...dimensionColumns, ...columns]}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Col>
      <Col span={24}>
        <Button
          type="link"
          disabled={disabled}
          icon={<PlusCircleOutlined />}
          onClick={() => addDistributionDestinationRow()}
        >
          {t('DISTRIBUTION_ADD_NEW_COMBINATION_ACTION')}
        </Button>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = {
  downloadDistributionTemplateXLS:
    distributions.actions.downloadDistributionTemplateXLS,
  editDistributionDestinationRow:
    distributions.actions.editDistributionDestinationRow,
  addDistributionDestinationRow:
    distributions.actions.addDistributionDestinationRow,
  removeDistributionDestinationRow:
    distributions.actions.removeDistributionDestinationRow,
  copyDistributionRow: distributions.actions.copyDistributionRow,
};

export default connect(null, mapDispatchToProps)(DistributionDestinationTable2);
