import React, { useState, useEffect } from 'react';
import { Table, Popconfirm, Space, Button, message, Tooltip } from 'antd';
import {
  DropdownMenu,
  Typography,
  InputCell,
  SelectCell,
  DeleteAndUpdate,
} from 'modules/core/components';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  orderBySelection,
  isUserAllowed,
  renderTableCellLabel,
} from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import login from 'modules/login';
import configuration from 'modules/configuration';

const { SETTINGS__SECURITY__USERS__EDIT, SETTINGS__SECURITY__USERS__DELETE } =
  ROLES;

const UsersTable = ({
  loggedUser: { userId },
  tableLoading,
  values,
  onTableChange,
  pagination,
  onSetTableLoading,
  dataSelection,
  fetchUserRoles,
  userRoles,
  deleteUser,
  onRefetchUsersList,
  editUser,
  whoAmI,
}) => {
  const [editRow, setEditRow] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    fetchUserRoles();
  }, [fetchUserRoles]);

  const onCellEdit = (name, value) =>
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value });

  const columns = [
    {
      title: t('FIELD_EMAIL'),
      dataIndex: 'email',
      editable: true,
      width: 200,
      render: (email, row) => {
        if (editRow && row.id === editRow) {
          return (
            <InputCell
              initialValue={email}
              onCellUpdate={onCellEdit}
              name="email"
            />
          );
        } else {
          if (row.password_confirmed) {
            return email;
          } else {
            return email.substr(0, email.indexOf('@'));
          }
        }
      },
    },
    {
      title: t('LABEL_ROLES'),
      dataIndex: 'roles',
      editable: true,
      width: 450,
      render: (roles, row) => {
        if (editRow && row.id === editRow) {
          const idsSelect = roles.map(infoRoles => infoRoles.id);
          return (
            <SelectCell
              initialValue={[...idsSelect]}
              onCellUpdate={onCellEdit}
              name="roles"
              style={{ width: 450 }}
              options={orderBySelection(
                idsSelect,
                userRoles.map(infoRol => {
                  return { id: infoRol.id, name: infoRol.display_name };
                })
              )}
              mode="multiple"
              showArrow
              allowClear
              maxTagCount={1}
              maxTagTextLength={12}
            />
          );
        } else {
          if (roles.length > 0) {
            return renderTableCellLabel({
              values: roles.map(infoRoles => infoRoles.display_name),
              allValues: userRoles,
              isDataJoin: true,
            });
          } else {
            return '-';
          }
        }
      },
    },
    // POR AHORA COMENTADO HASTA TENER DEFINICION SI SE EDITA O NO EL CAMPO DE ESTADO
    // {
    //   title: t('FIELD_STATUS'),
    //   dataIndex: 'status',
    //   editable: true,
    //   width: 200,
    //   render: (status, row) =>
    // if (editRow && row.id === editRow) {
    //   return (
    //     <SelectCell
    //       initialValue={status}
    //       onCellUpdate={onCellEdit}
    //       name="status"
    //       options={[
    //         { id: 'active', name: t('STATE_TAG_ACTIVE') },
    //         { id: 'inactive', name: t('STATE_TAG_INACTIVE') },
    //       ]}
    //     />
    //   );
    // } else {
    //   if (status === 'active') {
    //     return t('STATE_TAG_ACTIVE');
    //   } else {
    //     return t('STATE_TAG_INACTIVE');
    //   }
    // }
    // },
    {
      title: (
        <Space size="large">
          {t('FIELD_ACTIONS')}
          <Tooltip title={t('CONFIG_USERS_TOOLTIP_INFO_TITLE')}>
            <Typography.Icon icon={InfoCircleOutlined} type="primary" />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'id',
      align: 'right',
      width: 150,
      render: (record, row) => {
        return record !== editRow ? (
          <Popconfirm
            placement="bottomRight"
            title={t('CONFIG_USERS_POPCOFIRM_DELETE_USER_TITLE')}
            okText={t('ACTION_DELETE')}
            onConfirm={() => onDeleteUser(record)}
            okButtonProps={{ loading: isDeleting }}
            cancelText={t('ACTION_CANCEL')}
            onCancel={() => setDeleteUserId(null)}
            visible={deleteUserId === record}
          >
            <Space size="middle">
              <Button
                type="text"
                shape="circle"
                icon={<Typography.Icon icon={EyeOutlined} />}
                title={t('ACTION_OPEN_DETAIL')}
                onClick={() =>
                  history.push(`/configuracion/misdatos/usuarios/${record}`)
                }
              />
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={_.compact([
                  {
                    title: t('ACTION_EDIT'),
                    icon: <EditOutlined />,
                    onClick: () => setEditRow(record),
                    disabled: !isUserAllowed(SETTINGS__SECURITY__USERS__EDIT),
                  },
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteUserId(record),
                    disabled: !isUserAllowed(SETTINGS__SECURITY__USERS__DELETE),
                  },
                ])}
              />
            </Space>
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={onEditUser}
            dataCellEdit={dataCellEdit}
            loading={isEditing}
          />
        );
      },
    },
  ];

  const onConfirm = () => {
    setEditRow(null);
    setDataCellEdit({});
  };

  const onCancel = () => setEditRow(null);

  // TODO: Revisar edicion usuario
  const onEditUser = () => {
    if (!_.isEmpty(dataCellEdit)) {
      setIsEditing(true);
      editUser(editRow, dataCellEdit)
        .then(() => {
          onSetTableLoading(true);
          onRefetchUsersList().then(() => onSetTableLoading(false));
          // NOTE: Refetch del usuario logeado por si cambio su propio usuario.
          if (editRow === userId) {
            whoAmI();
          }
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          setEditRow(null);
          setDataCellEdit({});
        })
        .catch(() => {
          setIsEditing(false);
          message.error(t('FEEDBACK_SAVE_CHANGES_FAIL'));
          setEditRow(null);
          setDataCellEdit({});
        });
    } else {
      setEditRow(null);
      setDataCellEdit({});
    }
  };

  const onDeleteUser = id => {
    setIsDeleting(true);
    deleteUser(id)
      .then(() => {
        onSetTableLoading(true);
        onRefetchUsersList().then(() => onSetTableLoading(false));
        setIsDeleting(false);
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
        setDeleteUserId(null);
      })
      .catch(() => {
        setIsDeleting(false);
        message.error(t('FEEDBACK_DELETE_FAIL'));
        setDeleteUserId(null);
      });
  };

  return (
    <Table
      rowKey="id"
      bordered
      rowSelection={{
        onChange: idsRow => dataSelection(idsRow),
      }}
      size="small"
      loading={tableLoading}
      onChange={onTableChange}
      columns={columns}
      dataSource={values}
      pagination={{
        ...pagination,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
    />
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  userRoles: configuration.selectors.getUserRoles(state),
});

const mapDispatchToProps = {
  whoAmI: login.actions.whoAmI,
  editUser: configuration.actions.editUser,
  fetchUserRoles: configuration.actions.fetchUserRoles,
  deleteUser: configuration.actions.deleteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
