import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { client } from 'httpclient';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Form,
  Col,
  Row,
  Modal,
  Select,
  Spin,
  message,
  notification,
  Alert,
} from 'antd';
import { generateSelectOptions } from 'modules/core/utils';
import planning from 'modules/planning';

const FormItem = Form.Item;

const ReplicateFactorModal = ({
  visible,
  factorId,
  projectionId,
  onCancel,
  replicateFactor,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [factorsOptions, setFactorsOptions] = useState([]);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      client
        .get(`/planning/formulas/?writeable=False`)
        .then(response => {
          const allFactors = response.data.results.reduce(
            (allFactors, rules) => {
              const factors = rules.factores.map(f => {
                if (factorId === f.id) {
                  return null;
                }
                return { id: f.id, name: `${rules.name} - ${f.name}` };
              });
              return _.compact([...allFactors, ...factors]);
            },
            []
          );

          setFactorsOptions(allFactors);
          setIsLoading(false);
        })
        .catch(e => console.log(e));
    }
  }, [visible, factorId]);

  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOnConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsCreating(true);
        replicateFactor({
          factorId,
          projectionId,
          destinationFactor: values.destinationFactor,
        })
          .then(() => {
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
            handleOnCancel();
            setIsCreating(false);
          })
          .catch(error => {
            setIsCreating(false);
            notification.error({
              duration: 0,
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: error
                ? error.response.data[0]
                : t('FEEDBACK_SAVE_CHANGES_FAIL'),
            });
          });
      })
      .catch(e => console.log(e));
  };

  const renderAlertInfo = () => (
    <Alert
      showIcon
      closable
      type="info"
      description={t('PLANNING_FACTOR_REPLICATE_INFORMATIVE_ALERT_DESCRIPTION')}
    />
  );

  return (
    <Modal
      title={t('CONFIG_REPLICATE_FACTOR_TITLE')}
      visible={visible}
      onCancel={!isCreating ? handleOnCancel : null}
      centered
      okText={t('ACTION_REPLICATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={handleOnConfirm}
      confirmLoading={isCreating}
      okButtonProps={{ disabled: isLoading }}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={24}>{renderAlertInfo()}</Col>
            <Col span={24}>
              <FormItem
                label={t('CONFIG_REPLICATE_FACTOR_LABEL')}
                name="destinationFactor"
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Select
                  placeholder={t('ACTION_SELECT')}
                  options={generateSelectOptions({ options: factorsOptions })}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

const mapDispatchToProps = {
  replicateFactor: planning.actions.replicateFactor,
};

export default connect(null, mapDispatchToProps)(ReplicateFactorModal);
