import React, { useState } from 'react';
import { Modal, Row, Col, DatePicker, notification } from 'antd';
import { Typography } from 'modules/core/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { GA } from 'modules/core/utils';

const PublishModal = ({
  onConfirm = () => {},
  availableDate,
  onSetDate,
  date,
  periodId,
  visible,
  onClose,
  onAction,
  isActionPublish,
  onFetchProjections,
  conceptType,
}) => {
  const [ispublish, setIsPublish] = useState(false);
  const { t } = useTranslation();

  const onPublishDates = () => {
    setIsPublish(true);
    onAction(periodId, { month: date }, { concept_type: conceptType })
      .then(() => {
        setIsPublish(false);
        onFetchProjections(); //TODO: CHECKEAR QUE ESTE FUNCIOANNDO BIEN EL REFETCH
        onConfirm();
        onClose();
        GA.event(GA.ACTIONS.PUBLISH_DATA);
      })
      .catch(() => {
        setIsPublish(false);
        onSetDate(null);
        onClose();
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('CONTROL_PUBLISH_MONTHS_FAIL_FEEDBACK_DESCRIPTION'),
          duration: 0,
        });
      });
  };

  const onDisabledDates = date => {
    const pickerDates = moment(date._d).format('YYYY-MM-01');
    if (availableDate.length === 0) {
      return true;
    }
    return !availableDate.includes(pickerDates);
  };

  return (
    <>
      <Modal
        title={t('CONTROL_MODAL_ACTION_DATA_TITLE', {
          action: isActionPublish ? t('ACTION_PUBLISH') : t('ACTION_APPROVE'),
        })}
        okText={isActionPublish ? t('ACTION_PUBLISH') : t('ACTION_APPROVE')}
        cancelText={t('ACTION_CANCEL')}
        centered
        visible={visible}
        onCancel={() => {
          onSetDate(null);
          onClose();
        }}
        width={568}
        okButtonProps={{ loading: ispublish, disabled: !date }}
        onOk={onPublishDates}
        destroyOnClose={true}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Typography.Body level={2}>
              {isActionPublish
                ? t('CONTROL_CONCEPT_PUBLISH_MODAL_TEXT_SALES_EXPENSES')
                : t('CONTROL_CONCEPT_APPROVE_MODAL_TEXT_SALES_EXPENSES')}
            </Typography.Body>
          </Col>
          <Col span={24}>
            <DatePicker
              defaultPickerValue={moment(availableDate[0])}
              defaultValue={date && moment(date)}
              format="MMMM YYYY"
              picker="month"
              style={{ width: 260 }}
              disabledDate={onDisabledDates}
              onChange={date => onSetDate(moment(date).format('YYYY-MM-01'))}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PublishModal;
