import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PublishedOrApprovedDateCalendar = ({ value, status }) => {
  const { t } = useTranslation();

  const dateFormat = 'DD/MM/YYYY';
  const customFormat = value =>
    `${
      status === 'published'
        ? t('LABEL_PUBLISHED_DATA_UNTIL')
        : t('LABEL_APPROVED_DATA_UNTIL')
    } ${value.format(dateFormat)}`;

  return (
    <DatePicker
      format={customFormat}
      value={moment(value)}
      allowClear={false}
    />
  );
};

export default PublishedOrApprovedDateCalendar;
