import React, { useState } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { UploadModal } from 'modules/core/components';
import distributions from 'modules/distributions';
import { useTranslation } from 'react-i18next';

const DistributionUploadModal = ({
  visible,
  onCancel,
  dimensionId,
  uploadDistributionFile,
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();

  const onCloseUploadModal = () => {
    onCancel();
    setFileList([]);
  };

  const onConfirmUpload = () => {
    setIsUploadingFile(true);
    uploadDistributionFile(fileList, dimensionId)
      .then(() => {
        notification.success({
          message: t('DISTRIBUTION_LOAD_SUCCESS_FEEDBACK_TITLE'),
          description: t('DISTRIBUTION_LOAD_SUCCESS_FEEDBACK_DISTRIBUTION'),
        });
        setIsUploadingFile(false);
        onCloseUploadModal();
      })
      .catch(error => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: error.response.data[0],
          duration: 0,
        });
        setIsUploadingFile(false);
        onCloseUploadModal();
      });
  };

  const onAddUploadFile = file => {
    setFileList([file]);
    return false;
  };

  return (
    <UploadModal
      title={t('DISTIBUTION_UPLOAD_ACTION')}
      visible={visible}
      onCancel={onCloseUploadModal}
      onConfirm={onConfirmUpload}
      uploadProps={{
        name: 'file',
        accept: '.xls, .xlsx',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: file => setFileList([]),
        fileList: fileList,
      }}
      isUploadingFile={isUploadingFile}
    />
  );
};

const mapDispatchToProps = {
  uploadDistributionFile: distributions.actions.uploadDistributionFile,
};

export default connect(null, mapDispatchToProps)(DistributionUploadModal);
