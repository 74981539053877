import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  usePagination,
  useSorter,
  useToggle,
  useFunctionalCurrency,
} from 'modules/core/customHooks';
import numeral from 'numeral';
import {
  Spin,
  Card,
  Button,
  Space,
  Row,
  Col,
  notification,
  Alert,
  message,
  Tooltip,
} from 'antd';
import {
  DownloadOutlined,
  FileExcelOutlined,
  SolutionOutlined,
  UndoOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  DropdownMenu,
  ProjectionUploadModal,
  projectionSuccessUploadNotice,
  Typography,
  KPIValue,
  UploadDraggerScreen,
  TableSettingsModal,
  ShowCommentsButton,
  InputNumberPercentage,
  References,
} from 'modules/core/components';
import { ForecastTable } from './components';
import {
  isApproved,
  isInElaboration,
  isPublished,
  isUserAllowed,
  formItemsHelper,
  renderPrefix,
  userCanAcces,
} from 'modules/core/utils';
import {
  ROLES,
  CONCEPTS,
  TABLES,
  PROJECTION,
  COLUMNS,
  ACCES_USERS,
} from 'modules/core/constants';
import planning from 'modules/planning';
import login from 'modules/login';
import { useTranslation } from 'react-i18next';

const {
  FORECAST__EXPENSES__CREATE,
  FORECAST__SALES__CREATE,
  FORECAST__EXPENSES__EDIT,
  FORECAST__SALES__EDIT,
  FORECAST__EXPENSES__EXCHANGE_RATE,
  FORECAST__SALES__EXCHANGE_RATE,
  FORECAST__EXPENSES__UPLOAD,
  FORECAST__SALES__UPLOAD,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const FORECAST__CONCEPT = {
  [CONCEPTS.KEYS.SALES]: {
    EDIT: [FORECAST__SALES__CREATE, FORECAST__SALES__EDIT],
    EXCHANGE_RATE: FORECAST__SALES__EXCHANGE_RATE,
    UPLOAD: FORECAST__SALES__UPLOAD,
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    EDIT: [FORECAST__EXPENSES__CREATE, FORECAST__EXPENSES__EDIT],
    EXCHANGE_RATE: FORECAST__EXPENSES__EXCHANGE_RATE,
    UPLOAD: FORECAST__EXPENSES__UPLOAD,
  },
};

// TODO: Componentizar componentes en comun con PlanningDetail.
// TODO: Conectar el Alert con backend.
// TODO: Aplicar variable?
// TODO: Pasar descargar archivo a dropdown de mas acciones

const ForecastAmountDetail = ({
  projection,
  // projectionColumns,
  projectionColumnsImporte,
  projectionRows,
  filterTableOptions,
  fetchProjectionDetail,
  fetchProjectionColumns,
  fetchProjectionRows,
  fetchProjectionFilters,
  downloadTemplateXLS,
  uploadProjectionXLS,
  downloadProjectionXLS,
  fetchProjectionKpis,
  projectionKpis,
  updateTransaction,
  updateTransactionForward,
  updateTransactionAll,
  syncProjectionComment,
  createTransactionComment,
  deleteTransactionComment,
  loggedUser: { email },
  editTransactionOriginCurrency,
  createTransactionOriginCurrency,
  fetchOriginRows,
  updateTransactionForwardOrigin,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isKpisLoading, setIsKpisLoading] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [tableSettings, setTableSettings] = useState({
    ...TABLES.DEFAULT_SETTINGS(email),
    negativeValuesType: TABLES.NEGATIVE_VALUES_TYPES.NORMAL,
    showNegativeInRed: false,
  });
  const [tablePagination, setTablePagination, totalizerRows] = usePagination({
    totalizerRows: 1,
  });
  const [showRowsComment, toggleShowRowsComment] = useToggle(false);
  const [filters, setFilters] = useState({});
  const [tableSorter, setTableSorter] = useSorter();
  const [refetchFilters, setRefetchFilters] = useState(true);
  const [dataUndoCellEditAll, setDataUndoCellEditAll] = useState([]);
  const functionalCurrencyData = useFunctionalCurrency();
  let history = useHistory();
  let { periodId, projectionId, conceptName } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setIsScreenLoading(true);
    Promise.all([
      fetchProjectionDetail(projectionId),
      fetchProjectionColumns(projectionId),
    ]).then(() => setIsScreenLoading(false));
  }, [fetchProjectionDetail, fetchProjectionColumns, projectionId]);

  useEffect(() => {
    if (projection.concept?.id && refetchFilters) {
      fetchProjectionFilters(projection.concept.id, {
        conventional: projectionId,
        ...filters,
      })
        .then(() => setRefetchFilters(false))
        .catch(() => setRefetchFilters(false));
    }
  }, [
    fetchProjectionFilters,
    projectionId,
    projection.concept?.id,
    refetchFilters,
    filters,
  ]);

  useEffect(() => {
    setIsKpisLoading(true);
    fetchProjectionKpis(projectionId, { ...filters }).then(() =>
      setIsKpisLoading(false)
    );
  }, [fetchProjectionKpis, projectionId, filters]);

  useEffect(() => {
    setIsTableLoading(true);
    fetchProjectionRows(projectionId, {
      ...tablePagination,
      ...filters,
      ...tableSorter,
    }).then(() => setIsTableLoading(false));
  }, [
    fetchProjectionRows,
    projectionId,
    tablePagination,
    filters,
    tableSorter,
  ]);

  const canUserEdit =
    !isScreenLoading &&
    isUserAllowed(FORECAST__CONCEPT[projection.concept.name].EDIT);

  const canUserExchangeRateView =
    !isScreenLoading &&
    isUserAllowed(FORECAST__CONCEPT[projection.concept.name].EXCHANGE_RATE);

  const canUserUpload =
    !isScreenLoading &&
    isUserAllowed(FORECAST__CONCEPT[projection.concept.name].UPLOAD);

  // TODO: Agregar todas las validacones
  const isTableEditable =
    canUserEdit &&
    (isInElaboration(projection.status) || isPublished(projection.status));

  const handleMessageError = () => message.error(t('FEEDBACK_DEFAULT_ERROR'));

  const handleMessageSuccess = () => {
    message.destroy('loading_edit');
    message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES', 0.5));
  };

  const onSuccessUploadFile = response => {
    projectionSuccessUploadNotice({
      projectionType: PROJECTION.FORECAST,
      errorFile: response.payload.data.error_file,
    });
    setTablePagination({ current: 1 });
    setRefetchFilters(true);
    setIsKpisLoading(true);
    Promise.all([
      fetchProjectionKpis(projectionId, { ...filters }),
      fetchProjectionDetail(projectionId),
    ]).then(() => setIsKpisLoading(false));
  };

  const onFailUploadFile = error =>
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: error.response.data,
      duration: 0,
    });

  const onEditTransaction = (editFn, { qp = {}, ...data }) => {
    message.loading({
      key: 'loading_edit',
      content: t('FEEDBACK_LOADING_CHANGES'),
    });
    editFn(data, qp)
      .then(() => {
        Promise.all([
          fetchProjectionKpis(projectionId, { ...filters }),
          fetchProjectionRows(projectionId, {
            ...tablePagination,
            ...tableSorter,
            ...filters,
          }),
        ]).finally(() => {
          data.parentId
            ? fetchOriginRows(data.parentId, { expand_row: true }).then(() => {
                handleMessageSuccess();
              })
            : handleMessageSuccess();
        });
      })
      .catch(handleMessageError);
  };

  const handleOnCalculateValue = (data, isOriginCurrencyRow) => {
    if (isOriginCurrencyRow) {
      if (data.transactionId) {
        onEditTransaction(editTransactionOriginCurrency, {
          qp: { add: 'True' },
          ...data,
        });
      }
    } else {
      onEditTransaction(updateTransaction, {
        qp: { add: 'True' },
        ...data,
      });
    }
  };

  const handleCellEdit = (data, isOriginCurrencyRow) => {
    if (isOriginCurrencyRow) {
      if (data.transactionId) {
        onEditTransaction(editTransactionOriginCurrency, data);
      } else {
        onEditTransaction(createTransactionOriginCurrency, data);
      }
    } else {
      onEditTransaction(updateTransaction, data);
    }
  };

  const handleCellEditForward = (data, isOriginCurrencyRow) => {
    const auxEndpoint = isOriginCurrencyRow
      ? updateTransactionForwardOrigin
      : updateTransactionForward;

    onEditTransaction(auxEndpoint, {
      ...data,
      ...(!data.endDate && {
        endDate: projection.period.end_date,
      }),
    });
  };

  const handleCellEditAll = ({ value, undoParams = null }) => {
    onEditTransaction(updateTransactionAll, {
      projectionId,
      value,
      qp: undoParams ? { ...undoParams } : { ...filters },
    });
  };

  const handleOnChangeTable = (pagination, filters, sorter) => {
    setTablePagination(pagination);
    setTableSorter(sorter);
  };

  const onCommentCell = ({ columnId, rowId, transactionId, text }) => {
    createTransactionComment({ transactionId, text })
      .then(response => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionRows',
          newComment: response.payload.data,
        });
      })
      .catch(handleMessageError);
  };

  const onDeleteCommentCell = ({ columnId, rowId, commentId }) => {
    deleteTransactionComment({ commentId })
      .then(() => {
        syncProjectionComment({
          columnId,
          rowId,
          reducerKey: 'projectionRows',
          commentId,
        });
      })
      .catch(handleMessageError);
  };

  const handleUndoCellEditAll = () => {
    const dataUndo = dataUndoCellEditAll.pop();
    handleCellEditAll({
      value: dataUndo.value,
      undoParams: { ...dataUndo.qp, percentage_rollback: 'True' },
    });
    setDataUndoCellEditAll([...dataUndoCellEditAll]);
  };

  const renderTopAlert = () => (
    <Alert
      showIcon
      closable
      type="info"
      message={t('FORECAST_ALERT_INFO_TITLE')}
      description={t('FORECAST_ALERT_INFO_DESCRIPTION')}
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Tooltip
          title={
            isApproved(projection.status)
              ? t('FORECAST_TOOLTIP_NOT_UPLOAD_FILE')
              : ''
          }
        >
          <Button
            onClick={() => setShowUploadModal(true)}
            type="primary"
            disabled={isApproved(projection.status) || !canUserUpload}
          >
            {t('ACTION_UPLOAD_FILE')}
          </Button>
        </Tooltip>
        <Button
          icon={<SolutionOutlined />}
          onClick={() =>
            history.push(
              `/forecast/${periodId}/${conceptName}/actividad/${projectionId}`
            )
          }
        >
          {t('ACTION_ACTIVITIES')}
        </Button>
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            ...(userCanAcces({
              patchAcces: ACCES_USERS.PATCH_ACCES_CURRENCY_USERS,
              email,
            })
              ? [
                  {
                    title: t('ACTION_LOAD_EXCHANGE_RATE'),
                    icon: <DollarOutlined />,
                    onClick: () =>
                      history.push(
                        `/forecast/${periodId}/tipodecambio/${projectionId}`
                      ),
                    disabled: !canUserExchangeRateView,
                  },
                ]
              : []),
            {
              title: t('ACTION_DOWNLOAD_TEMPLATE'),
              icon: <FileExcelOutlined />,
              onClick: () => {
                downloadTemplateXLS(
                  projectionId,
                  t('FORECAST_TEMPLATE_NAME', {
                    concept: projection.name,
                    periodName: projection.period.name,
                  })
                );
              },
            },
          ]}
        />
      </Space>
    </ButtonActionBar>
  );

  const renderKPIs = () => (
    <Row gutter={[24, 0]}>
      <>
        {isKpisLoading &&
          [1, 2, 3].map(index => (
            <Col span={8} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isKpisLoading &&
          projectionKpis.map((kpi, index) => (
            <Col span={8} key={index}>
              <Card style={{ height: '100%' }}>
                <Space>
                  <KPIValue
                    size="medium"
                    prefix={renderPrefix(functionalCurrencyData, kpi.prefix)}
                    fullValue={kpi.value && numeral(kpi.value).format('0,0.00')}
                    value={
                      kpi.value ? numeral(kpi.value).format('0[.]0a') : '-'
                    }
                  />
                  {kpi.variation && (
                    <KPIValue
                      value={
                        kpi.variation
                          ? numeral(kpi.variation).format('0%')
                          : '-'
                      }
                      type="secondary"
                    />
                  )}
                </Space>
                <br />
                <Typography.Body level={3} type="secondary">
                  {kpi.name}
                </Typography.Body>
              </Card>
            </Col>
          ))}
      </>
    </Row>
  );

  const renderTableActions = () => {
    const auxValue =
      dataUndoCellEditAll.length > 0
        ? dataUndoCellEditAll[dataUndoCellEditAll.length - 1].value * 100
        : 0;
    return (
      <Row>
        <Col span={12}>
          <References
            referencesItems={[
              ...(functionalCurrencyData
                ? [
                    {
                      type: <DollarOutlined />,
                      title:
                        functionalCurrencyData && functionalCurrencyData.code,
                      description:
                        functionalCurrencyData &&
                        t('REFERENCE_DESCRIPTION', {
                          currencyName: t(
                            functionalCurrencyData.name
                          ).toLowerCase(),
                        }),
                    },
                  ]
                : []),
            ]}
          />
          <ShowCommentsButton onClick={toggleShowRowsComment} />
          {isTableEditable && (
            <>
              <InputNumberPercentage
                value={auxValue}
                disabled={!isTableEditable}
                onPressEnter={value => {
                  handleCellEditAll({ value });
                  setDataUndoCellEditAll(prevState => {
                    return [...prevState, { value, qp: { ...filters } }];
                  });
                }}
              />
              {dataUndoCellEditAll.length > 0 && (
                <Button
                  type="text"
                  shape="circle"
                  icon={<Typography.Icon icon={UndoOutlined} level={1} />}
                  onClick={() => handleUndoCellEditAll()}
                  title={t('ACTION_UNDO')}
                />
              )}
            </>
          )}
        </Col>
        <Col span={12}>
          <ButtonActionBar>
            <Button
              type="text"
              shape="circle"
              icon={<Typography.Icon icon={DownloadOutlined} />}
              onClick={() =>
                downloadProjectionXLS(
                  projectionId,
                  t('FORECAST_EVOLUTION_TABLE_FILE_NAME', {
                    concept: projection.name,
                    periodName: projection.period.name,
                  }),
                  { ...filters }
                )
              }
              title={t('ACTION_DOWNLOAD_TABLE')}
            />
            <TableSettingsModal
              onConfirm={setTableSettings}
              options={projectionColumnsImporte.filter(
                (column, index) =>
                  !column.is_transaction &&
                  index !== COLUMNS.FIRST_INDEX &&
                  column.data_index !== COLUMNS.TOTAL_DATA_INDEX
              )}
              settings={tableSettings}
              formItems={formItemsHelper.itemsDataView(
                tableSettings.negativeValuesType,
                tableSettings.showNegativeInRed
              )}
            />
          </ButtonActionBar>
        </Col>
      </Row>
    );
  };

  const handleOnFilter = newFilters => {
    setFilters(newFilters);
    setTablePagination({ current: 1 });
    setRefetchFilters(true);
  };

  return (
    <>
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && projection.has_transactions && (
        <Row gutter={[8, 16]}>
          <Col span={24}> {renderTopAlert()}</Col>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>{renderKPIs()}</Col>
          <Col span={24}>{renderTableActions()}</Col>
          <Col span={24}>
            <ForecastTable
              editable={isTableEditable}
              columns={projectionColumnsImporte}
              dataSource={projectionRows.transactions}
              loading={isTableLoading}
              onCalculateValue={handleOnCalculateValue}
              onCellEdit={handleCellEdit}
              onCellEditForward={handleCellEditForward}
              onChange={handleOnChangeTable}
              pagination={{
                pageSize: tablePagination.page_size + totalizerRows,
                current: tablePagination.page,
                total:
                  projectionRows.count > 0
                    ? projectionRows.count + totalizerRows
                    : projectionRows.count,
              }}
              tableSettings={tableSettings}
              filters={filters}
              filterOptions={filterTableOptions}
              onFilter={handleOnFilter}
              comments={{
                showRowsComment,
                onComment: onCommentCell,
                onDeleteComment: onDeleteCommentCell,
              }}
            />
          </Col>
        </Row>
      )}
      {!isScreenLoading && !projection.has_transactions && (
        <UploadDraggerScreen
          disabled={!canUserUpload}
          description={t('FORECAST_UPLOAD_SCREEN_DESCRIPTION')}
          downloadTemplate={() =>
            downloadTemplateXLS(
              projectionId,
              t('FORECAST_TEMPLATE_NAME', {
                concept: projection.name,
                periodName: projection.period.name,
              })
            )
          }
          uploadFile={fileList => uploadProjectionXLS(projectionId, fileList)}
          onSuccessUpload={onSuccessUploadFile}
          onFailUpload={onFailUploadFile}
        />
      )}
      <ProjectionUploadModal
        title={t('FORECAST_CREATE_ACTION')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={fileList => uploadProjectionXLS(projectionId, fileList)}
        onSuccessUpload={onSuccessUploadFile}
        onFailUpload={onFailUploadFile}
      />
    </>
  );
};

const mapStateToProps = state => ({
  projection: planning.selectors.getProjectionDetail(state),
  projectionColumnsImporte:
    planning.selectors.getProjectionsColumnsImporte(state),
  //NOTE: COMENTADO HASTA QUE ESTE HABILITADA LA VISION POR PXQ EN FORECAST.
  // projectionColumns: planning.selectors.getProjectionColumns(state),
  projectionRows: planning.selectors.getProjectionAmountsRows(state),
  projectionKpis: planning.selectors.getProjectionKpis(state),
  filterTableOptions: planning.selectors.getFilterTableOptions(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
  fetchProjectionColumns: planning.actions.fetchProjectionColumns,
  fetchProjectionRows: planning.actions.fetchProjectionAmountsRows,
  fetchProjectionFilters: planning.actions.fetchProjectionFilters,
  downloadTemplateXLS: planning.actions.downloadTemplateXLS,
  uploadProjectionXLS: planning.actions.uploadProjectionXLS,
  downloadProjectionXLS: planning.actions.downloadProjectionXLS,
  fetchProjectionKpis: planning.actions.fetchProjectionAmountsKpis,
  updateTransaction: planning.actions.updateTransaction,
  updateTransactionForward: planning.actions.updateTransactionForward,
  updateTransactionAll: planning.actions.updateTransactionAll,
  syncProjectionComment: planning.actions.syncProjectionComment,
  createTransactionComment: planning.actions.createTransactionComment,
  deleteTransactionComment: planning.actions.deleteTransactionComment,
  editTransactionOriginCurrency: planning.actions.editTransactionOriginCurrency,
  createTransactionOriginCurrency:
    planning.actions.createTransactionOriginCurrency,
  fetchOriginRows: planning.actions.fetchOriginRows,
  updateTransactionForwardOrigin:
    planning.actions.updateTransactionForwardOrigin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForecastAmountDetail);
