import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import numeral from 'numeral';
import {
  Spin,
  Button,
  Row,
  Col,
  Table,
  Input,
  InputNumber,
  notification,
} from 'antd';
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { SiderLayout } from 'modules/core/layouts';
import {
  ButtonActionBar,
  PeriodNavigator,
  ConfirmationModal,
  Typography,
} from 'modules/core/components';
import { DistributionDetailInfo } from './components';
import { DistributionTableHeader } from '../../components';
import { CellSelect } from '../DistributionCreation/components/DistributionOriginTable/Cells';
import { dateMMMMYYYY, isUserAllowed, renderPrefix } from 'modules/core/utils';
import { ROLES } from 'modules/core/constants';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import login from 'modules/login';
import planning from 'modules/planning';
import distributions from 'modules/distributions';
import { useTranslation } from 'react-i18next';

import './DistributionDetail.scss';

const { CONTROL__DISTRIBUTIONS__DELETE } = ROLES;

const DistributionDetail = ({
  loggedUser,
  periodDetail,
  distributionDetail: {
    date,
    account,
    canceled,
    comment,
    origin,
    destinations,
    reversion_comment,
  },
  fetchPeriodDetail,
  fetchDistributionDetail,
  downloadSingleDistributionsXLS,
  cancelDistribution,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCancelingDistribution, setIsCancelingDistribution] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  let { periodId, distributionId } = useParams();
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setIsScreenLoading(true);
    fetchPeriodDetail(periodId).then(() => setIsScreenLoading(false));
  }, [fetchPeriodDetail, periodId]);

  useEffect(() => {
    fetchDistributionDetail(distributionId).then(() =>
      setIsTableLoading(false)
    );
  }, [fetchDistributionDetail, distributionId]);

  const handleConfirmationModal = anulationComment => {
    setIsCancelingDistribution(true);
    cancelDistribution(distributionId, { comment: anulationComment })
      .then(() => {
        notification.success({
          message: t('DISTRIBUTION_CANCEL_SUCCESS_FEEDBACK_TITLE'),
          description: t('DISTRIBUTION_SUCCESS_FEEDBACK_DESCRIPTION'),
        });
        fetchDistributionDetail(distributionId).then(() => {
          setIsCancelingDistribution(true);
          setShowCancelModal(false);
        });
      })
      .catch(() => {
        setIsCancelingDistribution(false);
        setShowCancelModal(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t(
            'DISTRIBUTION_CANCEL_CONFIRM_FAIL_FEEDBACK_DESCRIPTION',
            { userName: loggedUser.firstName }
          ),
        });
      });
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_CONTROL'),
          url: `/seguimiento/${periodId}`,
        },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/seguimiento/${periodId}/economico?tab=distribuciones`,
        },
        {
          name: !canceled
            ? t('DISTRIBUTION_DETAIL_TITLE')
            : t('DISTRIBUTION_CANCELED_DETAIL_TITLE'),
        },
      ]}
    />
  );

  const renderTopActions = () => (
    <ButtonActionBar>
      <Button
        type="text"
        shape="circle"
        title={t('DISTRIBUTION_DOWNLOAD_ACTION')}
        icon={<Typography.Icon icon={DownloadOutlined} />}
        onClick={() =>
          downloadSingleDistributionsXLS(
            distributionId,
            t('DISTRIBUTION_DATA_ROW_FILE_NAME', {
              periodName: periodDetail.name,
            })
          )
        }
      />
    </ButtonActionBar>
  );

  const renderOriginTable = () => {
    const columns = origin.columns
      .filter(item => item.data_index !== 'amount')
      .map(item => {
        return {
          title: item.title,
          dataIndex: item.data_index,
          width: 200,
          render: (record, row) => (
            <Input value={record} suffix={<DownOutlined />} disabled={true} />
          ),
        };
      });

    //NOTE: Por como funciona antd con los scroll de tabla y las columnas con ancho fijo,
    // es necesario dejar al menos una columna sin ancho fijo.
    // https://ant.design/components/table/#components-table-demo-fixed-columns
    const amountColumn = [
      { title: '', dataIndex: 'id', render: id => '' },
      {
        title: '',
        dataIndex: 'amount',
        width: 200,
        fixed: 'right',
        render: record => (
          <Input
            value={numeral(record).format('0,0[.]00')}
            prefix={renderPrefix(functionalCurrencyData)}
            disabled={true}
          />
        ),
      },
    ];

    return (
      <>
        <DistributionTableHeader
          title={t('DISTIBUTION_ORIGIN_FIELD')}
          disabled={true}
        />
        <Table
          rowKey="id"
          size="small"
          showHeader={false}
          loading={isTableLoading}
          dataSource={origin.values}
          columns={[...columns, ...amountColumn]}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </>
    );
  };

  const renderDestinationTable = () => {
    const dimensionColumns = origin.columns
      .filter(item => item.data_index !== 'amount')
      .map(item => {
        return {
          title: item.title,
          dataIndex: item.data_index,
          width: 200,
          render: record => {
            return (
              <CellSelect
                key={item.dimension}
                label={item.title}
                disabled={true}
                value={record}
              />
            );
          },
        };
      });

    const columns = [
      { title: '', dataIndex: 'id', render: id => '' },
      {
        dataIndex: 'percentage',
        title: '',
        align: 'right',
        width: 200,
        render: record => (
          <InputNumber
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            value={record}
            disabled={true}
          />
        ),
      },
    ];

    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <DistributionTableHeader
            title={t('DISTRIBUTION_TO_FIELD')}
            disabled={true}
          />
          <Table
            rowKey="id"
            size="small"
            showHeader={false}
            loading={isTableLoading}
            dataSource={destinations}
            columns={[...dimensionColumns, ...columns]}
            scroll={{ x: 'max-content' }}
            pagination={false}
          />
        </Col>
      </Row>
    );
  };

  const renderCancelBottomActions = () => (
    <ButtonActionBar position="center">
      <Button
        onClick={() =>
          history.push(`/seguimiento/${periodId}/economico?tab=distribuciones`)
        }
      >
        {t('ACTION_RETURN')}
      </Button>
      {!canceled && isUserAllowed(CONTROL__DISTRIBUTIONS__DELETE) && (
        <Button type="primary" onClick={() => setShowCancelModal(true)}>
          {t('ACTION_ANULATE')}
        </Button>
      )}
    </ButtonActionBar>
  );

  return (
    <SiderLayout>
      <Spin size="large" spinning={isScreenLoading} />
      {renderHeader()}
      <div className="distribution-detail-container">
        <Row gutter={[8, 24]}>
          <Col span={24}>{renderTopActions()}</Col>
          <Col span={24}>
            <DistributionDetailInfo
              date={date}
              account={account}
              comment={comment}
              commentAnulation={reversion_comment}
              canceled={canceled}
            />
          </Col>
          <Col span={24}>{renderOriginTable()}</Col>
          <Col span={24}>{renderDestinationTable()}</Col>
        </Row>
        {renderCancelBottomActions()}
        {!isScreenLoading && !canceled && (
          <ConfirmationModal
            title={t('DISTRIBUTION_CANCEL_MODAL_TITLE')}
            bodyText={t('DISTRIBUTION_CANCEL_MODAL_TEXT', {
              userName: loggedUser.firstName,
              account: account,
              añoMes: dateMMMMYYYY(date, 'YYYY-MM-DD'),
            })}
            okText={t('ACTION_ANULATE')}
            visible={showCancelModal}
            onClose={() => setShowCancelModal(false)}
            onConfirm={handleConfirmationModal}
            loading={isCancelingDistribution}
            canceled={!canceled}
          />
        )}
      </div>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
  periodDetail: planning.selectors.getPeriodDetail(state),
  distributionDetail: distributions.selectors.getDistributionDetail(state),
});

const mapDispatchToProps = {
  fetchPeriodDetail: planning.actions.fetchPeriodDetail,
  downloadSingleDistributionsXLS:
    distributions.actions.downloadSingleDistributionsXLS,
  fetchDistributionDetail: distributions.actions.fetchDistributionDetail,
  cancelDistribution: distributions.actions.cancelDistribution,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributionDetail);
