import React from 'react';
import { Modal, Button, Upload, Col, Row, Tooltip, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './UploadModal.scss';

const UploadModal = ({
  visible,
  onConfirm,
  onCancel,
  downloadTemplateFile,
  uploadProps,
  isUploadingFile,
  title,
  extraBody = null,
  disabled = false,
  disabledTooltip = '',
  afterOnClose = null,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title ? title : t('ACTION_UPLOAD_FILE')}
      visible={visible}
      centered
      onCancel={!isUploadingFile ? onCancel : null}
      closable={true}
      destroyOnClose={true}
      afterClose={afterOnClose}
      wrapClassName="upload-modal"
      footer={
        <Space>
          <Button onClick={onCancel} disabled={isUploadingFile}>
            {t('ACTION_CANCEL')}
          </Button>
          <Tooltip
            title={
              uploadProps.fileList.length <= 0
                ? t('UPLOAD_FILE_PLACEHOLDER')
                : disabled && disabledTooltip
            }
          >
            <Button
              type="primary"
              onClick={onConfirm}
              loading={isUploadingFile}
              disabled={
                isUploadingFile || uploadProps.fileList.length <= 0 || disabled
              }
            >
              {t('ACTION_UPLOAD')}
            </Button>
          </Tooltip>
        </Space>
      }
    >
      <Row
        gutter={[8, 24]}
        type="flex"
        justify="center"
        className="upload-modal-body"
      >
        {extraBody && (
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            {extraBody}
          </Col>
        )}
        <Col>
          <Upload {...uploadProps}>
            {uploadProps.fileList.length <= 0 && (
              <Button>
                <UploadOutlined /> {t('UPLOAD_FILE_PLACEHOLDER')}
              </Button>
            )}
          </Upload>
        </Col>
        {downloadTemplateFile && (
          <Col>
            <div className="upload-moda-help">
              {t('INFO_UPLOAD_FILE_MODAL_DESCRIPTION')}{' '}
              <span onClick={downloadTemplateFile}>
                {t('INFO_UPLOAD_FILE_DOWNLOAD_ACTION')}
              </span>
            </div>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default UploadModal;
