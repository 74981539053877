import React, { useState } from 'react';
import { Tooltip, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import './CalculateValueInput.scss';

const CalculateValueInput = ({ initialValue, onCellUpdate, onCancel }) => {
  const [calculateValue, setCalculateValue] = useState('');
  const { t } = useTranslation();

  const handleUpdateValue = () => {
    if (calculateValue) {
      onCellUpdate(calculateValue);
      onCancel();
      setCalculateValue('');
    }
    onCancel();
    setCalculateValue('');
  };

  const handleOnChange = event => {
    const { value } = event.target;
    setCalculateValue(value);
  };

  return (
    <Tooltip title={t('LABEL_BASE_VALUE', { value: initialValue })}>
      <Input
        className="cell-input-calculate-value"
        onChange={handleOnChange}
        onPressEnter={handleUpdateValue}
        onBlur={handleUpdateValue}
        onFocus={e => e.target.select()}
        autoFocus
        type="number"
      />
    </Tooltip>
  );
};

export default CalculateValueInput;
