import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FileExcelOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Table,
  Space,
  Button,
  Popconfirm,
  Modal,
  notification,
  message,
} from 'antd';
import { isUserAllowed, isApproved, renderPrefix } from 'modules/core/utils';
import {
  ROLES,
  CONCEPTS,
  ROWS,
  PROJECTION_ELEMENTS,
  PROJECTION,
} from 'modules/core/constants';
import { useFunctionalCurrency } from 'modules/core/customHooks';
import RuleTableActions from './RuleTableActions';
import { initialRulesColumns, initialFactorColumns } from './columns';
import planning from 'modules/planning';
import configuration from 'modules/configuration';

import './RuleTable.scss';

//TODO: AGREGAR ROLES DE FORECAST CUANDO ESTEN
const {
  PLANNING__EXPENSES__CREATE,
  PLANNING__SALES__CREATE,
  PLANNING__EXPENSES__EDIT,
  PLANNING__SALES__EDIT,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PROJECTION__CONCEPT__EDIT = {
  [PROJECTION.BUDGET]: {
    [CONCEPTS.KEYS.SALES]: [PLANNING__SALES__CREATE, PLANNING__SALES__EDIT],
    [CONCEPTS.KEYS.EXPENSES]: [
      PLANNING__EXPENSES__CREATE,
      PLANNING__EXPENSES__EDIT,
    ],
  },
  [PROJECTION.FORECAST]: {
    [CONCEPTS.KEYS.SALES]: [PLANNING__SALES__CREATE, PLANNING__SALES__EDIT],
    [CONCEPTS.KEYS.EXPENSES]: [
      PLANNING__EXPENSES__CREATE,
      PLANNING__EXPENSES__EDIT,
    ],
  },
};

// TODO: Are "Formula" and "Rules" equals? Should props and functions refer to "Rules"?
const RuleTable = ({
  downloadTemplateXLS,
  dataSource,
  projectionId,
  formulasAmountList,
  handleTableChange,
  setFormulaId,
  isTableLoading,
  projection,
  setShowFactorsDetail,
  setFactorId,
  setShowUploadModal,
  tablePagination,
  onFetchDataFormulas,
  deleteCascade,
  desactivateFormula,
  deleteFormula,
  editFormula,
  editFactor,
  deleteFactor,
}) => {
  const [dataCellEdit, setDataCellEdit] = useState({});
  const [deleteFormulaId, setDeleteFormulaId] = useState(null);
  const [deleteFactorId, setDeleteFactorId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingFormula, setIsDeletingFormula] = useState(false);
  const [isDeletingFactor, setIsDeletingFactor] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [isEditingFormula, setIsEditingFormula] = useState(false);
  const [isEditingFactor, setIsEditingFactor] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const functionalCurrencyData = useFunctionalCurrency();
  const { t } = useTranslation();

  const canUserEdit = isUserAllowed(
    PROJECTION__CONCEPT__EDIT[projection.type][projection.concept.name]
  );

  const setDataSource = () => {
    return dataSource.results.map(formula => {
      const infoAmount = formulasAmountList.find(
        amount => amount.formula === formula.id
      );

      return infoAmount
        ? {
            ...formula,
            affected_rows: infoAmount.affected_rows,
            total: infoAmount.total,
          }
        : formula;
    });
  };

  const handleOnEnterDetail = (formulaId, factorId) => {
    setShowFactorsDetail(true);
    setFormulaId(formulaId);
    setFactorId(factorId);
  };

  const handleOnEdit = (editFn, setVisible) => {
    if (!_.isEmpty(dataCellEdit)) {
      editFn(editRow, dataCellEdit)
        .then(() => {
          setVisible(true);
          onFetchDataFormulas().then(() => {
            setVisible(false);
            setEditRow(null);
            setDataCellEdit({});
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          });
        })
        .catch(() => {
          setVisible(false);
          setEditRow(null);
          setDataCellEdit({});
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
            duration: 0,
          });
        });
    }
  };

  const onResetDataFactors = () => {
    setIsDeletingFactor(false);
    setDeleteFactorId(null);
  };

  const onDeleteFactor = (id, factorName = '', keepData = null) => {
    //NOTE: keep_data se le envia para validar si el usuario eligió borrar o conservar datos. Borrar => keep_data: false, conservar => keep_data: true
    const endpointParams = {
      ...(keepData !== null && {
        keep_data: keepData,
      }),
    };
    setIsDeletingFactor(true);
    deleteFactor(id, endpointParams)
      .then(response => {
        if (
          response.payload.data.code ===
          PROJECTION_ELEMENTS.FACTOR.CODES_DELETE.KEEP_DATA
        ) {
          setDeleteFactorId(null);
          Modal.confirm({
            title: t('FIELD_ATENTION'),
            centered: true,
            content: (
              <Space direction="vertical">
                <span>
                  {t('CONFIG_FACTOR_CONFIRM_ACTION_MODAL_DESCRIPTION', {
                    factorName: factorName,
                  })}
                </span>
                <span>
                  {t('CONFIG_FORMULAS_CONFIRM_ACTION_POPCONFIRM_TITLE')}
                </span>
              </Space>
            ),
            okText: t('ACTION_CLEAR'),
            onOk: () => onDeleteFactor(id, factorName, false),
            cancelText: t('ACTION_KEEP'),
            onCancel: () => onDeleteFactor(id, factorName, true),
          });
        } else {
          onFetchDataFormulas().then(() => {
            message.success(t('CONFIG_FACTOR_DELETE_FEEDBACK_SUCCESS'));
            onResetDataFactors();
          });
        }
      })
      .catch(() => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_DELETE_FAIL'),
          duration: 0,
        });
        onResetDataFactors();
      });
  };

  const onResetDataFormula = () => {
    setIsDeleting(false);
    setIsDeletingFormula(false);
    setDeleteFormulaId(null);
  };

  const onDeleteCascade = id => {
    setIsDeleting(true);
    deleteCascade(id)
      .then(() => {
        onFetchDataFormulas().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          onResetDataFormula();
        });
      })
      .catch(() => {
        onResetDataFormula();
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        });
      });
  };

  const onDeactivateFormula = id => {
    setIsDeleting(true);
    desactivateFormula(id)
      .then(() => {
        onFetchDataFormulas().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
          onResetDataFormula();
        });
      })
      .catch(() => {
        onResetDataFormula();
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        });
      });
  };

  const renderOnDeleteFormulaModal = ({ formulaId, isDeactivated, message }) =>
    Modal.confirm({
      title: t('FIELD_ATENTION'),
      centered: true,
      content: (
        <Space direction="vertical">
          <span>{message}</span>
          <span>
            {isDeactivated
              ? t('CONFIG_FORMULAS_MODAL_INACTIVE_CONFIRM_TEXT')
              : t('MODAL_DELETE_CONFIRM_TEXT')}
          </span>
        </Space>
      ),
      okText: t('YES'),
      onOk: () =>
        isDeactivated
          ? onDeactivateFormula(isDeactivated)
          : onDeleteCascade(formulaId),
      cancelText: t('NO'),
      onCancel: () => onResetDataFormula(),
    });

  const handleOnDeleteFormulaCatch = ({ formulaId, error }) => {
    const isDeletingCascade =
      error.response.data.code ===
      PROJECTION_ELEMENTS.RULE.CODES_DELETE.CASCADE;
    const isDeactivated =
      error.response.data.code ===
      PROJECTION_ELEMENTS.RULE.CODES_DELETE.DESACTIVATED;

    if (isDeactivated || isDeletingCascade) {
      setDeleteFormulaId(null);
      renderOnDeleteFormulaModal({
        formulaId,
        isDeactivated,
        message: error.response.data.message,
      });
    } else {
      notification.error({
        message: t('FEEDBACK_DEFAULT_ERROR'),
        description: error.response.data.message,
        duration: 0,
      });
    }
  };

  const onDeleteFormula = formulaId => {
    setIsDeletingFormula(true);
    deleteFormula(formulaId)
      .then(() =>
        onFetchDataFormulas().then(() => {
          message.success(t('CONFIG_FORMULAS_DELETE_FEEDBACK_SUCCESS'));
          onResetDataFormula();
        })
      )
      .catch(error => handleOnDeleteFormulaCatch({ formulaId, error }));
  };

  const renderEditButtons = ({ isFactor = false } = {}) => {
    const editFn = isFactor ? editFactor : editFormula;
    const setVisible = isFactor ? setIsEditingFactor : setIsEditingFormula;

    return (
      <Space direction="horizontal" size="small">
        <Popconfirm
          placement="bottomRight"
          title={t('POPCONFIRM_EDIT_CANCEL')}
          okText={t('YES')}
          onConfirm={() => {
            setShowCancelConfirm(false);
            setEditRow(null);
            setDataCellEdit({});
          }}
          cancelText={t('NO')}
          onCancel={() => setShowCancelConfirm(false)}
          visible={showCancelConfirm && !_.isEmpty(dataCellEdit)}
        >
          <Button
            type="link"
            onClick={() => {
              _.isEmpty(dataCellEdit)
                ? setEditRow(null)
                : setShowCancelConfirm(true);
            }}
          >
            {t('ACTION_CANCEL')}
          </Button>
        </Popconfirm>
        <Button
          type="link"
          onClick={() => handleOnEdit(editFn, setVisible)}
          disabled={_.isEmpty(dataCellEdit) || showCancelConfirm}
        >
          {t('ACTION_SAVE_CHANGES')}
        </Button>
      </Space>
    );
  };

  const renderFactorsList = record => {
    const factorsColumns = [
      ...initialFactorColumns({
        editRow,
        onCellUpdate: (name, value) =>
          setDataCellEdit({ ...dataCellEdit, [`${name}`]: value }),
      }),
      {
        dataIndex: 'id',
        align: 'right',
        render: (factorId, row) => {
          if (!factorId) return null;
          if (factorId !== editRow) {
            return (
              <RuleTableActions
                id={factorId}
                loading={isDeletingFactor}
                onEdit={setEditRow}
                popconfirmTitle="CONFIG_FACTOR_POPCONFIRM_DELETE_TITLE"
                onDelete={setDeleteFactorId}
                deleteConfirmationVisible={factorId === deleteFactorId}
                onDeleteConfirm={onDeleteFactor}
                onClickDetail={() => handleOnEnterDetail(record.id, factorId)}
                isMoreActionsVisible={!isApproved(projection.status)}
                moreActions={[
                  {
                    title: t('ACTION_DOWNLOAD_TEMPLATE'),
                    icon: <FileExcelOutlined />,
                    onClick: () =>
                      downloadTemplateXLS(
                        projectionId,
                        `${t('PLANNING_TEMPLATE_NAME', {
                          concept: projection.name,
                          periodName: projection.period.name,
                        })}  (${row.name})`,
                        { factor_id: factorId }
                      ),
                  },
                  {
                    title: t('ACTION_UPLOAD_FILE'),
                    icon: <UploadOutlined />,
                    disabled: !canUserEdit,
                    onClick: () => {
                      setFactorId(factorId);
                      setShowUploadModal(true);
                    },
                  },
                ]}
              />
            );
          } else {
            return renderEditButtons({ isFactor: true });
          }
        },
      },
    ];

    return (
      <Table
        rowKey="name"
        size="small"
        showHeader={false}
        columns={factorsColumns}
        loading={isEditingFactor}
        onRow={el => !el.id && { className: ROWS.TYPES[ROWS.TOTALIZER] }}
        dataSource={[record.behaviour, ...record.factores]}
        pagination={false}
      />
    );
  };

  const ruleColumns = [
    ...initialRulesColumns({
      editRow,
      onCellUpdate: (name, value) =>
        setDataCellEdit({ ...dataCellEdit, [`${name}`]: value }),
      prefix: renderPrefix(functionalCurrencyData),
    }),
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (ruleId, row) => {
        if (ruleId !== editRow) {
          return (
            <RuleTableActions
              id={ruleId}
              loading={isDeletingFormula}
              onEdit={setEditRow}
              popconfirmTitle="CONFIG_FORMULAS_POPCONFIRM_DELETE_TITLE"
              onDelete={setDeleteFormulaId}
              deleteConfirmationVisible={ruleId === deleteFormulaId}
              onDeleteConfirm={onDeleteFormula}
              disabledDetail={!row?.factores[0]?.id}
              onClickDetail={() =>
                handleOnEnterDetail(ruleId, row.factores[0].id)
              }
              isMoreActionsVisible={!isApproved(projection.status)}
            />
          );
        } else {
          return renderEditButtons();
        }
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      size="small"
      className="planning-rule-table"
      columns={ruleColumns}
      dataSource={
        formulasAmountList.length <= 0 ? dataSource.results : setDataSource()
      }
      loading={
        isTableLoading ||
        isDeleting ||
        isDeletingFormula ||
        isEditingFormula ||
        isEditingFactor
      }
      onChange={handleTableChange}
      expandable={{
        expandedRowRender: renderFactorsList,
        rowExpandable: record => record.factores.length !== 0,
      }}
      pagination={{
        pageSize: tablePagination.page_size,
        current: tablePagination.page,
        total: dataSource.count,
        pageSizeOptions: ['10', '20', '30'],
        size: 'small',
        showSizeChanger: true,
      }}
    />
  );
};

const mapDispatchToProps = {
  downloadTemplateXLS: planning.actions.downloadTemplateXLS,
  deleteFormula: configuration.actions.deleteFormula,
  desactivateFormula: configuration.actions.desactivateFormula,
  deleteCascade: configuration.actions.deleteCascade,
  editFormula: configuration.actions.editFormula,
  editFactor: configuration.actions.editFactor,
  deleteFactor: configuration.actions.deleteFactor,
};

export default connect(null, mapDispatchToProps)(RuleTable);
