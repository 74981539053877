const login = {
  LOGIN_WELLCOME_TITLE: 'Meet all the news of your planning',
  // LOGIN_WELLCOME_TITLE: 'Agilidad y simpleza para el análisis financiero.',
  LOGIN_WELLCOME_SUBTITLE: 'Have everything under control with Plika',

  LOGIN_FORM_TITLE: 'Login to start',
  LOGIN_FORM_TITLE_CREATE_PASSWORD: 'Create password',
  LOGIN_FORM_INPUT_EMAIL_LABEL: 'Enter your e-mail',
  LOGIN_FORM_INPUT_EMAIL_PLACEHOLDER: 'Example name@email.com',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_EMPTY: 'Please enter an e-mail',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_FORMAT: 'Please enter a valid e-mail',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_NAME: 'Please enter your name',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_SURNAME: 'Please enter your surname',
  LOGIN_FORM_INPUT_EMAIL_CAPTION_OK: 'Valid e-mail format',
  LOGIN_FORM_INPUT_PASSWORD_LABEL: 'Enter your password',
  LOGIN_FORM_INPUT_PASSWORD_PLACEHOLDER: 'password',
  LOGIN_FORM_INPUT_A_PASSWORD_CAPTION_EMPTY: 'Please enter a password',
  LOGIN_FORM_INPUT_PASSWORD_CAPTION_FORMAT: 'Please enter a valid password',
  LOGIN_FORM_INPUT_PASSWORD_CAPTION_OK: 'Valid password format',
  LOGIN_FORM_BUTTON_SUBMIT: 'Log in',
  LOGIN_FORM_CONFIRM_PASSWORD_LABEL_CURRENT: 'Current password',
  LOGIN_FORM_CONFIRM_PASSWORD_LABEL_NEW: 'New password',
  LOGIN_FORM_CONFIRM_PASSWORD_CHARACTERS: 'Must contain at least 6 characters',
  LOGIN_FORM_CONFIRM_PASSWORD_RECONFIRM: 'Confirm new password',
  LOGIN_FORM_PASSWORD_NOT_SAME: 'Passwords do not match',
  LOGIN_FORM_PASSWORD_NOT_DIFFERENT:
    'The password must be different from the previous one',

  LOGIN_FEEDBACK_ERROR:
    'We did not recognize the email or the password you just entered. Please try again',

  LOGIN_FORGOT_PASSWORD_ACTION: 'I forgot my password',
  LOGIN_GO_BACK_SESSION_ACTION: 'Back to Login',
  LOGIN_FORM_RESET_PASSWORD_TITLE: 'Reset password',
  LOGIN_FORM_RESET_PASSWORD_NOTIFICATION_TEXT:
    'We sent you an email to reset your password, please check your inbox',
  LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_1:
    'The password was reset successfully. Now you can ',
  LOGIN_RESET_PASSWORD_FEEDBACK_SUCCESS_DESCRIPTION_2:
    ' with your new password.',
};

export default login;
