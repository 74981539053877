const configuration = {
  //xls names
  CONFIG_USERS_TEMPLATE: 'Plantilla Plika - Configuración Usuarios.xlsx',
  CONFIG_CONCEPTS_TEMPLATE: 'Plantilla Plika - Configuración Conceptos.xlsx',
  CONFIG_ECONOMIC_REPORT_TEMPLATE:
    'Plantilla Plika - Configuración reporte económico.xlsx',
  CONFIG_FINANCIAL_REPORT_TEMPLATE:
    'Plantilla Plika - Configuración reporte financiero.xlsx',
  CONFIG_VALUES_TEMPLATE_NAME:
    'Plantilla Plika - Configuración {{name}} (valores).xlsx',
  CONFIG_VALUES_FILE_NAME: 'Plika - Configuración {{name}} (valores).xls',
  CONFIG_ACCOUNTS_TEMPLATE_NAME:
    'Plantilla Plika - Configuración Plan de cuentas.xlsx',
  CONFIG_DIMENSIONS_TEMPLATE_NAME:
    'Plantilla Plika - Configuración Dimensiones',
  CONFIG_DIMENSIONS_VALUES_TEMPLATE_NAME_DEFAULT:
    'Plantilla Plika - Configuración Dimension (valores).xlsx',
  CONFIG_ACCOUNTS_STRUCTURE_FILE_NAME:
    'Plika - Configuración Plan de cuentas (estructura).xlsx',
  CONFIG_STRUCTURE_FILE_NAME:
    'Plika - Configuración {{name}} (estructura).xlsx',
  CONFIG_DIMENSIONS_VALUES_FILE_NAME:
    'Plika - Configuración dimensión (valores).xlsx',
  CONFIG_USERS_FILE_NAME: 'Plika - Configuración Usuarios (listado).xlsx',
  CONFIG_ACCOUNT_CONCEPT_ASSOCIATION_TEMPLATE_NAME:
    'Plantilla Plika - asociación cuenta-concepto',
  CONFIG_CONCEPTS_FILE_NAME:
    'Plika - Configuración Conceptos {{conceptType}} ({{conceptName}}).xlsx',

  // General
  CONFIG_CARD_EMPTY_STATE_TEXT: 'Ingresá y comenzá a crear o cargar {{config}}',
  CONFIG_DISABLED_ACCES_NO_ACCOUNTS_TOOLTIP:
    'Para acceder primero cargá tu plan de cuentas.',
  CONFIG_SETTINGS_MODAL_TITLE: 'Preferencias',
  CONFIG_SETTINGS_MODAL_TEXT:
    '¿Cómo deseas que tu equipo visualice la dimensión {{dimension}}?',
  CONFIG_SETTINGS_MODAL_ALERT:
    '¡Importante! Te contamos que, lo que definas acá como preferencia impactará en la visualización de todo el producto.',
  CONFIG_SETTINGS_OPTION_BOTH: 'Por código y descripción.',
  CONFIG_SETTINGS_OPTION_CODE: 'Por código solamente.',
  CONFIG_SETTINGS_OPTION_NAME: 'Por descripción solamente.',

  //Conceptos
  CONFIG_CONCEPTS_EMPTY_STATE_TITLE: 'Comenzá la carga de tus conceptos',
  CONFIG_CONCEPTS_UPLOAD_MODAL_TITLE: 'Cargar conceptos',
  CONFIG_CONCEPTS_DIMENSIONS_EMPTY_STATE_TITLE:
    'Comenzá la carga de las dimensiones del concepto',
  CONFIG_CONCEPTS_EMPTY_DIENSIONS_TITLE:
    'Aún no hay otras dimensiones para asociar',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_ACTION: 'Asociar dimensión',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_MODAL_TITLE:
    'Asociar dimensión a concepto',
  CONFIG_CONCEPT_ASSOCIATE_TO_DIMENSION_FIELD: 'Dimensión/es a asociar',
  CONFIG_CONCEPT_ASSOCIATE_MODAL_SELECT_LABEL:
    'Seleccioná la/las dimensiones que deseas utilizar para presupuestar',
  CONFIG_CONCEPT_ASSOCIATE_REMOVE_TITLE:
    '¿Estás seguro que deseas quitar la dimensión "{{name}}"?',
  CONFIG_FIELD_ECONOMIC_CONCEPTS: 'Conceptos económicos',
  CONFIG_FIELD_FINANCIAL_CONCEPTS: 'Conceptos financieros',

  //Dimensiones
  CONFIG_DIMENSIONS_EMPTY_STATE_TITLE:
    'Comenzá la carga de los valores de dimensión',
  CONFIG_DIMENSION_VALUES_UPLOAD_MODAL_TITLE: 'Cargar valores de dimensión',
  CONFIG_DIMENSIONS_EMPTY_STATE_CREATE_ACTION: 'Crear dimensión',
  CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE:
    'Las acciones que realices sobre las dimensiones asociadas no impactarán sobre los períodos anteriores al vigente',
  CONFIG_DIMENSIONS_POPCONFIRM_DELETE_DIMENSION_TITLE:
    '¿Estás seguro que deseas eliminar esta dimensión?',
  CONFIG_DIMENSIONS_INPUT_NAME_PLACEHOLDER: 'Ej. Sucursal',
  CONFIG_DIMENSIONS_DELETE_ACTION: 'Eliminar dimensión',
  CONFIG_DIMENSIONS_ADD_NEW_ACTION: 'Añadir otra dimensión',
  CONFIG_DIMENSIONS_VALUES_POPCONFIRM_DELETE_VALUE_TITLE:
    '¿Estás seguro que deseas eliminar este valor de dimensión?',
  CONFIG_DIMENSIONS_VALUES_MODAL_CREATE_NEW_VALUE_TITLE:
    'Crear valor de dimensión',
  CONFIG_DIMENSIONS_VALUES_INPUT_CODE_PLACEHOLDER: 'Ej. LP',
  CONFIG_DIMENSIONS_VALUES_INPUT_NAME_PLACEHOLDER: 'Ej. La Plata',
  CONFIG_DIMENSIONS_VALUES_DELETE_ACTION: 'Eliminar valor de dimensión',
  CONFIG_DIMENSIONS_VALUES_ADD_NEW_ACTION: 'Añadir otro valor de dimensión',
  CONFIG_DIMENSIONS_VALUES_EMPTY_STATE_TITLE: '¡Aún no hay valores cargados!',
  CONFIG_DIMENSIONS_NOT_ALL_EDIT_SUCCESS_FEEDBACK:
    'No todos los valores se editaron correctamente',

  //Plan de cuentas
  CONFIG_ACCOUNTS_EMPTY_STATE_TITLE: 'Comenzá la carga del plan de cuentas',
  CONFIG_ACCOUNTS_UPLOAD_MODAL_TITLE: 'Cargar plan de cuentas',
  CONFIG_ACCOUNTS_CHOOSE_OPTION_MODAL_NAME: 'Nivel (Rubros o subrubros)',
  CONFIG_ACCOUNTS_CREATE_LEVEL_ACTION: 'Crear nivel',
  CONFIG_ACCOUNTS_EMPTY_STATE_DESCRIPTION:
    'Sabemos que el plan único de cuentas es indispensable para el registro de operaciones contables. Incluí todas las cuentas que utiliza tu contabilidad para registrar las operaciones, clasificadas por su naturaleza.',
  CONFIG_ACCOUNTS_INFO_TOOLTIP_TITLE:
    'Las acciones que realices sobre los niveles no impactarán sobre los períodos anteriores al vigente',
  CONFIG_ACCOUNTS_CONFIRM_DELETE_LEVEL_TITLE:
    '¿Estás seguro que deseas eliminar este nivel?',
  CONFIG_ACCOUNTS_INFORMATIVE_MODAL_TITLE:
    'La carga de tu plan de cuentas está compuesta por dos elementos',
  CONFIG_ACCOUNTS_INFORMATIVE_MODAL_ITEM_ONE:
    '1. Niveles: Rubros y sub-rubros del plan de cuentas que van a indicar la clasificación, naturaleza y ordenamiento de las cuentas.',
  CONFIG_ACCOUNTS_INFORMATIVE_MODAL_ITEM_TWO:
    '2. Cuentas: Las cuentas contables que vas a usar para todas las transacciones en Plika, indicando en qué nivel de los anteriores están agrupadas.',
  CONFIG_ACCOUNTS_CHOOSE_OPTION_MODAL_TITLE:
    'Elegí el elemento qué deseas crear',
  CONFIG_ACCOUNTS_CODE_INPUT_LEVEL_PLACEHOLDER: 'Ej. 1',
  CONFIG_ACCOUNTS_NAME_INPUT_LEVEL_PLACEHOLDEL: 'Ej. Activo',
  CONFIG_ACCOUNTS_ADD_NEW_LEVEL_ACTION: 'Añadir otro nivel',
  CONFIG_ACCOUNTS_DELETE_LEVEL_ACTION: 'Eliminar nivel',
  CONFIG_ACCOUNTS_LEVEL_PARENT_LABEL: 'Nivel padre',
  CONFIG_ACCOUNTS_CREATE_NEW_ACCOUNT: 'Crear cuenta',
  CONFIG_ACCOUNTS_CODE_INPUT_ACCOUNT_PLACEHOLDER: 'Ej. 1.1.01.01.001',
  CONFIG_ACCOUNTS_NAME_INPUT_ACCOUNT_PLACEHOLDER: 'Ej. Caja',
  CONFIG_ACCOUNTS_DELETE_ACCOUNT_ACTION: 'Eliminar cuenta',
  CONFIG_ACCOUNTS_ADD_NEW_ACCOUNT_ACTION: 'Añadir otra cuenta',
  CONFIG_ACCOUNTS_ASSOCIATION_ALERT_DESCRIPTION:
    'Recuerde que debe asociar las cuentas con sus respectivos conceptos. Para eso descargue la plantilla que disponibilizamos a continuación y suba el archivo con los cambios.',
  CONFIG_ACCOUNTS_EDIT_INFORMATIVE_TOOLTIP_TITLE:
    'Solo se puede acceder a la edición de concepto si estás sobre una fila de tipo Cuenta.',

  //Reportes
  CONFIG_REPORTS_EMPTY_STATE_TITLE: '¡Creá tu primer reporte!',
  CONFIG_REPORT_DETAIL_EMPTY_STATE_TITLE: 'Comenzá la carga del reporte',
  CONFIG_REPORTS_GROUPING_FIELD: 'Líneas agrupadoras',
  CONFIG_REPORTS_TOTALIZING_FILED: 'Líneas totalizadoras',
  CONFIG_REPORT_STRUCTURE_FIELD: 'Estructura',
  CONFIG_REPORT_DELETE_TITLE: '¿Estás seguro que deseas eliminar este reporte?',
  CONFIG_REPORT_UPLOAD_ACTION: 'Cargar reporte',
  CONFIG_REPORT_CREATE_ACTION: 'Crear reporte',
  CONFIG_REPORT_CREATE_MODAL_TEXT:
    'Es necesario que le coloques un nombre a tu reporte, podrás editar el mismo las veces que quieras.',
  CONFIG_REPORT_CREATE_MODAL_NAME_LABEL:
    'Ingresá un nombre para el reporte que deseas crear',
  CONFIG_REPORT_CREATE_MODAL_NAME_PLACEHOLDER: 'Ej. EERR - Reporte Directorio',

  //otros reportes
  CONFIG_OTHER_REPORT_CREATE_MODAL_NAME_PLACEHOLDER: 'Ej. Ventas por oficina',
  CONFIG_OTHER_REPORT_SELECT_REPORT_LABEL: 'Elegí un reporte',
  CONFIG_OTHER_REPORT_SELECT_REPORT_TOOLTIP:
    'Insertar descripción/explicación acá',
  CONFIG_OTHER_REPORT_SELECT_DIMENSION_LABEL:
    'Elegí la dimensión por la que deseas visualizar tu reporte',

  //Usuarios
  CONFIG_USERS_EMPTY_STATE_TITLE: 'Comenzá la creación de los usuarios',
  CONFIG_USERS_UPLOAD_MODAL_TITLE: 'Cargar usuarios',
  CONFIG_USER_COMPANY_POSITION_FIELD: 'Cargo en la empresa',
  CONFIG_USERS_TOOLTIP_INFO_TITLE:
    'Las acciones que realices sobre los usuarios no impactarán sobre los períodos anteriores al vigente',
  CONFIG_USERS_POPCOFIRM_DELETE_USER_TITLE:
    '¿Estás seguro que deseas eliminar este usuario?',
  CONFIG_USERS_MODAL_CREATE_USER_TITLE: 'Crear usuario',
  CONFIG_USERS_DELET_USER_ACTION: 'Eliminar usuario',
  CONFIG_USERS_ADD_USER_ACTION: 'Añadir otro usuario',

  //Variables
  CONFIG_VAR_CREATE_MODAL_TITLE: 'Crear variable ',
  CONFIG_VAR_CREATE_MODAL_NAME_PLACEHOLDER: 'Ej. Inflación',
  CONFIG_VAR_CREATE_MODAL_VALUE_TYPE_PLACEHOLDER: 'Ej. Porcentual',
  CONFIG_VAR_CREATE_MODAL_BEHAVIOR_PLACEHOLDER: 'Ej. dato * ( 1 + variable )',
  CONFIG_VAR_CREATE_MODAL_ACCOUNTS_PLACEHOLDER: 'Ej. Cuenta 10001',
  CONFIG_VAR_UPLOAD_MODAL_TITLE: 'Cargar variables',
  CONFIG_VAR_DELETE_CONFIRM_TOOLTIP:
    '¿Estás seguro que deseas eliminar la variable {{name}}?',
  CONFIG_VAR_ACCOUNTS_NOT_SELECTED_PLACEHOLDER: 'No hay cuentas seleccionadas', //NOTE: Revisar bien cuando este disponible la opcion de editar seleccion de cuenta en el subdrawer
  CONFIG_VAR_DIMENSIONS_INFORMATIVE_ALERT_DESCRIPTION:
    'Todos los cambios que se realicen impactarán en los datos donde la variable se encuentre aplicada.',
  CONFIG_VAR_CREATE_ACTION: 'Crear variable',

  // Reglas de negocio
  CONFIG_FORMULA_FIELD_NAME_TABLE: 'Nombre regla de negocio',
  CONFIG_FORMULAS_EMPTY_SCREEN_TITLE:
    'Comenzá la carga de tus reglas de negocio',
  CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION:
    'Crea tus reglas de negocio para obtener importes con los que calcular tus presupuestos',
  CONFIG_FACTOR_EMPTY_SCREEN_TITLE:
    '¡Aún no tienes factores cargados en esta regla!',
  CONFIG_FACTOR_EMPTY_SCREEN_DESCRIPTION:
    'Crea factores con sus comportamientos para esta regla de negocios ',
  CONFIG_FACTOR_CREATE_FEEDBACK_SUCCESS:
    'Creaste tu regla de negocio con éxito! ya puedes visualizarla en la tabla, crear nuevas reglas y nuevos factores.',
  CONFIG_FORMULAS_INFORMATIVE_TOOLTIP: 'Aún no tienes factores cargados',
  CONFIG_FORMULAS_CREATE_ACTION: 'Crear regla de negocio',
  CONFIG_FORMULAS_SAVE_ACTION: 'Guardar regla',
  CONFIG_FORMULAS_CONFIRM_ACTION_POPCONFIRM_TITLE:
    '¿Desea borrar los datos calculados actualmente? ¿O prefiere conservarlos?',
  CONFIG_FACTOR_CONFIRM_ACTION_MODAL_DESCRIPTION:
    'Estás a punto de eliminar el factor {{factorName}}. Ten en cuenta que esto impactará en el cálculo y sus resultados.',
  CONFIG_FACTOR_CREATE_MODAL_PLACEHOLDER: 'Ej. Cantidad Inicial',
  CONFIG_FACTOR_ADD_NEW_ACTION: 'Añadir otro factor',
  CONFIG_FACTOR_DELETE_ACTION: 'Eliminar factor',
  CONFIG_FACTOR_CREATE_ACTION: 'Crear factor',
  CONFIG_FACTOR_POPCONFIRM_DELETE_TITLE:
    '¿Estás seguro que deseas eliminar este factor?',
  CONFIG_FACTOR_DELETE_FEEDBACK_SUCCESS: 'El factor se eliminó correctamente',
  CONFIG_FORMULAS_ADD_FACTORS:
    'Crear factor(es) para regla de negocio {{name}}',
  CONFIG_FORMULAS_CREATE_MODAL_PLACEHOLDER: 'Ej: Ingreso por Ventas',
  CONFIG_FORMULAS_POPCONFIRM_DELETE_TITLE:
    '¿Estás seguro que deseas eliminar esta regla de negocio?',
  CONFIG_FORMULAS_DELETE_FEEDBACK_SUCCESS:
    'La regla de negocio se eliminó correctamente',
  CONFIG_FORMULAS_MODAL_INACTIVE_CONFIRM_TEXT:
    '¿Deseas inactivar de todas formas?',
  CONFIG_FORMULA_NAME: 'Regla de negocio {{name}}',
  CONFIG_FORMULAS_CONFIRM_ACTION_MODAL_DESCRIPTION:
    'Esta regla posee datos cargados.',
  CONFIG_VAR_DIMENSIONS_NOT_SELECTED_PLACEHOLDER:
    'No hay dimensiones seleccionadas',
  CONFIG_VAR_EDIT_DIMENSIONS_ACTION: 'Editar valores',
  CONFIG_VAR_EDIT_DIMENSIONS_MODAL_TITLE: 'Editar dimensión',
  CONFIG_REPLICATE_FACTOR_TITLE: 'Replicar factor',
  CONFIG_REPLICATE_FACTOR_LABEL: 'Factor de destino',

  //Roles
  CONFIG_ROLES_EMPTY_STATE_DESCRIPTION:
    'Aún no tenes cargado roles para tus usuarios, comienza a configurarlos',
  CONFIG_ROLES_CREATE_ACTION: 'Crear rol',
  CONFIG_ROLES_DUPLICATE_ACTION: 'Duplicar',
  CONFIG_ROLES_VIEW_TITLE: 'Ver rol',
  CONFIG_ROLES_MODAL_CREATE_ROLE_TITLE: 'Crear rol nuevo',
  CONFIG_ROLES_MODAL_DUPLICATE_ROLE_TITLE: 'Duplicar rol',
  CONFIG_ROLES_DRAWER_ROLE_OPERATIONS_TITLE: 'Seguridad del rol: {{name}}',
  CONFIG_ROLES_DRAWER_ROLE_DIMENSIONS_TITLE: 'Detalle: {{name}}',
  CONFIG_ROLES_POPCONFIRM_DELETE_ROLE_TITLE:
    '¿Estás seguro que deseas eliminar este rol?',
  CONFIG_ROLES_REPLY_SUBMODULES: 'Replicar en submódulos',
  CONFIG_ROLES_REPLY_SUBMODULES_TOOLTIP:
    'La configuración de dimensiones se replicará en los submódulos',
  CONFIG_ROLES_ACTION_MODAL_NAME_PLACEHOLDER: 'Ej: Responsable Ventas',
  CONFIG_ROLES_ACTION_MODAL_CODE_PLACEHOLDER: 'Ej: RV',
  CONFIG_ROLES_LABEL_COPY: 'copia',

  //onboarding
  CONFIG_ONBOARDING_STEP_DESCRIPTION_SECURITY:
    'Crea roles y permisos para luego asignarlos a los usuarios que crees para tu equipo.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PLAN_ACCOUNT:
    'Crea y carga información para tu plan de cuentas, niveles para la estructuta y cuentas para ser anidadas en los disntitnos niveles.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_CONCEPT:
    ' Acá encontrás conceptos de ventas y gastos para organizar y agrupar tu información.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_DIMENSIONS:
    'Crea de manera personalizada dimensiones y sus valores para clasificar, ordenar o agrupar información, las mismas serán asoaciadas a los conceptos.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PROJECTIONS_ELEMENTS:
    'Crea variables y reglas de negocios para cargar datos, aplicar cálculos y simular escenarios en el módulo de planificación.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_REPORT:
    'Visualiza y crea tus reportes. Crea la estructura de tu estado de resultados y arma reportes personalizados aun más detallados.',
  CONFIG_ONBOARDING_SKIP_TOOLTIP_TITLE:
    'Si omitis podrás volver a ver el recorrido haciendo click en el signo ? de ayuda -> Ver onboarding',

  //moneda
  CONFIG_CURRENCY_EMPTY_SCREEN_TITLE: '¡Aún no tienes monedas creadas!',
  CONFIG_CURRENCY_EMPTY_SCREEN_DESCRIPTION:
    'Crea Monedas de Origen y Funcionales. Las monedas de origen serán en las que puedas cargar tus valores en Plika, las monedas funcionales en las que verás reflejados los mismos.',
  CONFIG_CURRENCY_HEADER_FUNCTIONAL: 'Moneda funcional',
  CONFIG_CURRENCY_HEADER_ORIGIN: 'Moneda de origen',
  CONFIG_CURRENCY_ACTIVATE: 'Activar moneda',
  CONFIG_CURRENCY_DEACTIVATE: 'Desactivar moneda',
  CONFIG_CURRENCY_DEACTIVATE_CURRENCY_POPCONFIRM_TEXT:
    '¿Estás seguro de {{action}} esta moneda?',
  CONFIG_CURRENCY_ACTION_ACTIVATE: 'Activar',
  CONFIG_CURRENCY_ACTION_DEACTIVATE: 'Desactivar',
  CONFIG_CURRENCY_DELETE_ACTION: 'Eliminar moneda',
  CONFIG_CURRENCY_ADD_ACTION: 'Añadir otra moneda',
  CONFIG_CURRENCY_CREATE_ACTION: 'Crear Moneda',
  CONFIG_CURRENCY_CREATE_MODAL_TITLE: 'Crear moneda nueva',
  CONFIG_CURRENCY_CREATE_FEEDBACK_FAIL_DESCRIPTION: `Los siguientes codigos no se crearon porque ya existen: {{currencies}}`,
  CONFIG_CURRENCY_FIXBUTTON_TITLE: 'Fijar moneda',
  CONFIG_CURRENCY_POPCONFIRM_OKTEXT: 'Fijar',
  CONFIG_CURRENCY_FEEDBACK_SUCCESS: 'Moneda {{action}} correctamente',
  CONFIG_CURRENCY_FEEDBACK_FAIL: 'No se pudo {{action}} la moneda',
  CONFIG_CURRENCY_FEEDBACK_ERROR: 'Error',
  CONFIG_CURRENCY_ACTION_ACTIVATED: 'Activada',
  CONFIG_CURRENCY_ACTION_DEACTIVATED: 'Desactivada',
  CONFIG_CURRENCY_ACTION_FIXED: 'Fijada',
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_1:
    'Estás por fijar la moneda con código {{code}} como moneda funcional de toda la herramienta de forma definitiva. Esto quiere decir que si lo haces, no vas a poder deshacer la acción.',
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_2:
    ' ¿Estás seguro que deseas fijar esta moneda?',

  //mockData listado monedas
  CONFIG_CURRENCY_USD: 'Dólar Estadounidense',
  CONFIG_CURRENCY_EUR: 'Euro',
  CONFIG_CURRENCY_ARS: 'Peso Argentino',
  CONFIG_CURRENCY_CLP: 'Peso Chileno',
  CONFIG_CURRENCY_COP: 'Peso Colombiano',
  CONFIG_CURRENCY_MXN: 'Peso Mexicano',
  CONFIG_CURRENCY_UYU: 'Peso Uruguayo',
  CONFIG_CURRENCY_PEN: 'Sol Peruano',
};

export default configuration;
