import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ArrowRightOutlined,
  CommentOutlined,
  DollarCircleOutlined,
  SwapOutlined,
  UndoOutlined,
  CalculatorOutlined,
} from '@ant-design/icons';
import { DropdownMenu } from 'modules/core/components';
import _ from 'lodash';

const DEFAULT_ACTIONS = { visible: false, onClick: null, disabled: false };

const TableContextMenu = ({
  visible = true,
  comment = DEFAULT_ACTIONS,
  undo = DEFAULT_ACTIONS,
  adjustment = DEFAULT_ACTIONS,
  distribution = DEFAULT_ACTIONS,
  replicateValue = DEFAULT_ACTIONS,
  calculateValue = DEFAULT_ACTIONS,
  children,
}) => {
  const { t } = useTranslation();

  const hasOptions =
    !comment.visible &&
    !undo.visible &&
    !adjustment.visible &&
    !distribution.visible &&
    !replicateValue.visible &&
    !calculateValue.visible;

  if (!visible || hasOptions) {
    return children;
  }

  const renderUndo = () => {
    if (undo.visible) {
      return [
        {
          title: t('ACTION_UNDO'),
          icon: <UndoOutlined />,
          onClick: undo.onClick,
          disabled: undo.disabled,
        },
      ];
    }
    return [];
  };

  const renderComment = () => {
    if (comment.visible) {
      return [
        {
          title: t('ACTION_COMMENT'),
          icon: <CommentOutlined />,
          onClick: comment.onClick,
        },
      ];
    }
    return [];
  };

  const renderReplicateValue = () => {
    if (replicateValue.visible) {
      return [
        {
          title: t('ACTION_REPLICATE_VALUE'),
          icon: <ArrowRightOutlined />,
          onClick: replicateValue.onClick,
          disabled: replicateValue.disabled,
        },
      ];
    }
    return [];
  };

  const renderAdjustment = () => {
    if (adjustment.visible) {
      return [
        {
          title: t('ACTION_ADJUST'),
          icon: <DollarCircleOutlined />,
          onClick: adjustment.onClick,
          disabled: adjustment.disabled,
        },
      ];
    }
    return [];
  };

  const renderDistribution = () => {
    if (distribution.visible) {
      return [
        {
          title: t('ACTION_DISTRIBUTE'),
          icon: <SwapOutlined />,
          onClick: distribution.onClick,
          disabled: distribution.disabled,
        },
      ];
    }
    return [];
  };

  const renderCalculateValue = () => {
    if (calculateValue.visible) {
      return [
        {
          title: t('ACTION_CALCULATE_VALUE'),
          icon: <CalculatorOutlined />,
          onClick: calculateValue.onClick,
          disabled: calculateValue.disabled,
        },
      ];
    }
    return [];
  };

  return (
    <DropdownMenu
      trigger={['contextMenu']}
      menu={_.compact([
        ...renderUndo(),
        ...renderComment(),
        ...renderReplicateValue(),
        ...renderAdjustment(),
        ...renderDistribution(),
        ...renderCalculateValue(),
      ])}
    >
      {children}
    </DropdownMenu>
  );
};

export default connect(null, null)(TableContextMenu);
