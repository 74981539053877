import React, { useState, useEffect } from 'react';
import {
  Form,
  Modal,
  Input,
  DatePicker,
  Divider,
  notification,
  Select,
} from 'antd';
import { generateSelectOptions } from 'modules/core/utils';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import planning from 'modules/planning';
import home from 'modules/home';

const FormItem = Form.Item;

const ConnectIntegrationModal = ({
  visible,
  onCancel,
  integrationData,
  fetchPeriodList,
  periodsList,
  setShowScreenSuccess,
  createConfigurationOdoo,
}) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    fetchPeriodList();
  }, [fetchPeriodList]);

  const onClose = () => {
    form.resetFields();
    onCancel();
    setIsConnecting(false);
  };

  const handleValidateDataConnect = () => {
    form
      .validateFields()
      .then(values => {
        setIsConnecting(true);
        const data = {
          ...values,
          ...(periodsList.length === 0 && {
            anio_period: moment(values.period).format('YYYY'),
            period: null,
          }),
          integration_id: integrationData.id,
        };
        createConfigurationOdoo(data)
          .then(() => {
            setShowScreenSuccess();
            onClose();
          })
          .catch(error => {
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: error.response
                ? error.response
                : t('INTEGRATIONS_FEEDBACK_IMPORT_ERROR_DESCRIPTION'),
              duration: 0,
            });
            onClose();
          });
      })
      .catch(e => {});
  };

  return (
    <Modal
      title={t('INTEGRATION_DATA_INTEGRATION_MODAL_TITLE', {
        name: integrationData.name,
      })}
      visible={visible}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      forceRender
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancel}
      okText={t('ACTION_CONNECT')}
      onOk={handleValidateDataConnect}
      okButtonProps={{ loading: isConnecting, disabled: isConnecting }}
      cancelButtonProps={{ disabled: isConnecting }}
    >
      <Form form={form} layout="vertical">
        <FormItem
          label={t('LABEL_USER')}
          name="username"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Input placeholder={t('INTEGRATION_ENTER_USER_INPUT_PLACEHOLDER')} />
        </FormItem>
        <FormItem
          label={t('LOGIN_FORM_INPUT_PASSWORD_PLACEHOLDER')}
          name="password"
          rules={[
            {
              required: true,
              message: t('REQUIRED_FIELD'),
            },
          ]}
        >
          <Input.Password
            placeholder={t('INTEGRATION_ENTER_PASSWORD_INPUT_PLACEHOLDER')}
          />
        </FormItem>
        <Divider orientation="left" orientationmargin="0" plain>
          {t('INTEGRATION_CONNECTION_DATA_DIVIDER_TEXT')}
        </Divider>
        <FormItem
          label={t('INTEGRATION_URL_FORMITEM_LABEL')}
          name="url"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Input placeholder={t('INTEGRATION_ENTER_URL_INPUT_PLACEHOLDER')} />
        </FormItem>
        <FormItem
          label={t('INTEGRATION_DATABASE_NAME_FORMITEM_LABEL')}
          name="dbname"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Input
            placeholder={t('INTEGRATION_ENTER_DATABASE_INPUT_PLACEHOLDER')}
          />
        </FormItem>
        <FormItem
          label={t('INTEGRATION_PORT_FORMITEM_LABEL')}
          name="port"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Input placeholder={t('INTEGRATION_ENTER_PORT_INPUT_PLACEHOLDER')} />
        </FormItem>
        <FormItem
          label={t('LABEL_PERIOD')}
          name="period"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          {periodsList.length > 0 ? (
            <Select
              placeholder={t('INTEGRATION_SELECT_PERIOD_INPUT_PLACEHOLDER')}
              style={{ width: '100%' }}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={generateSelectOptions({ options: periodsList })}
            />
          ) : (
            <DatePicker
              style={{ width: '100%' }}
              picker="year"
              placeholder={t('INTEGRATION_SELECT_PERIOD_INPUT_PLACEHOLDER')}
            />
          )}
        </FormItem>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  periodsList: planning.selectors.getPeriods(state),
});

const mapDispatchToProps = {
  fetchPeriodList: planning.actions.fetchPeriods,
  createConfigurationOdoo: home.actions.createConfigurationOdoo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectIntegrationModal);
