import React from 'react';
import { Table } from 'antd';
import { columnsList } from 'modules/configuration/containers/Concepts/components';
import { useHistory } from 'react-router-dom';
import { CONCEPTS } from 'modules/core/constants';

const ConceptsTable = ({ dataSource, tableLoading }) => {
  let history = useHistory();

  return (
    <Table
      rowKey="id"
      bordered
      size="middle"
      loading={tableLoading}
      columns={columnsList({
        history,
        conceptType: CONCEPTS.TYPE.ECONOMIC_KEY,
      })}
      dataSource={dataSource}
      pagination={{
        pageSize: 10,
        size: 'small',
        pageSizeOptions: ['10', '20', '30'],
        showSizeChanger: true,
      }}
    />
  );
};

export default ConceptsTable;
