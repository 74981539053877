import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Row, Col, Space } from 'antd';
import { PeriodNavigator, Typography } from 'modules/core/components';
import { LoadAndFormatDataModal } from './components';
import { SiderLayout } from 'modules/core/layouts';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { renderButtonsByClients } from './components/ButtonsByClients/ButtonsByClients';
import { REPORT } from 'modules/core/constants';
import {
  getDashboard,
  handleUpdateDataOther,
  handleUpdateDataSICA,
  handleUpdateDataZarcam,
} from './utils';
import emptyImage from 'assets/images/compositions/empty-big.svg';
import planning from 'modules/planning';
import login from 'modules/login';
import _ from 'lodash';

import './ReportDashboards.scss';

const INITIAL_STATE = {
  typeList: [],
  okText: '',
  title: '',
  onAction: null,
  onRefetch: null,
};

const ReportDashboards = ({
  fetchDashboardUrl,
  dashboardUrl,
  loggedUser: { society },
  updateDataSICA,
  printReportsSICA,
  uploadDataSICA,
  updateDataOther,
  updateDataZarcam,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataDashboard, setDataDashboard] = useState();
  const [showLoadDataModal, setShowLoadDataModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [dataModal, setDataModal] = useState(INITIAL_STATE);
  const [error, setError] = useState(null);

  let { periodId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    fetchDashboardUrl()
      .then(() => setIsLoading(false))
      .catch(error => {
        setError(error.response.data);
        setIsLoading(false);
      });
  }, [fetchDashboardUrl]);

  useEffect(() => {
    if (!isLoading && !_.isEmpty(dashboardUrl)) {
      const dashboard = getDashboard(
        'embeddingContainer',
        dashboardUrl.EmbedUrl
      );
      setDataDashboard(dashboard);
    }
  }, [dashboardUrl, isLoading]);

  const dataSica = {
    onClickButtonUpdate: () =>
      handleUpdateDataSICA({
        setIsLoading: setIsUpdating,
        updateDataSICA,
        onRefetch: () => fetchDashboardUrl(),
      }),
    onClickButtonUpload: () => {
      setDataModal({
        typeList: REPORT.QUICKSIGHT_TYPES_LIST.LOAD,
        okText: 'ACTION_UPLOAD',
        title: 'ACTION_UPLOAD_DATA',
        onAction: uploadDataSICA,
        onRefetch: () => fetchDashboardUrl(),
      });
      setShowLoadDataModal(true);
    },
    onClickButtonPrint: () => {
      setDataModal({
        typeList: REPORT.QUICKSIGHT_TYPES_LIST.FORMAT,
        okText: 'ACTION_FORMAT',
        title: 'ACTION_PRINT_DATA',
        onAction: printReportsSICA,
      });
      setShowLoadDataModal(true);
    },
  };

  const dataZarcam = {
    onClickButtonUpdate: () =>
      handleUpdateDataZarcam({
        setIsLoading: setIsUpdating,
        updateDataZarcam,
        onRefetch: () => fetchDashboardUrl(),
      }),
  };

  const dataOther = {
    onClickButtonUpdate: () =>
      handleUpdateDataOther({
        setIsLoading: setIsUpdating,
        updateDataOther,
        onRefetch: () => fetchDashboardUrl(),
      }),
  };

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        { name: t('LABEL_ANALYSIS'), url: `/analisis/${periodId}` },
        { name: t('REPORT_SELF_SERVICE_FIELD') },
      ]}
    />
  );

  const renderNotPermissionScreen = () => (
    <Space className="not-permission-screen" direction="vertical" size="large">
      <img alt="not-permission-screen" src={emptyImage} />
      <div>
        <Typography.Headline level={5} type="title">
          {error}
        </Typography.Headline>
      </div>
    </Space>
  );

  return (
    <SiderLayout className="self-service-content">
      {renderHeader()}
      <Spin spinning={isLoading || isUpdating}>
        {!isLoading && !error && !_.isEmpty(dashboardUrl) && (
          <div className="dashboard-container">
            <Row gutter={[8, 8]}>
              <Col span={24}>
                {
                  renderButtonsByClients({ dataSica, dataZarcam, dataOther })[
                    society
                  ]
                }
              </Col>
              <Col span={24}>
                <div id="embeddingContainer"></div>
              </Col>
            </Row>
            <LoadAndFormatDataModal
              visible={showLoadDataModal}
              onCancel={() => {
                setShowLoadDataModal(false);
                setDataModal(INITIAL_STATE);
              }}
              dataModal={dataModal}
              setDataModal={() => setDataModal(INITIAL_STATE)}
            />
          </div>
        )}
        {!isLoading && error && renderNotPermissionScreen()}
      </Spin>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  dashboardUrl: planning.selectors.getDashboardUrl(state),
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  fetchDashboardUrl: planning.actions.fetchDashboardUrl,
  updateDataSICA: planning.actions.updateDataSICA,
  updateDataOther: planning.actions.updateDataOther,
  printReportsSICA: planning.actions.printReportsSICA,
  uploadDataSICA: planning.actions.uploadDataSICA,
  updateDataZarcam: planning.actions.updateDataZarcam,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboards);
