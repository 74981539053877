import i18n from 'i18next';
import { Button } from 'antd';
import { Typography } from 'modules/core/components';
import { EyeOutlined } from '@ant-design/icons';

const AUX_CONCEPTS_TYPE = {
  economic: 'economicos',
  financial: 'financieros',
};

const columns = ({ history, conceptType }) => [
  {
    title: i18n.t('FIELD_NAME'),
    dataIndex: 'name',
    width: 500,
  },
  {
    title: i18n.t('FIELD_DIMENSIONS_AMOUNT'),
    dataIndex: 'configs',
    width: 300,
    render: dimensionsAmount => dimensionsAmount.length,
  },
  {
    title: i18n.t('FIELD_ACTIONS'),
    dataIndex: 'id',
    align: 'right',
    width: 50,
    render: id => (
      <Button
        type="text"
        shape="circle"
        icon={<Typography.Icon icon={EyeOutlined} />}
        title={i18n.t('ACTION_OPEN_DETAIL')}
        onClick={() =>
          history.push(
            `/configuracion/misdatos/conceptos/${AUX_CONCEPTS_TYPE[conceptType]}/${id}`
          )
        }
      />
    ),
  },
];

export default columns;
