import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Spin } from 'antd';
import { Navigator, UploadDraggerScreen } from 'modules/core/components';
import { SiderLayout } from 'modules/core/layouts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES, ROWS, INTEGRATIONS, CONCEPTS } from 'modules/core/constants';
import configuration from 'modules/configuration';

import './OtherReportsDetail.scss';

const { SETTINGS__REPORTS__CUSTOM_REPORTS__CREATE } = ROLES;

//TODO: AGREGAR FUNCION SERCH CUANDO ESTE HECHO DESDE BACK
const OtherReportsDetail = ({
  fetchOtherReportStructure,
  otherReportsStructure,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  let { otherReportId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setIsScreenLoading(true);
    fetchOtherReportStructure(otherReportId).then(() =>
      setIsScreenLoading(false)
    );
  }, [otherReportId, fetchOtherReportStructure]);

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_MY_DATA'),
          url: `/configuracion?tab=${INTEGRATIONS.KEYS.DATA_KEY}`,
        },
        {
          name: t('LABEL_REPORTS'),
          url: `/configuracion/misdatos/reportes?tab=${CONCEPTS.TYPE.ECONOMIC_KEY}`,
        },
        {
          name: t('LABEL_ECONOMIC'),
          url: `/configuracion/misdatos/reportes?tab=${CONCEPTS.TYPE.ECONOMIC_KEY}`,
        },
        {
          name: t('LABEL_CUSTOM_REPORTS'),
        },
        {
          name: otherReportsStructure.report_name
            ? otherReportsStructure.report_name
            : '',
        },
      ]}
    />
  );

  const canUserUpload = isUserAllowed(
    SETTINGS__REPORTS__CUSTOM_REPORTS__CREATE
  );

  //TODO: AJUSTAR CUANDO ESTE LO DE CARGAR ARCHIVO Y DESCARGAR PLANTILLA
  const renderEmptyScreen = () => (
    <UploadDraggerScreen
      disabled={!canUserUpload}
      description={t('CONFIG_REPORT_DETAIL_EMPTY_STATE_TITLE')}
      downloadTemplate={() => console.log('descargar planitlla')}
      //uploadFile={file => uploadReportValuesXLS(file, reportId)}
      //   onSuccessUpload={onSuccessUploadFile}
      //   onFailUpload={onFailUploadFile}
    />
  );

  const renderTable = () => {
    const columns = [
      {
        title: otherReportsStructure?.dimension?.concept,
        dataIndex: 'name',
      },
    ];

    return (
      <Table
        rowKey="id"
        bordered
        size="small"
        loading={isScreenLoading}
        dataSource={otherReportsStructure.rows} //TODO: AGREGAR ESTO CUANDO ESTE LO DEL SEARCH searchValue === null ? values : functionSearch(values, searchValue)
        columns={columns}
        rowClassName={record => ROWS.TYPES[record.type]}
        pagination={false}
        scroll={{ y: 450 }}
      />
    );
  };

  return (
    <SiderLayout>
      <div className="configuration-reports-others-detail-container">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Spin spinning={isScreenLoading} />
          {_.isEmpty(otherReportsStructure) &&
            !isScreenLoading &&
            renderEmptyScreen()}
          {!_.isEmpty(otherReportsStructure) && !isScreenLoading && (
            // TODO: AGREGAR ACCIONES DE USUARIO(DESCARGAR PLANTILLA, CARGAR ARCHIVO, BUSCADOR)
            // TODO: AGREGAR ACCIONES TABLA (DESCARGAR TABLA)
            <Col span={24}>{renderTable()}</Col>
          )}
        </Row>
      </div>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  otherReportsStructure:
    configuration.selectors.getOtherReportsStructure(state),
});

const mapDispatchToProps = {
  fetchOtherReportStructure: configuration.actions.fetchOtherReportStructure,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherReportsDetail);
