// NOTE: Las labels se obtienen de los archivos de traduccion (i18n).
// NOTE: Para el armado de las KEYS se va a utilizar la estructura MODULO/SUBMODULO/OPERACION, concatenandolo con el caracter '/'. Por lo tanto es recomendable que no se utilice '/' en la definicion de alguna KEY.

const KEY_SEPARATOR = '/';
// /\//g
const OBJECT_SEPARATOR_REGEX = new RegExp(KEY_SEPARATOR, 'g');
// NOTE: Fn que reemplaza keys para el objeto
// OUTPUT: MODULE/SUBMODULE/OP >> MODULE__SUBMODULE__OP
const replaceObjectKey = key => key.replace(OBJECT_SEPARATOR_REGEX, '__');

// +++++++++++++++++++++++++++++++++
// +++++++ SETUP OPERACIONES +++++++
// +++++++++++++++++++++++++++++++++

// NOTE: Se variabilizan las KEYs de cada OPERACION y se arman estructuras para ser reutilizadas.

// ++ OPERACIONES ++
const CREATE = { key: 'CREATE', label: 'ACTION_CREATE' };
const EDIT = { key: 'EDIT', label: 'ACTION_EDIT' };
const DUPLICATE = { key: 'DUPLICATE', label: 'ACTION_DUPLICATE' };
const DELETE = { key: 'DELETE', label: 'ACTION_DELETE' };
const VIEW = { key: 'VIEW', label: 'ACTION_VIEW' };
const APPROVE = { key: 'APPROVE', label: 'ACTION_APPROVE' };
const PUBLISH = { key: 'PUBLISH', label: 'ACTION_PUBLISH' };
const APPLY_VARS = { key: 'APPLY_VARS', label: 'LABEL_VARIABLES' };
const UPLOAD = { key: 'UPLOAD', label: 'ACTION_UPLOAD' };
const OP_BUSINESS_RULES = {
  key: 'BUSINESS_RULES',
  label: 'LABEL_BUSINESS_RULES',
};
const AMOUNT = { key: 'AMOUNT', label: 'FIELD_AMOUNT' };
const ACTIVATE = { key: 'ACTIVATE', label: 'CONFIG_CURRENCY_ACTION_ACTIVATE' };
const EXCHANGE_RATE = { key: 'EXCHANGE_RATE', label: 'LABEL_EXCHANGE_RATE' };

const BASE_OPERATION_OPTIONS = [CREATE, EDIT, DELETE];

const BASE_OPERATION_OPTIONS_VIEW = [...BASE_OPERATION_OPTIONS, VIEW];

const BASE_OPERATION_OPTIONS_DUPLICATE = [
  ...BASE_OPERATION_OPTIONS_VIEW,
  DUPLICATE,
];

const APPROVE_PUBLISH = [PUBLISH, APPROVE];

const CURRENCY_OPERATIONS = [CREATE, ACTIVATE, DELETE];

const PLANNING_BASE_OPERATION_OPTIONS = [
  ...BASE_OPERATION_OPTIONS,
  UPLOAD,
  DUPLICATE,
  VIEW,
];

const PLANNING_CONCEPT_OPERATION_OPTIONS = [
  ...PLANNING_BASE_OPERATION_OPTIONS,
  APPLY_VARS,
  EXCHANGE_RATE,
  OP_BUSINESS_RULES,
  AMOUNT,
];

const PLANNING_CONSOLIDATED_OPERATION_OPTIONS = [
  ...BASE_OPERATION_OPTIONS_DUPLICATE,
  APPLY_VARS,
  ...APPROVE_PUBLISH,
];

const CONTROL_BASE_OPERATION_OPTIONS = [DELETE, VIEW];

const CONTROL_BASE_OPERATION_OPTIONS_REAL = [
  UPLOAD,
  ...CONTROL_BASE_OPERATION_OPTIONS,
];

const CONTROL_BASE_OPERATION_OPTIONS_OTHER = [
  CREATE,
  ...CONTROL_BASE_OPERATION_OPTIONS,
  DUPLICATE,
];

const FORECAST_BASE_OPERATIONS_OPTIONS = [
  UPLOAD,
  ...BASE_OPERATION_OPTIONS_VIEW,
  EXCHANGE_RATE,
];

const FORECAST_CONSOLIDATED_OPERATION_OPTIONS = [
  ...BASE_OPERATION_OPTIONS_DUPLICATE,
  ...APPROVE_PUBLISH,
];

const ANALISIS_OPERATIONS_OPTIONS = [CREATE, VIEW];

// +++++++++++++++++++++++++++++
// +++++++ SETUP MODULOS +++++++
// +++++++++++++++++++++++++++++

// NOTE: Se variabilizan las KEYs de cada MODULO y sus correspondientes SUBMODULOS.

// ++ MODULO: SETTINGS ++
const SETTINGS = 'SETTINGS';
const SETTINGS_LABEL = 'LABEL_CONFIG';

const PLAN_ACCOUNTS = 'PLAN_ACCOUNTS';
const PLAN_ACCOUNTS_LABEL = 'LABEL_ACCOUNTS';

const CONCEPTS = 'CONCEPTS';
const CONCEPTS_LABEL = 'LABEL_CONCEPTS';

const DIMENSIONS = 'DIMENSIONS';
const DIMENSIONS_LABEL = 'LABEL_DIMENSIONS';

const PROJECTION_ELEMENTS = 'PROJECTION_ELEMENTS';
const PROJECTION_ELEMENTS_LABEL = 'LABEL_PROJECTION_ELEMENTS';

const BUSINESS_RULES = 'BUSINESS_RULES';
const BUSINESS_RULES_LABEL = 'LABEL_BUSINESS_RULES';

const VARIABLES = 'VARIABLES';
const VARIABLES_LABEL = 'LABEL_VARIABLES';

const CURRENCY = 'CURRENCY';
const CURRENCY_LABEL = 'LABEL_CURRENCY';

// const FINANCIAL_GUIDELINES = 'FINANCIAL_GUIDELINES';
// const FINANCIAL_GUIDELINES_LABEL = 'LABEL_FINANCIAL_GUIDELINES';

const REPORTS = 'REPORTS';
const REPORTS_LABEL = 'LABEL_REPORTS';

const REPORTS_PNL = 'REPORTSPNL';
const REPORTS_PNL_LABEL = 'LABEL_REPORTS';

const CUSTOM_REPORTS = 'CUSTOM_REPORTS';
const CUSTOM_REPORTS_LABEL = 'LABEL_CUSTOM_REPORTS';

const USERS = 'USERS';
const USERS_LABEL = 'LABEL_USERS';

const ROLES = 'ROLES';
const ROLES_LABEL = 'LABEL_ROLES';

const SECURITY = 'SECURITY';
const SECURITY_LABEL = 'LABEL_SECURITY';

const SETTINGS_MODULE = {
  key: SETTINGS,
  label: SETTINGS_LABEL,
  children: [
    {
      key: PLAN_ACCOUNTS,
      label: PLAN_ACCOUNTS_LABEL,
      operations: BASE_OPERATION_OPTIONS_VIEW,
    },
    {
      key: CONCEPTS,
      label: CONCEPTS_LABEL,
      operations: [VIEW],
    },
    {
      key: DIMENSIONS,
      label: DIMENSIONS_LABEL,
      operations: BASE_OPERATION_OPTIONS_VIEW,
    },
    {
      key: PROJECTION_ELEMENTS,
      label: PROJECTION_ELEMENTS_LABEL,
      children: [
        {
          key: BUSINESS_RULES,
          label: BUSINESS_RULES_LABEL,
          operations: BASE_OPERATION_OPTIONS_VIEW,
        },
        {
          key: VARIABLES,
          label: VARIABLES_LABEL,
          operations: BASE_OPERATION_OPTIONS_DUPLICATE,
        },
        {
          key: CURRENCY,
          label: CURRENCY_LABEL,
          operations: CURRENCY_OPERATIONS,
        },
        // {
        //   key: FINANCIAL_GUIDELINES,
        //   label: FINANCIAL_GUIDELINES_LABEL,
        //   operations: BASE_OPERATION_OPTIONS,
        // },
      ],
    },
    {
      key: REPORTS,
      label: REPORTS_LABEL,
      children: [
        {
          key: REPORTS_PNL,
          label: REPORTS_PNL_LABEL,
          operations: BASE_OPERATION_OPTIONS_VIEW,
        },
        {
          key: CUSTOM_REPORTS,
          label: CUSTOM_REPORTS_LABEL,
          operations: BASE_OPERATION_OPTIONS_VIEW,
        },
      ],
    },
    {
      key: SECURITY,
      label: SECURITY_LABEL,
      children: [
        { key: USERS, label: USERS_LABEL, operations: BASE_OPERATION_OPTIONS },
        { key: ROLES, label: ROLES_LABEL, operations: BASE_OPERATION_OPTIONS },
      ],
    },
  ],
};

// ++ MODULO: PLANNING ++
const PLANNING = 'PLANNING';
const PLANNING_LABEL = 'LABEL_PLANNING';

const SALES = 'SALES';
const SALES_LABEL = 'LABEL_SALES';

const EXPENSES = 'EXPENSES';
const EXPENSES_LABEL = 'LABEL_EXPENSES';

const CONSOLIDATED = 'CONSOLIDATED';
const CONSOLIDATED_LABEL = 'LABEL_CONSOLIDATION';

const PLANNING_MODULE = {
  key: PLANNING,
  label: PLANNING_LABEL,
  children: [
    {
      key: SALES,
      label: SALES_LABEL,
      operations: PLANNING_CONCEPT_OPERATION_OPTIONS,
    },
    {
      key: EXPENSES,
      label: EXPENSES_LABEL,
      operations: PLANNING_CONCEPT_OPERATION_OPTIONS,
    },
    {
      key: CONSOLIDATED,
      label: CONSOLIDATED_LABEL,
      operations: PLANNING_CONSOLIDATED_OPERATION_OPTIONS,
    },
  ],
};

// ++ MODULO: CONTROL ++
const CONTROL = 'CONTROL';
const CONTROL_LABEL = 'LABEL_CONTROL';

const REAL = 'REAL';
const REAL_LABEL = 'LABEL_REAL';

const STATUS_CHANGE = 'STATUS_CHANGE';
const STATUS_CHANGE_LABEL = 'LABEL_STATUS_CHANGE';

const ADJUSTMENTS = 'ADJUSTMENTS';
const ADJUSTMENTS_LABEL = 'LABEL_ADJUSTMENTS';

const DISTRIBUTIONS = 'DISTRIBUTIONS';
const DISTRIBUTIONS_LABEL = 'LABEL_DISTRIBUTIONS';

const CONTROL_MODULE = {
  key: CONTROL,
  label: CONTROL_LABEL,
  children: [
    {
      key: REAL,
      label: REAL_LABEL,
      children: [
        {
          key: SALES,
          label: SALES_LABEL,
          operations: CONTROL_BASE_OPERATION_OPTIONS_REAL,
        },
        {
          key: EXPENSES,
          label: EXPENSES_LABEL,
          operations: CONTROL_BASE_OPERATION_OPTIONS_REAL,
        },
        {
          key: STATUS_CHANGE,
          label: STATUS_CHANGE_LABEL,
          operations: APPROVE_PUBLISH,
        },
      ],
    },
    {
      key: ADJUSTMENTS,
      label: ADJUSTMENTS_LABEL,
      operations: CONTROL_BASE_OPERATION_OPTIONS_OTHER,
    },
    {
      key: DISTRIBUTIONS,
      label: DISTRIBUTIONS_LABEL,
      operations: CONTROL_BASE_OPERATION_OPTIONS_OTHER,
    },
  ],
};

// ++ MODULO: FORECAST ++
const FORECAST = 'FORECAST';
const FORECAST_LABEL = 'LABEL_FORECAST';

const FORECAST_MODULE = {
  key: FORECAST,
  label: FORECAST_LABEL,
  // Reutilizo lo de planning
  children: PLANNING_MODULE.children.map(module => {
    return {
      ...module,
      operations:
        module.key === CONSOLIDATED
          ? FORECAST_CONSOLIDATED_OPERATION_OPTIONS
          : FORECAST_BASE_OPERATIONS_OPTIONS,
    };
  }),
};

// ++ MODULO: ANALYSIS ++
const ANALYSIS = 'ANALYSIS';
const ANALYSIS_LABEL = 'LABEL_ANALYSIS';

const ANALYSIS_MODULE = {
  key: ANALYSIS,
  label: ANALYSIS_LABEL,
  children: [
    {
      key: REPORTS_PNL,
      label: REPORTS_PNL_LABEL,
      operations: ANALISIS_OPERATIONS_OPTIONS,
    },
    {
      key: CUSTOM_REPORTS,
      label: CUSTOM_REPORTS_LABEL,
      operations: ANALISIS_OPERATIONS_OPTIONS,
    },
  ],
};

// NOTE: Estructura inicial de la relacion entre los modulos y submodulos.
const MODULES_SKELETON = [
  SETTINGS_MODULE,
  PLANNING_MODULE,
  CONTROL_MODULE,
  FORECAST_MODULE,
  ANALYSIS_MODULE,
];

// NOTE: Se van a ir guardando todas las KEYs de los modulos y de operaciones.
// NOTE: Por otro lado guardando todas las KEYs de operaciones en array. (Contiene la concatenacion del nivel mas bajo)
let MODULES_KEYS = {};
let MODULES_OPERATIONS_LIST = [];

// +++++++++++++++++++++++++++++++++
// +++++++ ARMADO ESTRUCTURA +++++++
// +++++++++++++++++++++++++++++++++

// NOTE: Itero sobre cada elemento de la estructura de modulos para ir generando las KEYS de operaciones en los nodos hojas.
const operationsKeyGenerator = (options, parentKey = null) => {
  return options.map(el => {
    let parentNewKey = parentKey
      ? `${parentKey}${KEY_SEPARATOR}${el.key}`
      : el.key;
    // NOTE: Guardo las KEYS de los modulos y submodulos.
    MODULES_KEYS[replaceObjectKey(parentNewKey)] = parentNewKey;

    if (el.children) {
      return {
        ...el,
        key: parentNewKey,
        children: operationsKeyGenerator(el.children, parentNewKey),
      };
    } else {
      const operationsKeys = el.operations.map(op => {
        let opKey = `${parentNewKey}${KEY_SEPARATOR}${op.key}`;
        // NOTE: Guardo las KEYS de los modulos/submodulos con operaciones
        MODULES_KEYS[replaceObjectKey(opKey)] = opKey;
        return opKey;
      });

      MODULES_OPERATIONS_LIST = [...MODULES_OPERATIONS_LIST, ...operationsKeys];

      return {
        ...el,
        key: parentNewKey,
        operationsKeys: operationsKeys,
        operations: el.operations.map(op => {
          return {
            ...op,
            key: `${parentNewKey}${KEY_SEPARATOR}${op.key}`,
          };
        }),
      };
    }
  });
};

// NOTE: Itero sobre cada modulo padre y agrego las KEYS de operaciones de sus nodos hijos
const addOperationsKeyToParents = options => {
  const filterOperationsByKey = key =>
    MODULES_OPERATIONS_LIST.filter(el => el.includes(key));

  return options.map(el => {
    if (el.children) {
      return {
        ...el,
        operationsKeys: filterOperationsByKey(el.key),
        children: addOperationsKeyToParents(el.children),
      };
    }
    return el;
  });
};

// NOTE: Genero la estructura final de los modulos
const moduleOptionsGenerator = () => {
  const MODULES_SKELETON_KEYS = operationsKeyGenerator(MODULES_SKELETON);
  return addOperationsKeyToParents(MODULES_SKELETON_KEYS);
};

const MODULES_OPTIONS = moduleOptionsGenerator();

const ROLES_INDEX = {
  MODULES_OPTIONS,
  MODULES_OPERATIONS_LIST,
  ...MODULES_KEYS,
};

export default ROLES_INDEX;
