import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select, Form, Col, Row, Input, message } from 'antd';
import configuration from 'modules/configuration';
import { connect } from 'react-redux';
import { AddAndDeleteFormItem } from 'modules/core/components';
import { handleSelectAll, generateSelectOptions } from 'modules/core/utils';
import { DIMENSIONS, PROJECTION } from 'modules/core/constants';

const FormItem = Form.Item;

const INITIAL_STATE_SELECTION = { accounts: false, dimensionValues: false };

// TODO: Sacar las dimensines ya seleccionadas de la lista
const CreateVariableModal = ({
  visible,
  title,
  onCancel,
  onConfirm,
  initialValues = {},
  accountsList: { values: accountsOptions },
  optionsBehaviour,
  onRefreshData,
  dimensionsList,
  fetchValuesDimension,
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const [dimensionId, setDimensionId] = useState(null); //NOTE: Guardo id de dimension para ftechear los valores cada vez que se seleccione una dimension distinta.
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [areAllSelected, setAreAllSelected] = useState(INITIAL_STATE_SELECTION);
  const [dimensionOptions, setDimensionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }
  }, [visible, form, initialValues]);

  useEffect(() => {
    if (dimensionId) {
      setIsLoading(true);
      fetchValuesDimension({
        dimension: dimensionId,
        writeable: 'True',
        page,
      }).then(response => {
        setDimensionOptions([
          ...dimensionOptions,
          ...response.payload.data.results,
        ]);
        if (response.payload.data.next) {
          setPage(page + 1);
        }
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [fetchValuesDimension, dimensionId, page]);

  const onClose = () => {
    form.resetFields();
    setAreAllSelected(INITIAL_STATE_SELECTION);
    onCancel();
  };

  const handleConfirmation = () => {
    form
      .validateFields()
      .then(values => {
        message.loading({
          key: 'loading_creation',
          content: t('FEEDBACK_LOADING_CHANGES'),
        });
        //NOTE: Armo el objeto dataDimensions para obtener la data de la siguiete forma
        // {'idDimension':[idValuesDimension],'idDimension':[idValuesDimension]}.
        const dataDimensions = {};
        values.dimensions &&
          values.dimensions.map(
            dimension =>
              dimension.dimensionValue &&
              Object.assign(dataDimensions, {
                [`${dimension.dimension}`]: dimension.dimensionValue.map(id =>
                  parseInt(id)
                ),
              })
          );

        let dataAccounts = values.accounts.map(id => {
          return parseInt(id);
        });

        const data = {
          name: values.name,
          value_type: values.value_type,
          behaviour: values.behaviour,
          accounts: {
            ...dataDimensions,
            [DIMENSIONS.ACCOUNTS.id]: dataAccounts,
          },
        };

        setIsCreating(true);
        onConfirm(data)
          .then(() => {
            message.destroy('loading_creation');
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
            onRefreshData().then(() => {
              setIsCreating(false);
              setDimensionId(null);
            });
            onClose();
          })
          .catch(error => {
            onClose();
            setIsCreating(false);
            setDimensionId(null);
            message.destroy('loading_creation');
            message.error(t('FEEDBACK_CREATION_FAIL'));
          });
      })
      .catch(e => {});
  };

  const handleSetFormListValues = (values, index) => {
    const fields = form.getFieldsValue();
    const { dimensions } = fields;
    Object.assign(dimensions[index], { dimensionValue: values });
    return form.setFieldsValue({ dimensions });
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={!isCreating && onClose}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={handleConfirmation}
      forceRender
      okButtonProps={{ loading: isCreating }}
      cancelButtonProps={{ disabled: isCreating }}
      bodyStyle={{ overflowY: 'auto', maxHeight: 500 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark={false}
        initialValues={{ dimensions: [''] }}
      >
        <Row gutter={24} type="flex" justify="center">
          <Col span={24}>
            <FormItem
              name="name"
              label={t('FIELD_NAME')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input
                placeholder={t('CONFIG_VAR_CREATE_MODAL_NAME_PLACEHOLDER')}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="fex-start">
          <Col span={12}>
            <FormItem
              name="value_type"
              label={t('FIELD_VALUE_TYPE')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t(
                  'CONFIG_VAR_CREATE_MODAL_VALUE_TYPE_PLACEHOLDER'
                )}
                options={generateSelectOptions({
                  options: PROJECTION.VARIABLES_TYPE_OPTIONS.map(variable => {
                    return { ...variable, name: t(variable.name) };
                  }),
                })}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="behaviour"
              label={t('FIELD_BEHAVIOR')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('CONFIG_VAR_CREATE_MODAL_BEHAVIOR_PLACEHOLDER')}
                options={generateSelectOptions({ options: optionsBehaviour })}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="center">
          <Col span={24}>
            <FormItem
              name="accounts"
              label={t('FIELD_ACCOUNTS_IMPACTED')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('CONFIG_VAR_CREATE_MODAL_ACCOUNTS_PLACEHOLDER')}
                mode="multiple"
                maxTagCount={1}
                showSearch
                showArrow
                onChange={values =>
                  handleSelectAll({
                    values,
                    allValues: accountsOptions
                      .asMutable()
                      .map(account => account.id),
                    areAllSelected: areAllSelected.accounts,
                    onSetValues: values =>
                      form.setFieldsValue({ accounts: values }),
                    onSetAreAllSelected: state =>
                      setAreAllSelected(prevState => {
                        return { ...prevState, accounts: state };
                      }),
                  })
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={generateSelectOptions({
                  options: accountsOptions,
                  includeOptionAll: true,
                })}
              />
            </FormItem>
          </Col>
        </Row>
        <Form.List name="dimensions">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => {
                  return (
                    <Row gutter={24} key={field.key} justify="end">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'dimension']}
                          label={[field.label, t('FIELD_DIMENSION')]}
                        >
                          <Select
                            placeholder={t('ACTION_SELECT')}
                            onChange={id => {
                              setDimensionId(id);
                              // Se limpia el campo de dimensionValue
                              const fields = form.getFieldsValue();
                              const { dimensions } = fields;
                              Object.assign(dimensions[field.name], {
                                dimensionValue: [],
                              });
                              form.setFieldsValue({ dimensions });
                            }}
                            options={generateSelectOptions({
                              options:
                                dimensionsList && dimensionsList.dimensions,
                              selection: selectedDimensions,
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'dimensionValue']}
                          label={[field.label, t('FIELD_DIMENSION_VALUES')]}
                        >
                          <Select
                            placeholder={t('ACTION_SELECT')}
                            mode="multiple"
                            showArrow
                            allowClear
                            showSearch
                            loading={isLoading}
                            maxTagCount={1}
                            maxTagTextLength={12}
                            onChange={values => {
                              handleSelectAll({
                                values,
                                allValues: dimensionOptions
                                  .asMutable()
                                  .map(dimValue => dimValue.id),
                                areAllSelected: areAllSelected.dimensionValues,
                                onSetValues: values =>
                                  handleSetFormListValues(values, index),
                                onSetAreAllSelected: state =>
                                  setAreAllSelected(prevState => {
                                    return {
                                      ...prevState,
                                      dimensionValues: state,
                                    };
                                  }),
                              });
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={
                              !dimensionId ||
                              (form.getFieldValue('dimensions')[field.name] &&
                                dimensionId !==
                                  form.getFieldValue('dimensions')[field.name][
                                    'dimension'
                                  ])
                            }
                            options={generateSelectOptions({
                              options: dimensionOptions,
                              includeOptionAll: true,
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <AddAndDeleteFormItem
                        fieldsLength={fields.length}
                        index={index}
                        disabled={isCreating}
                        addData={{
                          onClick: () => {
                            add();
                            setSelectedDimensions([
                              ...selectedDimensions,
                              dimensionId,
                            ]);
                            setDimensionId(null);
                          },
                          buttonName: 'CONFIG_DIMENSIONS_ADD_NEW_ACTION',
                        }}
                        deleteData={{
                          onClick: () => {
                            const selectedID =
                              form.getFieldValue('dimensions')[field.name] &&
                              form.getFieldValue('dimensions')[field.name][
                                'dimension'
                              ];
                            if (selectedID)
                              setSelectedDimensions(
                                selectedDimensions.filter(
                                  id => id !== selectedID.toString()
                                )
                              );
                            remove(field.name);
                          },
                          buttonName: 'CONFIG_DIMENSIONS_DELETE_ACTION',
                        }}
                      />
                    </Row>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  fetchValuesDimension: configuration.actions.fetchValuesDimension,
};

export default connect(null, mapDispatchToProps)(CreateVariableModal);
