import {
  ButtonActionBar,
  References,
  TableSettingsModal,
} from 'modules/core/components';
import { DollarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { formItemsHelper } from 'modules/core/utils';

const TableActions = ({
  reportFunctionalCurrencyData,
  setSegregatedTableSettings,
  segregatedTableSettings,
}) => {
  const { t } = useTranslation();
  return (
    <ButtonActionBar position="space-between">
      <References
        referencesItems={[
          {
            type: '123',
            title: t('LABEL_REALES'),
            description: t('CONTROL_REAL_DATA'),
          },
          ...(reportFunctionalCurrencyData
            ? [
                {
                  type: <DollarOutlined />,
                  title:
                    reportFunctionalCurrencyData &&
                    reportFunctionalCurrencyData.code,
                  description:
                    reportFunctionalCurrencyData &&
                    t('REFERENCE_DESCRIPTION', {
                      currencyName: t(
                        reportFunctionalCurrencyData.name
                      ).toLowerCase(),
                    }),
                },
              ]
            : []),
        ]}
      />
      <TableSettingsModal
        onConfirm={setSegregatedTableSettings}
        settings={segregatedTableSettings}
        formItems={formItemsHelper.itemColumnView(
          segregatedTableSettings.visualizationColumn
        )}
      />
    </ButtonActionBar>
  );
};

export default TableActions;
