const FACTOR_CODE_DELETE_KEEP_DATA = 1;

const FACTOR_CODE_DELETE_DEFAULT = 0;

const RULE_CODE_DESACTIVATED = 0;

const RULE_CODE_DELETE_CASCADE = 1;

const RULE_CODE_DELETE_UNAPPLY = 2;

const FACTOR = {
  CODES_DELETE: {
    KEEP_DATA: FACTOR_CODE_DELETE_KEEP_DATA,
    DEFAULT: FACTOR_CODE_DELETE_DEFAULT,
  },
};

const RULE = {
  CODES_DELETE: {
    DESACTIVATED: RULE_CODE_DESACTIVATED,
    CASCADE: RULE_CODE_DELETE_CASCADE,
    UNAPPLY: RULE_CODE_DELETE_UNAPPLY,
  },
};

const PROJECTION_ELEMENTS = { FACTOR, RULE };

export default PROJECTION_ELEMENTS;
