import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'modules/core/customHooks';
import { useParams } from 'react-router-dom';
import { SiderLayout } from 'modules/core/layouts';
import { Tabs, Spin } from 'antd';
import { ForecastAmountDetail } from './containers/';
import {
  PeriodNavigator,
  ProjectionDetailSummary,
  ProjectionRulesDetail,
} from 'modules/core/components';
import { isUserAllowed } from 'modules/core/utils';
import { ROLES, CONCEPTS } from 'modules/core/constants';
import planning from 'modules/planning';
import forecast from 'modules/forecast';

const RESUME = 'resume';
const AMOUNT = 'amount';
const RULES = 'rules';

//TODO: CAMBIAR PLANNING POR FORECAST EN LO DE ROLES CUANDO ESTEN.
const {
  PLANNING__EXPENSES__VIEW,
  PLANNING__EXPENSES__BUSINESS_RULES,
  PLANNING__EXPENSES__AMOUNT,
  PLANNING__SALES__VIEW,
  PLANNING__SALES__BUSINESS_RULES,
  PLANNING__SALES__AMOUNT,
} = ROLES;

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PLANNING__CONCEPT = {
  [CONCEPTS.KEYS.SALES]: {
    VIEW: PLANNING__SALES__VIEW,
    VIEW_AMOUNT: PLANNING__SALES__AMOUNT,
    VIEW_BUSINESS_RULES: PLANNING__SALES__BUSINESS_RULES,
  },
  [CONCEPTS.KEYS.EXPENSES]: {
    VIEW: PLANNING__EXPENSES__VIEW,
    VIEW_AMOUNT: PLANNING__EXPENSES__AMOUNT,
    VIEW_BUSINESS_RULES: PLANNING__EXPENSES__BUSINESS_RULES,
  },
};

const ForecastDetail = ({
  projection,
  fetchProjectionDetail,
  forecastDetail,
  fetchForecastDetail,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(RESUME);
  const { t } = useTranslation();
  let { periodId, forecastId, projectionId } = useParams();
  let query = useQuery();

  useEffect(() => {
    setIsScreenLoading(true);
    Promise.all([
      fetchForecastDetail(forecastId),
      fetchProjectionDetail(projectionId),
    ]).then(() => setIsScreenLoading(false));
  }, [projectionId, forecastId, fetchProjectionDetail, fetchForecastDetail]);

  const userCanView = isScreenLoading
    ? false
    : isUserAllowed(PLANNING__CONCEPT[projection.concept.name].VIEW);

  const userCanViewAmount = isScreenLoading
    ? false
    : isUserAllowed(PLANNING__CONCEPT[projection.concept.name].VIEW_AMOUNT);

  const userCanViewBusinessRules = isScreenLoading
    ? false
    : isUserAllowed(
        PLANNING__CONCEPT[projection.concept.name].VIEW_BUSINESS_RULES
      );

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_FORECAST'),
          url: `/forecast/${periodId}`,
        },
        {
          name: `${t('LABEL_DETAIL')}: ${
            forecastDetail.name ? forecastDetail.name : ''
          }`,
          url: `/forecast/${periodId}/${forecastId}?tab=${projection.concept.name}`,
        },
        { name: projection.name ? projection.name : '' },
      ]}
    />
  );

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading}>
        {!isScreenLoading && renderHeader()}
        {!isScreenLoading && (
          <Tabs
            defaultActiveKey={
              query.get('tab') !== null ? query.get('tab') : RESUME
            }
            size="small"
            onChange={key => setActiveTab(key)}
          >
            {userCanView && (
              <>
                {userCanViewAmount && (
                  <Tabs.TabPane tab={t('FIELD_AMOUNT')} key={AMOUNT}>
                    {activeTab === AMOUNT && <ForecastAmountDetail />}
                  </Tabs.TabPane>
                )}
                {userCanViewBusinessRules && (
                  <Tabs.TabPane tab={t('LABEL_BUSINESS_RULES')} key={RULES}>
                    {activeTab === RULES && (
                      <ProjectionRulesDetail
                        projection={projection}
                        projectionId={projectionId}
                      />
                    )}
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane tab={t('LABEL_RESUME')} key={RESUME}>
                  {activeTab === RESUME && (
                    <ProjectionDetailSummary projectionId={projectionId} />
                  )}
                </Tabs.TabPane>
              </>
            )}
          </Tabs>
        )}
      </Spin>
    </SiderLayout>
  );
};

const mapStateToProps = state => ({
  projection: planning.selectors.getProjectionDetail(state),
  forecastDetail: forecast.selectors.getForecastDetail(state),
});

const mapDispatchToProps = {
  fetchProjectionDetail: planning.actions.fetchProjectionDetail,
  fetchForecastDetail: forecast.actions.fetchForecastDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastDetail);
