import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { Card, Button, Radio, Row, Col } from 'antd';
import {
  ControlOutlined,
  BarChartOutlined,
  PieChartOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import {
  ButtonActionBar,
  Typography,
  BarChart,
  ChartCustomTooltip,
  PieChart,
  DropdownMenu,
} from 'modules/core/components';
import ChartsSettingsModal from './ChartsSettingsModal';
import { CHARTS, PROJECTION } from 'modules/core/constants';
import { executeExportAsImage, dateMMYYYY } from 'modules/core/utils';
import reports from 'modules/reports';

const { BUDGET, REAL, LAST_FORECAST, REAL_LAST_PERIOD } = PROJECTION;
const BAR_KEYS = [BUDGET, REAL, LAST_FORECAST, REAL_LAST_PERIOD];

// NOTE: Potencial de constantes generico
const NUMBER_FORMAT = {
  ABBREVIATIONS: '0,0[.]0 a',
  SEPARATORS: '0,0',
  PERCENTAGE: '0%',
};

const PIE_COL_SPAN = { 1: 12, 2: 12, 3: 8, 4: 6 };

const CHARTS_ICONS = {
  [CHARTS.TYPES.BAR]: BarChartOutlined,
  [CHARTS.TYPES.PIE]: PieChartOutlined,
};

const GENERAL_OPTIONS = {
  labelFormat: CHARTS.SETTINGS.LABEL_FORMAT_LIST,
  labelPosition: CHARTS.SETTINGS.LABEL_POSITION,
  valueFormat: CHARTS.SETTINGS.VALUE_FORMAT_LIST,
};
let BAR_OPTIONS = {
  groupMode: CHARTS.SETTINGS.BAR_GROUP_MODE,
  filteredValues: [],
};
const GENERAL_INITIAL_SETTINGS = {
  showLabel: false,
  labelFontSize: 12,
  dataSize: 5,
  labelFormat: GENERAL_OPTIONS.labelFormat[0],
  labelPosition: GENERAL_OPTIONS.labelPosition[0],
  valueFormat: GENERAL_OPTIONS.valueFormat[0],
};
const PIE_INITIAL_SETTINGS = {
  showTotalAmount: true,
  showLegends: true,
  totalFontSize: 25,
};

const BAR_INITIAL_SETTINGS = {
  groupMode: BAR_OPTIONS.groupMode[0],
  filteredValues: [],
};

const OtherReportCharts = ({
  reportId,
  periodId,
  selectedDates,
  barData,
  pieData,
  fetchCustomReportGraphData,
  lineReportAccount,
  dimensionName,
  prefix,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGraphType, setSelectedGraphType] = useState(CHARTS.TYPES.BAR);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [pieSettings, setPieSettings] = useState(PIE_INITIAL_SETTINGS);
  const [barSettings, setBarSettings] = useState(BAR_INITIAL_SETTINGS);
  const [generalSettings, setGeneralSettings] = useState(
    GENERAL_INITIAL_SETTINGS
  );
  const [pieLegendId, setPieLegendId] = useState('');
  const pieChartRef = useRef();
  const barChartRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    fetchCustomReportGraphData(reportId, {
      period_id: periodId,
      amount: generalSettings.dataSize,
      ...selectedDates,
    }).then(() => setIsLoading(false));
  }, [
    reportId,
    periodId,
    selectedDates,
    fetchCustomReportGraphData,
    generalSettings.dataSize,
  ]);

  useEffect(() => {
    if (barData.allDimensions) {
      BAR_OPTIONS.filteredValues = barData.allDimensions;
      setBarSettings(prevSettings => {
        return { ...prevSettings, filteredValues: barData.allDimensions };
      });
    }
  }, [barData]);

  useEffect(() => {
    if (pieData.length > 0) {
      setPieLegendId(pieData[0].id);
    }
  }, [pieData]);

  const chartTheme = {
    labels: {
      text: {
        fontSize: generalSettings.showLabel ? generalSettings.labelFontSize : 0,
      },
    },
  };

  const chartTitle = `${t('REPORT_OTHER_CHART_CARD_TITLE', {
    lineReportAccount,
    dimensionName,
  })} ${
    !_.isEmpty(selectedDates)
      ? `(${dateMMYYYY(selectedDates['start-date'], 'YYYY-MM')}- ${dateMMYYYY(
          selectedDates['end-date'],
          'YYYY-MM'
        )})`
      : ''
  }`;

  const handleOnSettingChange = ({ chartSettings, generalSettings }) => {
    if (selectedGraphType === CHARTS.TYPES.BAR) setBarSettings(chartSettings);
    if (selectedGraphType === CHARTS.TYPES.PIE) setPieSettings(chartSettings);
    setGeneralSettings(generalSettings);
  };

  const renderValueFormat = (value, format = NUMBER_FORMAT.ABBREVIATIONS) => {
    if (format === NUMBER_FORMAT.PERCENTAGE) {
      return numeral(value).format(format);
    }
    return `${prefix} ${numeral(value).format(format)}`;
  };

  const renderLabelTrim = ({ label, length = 12, suffix = '...' }) =>
    label.length > length ? `${label.slice(0, length)}${suffix}` : label;

  const onExportChart = useCallback(props => {
    executeExportAsImage({ ...props });
  }, []);

  const renderDropDown = () => {
    return (
      <DropdownMenu
        title={t('ACTION_MORE')}
        placement="bottomRight"
        menu={[
          {
            title: t('ACTION_EXPORT'),
            icon: <ExportOutlined />,
            submenu: CHARTS.EXPORT_FORMAT_TYPE_OPTIONS.map(formatType => {
              return {
                title: formatType.name,
                key: formatType.id,
                onClick: () =>
                  onExportChart({
                    chartRef:
                      selectedGraphType === CHARTS.TYPES.PIE
                        ? pieChartRef.current
                        : barChartRef.current,
                    format: formatType.id,
                    onExport: formatType.onAction,
                    props: formatType.props,
                    title: chartTitle,
                  }),
              };
            }),
          },
        ]}
      />
    );
  };

  const renderCardExtra = () => (
    <ButtonActionBar>
      <Radio.Group
        defaultValue={selectedGraphType}
        onChange={action => setSelectedGraphType(action.target.value)}
      >
        {_.map(CHARTS_ICONS, (icon, key) => (
          <Radio.Button value={key} key={key}>
            <Typography.Icon icon={icon} level={2} />
          </Radio.Button>
        ))}
      </Radio.Group>
      <Button
        title={t('CONFIG_SETTINGS_MODAL_TITLE')}
        type="text"
        shape="circle"
        icon={<Typography.Icon icon={ControlOutlined} level={2} />}
        onClick={() => setShowSettingModal(true)}
      />
      {renderDropDown()}
    </ButtonActionBar>
  );

  const renderCenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach(datum => (total += datum.data.amount));

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: pieSettings.totalFontSize }}
      >
        {renderValueFormat(total)}
      </text>
    );
  };

  const renderPieLabelFormat = ({ label, data: { amount, percentage } }) => {
    const value =
      CHARTS.VALUE_FORMAT.PERCENTAGE === generalSettings.valueFormat
        ? renderValueFormat(percentage, NUMBER_FORMAT.PERCENTAGE)
        : renderValueFormat(amount);

    if (generalSettings.labelFormat === CHARTS.LABEL_FORMAT.NAME) {
      return renderLabelTrim({ label, length: 23, suffix: '.' });
    }
    if (generalSettings.labelFormat === CHARTS.LABEL_FORMAT.VALUE) {
      return value;
    }
    if (generalSettings.labelFormat === CHARTS.LABEL_FORMAT.NAME_VALUE) {
      return `${renderLabelTrim({ label, suffix: '.' })}: ${value}`;
    }
  };

  const renderPieChart = () => (
    <Row gutter={[8, 24]} type="flex" justify="center">
      {pieData.map(el => (
        <Col
          key={el.id}
          span={PIE_COL_SPAN[pieData.length]}
          style={{
            textAlign: 'center',
            ...(pieSettings.showLegends && { cursor: 'pointer' }),
          }}
          onClick={pieSettings.showLegends ? () => setPieLegendId(el.id) : null}
        >
          <PieChart
            containerHeight={400}
            data={el.values}
            value={d => d.percentage}
            margin={{
              right: 80,
              left: pieSettings.showLegends ? 130 : 100,
            }}
            arcLabel={renderPieLabelFormat}
            arcLinkLabel={renderPieLabelFormat}
            enableArcLinkLabels={
              generalSettings.showLabel &&
              generalSettings.labelPosition === CHARTS.SETTINGS.POSITION_OUTER
            }
            enableArcLabels={
              generalSettings.showLabel &&
              generalSettings.labelPosition === CHARTS.SETTINGS.POSITION_INNER
            }
            {...(pieSettings.showTotalAmount && {
              innerRadius: 0.6,
              layers: [
                'arcs',
                'arcLabels',
                'arcLinkLabels',
                'legends',
                renderCenteredMetric,
              ],
            })}
            tooltip={({ datum: { data, label, color } }) => {
              return (
                <ChartCustomTooltip
                  title={label}
                  value={renderValueFormat(data.amount)}
                  color={color}
                />
              );
            }}
            legends={
              pieSettings.showLegends && pieLegendId === el.id
                ? [
                    {
                      anchor: 'left',
                      direction: 'column',
                      itemHeight: 20,
                      itemWidth: 120,
                      toggleSerie: true,
                      translateX: -130,
                      translateY: 0,
                      data: el.values.map((item, index) => {
                        return {
                          ...item,
                          color: CHARTS.COLORS[index],
                          label: renderLabelTrim({ label: item.label }),
                        };
                      }),
                    },
                  ]
                : []
            }
            theme={{
              ...chartTheme,
            }}
          />
          <Typography.Subtitle level={2}>
            {t(PROJECTION.I18N[el.id])}
          </Typography.Subtitle>
        </Col>
      ))}
    </Row>
  );

  const renderBarChart = () => (
    <BarChart
      containerHeight={400}
      keys={BAR_KEYS}
      indexBy="dimension"
      data={barData.values.filter(el =>
        barSettings.filteredValues.includes(el.dimension)
      )}
      groupMode={barSettings.groupMode}
      axisLeft={{ format: value => renderValueFormat(value) }}
      tooltip={({ value, color, indexValue }) => (
        <ChartCustomTooltip
          title={indexValue}
          value={renderValueFormat(value, NUMBER_FORMAT.SEPARATORS)}
          color={color}
        />
      )}
      label={d => (d.value ? renderValueFormat(d.value) : '')}
      legendLabel={d => t(PROJECTION.I18N[d.id])}
      legends={[
        {
          anchor: 'bottom',
          dataFrom: 'keys',
          direction: 'row',
          itemHeight: 20,
          itemWidth: 120,
          toggleSerie: true,
          translateX: 0,
          translateY: 55,
        },
      ]}
      theme={{ ...chartTheme }}
    />
  );

  return (
    <>
      <div
        ref={selectedGraphType === CHARTS.TYPES.PIE ? pieChartRef : barChartRef}
      >
        <Card
          className="report-line-chart-card"
          title={chartTitle}
          headStyle={{ height: 56 }}
          loading={isLoading}
          extra={renderCardExtra()}
        >
          {selectedGraphType === CHARTS.TYPES.PIE && renderPieChart()}
          {selectedGraphType === CHARTS.TYPES.BAR && renderBarChart()}
        </Card>
      </div>
      <ChartsSettingsModal
        visible={showSettingModal}
        selectedGraphType={selectedGraphType}
        onCancel={() => setShowSettingModal(false)}
        onOk={handleOnSettingChange}
        settingOptions={{
          bar: BAR_OPTIONS,
          general: GENERAL_OPTIONS,
        }}
        settingsValues={{
          pie: pieSettings,
          bar: barSettings,
          general: generalSettings,
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  barData: reports.selectors.getCustomReportBarData(state),
  pieData: reports.selectors.getCustomReportPieData(state),
});

const mapDispatchToProps = {
  fetchCustomReportGraphData: reports.actions.fetchCustomReportGraphData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherReportCharts);
