import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import {
  Drawer,
  Spin,
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Space,
  message,
  Modal,
  Tooltip,
} from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ButtonActionBar, InputMonth } from 'modules/core/components';
import configuration from 'modules/configuration';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import './VariableDetailDrawer.scss';
import { generateSelectOptions, handleSelectAll } from 'modules/core/utils';
import { DIMENSIONS, PROJECTION } from 'modules/core/constants';

const FormItem = Form.Item;

const isPercentage = type => {
  return type === 'percentage';
};

const VariableForm = ({
  form,
  canEdit,
  setCanEdit,
  setHasChanges,
  initialValues,
  optionsBehaviour,
  selectorAccountOptions,
  visible,
  dimensionsList,
}) => {
  const [showAllAccounts, setShowAllAccounts] = useState(false);
  const [showAllDimensions, setShowAllDimensions] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }
  }, [visible, form, initialValues]);
  return (
    <Form form={form} layout="vertical" hideRequiredMark={false}>
      <Row gutter={0} type="flex" justify="center">
        <Col span={24}>
          <FormItem
            name="name"
            label={
              !canEdit ? (
                <Space>
                  {t('FIELD_NAME')}
                  <Tooltip title={t('PLANNING_VARIABLE_EDIT_TOOLTIP')}>
                    <EditOutlined style={{ cursor: 'pointer' }} />
                  </Tooltip>
                </Space>
              ) : (
                t('FIELD_NAME')
              )
            }
            rules={[{ required: canEdit, message: t('REQUIRED_FIELD') }]}
            onClick={() => setCanEdit(true)}
          >
            <Input
              disabled={!canEdit}
              style={{ cursor: 'pointer' }}
              onChange={() => setHasChanges()}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24} type="flex" justify="fex-start">
        <Col span={12}>
          <FormItem
            name="value_type"
            label={t('FIELD_VALUE_TYPE')}
            rules={[{ required: canEdit, message: t('REQUIRED_FIELD') }]}
          >
            <Select
              disabled={!canEdit}
              onChange={() => setHasChanges()}
              options={generateSelectOptions({
                options: PROJECTION.VARIABLES_TYPE_OPTIONS.map(variable => {
                  return { ...variable, name: t(variable.name) };
                }),
              })}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="behaviour"
            label={t('FIELD_BEHAVIOR')}
            rules={[{ required: canEdit, message: t('REQUIRED_FIELD') }]}
          >
            <Select
              disabled={!canEdit}
              onChange={() => setHasChanges()}
              options={generateSelectOptions({ options: optionsBehaviour })}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24} type="flex" justify="center">
        <Col span={24}>
          <FormItem name="accounts" label={t('FIELD_ACCOUNTS_IMPACTED')}>
            <Select
              disabled={!canEdit}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
              allowClear={true}
              onClick={() => setShowAllAccounts(!showAllAccounts)}
              maxTagCount={!canEdit && showAllAccounts ? null : 1}
              maxTagTextLength={30}
              placeholder={
                initialValues.accounts.length > 0
                  ? t('ACTION_SELECT')
                  : t('CONFIG_VAR_ACCOUNTS_NOT_SELECTED_PLACEHOLDER')
              }
              onChange={values => {
                setHasChanges();
                handleSelectAll({
                  values,
                  allValues: selectorAccountOptions
                    .asMutable()
                    .map(account => account.id),
                  areAllSelected,
                  onSetValues: values =>
                    form.setFieldsValue({ accounts: values }),
                  onSetAreAllSelected: state => setAreAllSelected(state),
                });
              }}
              options={generateSelectOptions({
                options: selectorAccountOptions,
                includeOptionAll: true,
              })}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24} type="flex" justify="center">
        <Col span={24}>
          <FormItem
            name="dimensions"
            label={
              canEdit ? (
                <span>
                  {t('LABEL_DIMENSIONS') + ' '}
                  <Tooltip title={t('PLANNING_VARIABLE_CONFIG_TOOLTIP')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              ) : (
                t('LABEL_DIMENSIONS')
              )
            }
          >
            <Select
              disabled={true}
              allowClear={true}
              mode="multiple"
              maxTagCount={!canEdit && showAllDimensions ? null : 1}
              maxTagTextLength={30}
              onClick={() => setShowAllDimensions(!showAllDimensions)}
              placeholder={
                initialValues.dimensions.length > 0
                  ? t('ACTION_SELECT')
                  : t('CONFIG_VAR_DIMENSIONS_NOT_SELECTED_PLACEHOLDER')
              }
              options={generateSelectOptions({
                options: dimensionsList?.dimensions,
              })} //NOTE: HASTA QUE SE PUEDA EDITAR EN EL DROWER, QUEDA SIN OPTION 'TODAS'
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

const renderPercentageValue = ({ amount, format }) =>
  numeral(amount).format(format);

const renderDefaultValue = (type, amount) => {
  if (isPercentage(type)) {
    return (
      amount &&
      renderPercentageValue({
        amount,
        format: Number.isInteger(amount * 100) ? '0%' : '0.0%',
      })
    );
  }
  return amount;
};

const ValuesTable = ({
  type,
  canEdit,
  values,
  onEditValue,
  monthsPublishAndApprove,
  isForecast,
}) => {
  return (
    <Row>
      {values.map((item, index) => (
        <Col span={8} key={index}>
          <InputMonth
            index={index}
            month={item.date}
            defaultValue={renderDefaultValue(type, item.amount)}
            disabled={
              !canEdit ||
              (isForecast && monthsPublishAndApprove.includes(item.date))
            }
            onChange={value =>
              onEditValue(index, {
                date: item.date,
                amount: isPercentage(type) ? value / 100 : value,
              })
            }
            formatter={
              isPercentage(type) ? value => value && `${value}%` : null
            }
            className="value-item"
          />
        </Col>
      ))}
    </Row>
  );
};

const VariableDetailDrawer = ({
  visible,
  onClose,
  budgetId,
  variableId,
  variableDetail,
  accountsValues,
  optionsBeh,
  fetchAccountsValues,
  fetchBehaviourOptions,
  fetchVariableDetail,
  editValues,
  onChangesDone,
  dimensionsList,
  fetchDimensionsList,
  editVariable,
  monthsPublishAndApprove,
  isForecast,
  setVariablesValues,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState([]);
  const [savingChanges, setSavingChanges] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [showExitConfirmModal, setShowExitConfirmModal] = useState(false);
  const [editVar, setEditVar] = useState(false);
  const [isVarEdited, setIsVarEdited] = useState(false);
  const [form] = Form.useForm();
  // let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    Promise.all([
      fetchVariableDetail(variableId, {
        drawer: true,
        projection_id: budgetId,
      }),
      fetchAccountsValues({
        dimension: DIMENSIONS.ACCOUNTS.id,
        flat: 'True',
        writeable: 'True',
      }),
      fetchBehaviourOptions(),
      fetchDimensionsList({ flat: 'True', writeable: 'True' }),
    ]).then(() => setIsLoading(false));
  }, [
    fetchVariableDetail,
    fetchAccountsValues,
    fetchBehaviourOptions,
    fetchDimensionsList,
    variableId,
    budgetId,
  ]);

  useEffect(() => {
    variableDetail.values && setInitialValues(variableDetail.values);
  }, [variableDetail]);

  const handleConfirm = () => {
    const data = {
      values: variableDetail.values.map(val => {
        return {
          ...val,
          amount: val.amount ? val.amount : 0,
        };
      }),
    };
    setSavingChanges(true);
    editValues(variableId, data, { projection_id: budgetId })
      .then(() => {
        setSavingChanges(false);
        message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'));
        handleClose();
        onChangesDone();
      })
      .catch(error => {
        setSavingChanges(false);
        message.error(t('FEEDBACK_SAVE_CHANGES_FAIL'));
        handleClose();
      });
  };

  const handleClose = () => {
    form.resetFields();
    setInitialValues([]);
    onClose();
  };

  const handleEditVar = () => {
    form
      .validateFields()
      .then(values => {
        const data = {
          name: values.name,
          behaviour: values.behaviour,
          value_type: values.value_type,
          accounts: {
            ...variableDetail.accounts,
            [DIMENSIONS.ACCOUNTS.id]: values.accounts.map(id => parseInt(id)),
          },
        };
        message.loading({
          key: 'loading_edit',
          content: t('FEEDBACK_LOADING_CHANGES'),
        });
        editVariable(variableId, data)
          .then(() => {
            message.destroy('loading_edit');
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'));
            setEditVar(false);
            setIsVarEdited(false);
            setIsLoading(true);
            fetchVariableDetail(variableId, {
              drawer: true,
              projection_id: budgetId,
            }).then(() => {
              setIsLoading(false);
              onChangesDone();
            });
          })
          .catch(() => {
            message.destroy('loading_edit');
            message.error(t('FEEDBACK_SAVE_CHANGES_FAIL'));
          });
      })
      .catch(e => {});
  };
  return (
    <Drawer
      className="variable-detail-drawer"
      title={t('DETAIL_TITLE', { name: variableDetail.name })}
      visible={visible && variableDetail.name}
      closable={true}
      onClose={saveEnabled ? setShowExitConfirmModal : handleClose}
      width={400}
      footer={
        !editVar ? (
          <ButtonActionBar>
            <Button
              onClick={saveEnabled ? setShowExitConfirmModal : handleClose}
              disabled={savingChanges}
            >
              {t('ACTION_RETURN')}
            </Button>
            <Button
              type="primary"
              onClick={handleConfirm}
              loading={savingChanges}
              disabled={!saveEnabled}
            >
              {t('ACTION_SAVE')}
            </Button>
          </ButtonActionBar>
        ) : (
          <ButtonActionBar>
            <Button
              onClick={() => {
                setEditVar(false);
                setIsVarEdited(false);
                form.resetFields();
              }}
            >
              {t('ACTION_CANCEL')}
            </Button>
            <Button
              type="primary"
              onClick={handleEditVar}
              // loading={savingChanges}
              disabled={!isVarEdited}
            >
              {t('ACTION_EDIT')}
            </Button>
          </ButtonActionBar>
        )
      }
    >
      <VariableForm
        form={form}
        initialValues={{
          ...variableDetail,
          behaviour: variableDetail.behaviour
            ? variableDetail.behaviour.id
            : undefined,
          accounts: variableDetail.accounts
            ? [
                ...(variableDetail.accounts[DIMENSIONS.ACCOUNTS.id]
                  ? variableDetail.accounts[DIMENSIONS.ACCOUNTS.id] //envio los ids de cuenta
                  : variableDetail.accounts['"1"']),
              ].map(accountId => accountId)
            : [],
          dimensions: variableDetail.accounts
            ? _.keys(variableDetail.accounts)
                .filter(
                  key =>
                    parseInt(key) !== DIMENSIONS.ACCOUNTS.id && key !== '"1"'
                )
                .map(key => parseInt(key))
            : [],
        }}
        canEdit={editVar}
        setCanEdit={setEditVar}
        setHasChanges={() => setIsVarEdited(true)}
        dimensionsList={dimensionsList}
        optionsBehaviour={optionsBeh}
        selectorAccountOptions={accountsValues.values}
        visible={visible}
      />
      {/* TODO: FIX http://localhost:3000/static/js/vendors~main.chunk.js.map ERROR*/}
      {/* {editVar && (
           <Button
           type="link"
           icon={<EditOutlined />}
           onClick={()=>history.push(`/configuracion/modificadores?tab=Variabes`)} //TODO: CHEQUEAR TAB
           title={t('PLANNING_VARIABLE_CONFIG_LINK')}
         >
           {t('PLANNING_VARIABLE_CONFIG_LINK_ACTION')}
         </Button>
      )} */}
      {initialValues.length > 0 && !isLoading && !editVar && (
        <ValuesTable
          type={variableDetail.value_type}
          values={initialValues}
          canEdit={true} //todo: checkear permisos
          onEditValue={(index, newValue) => {
            setVariablesValues({ index, newValue });
            if (
              !saveEnabled &&
              newValue !== variableDetail.values[index].amount
            )
              setSaveEnabled(true);
          }}
          monthsPublishAndApprove={monthsPublishAndApprove}
          isForecast={isForecast}
        />
      )}
      <Spin spinning={isLoading} />
      <Modal
        title={t('PLANNING_VARIABLES_RETURN_CONFIRM_TITLE')}
        visible={showExitConfirmModal}
        onCancel={() => setShowExitConfirmModal(false)}
        centered
        okText={t('ACTION_RETURN_ANYWAY')}
        cancelText={t('ACTION_CANCEL')}
        onOk={handleClose}
      >
        <>
          {t('PLANNING_VARIABLES_RETURN_CONFIRM_TEXT')}
          <br />
          {t('PLANNING_VARIABLES_RETURN_CONFIRM_TOOLTIP')}
        </>
      </Modal>
    </Drawer>
  );
};
const mapStateToProps = state => ({
  dimensionsList: configuration.selectors.getDimensionsList(state), //Se usa este fetch para el selector de dimensiones por ahora. Ver como mostrar los valores o si es necesario mostrar todo eso
  variableDetail: configuration.selectors.getVariableDetail(state),
  optionsBeh: configuration.selectors.getBehaviourList(state),
  accountsValues: configuration.selectors.getDimensionValues(state),
});

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchAccountsValues: configuration.actions.fetchDimensionValues,
  fetchBehaviourOptions: configuration.actions.fetchBehaviourList,
  fetchVariableDetail: configuration.actions.fetchVariableDetail,
  editValues: configuration.actions.editVariableValues,
  editVariable: configuration.actions.editVariable,
  setVariablesValues: configuration.actions.setVariablesValues,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VariableDetailDrawer);
