import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Space, Row, Col, Input, Spin } from 'antd';
import {
  ButtonActionBar,
  EmptyScreen,
  CreateReportModal,
} from 'modules/core/components';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';
import { ReportListTable } from './components';
import { ROLES } from 'modules/core/constants';
import reports from 'modules/reports';

const { Search } = Input;
const { SETTINGS__REPORTS__REPORTSPNL__CREATE } = ROLES;

const ReportsList = ({
  fetchReportList,
  pnlReportsData,
  reportsCount,
  isScreenLoading,
}) => {
  const [searchValue, setSearchValue] = useState(null);
  const { t } = useTranslation();

  const functionSearch = (values, searchValue) => {
    const resultado = [];
    values.forEach(value => {
      if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
        resultado.push(value);
      }
    });
    return resultado;
  };

  const renderCreateReportModal = () => (
    <CreateReportModal
      rolesAvailables={[SETTINGS__REPORTS__REPORTSPNL__CREATE]}
      onFetchReportList={() => fetchReportList({ flat: 'True' })}
    />
  );

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_REPORTS_EMPTY_STATE_TITLE')}
      footer={renderCreateReportModal()}
    />
  );

  const renderUserActions = () => (
    <ButtonActionBar>
      <Space size="middle">
        <Search
          className="configuration-reports-list-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={search => setSearchValue(search)}
        />
        {renderCreateReportModal()}
      </Space>
    </ButtonActionBar>
  );

  return (
    <>
      <Spin spinning={isScreenLoading} />
      <Row gutter={[24, 24]}>
        {reportsCount <= 0 && !isScreenLoading && renderEmptyScreen()}
        {reportsCount > 0 && !isScreenLoading && (
          <>
            <Col span={24}>{renderUserActions()}</Col>
            <Col span={24}>
              <ReportListTable
                searchValue={searchValue}
                dataSource={pnlReportsData}
                functionSearch={functionSearch}
                isScreenLoading={isScreenLoading}
                onFetchReportList={() => fetchReportList({ flat: 'True' })}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  reportList: reports.selectors.getReportList(state),
});

const mapDispatchToProps = {
  fetchReportList: reports.actions.fetchReportList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);
