import * as types from './constants';
import moment from 'moment';
import decode from 'jwt-decode';
import { GA } from 'modules/core/utils';

export const tokenutils = {
  getAccessToken() {
    return localStorage.getItem('access_token');
  },
  saveAccessToken(token) {
    localStorage.setItem('access_token', token);
  },
  saveTokens(data) {
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
  },
  removeToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  },
  checkTokenFreshness(token, timeToExpire) {
    // NOTE: this does NOT verify the token signature is valid, only checks expiry date
    // integrity validation is done on the server
    try {
      let tokenPayload = decode(token);
      let expiry = moment.unix(tokenPayload.exp);
      return expiry.diff(moment(), 'seconds') > timeToExpire;
    } catch (error) {
      //token is invalid (undefined) so cant do the expire validation
      return false;
    }
  },
};

export const onLogin = data => async dispatch => {
  try {
    const response = await dispatch({
      type: types.USER_LOGIN,
      payload: {
        request: {
          url: '/auth/users/token/',
          method: 'POST',
          data,
        },
      },
    });
    tokenutils.saveTokens(response.payload.data);
    return Promise.resolve();
  } catch (error) {
    GA.event(GA.ACTIONS.USER_LOGIN_ERROR);
    return Promise.reject(error);
  }
};

export const confirmPassword = data => async dispatch => {
  try {
    const response = await dispatch({
      type: types.USER_CHANGE_PASSWORD,
      payload: {
        request: {
          url: '/auth/users/confirm/',
          method: 'POST',
          data,
        },
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

// TODO: Agregar llamada await a API si existe endpoint
export const onLogout = () => async dispatch => {
  try {
    tokenutils.removeToken();
    dispatch({ type: types.USER_LOGOUT });
    GA.event(GA.ACTIONS.USER_LOGOUT);
    GA.endSession();
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const whoAmI = () => async dispatch => {
  try {
    const response = await dispatch({
      type: types.USER_WHO_AM_I,
      payload: {
        request: {
          url: '/auth/users/current/',
          method: 'GET',
        },
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// LANG SETTING
export const editUserLang = (userId, lang) => async dispatch => {
  const response = await dispatch({
    type: types.USER_SETTING_LANG,
    payload: {
      request: {
        url: `/auth/users/${userId}/`,
        method: 'PATCH',
        data: {
          lang: lang,
        },
      },
    },
  });
  if (response.error) {
    return Promise.reject(response.error);
  }
  return Promise.resolve(response);
};

export const emailConfirm =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.EMAIL_CONFIRM,
      payload: {
        request: {
          url: `/auth/accounts/send-reset-password-link/`,
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return Promise.resolve(response);
  };

export const resetPassword =
  (data = {}) =>
  async dispatch => {
    const response = await dispatch({
      type: types.RESET_PASSWORD,
      payload: {
        request: {
          url: `/auth/accounts/reset-password/`,
          method: 'POST',
          data,
        },
      },
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    return Promise.resolve(response);
  };
