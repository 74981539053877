import React, { useState } from 'react';
import { Modal, Form, Row, Col, Input, message, notification } from 'antd';
import { AddAndDeleteFormItem } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import configuration from 'modules/configuration';
import { connect } from 'react-redux';

const ConfigurationCreateModal = ({
  visible,
  onCancel,
  createDimensionValue,
  onFetchDimensionValues,
  dimensionId,
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onConfirm = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoadingModal(true);
        const data = values.dimensionsValues.map(infoValue => {
          return {
            ...infoValue,
            dimension: dimensionId,
          };
        });
        createDimensionValue(data)
          .then(() =>
            onFetchDimensionValues().then(() => {
              message.success(t('FEEDBACK_CREATE_SUCCESS'));
              setIsLoadingModal(false);
              onClose();
            })
          )
          .catch(() => {
            notification.error({
              message: t('FEEDBACK_DEFAULT_ERROR'),
              description: t('FEEDBACK_CREATION_FAIL'),
              duration: 0,
            });
            setIsLoadingModal(false);
            onClose();
          });
      })
      .catch(e => {});
  };

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_VALUES_MODAL_CREATE_NEW_VALUE_TITLE')}
      visible={visible}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={onConfirm}
      onCancel={onClose}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ dimensionsValues: [''] }}
      >
        <Form.List name="dimensionsValues">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => {
                  return (
                    <Row gutter={24} key={field.key} type="flex" justify="end">
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'code']}
                          label={[field.label, t('FIELD_CODE')]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_DIMENSIONS_VALUES_INPUT_CODE_PLACEHOLDER'
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[field.name, 'name']}
                          label={[field.label, t('FIELD_NAME')]}
                          rules={[
                            {
                              required: true,
                              message: t('REQUIRED_FIELD'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              'CONFIG_DIMENSIONS_VALUES_INPUT_NAME_PLACEHOLDER'
                            )}
                          ></Input>
                        </Form.Item>
                      </Col>
                      <AddAndDeleteFormItem
                        fieldsLength={fields.length}
                        index={index}
                        disabled={isLoadingModal}
                        addData={{
                          onClick: () => add(),
                          buttonName: 'CONFIG_DIMENSIONS_VALUES_ADD_NEW_ACTION',
                        }}
                        deleteData={{
                          onClick: () => remove(field.name),
                          buttonName: 'CONFIG_DIMENSIONS_VALUES_DELETE_ACTION',
                        }}
                      />
                    </Row>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  createDimensionValue: configuration.actions.createDimensionValue,
};

export default connect(null, mapDispatchToProps)(ConfigurationCreateModal);
