import React, { useState } from 'react';
import {
  Modal,
  Space,
  Radio,
  Upload,
  Button,
  Row,
  Col,
  message,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';

const LoadAndFormatDataModal = ({
  visible,
  onCancel,
  dataModal: { typeList, okText, title, onAction, onRefetch = null },
  setDataModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [optionLoadFile, setOptionLoadFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();

  const onClose = () => {
    setOptionLoadFile(null);
    setDataModal();
    setFileList([]);
    onCancel();
  };

  const renderMessageSuccess = () => {
    message.success(t('MESSAGE_PROCESS_STATUS_SUCCESS'));
    setIsLoading(false);
    onClose();
  };

  const onConfirm = () => {
    setIsLoading(true);
    onAction(fileList, optionLoadFile, fileList[0].name)
      .then(() => {
        onRefetch
          ? onRefetch().then(() => {
              renderMessageSuccess();
            })
          : renderMessageSuccess();
      })
      .catch(error => {
        setIsLoading(false);
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: t('FEEDBACK_LOAD_DATA_ERROR'),
          duration: 0,
        });
        onClose();
      });
  };

  const onAddUploadFile = file => {
    setFileList([file]);
    return false;
  };

  const uploadProps = {
    name: 'file',
    accept: '.xls, .xlsx',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: onAddUploadFile,
    onRemove: file => setFileList([]),
    fileList: fileList,
  };

  return (
    <Modal
      title={t(title)}
      centered
      visible={visible}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t(okText)}
      forceRender
      destroyOnClose
      okButtonProps={{ loading: isLoading, disabled: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Radio.Group
              onChange={value => setOptionLoadFile(value.target.value)}
            >
              <Space direction="vertical">
                {typeList.map(loadType => (
                  <Radio value={loadType.id} key={loadType.id}>
                    {t(loadType.name)}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        </Col>
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Upload {...uploadProps}>
              {uploadProps.fileList.length <= 0 && (
                <Button disabled={!optionLoadFile}>
                  <UploadOutlined /> {t('UPLOAD_FILE_PLACEHOLDER')}
                </Button>
              )}
            </Upload>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default LoadAndFormatDataModal;
