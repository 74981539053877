const adjustments = {
  ADJUSTMENT_MANAGMENT_LABEL: 'Management settings',
  ADJUSTMENT_EMPTY_STATE_ALERT_TITLE:
    'We still do not have all the data we need so that you can create your management settings',
  ADJUSTMENT_EMPTY_STATE_ALERT_TEXT:
    'In order for you to create adjustments we must have real data approved. Once you do, you can create all the settings that you consider necessary.',
  ADJUSTMENT_EMPTY_STATE_TITLE: 'Create your first fit!',
  ADJUSTMENT_EMPTY_STATE_TEXT:
    'Make records that allow you to adjust your data and correctly allocate all your sales and expenses',
  ADJUSTMENT_CREATE_ACTION: 'Create fit',
  ADJUSTMENT_VALID_LABEL: 'Current adjustments',
  ADJUSTMENT_DATE_FIELD: 'Adjusted year / month',
  ADJUSTMENT_TYPE_FIELD: 'Fit type',
  ADJUSTMENT_CREATE_FORM_TITLE: 'Setting creation',
  ADJUSTMENT_SELECT_DATE_LABEL: 'Year / month to adjust',
  ADJUSTMENT_TYPE_EXTENSION: 'Extension',
  ADJUSTMENT_TYPE_RECLASSIFICATION: 'Reclassification',
  ADJUSTMENT_TYPE_REDUCTION: 'Reduction',
  ADJUSTMENT_ADD_MOVEMENT_ACTION: 'Add movement',
  ADJUSTMENT_FORM_CANCEL_ALERT:
    'When you leave, you lose all the information you have uploaded so far. Shall we go out anyway?',
  ADJUSTMENT_CREATE_CONFIRM_TITLE: 'Setting creation confirmation',
  ADJUSTMENT_CREATE_CONFIRM_DESCRIPTION:
    'You are about to create an {{type}} in {{añoMes}}. Remember that once you do it, it will be registered, you can cancel it but not delete it.',
  ADJUSTMENT_CREATE_SUCCESS_FEEDBACK_TITLE: 'Registered setting',
  ADJUSTMENT_SUCCESS_FEEDBACK_DESCRIPTION:
    'You can already see the impact of it in monitoring or analysis.',
  ADJUSTMENT_DETAIL_TITLE: 'Adjustment detail',
  ADJUSTMENT_CANCELED_DETAIL_TITLE: 'Override trim detail',
  ADJUSTMENT_DOWNLOAD_ACTION: 'Download setting',
  ADJUSTMENT_DOWNLOAD_MOVEMENT_ACTION_TITLE: 'Download movements',
  ADJUSTMENT_CANCEL_CONFIRM_TITLE: 'Setting override confirmation',
  ADJUSTMENT_CANCEL_CONFIRM_TEXT:
    '{{userName}}, you are about to cancel an adjustment in {{añoMes}} Remember that once you make it, you cannot undo it.',
  ADJUSTMENT_CANCEL_CONFIRM_SUCCESS_FEEDBACK_TITLE:
    'Great! The cancellation was recorded.',

  ADJUSTMENT_CANCEL_CONFIRM_FAIL_FEEDBACK_DESCRIPTION:
    '{{userName}}, we were unable to abort.',
  ADJUSTMENT_EMPTY_STATE_APPROVE_DATA_TOOLTIP: 'No actual data approved',

  ADJUSTMENT_VIEW_ACTION_TITLE: 'See setting',
  ADJUSTMENT_NEW_CREATE_ACTION: 'Create new setting',
  ADJUSTMENT_CREATE_FAIL_FEEDBACK_DESCRIPTION:
    'The setting could not be created',
  ADJUSTMENT_LOAD_MOVEMENT_ACTION_TITLE: 'Load movements',
  ADJUSTMENT_DELETE_MOVEMENT_ACTION_TITLE: 'Delete motion',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_TITLE: 'Movements loaded successfully',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_DESCRIPTION:
    'You can already work with them',

  ADJUSTMENT_OBSERVATION_DETAIL_INFO: '(Original)\n{{canceled}} (cancellation)',

  ADJUSTMENT_DATA_TABLE_FILE_NAME:
    'Plika - Management Settings - {{periodName}} (listing)',
  ADJUSTMENT_LIST_DEFAULT_FILE_NAME: 'Plika - Management Settings (listing)',
  ADJUSTMENT_DEFAULT_FILE_NAME: 'Plika - Management Settings ',
  ADJUSTMENT_DATA_TYPE_FILE_NAME: `Plika - Management setting {{type}} - {{periodName}}`,
  ADJUSTMENT_MOVEMENT_FILE_NAME:
    'Plika Template- Management settings Movements.xlsx',
};

export default adjustments;
