import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { /*Dashboard,*/ Integrations, DashboardGeneral } from './components';
import configuration from 'modules/configuration';
import home from 'modules/home';

const Home = ({
  fetchDimensionValues,
  fetchDimensionsList,
  hasDataConfig,
  fetchIntegrationList,
  integrationList,
}) => {
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setIsConfigLoading(true);
    Promise.all([
      fetchDimensionValues({
        flat: 'True',
        dimension_name: 'Nivel',
        writeable: 'True',
      }),
      fetchDimensionsList({ flat: 'True', writeable: 'True' }),
    ])
      .then(() => setIsConfigLoading(false))
      .catch(() => setIsConfigLoading(false));
  }, [fetchDimensionsList, fetchDimensionValues]);

  useEffect(() => {
    setIsDataLoading(true);
    fetchIntegrationList().then(() => setIsDataLoading(false));
  }, [fetchIntegrationList]);

  return (
    <>
      <Spin style={{ paddingTop: 20 }} spinning={isConfigLoading} />
      {!isConfigLoading &&
        !hasDataConfig &&
        integrationList.every(data => !data.status) && (
          <Integrations
            dataSource={integrationList}
            isdataLoading={isDataLoading}
          />
        )}
      {!isConfigLoading &&
        (hasDataConfig ||
          (!hasDataConfig &&
            [...integrationList].some(data => data.status))) && (
          // NOTE: COMENTADO POR EL MOMENTO. SI SE VUELVE A USAR DESCOMENTAR.
          // <Dashboard hasDataConfig={hasDataConfig} />
          <DashboardGeneral hasDataConfig={hasDataConfig} />
        )}
    </>
  );
};

const mapStateToProps = state => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  dimensionValues: configuration.selectors.getDimensionValues(state),
  hasDataConfig: configuration.selectors.getHasDataConfig(state),
  integrationList: home.selectors.getIntegrationList(state),
});

const mapDispatchToProps = {
  fetchDimensionValues: configuration.actions.fetchDimensionValues,
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchIntegrationList: home.actions.fetchIntegrationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
