import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Upload,
  Space,
  Col,
  Row,
  Form,
  Select,
  Tooltip,
  notification,
  Spin,
} from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import emptyImage from 'assets/images/compositions/empty-small.svg';
import { ButtonActionBar, Typography } from 'modules/core/components';
import {
  generateSelectOptions,
  isInitiated,
  isInElaboration,
  isPublished,
  handleSelectAll,
} from 'modules/core/utils';
import { PROJECTION } from 'modules/core/constants';
import planning from 'modules/planning';
import { client } from 'httpclient';
import login from 'modules/login';
import { useHistory } from 'react-router-dom';

import './IntegrationUploadFile.scss';

const { Dragger } = Upload;
const { Option } = Select;
const FormItem = Form.Item;

const INITIAL_STATE_SELECTION = { incomes: false, expenses: false };

const IntegrationUploadFile = ({
  periodId,
  integrationFilesUpload,
  loggedUser: { email },
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [informationType, setInformationType] = useState('');
  const [budgetData, setBudgetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(INITIAL_STATE_SELECTION);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  let history = useHistory();

  //chequea si el mail de mi usuario matchea con ucc.edu.ar y si da true te habilita el boton de carga
  useEffect(() => {
    if (/@ucc.edu.ar\s*$/.test(email)) {
      setShowUploadButton(true);
    }
  }, [email]);

  useEffect(() => {
    if (showUploadModal === true) {
      client
        .get(
          `/planning/projections/?type=${PROJECTION.BUDGET}&period=${periodId}`
        )
        .then(response => setBudgetData(response.data.results));
    }
  }, [showUploadModal, periodId]);

  const salesOptions = budgetData.filter(
    budget =>
      budget.concept.name === 'Ventas' &&
      (isInElaboration(budget.status) ||
        isPublished(budget.status) ||
        isInitiated(budget.status))
  );

  const expensesOptions = budgetData.filter(
    budget =>
      budget.concept.name === 'Gastos' &&
      (isInElaboration(budget.status) ||
        isPublished(budget.status) ||
        isInitiated(budget.status))
  );

  const onAddUploadFile = () => {
    /*NOTE: esto lo dejo asi porque al cargar los archivos hace un post al endpoint de Planning,
    entonces al retornar false evito que haga esse trigger*/
    return false;
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true);
        const auxIncome = values.income ? values.income : [];
        const auxExpenses = values.expenses ? values.expenses : [];
        integrationFilesUpload(fileList, auxIncome, auxExpenses)
          .then(() => {
            setFileList([]);
            setInformationType('');
            form.resetFields();
            setIsLoading(false);
            setShowUploadModal(false);
            notification.success({
              message: t('FEEDBACK_VERY_GOOD'),
              description: (
                <>
                  <span>{t('FEEDBACK_INTEGRATION_FILE_UPLOAD_SUCCESS')}</span>
                  <Space direction="vertical" size="small">
                    <span
                      onClick={() => {
                        history.push(
                          `/planificacion/${periodId}/ventas/actividad/${values.income}/?tab=activity-details`
                        );
                      }}
                      className="link-to-upload-status"
                    >
                      {t('FEEDBACK_INTEGRATION_FILE_UPLOAD_SUCCESS2', {
                        action: t('LABEL_SALES'),
                      })}
                    </span>
                    <span
                      onClick={() => {
                        history.push(
                          `/planificacion/${periodId}/gastos/actividad/${values.expenses}/?tab=activity-details`
                        );
                      }}
                      className="link-to-upload-status"
                    >
                      {t('FEEDBACK_INTEGRATION_FILE_UPLOAD_SUCCESS2', {
                        action: t('LABEL_EXPENSES'),
                      })}
                    </span>
                  </Space>
                </>
              ),
              duration: 5,
            });
          })
          .catch(error => {
            setFileList([]);
            setInformationType('');
            form.resetFields();
            setIsLoading(false);
            setShowUploadModal(false);
            notification.error({
              message: t('FEEDBACK_INTEGRATION_FILE_UPLOAD_ERROR_MESSAGE'),
              description: error.response.data,
              duration: 0,
            });
          });
      })
      .catch(e => {});
  };

  const onClose = () => {
    setFileList([]);
    setInformationType('');
    form.resetFields();
    setShowUploadModal(false);
  };

  const renderShowModalButton = () => {
    if (showUploadButton) {
      return (
        <ButtonActionBar position="start">
          <Tooltip title={t('TOOLTIP_INTEGRATION_MESSAGE')}>
            <Button
              onClick={() => setShowUploadModal(true)}
              icon={<Typography.Icon icon={UploadOutlined} />}
              shape="circle"
              type="text"
            />
          </Tooltip>
        </ButtonActionBar>
      );
    }
  };

  const renderFooterButtons = () => (
    <Space direction="horizontal" size="small">
      <Button onClick={onClose} disabled={isLoading}>
        {t('ACTION_CANCEL')}
      </Button>
      <Button
        onClick={handleOk}
        type="primary"
        disabled={fileList.length <= 0 || isLoading}
      >
        {t('ACTION_UPLOAD')}
      </Button>
    </Space>
  );

  return (
    <>
      {renderShowModalButton()}
      <Modal
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        destroyOnClose={true}
        width={650}
        bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
        centered
        forceRender
        title={
          <Space direction="horizontal" size="small">
            {t('INTEGRATION_MODAL_TITLE')}
            <Tooltip title={t('TOOLTIP_INTEGRATION_MODAL_INFO')}>
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
        footer={renderFooterButtons()}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              <Col span={24}>
                <FormItem
                  //TO DO: agregar tooltip cuando se incluya la opcion de cargar por Importe
                  name="select-info"
                  label={t('LABEL_SELECT_OPTION_INTEGRATION')}
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  colon={false}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    onChange={value => setInformationType(value)}
                  >
                    <Option value="rules">{t('LABEL_BUSINESS_RULES')}</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="income"
                  label={t('LABEL_SELECT_SALES_BUDGET')}
                  tooltip={t('TOOLTIP_SELECT_BUDGET')}
                  rules={[
                    {
                      required:
                        form.getFieldValue('income')?.length > 0 ||
                        form.getFieldValue('expenses')?.length > 0
                          ? false
                          : true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    options={generateSelectOptions({
                      options: salesOptions,
                      includeOptionAll: true,
                    })}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={10}
                    showArrow
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={values =>
                      handleSelectAll({
                        values,
                        allValues: salesOptions.map(sale => sale.id),
                        areAllSelected: areAllSelected.incomes,
                        onSetValues: values =>
                          form.setFieldsValue({ income: values }),
                        onSetAreAllSelected: state =>
                          setAreAllSelected(prevState => {
                            return { ...prevState, incomes: state };
                          }),
                      })
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="expenses"
                  label={t('LABEL_SELECT_EXPENSES_BUDGET')}
                  rules={[
                    {
                      required:
                        form.getFieldValue('income')?.length > 0 ||
                        form.getFieldValue('expenses')?.length > 0
                          ? false
                          : true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    options={generateSelectOptions({
                      options: expensesOptions,
                      includeOptionAll: true,
                    })}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={10}
                    showArrow
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={values =>
                      handleSelectAll({
                        values,
                        allValues: expensesOptions.map(exp => exp.id),
                        areAllSelected: areAllSelected.expenses,
                        onSetValues: values =>
                          form.setFieldsValue({ expenses: values }),
                        onSetAreAllSelected: state =>
                          setAreAllSelected(prevState => {
                            return { ...prevState, expenses: state };
                          }),
                      })
                    }
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  name="fileList"
                  valuePropName="fileList"
                  /*NOTE: con esta funcion lo que hago es capturar la lista de archivos para poder mostrarla en la pantalla,
                'e' tiene una prop llamada fileList donde se guardan todos los archivos que uno va cargando*/
                  getValueFromEvent={e => {
                    return e.fileList;
                  }}
                >
                  <Dragger
                    {...{
                      name: 'file',
                      multiple: true,
                      beforeUpload: onAddUploadFile,
                      fileList: fileList,
                      onChange(file) {
                        setFileList(file.fileList);
                      },
                      disabled: !informationType,
                      showUploadList: {
                        showRemoveIcon: true,
                      },
                    }}
                  >
                    <Tooltip
                      title={!informationType ? t('TOOLTIP_DRAGGER_INFO') : ''}
                    >
                      <Space direction="vertical" size="small">
                        <img alt="img" src={emptyImage} />
                        <p className="ant-upload-text">
                          {t('INFO_FILES_UPLOAD')}
                        </p>
                      </Space>
                    </Tooltip>
                  </Dragger>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  loggedUser: login.selectors.getWhoAmI(state),
});

const mapDispatchToProps = {
  integrationFilesUpload: planning.actions.integrationFilesUpload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationUploadFile);
