import React from 'react';
import { Button, Space } from 'antd';
import { EmptyScreen } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import image from 'assets/images/compositions/feedback-image.svg';

// TODO: Notificar
const ScreenSuccess = ({ title, description, onConfirm, fixButton = null }) => {
  const { t } = useTranslation();
  return (
    <EmptyScreen
      image={image}
      title={title}
      description={description}
      footer={
        <Space>
          <Button type="primary" onClick={onConfirm}>
            {t('ACTION_FINISH')}
          </Button>
          {fixButton}
        </Space>
      }
    />
  );
};

export default ScreenSuccess;
