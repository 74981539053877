import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePagination } from 'modules/core/customHooks';
import { Alert, Row, Col, notification, Button } from 'antd';
import {
  ProjectionUploadModal,
  projectionSuccessUploadNotice,
  EmptyScreen,
} from 'modules/core/components';
import { ProjectionFactorsDetail, RuleTable } from './components';
import planning from 'modules/planning';
import configuration from 'modules/configuration';
import emptyStateImage from 'assets/images/compositions/empty-big.svg';

const ProjectionRulesDetail = ({
  fetchFormulasList,
  formulasRows,
  projection,
  projectionId,
  uploadProjectionXLS,
  fetchFormulasAmountList,
  formulasAmountList,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isAmountLoading, setIsAmountLoading] = useState(false);
  const [tablePagination, setTablePagination] = usePagination();
  const [formulaId, setFormulaId] = useState(null);
  const [factorId, setFactorId] = useState(null);
  const [showFactorsDetail, setShowFactorsDetail] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    setIsTableLoading(true);
    fetchFormulasList({ writeable: 'False', ...tablePagination }).then(() =>
      setIsTableLoading(false)
    );
  }, [fetchFormulasList, tablePagination]);

  useEffect(() => {
    setIsAmountLoading(true);
    fetchFormulasAmountList({ projection_id: projectionId })
      .then(() => setIsAmountLoading(false))
      .catch(() => setIsAmountLoading(false));
  }, [fetchFormulasAmountList, projectionId, showFactorsDetail]);

  const onSuccessUploadFile = response => {
    projectionSuccessUploadNotice({
      projectionType: 'factor',
      errorFile: response.payload.data.error_file,
      type: 'rule',
    });
    setIsAmountLoading(true);
    fetchFormulasAmountList({ projection_id: projectionId })
      .then(() => setIsAmountLoading(false))
      .catch(() => setIsAmountLoading(false));
  };

  const onFailUploadFile = error =>
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: error.response.data,
      duration: 0,
    });

  const handleFetchDataFormulas = () =>
    Promise.all([
      fetchFormulasAmountList({ projection_id: projectionId }),
      fetchFormulasList({ writeable: 'False', ...tablePagination }),
    ]);

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_FORMULAS_EMPTY_SCREEN_TITLE')}
      footer={
        <Button
          type="primary"
          onClick={() =>
            history.push(`/configuracion/elementosdeproyeccion?tab=rules`)
          }
        >
          {t('ACTION_GO_TO_CONFIGURE')}
        </Button>
      }
    />
  );

  const renderFactorsAlert = () => (
    <Alert
      type="info"
      showIcon
      description={t('PLANNING_FORMULAS_INFORMATIVE_ALERT')}
    />
  );

  const renderRuleTable = () => (
    <RuleTable
      projectionId={projectionId}
      projection={projection}
      dataSource={formulasRows}
      formulasAmountList={formulasAmountList}
      setFormulaId={setFormulaId}
      isTableLoading={isTableLoading || isAmountLoading}
      setFactorId={setFactorId}
      setShowFactorsDetail={setShowFactorsDetail}
      setShowUploadModal={setShowUploadModal}
      tablePagination={tablePagination}
      handleTableChange={setTablePagination}
      onFetchDataFormulas={handleFetchDataFormulas}
    />
  );

  const renderFactorsDetail = () => (
    <ProjectionFactorsDetail
      formulaId={formulaId}
      formulaData={formulasRows.results}
      factorId={factorId}
      setShowFactorsDetail={setShowFactorsDetail}
      setFactorId={setFactorId}
      projection={projection}
      projectionId={projectionId}
      formulaApplyData={formulasAmountList.find(
        data => data.formula === formulaId
      )}
      factoresList={
        formulasRows.results.filter(formula => formula.id === formulaId)[0]
          .factores
      }
      onFetchAmountList={() =>
        fetchFormulasAmountList({ projection_id: projectionId })
      }
    />
  );

  return (
    <>
      <Row gutter={[24, 24]}>
        {!showFactorsDetail && (
          <>
            {formulasRows.results.length <= 0 && !isTableLoading && (
              <Col span={24}>{renderNoDataScreen()}</Col>
            )}
            {formulasRows.results.length > 0 && (
              <>
                <Col span={24}>{renderFactorsAlert()}</Col>
                <Col span={24}>{renderRuleTable()}</Col>
              </>
            )}
          </>
        )}
        {showFactorsDetail && <Col span={24}>{renderFactorsDetail()}</Col>}
      </Row>
      <ProjectionUploadModal
        title={t('PLANNING_UPLOAD_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={fileList =>
          uploadProjectionXLS(projectionId, fileList, { factor_id: factorId })
        }
        onSuccessUpload={onSuccessUploadFile}
        onFailUpload={onFailUploadFile}
      />
    </>
  );
};

const mapStateToProps = state => ({
  formulasRows: configuration.selectors.getFormulasList(state),
  formulasAmountList: planning.selectors.getFormulasAmountList(state),
});

const mapDispatchToProps = {
  fetchFormulasList: configuration.actions.fetchFormulasList,
  fetchFormulasAmountList: planning.actions.fetchFormulasAmountList,
  uploadProjectionXLS: planning.actions.uploadProjectionXLS,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectionRulesDetail);
